import { Box, Grid, Typography, Divider } from '@mui/material';
import { ThemePalette } from 'mui.theme';

export const SummaryDetails = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  return (
    <Grid item lg={5} md={12} sm={12} xs={12}>
      <Box
        sx={{
          padding: '20px 10px 20px 10px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          borderRadius: '5px',
          border: `1px solid rgba(255, 255, 255, 0.15)`,
          minHeight: '500px',
          maxHeight: '500px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '700',
          }}
        >
          Summary
        </Typography>
        <Typography
          sx={{
            marginTop: '10px',
            fontSize: '12px',
            fontWeight: '700',
          }}
        >
          Issue:
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            marginTop: '5px',
          }}
        >
          Diesel Particulate Filter (DPF) plugging in dump trucks causes reduced
          performance, higher fuel use, and potential engine damage due to soot
          and ash buildup.
        </Typography>
        <Typography
          sx={{
            marginTop: '20px',
            fontSize: '12px',
            fontWeight: '700',
            marginBottom: '5px',
          }}
        >
          Causes:
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          Excessive idling, low exhaust temperature, poor fuel combustion
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          Ash accumulation from oil, faulty sensors, or EGR issues
        </Typography>
        <Divider
          sx={{
            backgroundColor: 'rgba(255,255,255,0.2)',
            marginTop: '20px',
            marginBottom: '10px',
          }}
        />

        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '700',
            marginBottom: '20px',
          }}
        >
          Recommended Actions
        </Typography>

        <Typography
          sx={{
            fontSize: '12px',
            marginBottom: '4px',
          }}
        >
          Preventive: Reduce idling, use low-ash oil, maintain injectors, and
          ensure proper fuel-air mix.
        </Typography>

        <Typography
          sx={{
            fontSize: '12px',
            marginBottom: '4px',
          }}
        >
          Maintenance: Perform manual regeneration, clean/replace DPF, check EGR
          and exhaust components.
        </Typography>

        <Typography
          sx={{
            fontSize: '12px',
            marginBottom: '4px',
          }}
        >
          Monitoring: Use telematics for alerts, train operators to recognize
          early signs.
        </Typography>
      </Box>
    </Grid>
  );
};
