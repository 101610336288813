import { truncateTo2dp } from 'utils/helpers/general';

export const findMinMaxValues = (arr: any[]) => {
  return arr.reduce(
    (acc, curr) => {
      curr.data.forEach((item: any) => {
        const value = item.value ?? 0; // Use 0 if value is undefined
        acc.min = Math.min(acc.min, value);
        acc.max = Math.max(acc.max, value);
      });
      return acc;
    },
    { min: Infinity, max: -Infinity }
  );
};

export function categorizeRange({ min, max }: any) {
  min = isNaN(Number(min)) ? 0 : Number(min);
  max = isNaN(Number(max)) ? 1 : Number(max);

  if (min > max) {
    [min, max] = [max, min];
  }

  const minVal: number = min > 0 ? 0 : min;

  const interval = (max - minVal) / 3;

  const categories = [
    { category: 'danger', range: [minVal, minVal + interval] },
    {
      category: 'warning',
      range: [minVal + interval + 0.01, minVal + 2 * interval],
    },
    { category: 'success', range: [minVal + 2 * interval + 0.01, max] },
  ];

  return categories;
}

export const thresholdColors = {
  success: '#008A5F',
  danger: '#EB5758',
  warning: '#FFA404',
};

export function processUtilizationAttributes(array: any[]) {
  return array.map((obj) => {
    const newObj: any = {};

    for (const [key, value] of Object.entries(obj)) {
      newObj[key] =
        key !== 'name' && typeof value === 'number'
          ? truncateTo2dp(value * 100)
          : value;
    }

    return newObj;
  });
}

export function multiplyRangeBy100(arr: any[]) {
  return arr.map((obj) => ({
    category: obj.category,
    range: obj.range.map((value: number) => value * 100),
  }));
}

export function getDefaultThresholds(tagName: string, defaults: any[]) {
  const tagExists = defaults.filter((item) => item.field_name === tagName);

  if (tagExists.length > 0) {
    const categoryMap = {
      red: 'danger',
      yellow: 'warning',
      green: 'success',
    };
    const parsedRanges = tagExists.map((item) => {
      const [min, max] = JSON.parse(
        item.range_string.replace('[', '[').replace(']', ']')
      );
      return {
        // @ts-expect-error ignore this
        category: categoryMap[item.category] || item.category,
        range: [Number(min), Number(max)],
      };
    });

    parsedRanges.sort((a, b) => a.range[0] - b.range[0]);

    return parsedRanges;
  }

  return [];
}
