import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Cell, PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import TableList from './TableList';
import { truncateTo2dp } from 'utils/helpers/general';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { useEffect } from 'react';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  isLoading: boolean;
  data: {
    total: number;
    totalDiff: number;
    productive: number;
    productiveDiff: number;
    idle: number;
    idleDiff: number;
  };
  unitSystem: string;
  setFuelConsumption: any;
}

const FuelConsumption = ({
  enableDarkTheme,
  title,
  isLoading,
  data,
  unitSystem,
  setFuelConsumption,
}: Props) => {
  const totalValue = data.total;
  const idleValue = data.idle;
  const productiveValue = data.productive;
  let maxHours = totalValue + idleValue;
  if (maxHours === 0) {
    maxHours = 10;
  }
  const isNegative = (value: number) => value < 0;

  const returnAnalysis = (value: number | string) => {
    return `${truncateTo2dp(
      Math.abs(isNaN(Number(value)) ? 0 : Number(value))
    )}% ${isNegative(Number(value)) ? 'less' : 'more'}`;
  };

  const tableData = [
    {
      name: 'Productive',
      time: formatNumberToShortScale(truncateTo2dp(productiveValue)),
      direction: data.productiveDiff > 0 ? 'up' : 'down',
      color: '#FF0068',
      fill: '#FF0068',
    },
    {
      name: 'Idle',
      time: formatNumberToShortScale(truncateTo2dp(idleValue)),
      direction: data.idleDiff > 0 ? 'up' : 'down',
      color: '#FF9C2C',
      fill: '#FF9C2C',
    },
  ];

  const rechartList = [
    {
      name: 'Productive',
      time: Number(truncateTo2dp(productiveValue)),
      direction: data.productiveDiff > 0 ? 'up' : 'down',
      color: '#FF0068',
    },
    {
      name: 'Idle',
      time: Number(truncateTo2dp(idleValue)),
      direction: data.idleDiff > 0 ? 'up' : 'down',
      color: '#FF9C2C',
    },
  ];

  const allData = {
    [`Total (${unitSystem === 'imperial' ? 'Gallons' : 'Litres'})`]:
      formatNumberToShortScale(truncateTo2dp(data.total)),
    'From Last period': returnAnalysis(data.totalDiff),
    ...Object.fromEntries(
      tableData.map(({ name, time, direction }) => [
        name,
        // `${time} (${direction})`,
        `${time}`,
      ])
    ),
  };

  useEffect(() => {
    setFuelConsumption(allData);
  }, [allData]);

  return (
    <Grid item lg={6} md={6}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '400px',
          maxHeight: '400px',
          overflowY: 'auto',
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            paddingTop: '0px',
          }}
        >
          {isLoading ? (
            <MultiColorCircularLoader height="300px" />
          ) : (
            <Grid container>
              <Grid item lg={6} sm={12}>
                <Box
                  sx={{
                    paddingTop: '20px',
                    minHeight: '200px',
                    maxHeight: '200px',
                  }}
                >
                  <FlexBox
                    sx={{
                      maxWidth: 'inherit',
                      height: '230px',
                      paddingTop: '20px',
                      marginRight: '10px',
                      paddingBottom: '10px',
                    }}
                  >
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={400} height={400}>
                        <Pie
                          data={rechartList}
                          dataKey="time"
                          cx="50%"
                          cy="50%"
                          innerRadius={70}
                          outerRadius={90}
                        >
                          {rechartList.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip
                          cursor={{ fill: '#1B2B4A' }}
                          wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                          contentStyle={{
                            backgroundColor: 'transparent',
                          }}
                          itemStyle={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.wheat
                              : ThemePalette.typography.black,
                          }}
                          formatter={(value: any, name, props) => {
                            return [
                              `${formatNumberToShortScale(
                                truncateTo2dp(value)
                              )} hrs`,
                              name,
                            ];
                          }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  </FlexBox>
                </Box>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '13px',
                    }}
                  >
                    Total
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '25px',
                    }}
                  >
                    {data.totalDiff > 0 ? (
                      <NorthEastIcon sx={{ color: '#029161', fontSize: 19 }} />
                    ) : (
                      <SouthEastIcon sx={{ color: '#EB5757', fontSize: 19 }} />
                    )}{' '}
                    {formatNumberToShortScale(truncateTo2dp(data.total))}
                    <span
                      style={{
                        fontSize: '11px',
                      }}
                    >
                      {' '}
                      {unitSystem === 'imperial' ? 'Gallons' : 'Litres'}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                    }}
                  >
                    <span
                      style={{
                        color: data.totalDiff > 0 ? '#029161' : '#EB5757',
                      }}
                    >
                      {returnAnalysis(data.totalDiff)}
                    </span>{' '}
                    from last period
                  </Typography>
                </Box>
                <TableList
                  enableDarkTheme={enableDarkTheme}
                  data={tableData}
                  unit={unitSystem === 'imperial' ? 'Gallons' : 'Litres'}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default FuelConsumption;
