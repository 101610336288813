/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { truncateTo2dp } from 'utils/helpers/general';
export function sumTimeDifferenceInHours(items: any[]) {
  return items.reduce((sum: number, item) => {
    if (item.date_of_orders && item.date_of_completion) {
      const orderDate: any = new Date(item.date_of_orders);
      const completionDate: any = new Date(item.date_of_completion);
      const diffInMs = completionDate - orderDate;
      const diffInHours: number = diffInMs / (1000 * 60 * 60);
      return sum + diffInHours;
    }
    return sum;
  }, 0);
}

export function processMaintenanceData(data: any[], timeframe = 'monthly') {
  if (timeframe !== 'weekly' && timeframe !== 'monthly') {
    throw new Error("Timeframe must be either 'weekly' or 'monthly'");
  }
  const categories = [
    ...new Set(data.map((item) => item.components_category)),
  ].filter((category) => category);

  const groupedData: Record<string, any> = {};

  data.forEach(
    (item: { date_of_orders: string; components_category: string }) => {
      if (!item.date_of_orders) return;

      const date: Date = new Date(item.date_of_orders);

      if (isNaN(date.getTime())) return;

      let timeKey: any, sortKey: any;
      if (timeframe === 'weekly') {
        const firstDayOfYear: Date = new Date(date.getFullYear(), 0, 1);
        // @ts-expect-error Date type calculation
        const pastDaysOfYear: number = (date - firstDayOfYear) / 86400000;
        const weekNumber = Math.ceil(
          (pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7
        );
        timeKey = `Week ${weekNumber}`;
        sortKey = `${date.getFullYear()}-${weekNumber
          .toString()
          .padStart(2, '0')}`;
      } else {
        const monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        timeKey = monthNames[date.getMonth()];
        sortKey = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}`;
      }

      if (!groupedData[sortKey]) {
        groupedData[sortKey] = {
          timeLabel: timeKey,
          sortKey: sortKey,
        };
        categories.forEach((cat) => {
          groupedData[sortKey][cat] = 0;
        });
      }

      if (item.components_category) {
        groupedData[sortKey][item.components_category] =
          (Number(groupedData[sortKey][item.components_category]) || 0) + 1;
      }
    }
  );
  const chartData = Object.values(groupedData);

  chartData.sort((a: any, b: any) => a.sortKey.localeCompare(b.sortKey));

  return {
    data: chartData,
    categories: categories,
  };
}

export function getCategoryStats(data: any) {
  return Object.values(
    data.reduce((acc: any, item: any) => {
      const { components_category: category, total_cost: totalCost } = item;

      if (!acc[category]) {
        acc[category] = { category, count: 0, cost: 0 };
      }

      acc[category].count += 1;
      acc[category].cost += Number(totalCost) || 0;

      return acc;
    }, [])
  );
}

export function getUniqueComponentCounts(data: any) {
  const counts: any = {};

  data.forEach((item: any) => {
    const key = `${
      item.components_category
    } - ${item.sub_component_categories.replace(/\[|\]/g, '')}`;
    counts[key] = (counts[key] || 0) + 1;
  });

  return Object.entries(counts).map(([key, count]) => ({
    category: key,
    count: count,
  }));
}

export function getAssetsReplacementCount(data: any) {
  return Object.values(
    data.reduce((acc: any, item: any) => {
      const {
        asset_id: assetId,
        total_cost: totalCost,
        date_of_orders: dateOfOrder,
        date_of_completion: dateOfCompletion,
      } = item;

      if (!acc[assetId]) {
        acc[assetId] = { assetId, count: 0, totalCost: 0, downtimeHours: 0 };
      }

      acc[assetId].count += 1;
      acc[assetId].totalCost += totalCost || 0;

      if (dateOfOrder && dateOfCompletion) {
        const diffInMs =
          new Date(dateOfCompletion).getTime() -
          new Date(dateOfOrder).getTime();
        acc[assetId].downtimeHours +=
          diffInMs > 0 ? diffInMs / (1000 * 60 * 60) : 0; // Convert milliseconds to hours
      }

      return acc;
    }, {})
  );
}

export function calculateAssetPartsStats(
  data: any[],
  selectedAsset: string[],
  selectedPart: string[]
) {
  const dataByAssetAndParts = data.filter(
    (item) =>
      selectedAsset.includes(item.asset_id) &&
      selectedPart.includes(item.components_category)
  );

  const replacementData = dataByAssetAndParts.filter(
    (item) => item.actions_performed === 'REPLACE'
  );

  return {
    woNumber: dataByAssetAndParts.length,
    partsReplaced: replacementData.length,
    downtimeHours: sumTimeDifferenceInHours(replacementData) || 0,
    downtimeCost: truncateTo2dp(
      replacementData.reduce(
        (sum: number, item: { total_cost: number }) =>
          sum + (item.total_cost || 0),
        0
      )
    ),
  };
}

export function percentageDifference(base: number, compare: number) {
  if (base === 0) return compare === 0 ? 0 : -100;

  const diff = ((compare - base) / base) * 100;
  return diff;
}

export function getStatColor(value: number, inverse: boolean) {
  if (!inverse) {
    return value > 0 ? '#009161' : '#EB5757';
  } else {
    return value > 0 ? '#EB5757' : '#009161';
  }
}

export function mergeAttributesByTimeLabel(arg: any) {
  const { data } = arg;
  return data.map((item: any) => {
    const { timeLabel, sortKey, ...rest } = item;
    const value = Object.values(rest).reduce(
      (sum: number, num: any) => sum + num,
      0
    );
    return { timeLabel, sortKey, value };
  });
}
