import { useEffect, useMemo, useState } from 'react';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import Header from './Components/Header';
import SurvivalAnalysisOverviewHandler from 'handlers/survivalAnalysisHandlers/survivalAnalysisOverview.handler';
import AssetTable from './Components/AssetTable';
import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import { type ServiceView } from 'types/survivalAnalysis.types';
import OverviewModalDetails from 'views/SurvivalAnalysis/Overview/modals/Overview-modal-details';
import {
  processAssetsWithLiveData,
  processDataAssets,
} from 'views/SurvivalAnalysis/Helper/Function/asset.helper.function';
import { selectLatestTagData } from 'store/device.slice';
import { selectNotificationsData } from 'store/notification.slice';
import { type HealthScore } from 'types/squares.type';
import { useFetchPmVsCmVsModification } from '../CostAnalysis/MaintenanceComparison/Helper/fetchData';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';
import { UploadWorkOrdersView } from '../UploadWorkOrders/UploadWorkOrdersView';
import { formatToUSD } from 'views/SurvivalAnalysis/Helper/Function/helper.function';
import { AssetsViewV2 } from '../CostAnalysis/Assets/AssetsViewV2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PartsViewV2 } from '../CostAnalysis/Parts/PartsViewV2';
import { MaintenanceComparisonView } from '../CostAnalysis/MaintenanceComparison/MaintenanceComparisonView';
import { SquaresPerformanceMetrics } from 'utils/enums';
import { productionLabels } from 'utils/helpers/labels';
import { convertMetricTonsToImperialTons } from 'utils/helpers/unitSystem';
import { calculateSavings } from './hooks/useKPI';
import dayjs from 'dayjs';
import { type MaintenanceDataInterface } from './Interfaces/KPI.interface';
import {
  calculateMaintenanceData,
  transformServiceViewToHealthScore,
} from './utils/utils';
import useCustomerInfo from 'hooks/useCustomerInfo';
import KPIsGraphs from './Components/KPIsGraphs';
import UploadWorkOrderComponent from './Components/UploadWorkOrderView';

interface AssetData {
  total_cost: number;
  total_labour_cost: number;
  total_part_cost: number;
  total_hours: string;
}

interface AssetsResponse {
  assets: Record<string, AssetData>;
  timeseries: Record<string, any>;
}

export interface PmVsCmApiResponse {
  category: 'PM' | 'CM';
  total_quantity: string;
  total_cost: number;
  total_hours: string;
}

export const DashbaordEntityManagementViewV2 = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;

  const { unitSystem } = useCustomerInfo();
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const productionUnitSystemLabel =
    unitSystem === 'metric'
      ? productionLabels.Load.metric
      : productionLabels.Load.imperial;
  const enableDarkTheme = isDarkTheme(theme);
  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );
  let initialStartDate = new Date();
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1);
  let initialEndDate = new Date();
  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedStartDate, setStartDate] = useState(initialStartDate);
  const [selectedEndDate, setEndDate] = useState(initialEndDate);
  const [selectedAsset, setSelectedAsset] = useState<ServiceView>();
  const [isUploadWorkOrder, setIsUploadWorkOrder] = useState<boolean>(false);
  const [filteredAssets, setFilteredAssets] = useState<ServiceView[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<string>(''); // Add selectedMonth state
  const [showView, setShowView] = useState<string | null>(null); // Manage which view to show
  const [totalCostSaved, setTotalCostSaved] = useState([
    {
      header: '',
      value: '0', // Initial hardcoded value
    },
  ]);
  const [totalProductivityImproved, setTotalProductivityImproved] = useState([
    {
      header: '',
      value: '0', // Initial hardcoded value
    },
  ]);
  const [totalDowntimeSaved, setTotalDowntimeSaved] = useState([
    {
      header: '',
      value: '0', // Initialize with proportional value
    },
  ]);
  const [totaltonnage, setTotaltonnage] = useState([
    {
      header: '',
      value: '0', // Default value, will be updated later
    },
  ]);
  const [totaltonnageValue, setTotaltonnageValue] = useState(0);
  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }
  const handleMonthSelect = (month: string | null) => {
    if (month) {
      setSelectedMonth(month);
    } else {
      setSelectedMonth(''); // Handle null by setting an empty string or a default value
    }
  };
  const { assets } = useAppSelector((state) => state.assetReducer);

  const latestTagData = useAppSelector(selectLatestTagData);
  const notificationsData = useAppSelector(selectNotificationsData);
  const fetchPmCmModification = useFetchPmVsCmVsModification();

  const [assetsItems, setAssetsItems] = useState<HealthScore[]>([]);
  const survivalAnalysisHandler = new SurvivalAnalysisOverviewHandler();
  const squaresHandler = new SquaresDashboardHandler();
  const { healthScoreData } = useAppSelector(
    (state) => state.squaresDashboardReducer
  );
  const [totalCosts, setTotalCosts] = useState({
    total_cost: 0,
    total_labour_cost: 0,
    total_part_cost: 0,
  });
  const [maintenanceData, setMaintenanceData] =
    useState<MaintenanceDataInterface>({
      pmTotalCost: 0,
      cmTotalCost: 0,
      totalMaintenanceCost: 0,
      pmTotalHours: 0,
      cmTotalHours: 0,
      totalMaintenanceHours: 0,
      pmTotalQuantity: 0,
      cmTotalQuantity: 0,
      totalMaintenanceQuantity: 0,
    });

  const [pmCmData, setPmCmData] = useState<PmVsCmApiResponse[]>([]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate && accessToken) {
      void squaresHandler
        .getDashboardAssetsData(selectedStartDate, selectedEndDate)
        .then((data: AssetsResponse) => {
          const aggregatedCosts = Object.values(data?.assets || {}).reduce(
            (acc, asset) => {
              acc.total_cost += asset.total_cost;
              acc.total_labour_cost += asset.total_labour_cost;
              acc.total_part_cost += asset.total_part_cost;
              return acc;
            },
            { total_cost: 0, total_labour_cost: 0, total_part_cost: 0 }
          );
          setTotalCosts(aggregatedCosts);
        })
        .catch((error) => {
          console.error('Error fetching asset data:', error);
        });

      void fetchPmCmModification(selectedStartDate, selectedEndDate)
        .then((data: any[]) => {
          setPmCmData(data);
        })
        .catch((error) => {
          console.error('Error fetching PM vs CM vs Modification data:', error);
        });
    }
  }, [selectedStartDate, selectedEndDate, accessToken]);

  useEffect(() => {
    const maintenanceData = calculateMaintenanceData({ data: pmCmData });
    setMaintenanceData(maintenanceData);
    // Calculate proportional values based on the date range

    const savings = calculateSavings({
      totalWorkOrders: maintenanceData.totalMaintenanceQuantity,
      numAssets: assetsItems.length,
      maintenanceHoursPerWO: 24, // hard coded
      manufacturerFrequency: 121, // hard coded
      applicationFrequency: 136, // hard coded as of now. needs to be dynamically calculated
      totalMaintenanceCost: totalCosts?.total_cost,
      tonsPerRound: 82.3, // hard coded
      roundsPerDay: 20, // hard coded as of now // drn = ?
      startDate: dayjs(selectedStartDate).format('YYYY-MM-DD'),
      endDate: dayjs(selectedEndDate).format('YYYY-MM-DD'),
    });

    // Update the state with the calculated values
    setTotalDowntimeSaved([
      {
        header: '',
        value: (
          savings?.dateRange?.downtimeSaved ?? savings?.annual?.downtimeSaved
        )?.toFixed(2),
      },
    ]);
    setTotalCostSaved([
      {
        header: '',
        value: `$${formatToUSD(
          savings?.dateRange?.costSaved ?? savings?.annual?.costSaved,
          'USD'
        )}`,
      },
    ]);
    setTotalProductivityImproved([
      {
        header: '',
        value: formatToUSD(
          savings?.dateRange?.productivityImproved ??
            savings?.annual?.productivityImproved,
          productionUnitSystemLabel
        ),
      },
    ]);
    const tonnageValues = calculateValueForDateRange();
    setTotaltonnageValue(
      unitSystem === 'metric'
        ? tonnageValues[0].value
        : convertMetricTonsToImperialTons(tonnageValues[0].value)
    );
    setTotaltonnage([
      {
        header: tonnageValues[0].header,
        value: `${formatToUSD(
          tonnageValues[0].value,
          productionUnitSystemLabel
        )}`,
      },
    ]);
  }, [
    pmCmData,
    SquaresPerformanceMetrics,
    assetsItems,
    totalCosts,
    productionUnitSystemLabel,
  ]);

  useEffect(() => {
    if (healthScoreData && Object.keys(healthScoreData).length > 0) {
      const assetsArray = Object.values(healthScoreData)
        .map((asset) => ({
          asset_details: asset.asset_details,
          component_details: asset.component_details,
        }))
        .filter((asset) => asset.asset_details?.health_score !== undefined);

      setAssetsItems(assetsArray);
    } else {
      console.warn('healthScoreData is empty or invalid.');
    }
  }, [healthScoreData]);

  const showDetails = (asset: string) => {
    const filterItem = filteredAssets.find(
      (assets) => assets.item_id === asset
    );
    setSelectedAsset(filterItem);
  };

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);

    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };

  const handleIsWorkOrder = (check: boolean) => {
    setIsUploadWorkOrder(check);
  };

  const fetchData = async () => {
    try {
      const promises = [];
      promises.push(survivalAnalysisHandler.getAssetsRiskAnalysis());
      promises.push(
        survivalAnalysisHandler.getCumulativeAssetsPerSubComponent()
      );
      promises.push(survivalAnalysisHandler.getEOLs());
      promises.push(squaresHandler.getHealthScoreData());
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      void fetchData();
    }
  }, [accessToken]);
  const survivalAnaysisOverview = useAppSelector(
    (state) => state.survivalAnaysisOverview
  );

  useEffect(() => {
    const assetWithLiveData = processAssetsWithLiveData(
      assets,
      latestTagData,
      notificationsData
    );
    const processedData = processDataAssets(
      survivalAnaysisOverview.assets,
      assetWithLiveData,
      survivalAnaysisOverview.eol
    );
    setFilteredAssets(processedData);
  }, [survivalAnaysisOverview.assets]);

  const handleModalSuccess = async () => {
    if (!accessToken) {
      await fetchData();
    }
  };

  function calculateValueForDateRange() {
    const startDate = selectedStartDate;
    const endDate = selectedEndDate;

    // Check if startDate or endDate is null, undefined, or not a valid Date object
    if (!startDate || !endDate) {
      console.error('Start date or end date is invalid:', {
        startDate,
        endDate,
      });
      return [
        {
          header: '',
          value: 0, // Return '0' as a string or a default placeholder
        },
      ];
    }

    // Ensure startDate and endDate are valid Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if the date parsing was successful
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
      console.error('Failed to parse start date or end date:', { start, end });
      return [
        {
          header: '',
          value: 0, // Return '0' as a string or a default placeholder
        },
      ];
    }

    // Calculate the number of days between startDate and endDate
    const numOfDaysBetween =
      (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

    // If numOfDaysBetween is negative or zero, handle that scenario
    if (numOfDaysBetween <= 0) {
      console.warn(
        'Invalid date range: End date is before or same as start date.',
        { start, end }
      );
      return [
        {
          header: '',
          value: 0, // Return '0' as a string or a default placeholder
        },
      ];
    }

    // Calculate the proportional value based on the proportion of the year
    const proportionalValue = 82.3 * 20 * numOfDaysBetween; // Replace with the appropriate calculation for your context

    // Return the result in the format expected by <KPICard>
    return [
      {
        header: '',
        value: proportionalValue, // Ensure value is formatted as a string
      },
    ];
  }

  const healthScoreDataForAssetsTable = useMemo(() => {
    return transformServiceViewToHealthScore(filteredAssets, assetsItems);
  }, [filteredAssets, assetsItems]);

  return (
    <>
      {showView && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: 2,
            }}
          >
            <IconButton
              onClick={() => {
                setShowView(null);
              }} // Hide the current view
              sx={{
                marginBottom: 2,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              <ArrowBackIcon /> Return to Dashboard
            </IconButton>
          </Box>

          {/* Display specific views based on the clicked KPI card */}

          {showView === 'totalMaintenanceCost' && <AssetsViewV2 />}
          {showView === 'partsReplacement' && <PartsViewV2 />}
          {showView === 'workOrderCount' && <MaintenanceComparisonView />}
          {showView === 'maintenanceHours' && <MaintenanceComparisonView />}
        </>
      )}
      {isUploadWorkOrder && (
        <UploadWorkOrderComponent
          assetsItems={assetsItems}
          handleIsWorkOrder={handleIsWorkOrder}
          handleMonthSelect={handleMonthSelect}
          isUploadWorkOrder={isUploadWorkOrder}
        />
      )}
      {selectedAsset && (
        <OverviewModalDetails
          displayItems={selectedAsset}
          uniqueAssets={filteredAssets}
          onShowDetails={showDetails}
          detailType="Assets"
          handleModalSuccess={handleModalSuccess}
        />
      )}
      {!showView && !isUploadWorkOrder && !selectedAsset && (
        <Grid container spacing={1} marginTop={1}>
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              alignItems="center"
              marginBottom={1}
            >
              <Typography
                sx={{
                  paddingRight: '5px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Date Range{' '}
                <DatePicker
                  showIcon
                  selected={selectedStartDate}
                  onChange={handleDateChange}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  maxDate={new Date()}
                  selectsRange
                  className="custom-datepicker"
                />
              </Typography>
            </Box>
            <KPIsGraphs
              assetsItems={assetsItems}
              setShowView={setShowView}
              maintenanceData={maintenanceData}
              totalCostSaved={totalCostSaved}
              totalCosts={totalCosts}
              totalDowntimeSaved={totalDowntimeSaved}
              totalProductivityImproved={totalProductivityImproved}
              totaltonnage={totaltonnage}
              totaltonnageValue={totaltonnageValue}
            />
          </Grid>

          <Divider orientation="vertical" flexItem sx={{ marginX: 2 }} />

          <Grid item xs={12} md={9}>
            <Header
              allAssets={assetsItems}
              handleIsWorkOrder={handleIsWorkOrder}
              isUploadWorkOrder={isUploadWorkOrder}
              onMonthSelect={handleMonthSelect} // Pass onMonthSelect to Header
            />
            <AssetTable
              assets={healthScoreDataForAssetsTable}
              assetsDetails={survivalAnaysisOverview.assets}
              assetsPerSubComponent={survivalAnaysisOverview.cumulative}
              filteredAssets={filteredAssets}
              enableDarkTheme={enableDarkTheme}
              onShowDetails={showDetails}
              selectedMonth={selectedMonth} // Pass selectedMonth to AssetTable
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
