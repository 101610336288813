import { Grid, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { useEffect } from 'react';
import { getDataUnit } from './utils';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  isLoading: boolean;
  unitSystem: string;
  data: {
    loaded: number;
    unloaded: number;
    loading: number;
    unloading: number;
  };
  setHoursBreakDown: any;
}

const HaulerFuelConsumption = ({
  enableDarkTheme,
  title,
  isLoading,
  unitSystem,
  data,
  setHoursBreakDown,
}: Props) => {
  const dataValue = [
    { name: 'Loaded Trip', value: data.loaded, fill: '#FFA403' }, // Orange
    { name: 'Unloaded Trip', value: data.unloaded, fill: '#497BFA' }, // Blue
    { name: 'Loading', value: data.loading, fill: '#EB5757' }, // Red
    { name: 'Unloading', value: data.unloading, fill: '#029161' }, // Green
  ];

  const allData = {
    Unloading: `${data.unloading} ${getDataUnit('fuel', unitSystem)}`,
    Loading: `${data.loading} ${getDataUnit('fuel', unitSystem)}`,
    'Unloaded Trip': `${data.unloaded} ${getDataUnit('fuel', unitSystem)}`,
    'Loaded Trip': `${data.loaded} ${getDataUnit('fuel', unitSystem)}`,
  };

  useEffect(() => {
    setHoursBreakDown(allData);
  }, [data]);

  const totalValue = dataValue.reduce((sum, item) => sum + item.value, 0);

  return (
    <Grid item lg={6} md={6}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          overflowY: 'auto', // Enable vertical scrolling
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            fontWeight: '600',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            paddingTop: '10px',
          }}
        >
          {isLoading ? (
            <MultiColorCircularLoader height="300px" />
          ) : (
            <Box
              sx={{
                marginTop: '10px',
              }}
            >
              <FlexBox
                sx={{
                  maxWidth: 'inherit',
                  height: '230px',
                  paddingTop: '20px',
                  marginRight: '10px',
                  paddingBottom: '10px',
                }}
              >
                {totalValue > 0 ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      layout="vertical"
                      data={dataValue}
                      margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
                      barSize={30}
                    >
                      <CartesianGrid
                        strokeDasharray="3 3"
                        stroke={ThemePalette.dark.cartesianGrid}
                        vertical={false}
                      />
                      <XAxis
                        type="number"
                        tick={{ fill: '#8884d8' }}
                        label={{
                          value: `Fuel Consumed(${
                            unitSystem === 'imperial' ? 'gallons' : 'liters'
                          })`,
                          fontSize: 10,
                          position: 'bottom',
                          offset: 0,
                        }}
                      />
                      <YAxis
                        type="category"
                        dataKey="name"
                        tick={{
                          fill: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                          fontSize: '11px',
                        }}
                      />
                      <Tooltip
                        cursor={{
                          fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC',
                        }}
                        wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                        contentStyle={{
                          backgroundColor: 'transparent',
                        }}
                        itemStyle={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                        }}
                      />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.lightGrey
                        : ThemePalette.typography.black,
                    }}
                  >
                    No data available currently
                  </Typography>
                )}
              </FlexBox>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default HaulerFuelConsumption;
