import { Box, Pagination, Typography } from '@mui/material';
import { type ApexOptions } from 'apexcharts';
import { ThemePalette } from 'mui.theme';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useAppSelector } from 'store/hook';
import { type DriverBehaviorData } from 'types/driver.behavior.types';
import { allEvents } from 'utils/driverBehavior';
import { isDarkTheme } from 'utils/theme';
import { type DriverBehaviourWorkerInput } from 'views/DriverBehavior/workers/worker';
import { tooltip } from 'views/EngineHours/Components/tooltip';
import DriverBehaviourFilters from './DriverBehaviourFilters';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import { WarningAmberRounded } from '@mui/icons-material';
import dayjs from 'dayjs';
import NoDataComponent from './NoDataComponent';

interface Props {
  timelineDate: string;
  selectedAssets: string[];
  setTimeLineAssets: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface TimelineChartDataPointInstance {
  x: string;
  y: [number, number];
}

export interface TimelineChartDataInstance {
  data: TimelineChartDataPointInstance[];
  name: string;
}

export interface WorkerResult {
  result: TimelineChartDataInstance[];
  uniqueMachinesListInOrderOfAppearance: string[];
}

const DriverBehaviourTimeline = ({
  timelineDate,
  selectedAssets,
  setTimeLineAssets,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = useMemo(() => isDarkTheme(theme), [theme]);

  const { assets } = useAppSelector((state) => state.assetReducer);
  const {
    data,
    error: { timelineError },
  } = useAppSelector((state) => state.driverBehaviorReducer);

  const uniqueAssets = useMemo(() => {
    return Array.from(new Set(assets.map(({ bumperNumber }) => bumperNumber)));
  }, [assets]);

  const { startTimelineDateMs, endTimelineDateMs } = useMemo(() => {
    const startTimelineDate = new Date(`${timelineDate} 00:00`);
    const endTimelineDate = new Date(`${timelineDate} 23:59`);
    return {
      startTimelineDateMs: startTimelineDate.getTime(),
      endTimelineDateMs: endTimelineDate.getTime(),
    };
  }, [timelineDate]);

  const [worker, setWorker] = useState<Worker | null>(null);

  const [result, setResult] = useState<TimelineChartDataInstance[]>([]);

  const [loading, setLoading] = useState(false);

  const [
    uniqueMachinesListInOrderOfAppearance,
    setUniqueMachinesListInOrderOfAppearance,
  ] = useState<string[]>([]);

  const [machinesPerPage] = useState(5);
  const pages = useMemo(() => {
    return Math.ceil(
      uniqueMachinesListInOrderOfAppearance.length / machinesPerPage
    );
  }, [machinesPerPage, uniqueMachinesListInOrderOfAppearance]);

  const [currentPage, setCurrentPage] = useState(1);

  const paginatedMachineNamesInCurrentPage = useMemo(() => {
    return new Set(
      uniqueMachinesListInOrderOfAppearance.slice(
        (currentPage - 1) * machinesPerPage,
        currentPage * machinesPerPage
      )
    );
  }, [machinesPerPage, uniqueMachinesListInOrderOfAppearance, currentPage]);

  const paginateResult = useCallback(() => {
    try {
      setLoading(true);
      const resultToReturn = result?.map((item) => {
        return {
          ...item,
          data: item?.data?.filter((dataPoint) =>
            paginatedMachineNamesInCurrentPage.has(dataPoint.x)
          ),
        };
      });

      return resultToReturn;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    }
  }, [result, paginatedMachineNamesInCurrentPage]);

  const paginatedData = useMemo(() => {
    return paginateResult();
  }, [paginateResult]);

  const noDataAvailable = useMemo(() => {
    let missingItems = 0;
    if (!paginatedData) return false;
    for (const eventData of paginatedData) {
      if (eventData?.data?.length === 0) {
        missingItems++;
      }
    }
    return missingItems === paginatedData?.length;
  }, [paginatedData]);

  const chartOptions = useMemo<ApexOptions>(() => {
    return {
      chart: {
        events: {
          beforeMount: (chartContext, config) => {
            setLoading(true);
          },
          mounted: (chartContext, config) => {
            setLoading(false);
          },
        },
        animations: {
          enabled: false,
        },
        height: 950,
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        custom: (props) =>
          tooltip({
            ...props,
            unitSystem,
          }),
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '80%',
          borderRadius: 4,
        },
      },
      colors: [
        '#ffed54',
        '#3bb871',
        '#4bb0d1',
        '#fa9600',
        '#9b225a',
        '#555555',
        '#5e56a2',
      ],
      xaxis: {
        type: 'datetime',
        position: 'top',
        labels: {
          datetimeUTC: false,
          style: {
            colors: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          },
          formatter: function (value) {
            // const asset = assets.find(
            //   (item) => item.bumperNumber === value.toString()
            // );
            // if (asset) {
            //   return `${value}\n${
            //     ASSET_TYPES.find((item) => item.id === asset?.assetType)
            //       ?.display ?? ''
            //   }`;
            // }
            return `${value}`;
          },
        },
      },
      stroke: {
        width: 1,
      },
      fill: {
        type: 'solid',
        opacity: 0.6,
      },
      legend: {
        position: 'top',
        labels: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
      },
    };
  }, [enableDarkTheme, unitSystem]);

  useEffect(() => {
    const workerInstance = new Worker(
      new URL('../../workers/worker.ts', import.meta.url)
    );
    setWorker(workerInstance);

    // listen for messages from the worker
    workerInstance.onmessage = (e) => {
      const parsedData = JSON.parse(e.data) as WorkerResult;
      if (parsedData?.result?.length === 0) {
        return;
      } else {
        setResult(parsedData?.result);
        setUniqueMachinesListInOrderOfAppearance(
          parsedData?.uniqueMachinesListInOrderOfAppearance
        );
      }

      setLoading(false);
    };

    // cleanup worker when component unmounts
    return () => {
      workerInstance.terminate();
      console.log('worker terminated');
    };
  }, []);

  const loadChartData = useCallback(() => {
    if (!data) {
      console.log('no data');
      return;
    }
    if (worker) {
      setLoading(true);
      let dataToPost = data;
      if (selectedAssets && selectedAssets.length > 0) {
        const filteredData: DriverBehaviorData = {
          acceleration: [],
          braking: [],
          incorrect_gear: [],
          incorrect_gear_shift: [],
          gear_skip: [],
          short_idling: [],
          excess_idling: [],
          overspeed: [],
          underspeed: [],
          productive: [],
          engine_hours: [],
        };
        allEvents.forEach((event) => {
          const key = event.key as keyof DriverBehaviorData;
          filteredData[key] =
            data?.[key]?.filter(
              ({ bumper_id: bumperId }) =>
                bumperId && selectedAssets.includes(bumperId)
            ) || [];
        });
        dataToPost = filteredData;
      }
      const workerInput: DriverBehaviourWorkerInput = {
        data: dataToPost,
        timelineDate,
        startTimelineDateMs,
        endTimelineDateMs,
      };
      const jsonData = JSON.stringify(workerInput);
      worker.postMessage(jsonData);
    } else {
      console.log('no worker');
    }
  }, [
    data,
    worker,
    timelineDate,
    startTimelineDateMs,
    endTimelineDateMs,
    selectedAssets,
  ]);

  useEffect(() => {
    if (worker) {
      loadChartData();
    }
  }, [
    worker,
    data,
    timelineDate,
    startTimelineDateMs,
    endTimelineDateMs,
    selectedAssets,
  ]);

  return (
    <Box
      sx={{
        height: '590px',
        backgroundColor: enableDarkTheme ? '#1E2A47' : '#fff',
        padding: '10px',
        borderRadius: '5px',
        position: 'relative',
      }}
    >
      {timelineError ? (
        <div
          style={{
            position: 'absolute',
            borderRadius: '5px',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            zIndex: 1,
          }}
        >
          <WarningAmberRounded />
          <p>{timelineError}</p>
        </div>
      ) : (
        <>
          <DriverBehaviourFilters
            setTimeLineAssets={setTimeLineAssets}
            setCurrentPage={setCurrentPage}
            selectedAssets={selectedAssets}
            uniqueAssets={uniqueAssets}
            isLoading={loading}
          />
          {timelineDate && (
            <Typography
              sx={{
                color: enableDarkTheme ? '#fff' : '#000',
                margin: '10px',
                fontSize: '14px',
              }}
            >
              {loading ? 'Loading' : 'Showing'} data for{' '}
              <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {dayjs(timelineDate).format('DD-MM-YYYY')}{' '}
              </span>
            </Typography>
          )}
          <Box
            height={'500px'}
            width={'99%'}
            sx={{
              backgroundColor: enableDarkTheme
                ? '#1E2A47'
                : loading
                ? '#152642'
                : '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px',
              borderRadius: '5px',
            }}
          >
            {loading ? (
              <SingleFadeLoader
                isFullScreen={false}
                // color={enableDarkTheme ? 'secondary' : 'primary'}
                // size={50}
              />
            ) : (
              <Box sx={{ height: '100%', width: '100%' }}>
                {noDataAvailable ? (
                  <NoDataComponent />
                ) : (
                  <ReactApexChart
                    options={chartOptions}
                    series={paginatedData}
                    type="rangeBar"
                    height={500}
                    width={'100%'}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box>
            {pages > 1 && (
              <Pagination
                count={pages}
                page={currentPage}
                onChange={(e, page) => {
                  setCurrentPage(page);
                }}
                color="primary"
                sx={{
                  color: enableDarkTheme ? '#fff' : '#000',
                  '& .MuiPaginationItem-root': {
                    color: enableDarkTheme ? '#fff' : '#000',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    color: enableDarkTheme ? '#fff' : '#fff',
                  },
                }}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default DriverBehaviourTimeline;
