import type { Asset } from 'types/asset.types';
import { ListViewItem } from './ListView/ListItem';
import type { DataEntry, Data, HistogramData } from 'types/FleetOverview.types';
import { LinearProgress } from '@mui/material';

export const ListView = ({
  enableDarkTheme,
  assets,
  metrics,
  itemLoading,
  thresholds,
  defaultThresholds,
}: {
  enableDarkTheme: boolean;
  assets: Asset[];
  metrics: Data;
  itemLoading?: boolean;
  thresholds: any[];
  defaultThresholds: any[];
}) => {
  return itemLoading ? (
    <LinearProgress />
  ) : (
    <>
      {Object.keys(metrics).map((key) => {
        const value = (metrics as any)[key] as {
          data: DataEntry[];
          histogram: HistogramData[];
          unit?: string;
        };

        return (
          <ListViewItem
            enableDarkTheme={enableDarkTheme}
            title={key}
            data={value}
            key={key}
            assets={assets}
            thresholds={thresholds}
            defaultThresholds={defaultThresholds}
          />
        );
      })}
    </>
  );
};
