import { createSlice } from '@reduxjs/toolkit';
import type {
  utilizationLoadingStates,
  utilizationFleetData,
} from 'types/utilization.types';

export interface utilizationState {
  data: {
    allFleet: utilizationFleetData;
  };
  loadingStates: utilizationLoadingStates;
}

const initialState: utilizationState = {
  data: {
    allFleet: {
      summary: {
        fleet_avg_utilization_engine_by_shift_hours: 0,
        hauler_avg_utilization_engine_by_shift_hours: 0,
        loader_avg_utilization_engine_by_shift_hours: 0,
        fleet_total_engine_working_minutes: 0,
        hauler_total_engine_working_minutes: 0,
        loader_total_engine_working_minutes: 0,
        fleet_total_idling_minutes: 0,
        hauler_total_idling_minutes: 0,
        loader_total_idling_minutes: 0,
        fleet_total_productive_fuel_consumption_l: 0,
        hauler_total_productive_fuel_consumption_l: 0,
        loader_total_productive_fuel_consumption_l: 0,
        fleet_total_idle_fuel_consumption_l: 0,
        hauler_total_idle_fuel_consumption_l: 0,
        loader_total_idle_fuel_consumption_l: 0,
        hauler_total_engine_working_minutes_diff: 0,
        loader_total_engine_working_minutes_diff: 0,
        fleet_avg_utilization_engine_by_shift_hours_diff: 0,
        hauler_avg_utilization_engine_by_shift_hours_diff: 0,
        loader_avg_utilization_engine_by_shift_hours_diff: 0,
        fleet_total_productive_fuel_consumption_l_diff: 0,
        hauler_total_productive_fuel_consumption_l_diff: 0,
        loader_total_productive_fuel_consumption_l_diff: 0,
        fleet_total_idle_fuel_consumption_l_diff: 0,
        hauler_total_idle_fuel_consumption_l_diff: 0,
        loader_total_idle_fuel_consumption_l_diff: 0,
        hauler_total_idling_minutes_diff: 0,
        loader_total_idling_minutes_diff: 0,
        fleet_avg_utilization_engine_active_minute: 0,
        fleet_avg_utilization_engine_working_minute: 0,
        fleet_avg_utilization_by_whole_day: 0,
        fleet_avg_fuel_consumption_l: 0,
        fleet_avg_idling_duration: 0,
        fleet_avg_idling_excess_idle: 0,
        fleet_avg_idling_average_rpm: 0,
        fleet_avg_idling_average_fuel_rate: 0,
        hauler_sum_utilization_engine_working_minute_diff: 0,
        hauler_avg_utilization_engine_active_minute: 0,
        hauler_avg_utilization_engine_working_minute: 0,
        hauler_avg_utilization_by_whole_day: 0,
        hauler_avg_fuel_consumption_l: 0,
        hauler_avg_idling_duration: 0,
        hauler_avg_idling_excess_idle: 0,
        hauler_avg_idling_average_rpm: 0,
        hauler_avg_idling_average_fuel_rate: 0,
        loader_avg_utilization_engine_active_minute: 0,
        loader_avg_utilization_engine_working_minute: 0,
        loader_avg_utilization_by_whole_day: 0,
        loader_sum_utilization_engine_working_minute_diff: 0,
        loader_avg_fuel_consumption_l: 0,
        loader_avg_idling_duration: 0,
        loader_avg_idling_excess_idle: 0,
        loader_avg_idling_average_rpm: 0,
        loader_avg_idling_average_fuel_rate: 0,
        fleet_avg_utilization_engine_active_minute_diff: 0,
        fleet_avg_utilization_engine_working_minute_diff: 0,
        fleet_avg_utilization_by_whole_day_diff: 0,
        fleet_avg_fuel_consumption_l_diff: 0,
        fleet_avg_idling_duration_diff: 0,
        fleet_avg_idling_excess_idle_diff: 0,
        fleet_avg_idling_average_rpm_diff: 0,
        fleet_avg_idling_average_fuel_rate_diff: 0,
        hauler_avg_utilization_engine_active_minute_diff: 0,
        hauler_avg_utilization_engine_working_minute_diff: 0,
        hauler_avg_utilization_by_whole_day_diff: 0,
        hauler_avg_fuel_consumption_l_diff: 0,
        hauler_sum_idling_duration: 0,
        hauler_avg_idling_duration_diff: 0,
        hauler_avg_idling_excess_idle_diff: 0,
        hauler_avg_idling_average_rpm_diff: 0,
        hauler_avg_idling_average_fuel_rate_diff: 0,
        loader_avg_utilization_engine_active_minute_diff: 0,
        loader_avg_utilization_engine_working_minute_diff: 0,
        loader_avg_utilization_by_whole_day_diff: 0,
        loader_avg_fuel_consumption_l_diff: 0,
        loader_sum_idling_duration: 0,
        loader_avg_idling_duration_diff: 0,
        loader_avg_idling_excess_idle_diff: 0,
        loader_avg_idling_average_rpm_diff: 0,
        loader_avg_idling_average_fuel_rate_diff: 0,
        fleetSumIdlingHours: 0,
        haulerSumIdlingHours: 0,
        loaderSumIdlingHours: 0,
        fleetSumFuelConsumption: 0,
        haulerSumFuelConsumption: 0,
        loaderSumFuelConsumption: 0,
        fleetSumWorkingMinutes: 0,
        haulerSumWorkingMinutes: 0,
        loaderSumWorkingMinutes: 0,
        fleetAvgWorkingMinutesPerDay: 0,
        haulerAvgWorkingMinutesPerDay: 0,
        loaderAvgWorkingMinutesPerDay: 0,
        fleetSumIdlingHours_diff: 0,
        haulerSumIdlingHours_diff: 0,
        loaderSumIdlingHours_diff: 0,
        fleetSumFuelConsumption_diff: 0,
        haulerSumFuelConsumption_diff: 0,
        loaderSumFuelConsumption_diff: 0,
        fleetSumWorkingMinutes_diff: 0,
        haulerSumWorkingMinutes_diff: 0,
        loaderSumWorkingMinutes_diff: 0,
        fleetIdleSumFuelConsumption: 0,
        haulerIdleSumFuelConsumption: 0,
        loaderIdleSumFuelConsumption: 0,
        fleetIdleSumFuelConsumption_diff: 0,
        haulerIdleSumFuelConsumption_diff: 0,
        loaderIdleSumFuelConsumption_diff: 0,
        number_of_fleet: 0,
        number_of_haulers: 0,
        number_of_loaders: 0,
        date_range: 1,
        fleet_total_engine_active_minutes: 0,
        fleet_total_shift_hours: 0,
        hauler_total_engine_active_minutes: 0,
        hauler_total_shift_hours: 0,
        loader_total_engine_active_minutes: 0,
        loader_total_shift_hours: 0,
        hauler_total_engine_active_minutes_diff: 0,
        hauler_total_shift_hours_diff: 0,
        loader_total_engine_active_minutes_diff: 0,
        loader_total_shift_hours_diff: 0,
      },
      summaryChart: {
        fleet: [],
        loaders: [],
        haulers: [],
      },
      score: {
        date: '',
        asset_type: '',
        customer: '',
        util_score: 0,
        dashboard_color: '',
      },
      fleetPeriodSummary: {
        fleet: [],
        haulers: [],
        loaders: [],
      },
      assets: [],
      fleetDetails: {
        fleet: {
          total_engine_active_minutes: '0',
          total_engine_working_minutes: '0',
          daily_average_active_minutes: '0',
          daily_average_working_minutes: '0',
          number_of_assets: '0',
          numOfDays: 0,
          rollingDailyAverageFromChart: 0,
        },
        haulers: {
          total_engine_active_minutes: '0',
          total_engine_working_minutes: '0',
          daily_average_active_minutes: '0',
          daily_average_working_minutes: '0',
          number_of_assets: '0',
          numOfDays: 0,
        },
        loaders: {
          total_engine_active_minutes: '0',
          total_engine_working_minutes: '0',
          daily_average_active_minutes: '0',
          daily_average_working_minutes: '0',
          number_of_assets: '0',
          numOfDays: 0,
        },
      },
    },
  },
  loadingStates: {
    isLoadingFleetPeriodSummary: true,
    isLoadingFleetAssets: true,
    isLoadingFleetSummary: true,
    isLoadingFleetDetails: true,
    isLoadingSuggestions: true,
  },
};

export const utilizationSlice = createSlice({
  name: 'utilization',
  initialState,
  reducers: {
    setFleetSummary(state, { payload }) {
      state.data.allFleet.summary = {
        ...state.data.allFleet.summary,
        ...payload.summary,
      };
      state.data.allFleet.score = {
        ...state.data.allFleet.score,
        ...(payload.score ? payload.score : { date: '', prod_score: 0 }),
      };
      state.data.allFleet.summaryChart = payload.chartData;
    },
    setFleetPeriodSummary(state, { payload }) {
      state.data.allFleet.fleetPeriodSummary = payload;
    },
    setTotalFleetAssets(state, { payload }) {
      state.data.allFleet.assets = payload;
    },
    setFleetDetails(state, { payload }) {
      const { fleet, haulers, loaders, numOfDays } = payload;
      state.data.allFleet.fleetDetails.fleet = {
        ...fleet[0],
        numOfDays,
      };
      state.data.allFleet.fleetDetails.haulers = {
        ...haulers[0],
        numOfDays,
      };
      state.data.allFleet.fleetDetails.loaders = {
        ...loaders[0],
        numOfDays,
      };
      // add update for rollingDailyAverageFromChart
    },
    setIsLoading(state, { payload }) {
      state.loadingStates = {
        ...state.loadingStates,
        ...payload,
      };
    },
    resetState(state, { payload }) {
      state.loadingStates = payload;
      state.data = initialState.data;
    },
  },
});

export const {
  setFleetSummary,
  setIsLoading,
  setFleetPeriodSummary,
  setTotalFleetAssets,
  setFleetDetails,
  resetState,
} = utilizationSlice.actions;
export default utilizationSlice.reducer;
