import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  Cell,
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import { Box } from '@mui/system';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import TableList from './TableList';
import { truncateTo2dp } from 'utils/helpers/general';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { convertsecondsToHours } from '../utils';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  isLoading: boolean;
  customerCode: string;
  data: {
    productiveHours: number;
    productiveHoursDiff: number;
    idling: number;
    idlingDiff: number;
    noOfAssets: number;
    dateRange: number;
    engineHours: number;
    engineHoursDiff: number;
    shiftHours: number;
    shiftHoursDiff: number;
  };
  setHoursBreakDown: any;
}

const HoursBreakdown = ({
  enableDarkTheme,
  title,
  isLoading,
  data,
  setHoursBreakDown,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const onPieClick = (data: any, index: number) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const maxHours = Number(
    truncateTo2dp(
      data.engineHours / 60 + (data.shiftHours / 60 - data.engineHours / 60)
    )
  );

  const prevProductiveHours =
    data.productiveHours / (1 + data.productiveHoursDiff / 100);
  const prevIdling = data.idling / (1 + data.idlingDiff / 100);

  const prevEngineHours = data.engineHours / (1 + data.engineHoursDiff / 100);

  const prevShiftHours = data.shiftHours / (1 + data.shiftHoursDiff / 100);

  const prevMaxHours = Number(
    truncateTo2dp(
      prevEngineHours / 60 + (prevShiftHours / 60 - prevEngineHours / 60)
    )
  );

  const maxHoursDiff =
    prevMaxHours !== 0
      ? truncateTo2dp(((maxHours - prevMaxHours) / prevMaxHours) * 100)
      : 0;

  const isNegative = (value: number) => value < 0;

  const returnAnalysis = (value: number | string) => {
    return `${truncateTo2dp(
      Math.abs(isNaN(Number(value)) ? 0 : Number(value))
    )}% ${isNegative(Number(value)) ? 'less' : 'more'}`;
  };

  const tableData = [
    {
      name: 'Engine Hours',
      time: formatNumberToShortScale(truncateTo2dp(data.engineHours / 60)),
      direction: data.engineHours > 0 ? 'up' : 'down',
      color: '#165BAA',
      fill: '#165BAA',
      maxValue: maxHours,
    },
    {
      name: 'Engine Off Hours',
      time: formatNumberToShortScale(
        truncateTo2dp(
          data.engineHours === 0 || data.shiftHours < data.engineHours
            ? 0
            : data.shiftHours / 60 - data.engineHours / 60
        )
      ),
      direction: data.shiftHours > 0 ? 'up' : 'down',
      color: '#A776FF',
      fill: '#A776FF',
      maxValue: maxHours,
    },
  ];

  const unknownState =
    data.engineHours / 60 -
    (data.productiveHours / 60 + convertsecondsToHours(data.idling));

  const dataList = {
    'Engine Hours': [
      {
        name: 'Productive Hours',
        value: Number(truncateTo2dp(data.productiveHours / 60)),
        direction: 'up',
        color: '#FF0068',
      },
      {
        name: 'Idling Hours',
        value: Number(truncateTo2dp(convertsecondsToHours(data.idling))),
        direction: 'up',
        color: '#FF9C2C',
      },
      {
        name: 'Unknown',
        value: unknownState > 0 ? truncateTo2dp(unknownState) : 0,
        direction: 'up',
        color: '#C3C3C3',
      },
    ],
    'Engine Off': [
      {
        name: 'Engine Off Hours',
        value: truncateTo2dp(
          data.engineHours === 0 || data.shiftHours < data.engineHours
            ? 0
            : data.shiftHours / 60 - data.engineHours / 60
        ),
        direction: 'down',
        color: '#A776FF',
      },
    ],
  };

  const pieData = [
    {
      name: 'Engine Hours',
      value: Number(truncateTo2dp(data.engineHours / 60)),
      direction: 'up',
      color: '#165BAA',
    },
    {
      name: 'Engine Off',
      value: Number(
        truncateTo2dp(
          data.engineHours === 0 || data.shiftHours < data.engineHours
            ? 0
            : data.shiftHours / 60 - data.engineHours / 60
        )
      ),
      direction: 'up',
      color: '#A776FF',
    },
  ];

  const allData = {
    'Productive Hours': Number(truncateTo2dp(data.productiveHours / 60)),
    'Idling Hours': Number(truncateTo2dp(convertsecondsToHours(data.idling))),
    'Total Engine Hours': Number(truncateTo2dp(data.engineHours / 60)),
    'Unknown (hrs)': unknownState > 0 ? truncateTo2dp(unknownState) : 0,
    'Engine Off Hours': truncateTo2dp(
      data.engineHours === 0 || data.shiftHours < data.engineHours
        ? 0
        : data.shiftHours / 60 - data.engineHours / 60
    ),
    'Total Shift Hours': formatNumberToShortScale(truncateTo2dp(maxHours)),
    'Difference from last period': returnAnalysis(maxHoursDiff),
  };

  useEffect(() => {
    setHoursBreakDown(allData);
  }, [allData]);

  return (
    <Grid item lg={6} md={6}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '400px',
          maxHeight: '400px',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '600',
            }}
          >
            {title}
          </Typography>
          {!isLoading && (
            <Box>
              <Typography
                sx={{
                  fontSize: '13px',
                }}
              >
                Total Shift Hours
              </Typography>
              <Typography
                sx={{
                  fontSize: '22px',
                }}
              >
                {Number(maxHoursDiff) > 0 ? (
                  <NorthEastIcon sx={{ color: '#029161', fontSize: 19 }} />
                ) : (
                  <SouthEastIcon sx={{ color: '#EB5757', fontSize: 19 }} />
                )}{' '}
                {formatNumberToShortScale(truncateTo2dp(maxHours))}
                <span
                  style={{
                    fontSize: '11px',
                  }}
                >
                  {' '}
                  Hrs
                </span>
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                <span
                  style={{
                    color: Number(maxHoursDiff) > 0 ? '#029161' : '#EB5757',
                  }}
                >
                  {returnAnalysis(maxHoursDiff)}
                </span>{' '}
                from last period
              </Typography>
            </Box>
          )}
        </Box>

        <Box>
          {isLoading ? (
            <MultiColorCircularLoader height="300px" />
          ) : (
            <>
              <TableList
                enableDarkTheme={enableDarkTheme}
                data={tableData}
                subItems={dataList}
              />
              <Box>
                <FlexBox
                  sx={{
                    maxWidth: 'inherit',
                    height: '210px',
                    paddingTop: '0px',
                    marginRight: '10px',
                    paddingBottom: '0px',
                  }}
                >
                  {data.engineHours === 0 ? (
                    <Typography
                      sx={{
                        fontSize: '12px',
                      }}
                    >
                      No chart data available for the selected time range
                    </Typography>
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart
                        width={400}
                        height={400}
                        margin={{ top: 10, right: 10, left: 5, bottom: 5 }}
                      >
                        <Pie
                          data={pieData}
                          dataKey="value"
                          cx="50%"
                          cy="50%"
                          innerRadius={50}
                          outerRadius={70}
                          onClick={(data, index) => {
                            onPieClick(data, index);
                          }}
                          // @ts-expect-error ignore to display custom shape
                          activeIndex={activeIndex}
                          activeShape={(props: any) =>
                            activeIndex !== null
                              ? renderActiveShape(props, dataList)
                              : null
                          }
                        >
                          {pieData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        <Tooltip
                          cursor={{ fill: '#1B2B4A' }}
                          wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                          contentStyle={{
                            backgroundColor: 'transparent',
                          }}
                          itemStyle={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.wheat
                              : ThemePalette.typography.black,
                          }}
                          formatter={(value: any, name, props) => {
                            return [
                              `${formatNumberToShortScale(
                                truncateTo2dp(value)
                              )} hrs`,
                              name,
                            ];
                          }}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                </FlexBox>
              </Box>
            </>
          )}
        </Box>
        {/* Add the new Typography component here for the footer text */}
        <Box
          sx={{
            marginTop: '0px',
          }}
        >
          <Typography
            sx={{
              fontSize: '10px',
              textAlign: 'right',
              color: enableDarkTheme ? '#F3F4F6' : '#2F445D',
              paddingTop: '2px',
            }}
          >
            *Assuming all assets are running all days during this time period
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default HoursBreakdown;

const renderActiveShape = (props: any, dataList: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  const selectionName: string = payload.payload.name ?? 'Engine Hours';

  const shapeData = dataList[selectionName];

  const totalChildValue = shapeData.reduce(
    (acc: number, child: any) => acc + Number(child.value),
    0
  );

  let currentAngle = startAngle;

  const childSegments = shapeData.map((child: any) => {
    const childAngleDelta: number =
      (child.value / totalChildValue) * (endAngle - startAngle);

    const childStartAngle: number = currentAngle;
    const childEndAngle = Number(currentAngle) + Number(childAngleDelta);
    currentAngle = Number(currentAngle) + Number(childAngleDelta);

    const childAngle = (childStartAngle + childEndAngle) / 2;
    const childSin = Math.sin(-RADIAN * childAngle);
    const childCos = Math.cos(-RADIAN * childAngle);
    const labelX = Number(cx) + (Number(outerRadius) + 24) * childCos;
    const labelY = Number(cy) + (Number(outerRadius) + 22) * childSin;

    return (
      <g key={child.name}>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={Number(outerRadius) + 6}
          outerRadius={Number(outerRadius) + 10}
          startAngle={childStartAngle}
          endAngle={childEndAngle}
          fill={child.color}
        />
        <text
          x={labelX}
          y={labelY}
          textAnchor="middle"
          fill={child.color}
          fontSize={8}
        >
          {`${child.value !== 0 ? child.value : ''}`}
        </text>
      </g>
    );
  });

  return (
    <g>
      <text x={cx} y={cy} textAnchor="middle" fill={fill} fontSize={8}>
        {payload.name}
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {childSegments}
    </g>
  );
};
