import dayjs from 'dayjs';
import { FlexBox } from 'components/Containers/FlexBox';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import {
  generateRandomColor,
  lineChartDefaultColorMap,
  darkThemeLineChartDefaultColorMap,
  removeLastItemFromData,
} from 'utils/helpers/general';

import type { LineChartData } from 'types/chart.types';
import { useEffect, useRef } from 'react';
import { useAppSelector } from 'store/hook';
import { selectExportFormat } from 'store/exportData.slice';
import { exportToPdf } from 'utils/exports/pdf';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import { chartToExcelForVehicleStatus } from 'utils/exports/excel';
import { Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { type RuleCondition } from 'types/notification.types';
import { selectHourlyOrDailyObject } from 'utils/charts/tags';
import { formatYAxis } from 'views/SensorAnalysisView/SensorAnalysisView/Content/Chart/Individual/Chart';

interface Props {
  data: LineChartData[];
  xAxis: string;
  exportTo?: boolean;
  handleExport: (val: boolean) => void;
  assetBumperNumber: string;
  selectedRange: string;
  device: string;
  avgVal: string;
  enableDarkTheme: boolean;
  xAxisTimeFormat?: string;
  domainValue?: [boolean, string];
  notificationConditions?: RuleCondition[];
}

const LineChartComponent = ({
  data,
  xAxis,
  exportTo,
  handleExport,
  assetBumperNumber,
  selectedRange,
  device,
  avgVal,
  enableDarkTheme,
  xAxisTimeFormat = 'YYYY MMM DD',
  domainValue,
  notificationConditions,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const uniqueUnitLabels = filterUniqueLabels(data, enableDarkTheme);
  const chartRef = useRef<any>(null);
  const { tagChartData, tags } = useAppSelector((state) => state.tagReducer);

  const dataValues =
    Array.isArray(data) && data.length <= 0
      ? []
      : data.flatMap((item) => {
          return Array.isArray(item.data)
            ? item?.data?.map((dataItem) => dataItem.value)
            : [];
        });
  const minValue = Math.min(...dataValues);
  const maxValue = Math.max(...dataValues);

  const tagsWithNoData = () => {
    const getDataWithEmptyDetails: Array<{ tagName: string; tagDetails: [] }> =
      tagChartData.filter(
        (tag: { tagName: string; tagDetails: []; isValidRange: boolean }) => {
          const tagsDetails = selectHourlyOrDailyObject(
            tag.tagDetails,
            selectedRange
          );
          return tagsDetails.length < 1 || !tag.isValidRange;
        }
      );
    // get reqd day range, get latest ts per tag, check if they fit into the selected day range
    const chartDataList: any[] = [];
    tagChartData.map((data: any) => {
      const tagDetails = selectHourlyOrDailyObject(
        data.tagDetails,
        selectedRange
      );
      const dataTagName = data.tagName;
      const dataTs: [number] = [0];
      tagDetails.map((detail: any) => dataTs.push(detail.ts));
      const maxTs = Math.max(...dataTs);
      const newObj: any = {};
      newObj[dataTagName] = maxTs;
      return chartDataList.push(newObj);
    });

    const tagsWithNoDataResponse: Array<{ tagName: string; tagDetails: [] }> =
      [];
    let unixMsBySelectedRange = 0;

    const getUnixMsBySelectedRange = (dateRange: number) => {
      const d = new Date();
      d.setDate(d.getDate() - dateRange);
      return new Date(d).getTime();
    };
    chartDataList.map((item) => {
      const itemObj = Object.entries(item)[0];
      const itemTs: number = itemObj[1] as number;
      const tagExists = getDataWithEmptyDetails.some(
        (item) => item.tagName === itemObj[0]
      );
      const noDataTags = (msRange: number) => {
        if (itemTs < msRange && !tagExists) {
          tagsWithNoDataResponse.push({
            tagName: itemObj[0],
            tagDetails: [],
          });
        } else {
          tagsWithNoDataResponse.push(...getDataWithEmptyDetails);
        }
      };
      switch (selectedRange) {
        case '1D':
          unixMsBySelectedRange = getUnixMsBySelectedRange(1);
          noDataTags(unixMsBySelectedRange);
          break;
        case '7D':
          unixMsBySelectedRange = getUnixMsBySelectedRange(7);
          noDataTags(unixMsBySelectedRange);
          break;
        case '14D':
          unixMsBySelectedRange = getUnixMsBySelectedRange(14);
          noDataTags(unixMsBySelectedRange);
          break;
        case '1Y':
          unixMsBySelectedRange = getUnixMsBySelectedRange(365);
          noDataTags(unixMsBySelectedRange);
          break;

        default:
          break;
      }
      return unixMsBySelectedRange;
    });

    const dateRangeMax = new Date(unixMsBySelectedRange).toDateString();
    return { getDataWithEmptyDetails, dateRangeMax };
  };

  const exportFormat = useAppSelector(selectExportFormat);

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const fileNameWithDateTime = `Vehicle Status for ${assetBumperNumber}-${formatDate(
    new Date()
  )}`;

  const labels: string[] = [];
  data.map((item) => labels.push(item.name));

  const title = `Vehicle Status for ${assetBumperNumber} ${
    selectedRange === 'ALL'
      ? '(All records)'
      : `between ${new Date().toDateString()} and ${
          tagsWithNoData().dateRangeMax
        }.`
  }`;

  useEffect(() => {
    const fetchData = async () => {
      if (exportTo && exportFormat === 'pdf') {
        await exportToPdf(
          'vehicle-status-page',
          fileNameWithDateTime,
          title,
          enableDarkTheme,
          'landscape'
        );
        handleExport(false);
      }
      if (exportTo && exportFormat === 'excel') {
        chartToExcelForVehicleStatus(
          data,
          fileNameWithDateTime,
          title,
          unitSystem
        );
        handleExport(false);
      }
    };

    void fetchData();
  }, [exportTo, handleExport, exportFormat]);

  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format(xAxisTimeFormat);
    return formattedDate;
  };

  const formatTimestampWithHour = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('YYYY MMM DD HH:mm (Z)');
    return formattedDate;
  };

  const formatValue = (value: any) => {
    if (typeof value === 'number' && value % 1 !== 0) {
      return value.toFixed(2);
    }
    return value;
  };

  const getTagAlias = (tagName: string, device: string): string => {
    const deviceTags = tags[device]?.tags || [];
    const tag = deviceTags.find((t) => t.tagName === tagName);

    return tag?.tagAlias != null ? tag.tagAlias : tagName;
  };
  const evaluateExpression = (
    value: number,
    operator: string,
    comparisonValue: number
  ) => {
    switch (operator) {
      case '>':
        return value > comparisonValue;
      case '<':
        return value < comparisonValue;
      case '>=':
        return value >= comparisonValue;
      case '<=':
        return value <= comparisonValue;
      case '=':
        return value === comparisonValue; // Use '===' for strict equality if appropriate
      case '!=':
        return value !== comparisonValue; // Use '!==' for strict inequality if appropriate
      default:
        return false;
    }
  };

  const CustomDot = (props: any) => {
    const { cx, cy, value, name } = props;
    const itemNotification = notificationConditions?.find(
      (item) => item.tagName === name
    );
    if (itemNotification) {
      const isExpression = evaluateExpression(
        +value,
        itemNotification.relationalOperator,
        itemNotification.value
      );
      if (isExpression) {
        return <circle cx={cx} cy={cy} r={2} fill={'red'} stroke="red" />;
      } else {
        return <circle cx={cx} cy={cy} r={1} fill="black" stroke="none" />;
      }
    } else {
      return <circle cx={cx} cy={cy} r={1} fill="blue" stroke="none" />;
    }
  };

  return (
    <FlexBox
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 'inherit',
        height: '650px',
        flexDirection: 'column',
        paddingTop: '20px',
        marginRight: '10px',
        paddingBottom: '10px',
      }}
      id="vehicle-status-page"
    >
      <ResponsiveContainer
        width="100%"
        height={
          tagsWithNoData().getDataWithEmptyDetails.length >= 1 ? '90%' : '100%'
        }
        ref={chartRef}
      >
        <LineChart
          width={500}
          height={400}
          margin={{
            top: 1,
            right: 100,
            left: 20,
            bottom: 140,
          }}
        >
          <CartesianGrid
            stroke={
              enableDarkTheme
                ? ThemePalette.dark.cartesianGrid
                : ThemePalette.light.cartesianGrid
            }
            strokeDasharray="3 3"
          />
          <XAxis
            dataKey={xAxis}
            domain={['auto', 'auto']} // Set x-axis domain automatically
            scale="time" // Set x-axis scale to 'time'
            type="number" // Set x-axis type to 'number'
            allowDuplicatedCategory={false}
            tickFormatter={formatTimestamp}
            angle={-65}
            textAnchor="end"
            tick={{ fontSize: 11 }}
          />
          {uniqueUnitLabels.map((dt: LineChartData, index: number) => {
            if (dt.name !== 'sym_start') {
              return (
                <YAxis
                  key={dt.name}
                  yAxisId={dt.label}
                  domain={
                    domainValue?.[0]
                      ? [minValue, maxValue + 10]
                      : ['auto', 'auto']
                  }
                  stroke={dt.color ?? generateRandomColor()} // Use the given color or generate a random one
                  label={{
                    value: `${getTagAlias(dt.label, device)}`,
                    angle: -90,
                    position: 'inside',
                    fill: enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.black,
                    dy: 10,
                    dx: 15,
                  }}
                  tickCount={8}
                  tickFormatter={formatYAxis}
                />
              );
            }
            return (
              <YAxis
                key={dt.name}
                yAxisId={dt.label}
                stroke={dt.color} // Use the given color or generate a random one
                label={{
                  value: `${dt.label}`,
                  angle: -90,
                  position: 'inside',
                  fill: enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black,
                  dy: 10,
                  dx: 15,
                }}
                tickCount={8}
                tickFormatter={
                  dt.name === 'sym_start' ? formatDateAxis : formatNormalAxis
                }
                domain={[new Date('2020-01-01').getTime(), 'auto']}
              />
            );
          })}

          <Tooltip
            labelFormatter={(value) => formatTimestampWithHour(value)}
            formatter={(value) => formatYAxis(formatValue(value))}
          />
          <Legend
            wrapperStyle={{ fontSize: '20px' }}
            layout="horizontal"
            verticalAlign="top"
            align="center"
          />
          {removeLastItemFromData(data, selectedRange).map(
            (dt: any, index: number) => {
              return (
                <Line
                  key={index}
                  yAxisId={dt.label}
                  type="monotone"
                  dataKey={avgVal}
                  data={dt.data}
                  stroke={
                    enableDarkTheme
                      ? darkThemeLineChartDefaultColorMap[index] ||
                        generateRandomColor()
                      : lineChartDefaultColorMap[index] || generateRandomColor()
                  } // Use the given color or generate a random one
                  name={getTagAlias(dt.name, device)}
                  connectNulls={false}
                  dot={<CustomDot />}
                />
              );
            }
          )}
        </LineChart>
      </ResponsiveContainer>

      <Typography
        style={{
          fontSize: '14px',
          color: ThemePalette.typography.warning,
          display:
            tagsWithNoData().getDataWithEmptyDetails.length >= 1 &&
            !notificationConditions
              ? 'block'
              : 'none',
          padding: '0 10%',
          textAlign: 'center',
        }}
      >
        <strong>
          Asset was not able to transmit value during this time period for:
        </strong>
        <br />
        {tagsWithNoData().getDataWithEmptyDetails.map(
          (tag: { tagName: string; tagDetails: [] }, index) => (
            <span key={index} style={{ margin: '5px 0', color: 'red' }}>
              {'\u00A0\u00A0*'} {getTagAlias(tag.tagName, device)}
            </span>
          )
        )}
      </Typography>
    </FlexBox>
  );
};

export default LineChartComponent;

const filterUniqueLabels = (
  data: LineChartData[],
  enableDarkTheme: boolean
) => {
  const mappedLabels = data.map((item, index) => {
    return {
      name: item.name,
      label: item.label,
      data: [],
      color: enableDarkTheme
        ? darkThemeLineChartDefaultColorMap[index]
        : lineChartDefaultColorMap[index],
    };
  });

  const uniqueLabels: any = {};
  const result = [];

  for (const item of mappedLabels) {
    if (!uniqueLabels[item.label]) {
      uniqueLabels[item.label] = true;
      result.push(item);
    }
  }

  return result;
};

function formatDateAxis(tickItem: any) {
  return dayjs(tickItem).format('YYYY-MM');
}

function formatNormalAxis(tickItem: any) {
  return tickItem;
}
