import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import { Box, Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { ThemePalette } from 'mui.theme';
import {
  aggregateCostsByMonth,
  calculateTrend,
  findHighestValue,
} from './util';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';

export const FleetProduction = ({
  enableDarkTheme,
  data,
}: {
  enableDarkTheme: boolean;
  data: any[];
}) => {
  const ytdTotalCost = data.reduce((acc: number, curr: any) => {
    return acc + Number(curr?.total_cost ?? 0);
  }, 0);
  const currentMonth = new Date().getMonth() + 1;
  const targetedAverageMonthlMaintenanceCost = 3000000 / 12;
  const averageMonthlMaintenanceCost = Number(ytdTotalCost / currentMonth);
  const aggregatedData = aggregateCostsByMonth(data);
  const chartData = calculateTrend(aggregatedData);
  const chartHightestValue = findHighestValue(chartData);
  const chartMaxValue =
    Math.max(
      targetedAverageMonthlMaintenanceCost,
      averageMonthlMaintenanceCost,
      chartHightestValue ?? 0
    ) + 1000;

  return (
    <Box
      sx={{
        marginTop: '10px',
        marginBottom: '20px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.toolBarBackground
          : ThemePalette.light.toolBarBackground,
        padding: '15px 10px 15px 10px',
        borderRadius: '5px',
        border: '1px solid #2F445D',
        minHeight: '420px',
        maxHeight: '420px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            marginTop: '12px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontWeight: 'bolder',
          }}
        >
          Monthly Maintenance Cost Distribution
        </Typography>
      </Box>
      <FlexBox
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 'inherit',
          height: '400px',
        }}
      >
        <ResponsiveContainer width="100%" height="85%">
          <ComposedChart
            data={chartData}
            margin={{ top: 10, right: 90, left: 5, bottom: 5 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={
                enableDarkTheme
                  ? ThemePalette.dark.cartesianGrid
                  : ThemePalette.light.cartesianGrid
              }
              vertical={false}
            />
            <XAxis
              dataKey="name"
              stroke={
                enableDarkTheme
                  ? ThemePalette.typography.grey
                  : ThemePalette.typography.black
              }
              tick={{
                fontSize: 10,
              }}
            />
            <YAxis
              domain={[0, chartMaxValue]}
              label={{
                value: 'Cost',
                angle: -90,
                position: 'insideLeft',
                dy: 10,
                fontSize: '12px',
              }}
              stroke={
                enableDarkTheme
                  ? ThemePalette.typography.grey
                  : ThemePalette.typography.black
              }
              tick={{
                fontSize: 10,
              }}
            />
            <ReferenceLine
              y={targetedAverageMonthlMaintenanceCost}
              stroke="#FF6B6B"
              strokeDasharray="3 3"
              label={{
                value: `Target $${formatNumberToShortScale(
                  targetedAverageMonthlMaintenanceCost
                )} /Month`,
                position: 'right',
                fontSize: '8px',
              }}
            />
            <ReferenceLine
              y={1000}
              stroke="#4B7BEC"
              strokeDasharray="3 3"
              label={{
                value: `Avg $${formatNumberToShortScale(
                  averageMonthlMaintenanceCost
                )} /Month`,
                position: 'right',
                fontSize: '8px',
              }}
            />
            <Bar
              dataKey="cost"
              fill="#4B7BEC"
              barSize={40}
              radius={[5, 5, 0, 0]}
            />
            <Line
              type="monotone"
              dataKey="trend"
              stroke={
                enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black
              }
              strokeWidth={3}
              dot={false}
              activeDot={false}
            />
            <Tooltip
              cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
              content={<CustomTooltip />}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </FlexBox>
    </Box>
  );
};

const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any;
}) => {
  if (active && payload && payload.length) {
    const { name, cost, trend } = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: 'rgba(217, 229, 247, 0.8) ',
          border: '1px solid #ccc',
          color: '#000',
          padding: '3px 5px',
          borderRadius: '4px',
        }}
      >
        <p
          style={{
            fontSize: '12px',
          }}
        >{`Month: ${name}`}</p>
        <p
          style={{
            fontSize: '12px',
          }}
        >{`Cost: ${formatNumberToShortScale(cost)}`}</p>
        <p
          style={{
            fontSize: '12px',
          }}
        >{`Trend: ${formatNumberToShortScale(trend)}`}</p>
      </div>
    );
  }
  return null;
};
