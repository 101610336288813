import {
  ButtonGroup,
  Button,
  Typography,
  Switch,
  Select,
  MenuItem,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';
import DatePicker from 'react-datepicker';
import './styles.css';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hook';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import { ShiftDetails } from '../utils';

interface Props {
  enableDarkTheme: boolean;
  ignoreYtd?: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  aggregation: string;
  setAggregation: any;
  shiftWiseAnalysis: boolean;
  setShiftWiseAnalysis: any;
  shiftNumber: number;
  setShiftNumber: any;
  customerCode: string;
  utilizationType?: string;
  setUtilizationType?: any;
}

const DateRangeDiv = ({
  enableDarkTheme,
  ignoreYtd = false,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  aggregation,
  setAggregation,
  shiftWiseAnalysis,
  setShiftWiseAnalysis,
  shiftNumber,
  setShiftNumber,
  customerCode,
  utilizationType,
  setUtilizationType,
}: Props) => {
  const { loadingStates } = useAppSelector((state) => state.utilizationReducer);
  const shiftOptions = ShiftDetails[customerCode] || ShiftDetails.others;
  const handleSelect = (id: string) => {
    setIsSelected(id);

    const today = dayjs();
    let newStartDate = startDate;
    let newEndDate = yesterday;

    switch (id) {
      case '1D':
        newStartDate = dayjs(yesterday).subtract(1, 'day').toDate();
        newEndDate = yesterday;
        break;
      case '7D':
        newStartDate = dayjs(yesterday).subtract(7, 'days').toDate();
        newEndDate = yesterday;
        break;
      case '14D':
        newStartDate = dayjs(yesterday).subtract(14, 'days').toDate();
        newEndDate = yesterday;
        break;
      case '1M':
        newStartDate = dayjs(yesterday).subtract(1, 'month').toDate();
        newEndDate = yesterday;
        break;
      case '1Y':
        newStartDate = dayjs(yesterday).subtract(1, 'year').toDate();
        newEndDate = yesterday;
        setAggregation('Monthly');
        break;
      case 'YTD':
        newStartDate = dayjs(today).startOf('year').toDate();
        newEndDate = yesterday;
        setAggregation('Monthly');
        break;
      case 'Custom':
        // Do nothing, user will select manually
        break;
      default:
        break;
    }

    // Update the startDate and endDate for non-custom ranges
    if (id !== 'Custom') {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  let dateRangeList = ['1D', '7D', '14D', '1M', '1Y', 'YTD', 'Custom'];
  if (ignoreYtd) {
    dateRangeList = dateRangeList.filter((item) => item !== 'YTD');
  }

  const rangeIsOverMonth = ['1Y', 'YTD', 'Custom'].includes(isSelected);
  return (
    <>
      <Typography
        style={{ fontFamily: "'Inter Variable', sans-serif", margin: '20px 0' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.mediumGray
                : '#3d3d3d',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontSize: '11px', marginRight: '5px' }}>
              Quick Range:
            </Typography>
            <ButtonGroup
              size="small"
              variant="outlined"
              aria-label="Basic button group"
              sx={{
                border: '1px solid #2F445D',
              }}
            >
              {dateRangeList.map((item) => {
                return (
                  <Button
                    key={item}
                    size="small"
                    disabled={loadingStates?.isLoadingFleetSummary}
                    style={{
                      fontSize: ' 11px ',
                      backgroundColor:
                        isSelected === item
                          ? '#3666EC'
                          : enableDarkTheme
                          ? ThemePalette.dark.boxBackground
                          : ThemePalette.light.toolBarBackground,
                      color: loadingStates?.isLoadingFleetSummary
                        ? ThemePalette.typography.grey
                        : enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                    onClick={() => {
                      handleSelect(item);
                    }}
                  >
                    {item}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>

          <div
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.mediumGray
                : '#3d3d3d',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography
              style={{
                fontSize: '11px',
                marginLeft: '15px',
                marginRight: '5px',
              }}
            >
              Date Range:
            </Typography>
            {aggregation === 'Monthly' ? (
              <DatePicker
                showIcon
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={yesterday}
                selectsRange
                showMonthYearPicker
                dateFormat="MMMM yyyy"
                className={
                  enableDarkTheme
                    ? 'custom-datepicker-prod'
                    : 'custom-datepicker-prod-light-theme'
                }
                filterDate={(date) => {
                  const diff = dayjs(date).diff(dayjs(startDate), 'month');
                  return diff <= 12;
                }}
                disabled={isSelected !== 'Custom'}
              />
            ) : (
              <DatePicker
                showIcon
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={yesterday}
                selectsRange
                className={
                  enableDarkTheme
                    ? 'custom-datepicker-prod'
                    : 'custom-datepicker-prod-light-theme'
                }
                disabled={isSelected !== 'Custom'}
                filterDate={(date) => {
                  const diff = dayjs(date).diff(dayjs(startDate), 'day');
                  return diff <= 365;
                }} // disabling user to select more than 1 year
              />
            )}
          </div>
          <div
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.mediumGray
                : '#3d3d3d',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginLeft: '20px',
            }}
          >
            <Select
              labelId="utilzation-type-label"
              value={utilizationType}
              disabled={loadingStates?.isLoadingFleetSummary}
              displayEmpty
              onChange={(event) => {
                setUtilizationType(event.target.value);
              }}
              sx={{
                height: '30px',
                fontSize: '11px',
                marginRight: '20px',
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.cartesianGrid
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                '& .MuiSelect-icon': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                },
                '& fieldset': {
                  borderColor: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                },
                ...(enableDarkTheme && {
                  '&.MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#2F445D', // <------------------ outline-color by default
                    },
                  },
                }),
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.menuOptions
                      : ThemePalette.light.menuOptions,
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    fontSize: '11px',
                  },
                },
              }}
            >
              <MenuItem
                value="shiftHours"
                sx={{
                  fontSize: '11px',
                }}
              >
                Utilization by Shift Hours
              </MenuItem>
              <MenuItem
                value="engineHours"
                sx={{
                  fontSize: '11px',
                }}
              >
                Utilization by Engine Hours
              </MenuItem>
            </Select>
            <Typography style={{ fontSize: '11px' }}>
              Shift Wise Analysis:
            </Typography>
            <Switch
              checked={shiftWiseAnalysis}
              disabled={loadingStates?.isLoadingFleetSummary}
              onChange={(e) => {
                setShiftWiseAnalysis(e.target.checked);
              }}
              color="secondary"
            />
          </div>
          {shiftWiseAnalysis && (
            <Select
              labelId="shift-number-label"
              value={shiftNumber}
              defaultValue={shiftNumber}
              disabled={loadingStates?.isLoadingFleetSummary}
              onChange={(e) => {
                setShiftNumber(e.target.value);
              }}
              sx={{
                height: '30px',
                fontSize: '11px',
                marginLeft: '10px',
                marginRight: '20px',
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.cartesianGrid
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                '& .MuiSelect-icon': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.lightGrey
                    : ThemePalette.typography.black,
                },
                '& fieldset': {
                  borderColor: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                },
                ...(enableDarkTheme && {
                  '&.MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#2F445D', // <------------------ outline-color by default
                    },
                  },
                }),
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.menuOptions
                      : ThemePalette.light.menuOptions,
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    fontSize: '11px',
                  },
                },
              }}
            >
              {shiftOptions.map((shift, index) => (
                <MenuItem
                  key={index}
                  value={index + 1}
                  sx={{
                    fontSize: '11px',
                  }}
                >
                  Shift {index + 1} ({shift.startHour}:00 - {shift.endHour}:00)
                </MenuItem>
              ))}
            </Select>
          )}
          {rangeIsOverMonth && (
            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography
                sx={{
                  fontSize: '11px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.mediumGray
                    : '#3d3d3d',
                  marginTop: '7px',
                  marginRight: '10px',
                }}
              >
                Aggregation:
              </Typography>

              <ProductionSelector
                enableDarkTheme={enableDarkTheme}
                options={['Daily', 'Monthly']}
                selected={aggregation}
                setSelected={setAggregation}
                disabled={loadingStates?.isLoadingFleetSummary}
                minWidth="100px"
              />
            </div>
          )}
        </div>
      </Typography>
    </>
  );
};

export default DateRangeDiv;
