export const safeReplace = (
  str: string,
  remove: string,
  replace: string = ''
) => {
  try {
    return str.replace(remove, replace);
  } catch (_) {
    return str;
  }
};

export const safeReplaceBredcrumb = (
  str: string,
  remove: string,
  replace: string = ''
) => {
  try {
    const regex = new RegExp(remove, 'g');
    return str.replace(regex, replace);
  } catch (_) {
    return str;
  }
};

// capitalizeFirstLetter('hello world') => 'Hello world'
export const capitalizeFirstLetter = (str: string) => {
  try {
    return (
      str[0]?.toUpperCase() + (str?.substring(1) ?? '').toLocaleLowerCase()
    );
  } catch (_) {
    console.log('capitalizeFirstLetter error', _);

    return str;
  }
};

// capitalizeFirstWetter except and('hello and world') => 'Hello and World'
export const capitalizeFirstWord = (sentence: string): string => {
  const sentArr = sentence.split(' ');
  const newArr = [];
  for (let i = 0; i < sentArr.length; i++) {
    if (sentArr[i] === 'and') {
      newArr.push(sentArr[i]);
      continue;
    }
    let word = sentArr[i];
    word = word.charAt(0).toUpperCase() + word.substring(1);
    newArr.push(word);
  }
  return newArr.join(' ');
};

/* creates a unique key given a list of strings and delimiter */
export const composeUniqueKey = (
  listOfKeys: string[],
  delimiter = '!'
): string => {
  return listOfKeys.join(delimiter).toLowerCase();
};

// checks if a string contains/includes another string
export const stringContains = (value: string, name: string): boolean => {
  return new RegExp(value, 'i').test(name);
};

export const safeTrim = (str: string, length = Infinity) => {
  try {
    return str.trim().substring(0, length);
  } catch (_) {
    return str;
  }
};

export const splitCamelCase = (text: string): string => {
  return text.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
};
