import { useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  Cell,
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import { Box } from '@mui/system';
// import SouthEastIcon from '@mui/icons-material/SouthEast';
// import NorthEastIcon from '@mui/icons-material/NorthEast';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { truncateTo2dp } from 'utils/helpers/general';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import TableList from 'views/Utilization/components/TableList';
import useCustomerInfo from 'hooks/useCustomerInfo';

interface Props {
  enableDarkTheme: boolean;
  title: string;
  isLoading: boolean;
  customerCode: string;
  data: Record<string, number>;
  setHoursBreakDown: any;
  totalFuelConsumption: number;
  assetTypeKey: string;
}

const FuelUsageBreakdown = ({
  enableDarkTheme,
  title,
  isLoading,
  data,
  totalFuelConsumption,
  assetTypeKey,
}: Props) => {
  const { unitSystem } = useCustomerInfo();
  const [activeIndex] = useState<number | null>(0);
  // const onPieClick = (data: any, index: number) => {
  //   setActiveIndex(index === activeIndex ? null : index);
  // };

  const excessFuelUsageDiff = useMemo(() => {
    const keys = Object.keys(data)?.filter((key) => {
      if (assetTypeKey === 'loader') return true; // temporarily removed filter for loaders
      if (assetTypeKey === 'fleet' || assetTypeKey === 'hauler') return true;
      return (
        key !== 'overspeed' && key !== 'underspeed' && key !== 'incorrect_gear'
      );
    });
    let total = 0;
    for (let i = 0; i < keys.length; i++) {
      if (!keys[i]?.includes('diff')) continue;
      total += data[keys[i]];
    }
    return total;
    // return Object.values(data).reduce((acc, curr) => acc + curr, 0);
  }, [data, assetTypeKey]);

  const excessFuelUsage = useMemo(() => {
    const keys = Object.keys(data)?.filter((key) => {
      if (assetTypeKey === 'loader') return true; // temporarily removed filter for loaders
      if (assetTypeKey === 'fleet' || assetTypeKey === 'hauler') return true;
      return (
        key !== 'overspeed' && key !== 'underspeed' && key !== 'incorrect_gear'
      );
    });
    let total = 0;
    for (let i = 0; i < keys.length; i++) {
      if (keys[i]?.includes('diff')) continue;
      total += data[keys[i]];
    }
    return total;
    // return Object.values(data).reduce((acc, curr) => acc + curr, 0);
  }, [data, assetTypeKey]);

  const tableData = [
    {
      name: 'Excess Fuel Usage',
      time: formatNumberToShortScale(truncateTo2dp(excessFuelUsage), 2),
      direction: excessFuelUsageDiff > 0 ? 'up' : 'down',
      color: '#165BAA',
      fill: '#165BAA',
      maxValue: excessFuelUsage,
    },
  ];

  const fuelUnit = useMemo(
    () => (unitSystem === 'metric' ? 'L' : 'Gal'),
    [unitSystem]
  );

  const dataList = useMemo(() => {
    const breakdown = {
      'Excess Fuel Usage': [
        {
          name: 'Overspeeding',
          value: `${formatNumberToShortScale(
            Number(truncateTo2dp(data.overspeed)),
            2
          )} ${fuelUnit}`,
          direction: data?.overspeed_diff > 0 ? 'up' : 'down',
          color: '#165BAA',
        },
        {
          name: 'Underspeeding',
          value: `${formatNumberToShortScale(
            Number(truncateTo2dp(data.underspeed)),
            2
          )} ${fuelUnit}`,
          direction: data?.underspeed_diff > 0 ? 'up' : 'down',
          color: '#FF0068',
        },
        {
          name: 'Incorrect Gear Speed Ratio',
          value: `${formatNumberToShortScale(
            Number(truncateTo2dp(data.incorrect_gear)),
            2
          )} ${fuelUnit}`,
          direction: data?.incorrect_gear_diff > 0 ? 'up' : 'down',
          color: '#A776FF',
        },
        {
          name: 'Short Idling',
          value: `${formatNumberToShortScale(
            truncateTo2dp(data.short_idling),
            2
          )} ${fuelUnit}`,
          direction: data?.short_idling_diff > 0 ? 'up' : 'down',
          color: '#FF9C2C',
        },
        {
          name: 'Long Idling',
          value: `${formatNumberToShortScale(
            truncateTo2dp(data.long_idling),
            2
          )} ${fuelUnit}`,
          direction: data?.long_idling_diff > 0 ? 'up' : 'down',
          color: '#FF0000',
        },
        {
          name: 'Queuing - Pre Dumping',
          value: `${formatNumberToShortScale(
            truncateTo2dp(data.pre_dumping),
            2
          )} ${fuelUnit}`,
          direction: data?.pre_dumping_diff > 0 ? 'up' : 'down',
          color: '#FFAAAA',
        },
        {
          name: 'Queuing - Pre Loading',
          value: `${formatNumberToShortScale(
            truncateTo2dp(data.pre_loading),
            2
          )} ${fuelUnit}`,
          direction: data?.pre_loading_diff > 0 ? 'up' : 'down',
          color: '#CBE4FF',
        },
      ],
    };
    breakdown['Excess Fuel Usage'] = breakdown['Excess Fuel Usage'].filter(
      (item) => {
        if (assetTypeKey === 'loader') return true; // temporarily removed filter for loaders
        if (assetTypeKey === 'fleet' || assetTypeKey === 'hauler') return true;
        else {
          return (
            item.name !== 'Overspeeding' &&
            item.name !== 'Underspeeding' &&
            item.name !== 'Incorrect Gear Speed Ratio'
          );
        }
      }
    );
    return breakdown;
  }, [data, assetTypeKey]);

  const pieData = [
    {
      name: 'Excess Fuel Usage',
      value: Number(truncateTo2dp(excessFuelUsage)),
      direction: 'up',
      color: '#165BAA',
    },
    {
      name: 'Normal Fuel Usage',
      value: Number(truncateTo2dp(totalFuelConsumption - excessFuelUsage)),
      direction: 'up',
      color: '#A776FF',
    },
  ];

  return (
    <Grid item lg={12} md={6}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '400px',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '600',
            }}
          >
            {title}
          </Typography>
          {!isLoading && (
            <Box>
              <Typography
                sx={{
                  fontSize: '13px',
                }}
              >
                Total Excess Fuel Usage
              </Typography>
              <Typography
                sx={{
                  fontSize: '22px',
                }}
              >
                {/* {Number(maxHoursDiff) > 0 ? (
                  <NorthEastIcon sx={{ color: '#029161', fontSize: 19 }} />
                ) : (
                  <SouthEastIcon sx={{ color: '#EB5757', fontSize: 19 }} />
                )}{' '} */}
                {formatNumberToShortScale(truncateTo2dp(excessFuelUsage), 2)}
                <span
                  style={{
                    fontSize: '11px',
                  }}
                >
                  {' '}
                  {unitSystem === 'metric' ? 'L' : 'gal'}
                </span>
              </Typography>
              {/* <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                <span
                  style={{
                    color: Number(maxHoursDiff) > 0 ? '#029161' : '#EB5757',
                  }}
                >
                  {returnAnalysis(maxHoursDiff)}
                </span>{' '}
                from last period
              </Typography> */}
            </Box>
          )}
        </Box>

        <Box>
          {isLoading ? (
            <MultiColorCircularLoader height="300px" />
          ) : (
            <Box sx={{ gap: 2, alignItems: 'flex-start' }}>
              {/* Chart Section */}
              <Box
                sx={{
                  width: '100%',
                  gap: 2,
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                }}
              >
                {/* evenly divided 2 columns with 10px gap */}
                <Box>
                  <FlexBox sx={{ height: '210px', marginRight: '10px' }}>
                    {data.engineHours === 0 ? (
                      <Typography sx={{ fontSize: '12px' }}>
                        No chart data available for the selected time range
                      </Typography>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart
                          width={400}
                          height={400}
                          margin={{ top: 10, right: 10, left: 5, bottom: 5 }}
                        >
                          <Pie
                            data={pieData}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            // onClick={(data, index) => {
                            //   onPieClick(data, index);
                            // }}
                            activeIndex={
                              activeIndex !== null ? [activeIndex] : []
                            }
                            label={(props: any) => {
                              return `
                              ${
                                // eslint-disable-next-line react/prop-types
                                props.name
                              }`;
                            }}
                            activeShape={renderActiveShape}
                            // activeShape={(props: any) =>
                            //   activeIndex !== null
                            //     ? renderActiveShape(props, dataList)
                            //     : null
                            // }
                            innerRadius={'40%'}
                            outerRadius="70%"
                          >
                            {pieData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <Tooltip
                            cursor={{ fill: '#1B2B4A' }}
                            wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                            formatter={(value: any, name: any) => {
                              return `${formatNumberToShortScale(value)} ${
                                unitSystem === 'metric' ? 'L' : 'gal'
                              }`;
                            }}
                            // ... rest of tooltip props
                          />
                        </PieChart>
                      </ResponsiveContainer>
                    )}
                  </FlexBox>
                </Box>
                <Box>
                  <TableList
                    enableDarkTheme={enableDarkTheme}
                    data={tableData}
                    subItems={dataList}
                    unit={unitSystem === 'metric' ? 'Litres' : 'Gallons'}
                  />
                  {/* Breakdown List */}
                  {/* <Box sx={{ flex: 1, marginTop: '40px' }}>
                <Typography variant="body2" sx={{ fontWeight: 600, mb: 1 }}>
                  Excess Fuel Usage Breakdown
                </Typography>
                <List dense sx={{ '& .MuiListItem-root': { padding: 0 } }}>
                  {oldPieData.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemIcon sx={{ minWidth: 20 }}>
                        <Box
                          sx={{
                            width: 12,
                            height: 12,
                            backgroundColor: item.color,
                            borderRadius: '2px',
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={`${item.name}: ${formatNumberToShortScale(
                          item.value
                        )} ${unitSystem === 'metric' ? 'L' : 'gal'}`}
                        primaryTypographyProps={{
                          fontSize: '0.75rem',
                          fontWeight: 500,
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box> */}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default FuelUsageBreakdown;

const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      {/* Enlarged active segment */}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={Number(outerRadius) + 20}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

      {/* label */}
      {/* <text
        x={cx}
        y={cy}
        dy={8}
        fill={enableDarkTheme ? '#F3F4F6' : '#2F445D'}
        fontSize={14}
        fontWeight={600}
      >
        {`${payload.name}: `}
        <tspan fontSize={12} fill={fill} dx={2}>
          {formatNumberToShortScale(payload.value)}{' '}
          {unitSystem === 'metric' ? 'L' : 'gal'}
        </tspan>
      </text> */}
    </g>
  );
};
