import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Typography, Box, Select, MenuItem } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { ThemePalette } from 'mui.theme';
import { getCategoryStats } from './utils';

const AssetChart = ({
  enableDarkTheme,
  data,
  selectedAssets,
  selectedPart,
}: {
  enableDarkTheme: boolean;
  data: any[];
  selectedAssets: any[];
  selectedPart: any[];
}) => {
  const [sortType, setSortType] = useState('Cost');

  const formatCategoryText = (text: string) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const replacementData = data.filter(
    (item) => item.actions_performed === 'REPLACE'
  );

  const assetsData = replacementData.filter((item) =>
    selectedAssets.includes(item.asset_id)
  );

  const catgoryData: any[] = getCategoryStats(assetsData);

  const processedData = [...catgoryData]
    .filter((item) => selectedPart.includes(item.category))
    .sort(
      (a: any, b: any) => b[sortType.toLowerCase()] - a[sortType.toLowerCase()]
    )
    .map((item) => ({
      ...item,
      category: formatCategoryText(item.category),
    }));

  return (
    <Box
      sx={{
        marginTop: '20px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.toolBarBackground,
        borderRadius: '5px',
        paddingTop: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Box>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '14px',
              paddingLeft: '10px',
            }}
          >
            Frequently Replaced Parts
          </Typography>
          <span
            style={{
              color: ThemePalette.typography.lightGrey,
              fontSize: '10px',
              paddingLeft: '10px',
              paddingTop: '5px',
              marginBottom: '10px',
            }}
          >
            Click on the bar to view details of a particular part
          </span>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography
            sx={{
              paddingRight: '5px',
              marginTop: '8px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            {' '}
            View
          </Typography>
          <Select
            labelId="parts-recommendation-selected-asset"
            value={sortType}
            onChange={(e: any) => {
              setSortType(e.target.value);
            }}
            sx={{
              height: '40px',
              fontSize: '14px',
              marginLeft: '10px',
              marginRight: '20px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.cartesianGrid
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              '& .MuiSelect-icon': {
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              },
              '& fieldset': {
                borderColor: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
              ...(enableDarkTheme && {
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#2F445D',
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  fontSize: '14px',
                },
              },
            }}
          >
            {['Count', 'Cost'].map((type, index) => (
              <MenuItem
                key={index}
                value={type}
                sx={{
                  fontSize: '14px',
                }}
              >
                {type}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <FlexBox
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        {processedData.length === 0 ? (
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
            }}
          >
            No data for selected filter
          </Typography>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={processedData}
              layout="horizontal"
              width={800}
              height={600}
              margin={{ top: 5, right: 30, left: 20, bottom: 70 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                horizontal={true}
                vertical={false}
                stroke={ThemePalette.dark.cartesianGrid}
              />
              <XAxis
                dataKey="category"
                type="category"
                tick={{ fontSize: 11 }}
                angle={-45}
                textAnchor="end"
              />
              <YAxis type="number" tick={{ fontSize: 11 }} />
              <Tooltip
                formatter={(value, name) => [
                  name === 'cost' ? `$${value.toLocaleString()}` : value,
                  name === 'cost' ? 'Cost' : 'Count',
                ]}
                cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
              />
              {sortType === 'Cost' ? (
                <Bar dataKey="cost" name="cost" fill="#58BF6A" barSize={30} />
              ) : (
                <Bar dataKey="count" name="count" fill="#F08733" barSize={30} />
              )}
            </BarChart>
          </ResponsiveContainer>
        )}
      </FlexBox>
    </Box>
  );
};

export default AssetChart;
