import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';
import type { GroupedHistogramDataItem } from 'types/FleetOverview.types';
import { transformData, calculateAverage } from 'views/FleetComparison/helper';
import {
  findMinMaxValues,
  categorizeRange,
  thresholdColors,
  processUtilizationAttributes,
  getDefaultThresholds,
  multiplyRangeBy100,
} from './utils';

export const MetricItemChartTrends = ({
  enableDarkTheme,
  data,
  colorIndex,
  bumperIdMap,
  thresholds,
  title,
  defaultThresholds,
}: {
  enableDarkTheme: boolean;
  data: GroupedHistogramDataItem[];
  colorIndex: string[];
  bumperIdMap: string[];
  thresholds: any[];
  title: string;
  defaultThresholds: any[];
}) => {
  const transformedData = transformData(data);
  let movingAverage = calculateAverage(transformedData);

  let thresholdData = thresholds;

  const minMax = findMinMaxValues(data);
  let maxDomain = minMax.max ?? 'auto';

  if (defaultThresholds.length > 0) {
    thresholdData = getDefaultThresholds(title, defaultThresholds);
    if (thresholdData.length === 0) {
      thresholdData = categorizeRange(minMax);
    }
  } else {
    thresholdData = categorizeRange(minMax);
  }

  const maxValues = thresholdData.map((item: any) => item?.range[1]);

  const overallMax = Math.max(...maxValues) ?? 'auto';
  maxDomain = overallMax;

  function formatYAxis(value: any) {
    let updatedValue = value;

    if (updatedValue % 1 !== 0) {
      updatedValue = parseFloat(updatedValue.toFixed(2));
    }

    if (value >= 1e12) {
      // Trillions
      updatedValue = `${(value / 1e12).toFixed(2)} T`;
    } else if (value >= 1e9) {
      // Billions
      updatedValue = `${(value / 1e9).toFixed(2)} B`;
    } else if (value >= 1e6) {
      // Millions
      updatedValue = `${(value / 1e6).toFixed(2)} M`;
    } else if (value >= 1e3) {
      // Thousands
      updatedValue = `${(value / 1e3).toFixed(2)} k`;
    }
    return updatedValue;
  }

  if (title === 'utilization') {
    movingAverage = processUtilizationAttributes(movingAverage);
    maxDomain = maxDomain * 100;
    thresholdData = multiplyRangeBy100(thresholdData);
  }

  movingAverage = [...movingAverage].sort((a, b) => {
    // @ts-expect-error ignore this line
    return new Date(a.name) - new Date(b.name);
  });

  return (
    <Box
      sx={{
        textAlign: 'left',
        margin: '20px',
        paddingLeft: '20px',
        paddingTop: '20px',
        border: '1px solid #26364C',
        borderRadius: '4px',
      }}
    >
      <Typography>Daily Moving Average</Typography>
      {movingAverage.length > 0 ? (
        <FlexBox
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 'inherit',
            height: '500px',
            flexDirection: 'column',
            paddingTop: '20px',
            marginRight: '10px',
            paddingBottom: '10px',
          }}
          id="vehicle-status-page"
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={movingAverage}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={ThemePalette.dark.cartesianGrid}
                vertical={false}
              />
              <XAxis dataKey="name" />
              <YAxis domain={[0, maxDomain]} tickFormatter={formatYAxis} />
              <Tooltip />
              {thresholdData.map((threshold, index) => (
                <ReferenceArea
                  key={index}
                  y1={threshold.range[0]}
                  y2={threshold.range[1]}
                  // @ts-expect-error fjfjghhg ghgh ghgh
                  fill={thresholdColors[threshold.category] ?? '#C3c3c3'}
                  fillOpacity={0.3}
                />
              ))}
              {bumperIdMap.map((itemMap, index) => (
                <Line
                  key={itemMap}
                  type="linear"
                  dataKey={itemMap}
                  stroke={colorIndex[index]}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </FlexBox>
      ) : (
        <Typography>No Data to Display</Typography>
      )}
    </Box>
  );
};
