import * as React from 'react';
import { Box, List, Typography, type SelectChangeEvent } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { AdditionalColumnWrapper } from 'views/FleetOverview/components/FilterViewBar/FilterBar.styled';
import { type Dispatch } from 'react';
import type { TagDisplay } from 'views/FleetOverview/AssetDisplay';
import ExportSelector from './ExportSelector';
import { useCustomDropdownEffect } from 'utils/exports/hooks';
import { Selector } from 'components/Form/Selectors/Selector';
import { GroupedTagMultiSelector } from 'components/Form/Selectors/GroupedTagMultiSelector';
import { type TGroupedMultiSelectOptions } from 'types/utils.types';
import { handleSelectAllForGroupedSelector } from 'views/Settings/Rules/helpers/common';
import { ThemePalette } from 'mui.theme';
import SwitchView from './SwitchView';
import { nonGPSCustomers } from 'utils/helpers/general';
import { useAppSelector } from 'store/hook';

/**
 * A component that displays renders the filter and view change bar on the dashboard
 */

interface Props {
  setSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setView: Dispatch<string>;
  view: string;
  tags: TagDisplay[];
  handleSelectTag: (tag: TagDisplay) => void;
  selectedTags: TagDisplay[];
  handleExport: (val: boolean) => void;
  setSelectedTags: React.Dispatch<React.SetStateAction<TagDisplay[]>>;
  enableDarkTheme: boolean;
  shouldShowNewDashboard: boolean;
  dateComparer: string;
  setDateComparer: React.Dispatch<React.SetStateAction<string>>;
  cardSort: string;
  setCardSort: React.Dispatch<React.SetStateAction<string>>;
}

export const FilterViewBar = ({
  setSearchValue,
  setView,
  view,
  tags,
  handleSelectTag,
  selectedTags,
  handleExport,
  setSelectedTags,
  enableDarkTheme,
  shouldShowNewDashboard,
  dateComparer,
  setDateComparer,
  cardSort,
  setCardSort,
}: Props) => {
  const [exportTo, setExportTo] = React.useState('');
  const [exportToLabel, setExportToLabel] = React.useState('Export To');

  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportTo(event.target.value);
  };

  useCustomDropdownEffect(
    exportTo,
    handleExport,
    setExportToLabel,
    setExportTo,
    [exportTo]
  );
  const { code: customerCode } = useAppSelector(
    (state) => state.persistedReducer
  ).customer;
  const isNonGpsClient = nonGPSCustomers(customerCode);

  return (
    <>
      <FlexBox
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          padding: '10px 0 0 10px',
        }}
      >
        <List
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0,
            alignItems: 'center',
          }}
        >
          {shouldShowNewDashboard ? (
            <span
              style={{
                fontSize: '20px',
                paddingRight: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            >
              All Assets
            </span>
          ) : (
            <></>
          )}
          <SWTextField
            name="filter"
            placeholder={
              shouldShowNewDashboard ? 'Search for an asset' : 'Filter...'
            }
            TextFieldProps={{
              sx: {
                width: '120%',
                fontSize: '18px',
                '& .MuiInputBase-root': {
                  height: '40px',
                },
                input: {
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                },
                ...(enableDarkTheme && {
                  border: `1px solid ${String(ThemePalette.border)}`,
                }),
              },
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchValue(e);
              },
            }}
            enableDarkTheme={enableDarkTheme}
          />
          {view !== 'map' && view !== 'card' ? (
            <>
              <AdditionalColumnWrapper>
                <span
                  style={{
                    paddingRight: '10px',
                    paddingTop: '4px',
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.black,
                  }}
                >
                  Select Additional Columns
                </span>

                <GroupedTagMultiSelector
                  disableSelectAll={true}
                  value={selectedTags as TGroupedMultiSelectOptions}
                  selectorOptions={tags as TGroupedMultiSelectOptions}
                  handleSelectOne={(option: TGroupedMultiSelectOptions) => {
                    handleSelectTag(option[option.length - 1]);
                  }}
                  handleSelectAll={(
                    options: TGroupedMultiSelectOptions,
                    allSelected: boolean
                  ) => {
                    handleSelectAllForGroupedSelector(
                      options,
                      selectedTags,
                      setSelectedTags,
                      'tags',
                      allSelected,
                      true
                    );
                  }}
                  renderValue={(selected) => {
                    return `${selected?.length || 0} Selected`;
                  }}
                  height="25px"
                  enableDarkTheme={enableDarkTheme}
                />
              </AdditionalColumnWrapper>
            </>
          ) : null}
          {view !== 'map' ? (
            <>
              {shouldShowNewDashboard ? (
                <></>
              ) : (
                <ExportSelector
                  view={view}
                  exportToLabel={exportToLabel}
                  exportTo={exportTo}
                  onExportToChange={handleExportDropDownChange}
                  enableDarkTheme={enableDarkTheme}
                />
              )}
            </>
          ) : null}

          <>
            {view === 'card' ? (
              <>
                {shouldShowNewDashboard ? (
                  <>
                    <AdditionalColumnWrapper>
                      <Typography
                        sx={{
                          marginRight: '10px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                        }}
                        fontSize="14px"
                      >
                        Compare to
                      </Typography>

                      <Selector
                        minWidth="1px"
                        value={dateComparer}
                        onChange={(event) => {
                          setDateComparer(event.target.value as string);
                        }}
                        selectorOptions={[
                          {
                            id: 'day',
                            display: 'Previous Day',
                          },
                          {
                            id: '3days',
                            display: 'Previous 3 Days',
                          },
                          {
                            id: 'week',
                            display: 'Previous Week',
                          },
                        ]}
                        enableDarkTheme={enableDarkTheme}
                      />

                      <Typography
                        sx={{
                          marginRight: '10px',
                          marginLeft: '10px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                        }}
                        fontSize="14px"
                      >
                        Sort by
                      </Typography>

                      <Selector
                        minWidth="1px"
                        value={cardSort}
                        onChange={(event) => {
                          setCardSort(event.target.value as string);
                        }}
                        selectorOptions={[
                          {
                            id: 'severity',
                            display: 'Severity All',
                          },
                          {
                            id: 'performance',
                            display: 'Performance',
                          },
                          {
                            id: 'utilization',
                            display: 'Utilization',
                          },
                        ]}
                        enableDarkTheme={enableDarkTheme}
                      />
                    </AdditionalColumnWrapper>
                  </>
                ) : null}
              </>
            ) : null}
          </>
        </List>

        <Box sx={{ width: '30%' }}>
          <SwitchView
            setView={setView}
            view={view}
            enableDarkTheme={enableDarkTheme}
            isNonGpsClient={isNonGpsClient}
          />
        </Box>
      </FlexBox>
    </>
  );
};
