import { createSlice } from '@reduxjs/toolkit';
import { type metricTargetData, type targetData } from 'types/target.types';
import type { targetLoadingStates } from 'types/targets.types';
import { daysLeftInYear } from 'views/Utilization/utils';

export interface targetState {
  data: Record<string, metricTargetData[]>; // For dynamic metric names
  loadingStates: {
    isLoadingTarget: boolean;
  };
}

const initialState: targetState = {
  data: {}, // Empty object allows dynamic metric names
  loadingStates: {
    isLoadingTarget: true,
  },
};

const calculateYtdTarget = (targetVal: number) => {
  return (
    Number((((targetVal || 0) / 365) * (365 - daysLeftInYear())).toFixed(1)) ||
    0
  );
};

export const targetSlice = createSlice({
  name: 'targets',
  initialState,
  reducers: {
    setIsLoading(state, { payload }) {
      state.loadingStates = {
        ...state.loadingStates,
        ...payload,
      };
    },
    setTarget(state, { payload }) {
      const { metricName, targetValue, assetType } = payload;
      const ytdTarget = calculateYtdTarget(targetValue);

      if (!state.data[metricName]) {
        state.data[metricName] = [];
      }

      const existingEntryIndex = state.data[metricName].findIndex(
        (entry) => entry.assetType === assetType
      );

      if (existingEntryIndex !== -1) {
        // Update existing entry
        state.data[metricName][existingEntryIndex].targetValue = targetValue;
        state.data[metricName][existingEntryIndex].ytdTarget = ytdTarget;
      } else {
        state.data[metricName].push({ targetValue, assetType, ytdTarget });
      }

      /// //// ALL---note
      // Recalculate "all" except for "idling"
      if (metricName !== 'idling') {
        const totalTargetValue = state.data[metricName]
          .filter((entry) => entry.assetType !== 'all')
          .reduce((sum, entry) => sum + entry.targetValue, 0);

        // Find the existing "all"
        const allEntryIndex = state.data[metricName].findIndex(
          (entry) => entry.assetType === 'all'
        );

        if (allEntryIndex !== -1) {
          // Update existing "all"
          state.data[metricName][allEntryIndex].targetValue = totalTargetValue;
          state.data[metricName][allEntryIndex].ytdTarget =
            calculateYtdTarget(totalTargetValue);
        }
      }
    },
    updateAllTargets(state, { payload }) {
      const aggregatedData: Record<string, metricTargetData[]> = {};

      Object.entries(payload).forEach(([metricName, targets]) => {
        if (!aggregatedData[metricName]) {
          aggregatedData[metricName] = [];
        }

        (targets as any).forEach((target: targetData) => {
          const { targetValue, assetType } = target;
          const ytdTarget = calculateYtdTarget(targetValue);

          const existingEntry = aggregatedData[metricName].find(
            (entry) => entry.assetType === assetType
          );

          if (existingEntry) {
            existingEntry.targetValue += targetValue;
            existingEntry.ytdTarget = calculateYtdTarget(
              existingEntry.targetValue
            );
          } else {
            aggregatedData[metricName].push({
              targetValue,
              assetType,
              ytdTarget,
            });
          }
        });
      });

      state.data = aggregatedData;
    },
  },
});

export const {
  setIsLoading: setTargetLoading,
  setTarget,
  updateAllTargets,
} = targetSlice.actions;

export default targetSlice.reducer;
