import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ThemePalette } from 'mui.theme';
import AssetMap from '../components/AssetMap';
import { type Asset } from 'types/asset.types';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { SVG } from 'components/Asset/SVG';
import { includedLargeSVgs } from 'views/VehicleStatus/utils';
import {
  assetHasDTC,
  assetHasSuggestions,
  assetTypeMappings,
  isAssetIgnition,
  notificationLevel,
} from '../helpers/asset.helpers';
import {
  getIsAssetOutOfService,
  getMakeModel,
  svgStyleImport,
  svgStylePerformanceIcon,
  svgStyleRedIcon,
} from '../helpers/map.helpers';
import CircularProgressChart from 'views/Production/components/CircularProgress/CircularProgress';
import UtilCircularProgressChart from 'views/Utilization/components/ProductiveHours/CircularProgress';
import {
  formatNumberToShortScale,
  formatTripData,
} from 'views/Production/Hauler/component/utils';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  calculateCurrentDailyAverage,
  calculateDailyMovingAverage,
  type ChartSource,
  getFleetProduction,
  hasMetTarget,
  periodMap,
} from 'views/Production/AllFleet/utils';
import {
  calculateUtilCurrentDailyAverage,
  filterDataByPeriod,
  getShiftHours,
} from 'views/Utilization/utils';
import { truncateTo2dp } from 'utils/helpers/general';
import { useNavigate } from 'react-router-dom';
import {
  getNotificationIndex,
  timeAgo,
} from '../components/AssetCardsV2/utils';
import AssetCardFuelUsage from '../components/AssetCardsV2/AssetCardItem/Cards/AssetCardFuelUsage';
import AssetCardProductivity from '../components/AssetCardsV2/AssetCardItem/Cards/AssetCardProductivity';
import AssetCardUtilization from '../components/AssetCardsV2/AssetCardItem/Cards/AssetCardUtilization';
import { setTotalFleetDetails } from 'store/production.slice';
import {
  fuelIdleConsumptionLabels,
  getHourlyFuelEfficencyLabelWrtUnitSystem,
} from 'utils/helpers/labels';
import SwitchView from '../components/FilterViewBar/SwitchView';

interface Props {
  filteredAssets: Asset[];
  enableDarkTheme: boolean;
  isLoadingAllAssets: boolean;
  setShowModal: any;
  setselectedAsset: any;
  selectedAsset: Asset | null;
  filteredArray: Asset[];
  setFilteredArray: any;
  view: string;
  setView: any;
  searchValue: string;
  setSearchValue: any;
  isNonGpsClient: boolean | null;
}

const MapView = ({
  filteredAssets,
  enableDarkTheme,
  isLoadingAllAssets,
  setShowModal,
  setselectedAsset,
  selectedAsset,
  filteredArray,
  setFilteredArray,
  view,
  setView,
  searchValue,
  setSearchValue,
  isNonGpsClient,
}: Props) => {
  const navigate = useNavigate();

  const viewerRef = useRef<any>(null);

  const {
    data: {
      allFleet: { totalFleetDetails, fleetPeriodSummary, suggestions },
    },
    loadingStates,
  } = useAppSelector((state) => state.productionReducer);

  const { data: utilData, loadingStates: utilLoadingState } = useAppSelector(
    (state) => state.utilizationReducer
  );
  const { unitSystem, code: customerCode } = useAppSelector(
    (state) => state.persistedReducer
  ).customer;

  const targetData = useAppSelector((state) => state.targetReducer.data);
  const isLoadingTarget = useAppSelector(
    (state) => state.targetReducer.loadingStates?.isLoadingTarget
  );
  const { latestTagData } = useAppSelector((state) => state.deviceReducer);

  const utilFleetData = utilData.allFleet.fleetPeriodSummary.fleet;
  const dispatch = useAppDispatch();

  const {
    allFleet: {
      fleetDetails: { fleet: fleetUtilData },
      fleetPeriodSummary: utilFleetPeriodSummary,
    },
  } = utilData;

  const productionTarget = targetData.production?.find(
    (item) => item.assetType === 'all'
  );
  const utilizationTarget = targetData.utilization?.find(
    (item) => item.assetType === 'all'
  );

  const [isAssetPanelVisible, setIsAssetPanelVisible] = useState<{
    assets: boolean;
    metrics: boolean;
  }>({
    assets: true,
    metrics: true,
  });

  // const [filteredArray, setFilteredArray] = useState(filteredAssets);
  const [rollingDailyAverage, setRollingDailyAverage] = useState<number>(0); // TODO

  const [sevenDayAvg, setDayAvg] = useState<number>(0);

  const [selectedChartSource, setSelectedChartSource] =
    useState<ChartSource>('Last 7 Days');
  let chartData: any = [];

  const selectedSource: any = Array.isArray(fleetPeriodSummary)
    ? fleetPeriodSummary?.find(
        (item) => item.time_range === getFleetProduction(selectedChartSource)
      )
    : null;

  if (selectedSource?.daily_production) {
    chartData = Object.keys(selectedSource?.daily_production).map((date) => ({
      date,
      value: convertToTwoDecimalPlaces(selectedSource?.daily_production[date]),
    }));
  }

  const rdaRef = useRef<number | null>(null); // Initialize ref to track the last dispatched value

  useEffect(() => {
    const result = calculateDailyMovingAverage(chartData, 2);

    if (result?.length > 0 && Array.isArray(result)) {
      const rda = result[result.length - 1].movingAverage ?? 0;

      if (rdaRef.current !== rda) {
        rdaRef.current = rda; // Update ref with new value
        setRollingDailyAverage(rda);
        dispatch(setTotalFleetDetails({ rollingDailyAverageFromChart: rda }));
      }
    }
  }, [chartData]);

  const [selectedUtilChartSource, setSelectedUtilChartSource] =
    useState<ChartSource>('Last 7 Days');

  const [utilChartData, setUtilChartData] = useState<any[]>([]);

  useEffect(() => {
    if (utilData) {
      const filteredData = filterDataByPeriod(
        utilFleetData,
        periodMap[selectedChartSource]
      ).map((item) => ({
        ...item,
        value: truncateTo2dp(Number(item.total_engine_working_minutes) / 60),
      }));

      setUtilChartData(filteredData);
    }
  }, [utilData, selectedChartSource]);

  useEffect(() => {
    const result = calculateDailyMovingAverage(utilChartData, 2);
    if (result?.length > 0 && Array.isArray(result)) {
      setDayAvg(result[result.length - 1].movingAverage ?? 0);
    }
  }, [utilChartData]);

  const noOfAssets = Number(fleetUtilData.number_of_assets);

  const currentDailyAvg = useMemo(() => {
    return calculateUtilCurrentDailyAverage(utilFleetPeriodSummary.fleet ?? []);
  }, [utilFleetPeriodSummary.fleet]);

  useEffect(() => {
    const filteredData = filteredAssets?.filter(
      (asset) =>
        asset.assetType.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.make.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.model.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.bumperNumber
          .toLowerCase()
          .includes(String(searchValue).toLowerCase())
    );

    setFilteredArray(filteredData);
  }, [searchValue, filteredAssets]);

  const onVisibleClick = (type: 'metrics' | 'assets') => {
    setIsAssetPanelVisible((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  // const [selectedAsset, setselectedAsset] = useState<Asset | null>(null);

  const notificationLevelIndex = notificationLevel(
    selectedAsset?.notifications
  );

  const notificationLevelMap = getNotificationIndex(
    selectedAsset?.notifications
  );

  const [clickPosition, setClickPosition] = useState({
    x: 0,
    y: 0,
    displayAbove: false,
  });
  const [showTail, setShowTail] = useState(true);

  const handleEntityClick = (
    asset?: Asset,
    movement?: any,
    position?: any,
    sidebar?: boolean
  ) => {
    if (!asset) {
      setselectedAsset(null);
    } else {
      const cartesian2 = position
        ? viewerRef.current.cesiumElement.scene.cartesianToCanvasCoordinates(
            position
          )
        : { x: 400, y: 100 };

      setselectedAsset(asset);

      sidebar ? setShowTail(false) : setShowTail(true);

      // Calculate the available space below the clicked asset
      const screenHeight = window.innerHeight;
      const spaceBelow = screenHeight - cartesian2.y;

      // Set a threshold for the minimum space required below the asset
      const minSpaceRequired = 300;

      // Determine if the info box should be displayed above or below the asset
      const displayAbove = spaceBelow < minSpaceRequired;

      // Update the click position and whether to show the tail
      setClickPosition({
        x: cartesian2.x,
        y: cartesian2.y,
        displayAbove,
      });
    }
  };

  const makeModel = getMakeModel(selectedAsset as Asset);

  const currentDailyAverage = useMemo(() => {
    return calculateCurrentDailyAverage(fleetPeriodSummary ?? []);
  }, [fleetPeriodSummary]);

  const handleAlertClick = async (asset?: Asset, type = 'notifications') => {
    const dataToPass = {
      bumperNumber: asset ? asset.bumperNumber : selectedAsset?.bumperNumber,
    };
    if (type === 'notifications') {
      navigate('/notifications', {
        state: {
          data: dataToPass,
        },
      });
    }
    if (type === 'dtc') {
      navigate('/diagnostic-tool-summary', {
        state: {
          data: dataToPass,
        },
      });
    }
  };

  function handleAssetSelected(asset: Asset) {
    setselectedAsset(asset);
    setShowModal(true);
  }
  const { outlierTripsBasedOnTime } = formatTripData(
    suggestions[0],
    unitSystem
  );

  const groupedOutliers =
    outlierTripsBasedOnTime !== 'None'
      ? (outlierTripsBasedOnTime as string[]).reduce(
          (acc: any, item: string) => {
            const [trip, asset] = item.split(' for ');
            const [assetId, tripDetails] = asset.split(' with trip ');
            const [, tripTime] = tripDetails.split('of ');

            if (!acc[assetId]) {
              acc[assetId] = [];
            }

            acc[assetId].push({ trip, assetId, tripDetails, tripTime });
            return acc;
          },
          {}
        )
      : {};

  const handleTripClick = (bumperNumber: string, trip: any) => {
    function getAssetVinByBumperNumber(bumperNumber: string): string | null {
      const asset = filteredAssets.find((a) => a.bumperNumber === bumperNumber);
      return asset ? asset.assetVin : null;
    }
    const assetVin = getAssetVinByBumperNumber(bumperNumber);
    const date = String(trip?.trip).split('on')?.[1];
    navigate(`/production/${assetVin}`, {
      state: {
        type: 'trip-details',
        value: 0,
        asset: bumperNumber,
        date,
      },
    });
  };

  return (
    <>
      <Grid
        style={{
          position: 'relative',
          width: '100%',
          height: '100vh',
          color: '#fff',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 3,
            left: 10,
            width: '60%',

            zIndex: 10,
            color: 'white',
            overflowY: 'auto',
            display: 'flex',
          }}
        >
          <TextField
            label="Search Asset"
            variant="outlined"
            InputProps={{
              sx: {
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#38475E',
                },
                backgroundColor: enableDarkTheme ? '#152642' : '#ffffff',
                width: '100%',
                height: '50px',
              },
            }}
            InputLabelProps={{
              sx: {
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                '&.Mui-focused': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black,
                },
              },
            }}
            sx={{
              marginTop: '8px',
              borderRadius: '5px',
              width: '25%',
              '& .MuiInputBase-input::placeholder': {
                color: 'gray',
              },
            }}
            value={searchValue}
            onChange={onChange}
          />
          <SVG
            name={isAssetPanelVisible.assets ? 'hideIcon' : 'eyeViewIcon'}
            style={{
              marginTop: '8px',
              height: '50px',
              width: '10%',
              cursor: 'pointer',
            }}
            onClick={() => {
              onVisibleClick('assets');
            }}
          />

          <Box
            sx={{
              width: '40%',
              backgroundColor: '#27364C',
              marginTop: '8px',
              height: '55px',
              paddingX: '4px',
            }}
          >
            <SwitchView
              setView={setView}
              view={view}
              enableDarkTheme={enableDarkTheme}
              isNonGpsClient={isNonGpsClient}
              setSelectedAsset={setselectedAsset}
            />
          </Box>
        </Box>
        {/* Sidebar */}
        <Box
          style={{
            position: 'absolute',
            top: 100,
            left: 10,
            width: '20%',
            height: '89%',

            zIndex: 10,
            color: 'white',
            overflowY: 'auto',
          }}
        >
          {isAssetPanelVisible.assets && (
            <Box
              style={{
                backgroundColor: enableDarkTheme
                  ? '#0A2341'
                  : ThemePalette.light.boxBackground,
                border: 'solid 1px rgba(255,255,255, 0.1)',
                borderRadius: 5,
                marginBottom: '15px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#3666EC',
                  padding: '10px',
                  border: 'none',
                  borderTopLeftRadius: 3,
                  borderTopRightRadius: 3,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.black,
                  }}
                >
                  Assets
                </Typography>
              </Box>

              {filteredArray.map((asset, index) => {
                const makeModel = getMakeModel(asset);
                const notificationLevelIndex = notificationLevel(
                  asset?.notifications
                );

                const notificationLevelMap = getNotificationIndex(
                  asset?.notifications
                );
                return (
                  <Grid key={index} sx={{ cursor: 'pointer' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'left',
                        padding: '10px',
                        paddingBottom: '0px',
                      }}
                    >
                      <LaunchIcon
                        onClick={() => {
                          handleAssetSelected(asset);
                        }}
                        sx={{
                          position: 'absolute',
                          right: 4,
                          marginTop: '-5px',
                          fontSize: '17px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        }}
                      />

                      <Tooltip
                        title={`${
                          isAssetIgnition(latestTagData, asset)
                            ? 'Vehicle is ON'
                            : 'Vehicle is OFF'
                        }`}
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -30],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: '30%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '25px', // Smaller circle
                              height: '25px',
                              borderRadius: '50%',
                              bgcolor: isAssetIgnition(latestTagData, asset)
                                ? '#4e9967'
                                : '#7a4c4c',
                              boxShadow: `0px 0px 18px 7px ${
                                isAssetIgnition(latestTagData, asset)
                                  ? '#37a17a'
                                  : '#5e4838' // '#EB5757' Red Glow for Other Statuses
                              }`,
                              transition: 'box-shadow 0.3s ease-in-out',
                            }}
                          />
                          <SVG
                            name={
                              asset.assetType === 'haul_truck'
                                ? includedLargeSVgs.includes(makeModel)
                                  ? `${makeModel}ImageLarge`
                                  : `cat810eImageLarge`
                                : `cat810eImageLarge`
                            }
                            style={{
                              height: '60px',
                              width: '90px',
                              marginLeft: '-20px',
                              position: 'relative',
                              zIndex: 1,
                            }}
                          />
                        </Box>
                      </Tooltip>

                      <Box
                        sx={{
                          marginLeft: '-10px',
                          width: '65%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingTop: '20px',
                        }}
                      >
                        <Box
                          sx={{
                            width: '45%',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: enableDarkTheme ? '#ffffff' : '#000000',
                              fontSize: '14px',
                              marginRight: '5px',
                            }}
                            onClick={() => {
                              handleEntityClick(asset, null, null, true);
                            }}
                          >
                            {asset.bumperNumber}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            columnGap: '4px',
                            width: '75%',
                          }}
                        >
                          <Tooltip
                            title={`Asset Status: ${
                              getIsAssetOutOfService(asset)
                                ? 'Out of Service'
                                : 'Operational'
                            }`}
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, -30],
                                    },
                                  },
                                ],
                              },
                            }}
                          >
                            <Box
                              sx={{
                                maxWidth: '20px',
                              }}
                            >
                              <SVG
                                name={'hillsIcon'}
                                style={{
                                  height: '1.4em',
                                  width: '1.0em',
                                  ...svgStyleImport(asset),
                                }}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={`Notification Level: ${notificationLevelMap.message}`}
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, -30],
                                    },
                                  },
                                ],
                              },
                            }}
                            onClick={async () => {
                              await handleAlertClick(asset);
                            }}
                          >
                            <Box
                              sx={{
                                maxWidth: '20px',
                              }}
                            >
                              <SVG
                                name={
                                  notificationLevelIndex.level <= 1
                                    ? 'optimalPerformanceIcon'
                                    : 'nonOptimalPerformanceIcon'
                                }
                                style={{
                                  fill: '#EB5757',
                                  height: '1.4em',
                                  width: '1.0em',
                                  cursor: 'pointer',
                                  ...svgStylePerformanceIcon(
                                    notificationLevelMap.color
                                  ),
                                }}
                              />
                            </Box>
                          </Tooltip>
                          <Tooltip
                            title={`Device Status: ${asset.device.deviceStatus}`}
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, -30],
                                    },
                                  },
                                ],
                              },
                            }}
                          >
                            <Box
                              sx={{
                                maxWidth: '20%',
                              }}
                            >
                              <SVG
                                name={'fillableXLogo'}
                                fill={
                                  asset.device.deviceStatus === 'Online'
                                    ? 'rgba(43, 198, 25, 0.8)'
                                    : asset.device.deviceStatus ===
                                      'Recently Online'
                                    ? '#6888d6'
                                    : asset.device.deviceStatus === 'Offline'
                                    ? '#F47C02'
                                    : '#EB5757'
                                }
                                style={{
                                  height: '1.4em',
                                  width: '1.3em',
                                  cursor: 'pointer',
                                  // ...svgStyleRedIcon(asset),
                                }}
                              />
                            </Box>
                          </Tooltip>

                          {/* <Box
                            sx={{
                              width: '20%',
                              cursor: 'pointer',
                            }}
                          >
                            {iconCount(asset) > 0 ? (
                              <Typography
                                sx={{
                                  marginBottom: '4px',
                                  fontSize: '12px',
                                  color: 'rgba(235, 87, 87, 0.7)',
                                  fontWeight: 'bold',
                                }}
                                onClick={() => {
                                  handleEntityClick(asset, null, null, true);
                                }}
                              >{`+${iconCount(asset)}`}</Typography>
                            ) : null}
                          </Box> */}

                          {assetHasDTC(asset) && (
                            <Tooltip
                              title={'Asset has DTC notifications'}
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [0, -30],
                                      },
                                    },
                                  ],
                                },
                              }}
                              onClick={async () => {
                                await handleAlertClick(asset, 'dtc');
                              }}
                            >
                              <Box
                                sx={{
                                  maxWidth: '20px',
                                }}
                              >
                                <SVG
                                  name="dTCIcon"
                                  style={{
                                    fill: '#EB5757',
                                    height: '1.3em',
                                    width: '1.1em',
                                    cursor: 'pointer',
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          )}

                          {assetHasSuggestions(asset, suggestions) > 0 && (
                            <Tooltip
                              title={`Asset has ${assetHasSuggestions(
                                asset,
                                suggestions
                              )}  outlier trips`}
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [0, -30],
                                      },
                                    },
                                  ],
                                },
                              }}
                              onClick={() => {
                                handleTripClick(
                                  asset.bumperNumber,
                                  groupedOutliers[asset.bumperNumber][0]
                                );
                              }}
                            >
                              <Box
                                sx={{
                                  maxWidth: '20px',
                                }}
                              >
                                <SVG
                                  name="oTIcon"
                                  style={{
                                    fill: '#EB5757',
                                    height: '1.4em',
                                    width: '1.0em',
                                    cursor: 'pointer',
                                    ...{ '--icon-fill': '#EB5757' },
                                  }}
                                />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        width: '80%',
                        justifyContent: 'space-between',
                        padding: '10px',
                        marginTop: '-10px',
                      }}
                    >
                      <Typography
                        sx={{
                          color: enableDarkTheme
                            ? 'rgba(225,225,225, 0.5)'
                            : 'rgba(0,0,0, 0.5)',
                          fontSize: '10px',
                        }}
                      >
                        {assetTypeMappings[asset.assetType]}
                      </Typography>

                      {asset?.liveTagData?.gps_latitude &&
                      asset?.liveTagData?.gps_longitude ? null : (
                        <Typography
                          sx={{
                            color: enableDarkTheme
                              ? 'rgba(225,225,225, 0.5)'
                              : 'rgba(0,0,0, 0.5)',
                            backgroundColor: 'rgba(195, 195, 195, 0.2)',
                            padding: '2px 5px',
                            borderRadius: '5px',
                            fontSize: '10px',
                          }}
                        >
                          gps data not available
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        paddingLeft: '10px',
                        justifyContent: 'space-between',
                        width: '60%',
                        paddingBottom: '10px',
                      }}
                    >
                      <Tooltip title="Production Score">
                        <Box sx={{ display: 'flex' }}>
                          <SVG
                            name={'productionIcon'}
                            style={{
                              fill: enableDarkTheme ? '#ffffff' : '#000000',
                              height: '20px',
                              width: '20px',
                              paddingRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{
                              color: enableDarkTheme ? '#ffffff' : '#000000',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              paddingRight: '5px',
                            }}
                          >
                            {truncateTo2dp(
                              asset.productivity?.prod_score
                                ? asset.productivity.prod_score
                                : 0
                            )}
                            %
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip title="Utilization Score">
                        <Box sx={{ display: 'flex' }}>
                          <SVG
                            name={'utilizationIcon'}
                            style={{
                              fill: enableDarkTheme ? '#ffffff' : '#000000',
                              height: '20px',
                              width: '20px',
                              paddingRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{
                              color: enableDarkTheme ? '#ffffff' : '#000000',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              paddingRight: '5px',
                            }}
                          >
                            {truncateTo2dp(
                              asset.utilization?.util_score
                                ? +asset.utilization.util_score
                                : 0
                            )}
                            %
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip title="Fuel Usage Score">
                        <Box sx={{ display: 'flex' }}>
                          <SVG
                            name={'fuelUsageIcon'}
                            style={{
                              fill: enableDarkTheme ? '#ffffff' : '#000000',
                              height: '20px',
                              width: '20px',
                              paddingRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{
                              color: enableDarkTheme ? '#ffffff' : '#000000',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              display: 'flex',
                            }}
                          >
                            {/* {truncateTo2dp(
                          asset.fuelUsage?.fuel_score
                            ? +asset.fuelUsage.fuel_score
                            : 0
                        )}
                        % */}
                            {truncateTo2dp(
                              asset.fuelUsage?.hourly_fuel_consumption_l
                                ? asset.fuelUsage?.hourly_fuel_consumption_l
                                : 0
                            )}{' '}
                            <span
                              style={{
                                fontSize: '8px',
                                marginTop: '5px',
                                marginLeft: '2px',
                              }}
                            >
                              {' '}
                              {getHourlyFuelEfficencyLabelWrtUnitSystem(
                                unitSystem,
                                fuelIdleConsumptionLabels.total_fuel_consumed
                              )}
                            </span>
                          </Typography>
                        </Box>
                      </Tooltip>
                    </Box>
                    <Divider
                      sx={{
                        background: enableDarkTheme
                          ? 'rgba(225,225,225, 0.5)'
                          : ThemePalette.border,
                        fontSize: '10px',
                        width: '100%',
                      }}
                    />
                  </Grid>
                );
              })}
            </Box>
          )}
        </Box>

        {/* Analytics Panel */}
        {/* visibility icon */}
        <Box
          sx={{
            position: 'absolute',
            right: -20,
            top: 400,
            zIndex: 20,
          }}
        >
          <SVG
            name={
              isAssetPanelVisible.metrics
                ? 'pullRightChevron'
                : 'pullLeftChevron'
            }
            style={{
              marginTop: '8px',
              height: '50px',
              width: '60%',
              cursor: 'pointer',
            }}
            onClick={() => {
              onVisibleClick('metrics');
            }}
          />
        </Box>
        {isAssetPanelVisible.metrics && (
          <Box
            style={{
              position: 'absolute',
              top: 100,
              right: 20,
              width: '15%',
              height: '87%',
              backgroundColor: enableDarkTheme ? '#0d1b2a' : '#ffffff',
              zIndex: 10,
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
              padding: '10px',
              borderRadius: 5,
              overflowY: 'auto',
            }}
          >
            <Grid container spacing={2} sx={{ color: '#FFF' }}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    backgroundColor: enableDarkTheme ? '#16293E' : '#f3f4f2',
                    padding: 2,
                    borderRadius: 2,
                    border: 'solid 1px rgba(255,255,255, 0.3)',
                    color: enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.black,
                  }}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <SVG
                      name={'sidebarProduction'}
                      style={{
                        height: '25px',
                        width: '25px',
                        marginTop: '2px',
                        backgroundColor: 'rgba(54, 102, 236, 1)',
                        padding: '5px',
                        borderRadius: '45%',
                      }}
                    />

                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        transition:
                          'background-color 0.3s ease, transform 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.1)', // Slightly enlarges the element on hover
                        },
                      }}
                      onClick={() => {
                        navigate(`/production/overview`, {
                          state: {
                            type: 'total',
                          },
                        });
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                            md: '16px',
                            lg: '18px',
                          },
                        }}
                      >
                        Production
                      </Typography>

                      <ChevronRightIcon
                        sx={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                          fontWeight: 'bold',
                          fontSize: { xs: '12px', sm: '14px', md: '16px' },
                          marginLeft: '5px',
                        }}
                      />
                    </Grid>
                  </Grid>

                  {loadingStates.isLoadingFleetDetails ? (
                    <MultiColorCircularLoader height="300px" />
                  ) : (
                    <Box
                      sx={{
                        paddingTop: '15px',
                      }}
                    >
                      <Grid container>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '5px 0',
                            }}
                          >
                            <CircularProgressChart
                              enableDarkTheme={enableDarkTheme}
                              value={totalFleetDetails.tons}
                              target={productionTarget?.ytdTarget as number}
                              unitSystem={unitSystem}
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item lg={6} sm={12}>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                }}
                              >
                                YTD Target
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                  paddingBottom: '15px',
                                }}
                              >
                                {formatNumberToShortScale(
                                  convertToTwoDecimalPlaces(
                                    (
                                      productionTarget?.ytdTarget as number
                                    )?.toString()
                                  )
                                )}{' '}
                                {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                              </Typography>

                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                }}
                              >
                                Rolling Daily Avg
                              </Typography>
                              {loadingStates.isLoadingFleetPeriodSummary ? (
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.white
                                      : ThemePalette.typography.black,
                                    paddingTop: '10px',
                                  }}
                                >
                                  Loading...
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: '12px',
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.white
                                      : ThemePalette.typography.black,
                                    paddingTop: '10px',
                                  }}
                                >
                                  {formatNumberToShortScale(
                                    convertToTwoDecimalPlaces(
                                      (rollingDailyAverage || 0)?.toString()
                                    )
                                  )}
                                  <span
                                    style={{
                                      color: enableDarkTheme
                                        ? ThemePalette.typography.lightGrey
                                        : ThemePalette.typography.black,
                                    }}
                                  >
                                    {' '}
                                    {unitSystem === 'imperial'
                                      ? 'iTons/Day'
                                      : 'Tons/Day'}
                                  </span>
                                </Typography>
                              )}
                            </Box>
                          </Grid>

                          <Grid item lg={6} sm={12}>
                            <Box>
                              <Grid container>
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontSize: '11px',
                                      color: enableDarkTheme
                                        ? ThemePalette.typography.lightGrey
                                        : ThemePalette.typography.black,
                                    }}
                                  >
                                    Annual Target
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: '11px',
                                      color: enableDarkTheme
                                        ? ThemePalette.typography.white
                                        : ThemePalette.typography.black,
                                      paddingBottom: '15px',
                                    }}
                                  >
                                    {formatNumberToShortScale(
                                      convertToTwoDecimalPlaces(
                                        (
                                          productionTarget?.targetValue as number
                                        )?.toString()
                                      )
                                    )}{' '}
                                    {unitSystem === 'imperial'
                                      ? 'iTons'
                                      : 'Tons'}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: '11px',
                                      color: enableDarkTheme
                                        ? ThemePalette.typography.lightGrey
                                        : ThemePalette.typography.black,
                                    }}
                                  >
                                    Current Daily Avg
                                  </Typography>
                                  {loadingStates.isLoadingFleetPeriodSummary ? (
                                    <Typography
                                      sx={{
                                        fontSize: '13px',
                                        color: enableDarkTheme
                                          ? ThemePalette.typography.white
                                          : ThemePalette.typography.black,
                                        paddingTop: '10px',
                                      }}
                                    >
                                      Loading...
                                    </Typography>
                                  ) : (
                                    <Typography
                                      sx={{
                                        fontSize: '13px',
                                        color: enableDarkTheme
                                          ? ThemePalette.typography.white
                                          : ThemePalette.typography.black,
                                        paddingTop: '10px',
                                      }}
                                    >
                                      {utilLoadingState.isLoadingFleetPeriodSummary
                                        ? 'Loading...'
                                        : formatNumberToShortScale(
                                            convertToTwoDecimalPlaces(
                                              (
                                                currentDailyAverage ?? 0
                                              )?.toString()
                                            )
                                          )}
                                      <span
                                        style={{
                                          color: enableDarkTheme
                                            ? ThemePalette.typography.lightGrey
                                            : ThemePalette.typography.black,
                                        }}
                                      >
                                        {' '}
                                        {unitSystem === 'imperial'
                                          ? 'iTons/Day'
                                          : 'Tons/Day'}
                                      </span>
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>

                          {!hasMetTarget(
                            totalFleetDetails,
                            productionTarget?.targetValue as number
                          ) ? (
                            <></>
                          ) : (
                            <Box
                              sx={{
                                textAlign: 'center',
                                border: '1px solid #2F445F',
                                borderRadius: '5px',
                                marginTop: '15px',
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{ color: '#029161', marginRight: '4px' }}
                                />
                                Avg Production is on target
                              </Typography>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card
                  sx={{
                    backgroundColor: enableDarkTheme ? '#16293E' : '#f3f4f2',
                    padding: 2,
                    borderRadius: 2,
                    border: 'solid 1px rgba(255,255,255, 0.3)',
                    color: enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.black,
                  }}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <SVG
                      name={'sidebarUtilization'}
                      style={{
                        height: '25px',
                        width: '25px',
                        marginRight: '5px',
                        backgroundColor: 'rgba(54, 102, 236, 1)',
                        padding: '5px',
                        borderRadius: '45%',
                      }}
                    />
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        transition:
                          'background-color 0.3s ease, transform 0.2s ease',
                        '&:hover': {
                          transform: 'scale(1.1)', // Slightly enlarges the element on hover
                        },
                      }}
                      onClick={() => {
                        navigate(`/utilization`, {
                          state: {
                            type: 'total',
                          },
                        });
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 'bold',
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                            md: '16px',
                            lg: '18px',
                          },
                        }}
                      >
                        Utilization
                      </Typography>

                      <ChevronRightIcon
                        sx={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.wheat
                            : ThemePalette.typography.black,
                          fontWeight: 'bold',
                          fontSize: { xs: '12px', sm: '14px', md: '16px' },
                          marginLeft: '5px',
                        }}
                      />
                    </Grid>
                  </Grid>
                  {utilLoadingState.isLoadingFleetPeriodSummary ||
                  utilLoadingState.isLoadingFleetDetails ? (
                    <MultiColorCircularLoader height="300px" />
                  ) : (
                    <Box
                      sx={{
                        paddingTop: '15px',
                      }}
                    >
                      <Grid container>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: '5px 0',
                            }}
                          >
                            <UtilCircularProgressChart
                              enableDarkTheme={enableDarkTheme}
                              value={Number(
                                fleetUtilData?.total_engine_working_minutes
                              )}
                              target={
                                (utilizationTarget?.targetValue as number) * 60
                              }
                              unitSystem={unitSystem}
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item lg={6} sm={12}>
                            <Box>
                              <>
                                <Typography
                                  sx={{
                                    fontSize: '11px',
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.lightGrey
                                      : ThemePalette.typography.black,
                                  }}
                                >
                                  YTD Target
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: '11px',
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.white
                                      : ThemePalette.typography.black,
                                  }}
                                >
                                  {isLoadingTarget
                                    ? 'Loading...'
                                    : `${formatNumberToShortScale(
                                        utilizationTarget?.ytdTarget as number
                                      )} Hours`}
                                </Typography>
                              </>

                              <Typography
                                sx={{
                                  fontSize: '11px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                  paddingTop: '10px',
                                }}
                              >
                                Rolling Daily Average
                              </Typography>

                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                {sevenDayAvg > 0 ? (
                                  <>
                                    {truncateTo2dp(sevenDayAvg)}
                                    <span
                                      style={{
                                        color: enableDarkTheme
                                          ? ThemePalette.typography.lightGrey
                                          : ThemePalette.typography.black,
                                      }}
                                    >
                                      {' '}
                                      Hours/Day
                                    </span>
                                  </>
                                ) : (
                                  <>{'No data available'}</>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={6} sm={12}>
                            <Typography
                              sx={{
                                fontSize: '11px',
                                color: enableDarkTheme
                                  ? ThemePalette.typography.lightGrey
                                  : ThemePalette.typography.black,
                              }}
                            >
                              Annual Productivity Target
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: '12px',
                                color: enableDarkTheme
                                  ? ThemePalette.typography.white
                                  : ThemePalette.typography.black,
                              }}
                            >
                              {formatNumberToShortScale(
                                (utilizationTarget?.targetValue as number) ||
                                  365 * getShiftHours(customerCode) * noOfAssets
                              )}
                              <span
                                style={{
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                  paddingBottom: '15px',
                                }}
                              >
                                {' '}
                                Hours
                              </span>
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: '11px',
                                color: enableDarkTheme
                                  ? ThemePalette.typography.lightGrey
                                  : ThemePalette.typography.black,
                                paddingTop: '10px',
                              }}
                            >
                              Current Daily Average
                            </Typography>
                            {utilLoadingState.isLoadingFleetPeriodSummary ? (
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                Loading...
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                {truncateTo2dp(currentDailyAvg / 60)}
                                <span
                                  style={{
                                    color: enableDarkTheme
                                      ? ThemePalette.typography.lightGrey
                                      : ThemePalette.typography.black,
                                  }}
                                >
                                  {' '}
                                  Hours/Day
                                </span>
                              </Typography>
                            )}
                          </Grid>

                          {!(
                            Number(
                              utilData.allFleet.fleetDetails.fleet
                                .total_engine_working_minutes
                            ) /
                              60 >
                            (utilizationTarget?.targetValue as number)
                          ) ? (
                            <></>
                          ) : (
                            <Box
                              sx={{
                                textAlign: 'center',
                                border: '1px solid #2F445F',
                                borderRadius: '5px',
                                marginTop: '15px',
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <CheckCircleIcon
                                  sx={{ color: '#029161', marginRight: '4px' }}
                                />
                                Avg Productive hours are on target
                              </Typography>
                            </Box>
                          )}
                          {/* </Box> */}
                          {/* </Grid> */}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        >
          {/* Cesium Viewer */}
          <AssetMap
            filteredAssets={filteredArray}
            setselectedAsset={setselectedAsset}
            handleEntityClick={handleEntityClick}
            selectedAsset={selectedAsset}
            viewerRef={viewerRef}
            latestTagData={latestTagData}
            customerCode={customerCode}
          />
          {/* // ANOTHER COMPONENT Info box */}
          {selectedAsset && (
            <Box
              style={{
                position: 'absolute',
                width: 'auto',
                maxWidth: '35%',
                backgroundColor: enableDarkTheme ? '#09182D' : '#f2efef',
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
                zIndex: 10,
                left: clickPosition.x + 80,
                top: clickPosition.displayAbove
                  ? clickPosition.y - 280 // Adjust this value based on the height of your info box
                  : clickPosition.y + 50,
                transform: 'translate(-50%, 0)',
              }}
            >
              {/*  chatbox tail */}
              {showTail ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: clickPosition.displayAbove ? '100%' : '-20px', // Adjust tail position
                    left: '32%',
                    transform: 'translateX(-50%)',
                    width: '0',
                    height: '0',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: clickPosition.displayAbove
                      ? '10px solid transparent'
                      : '10px solid #0d1b2a',
                    borderTop: clickPosition.displayAbove
                      ? '10px solid #0d1b2a'
                      : '10px solid transparent',
                    zIndex: 1,
                  }}
                />
              ) : null}

              <Box
                sx={{
                  maxHeight: '100%',
                  overflowY: 'auto',
                  overflowX: 'auto',
                  paddingRight: '10px',
                }}
              >
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: 'center' }}
                >
                  {/* <Grid item> */}
                  <Box
                    sx={{
                      display: 'flex',
                      marginTop: '40px',
                      alignContent: 'start',
                      width: '100%',
                    }}
                  >
                    <Tooltip
                      title={`${
                        isAssetIgnition(latestTagData, selectedAsset)
                          ? 'Vehicle is ON'
                          : 'Vehicle is OFF'
                      }`}
                    >
                      <Box
                        sx={{
                          width: '30%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <Box
                          sx={{
                            position: 'absolute',
                            width: '25px',
                            height: '25px',
                            borderRadius: '50%',
                            bgcolor: isAssetIgnition(
                              latestTagData,
                              selectedAsset
                            )
                              ? '#4e9967'
                              : '#7a4c4c',
                            boxShadow: `0px 0px 20px 8px ${
                              isAssetIgnition(latestTagData, selectedAsset)
                                ? '#37a17a'
                                : '#5e4838'
                            }`,
                            transition: 'box-shadow 0.3s ease-in-out',
                          }}
                        />
                        <SVG
                          name={
                            selectedAsset.assetType === 'haul_truck'
                              ? includedLargeSVgs.includes(makeModel)
                                ? `${makeModel}ImageLarge`
                                : `cat810eImageLarge`
                              : `cat810eImageLarge`
                          }
                          style={{
                            height: '70px',
                            marginLeft: '-20px',
                            position: 'relative',
                            zIndex: 1,
                          }}
                        />
                      </Box>
                    </Tooltip>

                    <Box sx={{ width: '50%' }}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {selectedAsset.bumperNumber}
                      </Typography>
                      <Typography sx={{ fontSize: '10px' }}>
                        {assetTypeMappings[selectedAsset.assetType]}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        width: '10%',
                        position: 'absolute',
                        top: '10px',
                        right: '-10px',
                      }}
                    >
                      <Chip
                        size="medium"
                        icon={
                          <CloseIcon
                            sx={{
                              backgroundColor: 'rgba(242, 70, 70, .9)',
                              fontWeight: '800',
                              borderRadius: '5px',
                            }}
                            color="inherit"
                          />
                        }
                        sx={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          height: '25%',
                          cursor: 'pointer',
                          marginTop: '10%',
                          // marginLeft: '120%',
                          position: 'inherit',
                          transition:
                            'background-color 0.3s ease, transform 0.2s ease',
                          '&:hover': {
                            backgroundColor: 'rgba(200, 50, 50, 1)',
                            transform: 'scale(1.1)',
                          },
                        }}
                        onClick={() => {
                          setselectedAsset(null);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      position: 'inherit',
                      justifyContent: 'space-around',
                      margin: '0px 10px',
                      // marginTop: '2px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        width: '90%',
                        position: 'inherit',
                        marginTop: '10px',
                        justifyContent: 'space-between',
                      }}
                    >
                      {/* </Grid>
                <Grid item> */}
                      <Tooltip
                        title={`Asset Status: ${
                          getIsAssetOutOfService(selectedAsset)
                            ? 'Out of Service'
                            : 'Operational'
                        }`}
                      >
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginX: '5px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            padding: '8px',
                            backgroundColor: getIsAssetOutOfService(
                              selectedAsset
                            )
                              ? 'rgba(195, 195, 195, 0.27)'
                              : 'rgba(0, 0, 255, 0.3)',
                            height: '45%',
                            borderRadius: '10px',
                          }}
                        >
                          <span>
                            <SVG
                              name={'hillsIcon'}
                              style={{
                                height: '15px',
                                width: '15px',
                                marginRight: '5px',
                                marginBottom: '-5px',
                                ...svgStyleImport(selectedAsset),
                              }}
                            />
                          </span>
                          {getIsAssetOutOfService(selectedAsset)
                            ? 'Asset Out Of Service'
                            : 'Asset Operational'}
                        </Typography>
                      </Tooltip>

                      <Tooltip
                        title={`Notification Level: ${notificationLevelMap.message}`}
                      >
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            padding: '8px',
                            backgroundColor: notificationLevelMap.color,
                            height: '45%',
                            borderRadius: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={async () => {
                            await handleAlertClick(selectedAsset);
                          }}
                        >
                          <span>
                            <SVG
                              name={
                                notificationLevelIndex.level <= 1
                                  ? 'optimalPerformanceIcon'
                                  : 'nonOptimalPerformanceIcon'
                              }
                              style={{
                                height: '15px',
                                width: '15px',
                                marginRight: '5px',
                                marginBottom: '-5px',
                                ...svgStylePerformanceIcon(
                                  notificationLevelMap.color
                                ),
                              }}
                            />
                          </span>
                          {notificationLevelMap.message}
                        </Typography>
                      </Tooltip>

                      <Tooltip
                        title={`Device Status: ${selectedAsset.device.deviceStatus}`}
                      >
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            padding: '8px',
                            marginX: '5px',
                            backgroundColor:
                              selectedAsset.device.deviceStatus === 'Online'
                                ? 'rgba(0, 145, 97, 0.2)'
                                : selectedAsset.device.deviceStatus ===
                                  'Recently Online'
                                ? 'rgba(104, 136, 214, .5)'
                                : selectedAsset.device.deviceStatus ===
                                  'Offline'
                                ? 'rgba(244, 124, 2, .5)'
                                : ' rgba(235, 87, 87, 0.5)',
                            height: '45%',
                            borderRadius: '10px',
                          }}
                        >
                          <span>
                            <SVG
                              name={'fillableXLogo'}
                              fill={
                                selectedAsset.device.deviceStatus === 'Online'
                                  ? 'rgba(43, 198, 25, 0.8)'
                                  : selectedAsset.device.deviceStatus ===
                                    'Recently Online'
                                  ? '#6888d6'
                                  : selectedAsset.device.deviceStatus ===
                                    'Offline'
                                  ? '#F47C02'
                                  : '#EB5757'
                              }
                              style={{
                                height: '15px',
                                width: '15px',
                                cursor: 'pointer',
                                marginRight: '5px',
                                marginBottom: '-5px',
                                ...svgStyleRedIcon(selectedAsset),
                              }}
                            />
                          </span>
                          {selectedAsset.device.deviceStatus}
                        </Typography>
                      </Tooltip>
                      {assetHasDTC(selectedAsset) && (
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginX: '5px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            padding: '8px',
                            backgroundColor: 'rgba(235, 87, 87, 0.5)',
                            height: '45%',
                            borderRadius: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={async () => {
                            await handleAlertClick(selectedAsset, 'dtc');
                          }}
                        >
                          <span>
                            <SVG
                              name="dTCIcon"
                              style={{
                                fill: '#EB5757',
                                height: '15px',
                                width: '15px',
                                marginRight: '5px',
                                marginBottom: '-5px',
                                cursor: 'pointer',
                              }}
                            />
                          </span>
                          {'DTC'}
                        </Typography>
                      )}

                      {assetHasSuggestions(selectedAsset, suggestions) > 0 && (
                        <Tooltip
                          title={`Asset has ${assetHasSuggestions(
                            selectedAsset,
                            suggestions
                          )}  outlier trips`}
                        >
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginX: '5px',
                              fontSize: '10px',
                              fontWeight: 'bold',
                              padding: '8px',
                              backgroundColor: 'rgba(235, 87, 87, 0.5)',
                              // maxHeight: '17px',
                              height: '45%',
                              borderRadius: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              handleTripClick(
                                selectedAsset.bumperNumber,
                                groupedOutliers[selectedAsset.bumperNumber][0]
                              );
                            }}
                          >
                            <span>
                              <SVG
                                name="oTIcon"
                                style={{
                                  fill: '#EB5757',
                                  height: '15px',
                                  width: '15px',
                                  marginRight: '5px',
                                  marginBottom: '-5px',
                                  cursor: 'pointer',
                                  ...{ '--icon-fill': '#EB5757' },
                                }}
                              />
                            </span>
                            OT
                          </Typography>
                        </Tooltip>
                      )}
                    </Box>
                    {/* </Grid> */}
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    gap={0.5}
                    sx={{ padding: '0 10% 0 20%', marginY: '20px' }}
                  >
                    <Typography variant="small">
                      OEM: {selectedAsset.make}
                    </Typography>
                    <Typography variant="small">
                      Model: {selectedAsset.model}
                    </Typography>
                    <Typography variant="small">
                      Last Connected: {timeAgo(selectedAsset.device.ingestDate)}
                    </Typography>
                  </Box>

                  {/* <Grid
                  container
                  sx={{
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    margin: '10px',
                  }}
                > */}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      margin: '10px 10px 20px 40px',
                    }}
                  >
                    <Card
                      sx={{
                        padding: '5px',
                        backgroundColor: enableDarkTheme
                          ? '#16293E'
                          : '#f3f4f2',
                        borderRadius: 2,
                        border: 'solid 1px rgba(255,255,255, 0.3)',
                        color: enableDarkTheme
                          ? ThemePalette.typography.wheat
                          : ThemePalette.typography.black,
                        width: '33%',
                        marginRight: '5px',
                      }}
                    >
                      <AssetCardFuelUsage
                        asset={selectedAsset}
                        enableDarkTheme={enableDarkTheme}
                        isLoadingAllAssets={isLoadingAllAssets}
                        dateComparer={'day'}
                      />
                    </Card>

                    <Card
                      sx={{
                        padding: '5px',
                        backgroundColor: enableDarkTheme
                          ? '#16293E'
                          : '#f3f4f2',
                        borderRadius: 2,
                        border: 'solid 1px rgba(255,255,255, 0.3)',
                        color: enableDarkTheme
                          ? ThemePalette.typography.wheat
                          : ThemePalette.typography.black,
                        width: '33%',
                        marginRight: '5px',
                      }}
                    >
                      <AssetCardProductivity
                        asset={selectedAsset}
                        enableDarkTheme={enableDarkTheme}
                        isLoadingAllAssets={isLoadingAllAssets}
                        dateComparer={'day'}
                      />
                    </Card>

                    <Card
                      sx={{
                        padding: '5px',
                        backgroundColor: enableDarkTheme
                          ? '#16293E'
                          : '#f3f4f2',
                        borderRadius: 2,
                        border: 'solid 1px rgba(255,255,255, 0.3)',
                        color: enableDarkTheme
                          ? ThemePalette.typography.wheat
                          : ThemePalette.typography.black,
                        width: '33%',
                        marginRight: '5px',
                      }}
                    >
                      <AssetCardUtilization
                        asset={selectedAsset}
                        enableDarkTheme={enableDarkTheme}
                        isLoadingAllAssets={isLoadingAllAssets}
                        dateComparer={'day'}
                      />
                    </Card>
                  </Box>
                  {/* </Grid> */}
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default MapView;
