import { Box, Grid, Typography } from '@mui/material';
import { Base } from 'components/Containers/BaseContainer';
import { ThemePalette } from 'mui.theme';
import React from 'react';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ComponentsPieChart } from '../../Parts/Components/PieChart';
import ComponentsLineChart from '../../Parts/Components/LineChart';
import { extractSelectedAssets } from '../../Parts/utils';
import OverflowingTitles from './OverflowingTitles';
import { pastelColorMap, generateRandomColor } from 'utils/helpers/general';

interface ComponentsCostBreakdownProps {
  pieChartData: any;
  selectedCell: any;
}

const ComponentsCostBreakdown = ({
  selectedCell,
  pieChartData,
}: ComponentsCostBreakdownProps) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const {
    dashboard: { assetComponentsCosts },
  } = useAppSelector((state) => state.squaresDashboardReducer);

  const [selectedComponents, setSelectedComponents] = React.useState<string[]>(
    []
  );

  const selectedAssetsData = extractSelectedAssets(
    assetComponentsCosts,
    selectedCell,
    selectedComponents[0]
  );

  const handlePieClick = (data: any, persistData?: boolean) => {
    const name = data.name;
    // data.fill has the color of selected data point. Use this in future if you need to sync colors
    // between pie chart and line/area chart next to it
    const updatedClickedCells = [...selectedComponents];
    if (updatedClickedCells.includes(name) && !persistData) {
      const indexToRemove = updatedClickedCells.indexOf(name);
      updatedClickedCells.splice(indexToRemove, 1);
    } else {
      updatedClickedCells.pop();
      updatedClickedCells.push(name);
    }
    setSelectedComponents(updatedClickedCells);
  };

  const sortedData = [...pieChartData].sort(
    (a: any, b: any) => b.total_asset_cost - a.total_asset_cost
  );

  const top5CostingComponents = [...sortedData].splice(0, 5);

  return (
    <Base enableDarkTheme={enableDarkTheme} sx={{ minHeight: '433px' }}>
      <Grid
        sx={{
          display: 'flex',
          marginLeft: '0px',
          maxWidth: '100%',
          paddingBottom: '30px',
        }}
        container
        spacing={1}
      >
        <Grid item lg={5} md={4} sm={12} xs={12}>
          <Base
            sx={{
              height: '100%',
              maxHeight: '100%',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.toolBarBackground
                : ThemePalette.light.toolBarBackground,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '15px',
                  paddingTop: '30px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {`Component wise cost breakdown for `}
              </Typography>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '20px',

                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {`${selectedCell?.slice(0, 2)?.join(', ')}`}
              </Typography>
              <OverflowingTitles allTitles={selectedCell} overflowAfter={2} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
              }}
            >
              <Grid container spacing={2}>
                <Grid item lg={8} sm={12} md={12} xs={12}>
                  <ComponentsPieChart
                    data={sortedData}
                    handlePieClick={handlePieClick}
                    enableDarkTheme={enableDarkTheme}
                    onlyOneActive
                    label="Component"
                    valueLabel="Total Component Cost"
                  />
                </Grid>
                <Grid item lg={4} sm={12} md={12} xs={12}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      marginBottom: '10px',
                    }}
                  >
                    Top 5 costing components
                  </Typography>
                  {top5CostingComponents.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        gap={1}
                        flexWrap="wrap"
                      >
                        <Box
                          sx={{
                            width: 8,
                            height: 8,
                            backgroundColor:
                              pastelColorMap[index] || generateRandomColor(),
                            borderRadius: 1,
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: '11px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            wordBreak: 'break-word',
                          }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </Base>
        </Grid>
        <Grid item lg={7} md={8} sm={12} xs={12}>
          <Base
            sx={{
              height: '100%',
              maxHeight: '100%',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.toolBarBackground
                : ThemePalette.light.toolBarBackground,
            }}
          >
            {!selectedComponents[0] ||
            selectedComponents?.length < 1 ||
            selectedCell?.length < 1 ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '20px',
                    paddingTop: '10px',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  Please select a component from the pie chart.
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '15px',
                      paddingTop: '30px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    {`Distribution of ${
                      selectedComponents[0]
                    } for ${selectedCell?.slice(0, 2)?.join(', ')}`}
                  </Typography>
                  <OverflowingTitles
                    allTitles={selectedCell}
                    overflowAfter={2}
                  />
                </Box>
                <Box sx={{ width: '100%', height: '100%' }}>
                  <ComponentsLineChart data={selectedAssetsData} />
                </Box>
              </Box>
            )}
          </Base>
        </Grid>
      </Grid>
    </Base>
  );
};

export default ComponentsCostBreakdown;
