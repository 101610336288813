import dayjs from 'dayjs';
import { type EngineHourDriverBehaviourEventConfig } from 'types/driver.behavior.types';
import { allEngineHoursEvents, allEvents } from 'utils/driverBehavior';
// import { truncateTo2dp } from 'utils/helpers/general';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';

export const tooltip = ({
  seriesIndex,
  dataPointIndex,
  w,
  unitSystem,
}: any): string => {
  const seriesName = w.globals.initialSeries[seriesIndex]?.name;
  const combinedEvents = structuredClone(allEvents);
  combinedEvents.push(...structuredClone(allEngineHoursEvents));
  const eventConfig = combinedEvents.find(
    (event: EngineHourDriverBehaviourEventConfig) => event.name === seriesName
  );
  const dataPoint = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  const seriesColor = w.globals.colors[seriesIndex];
  const title = dataPoint.x; // Include the title
  const startTime = dayjs(dataPoint.y[0]).format('YYYY-MM-DD HH:mm');
  const endTime = dayjs(dataPoint.y[1]).format('YYYY-MM-DD HH:mm');
  const durationMs = dayjs(dataPoint.y[1]).diff(dayjs(dataPoint.y[0]));

  // Function to format the duration dynamically
  const formatDuration = (durationMs: number): string => {
    const totalSeconds = Math.floor(durationMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''}${
        minutes > 0 ? ` and ${minutes} minute${minutes > 1 ? 's' : ''}` : ''
      }`;
    }
    if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}${
        seconds > 0 ? ` and ${seconds} second${seconds > 1 ? 's' : ''}` : ''
      }`;
    }
    return `${seconds} second${seconds > 1 ? 's' : ''}`;
  };

  const formattedDuration = formatDuration(durationMs);

  return `
      <div style="
        background: #2E3B4E; 
        color: #FFFFFF; 
        padding: 12px; 
        border-radius: 8px; 
        font-family: Arial, sans-serif; 
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
        max-width: 200px;">
        <div style="font-weight: bold; font-size: 14px; margin-bottom: 8px; text-align: center; color: white;">
          ${title}
        </div>
        <div style="font-size: 12px; margin-bottom: 4px; text-transform: capitalize;">
          <span style="color: ${seriesColor}; font-weight: bold;">Event:</span> ${seriesName}
        </div>
        ${
          eventConfig?.isInstantEvent
            ? `
            <div style="font-size: 12px; margin-bottom: 4px;">
              <span style="color: ${seriesColor}; font-weight: bold;">Event Time:</span> ${startTime}
            </div>
            `
            : `
        <div style="font-size: 12px; margin-bottom: 4px;">
          <span style="color: ${seriesColor}; font-weight: bold;">Start:</span> ${startTime}
        </div>
        <div style="font-size: 12px; margin-bottom: 4px;">
          <span style="color: ${seriesColor}; font-weight: bold;">End:</span> ${endTime}
        </div>
        `
        }
        ${
          eventConfig?.tooltipConfig?.showDuration
            ? `
          <div style="font-size: 12px; margin-bottom: 4px;">
            <span style="color: ${seriesColor}; font-weight: bold;">Duration:</span> ${formattedDuration}
          </div>
          `
            : ``
        }
        ${
          eventConfig?.tooltipConfig?.showCurrentAndNextGear
            ? `
              <div style="font-size: 12px; margin-bottom: 4px;">
                <span style="color: ${seriesColor}; font-weight: bold;">Current Gear:</span> ${dataPoint?.current_gear}
              </div>
              <div style="font-size: 12px; margin-bottom: 4px;">
                <span style="color: ${seriesColor}; font-weight: bold;">Next Gear:</span> ${dataPoint?.next_gear}
              </div>
            `
            : ``
        }
        ${
          eventConfig?.tooltipConfig?.showGear
            ? `
              <div style="font-size: 12px; margin-bottom: 4px;">
                <span style="color: ${seriesColor}; font-weight: bold;">Gear:</span> ${dataPoint?.gear_value}
              </div>
            `
            : ``
        }
        ${
          eventConfig?.tooltipConfig?.showCurrentAndPreviousSpeed
            ? `
              <div style="font-size: 12px; margin-bottom: 4px;">
                <span style="color: ${seriesColor}; font-weight: bold;">Previous Speed:</span>
                ${dataPoint?.previous_speed}
                ${getLabelWrtUnitSystem('KMH', unitSystem)}
              </div>
              <div style="font-size: 12px; margin-bottom: 4px;">
                <span style="color: ${seriesColor}; font-weight: bold;">Current Speed:</span>
                ${dataPoint?.current_speed}
                ${getLabelWrtUnitSystem('KMH', unitSystem)}
              </div>
              <div style="font-size: 12px; margin-bottom: 4px;">
                <span style="color: ${seriesColor}; font-weight: bold;">Difference:</span>
                ${
                  Number(dataPoint?.current_speed) -
                  Number(dataPoint?.previous_speed)
                }
                ${getLabelWrtUnitSystem('KMH', unitSystem)}
              </div>
            `
            : ``
        }
        ${
          eventConfig?.tooltipConfig?.showSpeed
            ? `
              <div style="font-size: 12px; margin-bottom: 4px;">
                <span style="color: ${seriesColor}; font-weight: bold;">Speed:</span>
                ${
                  dataPoint?.speed !== null && +dataPoint?.speed >= 0
                    ? dataPoint?.speed
                    : 'No data'
                }
                ${
                  dataPoint?.speed !== null && +dataPoint?.speed >= 0
                    ? getLabelWrtUnitSystem('KMH', unitSystem)
                    : ''
                }
              </div>
          `
            : ``
        }
        ${
          dataPoint?.avg_speed
            ? `
          <div style="font-size: 12px; margin-top: 8px; color: white;">
            <span style="color: ${seriesColor}; font-weight: bold;">Average Speed:</span>
            ${dataPoint?.avg_speed}
            ${getLabelWrtUnitSystem('KMH', unitSystem)}
          </div>
          `
            : ``
        }
      </div>
    `;
};

/**
 * TODO: add this later on....
${
          averageFuelRate !== null
            ? `<div style="color: #FF0000; font-size: 14px;">Average Fuel Rate: ${truncateTo2dp(
                averageFuelRate
              )} ${getLabelWrtUnitSystem('LPH', unitSystem)} </div>`
            : ''
        }
                ${
                  averageRpm !== null
                    ? `<div style="color: #FF0000; font-size: 14px;">Average Fuel Rate: ${truncateTo2dp(
                        averageRpm
                      )}</div>`
                    : ''
                }
 */
