import React from 'react';
import {
  Card,
  Typography,
  LinearProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

interface MeshStatusProps {
  nodes?: Array<{ id: string; name: string; rssi: number }>;
}

// Master list of nodes that should appear
const DEFAULT_NODE_NAMES = [
  'Gateway1',
  'Node1',
  'Node2',
  'Node3',
  'Node4',
  'Node5',
];

function getSignalColor(rssi: number): string {
  if (rssi >= -60) return '#4caf50'; // Excellent (Green)
  if (rssi >= -70) return '#8bc34a'; // Good (Light Green)
  if (rssi >= -80) return '#ffc107'; // Fair (Amber)
  if (rssi >= -90) return '#ff9800'; // Poor (Orange)
  return '#f44336'; // Very Poor (Red)
}

function getSignalPercentage(rssi: number): number {
  const min = -180;
  const max = -10;
  const clamped = Math.max(min, Math.min(rssi, max));
  return ((clamped - min) / (max - min)) * 100;
}

const MeshStatus: React.FC<MeshStatusProps> = ({ nodes = [] }) => {
  const displayedNodes = DEFAULT_NODE_NAMES.map((name) => {
    const found = nodes.find((n) => n.name === name);
    if (found) {
      return { ...found, offline: false };
    }
    return { id: name, name, rssi: -180, offline: true };
  });

  const onlineNodes = displayedNodes.filter((node) => !node.offline);
  const offlineNodes = displayedNodes.filter((node) => node.offline);

  const finalNodes = [...onlineNodes, ...offlineNodes];

  return (
    <Card sx={{ backgroundColor: '#112240', p: 2, color: 'white' }}>
      <Typography variant="h6" gutterBottom>
        Mesh Status
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: 300 }} aria-label="mesh status table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderBottom: 'none',
                }}
              >
                Asset
              </TableCell>
              <TableCell
                sx={{
                  color: 'white',
                  fontWeight: 'bold',
                  borderBottom: 'none',
                }}
              >
                Signal Strength
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalNodes.map((node) => {
              if (node.offline) {
                // Node is offline => show warning icon with a tooltip
                return (
                  <TableRow key={node.id}>
                    <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                      {node.name}
                    </TableCell>
                    <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                      <Tooltip title="Offline">
                        <WarningIcon sx={{ color: '#ff9800' }} />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              } else {
                // Node is online => show the signal bar with a tooltip
                const percentage = getSignalPercentage(node.rssi);
                const color = getSignalColor(node.rssi);

                return (
                  <TableRow key={node.id}>
                    <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                      {node.name}
                    </TableCell>
                    <TableCell sx={{ color: 'white', borderBottom: 'none' }}>
                      <Tooltip title={`Signal: ${node.rssi.toFixed(2)} dBm`}>
                        <Box sx={{ width: '100%', minWidth: '120px' }}>
                          <LinearProgress
                            variant="determinate"
                            value={percentage}
                            sx={{
                              height: 10,
                              borderRadius: 5,
                              backgroundColor: 'rgba(255,255,255,0.2)',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: color,
                              },
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default MeshStatus;
