import { ReactComponent as SidebarAnalytics } from 'assets/icons/sidebar-analytics.svg';
import { ReactComponent as SidebarFleetOverview } from 'assets/icons/sidebar-fleet-overview.svg';
import { ReactComponent as SidebarUtilization } from 'assets/icons/side-bar-utilization.svg';
import { ReactComponent as SidebarFuelUsage2 } from 'assets/icons/side-bar-fuel-usage.svg';
import { ReactComponent as SidebarProduction } from 'assets/icons/side-bar-production.svg';
import { ReactComponent as SidebarNotificationCenter } from 'assets/icons/side-bar-notification-center.svg';
import { ReactComponent as SidebarRoadCondition } from 'assets/icons/sidebar-road-condition.svg';
import { ReactComponent as MlInsightsCondition } from 'assets/icons/side-bar-insights-icon.svg';
import { ReactComponent as SidebarVehicleStatus } from 'assets/icons/side-bar-vehicle-status.svg';
import { ReactComponent as SidebarFleetOverview2 } from 'assets/icons/side-bar-fleet-overview.svg';
import { ReactComponent as SidebarFuelUsage } from 'assets/icons/Icon_nav_fuel_usage.svg';
import { ReactComponent as SidebarSettings } from 'assets/icons/sidebar-settings.svg';
import { ReactComponent as SidebarSquares } from 'assets/icons/sidebar-squares.svg';
import { ReactComponent as SidebarSWLogo } from 'assets/icons/sidebar-sw-logo.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as TableView } from 'assets/icons/icon-table.svg';
import { ReactComponent as HierachyView } from 'assets/icons/icon-hierarchy.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/icons/email-icon.svg';
import { ReactComponent as ResetIcon } from 'assets/icons/reset-icon.svg';
import { ReactComponent as ActiveIcon } from 'assets/icons/active-icon.svg';
import { ReactComponent as InActiveIcon } from 'assets/icons/icon-remove.svg';
import { ReactComponent as OfflineIcon } from 'assets/icons/icon-offline.svg';
import { ReactComponent as GreenIcon } from 'assets/icons/green-icon.svg';
import { ReactComponent as RedIcon } from 'assets/icons/red-icon.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as ViewGridIcon } from 'assets/icons/icon_views_grid_view.svg';
import { ReactComponent as ViewTableIcon } from 'assets/icons/icon_views_table_view.svg';
import { ReactComponent as ViewMapIcon } from 'assets/icons/icon_views_map_view.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/icon-copy.svg';
import { ReactComponent as AddDisabledIcon } from 'assets/icons/add-disabled.svg';
import { ReactComponent as UnlinkIcon } from 'assets/icons/icon-unlink.svg';
import { ReactComponent as UnlinkedDeviceStatusIcon } from 'assets/icons/icon-device-status-unlinked.svg';
import { ReactComponent as InfoTooltipIcon } from 'assets/icons/icon-info-tooltip.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/icon_interface_notification.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/x-thin.svg';
import { ReactComponent as SidebarTirePressure } from 'assets/icons/sidebar-tire-pressure.svg';
import { ReactComponent as RedFuelFilter } from 'assets/icons/red_fuel_filter.svg';
import { ReactComponent as DriverBehavior } from 'assets/icons/driver_behavior.svg';
import { ReactComponent as NetworkHealth } from 'assets/icons/networkHealth.svg';
import { ReactComponent as FuelUsageIcon } from 'assets/icons/fuel-usage-icon.svg';
import { ReactComponent as UtilizationIcon } from 'assets/icons/utilization-icon.svg';
import { ReactComponent as ProductionIcon } from 'assets/icons/production-icon.svg';
import { ReactComponent as HideIcon } from 'assets/icons/hide-eye-icon.svg';
import { ReactComponent as EyeViewIcon } from 'assets/icons/eye-svgrepo-com.svg';
import { ReactComponent as OptimalPerformanceIcon } from 'assets/icons/performance-green.svg';
import { ReactComponent as NonOptimalPerformanceIcon } from 'assets/icons/performance-red.svg';
import { ReactComponent as DTCIcon } from 'assets/icons/dtc.svg';
import { ReactComponent as PullRightChevron } from 'assets/icons/chevron-right.svg';
import { ReactComponent as PullLeftChevron } from 'assets/icons/chevron-left.svg';
import { ReactComponent as OTIcon } from 'assets/icons/trip-icon.svg';

// map icons
import { ReactComponent as AlertsOnlineHaulTruck } from 'assets/icons/mapIcons/alert-online-haulTruck.svg';
import { ReactComponent as HillsIcon } from 'assets/icons/mapIcons/hills.svg';
import { ReactComponent as GreenIcon2 } from 'assets/icons/green-icon-2.svg';
import { ReactComponent as RedIcon2 } from 'assets/icons/red-icon-2.svg';
import { ReactComponent as NavPin } from 'assets/icons/mapIcons/navigation-pin.svg';

// logos
import { ReactComponent as SidebarLogoMain } from 'assets/logos/sidebar-logo-main.svg';
import { ReactComponent as SymboticwareLogoFull } from 'assets/logos/sw-logo-full.svg';
import { ReactComponent as SymboticwareLogoFullDirectionColumn } from 'assets/logos/full-logo-direction-column.svg';
import { ReactComponent as BuzziReportLogo } from 'assets/logos/buzzi.svg';
import { ReactComponent as FillableXLogo } from 'assets/logos/symboticware_logo_fillable.svg';

// bckgrounds
import { ReactComponent as BackgroundImage } from 'assets/bg/sidebar-bg.svg';

// vehicles
import { ReactComponent as BolterImage } from 'assets/vehicles/icon_assets_bolter.svg';
import { ReactComponent as DozerImage } from 'assets/vehicles/icon_assets_dozer.svg';
import { ReactComponent as ExcavatorImage } from 'assets/vehicles/icon_assets_excavator.svg';
import { ReactComponent as HaulTruckImage } from 'assets/vehicles/icon_assets_haul_truck.svg';
import { ReactComponent as HeavyTruckImage } from 'assets/vehicles/icon_assets_heavy_truck.svg';
import { ReactComponent as JumboDrillImage } from 'assets/vehicles/icon_assets_jumbo_drill.svg';
import { ReactComponent as LightTruckImage } from 'assets/vehicles/icon_assets_light_truck.svg';
import { ReactComponent as LoaderImage } from 'assets/vehicles/icon_assets_loader.svg';
import { ReactComponent as PumpImage } from 'assets/vehicles/icon_assets_pump.svg';
import { ReactComponent as VerticalDrillImage } from 'assets/vehicles/icon_assets_vert_drill.svg';
import { ReactComponent as HaulTruckAssetImage } from 'assets/vehicles/image_haul_truck.svg';
import { ReactComponent as CAT810eImageLarge } from 'assets/vehicles/large/cat-810e.svg';
import { ReactComponent as CAT795fImageLarge } from 'assets/vehicles/large/cat-795f.svg';
import { ReactComponent as CAT798ImageLarge } from 'assets/vehicles/large/cat-798.svg';
import { ReactComponent as CAT777ImageLarge } from 'assets/vehicles/large/cat-777.svg';
import { ReactComponent as CAT745cImageLarge } from 'assets/vehicles/large/cat-745c.svg';
import { ReactComponent as Hitachieh1700ImageLarge } from 'assets/vehicles/large/hitachi-eh1700.svg';

import { ReactComponent as EngineLight } from 'assets/icons/engine_light.svg';
import { ReactComponent as StreetIcon } from 'assets/icons/street_icon.svg';

import {
  type CSSProperties,
  type FunctionComponent,
  type SVGProps,
} from 'react';

const icons: Record<
  string,
  FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>
> = {
  sidebarAnalytics: SidebarAnalytics,
  sidebarFleetOverview: SidebarFleetOverview,
  sidebarSquares: SidebarSquares,
  sidebarSymboticwareLogo: SidebarSWLogo,
  sidebarSettings: SidebarSettings,
  deleteIcon: DeleteIcon,
  tableView: TableView,
  hierachyView: HierachyView,
  envelopeIcon: EnvelopeIcon,
  resetIcon: ResetIcon,
  activeIcon: ActiveIcon,
  greenIcon: GreenIcon,
  redIcon: RedIcon,
  greenIcon2: GreenIcon2,
  redIcon2: RedIcon2,
  inActiveIcon: InActiveIcon,
  offlineIcon: OfflineIcon,
  viewGridIcon: ViewGridIcon,
  viewTableIcon: ViewTableIcon,
  viewMapIcon: ViewMapIcon,
  copyIcon: CopyIcon,
  unlinkIcon: UnlinkIcon,
  unlinkedDeviceStatusIcon: UnlinkedDeviceStatusIcon,
  infoTooltipIcon: InfoTooltipIcon,
  sidebarFuelUsage: SidebarFuelUsage,
  notificationIcon: NotificationIcon,
  sidebarUtilization: SidebarUtilization,
  sidebarFuelUsage2: SidebarFuelUsage2,
  sidebarProduction: SidebarProduction,
  sidebarNotificationCenter: SidebarNotificationCenter,
  sidebarRoadCondition: SidebarRoadCondition,
  mlInsightsCondition: MlInsightsCondition,
  sidebarVehicleStatus: SidebarVehicleStatus,
  sidebarFleetOverview2: SidebarFleetOverview2,
  sidebarTirePressure: SidebarTirePressure,
  redFuelFilter: RedFuelFilter,
  driverBehavior: DriverBehavior,
  networkHealth: NetworkHealth,
  fuelUsageIcon: FuelUsageIcon,
  productionIcon: ProductionIcon,
  utilizationIcon: UtilizationIcon,
  hideIcon: HideIcon,
  eyeViewIcon: EyeViewIcon,
  optimalPerformanceIcon: OptimalPerformanceIcon,
  nonOptimalPerformanceIcon: NonOptimalPerformanceIcon,
  dTCIcon: DTCIcon,
  pullLeftChevron: PullLeftChevron,
  pullRightChevron: PullRightChevron,
  oTIcon: OTIcon,

  // icons
  add: AddIcon,
  addDisabled: AddDisabledIcon,
  cancelIcon: CancelIcon,

  // map icons
  alertsOnlineHaulTruck: AlertsOnlineHaulTruck,
  hillsIcon: HillsIcon,
  navPin: NavPin,

  // logos
  sidebarLogoMain: SidebarLogoMain,
  symboticwareLogoFull: SymboticwareLogoFull,
  symboticwareLogoFullDirectionColumn: SymboticwareLogoFullDirectionColumn,
  buzziReportLogo: BuzziReportLogo,
  fillableXLogo: FillableXLogo,

  // backgrounds
  bg: BackgroundImage,

  // vehicles
  bolterImage: BolterImage,
  dozerImage: DozerImage,
  graderImage: DozerImage,
  excavatorImage: ExcavatorImage,
  haulTruckImage: HaulTruckImage,
  heavyTruckImage: HeavyTruckImage,
  jumboDrillImage: JumboDrillImage,
  lightTruckImage: LightTruckImage,
  loaderImage: LoaderImage,
  pumpImage: PumpImage,
  verticalDrillImage: VerticalDrillImage,
  haulTruckAssetImage: HaulTruckAssetImage,
  cat810eImageLarge: CAT810eImageLarge,
  cat795fImageLarge: CAT795fImageLarge,
  cat798ImageLarge: CAT798ImageLarge,
  cat777ImageLarge: CAT777ImageLarge,
  cat745cImageLarge: CAT745cImageLarge,
  hitachieh1700ImageLarge: Hitachieh1700ImageLarge,
  engineLight: EngineLight,
  streetIcon: StreetIcon,
};

interface Props extends SVGProps<SVGSVGElement> {
  name?: string;
  isHovering?: boolean;
  style?: CSSProperties;
}

export const SVG = ({
  name = '', // TODO change this to generic
  isHovering = false,
  style,
  ...rest
}: Props): JSX.Element | null => {
  const Icon = icons[name];

  if (!Icon) return null;

  return <Icon {...rest} style={style} />;
};
