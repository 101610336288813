import React from 'react';
import { Card, Typography, Box } from '@mui/material';

interface NetworkStrengthProps {
  status: string;
}

const NetworkStrength: React.FC<NetworkStrengthProps> = ({ status }) => {
  return (
    <Card
      sx={{
        backgroundColor: '#112240',
        p: 2,
        textAlign: 'center',
        color: 'white',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Network Strength
      </Typography>
      <Box
        sx={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color:
            status === 'Excellent'
              ? '#4CAF50'
              : status === 'Fair'
              ? '#FFC107'
              : '#F44336',
        }}
      >
        {status}
      </Box>
    </Card>
  );
};

export default NetworkStrength;
