import { useState, useEffect } from 'react';
import { Entity, PolylineGraphics, useCesium } from 'resium';
import {
  Color,
  PolylineDashMaterialProperty,
  PolylineArrowMaterialProperty,
  CallbackProperty,
  Cartesian3,
} from 'cesium';
import { Box } from '@mui/material';

const DirectionalArrows = ({ start, end }: { start: any; end: any }) => {
  const [progress, setProgress] = useState(0);
  const viewer = useCesium()?.viewer; // Access the Cesium viewer
  const scene = viewer ? viewer?.scene : null;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 0.01) % 1); // Loop progress from 0 to 1
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const cameraHeight = scene?.camera.positionCartographic.height;
  const scaleFactor = (cameraHeight ?? 1) * 0.001; // Adjust scale based on zoom

  const arrowheadLength = scaleFactor * 60; // Adjust this multiplier as needed
  const dashedLineLength = scaleFactor * 50;

  // Calculate world positions based on fixed screen size
  const arrowheadEnd = Cartesian3.lerp(
    start,
    end,
    arrowheadLength / Cartesian3.distance(start, end),
    new Cartesian3()
  );

  const dashedLineEnd = Cartesian3.lerp(
    start,
    end,
    dashedLineLength / Cartesian3.distance(start, end),
    new Cartesian3()
  );

  const animatedPositions = new CallbackProperty(() => {
    const fraction = progress; // Progress between 0 and 1
    const movedStart = Cartesian3.lerp(
      start,
      dashedLineEnd,
      fraction,
      new Cartesian3()
    );
    return [movedStart, dashedLineEnd];
  }, false);

  return (
    <Box
      sx={{ pointerEvents: 'none' }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* Moving Dashed Line */}
      <Entity>
        <PolylineGraphics
          positions={animatedPositions}
          width={8}
          material={
            new PolylineDashMaterialProperty({
              color: Color.fromCssColorString('rgba(131, 178, 240, 0.7)'),
              gapColor: Color.TRANSPARENT,
              dashLength: scaleFactor * 4, // Scale dashes dynamically
              dashPattern: 0b1111000011110000,
            })
          }
          clampToGround={true}
        />
      </Entity>

      {/* Static Arrowhead with Fixed Screen Size */}
      <Entity>
        <PolylineGraphics
          positions={[start, arrowheadEnd]}
          width={20}
          material={
            new PolylineArrowMaterialProperty(
              Color.fromCssColorString('rgb(42, 68, 174)')
            )
          }
          clampToGround={true}
        />
      </Entity>
    </Box>
  );
};

export default DirectionalArrows;
