import { FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import type { Asset } from 'types/asset.types';

export const AssetSelector = ({
  enableDarkTheme,
  selectedMakeModel,
  setSelectedMakeModel,
  assetMakeModelList,
  selectedAsset,
  setSelectedAsset,
  assetMap,
  filteredAssets,
}: {
  enableDarkTheme: boolean;
  selectedMakeModel: string;
  setSelectedMakeModel: any;
  assetMakeModelList: any[];
  selectedAsset: Asset;
  setSelectedAsset: any;
  assetMap: any;
  filteredAssets: Asset[];
}) => {
  const uniquePairs = new Set();

  assetMakeModelList.forEach((pair) => {
    const [make, model] = pair.split('!');
    uniquePairs.add(`${make}-${model}`);
  });

  const uniquePairsArray = Array.from(uniquePairs).sort((a, b) => {
    const [makeA, modelA] = (a as string).split('-');
    const [makeB, modelB] = (b as string).split('-');
    if (makeA !== makeB) {
      return makeA.localeCompare(makeB);
    }
    return modelA.localeCompare(modelB);
  });

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '0px',
        border: '1px solid #2F445E',
        borderRadius: '4px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        padding: '20px',
      }}
    >
      <FormControl fullWidth>
        <InputLabel
          id="asset-select-label"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            '&.Mui-focused': {
              color: enableDarkTheme
                ? ThemePalette.typography.cornflowerBlue
                : ThemePalette.typography.primary,
            },
          }}
        >
          Make-Model
        </InputLabel>
        <Select
          labelId="make-model-select-label"
          id="make-model-select"
          value={selectedMakeModel}
          label="Make Model"
          onChange={(event) => {
            const selectedValue = event.target.value;
            setSelectedMakeModel(selectedValue);
          }}
          style={{ width: '180px' }}
          sx={{
            width: '25%',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            ...(enableDarkTheme && {
              '& .MuiSvgIcon-root': {
                color: 'purple', // <------------------ arrow-svg-color
              },
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: ThemePalette.border, // <------------------ outline-color by default
                },
                '&:hover fieldset': {
                  borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                },
              },
            }),
          }}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions, // Change background color here
              },
            },
          }}
        >
          {uniquePairsArray.map((pair: any) => {
            return (
              <MenuItem
                key={pair}
                value={pair}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.lightGrey
                    : ThemePalette.typography.black,
                }}
              >
                {pair}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel
          id="asset-select-label"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Bumper ID
        </InputLabel>
        <Select
          labelId="asset-select-label"
          id="asset-select"
          value={selectedAsset.assetVin}
          label="Bumper ID"
          onChange={(event) => {
            setSelectedAsset(assetMap[event.target.value]);
          }}
          style={{ width: '180px' }}
          sx={{
            width: '25%',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            ...(enableDarkTheme && {
              '& .MuiSvgIcon-root': {
                color: 'purple', // <------------------ arrow-svg-color
              },
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: ThemePalette.border, // <------------------ outline-color by default
                },
                '&:hover fieldset': {
                  borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                },
              },
            }),
          }}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions, // Change background color here
              },
            },
          }}
        >
          {filteredAssets
            .filter((asset) => asset?.device?.deviceId)
            .map((asset) => {
              return (
                <MenuItem
                  key={asset.assetVin}
                  value={asset.assetVin}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.black,
                  }}
                >
                  {asset.bumperNumber}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};
