import { createSlice } from '@reduxjs/toolkit';
import { type GeoTrip } from 'types/geo.types';

export interface InclinometerState {
  events: any[];
  isLoading: boolean;
  tirePressure: any;
  fuelEfficiency: any;
  gpsData: GeoTrip[];
}

const initialState: InclinometerState = {
  events: [
    { incorrect_gear: [] },
    { overspeed: [] },
    { braking: [] },
    { acceleration: [] },
    { incorrect_gear_shift: [] },
    { gear_skip: [] },
  ],
  isLoading: false,
  tirePressure: {},
  fuelEfficiency: {
    device: [],
    fleet: [],
  },
  gpsData: [],
};

export const idleAnalyticsSlice = createSlice({
  name: 'inclinometer',
  initialState,
  reducers: {
    setInclinometerEvent(state, { payload }) {
      state.events = payload;
    },
    setTirePressure(state, { payload }) {
      state.tirePressure = payload;
    },
    setFuelEfficiency(state, { payload }) {
      state.fuelEfficiency = payload;
    },
    setGpsData(state, { payload }) {
      state.gpsData = payload;
    },
    setLoadingState(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const {
  setInclinometerEvent,
  setTirePressure,
  setFuelEfficiency,
  setGpsData,
  setLoadingState,
} = idleAnalyticsSlice.actions;
export default idleAnalyticsSlice.reducer;
