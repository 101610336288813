import { Box, Typography, Chip, Grid, Divider } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { ThemePalette } from 'mui.theme';
import { tireCodeNames } from 'views/TirePressure/util';
import type { Asset } from 'types/asset.types';

export const AssetTireMetric = ({
  enableDarkTheme,
  data,
  selectedAsset,
}: {
  enableDarkTheme: boolean;
  data: any;
  selectedAsset: Asset;
}) => {
  // @ts-expect-error ignoring since we have const for tirecodenames
  const tireCodes = tireCodeNames?.[selectedAsset.assetType];
  const isEmpty =
    Object.keys(data).length === 0 ||
    Object.values(data).every((value) => value === 0);

  let dataToUse = data;

  const dataKeys: string[] = Object.keys(data);

  if (isEmpty) {
    const newData = { ...dataToUse };

    dataKeys.forEach((item) => {
      newData[item] = Math.floor(Math.random() * (50 - 10 + 1)) + 10;
    });
    dataToUse = newData;
  }

  const dataValues: number[] = Object.values(dataToUse);

  const isDataToUseEmpty =
    Object.keys(dataToUse).length === 0 ||
    Object.values(dataToUse).every((value) => value === 0);

  return (
    <Box
      sx={{
        marginTop: '20px',
        padding: '10px',
        border: '1px solid #2F445E',
        borderRadius: '4px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        minHeight: '170px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontWeight: '700',
          }}
        >
          Tire Pressure
        </Typography>
        {!isDataToUseEmpty && (
          <Chip
            icon={<ErrorIcon style={{ fill: '#FFFFFF' }} />}
            sx={{
              padding: '15px 20px 15px 20px',
              borderRadius: '8px',
              backgroundColor: '#EB5758',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
            label="ALERT"
            size="small"
          />
        )}
      </Box>
      {isDataToUseEmpty ? (
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontSize: '12px',
            marginBottom: '50px',
          }}
        >
          No Tire Pressure data for the selected time range
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {dataKeys?.map((item: string, index: number) => {
            const tireCode = tireCodes?.[item];
            return (
              <Grid item lg={6} key={index}>
                <Typography
                  sx={{
                    fontSize: '10px',
                    marginTop: '10px',
                  }}
                >
                  <span
                    style={{
                      color: ThemePalette.typography.lightGrey,
                      paddingRight: '5px',
                    }}
                  >
                    {tireCode}:
                  </span>
                  <span
                    style={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    {dataValues[index]}
                  </span>
                  <span
                    style={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    {' '}
                    PSI
                  </span>
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      )}
      <Box
        sx={{
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        <Divider
          sx={{
            backgroundColor: '#40536B',
          }}
        />
        <Typography
          sx={{
            marginTop: '10px',
            textDecoration: 'underline',
            color: '#3666EC',
            fontSize: '12px',
          }}
        >
          More Details
        </Typography>
      </Box>
    </Box>
  );
};
