import React from 'react';
import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { type GeoTrip } from 'types/geo.types';
import { Viewer, Entity, CameraFlyTo } from 'resium';
import { Cartesian3, Color } from 'cesium';
import useFullscreenChange from 'hooks/geoMaps';

export const dummyCredit = document.createElement('div');

export const GpsView = ({
  enableDarkTheme,
  gpsData,
  startDate,
  endDate,
}: {
  enableDarkTheme: boolean;
  gpsData: GeoTrip[];
  startDate: Date;
  endDate: Date;
}) => {
  const viewerRef = React.useRef<any>(null);
  useFullscreenChange(viewerRef);

  const dataToDisplay = gpsData[0] ?? {
    cycle_index: '',
    loading_phase: '',
    loading_field: '',
    loading_area: '',
    loading_start_ts: '',
    loading_end_ts: '',
    loading_duration_sec: 0,
    loading_longitude: 0,
    loading_latitude: 0,
    unloading_phase: '',
    unloading_field: '',
    unloading_area: '',
    unloading_start_ts: '',
    unloading_end_ts: '',
    unloading_duration_sec: 0,
    unloading_longitude: 0,
    unloading_latitude: 0,
    device: '',
    loaded_trip_start_ts: '',
    loaded_trip_end_ts: '',
    loaded_trip_duration_sec: 0,
    unloaded_trip_start_ts: '',
    unloaded_trip_end_ts: '',
    unloaded_trip_duration_sec: 0,
    loading_fuel_consumption_l: 0,
    unloading_fuel_consumption_l: 0,
    loaded_trip_fuel_consumption_l: 0,
    unloaded_trip_fuel_consumption_l: 0,
    loaded_trip_travel_distance_km: 0,
    unloaded_trip_travel_distance_km: 0,
    max_load_ton: 0,
    bucket_count: 0,
    process_date: '',
    customer: '',
    loading_start_unix_ts: 0,
    trip: 0,
    paths: {
      Items: [
        {
          gpslongitude: 0,
          gpslatitude: 0,
          gpsaltitude: 0,
          sample_timestamp: '',
        },
      ],
    },
    loaded_stop_duration: 0,
  };
  const startTime = new Date(startDate)
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ');
  const endTime = new Date(endDate)
    .toISOString()
    .slice(0, 19)
    .replace('T', ' ');

  const filteredData = dataToDisplay.paths.Items.filter((item) => {
    const sampleTime = new Date(item.sample_timestamp);
    const start = new Date(startTime);
    const end = new Date(endTime);

    return sampleTime >= start && sampleTime <= end;
  });

  const pointColor = Color.fromCssColorString('#3666EC');

  return (
    <Box
      sx={{
        padding: '10px',
        border: '1px solid #2F445E',
        borderRadius: '4px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        minHeight: '350px',
      }}
    >
      <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          marginBottom: '20px',
        }}
      >
        GPS Coordinates
      </Typography>

      {filteredData.length === 0 ? (
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          No Gps data for selected time range
        </Typography>
      ) : (
        <div>
          <Viewer
            timeline={false}
            navigationHelpButton={false}
            homeButton={false}
            animation={false}
            infoBox={false}
            ref={viewerRef}
            creditContainer={dummyCredit}
          >
            {/* Fly the camera to the first location */}
            <CameraFlyTo
              duration={0}
              destination={Cartesian3.fromDegrees(
                filteredData[0].gpslongitude,
                filteredData[0].gpslatitude,
                1000
              )}
            />

            {/* Plot each point on the map */}
            {filteredData.map((coord, index) => (
              <Entity
                key={index}
                position={Cartesian3.fromDegrees(
                  coord.gpslongitude,
                  coord.gpslatitude
                )}
                point={{
                  pixelSize: 10,
                  color: pointColor,
                  outlineColor: Color.BLACK,
                }}
                name={`Point ${index + 1}`}
              />
            ))}
          </Viewer>
        </div>
      )}
    </Box>
  );
};
