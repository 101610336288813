import dayjs from 'dayjs';
import { Grid, Typography, LinearProgress } from '@mui/material';
import { AreaChart, Area, ResponsiveContainer, Tooltip, YAxis } from 'recharts';
import { Box } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { ThemePalette } from 'mui.theme';
import { truncateTo2dp } from 'utils/helpers/general';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { convertsecondsToHours, getShiftHours } from 'views/Utilization/utils';
import { convertSeconds } from 'views/Production/AllFleet/utils';
import { ProgressBarWithArrow } from 'views/FleetOverview/FleetOverviewV2/ProgressBarWithArrow';
import { SWToolTip } from 'components/SWToolTip';

interface Props {
  enableDarkTheme: boolean;
  analytics: any;
  isLoading: boolean;
  isSelected: string;
  aggregation?: string;
  utilizationType?: string;
}

const FuelCardAnalytics = ({
  enableDarkTheme,
  analytics,
  isLoading,
  isSelected,
  aggregation,
  utilizationType,
}: Props) => {
  let chartData = analytics.chartData;

  if (
    ['Avg Shift Hours', 'Avg Engine Hours', 'Avg Productive Hours'].includes(
      analytics.title
    )
  ) {
    chartData = analytics.chartData.map((item: any) => {
      return {
        ...item,
        value: Number(truncateTo2dp((item.value ?? 0) / 60)),
      };
    });
  }

  if (analytics.title === 'Avg Idling Hours') {
    chartData = analytics.chartData.map((item: any) => {
      return {
        ...item,
        value: Number(truncateTo2dp(convertsecondsToHours(item.value ?? 0))),
      };
    });
  }

  if (analytics.title === 'Avg Cycle Time') {
    const hasValueGreaterThan3600 = chartData.some(
      (item: any) => item.value > 3600
    );

    chartData = analytics.chartData.map((item: any) => {
      return {
        ...item,
        value: hasValueGreaterThan3600
          ? Number(truncateTo2dp(convertsecondsToHours(item.value ?? 0)))
          : Number(truncateTo2dp(convertSeconds(item.value ?? 0).value)),
      };
    });
  }

  return (
    <Box
      sx={{
        height: '200px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.toolBarBackground,
        borderRadius: '5px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        border: '1px solid #2F445D',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
    >
      {isLoading ? (
        <LinearProgress
          color="secondary"
          sx={{
            marginTop: '65px',
          }}
        />
      ) : (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography
              fontWeight="bold"
              sx={{
                fontSize: '13px',
              }}
            >
              {analytics.title}
              {analytics.title === 'Utilization' && (
                <span
                  style={{
                    fontSize: '10px',
                    marginLeft: '5px',
                  }}
                >
                  <br />
                  Utilization is measure of Productive Hours /
                  {utilizationType === 'shiftHours'
                    ? ' Shift Hours'
                    : ' Engine Hours'}{' '}
                  hours
                  <br />
                </span>
              )}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {analytics.hasWarning ? (
                <>
                  <WarningIcon
                    sx={{
                      color: '#FFB402',
                      marginRight: '4px',
                      fontSize: '15px',
                    }}
                  />
                  <Typography variant="body1">
                    {analytics.warningCount}
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Box>
          </Grid>

          <Grid container alignItems="center">
            <Grid item lg={12} sm={12}>
              <Typography
                fontWeight="bold"
                sx={{
                  fontSize: '25px',
                }}
              >
                {analytics.pointingUp ? (
                  <CallMadeIcon
                    sx={{
                      color: analytics.isGreen ? '#029161' : '#EB5757',
                      fontSize: '23px',
                    }}
                  />
                ) : (
                  <CallReceivedIcon
                    sx={{
                      color: analytics.isGreen ? '#029161' : '#EB5757',
                      fontSize: '23px',
                    }}
                  />
                )}
                {analytics.value}
                {analytics.unit ? (
                  <span
                    style={{
                      fontSize: '13px',
                      paddingLeft: '5px',
                      color: enableDarkTheme
                        ? '#8491A0'
                        : ThemePalette.typography.black,
                    }}
                  >
                    {analytics.unit}
                  </span>
                ) : (
                  ''
                )}
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              marginBottom: '10px',
            }}
          >
            <Typography
              sx={{
                color: analytics.isGreen ? '#029161' : '#EB5757',
                fontSize: '12px',
                paddingBottom: '15px',
              }}
            >
              {analytics.analysis}
              <span
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.grey
                    : ThemePalette.typography.black,
                }}
              >
                {' '}
                {analytics.message}
              </span>
            </Typography>
          </Box>
          {analytics.showProgressBar && isSelected === '1D' ? (
            <SWToolTip title={`${+analytics.progressBarValue.toFixed(2)}%`}>
              <Typography
                component="div"
                sx={{
                  fontSize: '12px',
                  textAlign: 'center',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  cursor: 'pointer',
                }}
              >
                <ProgressBarWithArrow
                  value={analytics.progressBarValue}
                  enableDarkTheme={enableDarkTheme}
                />
              </Typography>
            </SWToolTip>
          ) : (
            <></>
          )}
          <div
            style={{
              width: '100%',
              height: 60,
            }}
          >
            {chartData?.length > 1 ? (
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={chartData}
                  width={300}
                  height={250}
                  margin={{
                    left: -36,
                    right: 8,
                    top: 10,
                    bottom: 10,
                  }}
                >
                  <YAxis
                    tick={{ fontSize: 7 }}
                    tickFormatter={formatNumberToShortScale}
                  />
                  <Tooltip
                    content={
                      <CustomTooltip
                        title={analytics.title}
                        aggregation={aggregation}
                        isSelected={isSelected}
                      />
                    }
                    position={{ y: -50 }}
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke={analytics.isGreen ? '#029161' : '#EB5757'}
                    fill={analytics.isGreen ? '#029161' : '#EB5757'}
                    opacity={0.7}
                    strokeWidth={2}
                    dot={false}
                  />
                </AreaChart>
              </ResponsiveContainer>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </Box>
  );
};

export default FuelCardAnalytics;

const CustomTooltip = ({
  active,
  payload,
  title,
  aggregation,
  isSelected,
}: {
  active?: boolean;
  payload?: any;
  title: string;
  isSelected?: string;
  aggregation?: string;
}) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: 'rgba(217, 229, 247, 0.8) ',
          border: '1px solid #ccc',
          color: '#000',
          padding: '3px 5px',
          borderRadius: '4px',
          fontSize: '10px',
        }}
      >
        <p>{`Date: ${dayjs(name).format(
          ['1Y', 'YTD', 'Custom'].includes(isSelected ?? '') &&
            aggregation === 'Monthly'
            ? 'YYYY MMM'
            : 'YYYY MMM DD'
        )}`}</p>
        <p>{`${title}: ${formatNumberToShortScale(truncateTo2dp(value))}`}</p>
      </div>
    );
  }
  return null;
};
