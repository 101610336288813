import { SVG } from 'components/Asset/SVG';
import styled from 'styled-components';

export const LayoutSwitchWrapper = styled.div`
  width: 85%;
  display: flex;
  justify-content: space-around;
  margin-left: 2vh;

  p {
    color: #323143;
    width: 80%;
  }
`;

export const LayoutSwitchDiv = styled.div`
  width: 80%;
  height: 40px;
  background: var(---f3f4f6) 0% 0% no-repeat padding-box;
  background: #f3f4f6 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  margin-left: 0%;
  margin-right: 0%;
  padding: 15px 10px 0 20px;
  display: flex;
  justify-content: space-around;
`;

export const SVGDiv = styled(SVG)`
  cursor: pointer;

  &:hover {
  }
`;

export const AdditionalColumnWrapper = styled.div`
  height: 40px;
  padding: 15px 10px 0 100px;
  display: flex;
  justify-content: space-around;
  fontfamily: 'open sans';
`;
