import React, { useEffect, useState } from 'react';
import { ThemePalette } from 'mui.theme';
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  LinearProgress,
} from '@mui/material';
import type { Asset } from 'types/asset.types';
import { getAssetsReplacementCount } from './utils';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { truncateTo2dp } from 'utils/helpers/general';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';

const AssetDetails = ({
  enableDarkTheme,
  data,
  selectedPart,
  selectedAsset,
  assets,
}: {
  enableDarkTheme: boolean;
  data: any[];
  selectedPart: string[];
  selectedAsset: string[];
  assets: Asset[];
}) => {
  const [detailsPart, setDetailsPart] = useState(selectedPart);

  useEffect(() => {
    setDetailsPart(selectedPart);
  }, [selectedPart]);

  const replacementData = data.filter(
    (item) =>
      item.actions_performed === 'REPLACE' &&
      detailsPart.includes(item.components_category) &&
      selectedAsset.includes(item.asset_id)
  );

  let assetsReplacementData = getAssetsReplacementCount(replacementData).sort(
    (a: any, b: any) => b.count - a.count
  );

  assetsReplacementData = assetsReplacementData.map((item: any) => {
    const itemInAsset = assets.find(
      (asset) => asset.bumperNumber === item.assetId
    );
    if (itemInAsset) {
      return {
        ...item,
        makeModel: `${itemInAsset.make}-${itemInAsset.model}`,
      };
    }
    return {
      ...item,
      makeModel: '',
    };
  });

  const totalReplacementCount = assetsReplacementData.reduce(
    (sum: number, item: any) => {
      return sum + Number(item.count || 0);
    },
    0
  );

  const totalReplacementCost = assetsReplacementData.reduce(
    (sum: number, item: any) => {
      return sum + Number(item.totalCost || 0);
    },
    0
  );

  const totalReplacementDownTimeHours = assetsReplacementData.reduce(
    (sum: number, item: any) => {
      return sum + Number(item.downtimeHours || 0);
    },
    0
  );

  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.toolBarBackground,
        padding: '20px 10px 20px 10px',
        borderRadius: '5px',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        marginTop: '20px',
        overflow: 'scroll',
        height: '440px',
        minHeight: '440px',
      }}
    >
      <Typography sx={{ fontSize: '14px' }}>
        Parts Replaced Across Assets
      </Typography>
      <Box
        sx={{
          marginTop: '10px',
          display: 'flex',
        }}
      >
        <Typography
          sx={{
            fontSize: '10px',
            color: '#B4B4B4',
            paddingTop: '8px',
          }}
        >
          Showing For
        </Typography>

        <Select
          multiple
          labelId="parts-recommendation-asset-breakdown"
          value={detailsPart}
          renderValue={(selected) => {
            return `${selected?.length || 0} Part(s) Selected`;
          }}
          onChange={(e) => {
            const selectedValues = e.target.value as string[];
            if (selectedValues.includes('select-all')) {
              const allSelected = detailsPart.length === selectedPart.length;
              if (allSelected) {
                setDetailsPart([]);
              } else {
                setDetailsPart([...selectedPart]);
              }
            } else if (selectedValues.length > 0) {
              setDetailsPart(selectedValues);
            }
          }}
          sx={{
            height: '30px',
            fontSize: '14px',
            marginLeft: '10px',
            marginRight: '20px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.cartesianGrid
              : ThemePalette.light.menuOptions,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            '& .MuiSelect-icon': {
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            },
            '& fieldset': {
              borderColor: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            },
            ...(enableDarkTheme && {
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#2F445D',
                },
              },
            }),
          }}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '14px',
              },
            },
          }}
        >
          <MenuItem
            key="select-all-option"
            value="select-all"
            sx={{
              fontSize: '14px',
              borderBottom: '1px solid',
              borderColor: enableDarkTheme ? '#2F445D' : '#E0E0E0',
            }}
          >
            <Checkbox
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
                '&.Mui-checked': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                },
                '&.Mui-disabled': {
                  color: ThemePalette.typography.secondary,
                },
              }}
              checked={
                detailsPart.length === selectedPart.length &&
                selectedPart.length > 0
              }
              indeterminate={
                detailsPart.length > 0 &&
                detailsPart.length < selectedPart.length
              }
            />
            <ListItemText primary="Select All" />
          </MenuItem>

          {selectedPart.map((part, index) => (
            <MenuItem
              key={index}
              value={part}
              sx={{
                fontSize: '14px',
              }}
            >
              <Checkbox
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                  '&.Mui-disabled': {
                    color: ThemePalette.typography.secondary,
                  },
                }}
                checked={detailsPart.includes(part)}
              />
              <ListItemText primary={capitalizeFirstLetter(part)} />
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box
        sx={{
          marginTop: '20px',
        }}
      >
        {assetsReplacementData.map((asset: any, index) => {
          return (
            <Box
              key={index}
              sx={{
                marginTop: '20px',
                marginBottom: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '10px',
                    }}
                  >
                    {asset.assetId}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      color: 'rgba(255, 255, 255, 0.7)',
                      marginTop: '4px',
                    }}
                  >
                    {asset.makeModel}
                  </Typography>
                </Box>
                <Box sx={{ width: '80%' }}>
                  <CustomProgress
                    color="#3666EC"
                    value={asset.count}
                    total={totalReplacementCount}
                    unit={''}
                    enableDarkTheme={enableDarkTheme}
                  />
                  <CustomProgress
                    color="#36EC9C"
                    value={Number(truncateTo2dp(asset.totalCost))}
                    total={totalReplacementCost}
                    unit={'$'}
                    enableDarkTheme={enableDarkTheme}
                  />
                  {/* <CustomProgress
                    color="#EC3666"
                    value={asset.downtimeHours}
                    total={totalReplacementDownTimeHours}
                    unit={'Hrs'}
                    enableDarkTheme={enableDarkTheme}
                  /> */}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AssetDetails;

const CustomProgress = ({
  value,
  total,
  unit,
  color,
  enableDarkTheme,
}: {
  value: number;
  total: number;
  unit: string;
  color: string;
  enableDarkTheme: boolean;
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={(Number(value || 0) / total) * 100}
          sx={{
            marginTop: '8px',
            height: 6,
            borderRadius: 4,
            bgcolor: 'rgba(255, 255, 255, 0.12)',
            '& .MuiLinearProgress-bar': {
              bgcolor: color,
              borderRadius: 4,
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 45 }}>
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontSize: '10px',
          }}
        >
          {formatNumberToShortScale(value)}
          {unit}
        </Typography>
      </Box>
    </Box>
  );
};
