import WeatherAPI from 'api/weather.api';
import { getAll, setIsLoading } from 'store/weather.slice';

import { BaseHandler } from './base.handler';

export default class WeatherHandler extends BaseHandler {
  private readonly api: WeatherAPI;

  constructor() {
    super();

    this.api = new WeatherAPI();
  }

  async get(): Promise<any> {
    this.dispatch(setIsLoading(true));

    try {
      const cacheKey = `weather_cache`;
      const cachedData = sessionStorage.getItem(cacheKey);

      if (cachedData) {
        const data = JSON.parse(cachedData);

        // Check if the cache is stale (older than 1 hour)
        const cacheTimestamp = new Date(data.timestamp);
        const currentTime = new Date();
        const hoursDifference =
          (currentTime.getTime() - cacheTimestamp.getTime()) / (1000 * 60 * 60);

        if (hoursDifference < 1) {
          this.dispatch(setIsLoading(false));
          this.dispatch(getAll(data));
          return data;
        }
      }

      // Fetch fresh data if cache is empty or stale
      const weather = await this.api.get();

      // Store new data in cache with timestamp
      sessionStorage.setItem(
        cacheKey,
        JSON.stringify({ ...weather, timestamp: new Date() })
      );

      this.dispatch(setIsLoading(false));
      this.dispatch(getAll(weather));

      return weather;
    } catch (_) {
      this.handleError('An error occurred while fetching weather.');
      this.dispatch(setIsLoading(false));
    }

    return {};
  }
}
