/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useMemo } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  ReferenceArea,
} from 'recharts';
import { Box, Grid, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';

export const SupportingEvidence = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  const sensors = [
    'AftertreatmentDieselExhaustFluidTankTemp',
    'AftertreatmentDieselExhaustFluidTankHeater',
    'AftertreatmentSCROperationInducementSev',
    'EngineFilteredFuelDeliveryAbsolutePressure',
    'AftertreatmentDieselExhaustFluidDoserAbsol',
    'AftertreatmentDPFDifferentialPressure',
    'DPFConditionsNotMetForActiveRegeneration',
    'DPFSootLoadPercent',
    'AftertreatmentDieselExhaustFluidTankLevel',
  ];

  const createTimestamp = (year: any, month: any, day: any) => {
    return new Date(year, month - 1, day).getTime();
  };

  const faultData = [
    {
      sensor: 'AftertreatmentDieselExhaustFluidTankTemp',
      date: createTimestamp(2024, 5, 1),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDieselExhaustFluidTankTemp',
      date: createTimestamp(2024, 7, 15),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDieselExhaustFluidTankTemp',
      date: createTimestamp(2024, 10, 1),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDieselExhaustFluidTankHeater',
      date: createTimestamp(2024, 6, 15),
      size: 5,
    },
    {
      sensor: 'AftertreatmentSCROperationInducementSev',
      date: createTimestamp(2024, 9, 1),
      size: 5,
    },
    {
      sensor: 'AftertreatmentSCROperationInducementSev',
      date: createTimestamp(2024, 8, 1),
      size: 5,
    },
    {
      sensor: 'AftertreatmentSCROperationInducementSev',
      date: createTimestamp(2024, 10, 1),
      size: 5,
    },
    {
      sensor: 'EngineFilteredFuelDeliveryAbsolutePressure',
      date: createTimestamp(2024, 5, 15),
      size: 5,
    },
    {
      sensor: 'EngineFilteredFuelDeliveryAbsolutePressure',
      date: createTimestamp(2024, 7, 20),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDieselExhaustFluidDoserAbsol',
      date: createTimestamp(2024, 7, 25),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDieselExhaustFluidDoserAbsol',
      date: createTimestamp(2024, 9, 10),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDPFDifferentialPressure',
      date: createTimestamp(2024, 7, 27),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDPFDifferentialPressure',
      date: createTimestamp(2024, 9, 20),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 5, 20),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 5, 30),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 5, 30),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 6, 1),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 6, 10),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 6, 20),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 6, 31),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 7, 31),
      size: 5,
    },
    {
      sensor: 'DPFConditionsNotMetForActiveRegeneration',
      date: createTimestamp(2024, 7, 31),
      size: 5,
    },
    {
      sensor: 'DPFSootLoadPercent',
      date: createTimestamp(2024, 5, 5),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDieselExhaustFluidTankLevel',
      date: createTimestamp(2024, 8, 15),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDieselExhaustFluidTankLevel',
      date: createTimestamp(2024, 9, 15),
      size: 5,
    },
    {
      sensor: 'AftertreatmentDieselExhaustFluidTankLevel',
      date: createTimestamp(2024, 10, 15),
      size: 5,
    },
  ];

  const getSensorPosition = (sensorName: string) => {
    return sensors.indexOf(sensorName) + 1;
  };

  const formatDate = (timestamp: any) => {
    const date = new Date(timestamp);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return `${months[date.getMonth()]} ${date.getFullYear()}`;
  };

  const getYearMonth = (timestamp: any) => {
    const date = new Date(timestamp);
    return `${date.getFullYear()}-${date.getMonth()}`;
  };

  const insightAreas = useMemo(() => {
    const insights: any[] = [];

    const pointsByMonth: any = {};
    const pointsBySensor: any = {};

    faultData.forEach((point) => {
      const yearMonth = getYearMonth(point.date);
      pointsByMonth[yearMonth] = (pointsByMonth[yearMonth] || 0) + 1;
      pointsBySensor[point.sensor] = (pointsBySensor[point.sensor] || 0) + 1;
    });

    Object.entries(pointsByMonth).forEach(([yearMonth, count]: any) => {
      if (count >= 5) {
        const [year, month] = yearMonth.split('-');
        const startDate = new Date(
          parseInt(year),
          parseInt(month),
          1
        ).getTime();
        const endDate = new Date(
          parseInt(year),
          parseInt(month) + 1,
          0
        ).getTime();

        insights.push({
          startDate,
          endDate,
          label: `${formatDate(startDate)} (${count} points)`,
          type: 'month',
        });
      }
    });

    Object.entries(pointsBySensor).forEach(([sensor, count]: any) => {
      if (count >= 5) {
        const sensorPoints = faultData.filter(
          (point) => point.sensor === sensor
        );
        const startDate = Math.min(...sensorPoints.map((p) => p.date));
        const endDate = Math.max(...sensorPoints.map((p) => p.date));

        insights.push({
          startDate,
          endDate,
          label: `${sensor} (${count} points)`,
          type: 'sensor',
          sensorPosition: getSensorPosition(sensor),
        });
      }
    });

    return insights;
  }, [faultData]);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-2 border border-gray-300 rounded shadow">
          <p className="font-semibold">{data.sensor}</p>
          <p>{formatDate(data.date)}</p>
        </div>
      );
    }
    return null;
  };

  const chartData = faultData.map((item) => ({
    ...item,
    sensorPosition: getSensorPosition(item.sensor),
  }));

  return (
    <Grid item lg={7} md={12} sm={12} xs={12}>
      <Box
        sx={{
          padding: '20px 10px 20px 10px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          borderRadius: '5px',
          border: `1px solid rgba(255, 255, 255, 0.15)`,
          minHeight: '500px',
          maxHeight: '500px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: '700',
          }}
        >
          Supporting Evidence
        </Typography>
        <FlexBox
          sx={{
            maxWidth: 'inherit',
            height: 'inherit',
          }}
        >
          <ResponsiveContainer width="100%" height={500}>
            <ScatterChart
              width={700}
              height={500}
              margin={{ top: 20, right: 30, bottom: 20, left: 200 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={
                  enableDarkTheme
                    ? ThemePalette.dark.cartesianGrid
                    : ThemePalette.light.cartesianGrid
                }
                vertical={false}
              />
              <XAxis
                dataKey="date"
                name="Date"
                type="number"
                domain={['dataMin', 'dataMax']}
                tickFormatter={formatDate}
                tickCount={6}
                tick={{ fontSize: 11 }}
              />
              <YAxis
                type="number"
                dataKey="sensorPosition"
                name="Sensor"
                ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                tickFormatter={(value) => {
                  const idx = value - 1;
                  return idx >= 0 && idx < sensors.length ? sensors[idx] : '';
                }}
                tick={{ fontSize: 11 }}
              />
              <ZAxis range={[60, 60]} />
              <Tooltip content={<CustomTooltip />} cursor={false} />

              {insightAreas.map((area, index) => {
                if (area.type === 'month') {
                  return (
                    <ReferenceArea
                      key={`area-${index}`}
                      x1={area.startDate}
                      x2={area.endDate}
                      y1={0}
                      y2={10}
                      fill="rgba(220, 53, 69, 0.3)"
                      stroke="rgba(220, 53, 69, 0.8)"
                    />
                  );
                } else {
                  return (
                    <ReferenceArea
                      key={`area-${index}`}
                      x1={area.startDate}
                      x2={area.endDate}
                      y1={area.sensorPosition - 0.5}
                      y2={area.sensorPosition + 0.5}
                      fill="rgba(220, 53, 69, 0.3)"
                      stroke="rgba(220, 53, 69, 0.8)"
                    />
                  );
                }
              })}

              <Scatter name="Faults" data={chartData} fill="#F59E0B" />
            </ScatterChart>
          </ResponsiveContainer>
        </FlexBox>
      </Box>
    </Grid>
  );
};
