import {
  ButtonGroup,
  Button,
  Typography,
  Box,
  // Switch,
  // Select,
  // MenuItem,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';
import DatePicker from 'react-datepicker';
import './styles.css';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hook';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import { getDaysSinceStartOfYear, getStartAndEndDateFromRange } from '../utils';
import { useEffect, useMemo, useState } from 'react';
import { calculateDaysDifference } from 'views/FuelAnalytics/IdleDashboard/utils';
import ShiftwiseToggler from './ShiftwiseToggler';
// import ProductionSelector from 'views/Production/components/Selector/Selector';
// import { ShiftDetails } from 'views/Utilization/utils';

interface Props {
  enableDarkTheme: boolean;
  ignoreYtd?: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  aggregation: string;
  setAggregation: any;
  shiftWiseAnalysis: boolean;
  setShiftWiseAnalysis: any;
  shiftNumber: number;
  setShiftNumber: any;
  customerCode: string;
  utilizationType?: string;
  setUtilizationType?: any;
}

const FuelUsageDateRangeDiv = ({
  enableDarkTheme,
  ignoreYtd = false,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  aggregation,
  setAggregation,
  shiftWiseAnalysis,
  setShiftWiseAnalysis,
  shiftNumber,
  setShiftNumber,
  customerCode,
  utilizationType,
  setUtilizationType,
}: Props) => {
  const { loadingStates } = useAppSelector(
    (state) => state.fuelAnalyticsReducer
  );

  const disableComponent = useMemo(() => {
    return (
      loadingStates?.isLoadingFleetSummary ||
      loadingStates.isLoadingFuelConsumptionPerDayData ||
      loadingStates.isLoadingFuelTreemapData ||
      loadingStates.loadingFuelAnalyticsWRTDriverEvents
    );
  }, [loadingStates]);

  // const shiftOptions = ShiftDetails[customerCode] || ShiftDetails.others;
  const [aggregationOptions, setAggregationOptions] = useState([
    'Daily',
    'Monthly',
  ]);
  const handleSelect = (id: string) => {
    setIsSelected(id);

    // const today = dayjs();
    let newStartDate = startDate;
    let newEndDate = yesterday;

    switch (id) {
      case '1D': {
        const { startDate: startDateVal, endDate: endDateVal } =
          getStartAndEndDateFromRange(1);
        newStartDate = dayjs(startDateVal).toDate();
        newEndDate = dayjs(endDateVal).toDate();
        setAggregation('Daily');
        break;
      }
      case '7D': {
        const { startDate: startDateVal, endDate: endDateVal } =
          getStartAndEndDateFromRange(7);
        newStartDate = dayjs(startDateVal).toDate();
        newEndDate = dayjs(endDateVal).toDate();
        setAggregation('Daily');
        break;
      }
      case '14D': {
        const { startDate: startDateVal, endDate: endDateVal } =
          getStartAndEndDateFromRange(14);
        newStartDate = dayjs(startDateVal).toDate();
        newEndDate = dayjs(endDateVal).toDate();
        setAggregation('Daily');
        break;
      }
      case '1M': {
        const { startDate: startDateVal, endDate: endDateVal } =
          getStartAndEndDateFromRange(30);
        newStartDate = dayjs(startDateVal).toDate();
        newEndDate = dayjs(endDateVal).toDate();
        setAggregation('Daily');
        break;
      }
      case '1Y': {
        const { startDate: startDateVal, endDate: endDateVal } =
          getStartAndEndDateFromRange(365);
        newStartDate = dayjs(startDateVal).toDate();
        newEndDate = dayjs(endDateVal).toDate();
        setAggregation('Monthly');
        break;
      }
      case 'YTD': {
        const rangeInt = getDaysSinceStartOfYear();
        const { startDate: startDateVal, endDate: endDateVal } =
          getStartAndEndDateFromRange(rangeInt, true);
        newStartDate = dayjs(startDateVal).toDate();
        newEndDate = dayjs(endDateVal).toDate();
        setAggregation('Monthly');
        break;
      }
      case 'Custom':
        // Do nothing, user will select manually
        break;
      default:
        break;
    }

    // Update the startDate and endDate for non-custom ranges
    if (id !== 'Custom') {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  let dateRangeList = ['1D', '7D', '14D', '1M', '1Y', 'YTD', 'Custom'];
  if (ignoreYtd) {
    dateRangeList = dateRangeList.filter((item) => item !== 'YTD');
  }

  const rangeIsOverMonth = ['1Y', 'YTD', 'Custom'].includes(isSelected);

  useEffect(() => {
    if (!startDate || !endDate) return;
    const days = calculateDaysDifference(startDate, endDate);
    if (isSelected === '1Y' || isSelected === 'YTD' || days > 30) {
      setAggregationOptions(['Daily', 'Monthly']);
    } else {
      if (aggregation === 'Monthly') {
        setAggregation('Daily');
      }
      setAggregationOptions(['Daily']);
    }
  }, [rangeIsOverMonth, startDate, endDate]);

  return (
    <>
      <Typography
        style={{
          fontFamily: "'Inter Variable', sans-serif",
          margin: '20px 0',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
          padding: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <div
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.mediumGray
                : '#3d3d3d',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography style={{ fontSize: '11px', marginRight: '5px' }}>
              Quick Range:
            </Typography>
            <ButtonGroup
              size="small"
              variant="outlined"
              aria-label="Basic button group"
              sx={{
                border: '1px solid #2F445D',
              }}
            >
              {dateRangeList.map((item) => {
                return (
                  <Button
                    key={item}
                    size="small"
                    disabled={disableComponent}
                    style={{
                      fontSize: ' 11px ',
                      backgroundColor:
                        isSelected === item
                          ? '#3666EC'
                          : enableDarkTheme
                          ? ThemePalette.dark.boxBackground
                          : ThemePalette.light.toolBarBackground,
                      color: loadingStates?.isLoadingFleetSummary
                        ? ThemePalette.typography.grey
                        : enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                    onClick={() => {
                      handleSelect(item);
                    }}
                  >
                    {item}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>

          <Box
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.mediumGray
                : '#3d3d3d',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            <Typography
              style={{
                fontSize: '11px',
              }}
            >
              Date Range:
            </Typography>
            {aggregation === 'Monthly' ? (
              <DatePicker
                showIcon
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={yesterday}
                selectsRange
                showMonthYearPicker
                dateFormat="MMMM yyyy"
                className={
                  enableDarkTheme
                    ? 'custom-datepicker-prod'
                    : 'custom-datepicker-prod-light-theme'
                }
                disabled={disableComponent || isSelected !== 'Custom'}
              />
            ) : (
              <DatePicker
                showIcon
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={yesterday}
                selectsRange
                className={
                  enableDarkTheme
                    ? 'custom-datepicker-prod'
                    : 'custom-datepicker-prod-light-theme'
                }
                disabled={disableComponent || isSelected !== 'Custom'}
              />
            )}
          </Box>
          <ShiftwiseToggler
            shiftWiseAnalysis={shiftWiseAnalysis}
            setShiftWiseAnalysis={setShiftWiseAnalysis}
            shiftNumber={shiftNumber}
            setShiftNumber={setShiftNumber}
            disabled={disableComponent}
          />
          {rangeIsOverMonth && (
            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography
                sx={{
                  fontSize: '11px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.mediumGray
                    : '#3d3d3d',
                  marginTop: '7px',
                  marginRight: '10px',
                }}
              >
                Aggregation:
              </Typography>

              <ProductionSelector
                enableDarkTheme={enableDarkTheme}
                options={aggregationOptions}
                selected={aggregation}
                setSelected={setAggregation}
                disabled={disableComponent}
                minWidth="100px"
              />
            </div>
          )}
        </div>
      </Typography>
    </>
  );
};

export default FuelUsageDateRangeDiv;
