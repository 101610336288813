import { useEffect, useMemo, useState, type SyntheticEvent } from 'react';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hook';
import DriverBehaviorHandler from 'handlers/driver.behavior.handler';
import { isDarkTheme } from 'utils/theme';
import Fleet from './Fleet';
import Header from './Header';
import dayjs from 'dayjs';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import DriverBehaviourTimeline from './TimeLine/components/DriverBehaviourTimeline';
import { CustomTabPanel } from './components/CustomTabPanel';
import { calculateDaysDifference } from 'views/FuelAnalytics/IdleDashboard/utils';
import { setTimelineError } from 'store/driver.behavior.slice';
import { useLocation } from 'react-router-dom';

const DriverBehavior = () => {
  const dispatch = useAppDispatch();
  const customerCode = useAppSelector((state) => state.authReducer).customer
    .code;
  const location = useLocation();

  const { asset, tabIndex } = location.state || {};

  const driverBehaviorHandler = new DriverBehaviorHandler();
  const [isSelected, setIsSelected] = useState('1D');
  const [timeLineAssets, setTimeLineAssets] = useState<string[]>([`${asset}`]);
  const [timelineDate, setTimelineDate] = useState('');
  const [value, setValue] = useState(tabIndex ?? 0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { data, isLoading } = useAppSelector(
    (state) => state.driverBehaviorReducer
  );
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
    if (timelineDate && startDate && endDate) {
      setTimelineDate('');
    }
  };

  const handleChange = (
    newValue: number,
    assetId?: string,
    selectedDate?: string
  ) => {
    if (assetId) {
      setTimeLineAssets([assetId]);
    } else {
      setTimeLineAssets([]);
    }
    if (selectedDate) {
      setTimelineDate(selectedDate);
    }
    setValue(newValue);
  };

  const setHandleChange = (event: SyntheticEvent, newValue: number) => {
    handleChange(newValue);
  };

  const dayDifference = useMemo(() => {
    if (isSelected === 'Custom') {
      return calculateDaysDifference(startDate, endDate);
    } else {
      return 0;
    }
  }, [isSelected, startDate, endDate]);

  const fetchData = async (startDate: Date, endDate: Date, range: string) => {
    if (range === 'Custom') {
      if (startDate && endDate) {
        if (dayDifference > 2 && value === 1) {
          dispatch(
            setTimelineError('Kindly select maximum 1 day for timeline chart')
          );
          return;
        }
        const startDateFormatted = dayjs(startDate).format('YYYY-MM-DD');
        const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD');
        await driverBehaviorHandler.getAll({
          startDate: startDateFormatted,
          endDate: endDateFormatted,
        });
      }
    } else {
      await driverBehaviorHandler.getAll({
        range,
      });
    }
  };

  useEffect(() => {
    if (!customerCode) return;
    void fetchData(startDate, endDate, isSelected);
  }, [isSelected, endDate, customerCode]);

  useEffect(() => {
    setTimelineDate('');
  }, [isSelected]);

  return (
    <Box
      sx={{
        margin: '10px 0px 10px 0px',
        position: 'relative',
      }}
    >
      <Header
        enableDarkTheme={enableDarkTheme}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        yesterday={dayjs().subtract(1, 'day').toDate()}
        handleDateChange={handleDateChange}
        setHandleChange={setHandleChange}
        value={value}
        isLoading={isLoading}
      />

      {isLoading ? (
        <SingleFadeLoader />
      ) : (
        <>
          <Fleet
            enableDarkTheme={enableDarkTheme}
            value={value}
            handleChange={handleChange}
            data={data}
          />
          <CustomTabPanel value={value} index={1}>
            <DriverBehaviourTimeline
              timelineDate={timelineDate}
              selectedAssets={timeLineAssets}
              setTimeLineAssets={setTimeLineAssets}
            />
          </CustomTabPanel>
        </>
      )}
    </Box>
  );
};

export default DriverBehavior;
