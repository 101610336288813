import { createSlice } from '@reduxjs/toolkit';

export interface PartsRecommendationItem {
  description: string;
  work_order_number: string;
  work_order_status: string;
  asset_id: string;
  total_cost: number;
  date_of_orders: string;
  category: string;
  actions_performed: string;
  components_category: string;
  sub_component_categories: string;
  customer: string;
}

export interface PartsRecommendationHealthItem {
  age_in_days: string;
  bumper_id: string;
  component: string;
  criticality: string;
  customer: string;
  days_to_eol: number;
  health_score: number;
  health_score_weight: number;
  make: string;
  model: string;
  process_timestamp: string;
  squares_recommended_date: string;
  sub_component: string;
  work_order_date: string;
}
export interface PartsRecommendationMetadata {
  actions_performed: string;
  asset_type: string;
  component: string;
  criticality: string;
  failure_mode: string;
  mfr_recommended_freq_engine_hrs: string;
  sub_component: string;
}

export interface PartsRecommendationsInterface {
  data: PartsRecommendationItem[];
  prevData: PartsRecommendationItem[];
  healthData: PartsRecommendationHealthItem[];
  metadata: PartsRecommendationMetadata[];
  isLoading: boolean;
}

const initialState: PartsRecommendationsInterface = {
  data: [],
  prevData: [],
  healthData: [],
  metadata: [],
  isLoading: false,
};

export const squaresPartsRecommendationsSlice = createSlice({
  name: 'squaresPartsRecommendations',
  initialState,
  reducers: {
    setPartsRecommendations(state, { payload }) {
      state.data = payload.currentPeriod.workOrders;
      state.prevData = payload.prevPeriod.workOrders;
      state.healthData = payload.healthData;
      state.metadata = payload.metadata;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const { setPartsRecommendations, setIsLoading } =
  squaresPartsRecommendationsSlice.actions;

export default squaresPartsRecommendationsSlice.reducer;
