import { useState } from 'react';
import { Box, Typography, Grid, Collapse } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { calculatePercentageChange } from '../../FleetOverview/helpers/asset.helpers';
import { ItemCollapse } from './ItemCollapse/ItemCollapse';
import type { DataEntry, HistogramData } from 'types/FleetOverview.types';
import type { Asset } from 'types/asset.types';
import { SVG } from 'components/Asset/SVG';
import { useAppDispatch } from 'store/hook';
import { removeTagFromAssetComparisonByTags } from 'store/asset.slice';
import { truncateTo2dp } from 'utils/helpers/general';

export const ListViewItem = ({
  enableDarkTheme,
  title,
  data,
  assets,
  thresholds,
  defaultThresholds,
}: {
  enableDarkTheme: boolean;
  title: string;
  data: {
    data: DataEntry[];
    histogram: HistogramData[];
    unit?: string;
  };
  assets: Asset[];
  thresholds: any[];
  defaultThresholds: any[];
}) => {
  const { data: chartData, histogram } = data;
  const [itemOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const padArrayToFour = (data: any[], defaultValue = null) => {
    const newData = [...data]; // Create a shallow copy of the array
    while (newData.length < 4) {
      newData.push(defaultValue);
    }
    return newData;
  };

  // Use the padArrayToFour function to ensure the array length is 4
  const paddedArray = padArrayToFour(chartData);

  return (
    <Box
      sx={{
        paddingTop: '20px',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        textAlign: 'center',
        minWidth: '100%',
      }}
    >
      <Typography
        sx={{
          paddingBottom: '10px',
        }}
      >
        {title.replace(/\b\w/g, (char) => char.toUpperCase())}
      </Typography>
      <Grid
        container
        onClick={() => {
          handleClick();
        }}
      >
        {paddedArray.map((item: DataEntry, index: number) => {
          const { percentageChange, isIncrement } = calculatePercentageChange(
            chartData[0]?.value,
            item?.value || 0
          );
          return (
            <Grid item xl={2.9} lg={2.8} md={6} xs={12} key={index}>
              <Box
                sx={{
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.boxBackground
                    : ThemePalette.light.toolBarBackground,
                  borderRight:
                    chartData.length - 1 === index ? '' : '1px solid #3A4657',
                  minHeight: '70px',
                  alignContent: 'center',
                  justifyContent: 'center',
                  borderImage: `linear-gradient(to bottom, transparent 33%, #3B4F67 33%, #3B4F67 66%, transparent 66%, transparent 100%) 1`,
                }}
              >
                {item === null ? (
                  <></>
                ) : (
                  <Typography
                    sx={{
                      fontWeight: '600',
                      fontSize: '17px',
                    }}
                  >
                    {title === 'utilization'
                      ? truncateTo2dp(item.value * 100)
                      : item.value}
                    <span
                      style={{
                        fontSize: '11px',
                      }}
                    >
                      {' '}
                      {title === 'utilization'
                        ? '%'
                        : data.unit
                        ? data.unit
                        : ''}
                    </span>
                  </Typography>
                )}
                {index !== 0 && item !== null ? (
                  <Typography
                    sx={{
                      fontSize: '11px',
                      color: isIncrement
                        ? ThemePalette.typography.success
                        : ThemePalette.typography.danger,
                    }}
                  >
                    {isIncrement ? (
                      <CallMadeIcon
                        sx={{
                          fontSize: '14px',
                          paddingRight: '3px',
                        }}
                      />
                    ) : (
                      <CallReceivedIcon
                        sx={{
                          fontSize: '14px',
                          paddingRight: '3px',
                        }}
                      />
                    )}
                    {percentageChange}%
                  </Typography>
                ) : null}
              </Box>
            </Grid>
          );
        })}
        <Box
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.toolBarBackground,
            borderRight: '1px solid #3A4657',
            minHeight: '70px',
            alignContent: 'center',
            justifyContent: 'center',
            '& svg': {
              fill: '#0F49E5',
              height: '15px',
              cursor: 'pointer',
              width: '30px',
              transition: 'all 0.3s ease-in-out',
              '&:hover': {
                fill: '#FF0000',
                cursor: 'pointer',
                height: '20px',
                width: '40px',
              },
            },
          }}
        >
          {!['utilization', 'total engine hours', 'fuel usage'].includes(
            title
          ) && (
            <SVG
              name={'cancelIcon'}
              onClick={() => {
                dispatch(removeTagFromAssetComparisonByTags(title));
              }}
            />
          )}
        </Box>
      </Grid>
      <Collapse in={itemOpen} timeout="auto" unmountOnExit>
        <ItemCollapse
          enableDarkTheme={enableDarkTheme}
          data={histogram}
          assets={assets}
          thresholds={thresholds}
          title={title}
          defaultThresholds={defaultThresholds}
        />
      </Collapse>
    </Box>
  );
};
