import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ThemePalette } from 'mui.theme';
import WarningIcon from '@mui/icons-material/Warning';
import type { totalFleetDetails } from 'types/production.types';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import CircularProgressChart from 'views/Production/components/CircularProgress/CircularProgress';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { hasMetTarget, targetPercentageDiff } from '../utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect } from 'react';
import { useAppSelector } from 'store/hook';

interface Props {
  enableDarkTheme: boolean;
  data: totalFleetDetails;
  isLoading: boolean;
  unitSystem: string;
  currentDailyAverage: number;
  // rollingDailyAverage: number;
  isLoadingFleetPeriodSummary: boolean;
  setDetailsVal: any;
}

const FleetProductionDetails = ({
  enableDarkTheme,
  data,
  isLoading,
  unitSystem,
  currentDailyAverage,
  // rollingDailyAverage,
  isLoadingFleetPeriodSummary,
  setDetailsVal,
}: Props) => {
  const {
    data: {
      allFleet: { totalFleetDetails, fleetPeriodSummary },
    },
    loadingStates,
  } = useAppSelector((state) => state.productionReducer);
  const target = useAppSelector((state) => state.targetReducer.data.production);
  const productionTarget = target?.find((item) => item.assetType === 'all');
  const rollingDailyAverage =
    totalFleetDetails.rollingDailyAverageFromChart as number;
  useEffect(() => {
    if (!isLoadingFleetPeriodSummary) {
      setDetailsVal({
        'YTD Target (hours)': formatNumberToShortScale(
          convertToTwoDecimalPlaces(data.ytdTarget.toString())
        ),
        'Annual Production Target (hours)': formatNumberToShortScale(
          convertToTwoDecimalPlaces(data.annualTarget.toString())
        ),
        [`Rolling Daily Average (${
          unitSystem === 'imperial' ? 'iTons/Day' : 'Tons/Day'
        })`]: formatNumberToShortScale(
          convertToTwoDecimalPlaces((rollingDailyAverage || 0).toString())
        ),
        'Remaining Days': data.remainingDays,
        [`Current Daily Average (${
          unitSystem === 'imperial' ? 'iTons/Day' : 'Tons/Day'
        })`]: formatNumberToShortScale(
          convertToTwoDecimalPlaces((currentDailyAverage ?? 0).toString())
        ),
        // 'Target Met (True/False)': hasMetTarget(data.tons, target),
        'Target Met (True/False)': hasMetTarget(
          data.tons,
          productionTarget?.targetValue as number
        ),
      });
    }
  }, [
    isLoadingFleetPeriodSummary,
    data,
    rollingDailyAverage,
    currentDailyAverage,
    hasMetTarget,
  ]);
  return (
    <Grid item lg={6} md={6} sm={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '13px',
              }}
            >
              Total Fleet Production in Tons (YTD)
            </Typography>
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                Mine Production Plan:
              </Typography>
              <Button
                size="small"
                sx={{
                  border: '1px solid #4A7BFA',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  textTransform: 'none',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  fontSize: '11px',
                }}
                startIcon={<FileUploadIcon />}
              >
                Upload
              </Button>
            </Box> */}
          </Box>
        </Box>

        {isLoading ? (
          <MultiColorCircularLoader height="300px" />
        ) : (
          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            <Grid container>
              <Grid item lg={6} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <CircularProgressChart
                    enableDarkTheme={enableDarkTheme}
                    value={data.tons}
                    target={data.ytdTarget}
                    unitSystem={unitSystem}
                  />
                  <Box
                    sx={{
                      alignContent: 'center',
                      paddingLeft: '10px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.black,
                      }}
                    >
                      YTD Target
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        paddingBottom: '15px',
                      }}
                    >
                      {formatNumberToShortScale(
                        convertToTwoDecimalPlaces(
                          (productionTarget?.ytdTarget as number)?.toString()
                        )
                      )}{' '}
                      {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.lightGrey
                          : ThemePalette.typography.black,
                      }}
                    >
                      Annual Target
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                    >
                      {formatNumberToShortScale(
                        convertToTwoDecimalPlaces(
                          (productionTarget?.targetValue as number)?.toString()
                        )
                      )}{' '}
                      {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={6} sm={12}>
                <Box>
                  <Grid container>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Annual Production Target
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {formatNumberToShortScale(
                          convertToTwoDecimalPlaces(
                            (
                              productionTarget?.targetValue as number
                            )?.toString()
                          )
                        )}
                        <span
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                          }}
                        >
                          {' '}
                          {unitSystem === 'imperial' ? 'iTons' : 'Tons'}
                        </span>
                      </Typography>

                      <Typography
                        sx={{
                          paddingTop: '30px',
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Rolling Daily Average
                      </Typography>
                      {isLoadingFleetPeriodSummary ? (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          Loading...
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          {formatNumberToShortScale(
                            convertToTwoDecimalPlaces(
                              (rollingDailyAverage || 0).toString()
                            )
                          )}
                          <span
                            style={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.lightGrey
                                : ThemePalette.typography.black,
                            }}
                          >
                            {' '}
                            {unitSystem === 'imperial'
                              ? 'iTons/Day'
                              : 'Tons/Day'}
                          </span>
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Remaining Days
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {data.remainingDays}
                      </Typography>

                      <Typography
                        sx={{
                          paddingTop: '30px',
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Current Daily Average
                      </Typography>
                      {isLoadingFleetPeriodSummary ? (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          Loading...
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          {isLoadingFleetPeriodSummary
                            ? 'Loading...'
                            : formatNumberToShortScale(
                                convertToTwoDecimalPlaces(
                                  (currentDailyAverage ?? 0).toString()
                                )
                              )}
                          <span
                            style={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.lightGrey
                                : ThemePalette.typography.black,
                            }}
                          >
                            {' '}
                            {unitSystem === 'imperial'
                              ? 'iTons/Day'
                              : 'Tons/Day'}
                          </span>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {hasMetTarget(
                    data,
                    productionTarget?.targetValue as number
                  ) ? (
                    <Box
                      sx={{
                        textAlign: 'center',
                        border: '1px solid #2F445F',
                        borderRadius: '5px',
                        marginTop: '15px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CheckCircleIcon
                          sx={{ color: '#029161', marginRight: '4px' }}
                        />
                        Avg Production is on target
                      </Typography>
                    </Box>
                  ) : (
                    // <Box
                    //   sx={{
                    //     textAlign: 'center',
                    //     border: '1px solid #2F445F',
                    //     borderRadius: '5px',
                    //     marginTop: '15px',
                    //     padding: '10px',
                    //     display: 'flex',
                    //     justifyContent: 'center',
                    //     alignItems: 'center',
                    //   }}
                    // >
                    //   <Typography
                    //     sx={{
                    //       fontSize: '12px',
                    //       display: 'flex',
                    //       alignItems: 'center',
                    //     }}
                    //   >
                    //     <WarningIcon
                    //       sx={{ color: '#FFB402', marginRight: '4px' }}
                    //     />
                    //     Avg Production needs to go up by
                    //     <span
                    //       style={{
                    //         color: '#FFB402',
                    //         marginLeft: '4px',
                    //       }}
                    //     >
                    //       {targetPercentageDiff(data)}%
                    //     </span>
                    //   </Typography>
                    // </Box>
                    <></>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box
          sx={{
            marginTop: '50px', // Pushes the box to the bottom
          }}
        >
          <Typography
            sx={{
              fontSize: '10px',
              textAlign: 'right',
              color: enableDarkTheme ? '#F3F4F6' : '#2F445D',
              marginBottom: '4px',
            }}
          >
            * Averages shown above are based on last 7 days recent most data
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              textAlign: 'right',
              color: enableDarkTheme ? '#F3F4F6' : '#2F445D',
            }}
          >
            ** YTD numbers are based on real-time data received from assets
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default FleetProductionDetails;
