import { Box, Grid, Typography } from '@mui/material';
import { Base } from 'components/Containers/BaseContainer';
import { ThemePalette } from 'mui.theme';
import React from 'react';
import ComponentsPieChart from './PieChart';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import AreaChart from './AreaChart';
import { aggregateCostData } from '../utils';

interface Props {
  pieChartDataByKey: any;
  handlePieClick: any;
  selectedAssetsCostType: any;
  selectedAssetsTimesSeries: any;
  pieChartTitle?: string;
  lineChartTitle?: string;
  keysToAggregate?: string[];
}

const TotalCostVSLabourCost = ({
  pieChartDataByKey,
  handlePieClick,
  selectedAssetsCostType,
  selectedAssetsTimesSeries,
  pieChartTitle = 'Labour Cost vs Part Cost',
  lineChartTitle = 'Labor vs Part Cost Trend',
  keysToAggregate = ['total_labour_cost', 'total_part_cost'],
}: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const aggregatedData = aggregateCostData(selectedAssetsTimesSeries);
  return (
    <Base enableDarkTheme={enableDarkTheme} sx={{ minHeight: '433px' }}>
      <Grid
        sx={{
          display: 'flex',
          marginLeft: '0px',
          maxWidth: '100%',
          paddingBottom: '30px',
        }}
        container
        spacing={1}
      >
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Base
            sx={{
              height: '400px',
              maxHeight: '400px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.toolBarBackground
                : ThemePalette.light.toolBarBackground,
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '20px',
                  paddingTop: '30px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {pieChartTitle}
              </Typography>
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <ComponentsPieChart
                data={pieChartDataByKey}
                handlePieClick={handlePieClick}
                selectedAssetsCostType={selectedAssetsCostType}
                enableDarkTheme={enableDarkTheme}
                keysToAggregate={keysToAggregate}
              />
            </Box>
          </Base>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Base
            sx={{
              height: '400px',
              maxHeight: '400px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.toolBarBackground
                : ThemePalette.light.toolBarBackground,
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '20px',
                  paddingTop: '30px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {lineChartTitle}
              </Typography>
            </Box>

            {selectedAssetsCostType.length === 0 ? (
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '20px',
                  paddingTop: '10px',
                }}
              >
                Please select an asset from the pie chart.
              </Typography>
            ) : (
              <AreaChart
                data={aggregatedData}
                selectedAssetsCostType={selectedAssetsCostType}
              />
              // <ComponentsLineChart
              //   data={selectedAssetsTimesSeries}
              //   enableDarkTheme={enableDarkTheme}
              //   selectedAssetsCostType={selectedAssetsCostType}
              // />
            )}
          </Base>
        </Grid>
      </Grid>
    </Base>
  );
};

export default TotalCostVSLabourCost;
