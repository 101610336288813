import BaseAPI from './base.api';

export default class TargetAPI extends BaseAPI {
  getTargetAllApi = async (
    customerId: string,
    year: number,
    signal?: AbortSignal
  ) => {
    const response = await this.API.post(
      'customer/target/generic',
      {
        customerId,
        year,
      },
      {
        signal,
      }
    );
    return response.data;
  };

  getTargetApi = async (
    customerId: string,
    metricName: string,
    assetType: string,
    year: number,
    signal?: AbortSignal
  ) => {
    const response = await this.API.post(
      'customer/target/',
      {
        customerId,
        metricName,
        assetType,
        year,
      },
      {
        signal,
      }
    );
    return response.data;
  };

  postTargetApi = async (
    customerId: string,
    metricName: string,
    targetValue: number,
    year: number,
    assetType: string
  ) => {
    const response = await this.API.post('customer/target/create', {
      customerId,
      metricName,
      targetValue,
      year,
      assetType,
    });
    return response.data;
  };
}
