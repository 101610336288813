import { Grid, Box, Typography } from '@mui/material';
import { Selector } from 'components/Form/Selectors/Selector';
import RoughRoadHandler from 'handlers/rough-road.handler';
import { useDateRange } from 'hooks/dateRange';
import { ThemePalette } from 'mui.theme';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useAppSelector } from 'store/hook';
import { type RoughRoadResponse } from 'types/roadConditions.types';
import { isWithinGivenDays } from 'utils/helpers/dateTime';
import { isDarkTheme } from 'utils/theme';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import { selectSpacedItems } from './utils';
import RoadConditionList from './RoadConditionList';
import RoadConditionMap from './RoadConditionMap';
import { SingleFadeLoader } from 'components/SingleFadeLoader';

const RoadConditionSummary = () => {
  const { theme, code } = useAppSelector((state) => state.authReducer).customer;
  const enableDarkTheme = isDarkTheme(theme);
  const roughRoadHandler = new RoughRoadHandler();

  const [allPatches, setAllPatches] = useState<RoughRoadResponse[]>();
  const [filteredPatches, setFilteredPatches] = useState<RoughRoadResponse[]>();
  const [toggledPatch, setToggledPatch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedChartSource, setSelectedChartSource] =
    useState<string>('Last 7 Days');
  const options = [
    'Last 24 Hrs',
    'Last 2 Days',
    'Last 7 Days',
    'Last 14 Days',
    'Last 4 Weeks',
    'Last 6 Months',
    'Custom',
  ];
  const selectorOptions = [
    {
      id: 'all',
      display: 'All',
    },
    {
      id: 'active',
      display: 'Active',
    },
    {
      id: 'inactive',
      display: 'Inactive',
    },
  ];
  const [selectedViewOption, setSelectedViewOption] = useState<string>('all');

  const [selectedRow, setSelectedRow] = useState<RoughRoadResponse>();
  const [isRowClicked, setIsRowClicked] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>(
    filteredPatches?.[0] ?? selectedRow
  );
  const [selectedPatchSequence, setSelectedPatchSequence] =
    useState<number[]>();

  const { startDate, endDate, setStartDate, setEndDate, yesterday } =
    useDateRange({});

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    setIsLoading(true);
    const getPatches: any = async () => {
      const patches: RoughRoadResponse[] =
        await roughRoadHandler.getRoughRoadPatches();
      if (selectedChartSource === 'Last 7 Days') {
        setFilteredPatches(
          patches.filter((item: RoughRoadResponse) =>
            isWithinGivenDays(item.dataJson[0].date, 7)
          )
        );
      }

      setAllPatches(patches);
      setIsLoading(false);
    };

    void getPatches();
  }, []);

  useEffect(() => {
    setSelectedRow(undefined);
  }, [selectedChartSource]);

  useEffect(() => {
    const filterFunction = (item: RoughRoadResponse, days: number) => {
      const isWithinDateRange = isWithinGivenDays(item.dataJson[0].date, days);

      return selectedViewOption === 'active'
        ? isWithinDateRange &&
            item.isActive &&
            item.roadPatchId !== toggledPatch
        : selectedViewOption === 'inactive'
        ? isWithinDateRange &&
          !item.isActive &&
          item.roadPatchId !== toggledPatch
        : isWithinDateRange;
    };
    switch (selectedChartSource) {
      case 'Last 24 Hrs':
        setFilteredPatches(
          allPatches?.filter((item: RoughRoadResponse) =>
            filterFunction(item, 2)
          )
        );
        break;
      case 'Last 2 Days':
        setFilteredPatches(
          allPatches?.filter((item: RoughRoadResponse) =>
            filterFunction(item, 3)
          )
        );
        break;
      case 'Last 7 Days':
        setFilteredPatches(
          allPatches?.filter((item: RoughRoadResponse) =>
            filterFunction(item, 8)
          )
        );
        break;

      case 'Last 14 Days':
        setFilteredPatches(
          allPatches?.filter((item: RoughRoadResponse) =>
            filterFunction(item, 15)
          )
        );
        break;

      case 'Last 4 Weeks':
        setFilteredPatches(
          allPatches?.filter((item: RoughRoadResponse) =>
            filterFunction(item, 29)
          )
        );
        break;

      case 'Last 6 Months':
        setFilteredPatches(
          allPatches?.filter((item: RoughRoadResponse) =>
            filterFunction(item, 181)
          )
        );
        break;

      case 'Custom':
        if (startDate && endDate) {
          setFilteredPatches(
            allPatches?.filter((item: RoughRoadResponse) => {
              return selectedViewOption === 'active'
                ? isWithinGivenDays(
                    item.dataJson[0].date,
                    0,
                    startDate.toDateString(),
                    endDate.toDateString()
                  ) && item.isActive
                : selectedViewOption === 'inactive'
                ? isWithinGivenDays(
                    item.dataJson[0].date,
                    0,
                    startDate.toDateString(),
                    endDate.toDateString()
                  ) && !item.isActive
                : isWithinGivenDays(
                    item.dataJson[0].date,
                    0,
                    startDate.toDateString(),
                    endDate.toDateString()
                  );
            })
          );
        }
        break;

      default:
        setFilteredPatches(
          allPatches?.filter((item: RoughRoadResponse) =>
            isWithinGivenDays(item.dataJson[0].date, 7)
          )
        );
        break;
    }
  }, [
    selectedChartSource,
    startDate,
    endDate,
    selectedViewOption,
    toggledPatch,
    selectedRow,
    allPatches,
  ]);

  useEffect(() => {
    if (toggledPatch && allPatches) {
      const index = allPatches.findIndex(
        (item) => item.roadPatchId === toggledPatch
      );

      if (index !== -1) {
        allPatches[index].isActive = !allPatches[index].isActive;
      }
    }
  }, [toggledPatch]);

  useEffect(() => {
    if (selectedRow) {
      setSelectedData(selectedRow);
      setSelectedPatchSequence(
        selectSpacedItems(selectedRow?.patchSequence, 200)
      );
    } else if (filteredPatches && filteredPatches.length >= 1) {
      setSelectedData(filteredPatches[0]);
      setSelectedPatchSequence(
        selectSpacedItems(filteredPatches[0].patchSequence, 200)
      );
    }
  }, [filteredPatches, selectedRow]);
  return (
    <>
      <Grid item lg={6} md={6} sm={12} xs={12} mt={2}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '24px', // Increased gap for spacing
              flexBasis: '33%', // Occupies 1/3rd of the screen
            }}
          >
            <Box
              sx={{
                position: 'inherit',
                // top: '16px',
                right: '16px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.lightGrey
                    : ThemePalette.typography.black,
                }}
              >
                Show
              </Typography>

              <ProductionSelector
                enableDarkTheme={enableDarkTheme}
                options={options}
                selected={selectedChartSource}
                setSelected={setSelectedChartSource}
                minWidth="150px"
              />
            </Box>
            <Box
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.mediumGray
                  : '#3d3d3d',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography style={{ fontSize: '12px', margin: '0 10px 0 50px' }}>
                Date Range:
              </Typography>
              <DatePicker
                showIcon
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                maxDate={yesterday}
                selectsRange
                className={
                  enableDarkTheme
                    ? selectedChartSource === 'Custom'
                      ? 'custom-datepicker-prod'
                      : 'disabledDark'
                    : selectedChartSource === 'Custom'
                    ? 'custom-datepicker-prod-light-theme'
                    : 'disabledLight'
                }
                disabled={selectedChartSource !== 'Custom'}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexBasis: '33%', // Occupies space on the right
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              }}
            >
              Currently Viewing
            </Typography>
            <Selector
              value={selectedViewOption}
              onChange={(event) => {
                const selectedValue = event.target.value as string;
                setSelectedViewOption(selectedValue);
                setSelectedRow(undefined);
                setSelectedPatchSequence([]);
                setToggledPatch(null);
              }}
              selectorOptions={selectorOptions}
              selectSx={{
                ml: '10px',
                flex: 1,
                fontSize: '14px',
              }}
              enableDarkTheme={enableDarkTheme}
            />
          </Box>
        </Box>
      </Grid>

      {isLoading ? (
        <SingleFadeLoader />
      ) : filteredPatches && filteredPatches.length >= 1 ? (
        <>
          <RoadConditionList
            filteredPatches={filteredPatches}
            setSelectedRow={setSelectedRow}
            selectedRow={selectedRow}
            setToggledPatch={setToggledPatch}
            setSelectedPatchSequence={setSelectedPatchSequence}
            setIsRowClicked={setIsRowClicked}
          />

          <RoadConditionMap
            selectedData={selectedData}
            selectedPatchSequence={selectedPatchSequence}
            code={code}
            filteredPatches={filteredPatches}
            isRowClicked={isRowClicked}
            setIsRowClicked={setIsRowClicked}
            setSelectedRow={setSelectedRow}
          />
        </>
      ) : (
        <Typography
          sx={{
            marginTop: '36px',
          }}
          color={
            enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black
          }
        >
          No Rough Road Patch for Selected Period
        </Typography>
      )}
    </>
  );
};

export default RoadConditionSummary;
