import { createSlice } from '@reduxjs/toolkit';

export interface tagThresholdState {
  thresholds: any[];
  defaultThresholds: any[];
}

const initialState: tagThresholdState = {
  thresholds: [],
  defaultThresholds: [],
};

export const tagThresholdSlice = createSlice({
  name: 'tagThreshold',
  initialState,
  reducers: {
    setThresholds(state, { payload }) {
      state.thresholds = payload.thresholds;
      state.defaultThresholds = payload.defaultThresholds;
    },
  },
});

export const { setThresholds } = tagThresholdSlice.actions;

export default tagThresholdSlice.reducer;
