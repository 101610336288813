import { MenuItem, Select, Typography } from '@mui/material';
import useAppTheme from 'hooks/useAppTheme';
import { ThemePalette } from 'mui.theme';
import React from 'react';

interface SortByProps {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
  minWidth?: string;
  disabled?: boolean;
  options: string[];
}

const SortBy = ({
  selected,
  setSelected,
  minWidth,
  disabled,
  options,
}: SortByProps) => {
  const { enableDarkTheme } = useAppTheme();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        minWidth: '200px',
        justifyContent: 'flex-end',
      }}
    >
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '600',
          color: enableDarkTheme
            ? ThemePalette.typography.lightGrey
            : ThemePalette.typography.black,
          paddingLeft: '10px',
          paddingRight: '15px',
          paddingTop: '5px',
        }}
      >
        Sort By
      </Typography>

      <Select
        size="small"
        value={selected}
        onChange={(event) => {
          setSelected(event.target.value);
        }}
        sx={{
          textTransform: 'capitalize',
          minWidth,
          height: '30px',
          fontSize: '11px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.cartesianGrid
            : ThemePalette.light.menuOptions,
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          '& .MuiSelect-icon': {
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          },
          '& fieldset': {
            borderColor: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          },
          ...(enableDarkTheme && {
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#2F445D', // <------------------ outline-color by default
              },
            },
          }),
        }}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.menuOptions
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
              fontSize: '12px',
            },
          },
        }}
        disabled={disabled}
      >
        {options.map((option: any) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              fontSize: '12px',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SortBy;
