import { Box } from '@mui/material';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  ReferenceLine,
  Legend,
} from 'recharts';
import { FlexBox } from 'components/Containers/FlexBox';
import { ThemePalette } from 'mui.theme';
import type { DeviceStats } from 'types/utilization.types';
import {
  convertMinutesToHours,
  convertsecondsToHours,
  getDataUnit,
} from 'views/Utilization/utils';
import { truncateTo2dp } from 'utils/helpers/general';
import { useMemo } from 'react';

interface Props {
  enableDarkTheme: boolean;
  selectedSource: string[];
  unitSystem: string;
  customerCode: string;
  height?: string;
  data: DeviceStats;
}

const AssetItemChart = ({
  enableDarkTheme,
  selectedSource,
  unitSystem,
  customerCode,
  height = '230px',
  data,
}: Props) => {
  const { date_stats: dateStats } = data;

  const formattedDateLoad: any[] = Object.keys(dateStats).map((date) => ({
    date,
    ...dateStats[date],
    idlinghours: Number(
      convertsecondsToHours(Number(dateStats[date].idling_duration))
    ),
    productivehours: Number(
      convertMinutesToHours(
        Number(dateStats[date].utilization_engine_working_minute)
      )
    ),
    avgfuelrate: truncateTo2dp(dateStats[date].hourly_fuel_consumption_l),
    enginehours: Number(
      convertMinutesToHours(
        Number(dateStats[date].utilization_engine_active_minute)
      )
    ),
    utilization: truncateTo2dp(
      Number(dateStats[date].utilization_by_shift_hours) * 100
    ),
    fuel: truncateTo2dp(dateStats[date].fuel_consumption_l),
    production: truncateTo2dp(dateStats[date].max_load_ton),
  }));

  formattedDateLoad.sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());

  const getKey = (series: string) => series.toLowerCase().replace(/ /g, '');

  // Compute stats for each series
  const computeStats = (series: string) => {
    const key = getKey(series);
    const validValues = formattedDateLoad
      .map((entry) => Number(entry[key]))
      .filter((value) => value > 0);
    const maxValue = validValues.length ? Math.max(...validValues) : 0;
    const average = validValues.length
      ? validValues.reduce((sum, val) => sum + val, 0) / validValues.length
      : 0;
    const dataUnit = getDataUnit(series, unitSystem);
    return { maxValue, average, dataUnit: dataUnit ? `(${dataUnit})` : '' };
  };

  const statsDict = useMemo(() => {
    const dict: Record<
      string,
      { average: number; dataUnit: string; maxValue: number }
    > = {};
    selectedSource.forEach((series) => {
      const { average, dataUnit, maxValue } = computeStats(series);
      dict[series] = { average, dataUnit, maxValue };
    });
    return dict;
  }, [JSON.stringify(selectedSource), JSON.stringify(formattedDateLoad)]);

  let yAxisComponents = null;
  let referenceLines = null;
  let barComponents = null;

  if (selectedSource.length === 1) {
    const { maxValue, average } = computeStats(selectedSource[0]);
    yAxisComponents = (
      <YAxis
        tick={{ fontSize: 10 }}
        domain={[0, Math.ceil(maxValue * 1.1)]}
        label={{
          value: `${selectedSource[0]} ${
            statsDict[selectedSource[0]].dataUnit
          }`,
          angle: 270,
          position: 'insideLeft',
          fontSize: 10,
          textAnchor: 'middle',
          dy: 50,
        }}
      />
    );
    referenceLines = (
      <ReferenceLine
        y={average}
        stroke="#4A7BFA"
        strokeDasharray="3 3"
        isFront
      />
    );

    barComponents = (
      <Bar
        dataKey={getKey(selectedSource[0])}
        fill="#4A7BFA"
        name={selectedSource[0]}
      />
    );
  }
  // Two series
  else if (selectedSource.length === 2) {
    const stats1 = computeStats(selectedSource[0]);
    const stats2 = computeStats(selectedSource[1]);
    // Use combined max to unify the domain for both axes
    const combinedMax = Math.max(stats1.maxValue, stats2.maxValue);

    yAxisComponents = (
      <>
        <YAxis
          yAxisId="left"
          tick={{ fontSize: 10 }}
          allowDataOverflow
          domain={[
            0,
            Math.ceil(
              stats1.dataUnit === stats2.dataUnit
                ? combinedMax * 1.1
                : stats1.maxValue * 1.1
            ),
          ]}
          label={{
            value: `${selectedSource[0]} ${stats1.dataUnit}`,
            angle: 270,
            position: 'insideLeft',
            fontSize: 10,
            textAnchor: 'middle',
            dy: 50,
          }}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          allowDataOverflow
          tick={{ fontSize: 10 }}
          domain={[
            0,
            Math.ceil(
              stats1.dataUnit === stats2.dataUnit
                ? combinedMax * 1.1
                : stats2.maxValue * 1.1
            ),
          ]}
          label={{
            value: `${selectedSource[1]} ${stats2.dataUnit}`,
            angle: 270,
            position: 'insideRight',
            fontSize: 10,
            textAnchor: 'middle',
            dy: -50,
          }}
        />
      </>
    );

    referenceLines = (
      <>
        <ReferenceLine
          yAxisId="left"
          y={stats1.average}
          ifOverflow="extendDomain"
          stroke="#4A7BFA"
          strokeDasharray="3 3"
          isFront
        />
        <ReferenceLine
          yAxisId="right"
          y={stats2.average}
          ifOverflow="extendDomain"
          stroke="#FA7B4A"
          strokeDasharray="3 3"
          isFront
        />
      </>
    );

    barComponents = (
      <>
        <Bar
          yAxisId="left"
          dataKey={getKey(selectedSource[0])}
          fill="#4A7BFA"
          name={selectedSource[0]} // For legend
        />
        <Bar
          yAxisId="right"
          dataKey={getKey(selectedSource[1])}
          fill="#FA7B4A"
          name={selectedSource[1]} // For legend
        />
      </>
    );
  }

  return (
    <Box sx={{ paddingTop: '10px' }}>
      <FlexBox
        sx={{
          maxWidth: 'inherit',
          height,
          paddingTop: '20px',
          marginRight: '10px',
          paddingBottom: '10px',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={700}
            height={300}
            data={formattedDateLoad}
            margin={{
              top: 20,
              right: 100,
              left: 5,
              bottom: 5,
            }}
            barSize={20}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={ThemePalette.dark.cartesianGrid}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={(timestamp: number) =>
                dayjs(timestamp).format('DD MMM')
              }
              tick={{ fontSize: 10 }}
            />
            {yAxisComponents}

            {/* Legend */}
            <Legend
              wrapperStyle={{
                fontSize: '11px',
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />

            <Tooltip
              contentStyle={{ backgroundColor: 'transparent', border: 'none' }}
              wrapperStyle={{ outline: 'none', fontSize: '11px' }}
              cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
              itemStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
              content={
                <CustomTooltip
                  enableDarkTheme={enableDarkTheme}
                  selectedSource={selectedSource}
                  statsDict={statsDict}
                />
              }
            />

            {barComponents}
            {referenceLines}
          </BarChart>
        </ResponsiveContainer>
      </FlexBox>
    </Box>
  );
};

export default AssetItemChart;

const CustomTooltip = ({
  active,
  payload,
  enableDarkTheme,
  selectedSource,
  statsDict,
}: any) => {
  if (!active || !payload || !payload.length) {
    return null;
  }

  const hoveredDate = payload[0].payload.date;

  // Example styling for a "box" tooltip
  const boxStyle: React.CSSProperties = {
    border: enableDarkTheme
      ? '1px solid rgba(204, 204, 204, 0.8)'
      : '1px solid rgba(27, 43, 74, 0.8)',
    borderRadius: '4px',
    padding: '6px',
    color: enableDarkTheme
      ? ThemePalette.typography.wheat
      : ThemePalette.typography.black,
    fontSize: '10px',
  };

  return (
    <div style={boxStyle}>
      <p>{`Date: ${dayjs(hoveredDate).format('YYYY-MM-DD')}`}</p>
      {payload.map((entry: any) => {
        const dataKey = entry.dataKey;
        const sourceLabel = selectedSource.find(
          (s: string) => s.toLowerCase().replace(/ /g, '') === dataKey
        );
        if (!sourceLabel) return null;

        const { average, dataUnit } = statsDict[sourceLabel];
        const seriesValue = entry.value;

        return (
          <div key={dataKey}>
            <p>{`${sourceLabel}: ${seriesValue}${dataUnit || ''}`}</p>
            <p>{`Average: ${sourceLabel} ${truncateTo2dp(average)} ${
              dataUnit || ''
            }`}</p>
          </div>
        );
      })}
    </div>
  );
};
