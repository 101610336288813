import { Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  enableDarkTheme: boolean;
  options: any;
  selected: string | string[];
  setSelected: any;
  minWidth?: string;
  disabled?: boolean;
  multiple?: boolean;
  maxWidth?: string;
}

const ProductionSelector = ({
  enableDarkTheme,
  options,
  selected,
  setSelected,
  minWidth = '120px',
  disabled = false,
  multiple = false,
  maxWidth,
}: Props) => {
  const handleChange = (event: any) => {
    const value = event.target.value;
    if (multiple && Array.isArray(value)) {
      if (value.length > 2) return;
      setSelected(value);
    } else {
      setSelected(value);
    }
  };

  const renderValue = (selectedVal: string | string[]) => {
    if (!multiple) {
      return selectedVal as string;
    }
    const arr = Array.isArray(selectedVal) ? selectedVal : [];
    if (arr.length === 0) {
      return 'None';
    }
    return arr.join(', ');
  };

  const renderMenuItem = (option: any) => {
    if (!multiple) {
      return (
        <MenuItem key={option} value={option} sx={{ fontSize: '12px' }}>
          {option}
        </MenuItem>
      );
    }
    const selectedArr = Array.isArray(selected) ? selected : [];
    const isSelected = selectedArr.includes(option);
    const isDisabled = !isSelected && selectedArr.length >= 2;

    return (
      <MenuItem
        key={option}
        value={option}
        disabled={isDisabled}
        sx={{ fontSize: '12px' }}
      >
        <Checkbox
          checked={isSelected}
          size="small"
          sx={{
            padding: '0 5px 0 0',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        />
        <ListItemText
          primary={option}
          sx={{
            fontSize: '12px',
            margin: 0,
          }}
        />
      </MenuItem>
    );
  };

  return (
    <Select
      multiple={multiple}
      value={selected}
      onChange={handleChange}
      renderValue={renderValue}
      sx={{
        minWidth,
        maxWidth,
        height: '30px',
        fontSize: '11px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.cartesianGrid
          : ThemePalette.light.menuOptions,
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        '& .MuiSelect-icon': {
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
        '& fieldset': {
          borderColor: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
        ...(enableDarkTheme && {
          '&.MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#2F445D',
            },
          },
        }),
      }}
      MenuProps={{
        PaperProps: {
          style: {
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.menuOptions
              : ThemePalette.light.menuOptions,
            color: enableDarkTheme
              ? ThemePalette.typography.grey
              : ThemePalette.typography.black,
            fontSize: '12px',
          },
        },
      }}
      disabled={disabled}
    >
      {options.map(renderMenuItem)}
    </Select>
  );
};

export default ProductionSelector;
