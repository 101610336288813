import { Box, MenuItem, Select, Switch, Typography } from '@mui/material';
import useAppTheme from 'hooks/useAppTheme';
import useCustomerInfo from 'hooks/useCustomerInfo';
import { ThemePalette } from 'mui.theme';
import { ShiftDetails } from 'views/Utilization/utils';

interface Props {
  // Add your component props here
  shiftWiseAnalysis: boolean;
  setShiftWiseAnalysis: any;
  shiftNumber: number;
  setShiftNumber: any;
  disabled?: boolean;
}

const ShiftwiseToggler = ({
  setShiftNumber,
  setShiftWiseAnalysis,
  shiftNumber,
  shiftWiseAnalysis,
  disabled = false,
}: Props) => {
  const { enableDarkTheme } = useAppTheme();
  const { customerCode } = useCustomerInfo();
  const shiftOptions = ShiftDetails[customerCode] || ShiftDetails.others;
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Typography
        style={{ fontSize: '11px', color: enableDarkTheme ? 'white' : 'black' }}
      >
        Shift Wise Analysis:
      </Typography>
      <Switch
        checked={shiftWiseAnalysis}
        disabled={disabled}
        onChange={(e) => {
          setShiftWiseAnalysis(e.target.checked);
        }}
        color="secondary"
      />
      {shiftWiseAnalysis && (
        <Select
          labelId="shift-number-label"
          value={shiftNumber}
          defaultValue={shiftNumber}
          disabled={disabled}
          onChange={(e) => {
            setShiftNumber(e.target.value);
          }}
          sx={{
            height: '30px',
            fontSize: '11px',
            marginLeft: '10px',
            marginRight: '20px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.cartesianGrid
              : ThemePalette.light.menuOptions,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            '& .MuiSelect-icon': {
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            },
            '& fieldset': {
              borderColor: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            },
            ...(enableDarkTheme && {
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#2F445D', // <------------------ outline-color by default
                },
              },
            }),
          }}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '11px',
              },
            },
          }}
        >
          {shiftOptions.map((shift, index) => (
            <MenuItem
              key={index}
              value={index + 1}
              sx={{
                fontSize: '11px',
              }}
            >
              Shift {index + 1} ({shift.startHour}:00 - {shift.endHour}:00)
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  );
};

export default ShiftwiseToggler;
