import { Box, Grid, Typography, Chip, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { ThemePalette } from 'mui.theme';

const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  color: '#7986cb',
  fontWeight: 'bold',
  position: 'relative',
  textDecoration: 'underline dotted',
  textDecorationColor: '#7986cb',
  textDecorationThickness: '2px',
  textUnderlineOffset: '4px',
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: '#8c96a3',
  fontSize: '12px',
  marginBottom: theme.spacing(0.5),
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '4px',
  padding: '10px 15px 10px 15px',
  border: '1px solid #3666EC',
  color: 'white',
  textTransform: 'none',
  fontSize: '12px',
}));

const SeverityChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#EB5757',
  color: 'white',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0, 1),
  fontSize: '12px',
}));

const StatusChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#EB5757',
  color: 'white',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0, 1),
  fontSize: '12px',
}));

export const InsightDetails = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  return (
    <Grid item lg={7} md={12} sm={12} xs={12}>
      <Box
        sx={{
          padding: '20px 10px 20px 10px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          borderRadius: '5px',
          border: `1px solid rgba(255, 255, 255, 0.15)`,
          minHeight: '160px',
          maxHeight: '160px',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Insight Details
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <ActionButton startIcon={<OpenInNewIcon />}>
                  View In Vehicle Status
                </ActionButton>
                <ActionButton startIcon={<CheckCircleOutlineIcon />}>
                  Mark as solved
                </ActionButton>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TitleText>DPF Plugging</TitleText>
          </Grid>

          <Grid item container xs={12} spacing={4}>
            <Grid item xs={12} md={2.4}>
              <InfoLabel>Subsystem</InfoLabel>
              <InfoValue>Aftertreatment</InfoValue>
            </Grid>

            <Grid item xs={12} md={2.4}>
              <InfoLabel>Severity</InfoLabel>
              <Box sx={{ mt: 0.5 }}>
                <SeverityChip
                  icon={
                    <ErrorIcon
                      sx={{
                        fill: '#FFFFFF',
                      }}
                      fontSize="small"
                    />
                  }
                  label="Very High"
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={2.4}>
              <InfoLabel>Insight Type</InfoLabel>
              <InfoValue>Fault</InfoValue>
            </Grid>

            <Grid item xs={12} md={2.4}>
              <InfoLabel>Insight Start</InfoLabel>
              <InfoValue>10th Sept 2024, 00:40 AM CDT</InfoValue>
            </Grid>

            <Grid item xs={12} md={2.4}>
              <InfoLabel>Status</InfoLabel>
              <Box sx={{ mt: 0.5 }}>
                <StatusChip label="Active" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
