import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  Collapse,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { ThemePalette } from 'mui.theme';
import {
  categoryMapping,
  filterByCategoryBySubCategory,
  generateGraphData,
} from '../Function/helper.function';
import { SurvivalAnalysisProgressDotLine } from './SurvivalAnalysis.ProgressDotLineChart';
import { SurvivalAnalysisBarChart } from './SurvivalAnalysis.ProgressBarChart';
import {
  type AssetsOverview,
  type CumulativeOverview,
} from 'types/survivalAnalysis.types';
import CloseIcon from '@mui/icons-material/Close';

export const SurvivalAnalysisDetailsChart = ({
  enableDarkTheme = false,
  workOrderAgeCategory = 'low',
  assetsPerSubComponent,
  component,
}: {
  enableDarkTheme: boolean;
  workOrderAgeCategory?: 'high' | 'med' | 'low' | 'eol';
  assetsPerSubComponent: CumulativeOverview[];
  component: AssetsOverview;
}) => {
  const [generateGraphDataItems, setGenerateGraphDataItems] = useState<any[]>(
    []
  );
  const [filterAssets, setFilterAssets] = useState<any[]>([]);
  const [chartType, setChartType] = useState<'ABS' | 'COM'>('COM'); // Add state for chart type

  useEffect(() => {
    const filteredAssets = filterByCategoryBySubCategory(
      assetsPerSubComponent,
      component.components_category,
      component.sub_component
    );
    setFilterAssets(filteredAssets);
    setGenerateGraphDataItems(generateGraphData(filteredAssets));
  }, [
    assetsPerSubComponent,
    component.components_category,
    component.sub_component,
  ]);

  const handleChartTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newChartType: 'ABS' | 'COM' | null
  ) => {
    if (newChartType !== null) {
      setChartType(newChartType);
    }
  };

  return (
    <Box
      sx={{
        padding: 1,
        width: '100%',
        maxWidth: 1200, // Adjust as needed
        height: 220, // Adjust as needed
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Current Status
        </Typography>
        <ToggleButtonGroup
          value={chartType}
          exclusive
          onChange={handleChartTypeChange}
          aria-label="chart type"
          size="small"
          sx={{ mr: 1 }}
        >
          <ToggleButton
            value="ABS"
            aria-label="Dot Line Chart"
            sx={{
              minWidth: 40,
              padding: '1px 7px',
              fontSize: '0.5rem',
              color: chartType === 'ABS' ? 'darkgrey' : 'lightgrey',
              '&.Mui-selected': {
                backgroundColor: 'lightgrey',
                color: 'black',
              },
              '&:hover': {
                backgroundColor: chartType === 'ABS' ? 'lightgrey' : 'darkgrey',
              },
            }}
          >
            ABS
          </ToggleButton>
          <ToggleButton
            value="COM"
            aria-label="Bar Chart"
            sx={{
              minWidth: 40,
              padding: '1px 7px',
              fontSize: '0.5rem',
              color: chartType === 'COM' ? 'darkgrey' : 'lightgrey',
              '&.Mui-selected': {
                backgroundColor: 'lightgrey',
                color: 'black',
              },
              '&:hover': {
                backgroundColor: chartType === 'COM' ? 'lightgrey' : 'darkgrey',
              },
            }}
          >
            COM
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          sx={{ color: categoryMapping[workOrderAgeCategory].color ?? 'black' }}
        >
          <CancelIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: categoryMapping[workOrderAgeCategory].color }}
          >
            {categoryMapping[workOrderAgeCategory].text}
          </Typography>
        </Box>
      </Box>

      {/* Conditionally render the selected chart */}
      {chartType === 'ABS' ? (
        <SurvivalAnalysisProgressDotLine
          value={generateGraphDataItems}
          enableDarkTheme={enableDarkTheme}
        />
      ) : (
        <SurvivalAnalysisBarChart
          generateGraphDataItems={generateGraphDataItems}
          component={component}
          enableDarkTheme={enableDarkTheme}
          pointerColor={categoryMapping[workOrderAgeCategory].color}
        />
      )}
    </Box>
  );
};
