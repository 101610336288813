/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import * as htmlToImage from 'html-to-image';
import {
  pdf,
  Document,
  Page,
  Image,
  StyleSheet,
  View,
  Text,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import symxAILogo from '../../assets/logos/symx.ai.png';

export const exportToPdf = async (
  elementIds: string[],
  fileName: string,
  title: string,
  enableDarkTheme: boolean,
  orientation?: 'portrait' | 'landscape' | undefined
): Promise<any> => {
  try {
    const scale = 2; // Scale factor for higher resolution
    const imagesDataUrls = await Promise.all(
      elementIds.map(async (elementId) => {
        const node = document.getElementById(elementId);
        if (!node) {
          throw new Error(`Element with ID ${elementId} not found`);
        }
        // Increase the scale for higher resolution
        return await htmlToImage.toPng(node, {
          quality: 1,
          pixelRatio: scale, // Higher DPI for sharper images
        });
      })
    );

    const pageOrientation = orientation ?? 'portrait';
    const pageWidth = pageOrientation === 'portrait' ? 595.28 : 841.89;
    const pageHeight = pageOrientation === 'portrait' ? 841.89 : 595.28;

    const styles = StyleSheet.create({
      page: {
        backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        padding: 0,
      },
      imageContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start', // Align to the top left
        justifyContent: 'flex-start', // Align to the top
      },
      image: {
        objectFit: 'contain',
        width: pageWidth,
        height: 'auto', // Maintain aspect ratio
        maxHeight: pageHeight,
      },
    });

    const MyDocument = () => (
      <Document>
        {imagesDataUrls.map((dataUrl, i) => (
          <Page
            key={i}
            size="A4"
            orientation={pageOrientation}
            style={styles.page}
          >
            <View style={styles.imageContainer}>
              <Image
                src={dataUrl}
                style={{
                  width: pageWidth,
                  height: 'auto',
                  maxHeight: pageHeight,
                }}
              />
            </View>
          </Page>
        ))}
      </Document>
    );

    const pdfBlob = await pdf(<MyDocument />).toBlob();
    saveAs(pdfBlob, `${fileName}`);
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};

export const exportOverviewPagesToPdf = async (
  elementIds: string[],
  fileName: string,
  title: string,
  enableDarkTheme: boolean,
  orientation?: 'portrait' | 'landscape' | undefined
): Promise<any> => {
  try {
    const scale = 1;
    const pageOrientation = orientation ?? 'portrait';
    const pageWidth = pageOrientation === 'portrait' ? 595.28 : 841.89;
    const pageHeight = pageOrientation === 'portrait' ? 841.89 : 595.28;
    const headerHeight = 100;
    const availableContentHeight = pageHeight - headerHeight;

    // Get all image data URLs
    const imagesDataUrls = await Promise.all(
      elementIds.map(async (elementId) => {
        const node = document.getElementById(elementId);
        if (!node) throw new Error(`Element with ID ${elementId} not found`);
        return await htmlToImage.toPng(node, { quality: 1, pixelRatio: scale });
      })
    );
    const splitImageIntoChunks = async (dataUrl: string): Promise<string[]> => {
      return await new Promise((resolve) => {
        const img = document.createElement('img');
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          const targetWidth = pageWidth;
          const targetHeight = targetWidth / aspectRatio;

          if (targetHeight <= availableContentHeight) {
            resolve([dataUrl]);
            return;
          }

          const numPages = Math.ceil(targetHeight / availableContentHeight);
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const chunks = Array.from({ length: numPages }, (_, i) => {
            canvas.width = img.width;
            canvas.height = img.height / numPages;

            ctx?.drawImage(
              img,
              0,
              (i * img.height) / numPages,
              img.width,
              img.height / numPages,
              0,
              0,
              canvas.width,
              canvas.height
            );

            return canvas.toDataURL();
          });

          resolve(chunks);
        };
        img.src = dataUrl;
      });
    };

    // Split all images into chunks before rendering
    const allImageChunks = await Promise.all(
      imagesDataUrls.map(async (dataUrl) => await splitImageIntoChunks(dataUrl))
    );

    const styles = StyleSheet.create({
      page: {
        backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        padding: 0,
      },
      header: {
        padding: 20,
        flexDirection: 'column',
        height: headerHeight,
      },
      imageContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexGrow: 1,
      },
      logo: {
        width: 100,
        height: 'auto',
        marginBottom: 1,
      },
      textContainer: {
        marginBottom: 1,
        textAlign: 'left',
        paddingLeft: 2,
      },
      title: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 5,
        marginBottom: 5,
      },
      subtitle: {
        fontSize: 10,
        marginTop: 3,
      },
      borderLine: {
        height: 1,
        backgroundColor: '#000',
        marginBottom: 10,
        marginTop: 2,
      },
      contentContainer: {
        flexDirection: 'column',
        flexGrow: 1,
      },
    });

    const MyDocument = () => (
      <Document>
        {allImageChunks.map((imageChunks, index) =>
          imageChunks.map((chunk, chunkIndex) => (
            <Page
              key={`${index}-${chunkIndex}`}
              size="A4"
              orientation={pageOrientation}
              style={styles.page}
            >
              {chunkIndex === 0 && (
                <View style={styles.header}>
                  <Image src={symxAILogo} style={styles.logo} />
                  <View style={styles.textContainer}>
                    <Text style={styles.title}>X.Machines</Text>
                  </View>
                  <View style={styles.borderLine} />
                  <Text style={styles.subtitle}>{title}</Text>
                </View>
              )}

              <View style={styles.contentContainer}>
                <Image
                  src={chunk}
                  style={{
                    width: pageWidth,
                    height: 'auto',
                    maxHeight: availableContentHeight,
                    objectFit: 'contain',
                  }}
                />
              </View>
            </Page>
          ))
        )}
      </Document>
    );

    const pdfBlob = await pdf(<MyDocument />).toBlob();
    saveAs(pdfBlob, `${fileName}`);
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};

// Utility function to introduce a delay
const delay = async (ms: number) =>
  await new Promise((resolve) => setTimeout(resolve, ms));

const splitImageIntoChunks = async (
  dataUrl: string,
  pageWidth: number,
  availableContentHeight: number
): Promise<string[]> => {
  return await new Promise((resolve) => {
    const img = document.createElement('img');
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const targetWidth = pageWidth;
      const targetHeight = targetWidth / aspectRatio;

      if (targetHeight <= availableContentHeight) {
        resolve([dataUrl]);
        return;
      }

      const numPages = Math.ceil(targetHeight / availableContentHeight);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      const chunks = Array.from({ length: numPages }, (_, i) => {
        canvas.width = img.width;
        canvas.height = img.height / numPages;

        ctx?.drawImage(
          img,
          0,
          (i * img.height) / numPages,
          img.width,
          img.height / numPages,
          0,
          0,
          canvas.width,
          canvas.height
        );

        return canvas.toDataURL();
      });

      resolve(chunks);
    };
    img.src = dataUrl;
  });
};

export const exportOverviewPagesToPdf2 = async (
  containerId: string, // ID of the container that holds all the content
  fileName: string,
  title: string,
  enableDarkTheme: boolean,
  orientation?: 'portrait' | 'landscape' | undefined,
  perPage = 3
): Promise<any> => {
  try {
    const scale = 4;
    const pageOrientation = orientation ?? 'portrait';
    const pageWidth = pageOrientation === 'portrait' ? 595.28 : 841.89;
    const pageHeight = pageOrientation === 'portrait' ? 841.89 : 595.28;
    const headerHeight = 100;
    const availableContentHeight = pageHeight - headerHeight;

    // Get the container element that holds all the content
    const container = document.getElementById(containerId);
    if (!container)
      throw new Error(`Container with ID ${containerId} not found`);

    // Identify scrollable divs (excluding AssetItem components)
    const scrollableDivs = Array.from(container.querySelectorAll('div')).filter(
      (div) => !div.closest('[data-attribute="assetItemBox"]')
    );

    // Save original styles
    const originalStyles: Array<{
      element: HTMLElement;
      minHeight: string;
      maxHeight: string;
      overflow: string;
    }> = [];

    scrollableDivs.forEach((div) => {
      originalStyles.push({
        element: div,
        minHeight: div.style.minHeight,
        maxHeight: div.style.maxHeight,
        overflow: div.style.overflow,
      });

      // Temporarily override styles
      div.style.minHeight = 'auto';
      div.style.maxHeight = 'none';
      div.style.overflow = 'visible';
    });

    // Select elements to exclude
    const excludedElements = Array.from(
      container.querySelectorAll('[data-no-pdf-export]')
    );

    // Save their original styles
    const excludedOriginalStyles = excludedElements.map((el) => ({
      element: el as HTMLElement,
      position: (el as HTMLElement).style.position,
      left: (el as HTMLElement).style.left,
      visibility: (el as HTMLElement).style.visibility,
    }));

    // Move elements off-screen instead of hiding them
    excludedElements.forEach((el) => {
      (el as HTMLElement).style.position = 'absolute';
      (el as HTMLElement).style.left = '-9999px';
      (el as HTMLElement).style.visibility = 'hidden';
    });

    // Hide AssetItem components temporarily
    const assetItems = container.querySelectorAll(
      '[data-attribute="assetItemBox"]'
    );
    assetItems.forEach((assetItem) => {
      (assetItem as HTMLElement).style.display = 'none';
    });
    const originalWidth = container.style.width;
    const originalHeight = container.style.height;

    // Increase the size of the container temporarily
    container.style.width = '100%';
    container.style.height = 'auto'; // Maintain aspect ratio
    // Capture the entire container (excluding AssetItem components) as an image
    const containerImage = await htmlToImage.toPng(container, {
      quality: 1,
      pixelRatio: scale,
    });

    // Restore original styles for excluded elements
    excludedOriginalStyles.forEach(
      ({ element, position, left, visibility }) => {
        element.style.position = position;
        element.style.left = left;
        element.style.visibility = visibility;
      }
    );

    // Restore original size
    container.style.width = originalWidth;
    container.style.height = originalHeight;

    // Restore UI after capturing the image
    requestAnimationFrame(() => {
      // Restore AssetItem components visibility
      assetItems.forEach((assetItem) => {
        (assetItem as HTMLElement).style.display = '';
      });

      // Restore original styles for scrollable divs
      originalStyles.forEach(({ element, minHeight, maxHeight, overflow }) => {
        element.style.minHeight = minHeight;
        element.style.maxHeight = maxHeight;
        element.style.overflow = overflow;
      });
    });

    // Split the container image into chunks (pages)
    const allImageChunks = await splitImageIntoChunks(
      containerImage,
      pageWidth,
      availableContentHeight
    );

    // Wait for charts to render (adjust delay as needed)
    await delay(2000); // 2-second delay to ensure charts are fully rendered

    // Extract AssetItem components and convert them to images
    const assetItemImages = await Promise.all(
      Array.from(assetItems).map(async (assetItem) => {
        return await htmlToImage.toPng(assetItem as HTMLElement, {
          quality: 1,
          pixelRatio: scale,
        });
      })
    );

    const styles = StyleSheet.create({
      page: {
        backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        padding: 40, // Add margins to all pages
      },
      header: {
        padding: 20,
        flexDirection: 'column',
        height: headerHeight,
      },
      imageContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexGrow: 1,
      },
      logo: {
        width: 100,
        height: 'auto',
        marginBottom: 1,
      },
      textContainer: {
        marginBottom: 1,
        textAlign: 'left',
        paddingLeft: 2,
      },
      title: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 5,
        marginBottom: 5,
      },
      subtitle: {
        fontSize: 10,
        marginTop: 3,
      },
      borderLine: {
        height: 1,
        backgroundColor: '#000',
        marginBottom: 10,
        marginTop: 2,
      },
      contentContainer: {
        flexDirection: 'column',
        flexGrow: 1,
        marginTop: 10, // Add margin to match the first page
      },
      assetItemContainer: {
        flexDirection: 'column', // Stack items vertically
        justifyContent: 'space-between',
        marginTop: 10,
      },
      assetItemImage: {
        width: '100%', // Full width for vertical stacking
        height: 'auto',
        marginBottom: 10, // Add spacing between items
      },
    });

    const MyDocument = () => (
      <Document>
        {/* Render the main content (excluding AssetItem components) */}
        {allImageChunks.map((chunk, index) => (
          <Page
            key={`main-content-${index}`}
            size="A4"
            orientation={pageOrientation}
            style={styles.page}
          >
            {index === 0 && (
              <View style={styles.header}>
                <Image src={symxAILogo} style={styles.logo} />
                <View style={styles.textContainer}>
                  <Text style={styles.title}>X.Machines</Text>
                </View>
                <View style={styles.borderLine} />
                <Text style={styles.subtitle}>{title}</Text>
              </View>
            )}

            <View style={styles.contentContainer}>
              <Image
                src={chunk}
                style={{
                  width: '100%', // Full width to respect margins
                  height: 'auto',
                  maxHeight: availableContentHeight,
                  objectFit: 'contain',
                }}
              />
            </View>
          </Page>
        ))}

        {/* Render AssetItem components (3 per page, stacked vertically) */}
        {assetItemImages.reduce<JSX.Element[]>((acc, assetItemImage, index) => {
          if (index % perPage === 0) {
            // Start a new page for every 3 AssetItems
            acc.push(
              <Page
                key={`asset-item-page-${index / perPage}`}
                size="A4"
                orientation={pageOrientation}
                style={styles.page}
              >
                <View style={styles.header}>
                  <Image src={symxAILogo} style={styles.logo} />
                  <View style={styles.textContainer}>
                    <Text style={styles.title}>X.Machines</Text>
                  </View>
                  <View style={styles.borderLine} />
                  <Text style={styles.subtitle}>{title}</Text>
                </View>
                <View style={styles.assetItemContainer}>
                  {assetItemImages
                    .slice(index, index + perPage)
                    .map((image, i) => (
                      <Image
                        key={`asset-item-${index + i}`}
                        src={image}
                        style={styles.assetItemImage}
                      />
                    ))}
                </View>
              </Page>
            );
          }
          return acc;
        }, [])}
      </Document>
    );

    const pdfBlob = await pdf(<MyDocument />).toBlob();
    saveAs(pdfBlob, `${fileName}`);
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};
