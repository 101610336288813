import NetworkHealthAPI from 'api/network-health.api';

import { BaseHandler } from './base.handler';

export default class NetworkhealthHandler extends BaseHandler {
  private readonly api: NetworkHealthAPI;

  constructor() {
    super();

    this.api = new NetworkHealthAPI();
  }

  async getAll(payload: { customerCode: string }): Promise<any> {
    try {
      const networkHealthData = await this.api.getAll(payload);

      return networkHealthData;
    } catch (_) {
      this.handleError('An error occurred while fetching dtc data.');
    }

    return [];
  }
}
