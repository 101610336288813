import { Box, Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import type { GroupedHistogramDataItem } from 'types/FleetOverview.types';
import { transformData } from 'views/FleetComparison/helper';
import { processUtilizationAttributes } from './utils';
import { ThemePalette } from 'mui.theme';

export const MetricItemChartHistogram = ({
  enableDarkTheme,
  data,
  colorIndex,
  bumperIdMap,
  title,
}: {
  enableDarkTheme: boolean;
  data: GroupedHistogramDataItem[];
  colorIndex: string[];
  bumperIdMap: string[];
  title: string;
}) => {
  let transformedData = transformData(data);

  if (title === 'utilization') {
    transformedData = processUtilizationAttributes(transformedData);
  }

  transformedData = [...transformedData].sort((a, b) => {
    // @ts-expect-error ignore this line
    return new Date(a.name) - new Date(b.name);
  });

  return (
    <Box
      sx={{
        textAlign: 'left',
        margin: '20px',
        paddingLeft: '20px',
        paddingTop: '20px',
        border: '1px solid #26364C',
        borderRadius: '4px',
      }}
    >
      <Typography>Histogram</Typography>
      {data.length > 0 ? (
        <FlexBox
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: 'inherit',
            height: '500px',
            flexDirection: 'column',
            paddingTop: '20px',
            marginRight: '10px',
            paddingBottom: '10px',
          }}
          id="vehicle-status-page"
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={transformedData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                stroke={ThemePalette.dark.cartesianGrid}
                vertical={false}
              />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {bumperIdMap.map((itemMap, index) => (
                <Line
                  key={itemMap}
                  type="linear"
                  dataKey={itemMap}
                  stroke={colorIndex[index]}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </FlexBox>
      ) : (
        <Typography>No Data to Display</Typography>
      )}
    </Box>
  );
};
