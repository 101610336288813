import { Box, Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { ThemePalette } from 'mui.theme';
import dayjs from 'dayjs';
import { useMemo } from 'react';

function generateData(startTime: Date, endTime: Date) {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const interval = 10 * 1000; // Every 10 seconds
  const pitchHoldDuration = 4 * 60 * 1000; // 4 minutes
  const rollHoldDuration = 5 * 60 * 1000; // 5 minutes

  const result = [];

  let currentTime = start;
  let currentDistance = 0;

  let currentPitch = 0; // Start at 0
  let currentRoll = generateRandomRoll();
  let lastPitchChangeTime = start;
  let lastRollChangeTime = start;

  function generateRandomRoll() {
    return Math.random() * 40 - 20;
  }

  function updatePitch(prevPitch: number): number {
    const change = Math.random() * 2 + 1; // Random change between 1 and 3
    const newPitch = prevPitch + (Math.random() < 0.5 ? -change : change);

    // Clamp pitch between 0 and 15
    return Math.max(0, Math.min(15, newPitch));
  }

  while (currentTime <= end) {
    // Change pitch every 4 minutes
    if (
      currentTime.getTime() - lastPitchChangeTime.getTime() >=
      pitchHoldDuration
    ) {
      lastPitchChangeTime = currentTime;
      currentPitch = updatePitch(currentPitch);
    }

    // Change roll every 5 minutes
    if (
      currentTime.getTime() - lastRollChangeTime.getTime() >=
      rollHoldDuration
    ) {
      lastRollChangeTime = currentTime;
      currentRoll = generateRandomRoll();
    }

    const distanceIncrement = 8.3;
    currentDistance += distanceIncrement;

    result.push({
      ts: currentTime.getTime(),
      timestamp: currentTime.toISOString(),
      distance: Number(currentDistance.toFixed(2)),
      pitch: Number(currentPitch.toFixed(5)),
      roll: Number(currentRoll.toFixed(5)),
      rollAngle: Number(currentRoll.toFixed(5)),
      rotation_avg: Number((Math.random() * 2 + 7).toFixed(5)),
      values: [
        Number((currentRoll * 0.5).toFixed(5)),
        Number((currentPitch * 0.5).toFixed(5)),
        Number((Math.random() * -0.3).toFixed(5)),
        Number((Math.random() * -0.1).toFixed(5)),
      ],
    });

    // Move time forward
    currentTime = new Date(currentTime.getTime() + interval);
  }

  return result;
}

const processData = (data: any) => {
  return data.map((item: any, index: number) => ({
    id: index,
    pitch: item.pitch,
    rollAngle: item.values[0],
    ts: item.ts,
  }));
};

export const InclinometerView = ({
  enableDarkTheme,
  startDate,
  endDate,
}: {
  enableDarkTheme: boolean;
  startDate: Date;
  endDate: Date;
}) => {
  const data = useMemo(
    () => generateData(startDate, endDate),
    [startDate, endDate]
  );
  const chartData = processData(data);

  const formatXAxis = (tickItem: number) => {
    const formattedDate = new Date(tickItem).toLocaleString();
    return dayjs(formattedDate).format('HH:mm:ss');
  };

  const gradientOffset = () => {
    const dataMax = Math.max(...chartData.map((i: any) => i.rollAngle));
    const dataMin = Math.min(...chartData.map((i: any) => i.rollAngle));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  return (
    <Box
      sx={{
        marginBottom: '20px',
        padding: '10px',
        border: '1px solid #2F445E',
        borderRadius: '4px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
      }}
    >
      <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Inclinometer
      </Typography>
      <FlexBox
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '250px',
          flexDirection: 'column',
          paddingTop: '20px',
          marginRight: '10px',
          paddingBottom: '10px',
        }}
      >
        <ResponsiveContainer>
          <ComposedChart
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis
              dataKey="ts"
              tickFormatter={formatXAxis}
              tick={{ fontSize: 10 }}
            />
            <YAxis
              label={{
                value: 'Angle (degrees)',
                angle: -90,
                position: 'insideLeft',
              }}
              domain={[-20, 20]}
              tick={{ fontSize: 10 }}
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="bg-white p-2 border border-gray-300 rounded shadow">
                      <p
                        className="text-sm"
                        style={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        }}
                      >
                        Roll Angle: {payload[0].payload.rollAngle.toFixed(2)}°
                      </p>

                      <p
                        className="text-sm"
                        style={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        }}
                      >
                        Time:{' '}
                        {dayjs(payload[0].payload.ts).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <defs>
              <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                <stop offset={off} stopColor="green" stopOpacity={1} />
                <stop offset={off} stopColor="red" stopOpacity={1} />
              </linearGradient>
            </defs>
            <Area
              type="monotone"
              dataKey="rollAngle"
              stroke="#C28413"
              fill="url(#splitColor)"
              fillOpacity={0.3}
              name="Roll Angle"
              strokeOpacity={1}
            />
            <Legend
              verticalAlign="top"
              iconType="line"
              style={{
                padding: '16px',
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </FlexBox>

      <FlexBox
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '250px',
          flexDirection: 'column',
          paddingTop: '20px',
          marginRight: '10px',
          paddingBottom: '10px',
        }}
      >
        <ResponsiveContainer>
          <ComposedChart
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis
              dataKey="ts"
              tickFormatter={formatXAxis}
              tick={{ fontSize: 10 }}
            />
            <YAxis
              label={{
                value: 'Angle (degrees)',
                angle: -90,
                position: 'insideLeft',
              }}
              domain={[-20, 20]}
              tick={{ fontSize: 10 }}
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className="bg-white p-2 border border-gray-300 rounded shadow">
                      <p
                        className="text-sm"
                        style={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        }}
                      >
                        Pitch (Main Incline):{' '}
                        {payload[0].payload.pitch.toFixed(2)}°
                      </p>

                      <p
                        className="text-sm"
                        style={{
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        }}
                      >
                        Time:{' '}
                        {dayjs(payload[0].payload.ts).format(
                          'YYYY-MM-DD HH:mm:ss'
                        )}
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Area
              type="monotone"
              dataKey="pitch"
              stroke="#4ade80"
              fill="#164439"
              fillOpacity={0.3}
              name="Pitch"
              strokeOpacity={1}
            />
            <Legend
              verticalAlign="top"
              iconType="line"
              style={{
                padding: '16px',
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </FlexBox>
    </Box>
  );
};
