import React, { useState } from 'react';
import { type Asset } from 'types/asset.types';
import { useAppSelector } from 'store/hook';
import { Box } from '@mui/material';

import { CompareAssetModal } from '../components/AssetCardsV2/modals/CompareModal';
import MapView from './MapView';
import AssetCardV2 from '../components/AssetCardsV2';
import { AssetList } from '../components/AssetList';
import { type TagDisplay } from '../AssetDisplay';
import { removeDuplicates } from 'utils/helpers/assets';
import { selectUniqByActiveShowOnTableTags } from 'store/tag.slice';
import { FilterViewBar } from '../components/FilterViewBar';
import { FleetOverviewV2GridBox } from '../FleetOverviewV2/FleetOverviewV2Grid';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import NoChartData from 'views/FuelAnalytics/NoChart';
import { assetTypeMappings } from '../helpers/asset.helpers';

interface Props {
  filteredAssets: Asset[];
  enableDarkTheme: boolean;
  shouldDraw: boolean;
  isNonGpsClient: boolean | null;
}

const FleetOverView3 = ({
  filteredAssets,
  enableDarkTheme,
  shouldDraw,
  isNonGpsClient,
}: Props) => {
  const {
    assets,
    assetsLoaded,
    assetOverView: assetOverview,
    isLoadingAllAssets,
    isLoadingAssetOverview,
  } = useAppSelector((state) => state.assetReducer);

  const [showModal, setShowModal] = React.useState(false);
  const [selectedAsset, setselectedAsset] = useState<Asset | null>(null);
  const [filteredArray, setFilteredArray] = useState(filteredAssets);
  const [view, setView] = React.useState<string>(
    isNonGpsClient ? 'card' : 'map'
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const [exportTo, setExportTo] = React.useState(false);
  const [dateComparer, setDateComparer] = React.useState('day');
  const [cardSort, setCardSort] = React.useState('severity');
  const [tags, setTags] = React.useState<TagDisplay[]>([]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  // all tags "active" in our system
  const activeTags = useAppSelector(selectUniqByActiveShowOnTableTags);
  // convert tags to selector options
  const tagSelectionOptions = activeTags.map((tag) => {
    return {
      id: tag.tagName,
      display: tag.tagAlias || tag.tagName,
      unit: tag.unit,
    };
  });
  const sortedTagOptions = tagSelectionOptions
    .slice()
    .sort((a, b) => a.display.localeCompare(b.display));

  const setSelectedTag = (selectedTag: TagDisplay) => {
    let assetTags = [...tags];

    const tagExists = assetTags.find((tag) => tag.id === selectedTag.id);

    if (tagExists) {
      assetTags = assetTags.filter((tag) => tag.id !== selectedTag.id);
    } else {
      assetTags.push(selectedTag);
    }

    setTags([...assetTags]);
    sessionStorage.setItem('assetSelectedTags', JSON.stringify(assetTags));
  };

  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };

  React.useEffect(() => {
    const defaultTagsToShow = activeTags
      .filter((item) => item.showOnFleetOverviewTable)
      .map((tg) => {
        return {
          id: tg.tagName,
          display: tg.tagAlias || tg.tagName,
          unit: tg.unit,
        };
      });

    let assetTagsData = [];
    const storageData = sessionStorage.getItem('assetSelectedTags');

    if (storageData) {
      assetTagsData = JSON.parse(storageData);
    }

    const mergedTags = [...defaultTagsToShow, ...assetTagsData];

    const uniqueTags = removeDuplicates(mergedTags);

    setTags(uniqueTags);
  }, [activeTags]);

  const hasNoDozer =
    assets.filter(
      (asset: Asset) => assetTypeMappings[asset.assetType] === 'Others'
    ).length === 0;

  // useEffect(() => {
  //   const shouldRenderTable = filteredAssets.some((asset) => {
  //     return asset.device?.daysBehind !== undefined;
  //   });
  //   setShouldDraw(shouldRenderTable);
  // }, [filteredAssets]);

  return (
    <>
      {showModal && selectedAsset ? (
        <Box
          sx={{
            position: 'absolute',
            height: '100%',
            zIndex: 20,
          }}
        >
          <CompareAssetModal
            key="compre-assets-modal"
            title="Compare Assets"
            handleAbort={() => {
              setShowModal(false);
              setselectedAsset(null);
            }}
            open={showModal}
            enableDarkTheme={enableDarkTheme}
            selectedAsset={[selectedAsset.assetId]}
            filteredAssets={filteredArray}
          />
        </Box>
      ) : (
        <></>
      )}
      {filteredAssets.length <= 0 && assetsLoaded ? (
        <NoChartData
          message="No assets selected for display"
          enableDarkTheme={enableDarkTheme}
        />
      ) : !assetsLoaded && !shouldDraw ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <SingleFadeLoader />
        </div>
      ) : shouldDraw && filteredAssets.length > 0 ? (
        <>
          {(view === 'card' || view === 'list') && (
            <>
              {assetsLoaded && (
                <FleetOverviewV2GridBox
                  enableDarkTheme={enableDarkTheme}
                  assetOverview={assetOverview}
                  hasNoDozer={hasNoDozer}
                  isLoading={isLoadingAssetOverview}
                />
              )}

              <FilterViewBar
                setSearchValue={onChange}
                setView={setView}
                view={view}
                tags={sortedTagOptions}
                handleSelectTag={setSelectedTag}
                selectedTags={tags}
                handleExport={handleExportClick}
                setSelectedTags={setTags}
                enableDarkTheme={enableDarkTheme}
                shouldShowNewDashboard={true}
                dateComparer={dateComparer}
                setDateComparer={setDateComparer}
                cardSort={cardSort}
                setCardSort={setCardSort}
              />
            </>
          )}
          {view === 'card' ? (
            /**
             * The tags here need to be added to the asset data object itself
             * Only tags with show in card view should be added
             */
            <>
              <AssetCardV2
                filteredAssets={filteredAssets}
                searchValue={searchValue}
                enableDarkTheme={enableDarkTheme}
                dateComparer={dateComparer}
                cardSort={cardSort}
                isLoadingAllAssets={isLoadingAllAssets}
              />
            </>
          ) : view === 'list' ? (
            <AssetList
              searchValue={searchValue}
              filteredAssets={filteredAssets}
              tags={tags}
              exportTo={exportTo}
              handleExport={handleExportClick}
            />
          ) : (
            <MapView
              filteredAssets={filteredAssets}
              enableDarkTheme={enableDarkTheme}
              isLoadingAllAssets={isLoadingAllAssets}
              setShowModal={setShowModal}
              selectedAsset={selectedAsset}
              setselectedAsset={setselectedAsset}
              filteredArray={filteredArray}
              setFilteredArray={setFilteredArray}
              view={view}
              setView={setView}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              isNonGpsClient={isNonGpsClient}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FleetOverView3;
