import { Box, Grid, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import type {
  HistogramData,
  GroupedHistogramDataItem,
} from 'types/FleetOverview.types';
import type { Asset } from 'types/asset.types';
import { MetricItemChartHistogram } from './Charts/Histogram';
import { MetricItemChartTrends } from './Charts/Trends';

const colorIndex = ['#F08733', '#58BF6A', '#497BFA', '#DB5856'];

export const ItemCollapse = ({
  enableDarkTheme,
  data,
  assets,
  thresholds,
  title,
  defaultThresholds,
}: {
  enableDarkTheme: boolean;
  data: HistogramData[];
  assets: Asset[];
  thresholds: any[];
  title: string;
  defaultThresholds: any[];
}) => {
  const chartData: GroupedHistogramDataItem[] = data.reduce(
    (acc: GroupedHistogramDataItem[], current: HistogramData) => {
      const existingDevice = acc.find((item) => item.name === current.device);
      if (existingDevice) {
        existingDevice.data.push({
          value: current.value,
          date: current.date,
        });
      } else {
        acc.push({
          name: current.device,
          data: [
            {
              value: current.value,
              date: current.date,
            },
          ],
        });
      }
      return acc;
    },
    []
  );

  const formattedDataWithName = chartData.map((item) => {
    // find asset it belongs to
    const itemAsset = assets.find(
      (asset) => asset.device.deviceId === item.name
    );
    if (itemAsset) {
      return {
        ...item,
        name: itemAsset.bumperNumber,
      };
    }
    return {
      ...item,
    };
  });

  const bumperIdMap = formattedDataWithName.map((item) => item.name);

  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.toolBarBackground,
      }}
    >
      <Grid container>
        <Grid item lg={6} sm={12}>
          <MetricItemChartHistogram
            enableDarkTheme={enableDarkTheme}
            data={formattedDataWithName}
            colorIndex={colorIndex}
            bumperIdMap={bumperIdMap}
            title={title}
          />
        </Grid>
        <Grid item lg={6} sm={12}>
          <MetricItemChartTrends
            enableDarkTheme={enableDarkTheme}
            data={formattedDataWithName}
            colorIndex={colorIndex}
            bumperIdMap={bumperIdMap}
            thresholds={thresholds}
            title={title}
            defaultThresholds={defaultThresholds}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingTop: '10px',
          paddingBottom: '20px',
        }}
      >
        {bumperIdMap.map((item, index) => {
          return (
            <>
              <Box
                width={10}
                height={10}
                bgcolor={colorIndex[index]}
                sx={{
                  marginRight: '5px',
                  marginLeft: '30px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '13px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
                key={index}
              >
                {item}
              </Typography>
            </>
          );
        })}
      </Box>
    </Box>
  );
};
