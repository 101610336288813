import { Box, Grid, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ThemePalette } from 'mui.theme';

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: '#8c96a3',
  fontSize: '0.875rem',
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '12px',
}));

export const AssetDetails = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  return (
    <Grid item lg={5} md={12} sm={12} xs={12}>
      <Box
        sx={{
          padding: '20px 10px 20px 10px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          borderRadius: '5px',
          border: `1px solid rgba(255, 255, 255, 0.15)`,
          minHeight: '160px',
          maxHeight: '160px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >
                Asset Details
              </Typography>
              <Typography
                color="#8c96a3"
                sx={{ fontSize: '12px', marginLeft: '4px' }}
              >
                Last Ingested time: 24/05/2024
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}></Grid>

          <Grid item container xs={12}>
            <Grid item xs={2} md={1}>
              <Box
                sx={{
                  width: 80,
                  height: 80,
                  // Empty space for SVG
                }}
              />
            </Grid>

            <Grid item container xs={10} md={11} spacing={2}>
              <Grid item xs={12} container>
                <Grid item xs={12} md={4}>
                  <InfoLabel>Asset ID</InfoLabel>
                  <InfoValue>HT - 7762</InfoValue>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoLabel>Type</InfoLabel>
                  <InfoValue>HL- Haul Truck</InfoValue>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoLabel>Hierarchy Node</InfoLabel>
                  <InfoValue>Klg</InfoValue>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ borderColor: 'rgba(255,255,255,0.1)', my: 1 }} />
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={12} md={4}>
                  <InfoLabel>OEM</InfoLabel>
                  <InfoValue>CAT</InfoValue>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoLabel>Model</InfoLabel>
                  <InfoValue>795F</InfoValue>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InfoLabel>Status</InfoLabel>
                  <InfoValue>Operational</InfoValue>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
