import React from 'react';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { CustomizedContent } from './TreeChartCellRenderer';
import type { CategoryData } from '../utils';
import { Box, Typography, Button, Popover, Divider } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { ThemePalette } from 'mui.theme';
import { truncateTo2dp } from 'utils/helpers/general';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';

const COLORS = ['#4683E6'];

const CustomTooltip: React.FC<{
  active?: boolean;
  payload?: any;
  label?: string;
  enableDarkTheme: boolean;
}> = ({ active, payload, label, enableDarkTheme }) => {
  if (active && payload && payload.length) {
    const hoverCell = payload[0].payload;

    return (
      <>
        <Box
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.pageBackground
              : ThemePalette.light.pageBackground,
            padding: '10px',
          }}
        >
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Component: {hoverCell?.name || ''}
          </Typography>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Total Cost: {truncateTo2dp(hoverCell.totalCost)}{' '}
          </Typography>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Total Hours: {hoverCell.totalHours}{' '}
          </Typography>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Total Part Count: {hoverCell.totalWorkOrders}{' '}
          </Typography>
        </Box>
      </>
    );
  }

  return null;
};

export const ComponentsTreeChart = ({
  selectedCell,
  handleCellClick,
  data,
  enableDarkTheme = false,
  open,
  anchorEl,
  handleClose,
  showPopOver = false,
  handleShowTab,
}: {
  selectedCell: string;
  handleCellClick: any;
  data: CategoryData[];
  enableDarkTheme: boolean;
  open: boolean;
  anchorEl: any;
  handleClose: any;
  showPopOver?: boolean;
  handleShowTab: any;
}) => {
  const id = open ? 'simple-popover' : undefined;

  const handleConfirmButtonClick = (type: string, name: string) => {
    handleShowTab(type, name);
    handleClose();
  };

  const cellData = data.find((item) => item.name === selectedCell);

  return (
    <>
      <Popover
        id={id}
        open={showPopOver && open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#09182D',
            minHeight: '40px',
            minWidth: '150px',
            color: 'white',
          }}
        >
          {cellData ? (
            <>
              <Typography
                sx={{
                  fontSize: '12px',
                  textAlign: 'center',
                  backgroundColor: '#3666EC',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                {capitalizeFirstLetter(cellData.name)}
              </Typography>
              <Box
                sx={{
                  padding: '4px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#7B7B79',
                    marginTop: '5px',
                  }}
                >
                  Total Cost:{' '}
                  <span>${formatNumberToShortScale(cellData.totalCost)}</span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#7B7B79',
                    marginTop: '5px',
                  }}
                >
                  Total Hours:{' '}
                  <span>{formatNumberToShortScale(cellData.totalHours)}</span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#7B7B79',
                    marginTop: '5px',
                  }}
                >
                  Total Part Count:{' '}
                  <span>
                    {formatNumberToShortScale(cellData.totalWorkOrders)}
                  </span>
                </Typography>
              </Box>
            </>
          ) : null}
          <Box>
            <Button
              onClick={() => {
                handleConfirmButtonClick('component', selectedCell);
              }}
              color="primary"
              variant="contained"
              sx={{
                textTransform: 'none',
                width: '100%',
              }}
            >
              View in Graphs
            </Button>
          </Box>
          <Divider
            sx={{
              marginTop: '4px',
              marginBottom: '4px',
              backgroundColor: '#26364C',
            }}
          />
          <Box>
            <Button
              onClick={() => {
                handleConfirmButtonClick('subcomponent', selectedCell);
              }}
              color="primary"
              variant="contained"
              sx={{
                textTransform: 'none',
                width: '100%',
              }}
            >
              View Subcomponents
            </Button>
          </Box>
        </Box>
      </Popover>
      <ResponsiveContainer width="100%" height="100%">
        <Treemap
          width={500}
          height={600}
          data={data}
          dataKey="totalCost"
          aspectRatio={4 / 3}
          stroke="#fff"
          content={
            // @ts-expect-error error exists in ts with chart library
            <CustomizedContent
              colors={COLORS}
              onClick={handleCellClick}
              selectedCell={selectedCell}
              enableDarkTheme={enableDarkTheme}
            />
          }
        >
          <Tooltip
            content={<CustomTooltip enableDarkTheme={enableDarkTheme} />}
          />
        </Treemap>
      </ResponsiveContainer>
    </>
  );
};
