/* eslint-disable @typescript-eslint/restrict-plus-operands */
import dayjs from 'dayjs';
import Chart from 'react-apexcharts';
import { pastelColorMap, generateRandomColor } from 'utils/helpers/general';
import { type ApexOptions } from 'apexcharts';
import { isDarkTheme } from 'utils/theme';
import { useAppSelector } from 'store/hook';
import { ThemePalette } from 'mui.theme';
import './AreaChartTooltip.css';

const generateHTMLStringForTooltip = (
  dataObj?: Record<string, any>,
  darkMode?: boolean
) => {
  if (!dataObj) {
    return '';
  }
  let htmlString = '';
  for (const key in dataObj) {
    if (dataObj?.[key] === null || dataObj?.[key] === undefined) continue;
    htmlString += `<div class='tooltip-item'>${key}: ${dataObj[key]}</div>`;
  }
  return htmlString;
};

export const ComponentsLineChart = ({ data }: { data: any[] }) => {
  // Get the current theme
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  // Format data for ApexCharts
  // const series = data.map((dt: any, index: number) => ({
  //   name: dt.name,
  //   data: dt.data.map((d: any) => ({
  //     x: dayjs.unix(d.timestamp).toDate(), // Convert timestamp to Date object
  //     y: d.total_cost_asset, // Y-axis value
  //     total_cost_asset: d.total_cost_asset, // Additional data for tooltip
  //     total_labour_cost: d.total_labour_cost, // Additional data for tooltip
  //     total_part_cost: d.total_part_cost, // Additional data for tooltip
  //     work_order_number: d.work_order_number, // Additional data for tooltip
  //     asset_id: d.asset_id, // Additional data for tooltip
  //     timestamp: d.timestamp, // Additional data for tooltip
  //   })),
  //   color: pastelColorMap[index] || generateRandomColor(), // Assign color
  // }));

  const aggregatedDataMap = data.reduce((acc: Record<string, any>, dt: any) => {
    dt.data.forEach((d: any) => {
      const dateKey = dayjs.unix(d.timestamp).format('YYYY-MM-DD'); // Group by date

      if (!acc[dateKey]) {
        acc[dateKey] = {
          x: dayjs.unix(d.timestamp).toDate(),
          y: d.total_cost_asset,
          total_cost_asset: d.total_cost_asset,
          total_labour_cost: d.total_labour_cost,
          total_part_cost: d.total_part_cost,
          work_order_number: new Set([d.work_order_number]),
          asset_id: new Set([d.asset_id]),
          timestamp: d.timestamp,
        };
      } else {
        acc[dateKey].y += d.total_cost_asset;
        acc[dateKey].total_cost_asset += d.total_cost_asset;
        acc[dateKey].total_labour_cost += d.total_labour_cost;
        acc[dateKey].total_part_cost += d.total_part_cost;
        acc[dateKey].work_order_number.add(d.work_order_number);
        acc[dateKey].asset_id.add(d.asset_id);
      }
    });

    return acc;
  }, {});

  const aggregatedSeries = [
    {
      name:
        data.length > 1
          ? 'Aggregated Data'
          : data.length === 0
          ? 'Asset Data'
          : data[0].name,
      data: Object.values(aggregatedDataMap)
        .map((item) => ({
          ...item,
          work_order_number: Array.from(item.work_order_number),
          asset_id: Array.from(item.asset_id),
        }))
        .sort((a, b) => a.timestamp - b.timestamp),
      color: pastelColorMap[0],
    },
  ];

  // ApexCharts options
  const options: ApexOptions = {
    markers: {
      size: 3,
    },
    chart: {
      type: 'area',
      stacked: false,
      zoom: {
        enabled: true, // Enable zooming
      },
      toolbar: {
        show: false, // Show toolbar for zoom/reset
      },
      background: enableDarkTheme ? ThemePalette.dark?.topBar : '#ffffff', // Set chart background based on theme
    },
    dataLabels: {
      enabled: false, // Disable data labels for cleaner look
    },
    stroke: {
      curve: 'smooth', // Smooth lines
    },
    xaxis: {
      type: 'datetime', // Use datetime for X-axis
      labels: {
        formatter: (value, timestamp) => {
          return dayjs(timestamp).format('MMM DD');
        },
        style: {
          colors: enableDarkTheme ? '#ffffff' : '#000000', // X-axis label color
        },
      },
      axisBorder: {
        color: enableDarkTheme ? '#ffffff' : '#000000', // X-axis border color
      },
      axisTicks: {
        color: enableDarkTheme ? '#ffffff' : '#000000', // X-axis tick color
      },
      tooltip: {
        enabled: false, // Disable default X-axis tooltip
      },
    },
    yaxis: {
      title: {
        text: 'Cost', // Y-axis label
        style: {
          color: enableDarkTheme ? '#ffffff' : '#000000', // Y-axis title color
        },
      },
      labels: {
        style: {
          colors: enableDarkTheme ? '#ffffff' : '#000000', // Y-axis label color
        },
      },
    },
    tooltip: {
      custom: ({ seriesIndex, dataPointIndex, w }: any) => {
        const {
          total_cost_asset: cost,
          work_order_number: workOrderId,
          timestamp,
        } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        const seriesName = w.globals.initialSeries[seriesIndex]?.name;
        const seriesColor = w.globals.colors[seriesIndex];

        return `
          <div class='apexcharts-custom-tooltip'>
            <div style="font-size: 12px; margin-bottom: 4px; text-transform: capitalize; color: ${seriesColor}; font-weight: bold">
              ${seriesName}
            </div>
            ${generateHTMLStringForTooltip(
              {
                Cost: cost,
                'Work Order': workOrderId,
                Date: timestamp
                  ? dayjs(+timestamp * 1000).format('YYYY-MM-DD')
                  : '',
              },
              enableDarkTheme
            )}
          </div>
          `;
      },
    },
    legend: {
      show: true, // Show legend
      position: 'top', // Position legend at the top
      labels: {
        colors: enableDarkTheme ? '#ffffff' : '#000000', // Legend text color
      },
    },
    fill: {
      type: 'gradient', // Gradient fill for area
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 100],
      },
    },
    grid: {
      borderColor: enableDarkTheme ? '#444444' : '#e0e0e0', // Grid line color
    },
    theme: {
      mode: enableDarkTheme ? 'dark' : 'light', // Set theme mode
    },
  };

  return (
    <Chart
      options={options}
      series={aggregatedSeries}
      type="area"
      height={317.5}
      width="100%"
    />
  );
};

export default ComponentsLineChart;
