import { Box, Grid, Typography, Chip } from '@mui/material';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import { AssetDetails } from './Details/Asset';
import { SummaryDetails } from './Details/Summary';
import { InsightDetails } from './Details/InsightDetails';
import { SupportingEvidence } from './Details/SupportingEvidence';

const MlInsightDetails = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme: boolean = isDarkTheme(theme);
  return (
    <Box
      sx={{
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        borderRadius: '5px',
        marginTop: '10px',
        padding: '10px',
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <AssetDetails enableDarkTheme={enableDarkTheme} />
          <InsightDetails enableDarkTheme={enableDarkTheme} />
        </Grid>
      </Box>
      <Box sx={{ marginTop: '20px' }}>
        <Grid container spacing={2}>
          <SummaryDetails enableDarkTheme={enableDarkTheme} />
          <SupportingEvidence enableDarkTheme={enableDarkTheme} />
        </Grid>
      </Box>
    </Box>
  );
};

export default MlInsightDetails;
