import BaseAPI from './base.api';

export default class DriverBehaviourAPI extends BaseAPI {
  getAll = async (payload: {
    event: string;
    range?: string;
    startDate?: string;
    endDate?: string;
  }) => {
    const response = await this.API.get('driver-event', {
      params: {
        ...payload,
      },
    });
    return response.data;
  };

  getInclinometer = async (payload: {
    startDateTime: string;
    endDateTime: string;
    assetId: string;
    assetType: string;
  }) => {
    const response = await this.API.post('inclinometer', payload);
    return response.data;
  };
}
