import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Typography, Grid, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppSelector } from 'store/hook';
import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import { ComponentsTreeChart } from './Components/TreeChart';
import { ComponentsPieChart } from './Components/PieChart';
import { ComponentsLineChart } from './Components/LineChart';
import { Base } from 'components/Containers/BaseContainer';
import {
  extractWorkOrderData,
  getAssetsByKey,
  extractSelectedAssets,
  getFirstAttribute,
} from './utils';
import { ThemePalette } from 'mui.theme';
import LoadingDots from 'views/FleetOverview/components/AssetCardsV2/LoadingDots';
import useAppTheme from 'hooks/useAppTheme';
import { Subcomponent } from './Components/Subcomponent';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';

export const PartsViewV2 = () => {
  const { enableDarkTheme } = useAppTheme();
  const squaresHandler = new SquaresDashboardHandler();
  const { dashboard: data } = useAppSelector(
    (state) => state.squaresDashboardReducer
  );
  // const { isLoading } = useAppSelector((state) => state.appReducer);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );
  let initialStartDate = new Date();
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1);
  let initialEndDate = new Date();
  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }
  const [selectedStartDate, setStartDate] = useState(initialStartDate);
  const [selectedEndDate, setEndDate] = useState(initialEndDate);
  const [anchorEl, setAnchorEl] = useState(null);
  const [componentType, setComponentType] = useState('component');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);

    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };
  const [selectedCell, setSelectedCell] = useState<string>('');
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);

  const handleTreeCellClick = (event: any, name: string) => {
    handleClick(event);
    setSelectedCell(name);
  };

  const handleShowTab = (type: string, name: string) => {
    setComponentType(type);
    setSelectedCell(name);
    // When new tree cell is selected Clear assets
    if (selectedCell !== name) {
      setSelectedAssets([]);
    }
  };

  const handlePieClick = (data: any) => {
    const name = data.name;
    // data.fill has the color of selected data point. Use this in future if you need to sync colors
    // between pie chart and line/area chart next to it
    const updatedClickedCells = [...selectedAssets];
    if (updatedClickedCells.includes(name)) {
      const indexToRemove = updatedClickedCells.indexOf(name);
      updatedClickedCells.splice(indexToRemove, 1);
    } else {
      updatedClickedCells.push(name);
    }
    setSelectedAssets(updatedClickedCells);
  };

  const workOrderData = extractWorkOrderData(data?.components ?? {});
  const pieChartData = getAssetsByKey(data?.components ?? {}, selectedCell);
  const selectedAssetsData = extractSelectedAssets(
    data.assetComponentsCosts,
    selectedAssets,
    selectedCell
  );

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      setIsLoading(true);
      setSelectedAssets([]);
      void squaresHandler.getPartsComponentsData(
        selectedStartDate,
        selectedEndDate
      );
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    const highestKey = getFirstAttribute(data.components);
    setSelectedCell(highestKey);
    setIsLoading(false);
  }, [data]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          paddingLeft: '40%',
          paddingTop: '10px',
          paddingBottom: '20px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            paddingRight: '10px',
            fontSize: '20px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {' '}
          Parts Work Order Cost Totals
        </Typography>
        <DatePicker
          showIcon
          selected={selectedStartDate}
          onChange={handleDateChange}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          maxDate={new Date(new Date().getTime())}
          selectsRange
        />
      </Box>
      {/* {isLoading ? ( */}
      {isLoading ? (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '20px',
            paddingTop: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Waiting For Assets
          <LoadingDots enableDarkTheme={enableDarkTheme} />{' '}
        </Typography>
      ) : (
        <>
          {workOrderData.length <= 0 ? (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                paddingTop: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {' '}
              No Data, Please Select a different Date Range
            </Typography>
          ) : (
            <>
              {componentType === 'component' ? (
                <>
                  <Base
                    sx={{
                      padding: '20px 20px 40px 20px',
                      backgroundColor: enableDarkTheme
                        ? ThemePalette.dark.toolBarBackground
                        : ThemePalette.light.toolBarBackground,
                    }}
                  >
                    <Box
                      sx={{
                        height: '400px',
                        maxHeight: '400px',
                      }}
                    >
                      <ComponentsTreeChart
                        selectedCell={selectedCell}
                        handleCellClick={handleTreeCellClick}
                        data={workOrderData}
                        enableDarkTheme={enableDarkTheme}
                        open={open}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        showPopOver={true}
                        handleShowTab={handleShowTab}
                      />
                    </Box>
                  </Base>

                  <Box
                    sx={{
                      marginY: '20px',
                      backgroundColor: enableDarkTheme
                        ? ThemePalette.dark.toolBarBackground
                        : ThemePalette.light.toolBarBackground,
                    }}
                  >
                    <Grid
                      sx={{
                        display: 'flex',
                        marginLeft: '0px',
                        maxWidth: '100%',
                        paddingBottom: '30px',
                      }}
                      container
                      spacing={2}
                    >
                      {/* <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    marginLeft: '0px',
                    paddingBottom: '30px',
                  }}
                ></Grid> */}
                      <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Base
                          sx={{
                            height: '100%',
                            maxHeight: '100%',
                            backgroundColor: enableDarkTheme
                              ? ThemePalette.dark.toolBarBackground
                              : ThemePalette.light.toolBarBackground,
                          }}
                        >
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '15px',
                                paddingTop: '30px',
                                color: enableDarkTheme
                                  ? ThemePalette.typography.white
                                  : ThemePalette.typography.black,
                              }}
                            >
                              {`Asset distribution by total cost for `}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '20px',

                                color: enableDarkTheme
                                  ? ThemePalette.typography.white
                                  : ThemePalette.typography.black,
                              }}
                            >
                              {`${selectedCell}`}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'center',
                            }}
                          >
                            <ComponentsPieChart
                              data={pieChartData}
                              handlePieClick={handlePieClick}
                              enableDarkTheme={enableDarkTheme}
                              onlyOneActive
                            />
                          </Box>
                        </Base>
                      </Grid>
                      <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Base
                          sx={{
                            height: '100%',
                            maxHeight: '100%',
                            backgroundColor: enableDarkTheme
                              ? ThemePalette.dark.toolBarBackground
                              : ThemePalette.light.toolBarBackground,
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '15px',
                                paddingTop: '30px',
                                color: enableDarkTheme
                                  ? ThemePalette.typography.white
                                  : ThemePalette.typography.black,
                              }}
                            >
                              {`Asset distribution by total cost for `}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '20px',

                                color: enableDarkTheme
                                  ? ThemePalette.typography.white
                                  : ThemePalette.typography.black,
                              }}
                            >
                              {`${selectedCell}`}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {selectedAssets.length < 1 ? (
                              <Typography
                                sx={{
                                  textAlign: 'center',
                                  fontSize: '20px',
                                  paddingTop: '10px',
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.white
                                    : ThemePalette.typography.black,
                                }}
                              >
                                Please select an asset from the pie chart.
                              </Typography>
                            ) : (
                              <Box sx={{ width: '100%', height: '100%' }}>
                                <ComponentsLineChart
                                  data={selectedAssetsData}
                                />
                              </Box>
                            )}
                          </Box>
                        </Base>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      marginBottom: '10px',
                      marginTop: '10px',
                      display: 'flex',
                    }}
                  >
                    <ArrowBackIcon
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        fontSize: '20px',
                        marginTop: '4px',
                        marginRight: '5px',
                      }}
                      onClick={() => {
                        setComponentType('component');
                      }}
                    />
                    <Typography
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        fontSize: '20px',
                      }}
                    >
                      {capitalizeFirstLetter(selectedCell)}
                    </Typography>
                  </Box>
                  <Subcomponent
                    enableDarkTheme={enableDarkTheme}
                    data={data.workOrders}
                    selectedCell={selectedCell}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
