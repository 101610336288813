import { Grid, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ThemePalette } from 'mui.theme';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import CircularProgressChart from './CircularProgress';
import type { FleetDetailsItem } from 'types/utilization.types';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { truncateTo2dp } from 'utils/helpers/general';
import { daysLeftInYear, getShiftHours } from 'views/Utilization/utils';
import { useAppSelector } from 'store/hook';
import { useEffect } from 'react';
import TargetSetter from './TargetSetter';

interface Props {
  enableDarkTheme: boolean;
  unitSystem: string;
  customerCode: string;
  isLoading: boolean;
  data: FleetDetailsItem;
  currentDailyAvg: number;
  rollingDailyAverage: number;
  assetType: 'all' | 'loader' | 'hauler';
  isLoadingFleetPeriodSummary: boolean;
  setDetailsVal: any;
}

const Details = ({
  enableDarkTheme,
  unitSystem,
  customerCode,
  isLoading,
  data,
  currentDailyAvg,
  rollingDailyAverage: sevenDayAvg,
  assetType,
  isLoadingFleetPeriodSummary,
  setDetailsVal,
}: Props) => {
  const targetData = useAppSelector((state) => state.targetReducer.data.target);
  const isLoadingTarget = useAppSelector(
    (state) => state.targetReducer.loadingStates?.isLoadingTarget
  );

  const noOfAssets = Number(data.number_of_assets);

  const target = useAppSelector(
    (state) => state.targetReducer.data.utilization
  );
  const utilizationTarget = target?.find(
    (item) => item.assetType === assetType
  );
  const value = Number(data.total_engine_working_minutes) / 60; // in hours
  const hasMetTarget = value > (utilizationTarget?.targetValue as number);

  useEffect(() => {
    if (!isLoadingFleetPeriodSummary) {
      setDetailsVal({
        'YTD Target (hours)': formatNumberToShortScale(
          utilizationTarget?.ytdTarget as number
        ),

        'Annual Productivity Target (hrs)': formatNumberToShortScale(
          (utilizationTarget?.targetValue as number) ||
            365 * getShiftHours(customerCode) * noOfAssets
        ),
        'Rolling Daily Average (hrs/day)': truncateTo2dp(sevenDayAvg),
        'Remaining Days': daysLeftInYear(),
        'Current Daily Average (hrs/day)': truncateTo2dp(currentDailyAvg / 60),
        'Target Met (True/False)': hasMetTarget,
      });
    }
  }, [
    isLoadingFleetPeriodSummary,
    currentDailyAvg,
    sevenDayAvg,
    noOfAssets,
    targetData,
    hasMetTarget,
  ]);

  return (
    <Grid item lg={6} md={6} sm={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              minHeight: '30px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '13px',
              }}
            >
              Total Productive Hours (YTD)
            </Typography>
            {assetType !== 'all' && (
              <TargetSetter
                enableDarkTheme={enableDarkTheme}
                assetType={assetType}
              />
            )}
          </Box>
        </Box>
        {/** Content Box */}
        {isLoading ? (
          <MultiColorCircularLoader height="300px" />
        ) : (
          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            <Grid container>
              <Grid item lg={6} sm={12}>
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <CircularProgressChart
                    enableDarkTheme={enableDarkTheme}
                    value={Number(data.total_engine_working_minutes)}
                    target={(utilizationTarget?.targetValue as number) * 60}
                    unitSystem={unitSystem}
                  />
                  <Box
                    sx={{
                      alignContent: 'center',
                      paddingLeft: '10px',
                    }}
                  >
                    <>
                      <Typography
                        sx={{
                          fontSize: '11px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        YTD Target
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '11px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingBottom: '15px',
                        }}
                      >
                        {isLoadingTarget
                          ? 'Loading...'
                          : `${formatNumberToShortScale(
                              utilizationTarget?.ytdTarget as number
                            )} Hours`}
                      </Typography>
                    </>
                    <>
                      <Typography
                        sx={{
                          fontSize: '11px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Annual Target
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '11px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                        }}
                      >
                        {isLoadingTarget
                          ? 'Loading...'
                          : `${formatNumberToShortScale(
                              utilizationTarget?.targetValue as number
                            )} Hours`}
                      </Typography>
                    </>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} sm={12}>
                <Box>
                  <Grid container>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Annual Productivity Target
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {formatNumberToShortScale(
                          (utilizationTarget?.targetValue as number) ||
                            365 * getShiftHours(customerCode) * noOfAssets
                        )}
                        <span
                          style={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.lightGrey
                              : ThemePalette.typography.black,
                          }}
                        >
                          {' '}
                          Hours
                        </span>
                      </Typography>

                      <Typography
                        sx={{
                          paddingTop: '30px',
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Rolling Daily Average
                      </Typography>
                      {isLoadingFleetPeriodSummary ? (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          Loading...
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          {sevenDayAvg > 0 ? (
                            <>
                              {truncateTo2dp(sevenDayAvg)}
                              <span
                                style={{
                                  color: enableDarkTheme
                                    ? ThemePalette.typography.lightGrey
                                    : ThemePalette.typography.black,
                                }}
                              >
                                {' '}
                                Hours/Day
                              </span>
                            </>
                          ) : (
                            <>{'No data available'}</>
                          )}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={6} sm={12}>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Remaining Days
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.white
                            : ThemePalette.typography.black,
                          paddingTop: '10px',
                        }}
                      >
                        {daysLeftInYear()}
                      </Typography>

                      <Typography
                        sx={{
                          paddingTop: '30px',
                          fontSize: '13px',
                          color: enableDarkTheme
                            ? ThemePalette.typography.lightGrey
                            : ThemePalette.typography.black,
                        }}
                      >
                        Current Daily Average
                      </Typography>
                      {isLoadingFleetPeriodSummary ? (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          Loading...
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: '13px',
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            paddingTop: '10px',
                          }}
                        >
                          {truncateTo2dp(currentDailyAvg / 60)}
                          <span
                            style={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.lightGrey
                                : ThemePalette.typography.black,
                            }}
                          >
                            {' '}
                            Hours/Day
                          </span>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  {!hasMetTarget ? (
                    <></>
                  ) : (
                    <Box
                      sx={{
                        textAlign: 'center',
                        border: '1px solid #2F445F',
                        borderRadius: '5px',
                        marginTop: '15px',
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CheckCircleIcon
                          sx={{ color: '#029161', marginRight: '4px' }}
                        />
                        Avg Productive hours are on target
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box
          sx={{
            marginTop: '50px', // Pushes the box to the bottom
          }}
        >
          <Typography
            sx={{
              fontSize: '10px',
              textAlign: 'right',
              color: enableDarkTheme ? '#F3F4F6' : '#2F445D',
              marginBottom: '4px',
            }}
          >
            * Averages shown above are based on last 7 days recent most data
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              textAlign: 'right',
              color: enableDarkTheme ? '#F3F4F6' : '#2F445D',
              marginBottom: '4px',
            }}
          >
            ** YTD numbers are based on real-time data received from assets
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Details;
