import { SVG } from 'components/Asset/SVG';
import { IdSpan } from 'components/AgGrid/CellRenderer/ListView.styled';
import { theme } from 'mui.theme';
import { type Device as DeviceType } from 'types/device.types';
import { StyledIcon } from 'components/AgGrid/CellRenderer/CellRenderer.styled';
import { DEVICE_ORIENTATION } from 'utils/enums';
import {
  calculateTimeStampFromCurrentTime,
  formatTimestamp,
} from 'utils/helpers/dateTime';
import { checkDeviceStatus } from 'store/utils';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';

const { svgIcons } = theme.palette;

interface Props {
  value: string;
  data: DeviceType & Record<string, number>;
  handleIdClick: (device_id: string) => void;
  handleDeleteDevice: (device_id: string) => void;
  handleOperatorClick: (device_id: string) => void;
  handleTagClick: (device_id: string) => void;
  handleDeviceClick: (device_id: string) => void;
}

export const DeleteAsset = ({
  data: { deviceId },
  handleDeleteDevice,
}: Props) => {
  return (
    <span
      onClick={() => {
        handleDeleteDevice(deviceId);
      }}
    >
      <SVG
        name="deleteIcon"
        style={{
          ...svgIcons?.primary,
        }}
      />
    </span>
  );
};

export const DeviceName = ({ handleIdClick, data: { deviceId } }: Props) => {
  return (
    <IdSpan
      onClick={() => {
        handleIdClick(deviceId);
      }}
    >
      {deviceId}
    </IdSpan>
  );
};

export const AssetId = ({
  handleIdClick,
  data: { deviceId, assetId },
}: Props) => {
  return (
    <IdSpan
      onClick={() => {
        handleIdClick(deviceId);
      }}
    >
      {assetId}
    </IdSpan>
  );
};

export const DeviceStatus = ({ data }: Props) => {
  // 1. If NO asset id, device is unassociated to an asset -> show unlinked status
  if (!data.assetId) {
    return (
      <>
        <StyledIcon>
          <SVG name={'unlinkedDeviceStatusIcon'} />
        </StyledIcon>
        Unlinked
      </>
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isMissing, isActive, isXYFound] = checkDeviceStatus(data);

  if (!isXYFound) {
    return (
      <>
        <StyledIcon>
          <SVG
            name={'offlineIcon'}
            style={{
              ...svgIcons.error,
            }}
          />
        </StyledIcon>
        <IdSpan>Missing X/Y</IdSpan>
      </>
    );
  }
  return (
    <span>
      <StyledIcon>
        <SVG
          name={
            data.connection_status === 'connected'
              ? 'activeIcon'
              : 'offlineIcon'
          }
          style={{
            fill: 'red',
          }}
        />
      </StyledIcon>
      {data.connection_status
        ? capitalizeFirstLetter(data.connection_status)
        : 'Unknown'}
    </span>
  );
};

export const xOrientation = ({ data: { xOrientation } }: Props) => {
  return (
    <>
      {DEVICE_ORIENTATION.find((device) => device.id === xOrientation)?.display}
    </>
  );
};

export const yOrientation = ({ data: { yOrientation } }: Props) => {
  return (
    <>
      {DEVICE_ORIENTATION.find((device) => device.id === yOrientation)?.display}
    </>
  );
};

export const daysBehind = ({ data }: Props): number | string => {
  if (!data.latest_ts) return '-';
  const diffInMinutes =
    calculateTimeStampFromCurrentTime(data.latest_ts) / (1000 * 60);
  const diffInHours = diffInMinutes / 60;

  if (diffInHours < 24) {
    const hours = Math.floor(diffInMinutes / 60);
    const remainingMinutes = Math.floor(diffInMinutes % 60);
    if (remainingMinutes === 0 && hours > 0) {
      return `${hours} hrs`;
    } else if (
      (remainingMinutes === 0 && hours === 0) ||
      (remainingMinutes > 0 && hours === 0)
    ) {
      return `${remainingMinutes} mins`;
    } else {
      return `${hours} hrs, ${remainingMinutes} mins`;
    }
  } else {
    // Calculate days and remaining hours
    const days = Math.floor(diffInHours / 24);
    const remainingHours = Math.floor(diffInHours % 24);
    if (remainingHours === 0) {
      return `${days} days`;
    } else {
      return `${days} days, ${remainingHours} hrs`;
    }
  }
};

export const ingestDate = ({ data }: Props): string => {
  if (!data.connection_updated_ts) return '-';

  const getDate = Number(data.connection_updated_ts);

  return formatTimestamp(getDate);
};

export const connectionDate = ({ data }: Props): string => {
  if (!data.latest_ts) return '-';
  const getDate = Number(data.latest_ts);

  return formatTimestamp(getDate);
};
