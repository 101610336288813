import { fixedLimts } from 'views/TirePressure/util';

export const tireDataCalc = (
  tireData: any,
  metricType: string,
  limits: any,
  unitSystem: string
) => {
  const tireCurrentStatus =
    metricType === 'Pressure'
      ? tireData?.tireCurrentStatus
      : tireData?.tireCurrentTemperature;

  // @ts-expect-error ignore this we have it defined
  const limitsData = fixedLimts[unitSystem][metricType.toLowerCase()];

  const isOverMax = (code: string, value: number, limitData: any) => {
    const { maxValue } = limitData;
    const { minValue } = limitData;
    return !(value >= minValue && value <= maxValue);
  };

  const p1 = Number(tireCurrentStatus?.['0x17'] ?? 0);

  const p2 = Number(tireCurrentStatus?.['0x19'] ?? 0);

  const p3 = Number(tireCurrentStatus?.['0x27'] ?? 0);

  const p4 = Number(tireCurrentStatus?.['0x29'] ?? 0);

  const p1Limits = limits?.find((item: any) => item.tireCode === '0x17');
  const p2Limits = limits?.find((item: any) => item.tireCode === '0x19');
  const p3Limits = limits?.find((item: any) => item.tireCode === '0x27');
  const p4Limits = limits?.find((item: any) => item.tireCode === '0x29');

  const isP1WarninigMax = isOverMax('0x17', p1, limitsData);

  const isP2WarninigMax = isOverMax('0x19', p2, limitsData);

  const isP3WarninigMax = isOverMax('0x27', p3, limitsData);

  const isP4WarninigMax = isOverMax('0x29', p4, limitsData);

  return {
    p1,
    p2,
    p3,
    p4,
    p1Limits,
    p2Limits,
    p3Limits,
    p4Limits,
    isP1WarninigMax,
    isP2WarninigMax,
    isP3WarninigMax,
    isP4WarninigMax,
  };
};
