export function selectSpacedItems(
  array: number[],
  desiredCount: number
): number[] {
  const totalCount = array.length;

  // If the array has fewer than desiredCount items, return the whole array
  if (totalCount <= desiredCount) {
    return array;
  }

  // Calculate the step size
  const step = totalCount / desiredCount;
  const selectedItems = [];

  // Select items at the calculated step intervals
  for (let i = 0; i < desiredCount; i++) {
    const index = Math.floor(i * step);
    selectedItems.push(array[index]);
  }

  return selectedItems;
}

export function sortableFunction(valueA: string, valueB: string) {
  const extractParts = (str: any) =>
    str
      .match(/(\D+|\d+)/g)
      .map((part: any) => (isNaN(part) ? part : Number(part)));

  const partsA = extractParts(valueA);
  const partsB = extractParts(valueB);

  for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
    if (partsA[i] !== partsB[i]) {
      return partsA[i] < partsB[i] ? -1 : 1;
    }
  }
  return partsA.length - partsB.length;
}

export const getLastRecordedDate = (dataJson: any) => {
  if (!dataJson || dataJson.length === 0) return null;

  const sortedDates = dataJson // Sort dates in desc, return most recent one
    .map((item: any) => new Date(item.date))
    .sort((a: any, b: any) => b.getTime() - a.getTime());

  return sortedDates[0];
};
