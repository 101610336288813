import { Box, Typography } from '@mui/material';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { ThemePalette } from 'mui.theme';
import type { Asset } from 'types/asset.types';
import { getArrayByKey } from './utils';

export const AssetEvents = ({
  enableDarkTheme,
  data,
  selectedAsset,
}: {
  enableDarkTheme: boolean;
  data: any;
  selectedAsset: Asset;
}) => {
  const incorrectGearData = getArrayByKey(data, 'incorrect_gear');
  const brakingData = getArrayByKey(data, 'braking');
  const overspeedData = getArrayByKey(data, 'overspeed');
  const accelerationData = getArrayByKey(data, 'acceleration');
  const incorrectGearShiftData = getArrayByKey(data, 'incorrect_gear_shift');
  const incorrectGearSkipData = getArrayByKey(data, 'gear_skip');

  const events = [
    {
      title: 'Incorrect Gear Events',
      value:
        incorrectGearData.find(
          (item: any) => item.device_id === selectedAsset.device.deviceId
        )?.noOfEvents ?? 'N/A',
      color: '#EC4899',
    },
    {
      title: 'Overspeed Events',
      value:
        overspeedData.find(
          (item: any) => item.device_id === selectedAsset.device.deviceId
        )?.noOfEvents ?? 'N/A',
      color: '#EB5757',
    },
    {
      title: 'Braking Events',
      value:
        brakingData.find(
          (item: any) => item.device_id === selectedAsset.device.deviceId
        )?.noOfEvents ?? 'N/A',
      color: '#FFA403',
    },
    {
      title: 'Acceleration Events',
      value:
        accelerationData.find(
          (item: any) => item.device_id === selectedAsset.device.deviceId
        )?.noOfEvents ?? 'N/A',
      color: '#00E396',
    },
    {
      title: 'Incorrect Gear Shift Events',
      value:
        incorrectGearShiftData.find(
          (item: any) => item.device_id === selectedAsset.device.deviceId
        )?.noOfEvents ?? 'N/A',
      color: '#325C9F',
    },
    {
      title: 'Gear Skip Events',
      value:
        incorrectGearSkipData.find(
          (item: any) => item.device_id === selectedAsset.device.deviceId
        )?.noOfEvents ?? 'N/A',
      color: '#777777',
    },
  ];

  return (
    <Box>
      {events.map((item: any, index: number) => {
        return (
          <Box
            key={index}
            sx={{
              marginTop: '20px',
              padding: '10px',
              border: '1px solid #2F445E',
              borderRadius: '4px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.boxBackground
                : ThemePalette.light.boxBackground,
            }}
          >
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
                display: 'flex',
              }}
            >
              <PanoramaFishEyeIcon
                sx={{
                  color: item.color,
                  paddingRight: '10px',
                }}
              />
              <span
                style={{
                  paddingTop: '3px',
                }}
              >
                Total number of {item.title}
              </span>
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '20px',
                paddingTop: '10px',
                fontWeight: '600',
              }}
            >
              {item.value}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
