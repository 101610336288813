import React, { useMemo, useState } from 'react';
import { useAppSelector } from 'store/hook';
import FuelAssetItem from './FuelAssetItem';
import { Box, LinearProgress, Typography } from '@mui/material';
import useAppTheme from 'hooks/useAppTheme';
import { ThemePalette } from 'mui.theme';
import SortBy from './SortBy';
import { type fuelMeasureType } from 'views/FuelHistoricalAnalysis/types/fuelAnalytics.types';
import ProductionSelector from 'views/Production/components/Selector/Selector';

interface Props {
  assetType: string;
  title: string;
  hiddenDropDownItems?: fuelMeasureType[];
}

const FuelAssetAnalyticsSection = ({
  assetType,
  title,
  hiddenDropDownItems,
}: Props) => {
  const dropdownItems = useMemo(() => {
    const items = [
      'Consumption',
      'Co2 Emission',
      'Num of Idle Events',
      'Total Duration of Idle Events',
      'Loaded Fuel Consumption',
      'Unloaded Fuel Consumption',
      'Avg Fuel Rate',
    ];
    return items.filter(
      (item) =>
        !hiddenDropDownItems?.includes(
          item.toLowerCase()?.split(' ')?.join('_') as fuelMeasureType
        )
    );
  }, [hiddenDropDownItems]);
  const { enableDarkTheme } = useAppTheme();
  const {
    loadingStates: { isLoadingFuelTreemapData },
    fleetDeviceDetailsPerDayBasis,
  } = useAppSelector((state) => state.fuelAnalyticsReducer);

  const [selectedSource, setSelectedSource] = useState<string[]>([
    'Consumption',
  ]);

  const [sortBy, setSortBy] = useState<string>('most fuel consumed');
  const handleSource = (value: any) => {
    if (selectedSource.length > 2 || value > 2 || value <= 0) {
      return;
    }
    setSelectedSource(value);
  };

  const selectedAssetTypeDataPerDayBasis = useMemo(() => {
    const filteredData = fleetDeviceDetailsPerDayBasis.filter(
      (item) => item.asset_type === assetType
    );
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortBy === 'most fuel consumed') {
        return b.total_fuel_consumption - a.total_fuel_consumption;
      }
      return a.total_fuel_consumption - b.total_fuel_consumption;
    });
    return sortedData;
  }, [assetType, fleetDeviceDetailsPerDayBasis, sortBy]);

  return (
    <div
      style={{
        padding: '16px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        borderRadius: '5px',
        border: '1px solid #2F445D',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '600',
              paddingTop: '5px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={dropdownItems}
            selected={selectedSource}
            setSelected={handleSource}
            multiple
            minWidth="200px"
            maxWidth="200px"
          />
          <SortBy
            setSelected={setSortBy}
            options={['most fuel consumed', 'least fuel consumed']}
            selected={sortBy}
          />
        </Box>
      </Box>
      {isLoadingFuelTreemapData ? (
        <LinearProgress
          color="secondary"
          sx={{
            marginTop: '65px',
          }}
        />
      ) : (
        selectedAssetTypeDataPerDayBasis.map((item, index) => {
          return (
            <FuelAssetItem
              key={index}
              data={item}
              selectedSource={selectedSource}
            />
          );
        })
      )}
    </div>
  );
};

export default FuelAssetAnalyticsSection;
