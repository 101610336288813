import { Box, Typography, Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import {
  getHighestMaintenanceSpend,
  getHighestComponentSpend,
  getMostFrequentlyReplacedPart,
} from './util';

export const MaintenanceStats = ({
  enableDarkTheme,
  switchToTab,
  data,
}: {
  enableDarkTheme: boolean;
  switchToTab: any;
  data: any[];
}) => {
  const assetWithHighestCost = getHighestMaintenanceSpend(data);
  const componentWithHighestCost = getHighestComponentSpend(data);
  const mostReplacedPart = getMostFrequentlyReplacedPart(data);
  const stats = [
    {
      title: 'Asset with the Highest Maintenance Spend',
      contentTitle: assetWithHighestCost?.assetWithHighestSpend,
      cost: assetWithHighestCost?.totalSpend,
      subContentTitle: `Component with most spend on ${assetWithHighestCost.assetWithHighestSpend}`,
      subContent: assetWithHighestCost?.topComponentForAsset,
      redirectTab: 1,
    },
    {
      title: 'Component with the Highest Maintenance Spend',
      contentTitle: componentWithHighestCost?.componentWithHighestSpend,
      cost: componentWithHighestCost?.totalSpend,
      subContentTitle: `Asset with most spend on ${componentWithHighestCost.componentWithHighestSpend}`,
      subContent: componentWithHighestCost?.assetWithMostSpendOnComponent,
      redirectTab: 2,
    },
    {
      title: 'Most Frequently Replaced Part',
      contentTitle: mostReplacedPart?.mostFrequentPart,
      cost: mostReplacedPart?.totalSpendForPart,
      subContentTitle: `Asset with most spend on ${mostReplacedPart?.mostFrequentPart}`,
      subContent: mostReplacedPart?.assetWithMostSpendOnPart,
      redirectTab: 4,
    },
  ];
  return (
    <Box>
      {data.length > 0 ? (
        <Grid container spacing={2}>
          {stats.map((item, index) => {
            return (
              <Grid key={index} item lg={4} sm={6} md={6} xs={12}>
                <Box
                  sx={{
                    marginTop: '10px',
                    marginBottom: '20px',
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.toolBarBackground
                      : ThemePalette.light.toolBarBackground,
                    padding: '15px 10px 15px 10px',
                    borderRadius: '5px',
                    border: '1px solid #2F445D',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    switchToTab(item.redirectTab);
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '12px',
                      marginTop: '8px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      fontWeight: 'bolder',
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '24px',
                      marginTop: '10px',
                      marginBottom: '10px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                      fontWeight: 'bolder',
                    }}
                  >
                    {item.contentTitle}
                  </Typography>
                  <Box
                    sx={{
                      border: '1px solid #2F445D',
                      padding: '15px',
                      borderRadius: '5px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        marginBottom: '10px',
                        color: enableDarkTheme
                          ? '#B4B4B4'
                          : ThemePalette.typography.black,
                      }}
                    >
                      Total Spend
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: '20px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        fontWeight: 'bolder',
                      }}
                    >
                      ${formatNumberToShortScale(item.cost)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      border: '1px solid #2F445D',
                      padding: '15px',
                      borderRadius: '5px',
                      marginTop: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        marginBottom: '20px',
                        color: enableDarkTheme
                          ? '#B4B4B4'
                          : ThemePalette.typography.black,
                      }}
                    >
                      {item.subContentTitle}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: '20px',
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        fontWeight: 'bolder',
                      }}
                    >
                      {item.subContent}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Box
          sx={{
            marginTop: '10px',
            marginBottom: '20px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.toolBarBackground
              : ThemePalette.light.toolBarBackground,
            padding: '15px 10px 15px 10px',
            borderRadius: '5px',
            border: '1px solid #2F445D',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            No data available for selected time range
          </Typography>
        </Box>
      )}
    </Box>
  );
};
