import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@mui/material';
import NetworkStatus from './components/NetworkStatus';
import NetworkStrength from './components/NetworkStrength';
import AssetsConnected from './components/AssetsConnected';
import MeshGraph from './components/MeshGraph';
import MeshStatus from './components/MeshStatus';
import NetworkhealthHandler from 'handlers/network-health.handler';
import { useAppSelector } from 'store/hook';
import { SingleFadeLoader } from 'components/SingleFadeLoader';

// Define the shape of a mesh node including the new fields
interface MeshNode {
  hostname: string;
  mac: string;
  data_type: string; // "node", "connected_device", etc.
  'rssi[dBm]'?: number;
  'last_seen[s]'?: string;
}

// The fetched mesh data
type MeshData = Record<
  string,
  {
    nodes: MeshNode[];
    connected_devices: MeshNode[];
  }
>;

// The shape of each node passed into MeshStatus
interface MeshStatusNode {
  id: string; // unique identifier (e.g., mac)
  name: string; // display name (e.g., hostname)
  rssi: number; // numeric RSSI
}

const NetworkHealth: React.FC = () => {
  const networkHealthHandler = new NetworkhealthHandler();
  const customerCode = useAppSelector(
    (state) => state.persistedReducer.customer.code
  );

  const [meshData, setMeshData] = useState<MeshData | null>(null);
  const [nodeCount, setNodeCount] = useState<number>(0);
  const [networkStrength, setNetworkStrength] = useState<string>('Bad');
  const [fetchClicked, setFetchClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // New state for auto-refresh and refresh interval (in seconds)
  const [autoRefresh, setAutoRefresh] = useState<boolean>(false);
  const [refreshInterval, setRefreshInterval] = useState<number>(10);

  // This array is passed to MeshStatus
  const [meshStatusNodes, setMeshStatusNodes] = useState<MeshStatusNode[]>([]);

  // Calculate nodes present: all keys + unique hostnames in nodes and connected_devices.
  function calculateNodeCount(data: MeshData): number {
    const nodeSet = new Set<string>();
    Object.keys(data).forEach((key) => {
      nodeSet.add(key);
      data[key].nodes.forEach((node) => {
        nodeSet.add(node.hostname);
      });
      data[key].connected_devices.forEach((device) => {
        nodeSet.add(device.hostname);
      });
    });
    return nodeSet.size;
  }

  // Calculate average RSSI across all nodes and connected_devices.
  function calculateNetworkStrength(data: MeshData): string {
    let sum = 0;
    let count = 0;
    Object.values(data).forEach((group) => {
      group.nodes.forEach((node) => {
        if (
          node['rssi[dBm]'] !== undefined &&
          typeof node['rssi[dBm]'] === 'number'
        ) {
          sum += node['rssi[dBm]'];
          count++;
        }
      });
      group.connected_devices.forEach((device) => {
        if (
          device['rssi[dBm]'] !== undefined &&
          typeof device['rssi[dBm]'] === 'number'
        ) {
          sum += device['rssi[dBm]'];
          count++;
        }
      });
    });
    if (count === 0) {
      return 'Bad';
    }
    const avg = sum / count;
    if (avg <= 0 && avg >= -60) {
      return 'Excellent';
    } else if (avg < -60 && avg >= -80) {
      return 'Fair';
    } else {
      return 'Bad';
    }
  }

  /**
   * buildMeshStatusNodes:
   *   - Collect all nodes with data_type === "node".
   *   - Group them by `mac`.
   *   - For each group, compute the average RSSI.
   *   - Return a single entry per unique node.
   */
  function buildMeshStatusNodes(data: MeshData): MeshStatusNode[] {
    const nodeMap = new Map<string, { name: string; rssiValues: number[] }>();
    Object.values(data).forEach((group) => {
      group.nodes.forEach((node) => {
        if (
          node.data_type === 'node' &&
          typeof node['rssi[dBm]'] === 'number'
        ) {
          const macKey = node.mac;
          if (!nodeMap.has(macKey)) {
            nodeMap.set(macKey, { name: node.hostname, rssiValues: [] });
          }
          nodeMap.get(macKey)?.rssiValues.push(node['rssi[dBm]']);
        }
      });
    });
    const result: MeshStatusNode[] = [];
    nodeMap.forEach((value, macKey) => {
      const { name, rssiValues } = value;
      const sum = rssiValues.reduce((a, b) => a + b, 0);
      const avg = sum / rssiValues.length;
      result.push({ id: macKey, name, rssi: avg });
    });
    return result;
  }

  async function getNetworkHealthData(customerCode: string) {
    try {
      setIsLoading(true); // Start loading
      const fetchedData = await networkHealthHandler.getAll({ customerCode });
      setMeshData(fetchedData);

      const count = calculateNodeCount(fetchedData);
      setNodeCount(count);

      const strength = calculateNetworkStrength(fetchedData);
      setNetworkStrength(strength);

      const statusNodes = buildMeshStatusNodes(fetchedData);
      setMeshStatusNodes(statusNodes);
    } catch (error) {
      console.error('Error fetching network health data', error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  }

  useEffect(() => {
    if (fetchClicked) {
      void getNetworkHealthData(customerCode).then(() => {
        setFetchClicked(false);
      });
    }
  }, [fetchClicked, customerCode]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (autoRefresh) {
      void getNetworkHealthData(customerCode);
      intervalId = setInterval(() => {
        void getNetworkHealthData(customerCode);
      }, refreshInterval * 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoRefresh, refreshInterval, customerCode]);

  return (
    <Box
      sx={{
        backgroundColor: '#0A1929',
        color: 'white',
        minHeight: '100vh',
        padding: 3,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5" gutterBottom>
          Network Health
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControlLabel
            control={
              <Switch
                checked={autoRefresh}
                onChange={(e) => {
                  setAutoRefresh(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Auto Refresh"
          />
          {autoRefresh && (
            <FormControl sx={{ minWidth: 140 }} variant="outlined">
              <InputLabel
                sx={{
                  color: 'white', // White label text
                  backgroundColor: '#0A1929', // Match the background to avoid overlap
                  px: 1, // Add some padding to separate it from the border
                  '&.Mui-focused': {
                    color: '#90caf9', // Light blue on focus
                  },
                }}
              >
                Refresh Interval
              </InputLabel>
              <Select
                value={refreshInterval}
                onChange={(e) => {
                  setRefreshInterval(Number(e.target.value));
                  setTimeout(() => {
                    (document.activeElement as HTMLElement)?.blur(); // Correct blur handling
                  }, 0);
                }}
                displayEmpty
                sx={{
                  backgroundColor: '#112240', // Darker background
                  color: 'white', // White text
                  borderRadius: '6px', // Rounded corners
                  border: '1px solid #90caf9', // Light blue border
                  minWidth: '140px', // Ensures full text is visible
                  '& .MuiSvgIcon-root': {
                    color: 'white', // Change dropdown icon color
                  },
                  '&:hover': {
                    backgroundColor: '#0A1929', // Slightly lighter on hover
                  },
                }}
              >
                {[5, 10, 15, 30].map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}s
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button
            disabled={autoRefresh}
            variant="contained"
            color="primary"
            onClick={() => {
              setFetchClicked(true);
            }}
          >
            Fetch Data
          </Button>
        </Box>
      </Box>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <SingleFadeLoader />
        </Box>
      ) : meshData ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <NetworkStatus
              status={nodeCount === 0 ? 'Disconnected' : 'Connected'}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AssetsConnected count={nodeCount} />
          </Grid>
          <Grid item xs={12} md={4}>
            <NetworkStrength status={networkStrength} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ backgroundColor: '#112240', p: 2, color: 'white' }}>
              <Typography variant="h6" gutterBottom>
                Network Configuration
              </Typography>
              <MeshGraph apiResponse={meshData} />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <MeshStatus nodes={meshStatusNodes} />
          </Grid>
        </Grid>
      ) : (
        <Typography>Data not loaded yet.</Typography>
      )}
    </Box>
  );
};

export default NetworkHealth;
