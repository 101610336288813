import { Box, Typography } from '@mui/material';
import FuelAnalyticsHandler from 'handlers/fuelAnalytics.handler';
import useAppTheme from 'hooks/useAppTheme';
import { ThemePalette } from 'mui.theme';
import { useEffect, useMemo } from 'react';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';
import { truncateTo2dp } from 'utils/helpers/general';
import { formatNumberOrString } from 'utils/helpers/numbers';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { type fuelMeasureType } from 'views/FuelHistoricalAnalysis/types/fuelAnalytics.types';
import { fuelValueConversion } from 'views/FuelHistoricalAnalysis/utils';
import { getFuelUsageDataUnit } from 'views/FuelUsage/utils';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';

const TreeMapDiv = ({
  handleClicked,
  data,
  fuelMeasure,
  baseCost,
  selectedAssetType,
  isLoading,
  unitSystem,
  fuelUnit,
  sortBy,
}: {
  handleClicked: (device: string) => void;
  data: any;
  fuelMeasure: fuelMeasureType;
  baseCost: {
    diesel: number;
    gas: number;
  };
  selectedAssetType: string;
  isLoading: boolean;
  unitSystem: string;
  fuelUnit: 'ltr' | 'gal';
  sortBy?: 'low to high' | 'high to low';
}) => {
  const { enableDarkTheme } = useAppTheme();
  const fuelAnalyticsHandler = new FuelAnalyticsHandler();

  let totalSize = 0;
  const transformedData = data
    ?.map((item: any) => {
      let size = 0;
      switch (fuelMeasure) {
        case 'consumption':
          size = +fuelValueConversion(
            +item.total_fuel_consumption,
            unitSystem,
            fuelUnit
          ).toFixed(2);
          break;
        case 'co2_emission':
          size = +(+item.CO2).toFixed(2);
          break;
        case 'total_duration_of_idle_events':
          // converting seconds to hours
          size = +(+item.total_duration_of_idle_events).toFixed(2);
          break;
        case 'cost':
          size = +(
            fuelValueConversion(
              +item.total_fuel_consumption,
              unitSystem,
              fuelUnit,
              'cost'
            ) * baseCost[item.fuelSource.toLowerCase() as 'gas' | 'diesel']
          ).toFixed(2);
          break;
        case 'avg_fuel_rate':
        case 'loaded_fuel_consumption':
        case 'unloaded_fuel_consumption':
          size = +fuelValueConversion(
            +item[fuelMeasure],
            unitSystem,
            fuelUnit
          ).toFixed(2);
          break;
        default:
          size = item?.[fuelMeasure] ?? 0;
      }
      totalSize += size;
      if (
        selectedAssetType === 'haul_truck' &&
        item.asset_type === 'haul_truck'
      ) {
        return {
          name: item.bumper_number,
          size,
        };
      } else if (selectedAssetType === item.asset_type) {
        return {
          name: item.bumper_number,
          size,
        };
      }
      return {
        name: item.bumper_number,
        size,
      };
      // return null;
    })
    .filter(Boolean);

  const sortedData = useMemo(() => {
    const clone = structuredClone(transformedData);
    clone?.sort((a: any, b: any) => {
      if (sortBy === 'low to high') {
        return a.size - b.size;
      } else {
        return b.size - a.size;
      }
    });
    return clone;
  }, [transformedData]);

  const unit = useMemo(
    () => getFuelUsageDataUnit(fuelMeasure, unitSystem),
    [fuelMeasure, unitSystem]
  );

  return (
    <Box
      sx={{
        // padding: '30px 20px',
        border: '1px solid rgba(98, 98, 98, 0.2)',
        borderRadius: '4px',
        width: '100%',
        // height: '100%',
      }}
    >
      {!sortedData?.length && !isLoading ? (
        <Box
          sx={{
            padding: '50px',
            border: '1px solid rgba(98, 98, 98, 0.2)',
            borderRadius: '4px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          <Typography>No data available yet</Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              my: '5px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <Typography
              style={{
                fontSize: '13px',
                fontWeight: 'bold',
              }}
            >
              {/* change this to selected tiem frame */}
              {/* 17 Sept - 23 Oct */}
            </Typography>
            <Typography style={{ fontSize: '13px', fontWeight: 'bold' }}>
              <span
                style={{ fontWeight: 'normal', textTransform: 'capitalize' }}
              >
                {fuelMeasure.split('_').join(' ')}:
              </span>{' '}
              {formatNumberToShortScale(truncateTo2dp(totalSize || 0), 2)}{' '}
              {unit}
            </Typography>
          </Box>
          <ResponsiveContainer width="100%" height={230}>
            <Treemap
              data={sortedData}
              dataKey="size"
              aspectRatio={4 / 3}
              stroke="#000"
              fill="#4A7BFA"
              content={
                <CustomTreemapContent
                  handleClicked={handleClicked}
                  unit={unit ?? ''}
                  formatToShortScale
                />
              }
            >
              <Tooltip
                content={({ payload }) => {
                  if (payload && payload.length > 0) {
                    const { name, value } = payload[0].payload;
                    return (
                      <div
                        style={{
                          backgroundColor: '#fee',
                          padding: '6px',
                          border: '1px solid #ccc',
                          color: '#000',
                        }}
                      >
                        <p style={{ fontSize: 'smaller' }}>
                          Asset name: <b>{name}</b>
                        </p>
                        <p style={{ fontSize: 'smaller' }}>
                          {capitalizeFirstLetter(fuelMeasure)}:{' '}
                          <b>
                            {formatNumberToShortScale(value?.toString(), 2)}
                          </b>{' '}
                          {unit}
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </Treemap>
          </ResponsiveContainer>
        </>
      )}
    </Box>
  );
};

const CustomTreemapContent = ({
  depth = 0,
  x = 0,
  y = 0,
  width = 0,
  height = 0,
  name = '',
  size = '',
  handleClicked,
  unit = '',
  formatToShortScale = false,
}: {
  depth?: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  name?: string;
  size?: string;
  handleClicked: (device: string) => void;
  unit: string;
  formatToShortScale?: boolean;
}) => {
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="#000"
        strokeWidth={3}
        fill="#4A7BFA"
        onClick={() => {
          handleClicked(name);
        }}
        style={{ cursor: 'pointer' }}
      />
      {width > 50 && height > 20 && (
        <>
          <text
            x={x + width / 2}
            y={y + height / 4}
            textAnchor="middle"
            fill="#fff"
            stroke="none"
            style={{ fontSize: 14, fontWeight: 'bold' }}
          >
            {name}
          </text>

          <text
            x={x + width / 2}
            y={y + height / 2}
            textAnchor="middle"
            fill="#000"
            stroke="none"
            style={{ fontSize: 10, fontWeight: 'bold' }}
          >
            {formatToShortScale
              ? formatNumberToShortScale(size?.toString(), 2)
              : formatNumberOrString(size?.toString())}{' '}
            {unit}
          </text>
        </>
      )}
    </g>
  );
};

export default TreeMapDiv;
