import FuelAnalyticsAPI from 'api/fuelAnalytics.api';
import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import {
  setFuelAnalytics,
  clearFuelAnalytics,
  setFuelAnalyticsLoading,
  setSpecificFuelAnalyticsLoadingState,
  setFuelAnalyticsWRTDriverEvents,
  type FuelConsumptionWRTDriverEventsInterface,
  type FuelAnalyticsWRTDriverEventResponseInterface,
  setFleetSummary,
  setFleetDeviceDetails,
  setFleetDeviceDetailsPerDay,
  setFleetSummaryChart,
} from 'store/fuelAnalytics.slice';
import IdleAnalyticsAPI from 'api/idleAnalytics.api';
import { setIdleAnalytics } from 'store/idleAnalytics.slice';
import { type FuelAnalyticsWRTDriverEventsInterface } from '../interfaces/fuelAnalytics.interface';
import DriverBehaviourAPI from 'api/driver.behaviour.api';
import {
  type average_fuel_key_to_use,
  eventsForFuelAnalytics,
} from 'utils/fuelAnalytics';

export default class FuelAnalyticsHandler extends BaseHandler {
  private readonly api: FuelAnalyticsAPI;
  private readonly idleApi: IdleAnalyticsAPI;
  private readonly driverBehaviourApi: DriverBehaviourAPI;

  constructor() {
    super();

    this.api = new FuelAnalyticsAPI();
    this.idleApi = new IdleAnalyticsAPI();
    this.driverBehaviourApi = new DriverBehaviourAPI();
  }

  // Gets asset usage dashboard analysis
  async getFuelUsageAnalytics(
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    isHaulTruck: boolean = true,
    startDate: string,
    endDate: string,
    selectedConsumptionRange: string,
    assetTypeCategory: string
  ) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setFuelAnalyticsLoading(true));
    try {
      let response: any = {};
      const fuelType = isHaulTruck
        ? 'getFuelEfficiency'
        : 'getFuelConsumptionEngineHours';
      const fuelReducer = isHaulTruck
        ? 'fuelLoadedUnloadedAnalytics'
        : 'fuelConsumptionEngineHoursAnalytics';

      const [
        fuelEfficiencyDataResult,
        fuelIdleEventsDataResult,
        fuelNoneIdleEventsDataResult,
        fuelMonthOnMonthDataResult,
        historicalAnalysisCardDataResult,
      ] = await Promise.allSettled([
        (this.api as any)[fuelType](makeModels, startDate, endDate),
        this.api.getFuelIdleEvents(makeModels, startDate, endDate),
        this.api.getFuelNoneIdleEvents(makeModels, startDate, endDate),
        this.api.getMonthOnMonthData(makeModels, selectedConsumptionRange),
        this.api.getHistoricalAnalysisCardData(
          assetTypeCategory,
          startDate,
          endDate
        ),
      ]);

      const fuelEfficiencyData =
        fuelEfficiencyDataResult.status === 'fulfilled'
          ? fuelEfficiencyDataResult.value
          : null;
      const fuelIdleEventsData =
        fuelIdleEventsDataResult.status === 'fulfilled'
          ? fuelIdleEventsDataResult.value
          : null;
      const fuelNoneIdleEventsData =
        fuelNoneIdleEventsDataResult.status === 'fulfilled'
          ? fuelNoneIdleEventsDataResult.value
          : null;

      const fuelMonthOnMonthData =
        fuelMonthOnMonthDataResult.status === 'fulfilled'
          ? fuelMonthOnMonthDataResult.value
          : null;

      const historicalAnalysisCardData =
        historicalAnalysisCardDataResult.status === 'fulfilled'
          ? historicalAnalysisCardDataResult.value
          : null;

      response[fuelReducer] = fuelEfficiencyData;

      response = {
        ...response,
        fuelIdleEvents: fuelIdleEventsData,
        fuelNoneIdleEvents: fuelNoneIdleEventsData,
        fuelConsumptionRangeAnalytics: fuelMonthOnMonthData.data,
        historicalAnalysisCard: historicalAnalysisCardData,
      };

      this.dispatch(setFuelAnalytics(response));
      this.dispatch(setFuelAnalyticsLoading(false));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the fuel analytics data: ' + msg
      );
      this.dispatch(setFuelAnalyticsLoading(false));
    }
  }

  // Gets historical analysis data
  async getFuelUsageHistoricalAnalytics(
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string,
    selectedConsumptionRange: string,
    assetTypeCategory: string
  ) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setFuelAnalyticsLoading(true));
    try {
      let response: any = {};

      let fuelMonthOnMonthDataResult, fleetDeviceDetailsResult;

      if (selectedConsumptionRange === 'custom') {
        const [resp1, resp2] = await Promise.allSettled([
          this.api.getMonthOnMonthData(makeModels, 'day', startDate, endDate),
          this.api.getFleetFuelConsumptionPerDevice(
            startDate,
            endDate,
            '',
            '',
            true
          ),
        ]);

        fuelMonthOnMonthDataResult = resp1;
        fleetDeviceDetailsResult = resp2;
      } else {
        const [resp1, resp2] = await Promise.allSettled([
          this.api.getMonthOnMonthData(makeModels, selectedConsumptionRange),
          this.api.getFleetFuelConsumptionPerDevice(
            startDate,
            endDate,
            '',
            '',
            true
          ),
        ]);
        fuelMonthOnMonthDataResult = resp1;
        fleetDeviceDetailsResult = resp2;
      }

      const fuelMonthOnMonthData =
        fuelMonthOnMonthDataResult.status === 'fulfilled'
          ? fuelMonthOnMonthDataResult.value
          : null;

      const fleetDeviceDetailsData =
        fleetDeviceDetailsResult.status === 'fulfilled'
          ? fleetDeviceDetailsResult.value
          : null;

      response = {
        fuelConsumptionRangeAnalytics: fuelMonthOnMonthData.data,
        fleetDeviceDetails: fleetDeviceDetailsData,
      };

      this.dispatch(setFuelAnalytics(response));
      this.dispatch(setFuelAnalyticsLoading(false));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the fuel analytics data: ' + msg
      );
      this.dispatch(setFuelAnalyticsLoading(false));
    }
  }

  async getFleetFuelConsumptionPerDevice(
    startDate: string,
    endDate: string,
    shiftWiseAnalysis: boolean,
    shiftNumber: number,
    range?: string,
    aggregation?: string,
    shouldSendDate?: boolean
  ) {
    try {
      let shiftKey = '';
      if (shiftWiseAnalysis) {
        shiftKey = `_${shiftNumber}`;
      }
      const cacheKey = `fleet_fuel_consumption_cache_${range}_${startDate}_${endDate}_${shiftWiseAnalysis}${shiftKey}`;
      const cachedData = sessionStorage.getItem(cacheKey);

      if (cachedData && range !== 'Custom') {
        const data = JSON.parse(cachedData);
        this.dispatch(setFleetDeviceDetails(data?.fleetDeviceDetails));
        this.dispatch(
          setFleetDeviceDetailsPerDay(data?.fleetDeviceDetailsPerDay)
        );
        return data;
      }
      this.dispatch(
        setSpecificFuelAnalyticsLoadingState({
          isLoadingFuelTreemapData: true,
          isLoadingFuelConsumptionPerDayData: true,
        })
      );

      const promises = [];
      promises.push(
        this.api.getFleetFuelConsumptionPerDevice(
          startDate,
          endDate,
          range,
          aggregation,
          shouldSendDate,
          shiftWiseAnalysis,
          shiftNumber
        )
      );
      promises.push(
        this.api.getFleetFuelConsumptionPerDevicePerDay(
          startDate,
          endDate,
          range,
          aggregation,
          shouldSendDate,
          shiftWiseAnalysis,
          shiftNumber
        )
      );
      const response = await Promise.allSettled(promises);

      const result = [];

      for (let i = 0; i < response.length; i++) {
        const res = response[i];
        if (res.status === 'fulfilled') {
          result[i] = res.value;
        }
      }

      const cacheData = {
        fleetDeviceDetails: result[0],
        fleetDeviceDetailsPerDay: result[1] || [],
      };

      sessionStorage.setItem(cacheKey, JSON.stringify(cacheData));

      this.dispatch(setFleetDeviceDetails(result[0]));
      this.dispatch(setFleetDeviceDetailsPerDay(result[1] || []));
    } catch (error) {
      console.error(error);
    } finally {
      this.dispatch(
        setSpecificFuelAnalyticsLoadingState({
          isLoadingFuelTreemapData: false,
          isLoadingFuelConsumptionPerDayData: false,
        })
      );
    }
  }

  async getAirFilters(devices: string[]) {
    const airFilterResponse = await this.api.getAirFilters(devices);
    const response = {
      airFilterData: airFilterResponse.Items,
    };

    this.dispatch(setFuelAnalytics(response));
    this.handleSuccess();
  }

  catch(error: any) {
    let msg: string = '';
    if (error.isAxiosError) {
      msg = error.response.data.message as string;
    }

    this.handleError('An error occured while getting air filter data: ' + msg);
    this.dispatch(setFuelAnalyticsLoading(false));
  }

  clearFuelUsageAnalytics() {
    this.dispatch(clearFuelAnalytics());
  }

  async getIdleAnalytics(startDate: string, endDate: string) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setIdleAnalytics([]));
    try {
      const response = await this.idleApi.getIdleAnalytics(startDate, endDate);
      this.dispatch(setIdleAnalytics(response));
      this.handleSuccess();
      this.dispatch(updateLoadingState(false));
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the idle analytics data: ' + msg
      );
    }
  }

  /**
   * Retrieves fuel analytics with respect to driver events within a specified date range.
   * It dispatches actions to update loading states and stores the results in the state.
   *
   * @param params - An object containing parameters for the query, including:
   *  - startDate: The start date of the range to retrieve data for.
   *  - endDate: The end date of the range to retrieve data for.
   *
   * The method iterates over predefined events, fetching fuel consumption data for each event
   * within the date range, and aggregates the data by asset type. The results are stored in the Redux state.
   */
  async getFuelAnalyticsWRTDriverEvents(
    params: FuelAnalyticsWRTDriverEventsInterface
  ) {
    try {
      let shiftKey = '';
      if (params.shiftWiseAnalysis) {
        shiftKey = `_${params.shiftNumber}`;
      }
      const cacheKey = `fuel_analytics_wrt_driver_events_cache_${params.range}_${params?.startDate}_${params?.endDate}_${params.shiftWiseAnalysis}${shiftKey}`;
      const cachedData = sessionStorage.getItem(cacheKey);

      if (cachedData && params.range !== 'Custom') {
        const data = JSON.parse(cachedData);
        this.dispatch(setFuelAnalyticsWRTDriverEvents(data));
        this.dispatch(
          setSpecificFuelAnalyticsLoadingState({ isLoadingFleetSummary: false })
        );
        return data;
      }

      this.dispatch(
        setSpecificFuelAnalyticsLoadingState({
          loadingFuelAnalyticsWRTDriverEvents: true,
        })
      );
      const promises = [];
      for (const eventMeta of eventsForFuelAnalytics) {
        promises.push(
          this.api.getFuelConsumptionWithinDateRange(
            params.startDate,
            params.endDate,
            eventMeta.key,
            params.range,
            params.shouldSendDate,
            params.shiftWiseAnalysis,
            params.shiftNumber
          )
        );
      }

      const responses = await Promise.allSettled(promises);
      const eventWiseFuelAverages: FuelConsumptionWRTDriverEventsInterface[] =
        [];
      responses.forEach((response, index) => {
        if (response.status === 'fulfilled') {
          const value = response.value;
          const assetTypeWiseData = value?.Items?.reduce(
            (
              acc: Record<string, FuelAnalyticsWRTDriverEventResponseInterface>,
              el: FuelAnalyticsWRTDriverEventResponseInterface
            ) => {
              const assetType = el.asset_type_group;
              acc[assetType] = el;
              return acc;
            },
            {}
          );

          eventWiseFuelAverages.push({
            ...eventsForFuelAnalytics[index],
            data: assetTypeWiseData,
          });
        }
      });

      sessionStorage.setItem(cacheKey, JSON.stringify(eventWiseFuelAverages));
      this.dispatch(setFuelAnalyticsWRTDriverEvents(eventWiseFuelAverages));
    } catch (error) {
      console.error(error);
    } finally {
      this.dispatch(
        setSpecificFuelAnalyticsLoadingState({
          loadingFuelAnalyticsWRTDriverEvents: false,
        })
      );
    }
  }

  async getFuelAverageFromDriverEvent(params: {
    startDate: string;
    endDate: string;
    event: string;
    average_fuel_key_to_use: average_fuel_key_to_use;
  }) {
    try {
      interface IndividualDataPoint {
        start_ts: string;
        end_ts: string;
        avg_speed: number;
        average_fuel_rate: number;
      }
      interface ResponseInterface {
        bumper_id: string;
        device_id: string;
        asset_type: string;
        eventPercentage: number;
        noOfEvents: number;
        data: IndividualDataPoint[];
      }
      const response: ResponseInterface[] =
        await this.driverBehaviourApi.getAll(params);
      // const result: {
      //   event: string;
      //   avg_fuel_rate: number | null;
      // } = {
      //   event: params.event,
      //   avg_fuel_rate: null,
      // };
      type AssetTypes = 'haul_truck' | 'loader';
      interface IndividualBumperData {
        average_fuel_rate: number;
        totalDurationMillis: number;
      }
      const bumperAverages: Record<AssetTypes, number[]> = {
        haul_truck: [],
        loader: [],
      };
      for (const assetType of Object.keys(bumperAverages)) {
        const filteredData = response.filter(
          (item) => item.asset_type === assetType
        );
        // let totalDurationMillis = 0;
        for (const bumperData of filteredData) {
          const data = bumperData?.data;
          if (data) {
            const totalFuelRateForBumper = data.reduce(
              (acc: number, curr: IndividualDataPoint) => {
                return acc + curr?.[params?.average_fuel_key_to_use];
              },
              0
            );
            const averageFuelRateForBumper =
              totalFuelRateForBumper / data.length;
            bumperAverages[assetType as AssetTypes].push(
              averageFuelRateForBumper
            );
            // totalDurationMillis += data.reduce(
            //   (acc: number, curr: IndividualDataPoint) => {
            //     return (
            //       acc +
            //       Math.abs(
            //         new Date(curr.end_ts).getTime() -
            //         new Date(curr.start_ts).getTime()
            //       )
            //     );
            //   },
            //   0
            // );
          }
        }
      }
      const resultAvgFuelRate: Record<AssetTypes, number> = {
        haul_truck: 0,
        loader: 0,
      };
      for (const assetType of Object.keys(resultAvgFuelRate)) {
        if (bumperAverages[assetType as AssetTypes].length > 0) {
          resultAvgFuelRate[assetType as AssetTypes] =
            bumperAverages[assetType as AssetTypes].reduce((acc, curr) => {
              return acc + curr;
            }, 0) / bumperAverages[assetType as AssetTypes].length;
        }
      }
      return {
        event: params.event,
        ...resultAvgFuelRate,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async get(
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    initialRun: boolean,
    assetType: string,
    aggregation: string,
    shiftWiseAnalysis: boolean,
    shiftNumber: number,
    utilizationType: string
  ): Promise<any> {
    this.dispatch(
      setSpecificFuelAnalyticsLoadingState({
        isLoadingFleetSummary: true,
      })
    );
    let shiftKey = '';
    if (shiftWiseAnalysis) {
      shiftKey = `_${shiftNumber}`;
    }
    const cacheKey = `fuel_analytics_cache_${range}_${aggregation}_${shiftWiseAnalysis}${shiftKey}`;
    const cachedData = sessionStorage.getItem(cacheKey);

    // If cached data exists and range is not custom, parse it and return
    if (cachedData && range !== 'Custom') {
      const data = JSON.parse(cachedData);
      this.dispatch(setFleetSummary(data.fleetSummary));
      this.dispatch(setFleetSummaryChart(data.fleetSummary.summaryChart));
      // this.dispatch(setTotalFleetAssets(data.fleetAssets?.assets));
      if (initialRun) {
        // this.dispatch(setFleetDetails(data.fleetDetails));
        // this.dispatch(setFleetPeriodSummary(data.fleetPeriod));
      }
      this.dispatch(
        setSpecificFuelAnalyticsLoadingState({ isLoadingFleetSummary: false })
      );
      return data;
    }

    try {
      const fleetSummary = await this.api.getTotalFleetSummary(
        range,
        startDate,
        endDate,
        shouldSendDate,
        aggregation,
        shiftWiseAnalysis,
        shiftNumber,
        utilizationType
      );
      this.dispatch(setFleetSummary(fleetSummary));
      this.dispatch(setFleetSummaryChart(fleetSummary.summaryChart));

      // const fleetAssets = await fleetAssetsPromise;
      // this.dispatch(setTotalFleetAssets(fleetAssets?.assets));

      // Store the results in session storage
      const dataToCache = {
        fleetSummary,
        // fleetAssets,
        // fleetDetails: initialRun ? fleetDetailsPromise : null,
        // fleetPeriod: initialRun ? fleetPeriodPromise : null,
      };

      try {
        sessionStorage.setItem(cacheKey, JSON.stringify(dataToCache));
      } catch (e) {
        console.log('Error setting cache for fuel analytics', e);
      }

      return dataToCache; // return the cached data
    } catch (_) {
      this.handleError('An error occurred while fetching utilization details');
    } finally {
      this.dispatch(
        setSpecificFuelAnalyticsLoadingState({
          isLoadingFleetSummary: false,
        })
      );
    }
    return {};
  }
}
