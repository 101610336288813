import { Box, Typography, Chip, Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ThemePalette } from 'mui.theme';
import { truncateTo2dp } from 'utils/helpers/general';

export const AssetFuelMetric = ({
  enableDarkTheme,
  data,
}: {
  enableDarkTheme: boolean;
  data: any;
}) => {
  const assetFuelEfficiency = data?.device[0]?.fuel_rate || 'N/A';
  const fleetFuelEfficiency = data?.fleet[0]?.fuel_rate || 'N/A';

  return (
    <Box
      sx={{
        marginTop: '20px',
        padding: '10px',
        border: '1px solid #2F445E',
        borderRadius: '4px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        minHeight: '170px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontWeight: '700',
          }}
        >
          Fuel Efficiency
        </Typography>
        <Chip
          icon={<CheckCircleIcon style={{ fill: '#FFFFFF' }} />}
          sx={{
            padding: '15px 20px 15px 20px',
            borderRadius: '8px',
            backgroundColor: '#039161',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          label="OK"
          size="small"
        />
      </Box>
      <Grid container spacing={2}>
        {/* <Grid item lg={6}>
          <Box>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              Ltrs / km
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                marginTop: '10px',
                fontSize: '24px',
                fontWeight: '700',
                marginBottom: '10px',
              }}
            >
              1.2
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
              }}
            >
              Asset: 0.8
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
              }}
            >
              Fleet: 1.2
            </Typography>
          </Box>
        </Grid> */}
        <Grid item lg={6}>
          <Box>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              Ltrs / hour
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                marginTop: '10px',
                fontSize: '24px',
                fontWeight: '700',
                marginBottom: '10px',
              }}
            >
              {truncateTo2dp(assetFuelEfficiency)}
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
              }}
            >
              Asset: {truncateTo2dp(assetFuelEfficiency)}
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
              }}
            >
              Fleet: {truncateTo2dp(fleetFuelEfficiency)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
