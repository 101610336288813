import React, { useEffect, useRef, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Sector,
} from 'recharts';
import { pastelColorMap, generateRandomColor } from 'utils/helpers/general';
import { handleFocusRemoveSelectOutline } from '../../Assets/Helper/pieChart.Helper';
import { ThemePalette } from 'mui.theme';
import { Typography } from '@mui/material';

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = Number(cx) + (Number(outerRadius) + 10) * cos;
  const sy = Number(cy) + (Number(outerRadius) + 10) * sin;
  const mx = Number(cx) + (Number(outerRadius) + 30) * cos;
  const my = Number(cy) + (Number(outerRadius) + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={Number(outerRadius) + 6}
        outerRadius={Number(outerRadius) + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-45}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={10}
      >{`Name:`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-25}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={12}
      >{`${name}`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-5}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={10}
      >{`Value:`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        dy={15}
        fill={fill}
        fontSize={12}
      >{`${(+value).toFixed(2)}`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={35}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={10}
      >
        {`Rate`}
      </text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={55}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={12}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

const CustomTooltip: React.FC<{
  active?: boolean;
  payload?: any;
  label?: string;
  enableDarkTheme: boolean;
  valueLabel?: string;
  customLabel?: string;
}> = ({
  active,
  payload,
  label = 'Asset Name',
  customLabel = 'Asset Name',
  valueLabel = 'Total Asset Cost',
  enableDarkTheme = false,
}) => {
  if (active && payload && payload.length) {
    const hoverCell = payload[0].payload;

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {customLabel || label}: {hoverCell.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {valueLabel}: {(+hoverCell.total_asset_cost)?.toFixed(2)}
        </Typography>
      </div>
    );
  }

  return null;
};

export const ComponentsPieChart = ({
  data,
  handlePieClick,
  enableDarkTheme = false,
  onlyOneActive = false,
  label,
  valueLabel,
  dataKey = 'total_asset_cost',
}: {
  data: Array<Record<string, any>>;
  handlePieClick: any;
  enableDarkTheme: boolean;
  onlyOneActive?: boolean;
  label?: string;
  valueLabel?: string;
  dataKey?: string;
}) => {
  const [activeIndices, setActiveIndices] = useState<Record<number, boolean>>(
    {}
  );
  const prevDataRef = useRef<Array<Record<string, any>>>([]);

  useEffect(() => {
    const hasDataChanged = !isEqual(prevDataRef.current, data);

    if (hasDataChanged) {
      // setActiveIndices({
      //   0: true,
      // });
      let maxIndex = 0;
      const maxDataObject = data.reduce((prev, curr, index) => {
        if (curr[dataKey] > prev[dataKey]) {
          maxIndex = index;
          return curr;
        }
        // maxIndex = index;
        return prev;
      }, data[0]);
      handlePieClick(maxDataObject, true);
      setActiveIndices({
        [maxIndex]: true,
      });
    }
    // Update prevDataRef with current data for next comparison
    prevDataRef.current = data;
  }, [data]); // Reset when 'data' prop changes

  const onPieClick = (data: any, index: number) => {
    if (onlyOneActive) {
      setActiveIndices((prevState) => {
        const keys = Object.keys(prevState);
        for (const key of keys) {
          if (+key !== index) {
            prevState[+key] = false;
          }
        }
        return {
          ...prevState,
          [index]: !prevState[index], // Toggle the active state for the clicked segment
        };
      });
    } else {
      setActiveIndices((prevState) => ({
        ...prevState,
        [index]: !prevState[index], // Toggle the active state for the clicked segment
      }));
    }
    handlePieClick(data);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <div tabIndex={0} onFocus={handleFocusRemoveSelectOutline}>
        <PieChart width={470} height={370}>
          <Pie
            dataKey="total_asset_cost"
            isAnimationActive={true}
            data={data}
            cx="45%"
            cy="40%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            onClick={onPieClick}
            labelLine={false}
            activeIndex={Object.keys(activeIndices)
              .map(Number)
              .filter((i) => activeIndices[i])} // Pass active indices
            activeShape={renderActiveShape}
          >
            {data.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} fill={getColourByIndex(index)} />
            ))}
          </Pie>
          {/* <Legend /> */}
          <Tooltip
            content={
              <CustomTooltip
                enableDarkTheme={enableDarkTheme}
                customLabel={label}
                valueLabel={valueLabel}
              />
            }
          />
        </PieChart>
      </div>
    </ResponsiveContainer>
  );
};

function getColourByIndex(index: number) {
  return pastelColorMap[index] || generateRandomColor();
}

const isEqual = (
  arr1: Array<Record<string, any>>,
  arr2: Array<Record<string, any>>
): boolean => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false;
    }
  }
  return true;
};
