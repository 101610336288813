import { Button, ButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import KPIsTab from './KPIsTab';
import GraphsTab from './GraphsTab';
import { type HealthScore } from 'types/squares.type';
import {
  type TotalCostInterface,
  type KPIHeaderValuePair,
  type MaintenanceDataInterface,
} from '../Interfaces/KPI.interface';

interface KPIsGraphsProps {
  assetsItems: HealthScore[];
  setShowView: (view: string) => void;
  maintenanceData: MaintenanceDataInterface;
  totalCostSaved: KPIHeaderValuePair[];
  totalCosts: TotalCostInterface;
  totalDowntimeSaved: KPIHeaderValuePair[];
  totalProductivityImproved: KPIHeaderValuePair[];
  totaltonnage: KPIHeaderValuePair[];
  totaltonnageValue: number;
}

const KPIsGraphs = ({
  assetsItems,
  setShowView,
  maintenanceData,
  totalCostSaved,
  totalCosts,
  totalDowntimeSaved,
  totalProductivityImproved,
  totaltonnage,
  totaltonnageValue,
}: KPIsGraphsProps) => {
  const [activeTab, setActiveTab] = useState('KPIs');
  const handleButtonClick = (tab: string) => {
    setActiveTab(tab);
  };
  const handleKpiCardClick = (cardType: string) => {
    if (
      cardType === 'assetsView' ||
      cardType === 'totalMaintenanceCost' ||
      cardType === 'partsReplacement' ||
      cardType === 'workOrderCount' ||
      cardType === 'maintenanceHours'
    ) {
      setShowView(cardType); // Set the view based on the card clicked
    }
  };
  return (
    <div>
      <ButtonGroup
        color="secondary"
        aria-label="Medium-sized button group"
        fullWidth
      >
        <Button
          key="KPIs"
          onClick={() => {
            handleButtonClick('KPIs');
          }}
          sx={{
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: activeTab === 'KPIs' ? '2px solid #1976d2' : 'none',
            color: activeTab === 'KPIs' ? '#1976d2' : 'grey',
          }}
        >
          KPIs
        </Button>
        <Button
          key="Graphs"
          onClick={() => {
            handleButtonClick('Graphs');
          }}
          sx={{
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: activeTab === 'Graphs' ? '2px solid #1976d2' : 'none',
            color: activeTab === 'Graphs' ? '#1976d2' : 'grey',
          }}
        >
          Graphs
        </Button>
      </ButtonGroup>
      {activeTab === 'KPIs' && (
        <KPIsTab
          assetsItems={assetsItems}
          handleKpiCardClick={handleKpiCardClick}
          maintenanceData={maintenanceData}
          totalCostSaved={totalCostSaved}
          totalCosts={totalCosts}
          totalDowntimeSaved={totalDowntimeSaved}
          totalProductivityImproved={totalProductivityImproved}
          totaltonnage={totaltonnage}
          totaltonnageValue={totaltonnageValue}
        />
      )}
      {activeTab === 'Graphs' && (
        <GraphsTab
          maintenanceData={maintenanceData}
          totalCosts={totalCosts}
          totaltonnage={totaltonnage}
        />
      )}
    </div>
  );
};

export default KPIsGraphs;
