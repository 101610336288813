import { Box, Typography, ButtonGroup, Button } from '@mui/material';
import { addHours } from 'date-fns';
import DatePicker from 'react-datepicker';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import dayjs from 'dayjs';
import { ThemePalette } from 'mui.theme';

export const DateSelector = ({
  enableDarkTheme,
  isLoading,
  isSelected,
  setIsSelected,
  startDateTime,
  endDateTime,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  openEndDate,
  setOpenEndDate,
  setExecuteCall,
}: {
  enableDarkTheme: boolean;
  isLoading: boolean;
  isSelected: string;
  setIsSelected: any;
  startDateTime: any;
  endDateTime: any;
  handleStartDateTimeChange: any;
  handleEndDateTimeChange: any;
  openEndDate: boolean;
  setOpenEndDate: any;
  setExecuteCall: any;
}) => {
  const events = [
    {
      title: 'Incorrect Gear',
      color: '#EC4899',
    },
    {
      title: 'Overspeeding',
      color: '#EB5757',
    },
    {
      title: 'Abrupt Braking',
      color: '#FFA403',
    },
    {
      title: 'Acceleration',
      color: '#00E396',
    },
    {
      title: 'Incorrect Gear Shift',
      color: '#325C9F',
    },
    {
      title: 'Gear Skip',
      color: '#777777',
    },
  ];

  const getEndDateTime = (start: any) => {
    return addHours(start, 23);
  };

  const handleFocus = () => {
    setOpenEndDate(true);
  };

  const maxEndTime = getEndDateTime(startDateTime);

  return (
    <Box
      sx={{
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <>
          <Typography
            style={{
              fontSize: '12px',
              marginRight: '10px',
              paddingTop: '5px',
              marginLeft: '10px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          ></Typography>
          <>
            <label
              htmlFor="start-datetime"
              style={{
                fontSize: '12px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                paddingRight: '5px',
                marginTop: '6px',
              }}
            >
              From:
            </label>{' '}
            <DatePicker
              id="start-datetime"
              selected={startDateTime}
              onChange={handleStartDateTimeChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              // maxTime={
              //   dayjs(startDateTime).isSame(dayjs(), 'day')
              //     ? dayjs().toDate()
              //     : dayjs().endOf('day').toDate()
              // }
              // minTime={dayjs().startOf('day').toDate()}
              maxDate={dayjs().toDate()}
              minDate={dayjs('2000-01-01').toDate()}
              dateFormat="yyyy-MM-dd HH:mm"
              className={
                enableDarkTheme
                  ? 'custom-datetimepicker-prod'
                  : 'custom-datetimepicker-prod-light-theme'
              }
              onCalendarClose={() => {
                setOpenEndDate(true);
              }}
            />{' '}
            <label
              htmlFor="end-datetime"
              style={{
                fontSize: '12px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                paddingRight: '5px',
                paddingLeft: '5px',
                marginTop: '6px',
              }}
            >
              To:
            </label>{' '}
            <DatePicker
              id="end-datetime"
              selected={endDateTime}
              onChange={handleEndDateTimeChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={5}
              dateFormat="yyyy-MM-dd HH:mm"
              minDate={startDateTime}
              maxDate={maxEndTime}
              minTime={startDateTime}
              maxTime={maxEndTime}
              className={
                enableDarkTheme
                  ? 'custom-datetimepicker-prod'
                  : 'custom-datetimepicker-prod-light-theme'
              }
              open={openEndDate}
              onClickOutside={() => setOpenEndDate(false)}
              onFocus={handleFocus}
            />
          </>
        </>
      </Box>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {events.map((item: any, index: number) => {
          return (
            <Typography
              key={index}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
                display: 'flex',
                marginRight: '20px',
              }}
            >
              <PanoramaFishEyeIcon
                sx={{
                  color: item.color,
                  paddingRight: '2px',
                }}
                fontSize="small"
              />
              <span
                style={{
                  paddingTop: '1px',
                  fontSize: '10px',
                }}
              >
                {item.title}
              </span>
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};
