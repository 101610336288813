import { Box, LinearProgress, Typography } from '@mui/material';
import useAppTheme from 'hooks/useAppTheme';
import useCustomerInfo from 'hooks/useCustomerInfo';
import { ThemePalette } from 'mui.theme';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'store/hook';
import TreeMapDiv from 'views/FuelHistoricalAnalysis/Components/BodyDiv/TreeMapDiv';
import ChartDataToggler from 'views/FuelHistoricalAnalysis/Components/ChartDataToggler';
import { type fuelMeasureType } from 'views/FuelHistoricalAnalysis/types/fuelAnalytics.types';
import SortBy from './SortBy';
import FuelAssetAnalyticsSection from './FuelAssetAnalyticsSection';

interface Props {
  fuelMeasure?: fuelMeasureType;
  assetTypeKey?: 'fleet' | 'hauler' | 'loader';
}

const DetailedAnalysis = ({ assetTypeKey }: Props) => {
  const { enableDarkTheme } = useAppTheme();
  const { unitSystem } = useCustomerInfo();

  const {
    fleetDeviceDetails,
    loadingStates: { isLoadingFuelTreemapData },
  } = useAppSelector((state) => state.fuelAnalyticsReducer);

  const assetType = useMemo(() => {
    if (assetTypeKey === 'fleet' || assetTypeKey === 'loader')
      return assetTypeKey;
    else if (assetTypeKey === 'hauler') return 'haul_truck';
    return null;
  }, [assetTypeKey]);

  const selectedAssetTypTreemapDetails = useMemo(() => {
    if (assetType === 'fleet') {
      return fleetDeviceDetails;
    } else {
      return fleetDeviceDetails.filter((item) => item.asset_type === assetType);
    }
  }, [assetType, fleetDeviceDetails]);

  const [fuelMeasure, setFuelMeasure] =
    useState<fuelMeasureType>('consumption');

  const handleFuelMeasure = (value: fuelMeasureType) => {
    if (value) {
      setFuelMeasure(value);
    }
  };

  const [sortBy, setSortBy] = useState<string>('high to low');

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '13px',
              fontWeight: '600',
              paddingTop: '5px',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          >
            Detailed Analysis
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ChartDataToggler
            selectedDataKey={fuelMeasure}
            handleChange={handleFuelMeasure}
            hiddenDropDownItems={
              assetType === 'fleet' || assetType === 'loader'
                ? ['loaded_fuel_consumption', 'unloaded_fuel_consumption']
                : []
            }
          />
          <SortBy
            setSelected={setSortBy}
            options={['high to low', 'low to high']}
            selected={sortBy}
          />
        </Box>
      </Box>
      {isLoadingFuelTreemapData ? (
        <LinearProgress
          color="secondary"
          sx={{
            marginTop: '65px',
          }}
        />
      ) : (
        <>
          <Box
            data-attribute="assetItemBox"
            sx={{
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.boxBackground
                : ThemePalette.light.boxBackground,
              padding: '5px',
            }}
          >
            <TreeMapDiv
              baseCost={{
                diesel: 0,
                gas: 0,
              }}
              sortBy={sortBy as 'high to low' | 'low to high'}
              data={selectedAssetTypTreemapDetails}
              fuelMeasure={fuelMeasure}
              fuelUnit={unitSystem === 'imperial' ? 'gal' : 'ltr'}
              handleClicked={() => {}}
              isLoading={isLoadingFuelTreemapData}
              selectedAssetType={
                assetTypeKey === 'fleet' ? 'haul_truck' : 'loader'
              }
              unitSystem={unitSystem}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              marginTop: '16px',
            }}
          >
            {(assetType === 'fleet' || assetType === 'haul_truck') && (
              <FuelAssetAnalyticsSection
                assetType={'haul_truck'}
                title="Haulers"
              />
            )}
            {(assetType === 'fleet' || assetType === 'loader') && (
              <FuelAssetAnalyticsSection
                assetType={'loader'}
                title="Loaders"
                hiddenDropDownItems={[
                  'loaded_fuel_consumption',
                  'unloaded_fuel_consumption',
                ]}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default DetailedAnalysis;
