import { Box } from '@mui/material';
import { ChartView } from './ChartView';
import { ListView } from './ListView';

interface Props {
  metricsType: string;
  enableDarkTheme: boolean;
  assets: any;
  assetsComparison: {
    score?: any;
    metrics: any;
  };
  itemLoading?: boolean;
  showChart?: boolean;
  tagMetrics?: any;
  thresholds: any[];
  defaultThresholds: any[];
}

export const ComparisonMetrics = ({
  metricsType,
  enableDarkTheme,
  assets,
  assetsComparison,
  itemLoading,
  showChart = true,
  tagMetrics,
  thresholds,
  defaultThresholds,
}: Props) => {
  return (
    <Box>
      {metricsType === 'table' ? (
        <ListView
          enableDarkTheme={enableDarkTheme}
          assets={assets}
          metrics={assetsComparison.metrics}
          itemLoading={itemLoading}
          thresholds={thresholds}
          defaultThresholds={defaultThresholds}
        />
      ) : (
        showChart && (
          <ChartView
            enableDarkTheme={enableDarkTheme}
            assets={assets}
            metrics={{ ...assetsComparison.metrics, ...tagMetrics }}
          />
        )
      )}
    </Box>
  );
};
