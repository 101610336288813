import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';
import SquareServiceTimeline from '../Helpers/Squares.timeLineFilter'; // Ensure this path is correct
import { type HealthScore } from 'types/squares.type';
import useAppTheme from 'hooks/useAppTheme';

interface HeaderProps {
  allAssets: HealthScore[];
  handleIsWorkOrder: (check: boolean) => void;
  isUploadWorkOrder: boolean;
  onMonthSelect: (selectedMonth: string | null) => void;
}

const Header: React.FC<HeaderProps> = ({
  allAssets,
  handleIsWorkOrder,
  isUploadWorkOrder,
  onMonthSelect,
}) => {
  const { enableDarkTheme } = useAppTheme();

  // State to track the selected month from the timeline
  const [, setSelectedMonth] = useState<string | null>('All'); // Set 'All' as default

  // Function to handle month selection from the timeline
  const handleMonthSelect = (month: string | null) => {
    setSelectedMonth(month);
    onMonthSelect(month); // Pass the selected month to the parent component
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
        }}
      >
        <Typography
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          Upcoming Maintenance Plan
        </Typography>
        <Button
          size="small"
          variant="outlined"
          style={{
            padding: '10px',
            fontSize: '0.7rem',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            background: enableDarkTheme
              ? '#3666EC'
              : ThemePalette.typography.info,
            border: '1px solid #3666EC',
          }}
          onClick={() => {
            handleIsWorkOrder(!isUploadWorkOrder);
          }}
        >
          {isUploadWorkOrder ? 'Return to Dashboard' : 'Upload Work Order'}
        </Button>
      </Box>

      {/* Show the timeline only if not in upload work order mode */}
      {!isUploadWorkOrder && (
        <SquareServiceTimeline
          services={allAssets}
          enableDarkTheme={enableDarkTheme}
          onMonthSelect={handleMonthSelect} // Pass the handleMonthSelect function to the timeline
        />
      )}
    </Box>
  );
};

export default Header;
