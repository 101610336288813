import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  type SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';

/*  change this to use Selector from   import { Selector } from 'components/Form/Selectors/Selector'; */

interface Props {
  view: string;
  exportToLabel: string;
  exportTo: string;
  onExportToChange: (event: SelectChangeEvent) => void;
  itemArr?: string[];
  enableDarkTheme?: boolean;
  showComponent?: boolean;
  isPrinting?: boolean;
}

const ExportSelector = ({
  view,
  exportToLabel,
  exportTo,
  onExportToChange,
  itemArr = ['excel', 'pdf'],
  enableDarkTheme,
  showComponent = true,
  isPrinting,
}: Props) => {
  return (
    <FormControl
      sx={{
        minWidth: 150,
        margin: view === 'list' ? '0 0 0 35px' : '0 0 0 75px',
        padding: '-5px 0',
      }}
      size="small"
      disabled={!showComponent}
    >
      {isPrinting ? (
        <Typography color="white">Exporting...</Typography>
      ) : (
        <>
          <InputLabel
            id="demo-simple-select-label"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
              '&.Mui-focused': {
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
              },
            }}
          >
            {exportToLabel}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={exportTo}
            label="Export To"
            onChange={onExportToChange}
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
              ...(enableDarkTheme && {
                '& .MuiSvgIcon-root': {
                  color: 'purple', // <------------------ arrow-svg-color
                },
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: ThemePalette.border, // <------------------ outline-color by default
                  },
                  '&:hover fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions, // Change background color here
                },
              },
            }}
          >
            {!['card', 'map'].includes(view) ? (
              itemArr.map((item) => (
                <MenuItem
                  value={item}
                  key={item}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.black,
                  }}
                >
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </MenuItem>
              ))
            ) : (
              <MenuItem
                value={'pdf'}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.lightGrey
                    : ThemePalette.typography.black,
                }}
              >
                Pdf
              </MenuItem>
            )}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default ExportSelector;
