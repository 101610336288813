export type average_fuel_key_to_use = 'avg_speed' | 'average_fuel_rate';
interface EventsForFuelAnalyticsInterface {
  key: string;
  name: string;
  average_fuel_key_to_use: average_fuel_key_to_use;
  value_present?: boolean;
}
export const eventsForFuelAnalytics: EventsForFuelAnalyticsInterface[] = [
  {
    key: 'overspeed',
    name: 'overspeeding',
    average_fuel_key_to_use: 'average_fuel_rate',
    value_present: true,
  },
  {
    key: 'underspeed',
    name: 'underspeeding',
    average_fuel_key_to_use: 'average_fuel_rate',
    value_present: true,
  },
  {
    key: 'incorrect_gear',
    name: 'incorrect gear:speed ratio',
    average_fuel_key_to_use: 'average_fuel_rate',
    value_present: true,
  },
  {
    key: 'short_idling',
    name: 'Idling (lesser than 6 minutes)',
    average_fuel_key_to_use: 'average_fuel_rate',
    value_present: true,
  },
  {
    key: 'long_idling',
    name: 'Idling (greater than 6 minutes).',
    average_fuel_key_to_use: 'average_fuel_rate',
    value_present: true,
  },
  {
    key: 'pre_dumping',
    name: 'Queuing - Pre Dumping (shows actual fuel used).',
    average_fuel_key_to_use: 'average_fuel_rate',
    value_present: false,
  },
  {
    key: 'pre_loading',
    name: 'Queuing - Post Loading (shows actual fuel used).',
    average_fuel_key_to_use: 'average_fuel_rate',
    value_present: false,
  },
];
