import { useState, useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { type SelectChangeEvent } from '@mui/material';
import { addHours } from 'date-fns';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { Header } from './SensorAnalysisView/Header/Index';
import { AVG_VAL } from 'utils/enums';
import { type Asset } from 'types/asset.types';
import type { IGetSingleDetailTagPayload } from 'types/payloads/tag.payload.types';
import { type Device } from 'types/device.types';
import TagHandler from 'handlers/tag.handler';
import { getAssetMakeModel } from 'views/FleetOverview/components/AssetCardsV2/utils';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';
import { Content } from './SensorAnalysisView/Content/Index';
import { useCurrentDateRange } from 'hooks/dateRange';
import { SelectTagModal } from './SensorAnalysisView/modal/TagSelectorModal';
import { useCustomDropdownEffect } from 'utils/exports/hooks';

export const SensorAnalysisView2 = () => {
  const location = useLocation();
  const tagHandler = new TagHandler();
  const makeModelReceived = location.state?.makemodel;
  const assetReducer = useAppSelector((state) => state.assetReducer);
  const { assets, assetsLoaded } = assetReducer;
  const { latestTagData } = useAppSelector((state) => state.deviceReducer);
  const hierarchyInfo = useAppSelector((state) => state.hierarchyReducer);
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const { tagChartData, tagPayload, tagGrainData, isLoadingData } =
    useAppSelector((state) => state.tagReducer);
  const makeModels = getAssetMakeModel(assets);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const [selectedOperation, setSelectedOperation] = useState<string>(
    AVG_VAL[0].id
  );
  const [activeButton, setActiveButton] = useState<string>('7D');
  const [dataType, setDataType] = useState<string>('regular');
  const [chartType, setChartType] = useState<string>('Individual');
  const [assetBumperIds, setAssetBumperIds] = useState<string[]>([]);

  const [selectedMakeModel, setSelectedMakeModel] = useState(() => {
    if (
      makeModelReceived?.make &&
      makeModelReceived?.model &&
      makeModelReceived?.bumperNumber
    ) {
      return `${makeModelReceived.make.toLowerCase()}-${makeModelReceived.model.toLowerCase()}`;
    } else if (makeModels.length > 0) {
      return makeModels[0];
    } else {
      return '';
    }
  });

  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<Asset>({
    assetId: '',
    assetVin: '',
    assetType: 'haul_truck',
    make: '',
    bumperNumber: '',
    engineHoursOffset: -1,
    device: {} as const as Device,
    isActive: false,
    model: '',
    odometerOffset: -1,
    fuelSource: '',
    operators: [],
    externalId: '',
    assetNotes: [],
  });
  const [isSelectTagModalOpen, setIsSelectTagModalOpen] = useState(false);
  const prevSelectedTagsLengthRef = useRef<number>(0);
  const prevActiveButtonRef = useRef<string | null>(null);
  const prevSelectedOperationRef = useRef<string | null>(null);
  const prevEndDateTimeRef = useRef<Date | null>(null);
  const prevSelectedDeviceIdRef = useRef<string | null>(null);
  const [executeGrainCall, setExecuteGrainCall] = useState<boolean>(false);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);

  const [startDateTime, setStartDateTime] = useState(
    new Date(new Date().getTime() - 240 * 60000)
  );
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [exportDropdownTo, setExportDropdownTo] = useState('');
  const [exportToLabel, setExportToLabel] = useState('Export To');

  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportDropdownTo(event.target.value);
  };
  const [exportTo, setExportTo] = useState(false);
  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };

  const handleStartDateTimeChange = (date: any) => {
    setExecuteGrainCall(false);
    setStartDateTime(date);
    setEndDateTime(getEndDateTime(date));
  };

  const handleEndDateTimeChange = (date: any) => {
    setExecuteGrainCall(true);
    setEndDateTime(date);
    setOpenEndDate(false);
  };

  const getEndDateTime = (start: any) => {
    return addHours(start, 4);
  };

  const { startDate, endDate, setStartDate, setEndDate, today } =
    useCurrentDateRange({});

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const defaultAssetVin: string | null = new URLSearchParams(
    location.search
  ).get('assetVin');

  function handleSelectAsset(bumperNumber: string) {
    const item = assets.find((asset) => asset.bumperNumber === bumperNumber);
    if (item) {
      setSelectedAsset(item);
    }
  }

  const removeTag = (tagName: string) => {
    let updatedTags: string[] = [...selectedTags];
    const tagExists = selectedTags.find((item) => item === tagName);
    if (tagExists) {
      updatedTags = selectedTags.filter((item) => item !== tagName);
    } else {
      updatedTags = [...selectedTags, tagName];
    }
    setSelectedTags(updatedTags);
  };

  useEffect(() => {
    const newValue = dataType === 'regular' ? '7D' : '1h';
    setActiveButton(newValue);
  }, [dataType]);

  useEffect(() => {
    const filteredAssets = assets
      .filter(
        (asset) =>
          composeUniqueKey([asset.make, asset.model]) ===
          selectedMakeModel.replace('-', '!')
      )
      .map((item) => {
        return item.bumperNumber;
      });
    setAssetBumperIds(filteredAssets);
    handleSelectAsset(filteredAssets[0]);
  }, [selectedMakeModel]);

  useEffect(() => {
    if (defaultAssetVin) {
      const defaultAsset = assets.find(
        (item) => item.assetVin === defaultAssetVin
      );
      if (defaultAsset) {
        const assetMakeModel = composeUniqueKey(
          [defaultAsset.make, defaultAsset.model],
          '-'
        );
        setSelectedAsset(defaultAsset);
        setSelectedMakeModel(assetMakeModel);
      }
    }
  }, []);

  useCustomDropdownEffect(
    exportDropdownTo,
    handleExportClick,
    setExportToLabel,
    setExportDropdownTo,
    [exportDropdownTo]
  );

  const getTagDetails = async (
    deviceId: string,
    payload: IGetSingleDetailTagPayload,
    tagPayload: Record<string, any>,
    dataType: string,
    startDate: Date,
    endDate: Date,
    grainStartDate: Date,
    grainEndDate: Date
  ) => {
    if (dataType === 'highGrain') {
      const updatedPayload = { ...payload, timeFrame: '7D' };
      await Promise.all([
        tagHandler.getTagGrainDetails(
          deviceId,
          payload,
          grainStartDate,
          grainEndDate
        ),
        tagHandler.getTagDetail(
          deviceId,
          updatedPayload,
          tagPayload,
          tagChartData
        ),
      ]);
    } else {
      await tagHandler.getTagDetail(
        deviceId,
        payload,
        tagPayload,
        tagChartData
      );
    }
  };

  if (!assetsLoaded) {
    return <Navigate to="/" replace />;
  }

  useEffect(() => {
    let tagsToDisplay: string[] = [];
    let allTagsNames: string[] = [];
    const assetTags = selectedAsset?.device?.tags ?? [];
    const tagOptions = assetTags.map((tag) => tag.tagName);

    if (tagOptions) {
      const indexOfObject = tagOptions.findIndex(
        (item) => item === 'sb_ignition_status'
      );
      const defaultTag =
        indexOfObject !== -1 ? tagOptions[indexOfObject] : tagOptions[0];

      if (defaultTag) {
        tagsToDisplay = [defaultTag];
      }
      allTagsNames = tagOptions;
    }

    const storageData: any = sessionStorage.getItem(
      'selectedSensorAnalysisTags'
    );

    if (storageData) {
      tagsToDisplay = JSON.parse(storageData).tagNames;
    }

    tagsToDisplay = tagsToDisplay.filter((item) => allTagsNames.includes(item));

    setSelectedTags([...tagsToDisplay]);
  }, [selectedAsset]);

  useEffect(() => {
    const currentSelectedTagsLength = selectedTags.length;
    if (
      (endDateTime !== prevEndDateTimeRef.current && executeGrainCall) ||
      (selectedTags.length &&
        (currentSelectedTagsLength > prevSelectedTagsLengthRef.current ||
          activeButton !== prevActiveButtonRef.current ||
          selectedOperation !== prevSelectedOperationRef.current ||
          selectedAsset.device.deviceId !== prevSelectedDeviceIdRef.current))
    ) {
      const tagNames: string[] = [...selectedTags];
      void getTagDetails(
        selectedAsset.device.deviceId,
        {
          timeFrame: activeButton,
          tagNames,
        },
        tagPayload,
        dataType,
        startDate,
        endDate,
        startDateTime,
        endDateTime
      );
    }
    if (selectedTags.length) {
      sessionStorage.setItem(
        'selectedSensorAnalysisTags',
        JSON.stringify({ tagNames: selectedTags })
      );
    }

    prevSelectedTagsLengthRef.current = currentSelectedTagsLength;
    prevActiveButtonRef.current = activeButton;
    prevSelectedOperationRef.current = selectedOperation;
    prevEndDateTimeRef.current = endDateTime;
    prevSelectedDeviceIdRef.current = selectedAsset.device.deviceId;
  }, [
    selectedTags,
    activeButton,
    selectedOperation,
    selectedAsset.device.deviceId,
    tagPayload,
    endDateTime,
  ]);

  return (
    <>
      {isSelectTagModalOpen && (
        <SelectTagModal
          open={isSelectTagModalOpen}
          handleAbort={() => {
            setIsSelectTagModalOpen(false);
          }}
          title="Select Tags"
          enableDarkTheme={enableDarkTheme}
          tags={selectedAsset.device?.tags ?? []}
          addTags={setSelectedTags}
          defaultTags={selectedTags}
          dataType={dataType}
        />
      )}

      <Header
        enableDarkTheme={enableDarkTheme}
        selectedOperation={selectedOperation}
        setSelectedOperation={setSelectedOperation}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        dataType={dataType}
        setDataType={setDataType}
        selectedMakeModel={selectedMakeModel}
        setSelectedMakeModel={setSelectedMakeModel}
        makeModels={makeModels}
        selectedAsset={selectedAsset}
        setSelectedAsset={handleSelectAsset}
        assetBumperIds={assetBumperIds}
        assets={assets}
        latestTagData={latestTagData}
        hierarchyInfo={hierarchyInfo}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        maxDate={today}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        handleStartDateTimeChange={handleStartDateTimeChange}
        handleEndDateTimeChange={handleEndDateTimeChange}
        exportToLabel={exportToLabel}
        exportDropdownTo={exportDropdownTo}
        handleExportDropDownChange={handleExportDropDownChange}
        chartType={chartType}
        openEndDate={openEndDate}
        setOpenEndDate={setOpenEndDate}
      />

      <Content
        enableDarkTheme={enableDarkTheme}
        chartType={chartType}
        setChartType={setChartType}
        tags={selectedAsset.device?.tags ?? []}
        selectedTags={selectedTags}
        removeTag={removeTag}
        data={tagChartData}
        activeButton={activeButton}
        selectedAsset={selectedAsset}
        selectedOperation={selectedOperation}
        startDate={startDate}
        endDate={endDate}
        grainStartDate={startDateTime}
        grainEndDate={endDateTime}
        unitSystem={unitSystem}
        setIsSelectTagModalOpen={setIsSelectTagModalOpen}
        setSelectedTags={setSelectedTags}
        tagGrainData={tagGrainData}
        dataType={dataType}
        isLoadingData={isLoadingData}
        exportTo={exportTo}
        handleExport={handleExportClick}
      />
    </>
  );
};
