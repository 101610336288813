import { Box, Typography } from '@mui/material';
import { SWToolTip } from 'components/SWToolTip';
import { ThemePalette } from 'mui.theme';

const InfoPanel = ({
  enableDarkTheme,
  header,
  items,
  sx,
  disabled,
  disabledText,
}: {
  enableDarkTheme: boolean;
  header: string;
  items: Array<Record<string, unknown>>;
  sx: any;
  disabled?: boolean;
  disabledText?: string;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        border: enableDarkTheme ? '#313F53 solid 2px' : '#cdd1d6 solid 2px',
        borderRadius: '5px',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        minWidth: '250px',
        flexDirection: 'column',
        maxHeight: '250px',
        overflowX: 'scroll',
        ...sx,
      }}
    >
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          minHeight: '39px',
          backgroundColor: '#3666EC',
          borderTopRightRadius: '5px',
          borderTopLeftRadius: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <Typography
          sx={{ fontSize: '12px', fontWeight: '600', paddingLeft: '10px' }}
        >
          {header}
        </Typography>
      </Box>

      {disabled ? (
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '100px',
            backgroundColor: 'rgba(255,255,255,.6)',
          }}
        >
          <Typography
            sx={{
              padding: '0 10px',
              color: '#191818',
            }}
          >
            {disabledText}
          </Typography>
        </Box>
      ) : (
        items.map((item: any, index) => {
          return (
            <Box
              sx={{
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '5px 0',
              }}
              key={index}
            >
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '600',
                  paddingLeft: '10px',
                  width: '60%',
                  textAlign: 'left',
                }}
              >
                {item.name}{' '}
                <span
                  style={{
                    fontSize: '10px',
                    fontWeight: '300',
                    color: enableDarkTheme ? '#878A99' : '#878A99',
                    textAlign: 'left',
                    display: 'block',
                    marginTop: '-15px',
                  }}
                >
                  <br />
                  {item.desc}
                </span>
              </Typography>

              <SWToolTip title={`${item.toolTipDesc ?? null}`}>
                <Box
                  sx={{
                    width: '35%',
                    textAlign: 'center',
                    borderRadius: '5px',
                    minHeight: '25px',
                    backgroundColor:
                      item.status === 'OK' || item.statusText === 'OK'
                        ? '#009161'
                        : item.status === 'ALERT'
                        ? '#EB5757'
                        : item.status === 'WARNING'
                        ? '#d19929'
                        : item.status.includes('#')
                        ? item.status
                        : '#e0e0e0',
                    color:
                      item.status === 'OK' ||
                      item.status === 'ALERT' ||
                      item.status === 'WARNING' ||
                      item.statusText
                        ? '#FFFFFF'
                        : '#565454',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => item.handleClick()}
                >
                  <Typography sx={{ fontSize: '10px', fontWeight: '600' }}>
                    {item.statusText ?? item.status}
                  </Typography>
                </Box>
              </SWToolTip>
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default InfoPanel;
