/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useEffect, useRef, useState } from 'react';
import {
  Cartesian2,
  Cartesian3,
  Color,
  HorizontalOrigin,
  Ion,
  LabelStyle,
  ScreenSpaceEventType,
  VerticalOrigin,
} from 'cesium';
import {
  Viewer,
  Entity,
  ScreenSpaceEventHandler,
  ScreenSpaceEvent,
  ScreenSpaceCameraController,
} from 'resium';

import { type Asset } from 'types/asset.types';
import { ASSET_TYPES } from 'utils/enums';
import { getEnvVariable } from 'utils/helpers/getEnvVariable';
import { SVG } from 'components/Asset/SVG';
import {
  type CustomCSSProperties,
  getIsAssetOutOfService,
  svgToDataUrl,
  toCartesian3,
} from 'views/FleetOverview/helpers/map.helpers';
import { Box } from '@mui/material';
import './index.css';
import DirectionalArrows from './DirectionalArrows';
import {
  isAssetIgnition,
  isVehcleParked,
} from 'views/FleetOverview/helpers/asset.helpers';
import DeviceHandler from 'handlers/device.handler';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from 'store/hook';
import { SingleFadeLoader } from 'components/SingleFadeLoader';

interface Props {
  filteredAssets: Asset[];
  searchValue?: string;
  setselectedAsset?: any;
  handleEntityClick?: any;
  selectedAsset: Asset | null;
  viewerRef: any;
  latestTagData: any;
  customerCode: string;
}
export const dummyCredit = document.createElement('div');

const AssetMap = ({
  filteredAssets,
  setselectedAsset,
  handleEntityClick,
  selectedAsset,
  viewerRef,
  latestTagData,
  customerCode,
}: Props) => {
  const { isAuthenticated } = useAuth0();
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const { id: customerId } = useAppSelector(
    (state) => state.persistedReducer
  ).customer;

  const deviceHandler = new DeviceHandler();

  const [lastResponse, setLastResponse] = useState<any>(latestTagData);
  const [newResponse, setNewResponse] = useState<any>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const [homeViewCoords, setHomeViewCoords] = useState({
    lon: 0,
    lat: 0,
    height: 0,
  });

  useEffect(() => {
    const viewer = viewerRef.current?.cesiumElement;

    if (viewer && !viewer.isDestroyed()) {
      // Reinitialize the viewer or reset its state if needed
      viewer?.scene?.primitives?.removeAll(); // Clear existing entities
    }

    return () => {
      if (viewer && !viewer.isDestroyed()) {
        viewer.destroy();
      }
      setLastResponse(null);
      setNewResponse(null);
      isCameraInitialized.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isAuthenticated || !accessToken || !customerId) return;

        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }

        if (latestTagData && Object.keys(latestTagData).length > 0) {
          setLastResponse(latestTagData);
        }

        const latestData =
          await deviceHandler.getDeviceLatestSensorDataAllVarTime();
        setNewResponse(latestData);

        timeoutIdRef.current = setTimeout(fetchData, 30000);
      } catch (error) {
        console.error('Error fetching device data:', error);
      }
    };

    void fetchData();

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, [isAuthenticated, accessToken, customerId]);

  const isCameraInitialized = useRef(false);
  const [maxZoom, setMaxZoom] = useState<number | undefined>(undefined);

  Ion.defaultAccessToken = getEnvVariable(
    'REACT_APP_CESIUM_ION_DEFAULT_ACCESS_TOKEN'
  );

  const assetList: Asset[] = filteredAssets.filter(
    (asset) =>
      asset?.liveTagData?.gps_latitude && asset?.liveTagData?.gps_longitude
  );

  useEffect(() => {
    if (
      viewerRef?.current &&
      assetList.length > 0 &&
      !isCameraInitialized.current
    ) {
      const viewer = viewerRef?.current?.cesiumElement;

      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      if (viewer && viewer?.camera) {
        let minLat = Number.POSITIVE_INFINITY;
        let maxLat = Number.NEGATIVE_INFINITY;
        let minLon = Number.POSITIVE_INFINITY;
        let maxLon = Number.NEGATIVE_INFINITY;

        // **Include loading and unloading points, or any other fixed points in calculations**
        const pitsCoords =
          customerCode === 'buz'
            ? [
                { lon: -89.54475402832031, lat: 37.27180480957031 },
                { lon: -89.54063415527344, lat: 37.27317810058594 },
              ]
            : [];

        [
          ...assetList.map((asset) => ({
            lon: asset.liveTagData.gps_longitude,
            lat: asset.liveTagData.gps_latitude,
          })),
          ...pitsCoords,
        ].forEach(({ lon, lat }) => {
          if (!isNaN(lat) && !isNaN(lon)) {
            minLat = Math.min(minLat, lat);
            maxLat = Math.max(maxLat, lat);
            minLon = Math.min(minLon, lon);
            maxLon = Math.max(maxLon, lon);
          }
        });

        if (
          minLat === Number.POSITIVE_INFINITY ||
          maxLat === Number.NEGATIVE_INFINITY ||
          minLon === Number.POSITIVE_INFINITY ||
          maxLon === Number.NEGATIVE_INFINITY
        ) {
          console.error('Invalid coordinates - skipping camera update.');
          return;
        }

        //  center points
        const centerLat = (minLat + maxLat) / 2;
        const centerLon = (minLon + maxLon) / 2;

        const latDiff = Math.max(maxLat - minLat, 0.0001);
        const lonDiff = Math.max(maxLon - minLon, 0.0001);
        const maxDiff = Math.max(latDiff, lonDiff);
        const calculatedHeight = maxDiff * 200000;

        viewer?.camera?.setView({
          destination: Cartesian3.fromDegrees(
            centerLon,
            centerLat,
            calculatedHeight
          ),
        });

        setHomeViewCoords({
          lon: centerLon,
          lat: centerLat,
          height: calculatedHeight,
        });

        isCameraInitialized.current = true;
      }
    }
  }, [viewerRef, assetList]);

  const generateArrowSegments = (asset: Asset) => {
    const start = lastResponse ? lastResponse[asset?.device?.deviceId] : null;
    // const end = asset.liveTagData;
    const end = newResponse ? newResponse[asset?.device?.deviceId] : null;

    if (
      !start ||
      !start?.gps_latitude ||
      !start?.gps_longitude ||
      isNaN(start?.gps_latitude) ||
      isNaN(start?.gps_longitude)
    )
      return null;

    if (
      !end ||
      !end?.gps_latitude ||
      !end?.gps_longitude ||
      isNaN(end?.gps_latitude) ||
      isNaN(end?.gps_longitude)
    )
      return null;

    const startCartesian = toCartesian3(
      Number(start.gps_longitude),
      Number(start.gps_latitude),
      Number(start.gps_altitude) || 0
    );
    const endCartesian = toCartesian3(
      Number(end.gps_longitude),
      Number(end.gps_latitude),
      Number(end.gps_altitude) || 0
    );

    // Compute direction vector
    const direction = Cartesian3.subtract(
      endCartesian,
      startCartesian,
      new Cartesian3()
    );

    // Check if direction vector is valid
    if (
      isNaN(direction.x) ||
      isNaN(direction.y) ||
      isNaN(direction.z) ||
      Cartesian3.magnitudeSquared(direction) === 0
    ) {
      console.warn(
        'Invalid or zero movement detected. Skipping arrow generation.'
      );
      return null;
    }

    // Normalize safely
    const normalizedDirection = Cartesian3.normalize(
      direction,
      new Cartesian3()
    );

    if (
      isNaN(normalizedDirection.x) ||
      isNaN(normalizedDirection.y) ||
      isNaN(normalizedDirection.z)
    ) {
      console.error('Normalization resulted in NaN. Check input coordinates.');
      return null;
    }

    // Scale the direction vector to get arrow length
    const arrowLengthFactor = 0.1;
    const arrowVector = Cartesian3.multiplyByScalar(
      normalizedDirection,
      arrowLengthFactor,
      new Cartesian3()
    );

    const newEnd = Cartesian3.add(endCartesian, arrowVector, new Cartesian3());

    return { start: endCartesian, end: newEnd };
  };

  const handleMouseEnter = () => {
    document.body.style.cursor = 'pointer';
  };

  const handleMouseLeave = () => {
    document.body.style.cursor = 'default';
  };

  useEffect(() => {
    if (!viewerRef.current || !viewerRef.current.cesiumElement) return;

    const viewer = viewerRef.current.cesiumElement;

    if (!homeViewCoords || homeViewCoords.height === 0) return; // Prevent incorrect defaults

    setMaxZoom(homeViewCoords.height);
    if (viewer.homeButton) {
      viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
        (event: any) => {
          event.cancel = true; // Prevent default home button action

          viewer.camera.flyTo({
            destination: Cartesian3.fromDegrees(
              homeViewCoords.lon,
              homeViewCoords.lat,
              homeViewCoords.height
            ),
            duration: 2,
          });
        }
      );
    }
  }, [homeViewCoords, viewerRef]);

  // return !isAuthenticated || !accessToken || !customerId || !latestTagData ? (
  //   <SingleFadeLoader />
  // ) :

  const navPinSVGDataUrl = svgToDataUrl(
    <SVG name="navPin" width={50} height={50} />
  );

  return (
    <Viewer
      style={{
        position: 'inherit',
        width: '100%',
        height: '100%',
      }}
      ref={viewerRef}
      creditContainer={dummyCredit}
      timeline={false}
      navigationHelpButton={false}
      homeButton={true}
      animation={false}
      infoBox={false}
      selectionIndicator={false}
    >
      {customerCode === 'buz' && (
        <>
          {/* //loading area */}

          <Entity
            name={'loading area'}
            position={toCartesian3(-89.54475402832031, 37.27180480957031)}
            billboard={{
              image: navPinSVGDataUrl,
            }}
            description={'Loading Area: TOP LEFT'}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            id={'loading area'}
            label={{
              text: 'Loading Area',
              font: 'bold 14px sans-serif',
              fillColor: Color.fromCssColorString('rgb(249, 251, 240)'),
              outlineColor: Color.BLACK,
              outlineWidth: 4,
              style: LabelStyle.FILL_AND_OUTLINE,
              verticalOrigin: VerticalOrigin.CENTER,
              horizontalOrigin: HorizontalOrigin.CENTER,
              pixelOffset: new Cartesian2(0, -30), // Adjust position
            }}
          />

          {/* //unloading area */}
          <Entity
            name={'unloading area'}
            position={toCartesian3(-89.54063415527344, 37.27317810058594)}
            billboard={{
              image: navPinSVGDataUrl,
            }}
            description={'Unloading Area: PRI_DUMP'}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            id={'unloading area'}
            label={{
              text: 'Unloading Area',
              font: 'bold 14px sans-serif',
              fillColor: Color.fromCssColorString('rgb(249, 251, 240)'),
              outlineColor: Color.BLACK,
              outlineWidth: 4,
              style: LabelStyle.FILL_AND_OUTLINE,
              verticalOrigin: VerticalOrigin.CENTER,
              horizontalOrigin: HorizontalOrigin.CENTER,
              pixelOffset: new Cartesian2(0, -30), // Adjust position
            }}
          />
        </>
      )}

      <ScreenSpaceCameraController
        enableZoom={!selectedAsset}
        minimumZoomDistance={150}
        maximumZoomDistance={maxZoom}
      />
      {assetList.map((device: Asset) => {
        const tagsForMapView = device.device.tags.filter(
          (item: any) => item.showOnFleetOverviewMap
        );

        const tags: any = [];
        tagsForMapView.map((tag) => {
          const tagFromLiveTag = device?.liveTagData?.[`${tag.tagName}`];
          tags.push([
            `${tag.tagAlias || tag.tagName}`,
            `${tagFromLiveTag}`,
            `${tag.unit}`,
          ]);
          return null;
        });

        // const longitude = Number(device.liveTagData?.gps_longitude);
        // const latitude = Number(device.liveTagData?.gps_latitude);
        const longitude = Number(
          latestTagData[device.device.deviceId]?.gps_longitude ?? 0
        );

        const latitude = Number(
          latestTagData[device.device.deviceId]?.gps_latitude ?? 0
        );

        const isAssetOn = isAssetIgnition(latestTagData, device);

        const color = isAssetOn ? '#32736a' : '#5c4b4b';

        const svgStyle: CustomCSSProperties = {
          '--circle-color': color,
          '--background-color': getIsAssetOutOfService(device)
            ? '#C3C3C3'
            : '#0000FF',
          '--mountain-color': getIsAssetOutOfService(device)
            ? '#787878'
            : '#FFFFFF',
        };

        const svgDataUrl = svgToDataUrl(
          <SVG
            name="alertsOnlineHaulTruck"
            width={50}
            height={50}
            style={svgStyle}
          />
        );

        const segments = generateArrowSegments(device);
        return (
          <Box
            data-tooltip-id="my-tooltip"
            key={device.device.deviceId}
            style={{ cursor: 'pointer' }}
          >
            <Entity
              name={`${
                ASSET_TYPES.find((e) => e.id === device.assetType)?.display
              } ${device.device.assetBumperNumber}`}
              position={toCartesian3(longitude, latitude)}
              billboard={{
                image: svgDataUrl,
              }}
              description={undefined}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              id={device.device.deviceId}
              onClick={(movement) => {
                const position = toCartesian3(longitude, latitude);
                handleEntityClick(device, movement, position);
              }}
            >
              {segments && isAssetOn && (
                <DirectionalArrows start={segments.start} end={segments.end} />
              )}

              {/* Conditionally overlay "P" if isParked is true */}
              {isVehcleParked(latestTagData, device, 'buz') && (
                <Entity
                  position={toCartesian3(longitude, latitude)}
                  label={{
                    text: 'Parked',
                    font: 'bold 14px sans-serif',
                    fillColor: Color.fromCssColorString('rgb(249, 251, 240)'),
                    outlineColor: Color.BLACK,
                    outlineWidth: 4,
                    style: LabelStyle.FILL_AND_OUTLINE,
                    verticalOrigin: VerticalOrigin.CENTER,
                    horizontalOrigin: HorizontalOrigin.CENTER,
                    pixelOffset: new Cartesian2(0, -10), // Adjust position
                  }}
                />
              )}
            </Entity>
          </Box>
        );
      })}
      <ScreenSpaceEventHandler>
        <ScreenSpaceEvent
          action={(movement) => {
            // handleEntityClick(null, movement, newPosition); // Deselect any entity if the map is clicked
          }}
          type={ScreenSpaceEventType.LEFT_CLICK}
        />
      </ScreenSpaceEventHandler>
    </Viewer>
  );
};

export default AssetMap;
