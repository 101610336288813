import { useEffect, useState, useMemo } from 'react';
import dayjs from 'dayjs';
import { addHours } from 'date-fns';
import { Box, Grid } from '@mui/material';
import { useAppSelector } from 'store/hook';
import { InclinometerView } from './InclinometerView';
import { GpsView } from './GpsView';
import { AssetDetails } from './AssetDetails';
import { isDarkTheme } from 'utils/theme';
import { type Asset } from 'types/asset.types';
import { type Device } from 'types/device.types';
import { selectAssetByMakeModel } from 'store/asset.slice';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';
import { AssetEvents } from './AssetEvents';
import { AssetFuelMetric } from './AssetFuelMetric';
import { AssetTireMetric } from './AssetTireMetric';
import { DateSelector } from './DateSelector';
import InclinometerHandler from 'handlers/inclinomenter.handler';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import AssetDisplay from './Timeline';
import { getTimeRange, getArrayByKey } from './utils';
import { tireCodeNames } from 'views/TirePressure/util';

const availableTireCodeNames = Object.keys(tireCodeNames);

export const InclineRoadCondition = () => {
  const inclinometerHandler = new InclinometerHandler();
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const { assets } = useAppSelector((state) => state.assetReducer);
  const hierarchyInfo = useAppSelector((state) => state.hierarchyReducer);
  const { latestTagData } = useAppSelector((state) => state.deviceReducer);
  const { events, tirePressure, fuelEfficiency, isLoading, gpsData } =
    useAppSelector((state) => state.inclinometerReducer);
  const enableDarkTheme = isDarkTheme(theme);
  const [isSelected, setIsSelected] = useState('5m');

  const [selectedMakeModel, setSelectedMakeModel] = useState<string>('');
  const [selectedAsset, setSelectedAsset] = useState<Asset>({
    assetId: '',
    assetVin: '',
    assetType: 'haul_truck',
    make: '',
    bumperNumber: '',
    engineHoursOffset: -1,
    device: {} as const as Device,
    isActive: false,
    model: '',
    odometerOffset: -1,
    fuelSource: '',
    operators: [],
    externalId: '',
    assetNotes: [],
  });

  const [startDateTime, setStartDateTime] = useState(
    new Date(new Date().setDate(new Date().getDate() - 2))
  );

  const [endDateTime, setEndDateTime] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );

  const [openEndDate, setOpenEndDate] = useState<boolean>(false);

  const [executeCall, setExecuteCall] = useState<boolean>(false);

  const defaultAssetVin: string | null = new URLSearchParams(
    location.search
  ).get('assetVin');

  const allAssetMakeModelList = useAppSelector(selectAssetByMakeModel);

  const assetMakeModelList = useMemo(() => {
    const result: string[] = [];
    const keys = Object.keys(allAssetMakeModelList);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const assetType = allAssetMakeModelList[key]?.[0]?.assetType;
      if (availableTireCodeNames.includes(assetType)) {
        result.push(key);
      }
    }
    return result;
  }, [allAssetMakeModelList]);

  const filteredAssets = useMemo(() => {
    const sortedAssets = [...assets];
    sortedAssets.sort((a, b) => a.bumperNumber.localeCompare(b.bumperNumber));
    if (!selectedMakeModel)
      return sortedAssets.filter((asset) => asset.device.deviceId);

    const [selectedMake, selectedModel] = selectedMakeModel.split('-');
    return sortedAssets.filter(
      (asset) =>
        asset.make.toLowerCase() === selectedMake.toLowerCase() &&
        asset.model.toLowerCase() === selectedModel.toLowerCase() &&
        asset.device.deviceId
    );
  }, [selectedMakeModel, assets]);

  const assetMap = useMemo(() => {
    const map: Record<Asset['assetVin'], Asset> = {};
    filteredAssets.forEach((asset) => (map[asset.assetVin] = asset));
    return map;
  }, [filteredAssets]);

  function handleSelectAsset(bumperNumber: string) {
    const item = assets.find((asset) => asset.bumperNumber === bumperNumber);
    if (item) {
      setSelectedAsset(item);
    }
  }

  useEffect(() => {
    const filteredAssets = assets
      .filter(
        (asset) =>
          composeUniqueKey([asset.make, asset.model]) ===
          selectedMakeModel.replace('-', '!')
      )
      .map((item) => {
        return item.bumperNumber;
      });
    handleSelectAsset(filteredAssets[0]);
  }, [selectedMakeModel]);

  useEffect(() => {
    if (defaultAssetVin) {
      const defaultAsset = assets.find(
        (item) => item.assetVin === defaultAssetVin
      );
      if (defaultAsset) {
        const assetMakeModel = composeUniqueKey(
          [defaultAsset.make, defaultAsset.model],
          '-'
        );
        setSelectedAsset(defaultAsset);
        setSelectedMakeModel(assetMakeModel);
      }
    } else {
      if (assets[0]) {
        const assetMakeModel = composeUniqueKey(
          [assets[0].make, assets[0].model],
          '-'
        );
        setSelectedAsset(assets[0]);
        setSelectedMakeModel(assetMakeModel);
      }
    }
  }, []);

  const getEndDateTime = (start: any) => {
    return addHours(start, 4);
  };

  const handleStartDateTimeChange = (date: any) => {
    setExecuteCall(false);
    setStartDateTime(date);
    setEndDateTime(getEndDateTime(date));
  };

  const handleEndDateTimeChange = (date: any) => {
    setExecuteCall(true);
    setEndDateTime(date);
  };

  const fetchData = async (startDate: Date, endDate: Date, range: string) => {
    if (selectedAsset.device.deviceId) {
      if (range === 'Custom') {
        if (startDate && endDate) {
          const startDateFormatted = dayjs(startDate).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD HH:mm:ss');

          await inclinometerHandler.get(
            startDateFormatted,
            endDateFormatted,
            selectedAsset.device.deviceId,
            selectedAsset.assetType
          );
        }
      } else {
        const { startTime, endTime } = getTimeRange(range);
        await inclinometerHandler.get(
          startTime,
          endTime,
          selectedAsset.device.deviceId,
          selectedAsset.assetType
        );
      }
    }
  };

  useEffect(() => {
    if (executeCall) {
      void fetchData(startDateTime, endDateTime, isSelected);
    }
  }, [endDateTime]);

  useEffect(() => {
    void fetchData(startDateTime, endDateTime, isSelected);
  }, [selectedAsset]);

  const assetDisplayData = {
    braking: getArrayByKey(events, 'braking'),
    productive: getArrayByKey(events, 'productive'),
    short_idling: getArrayByKey(events, 'short_idling'),
    excess_idling: getArrayByKey(events, 'excess_idling'),
    overspeed: getArrayByKey(events, 'overspeed'),
    underspeed: getArrayByKey(events, 'underspeed'),
    incorrect_gear: getArrayByKey(events, 'incorrect_gear'),
    engine_hours: getArrayByKey(events, 'engine_hours'),
    acceleration: getArrayByKey(events, 'acceleration'),
    incorrect_gear_shift: getArrayByKey(events, 'incorrect_gear_shift'),
    gear_skip: getArrayByKey(events, 'gear_skip'),
  };

  return (
    <Box>
      <DateSelector
        enableDarkTheme={enableDarkTheme}
        isLoading={isLoading}
        isSelected={isSelected}
        setIsSelected={setIsSelected}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        handleStartDateTimeChange={handleStartDateTimeChange}
        handleEndDateTimeChange={handleEndDateTimeChange}
        openEndDate={openEndDate}
        setOpenEndDate={setOpenEndDate}
        setExecuteCall={setExecuteCall}
      />

      {isLoading ? (
        <SingleFadeLoader />
      ) : (
        <>
          <Box>
            <AssetDisplay
              enableDarkTheme={enableDarkTheme}
              hierarchyInfo={hierarchyInfo}
              assets={assets}
              latestTagData={latestTagData}
              selectedAsset={selectedAsset.bumperNumber}
              setSelectedAsset={setSelectedAsset}
              timelineDate={dayjs().format('YYYY-MM-DD')}
              data={assetDisplayData}
              startDate={startDateTime}
              endDate={endDateTime}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <AssetDetails
                enableDarkTheme={enableDarkTheme}
                selectedMakeModel={selectedMakeModel}
                setSelectedMakeModel={setSelectedMakeModel}
                assetMakeModelList={assetMakeModelList}
                selectedAsset={selectedAsset}
                setSelectedAsset={setSelectedAsset}
                assetMap={assetMap}
                filteredAssets={filteredAssets}
                hierarchyInfo={hierarchyInfo}
                latestTagData={latestTagData}
              />
              <AssetEvents
                enableDarkTheme={enableDarkTheme}
                data={events}
                selectedAsset={selectedAsset}
              />
              <Grid container spacing={2}>
                <Grid item lg={6}>
                  <AssetFuelMetric
                    enableDarkTheme={enableDarkTheme}
                    data={fuelEfficiency}
                  />
                </Grid>
                <Grid item lg={6}>
                  <AssetTireMetric
                    enableDarkTheme={enableDarkTheme}
                    data={tirePressure}
                    selectedAsset={selectedAsset}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={7} md={12} sm={12} xs={12}>
              <InclinometerView
                enableDarkTheme={enableDarkTheme}
                startDate={startDateTime}
                endDate={endDateTime}
              />
              <GpsView
                enableDarkTheme={enableDarkTheme}
                gpsData={gpsData}
                startDate={startDateTime}
                endDate={endDateTime}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};
