import { Box } from '@mui/material';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  ReferenceLine,
} from 'recharts';
import { FlexBox } from 'components/Containers/FlexBox';
import type { productionAsset } from 'types/production.types';
import { ThemePalette } from 'mui.theme';
import { getAssetChartDataSource } from '../../utils';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { getDataUnit } from 'views/Production/Hauler/component/utils';
import {
  convertMinutesToHours,
  convertsecondsToHours,
  getShiftHours,
} from 'views/Utilization/utils';
import { truncateTo2dp } from 'utils/helpers/general';

interface Props {
  enableDarkTheme: boolean;
  data: productionAsset;
  selectedSource: string;
  customerCode: string;
  unitSystem: string;
  height?: string;
}

const AssetItemChart = ({
  enableDarkTheme,
  data,
  selectedSource,
  customerCode,
  unitSystem,
  height = '230px',
}: Props) => {
  const { date_stats: dateLoad } = data;

  const formattedDateLoad = Object.keys(dateLoad).map((date) => ({
    date,
    ...dateLoad[date],
    idling_duration: convertsecondsToHours(dateLoad[date].idling_duration),
    productive_duration: convertMinutesToHours(
      dateLoad[date].utilization_engine_working_minute
    ),
    productionValue: dateLoad[date].max_load_ton,
    utilization_by_shift_hours: dateLoad[date].utilization_by_shift_hours,
  }));

  let dataUnit = getDataUnit(selectedSource, unitSystem);
  dataUnit = dataUnit ? `(${dataUnit})` : '';

  const chartData = formattedDateLoad.map((date: any) => {
    let value = date[getAssetChartDataSource(selectedSource)]?.toString();
    if (selectedSource === 'Utilization') {
      value = Number(value) * 100;
    }
    if (value === null || isNaN(value)) {
      value = 0;
    }
    return {
      date: date.date,
      value: convertToTwoDecimalPlaces(value),
    };
  });
  chartData.sort((a, b) => {
    const dateA = dayjs(a.date).unix();
    const dateB = dayjs(b.date).unix();
    return dateA - dateB;
  });

  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('DD MMM');
    return formattedDate;
  };
  // Calculate the average
  const validEntries = chartData.filter(
    (entry: any) =>
      entry.value !== null && !isNaN(entry.value) && entry.value !== 0
  );
  const average =
    validEntries.reduce(
      (sum: number, entry: any) => sum + parseFloat(entry.value),
      0
    ) / validEntries.length;
  // Calculate the maximum value from valid entries
  const maxValue = validEntries.length
    ? Math.max(...validEntries.map((entry) => parseFloat(entry.value)))
    : 0;

  return (
    <Box sx={{ paddingTop: '10px' }}>
      <FlexBox
        sx={{
          maxWidth: 'inherit',
          height,
          paddingTop: '20px',
          marginRight: '10px',
          paddingBottom: '10px',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={700}
            height={300}
            data={chartData}
            margin={{
              top: 20,
              right: 100,
              left: 5,
              bottom: 5,
            }}
            barSize={30}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={ThemePalette.dark.cartesianGrid}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={formatTimestamp}
              tick={{ fontSize: 10 }}
            />
            <YAxis
              tick={{ fontSize: 10 }}
              domain={[0, Math.ceil(maxValue * 1.1)]}
              label={{
                value: `${selectedSource} ${dataUnit}`,
                angle: 270,
                position: 'insideLeft',
                fontSize: 10,
                textAnchor: 'middle',
                dy: 50,
              }}
            />
            <Tooltip
              cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
              wrapperStyle={{ outline: 'none', fontSize: '11px' }}
              contentStyle={{
                backgroundColor: 'transparent',
              }}
              itemStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
            <Bar
              dataKey="value"
              fill="#4A7BFA"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            <ReferenceLine
              y={average}
              label={{
                value:
                  selectedSource.toLowerCase().replace(/ /g, '') ===
                  'productionrate'
                    ? `Avg ${truncateTo2dp(
                        data?.avg_load_per_hour
                      )} ${dataUnit}`
                    : `Avg ${average.toFixed(2)}  ${dataUnit}/Day`,
                position: 'right',
                fontSize: '10px',
              }}
              stroke="#4A7BFA"
              strokeDasharray="3 3"
              isFront
            />
          </BarChart>
        </ResponsiveContainer>
      </FlexBox>
    </Box>
  );
};

export default AssetItemChart;
