/* eslint-disable @typescript-eslint/restrict-plus-operands */
export function aggregateCostsByMonth(orders: any[]) {
  const monthlyCosts: any = {};

  orders.forEach((order) => {
    const date = new Date(order.date_of_orders);

    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();

    const monthYearKey = `${monthName} ${year}`;

    if (monthlyCosts[monthYearKey]) {
      monthlyCosts[monthYearKey] += Number(order.total_cost);
    } else {
      monthlyCosts[monthYearKey] = Number(order.total_cost);
    }
  });

  Object.keys(monthlyCosts).forEach((monthYear) => {
    monthlyCosts[monthYear] = parseFloat(monthlyCosts[monthYear].toFixed(2));
  });

  return monthlyCosts;
}

export function calculateTrend(data: any) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dataArray = Object.entries(data).map(([key, value]) => {
    return {
      name: key,
      cost: value,
    };
  });

  dataArray.sort((a, b) => {
    const [aMonth, aYear] = a.name.split(' ');
    const [bMonth, bYear] = b.name.split(' ');

    const aYearNum = parseInt(aYear, 10);
    const bYearNum = parseInt(bYear, 10);

    if (aYearNum !== bYearNum) {
      return aYearNum - bYearNum;
    }

    return (
      months.indexOf(aMonth.charAt(0).toUpperCase() + aMonth.slice(1)) -
      months.indexOf(bMonth.charAt(0).toUpperCase() + bMonth.slice(1))
    );
  });

  const result = dataArray.map((item: any, index) => {
    let trend;

    if (index < 2) {
      trend = item.cost;
    } else {
      trend =
        (item.cost + dataArray[index - 1].cost + dataArray[index - 2].cost) / 3;
    }

    return {
      ...item,
      trend,
    };
  });
  return result;
}

export function findHighestValue(data: any[]) {
  if (!data || data.length === 0) {
    return null;
  }

  const allValues: any[] = [];

  data.forEach((item) => {
    allValues.push(item.cost);
    allValues.push(item.trend);
  });

  const highestValue = Math.max(...allValues);

  return highestValue;
}

interface MaintenanceRecord {
  actions_performed: string;
  asset_id: string;
  category: string;
  components_category: string;
  customer: string;
  date_of_orders: string;
  description: string;
  part_cost: number;
  sub_component_categories: string;
  total_cost: number;
  work_order_number: string;
  work_order_status: string;
}

interface MaintenanceSpendResult {
  assetWithHighestSpend: string | null;
  totalSpend: number;
  topComponentForAsset: string | null;
}

export function getHighestMaintenanceSpend(
  maintenanceRecords: MaintenanceRecord[]
): MaintenanceSpendResult {
  const assetSpendMap: Record<
    string,
    { totalSpend: number; componentSpend: Record<string, number> }
  > = {};

  for (const record of maintenanceRecords) {
    const {
      asset_id: assetId,
      total_cost: totalCost,
      components_category: componentsCategory,
    } = record;
    if (!assetId || isNaN(totalCost)) continue;

    if (!assetSpendMap[assetId]) {
      assetSpendMap[assetId] = { totalSpend: 0, componentSpend: {} };
    }

    assetSpendMap[assetId].totalSpend += totalCost;

    if (!assetSpendMap[assetId].componentSpend[componentsCategory]) {
      assetSpendMap[assetId].componentSpend[componentsCategory] = 0;
    }

    assetSpendMap[assetId].componentSpend[componentsCategory] += totalCost;
  }

  let highestAsset: string | null = null;
  let highestSpend = -Infinity;

  for (const [asset, data] of Object.entries(assetSpendMap)) {
    if (data.totalSpend > highestSpend) {
      highestAsset = asset;
      highestSpend = data.totalSpend;
    }
  }

  if (!highestAsset)
    return {
      assetWithHighestSpend: null,
      totalSpend: 0,
      topComponentForAsset: null,
    };

  const topAssetData = assetSpendMap[highestAsset];
  let highestComponent: string | null = null;
  let highestComponentSpend = -Infinity;

  for (const [component, spend] of Object.entries(
    topAssetData.componentSpend
  )) {
    if (spend > highestComponentSpend) {
      highestComponent = component;
      highestComponentSpend = spend;
    }
  }

  return {
    assetWithHighestSpend: highestAsset,
    totalSpend: highestSpend,
    topComponentForAsset: highestComponent,
  };
}

interface ComponentSpendResult {
  componentWithHighestSpend: string | null;
  totalSpend: number;
  assetWithMostSpendOnComponent: string | null;
}

export function getHighestComponentSpend(
  maintenanceRecords: MaintenanceRecord[]
): ComponentSpendResult {
  const componentSpendMap: Record<
    string,
    { totalSpend: number; assetSpend: Record<string, number> }
  > = {};

  for (const record of maintenanceRecords) {
    const {
      asset_id: assetId,
      total_cost: totalCost,
      components_category: componentsCategory,
    } = record;
    if (!assetId || !componentsCategory || isNaN(totalCost)) continue;

    if (!componentSpendMap[componentsCategory]) {
      componentSpendMap[componentsCategory] = {
        totalSpend: 0,
        assetSpend: {},
      };
    }

    componentSpendMap[componentsCategory].totalSpend += totalCost;

    if (!componentSpendMap[componentsCategory].assetSpend[assetId]) {
      componentSpendMap[componentsCategory].assetSpend[assetId] = 0;
    }

    componentSpendMap[componentsCategory].assetSpend[assetId] += totalCost;
  }

  let highestComponent: string | null = null;
  let highestSpend = -Infinity;

  for (const [component, data] of Object.entries(componentSpendMap)) {
    if (data.totalSpend > highestSpend) {
      highestComponent = component;
      highestSpend = data.totalSpend;
    }
  }

  if (!highestComponent)
    return {
      componentWithHighestSpend: null,
      totalSpend: 0,
      assetWithMostSpendOnComponent: null,
    };

  const topComponentData = componentSpendMap[highestComponent];
  let highestAsset: string | null = null;
  let highestAssetSpend = -Infinity;

  for (const [asset, spend] of Object.entries(topComponentData.assetSpend)) {
    if (spend > highestAssetSpend) {
      highestAsset = asset;
      highestAssetSpend = spend;
    }
  }

  return {
    componentWithHighestSpend: highestComponent,
    totalSpend: highestSpend,
    assetWithMostSpendOnComponent: highestAsset,
  };
}

interface PartReplacementResult {
  mostFrequentPart: string | null;
  totalSpendForPart: number;
  assetWithMostSpendOnPart: string | null;
}

export function getMostFrequentlyReplacedPart(
  maintenanceRecords: MaintenanceRecord[]
): PartReplacementResult {
  const partFrequencyMap: Record<
    string,
    { count: number; totalSpend: number; assetSpend: Record<string, number> }
  > = {};

  const filteredReplacements = maintenanceRecords.filter(
    (item) => item.actions_performed === 'REPLACE'
  );
  for (const record of filteredReplacements) {
    const {
      asset_id: assetId,
      total_cost: totalCost,
      components_category: componentsCategory,
    } = record;
    if (!assetId || !componentsCategory || isNaN(totalCost)) continue;

    if (!partFrequencyMap[componentsCategory]) {
      partFrequencyMap[componentsCategory] = {
        count: 0,
        totalSpend: 0,
        assetSpend: {},
      };
    }

    partFrequencyMap[componentsCategory].count += 1;
    partFrequencyMap[componentsCategory].totalSpend += totalCost;

    if (!partFrequencyMap[componentsCategory].assetSpend[assetId]) {
      partFrequencyMap[componentsCategory].assetSpend[assetId] = 0;
    }

    partFrequencyMap[componentsCategory].assetSpend[assetId] += totalCost;
  }

  let mostFrequentPart: string | null = null;
  let highestCount = -Infinity;

  for (const [part, data] of Object.entries(partFrequencyMap)) {
    if (data.count > highestCount) {
      mostFrequentPart = part;
      highestCount = data.count;
    }
  }

  if (!mostFrequentPart)
    return {
      mostFrequentPart: null,
      totalSpendForPart: 0,
      assetWithMostSpendOnPart: null,
    };

  const topPartData = partFrequencyMap[mostFrequentPart];
  let highestAsset: string | null = null;
  let highestAssetSpend = -Infinity;

  for (const [asset, spend] of Object.entries(topPartData.assetSpend)) {
    if (spend > highestAssetSpend) {
      highestAsset = asset;
      highestAssetSpend = spend;
    }
  }

  return {
    mostFrequentPart,
    totalSpendForPart: topPartData.totalSpend,
    assetWithMostSpendOnPart: highestAsset,
  };
}
