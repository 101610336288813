import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { useCurrentDateRange } from 'hooks/dateRange';
import HeaderMenu from './HeaderMenu';
import Loader from './Loader';
import AllFleet from './AllFleet';
import Hauler from './Hauler';
import ProductionHandler from 'handlers/production.handler';
import { exportOverviewPagesToPdf2 } from 'utils/exports/pdf-reports';
import { setIsPrinting } from 'store/exportData.slice';
import { exportToExcelForProduction } from 'utils/exports/excel';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import TargetHandler from 'handlers/targets.handler';
import { useAuth0 } from '@auth0/auth0-react';
import { calculateDaysDifference } from 'views/FuelAnalytics/IdleDashboard/utils';
import { formatDateShortener } from 'utils/helpers/dateTime';

const Production = () => {
  const location = useLocation();
  const typeReceived = location.state?.type;
  const productionHandler = new ProductionHandler();
  const targetHandler = new TargetHandler();
  const { isAuthenticated } = useAuth0();
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const customerId = useAppSelector((state) => state.persistedReducer).customer
    ?.id;
  const [fullYear] = useState(new Date().getFullYear());
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const { data, loadingStates } = useAppSelector(
    (state) => state.productionReducer
  );
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const { assetOverView: assetOverview } = useAppSelector(
    (state) => state.assetReducer
  );
  const customerCode = useAppSelector(
    (state) => state.persistedReducer.customer.code
  );
  const { isPrinting } = useAppSelector((state) => state.exportDataReducer);
  const dispatch = useAppDispatch();

  const enableDarkTheme = isDarkTheme(theme);
  const defaultValue = typeReceived
    ? typeReceived === 'Haul Truck'
      ? 1
      : typeReceived === 'Loader'
      ? 2
      : 0
    : 0;
  const [value, setValue] = useState(defaultValue);
  const [isSelected, setIsSelected] = useState('1D');
  const [initRun, setInitRun] = useState<boolean>(true);
  const [isCustomDateChanged, setIsCustomDateChanged] = useState(false);

  const [aggregation, setAggregation] = useState<string>('Daily');

  const { startDate, endDate, setStartDate, setEndDate, today } =
    useCurrentDateRange({});
  const [exportTo, setExportTo] = useState('');
  const [exportClicked, setExportClicked] = useState(false);
  const [detailsVal, setDetailsVal] = useState<Record<string, unknown>>({});
  const [analyticsData, setAnalyticsData] = useState<any[]>();
  const [hoursBreakDown, setHoursBreakDown] = useState<{
    hauler: any;
    'Fuel Consumption': any;
    // loader: any;
  }>({
    hauler: null,
    'Fuel Consumption': null,
    // loader: null,
  });

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  const fileNameWithDateTime = `production-dashboard-${formatDate(new Date())}`;
  const title = '';

  const handleExportClick = async () => {
    dispatch(setIsPrinting(true));
    await exportOverviewPagesToPdf2(
      'production-overview',
      'production-overview',
      'Production Overview',
      false,
      'portrait'
    );
    dispatch(setIsPrinting(false));
  };
  const [userSelections, setUserSelections] = useState({});
  const [suggestionData, setSuggestionData] = useState({});

  useEffect(() => {
    if (exportTo === 'pdf') {
      void handleExportClick();
    }
    if (exportTo === 'excel') {
      exportToExcelForProduction(
        {
          ...data.allFleet,
          fleetSummary: detailsVal,
          value: value === 2 ? 'loader' : value === 1 ? 'hauler' : 'all fleet',
        },
        fileNameWithDateTime,
        `${title}`,
        unitSystem,
        analyticsData as any[],
        {
          ...userSelections,
          quickrange:
            isSelected === 'Custom'
              ? `${calculateDaysDifference(
                  startDate,
                  endDate
                )} Days from ${formatDateShortener(
                  startDate
                )} with ${aggregation} aggregation`
              : isSelected,
        },
        hoursBreakDown,
        suggestionData
      );
    }
  }, [exportTo]);

  const isNotLoadingData = Object.entries(loadingStates)
    .filter(
      ([key]) => key !== 'isLoadingTarget' && key !== 'isLoadingSuggestions'
    )
    .every(([, value]) => !value);

  const fetchData = async (range: string, startDate: Date, endDate: Date) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    const shouldSendDate = isSelected === 'Custom';
    if (isNotLoadingData) {
      await productionHandler.get(
        range,
        formattedStartDate,
        formattedEndDate,
        shouldSendDate,
        false,
        value === 2 ? 'loader' : value === 1 ? 'hauler' : 'all',
        ['1Y', 'YTD', 'Custom'].includes(isSelected)
          ? aggregation.toLowerCase()
          : 'daily'
      );
      setInitRun(false);
    }
  };

  useEffect(() => {
    if (isSelected === 'Custom') {
      if (!isCustomDateChanged) {
        setIsCustomDateChanged(true);
      }
      if (isCustomDateChanged && startDate && endDate) {
        void fetchData(isSelected, startDate, endDate);
      }
    } else if (startDate && endDate) {
      setIsCustomDateChanged(false);
      void fetchData(isSelected, startDate, endDate);
    }
  }, [isSelected, startDate, endDate, aggregation]);

  return (
    <>
      <HeaderMenu
        value={value}
        handleChange={handleChange}
        handleExport={setExportClicked}
        setExportTo={setExportTo}
        exportTo={exportTo}
        loadingData={isNotLoadingData}
        isPrinting={isPrinting}
      />
      <div id="production-overview">
        <Loader
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          unitSystem={unitSystem}
          customerCode={customerCode}
          loadingStates={loadingStates}
          assetOverview={assetOverview}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          isPrinting={isPrinting}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={setHoursBreakDown}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
        />
        <Hauler
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          unitSystem={unitSystem}
          customerCode={customerCode}
          loadingStates={loadingStates}
          assetOverview={assetOverview}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          isPrinting={isPrinting}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={setHoursBreakDown}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
        />
        <AllFleet
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          data={data.allFleet}
          loadingStates={loadingStates}
          unitSystem={unitSystem}
          customerCode={customerCode}
          assetOverview={assetOverview}
          aggregation={aggregation}
          setAggregation={setAggregation}
          isPrinting={isPrinting}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={setHoursBreakDown}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
        />
      </div>
    </>
  );
};

export default Production;
