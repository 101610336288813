import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { ThemePalette } from 'mui.theme';
import React, { useMemo } from 'react';
import { shiftWiseEngineStartEndDataToExcel } from 'utils/exports/excel';
import { isDarkTheme } from 'utils/theme';
import { ShiftDetailsUTC } from 'views/Utilization/utils';
import { useAppSelector } from 'store/hook';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';

interface Props {
  disableDownload?: boolean;
}
const ExportShiftsToExcel = ({ disableDownload }: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const unitSystem = useAppSelector((state) => state.authReducer).customer
    .unitSystem;
  const fuelRateUnit = getLabelWrtUnitSystem('LPH', unitSystem);
  const enableDarkTheme = isDarkTheme(theme);

  const customerCode = useAppSelector((state) => state.authReducer).customer
    .code;

  const { data } = useAppSelector((state) => state.driverBehaviorReducer);

  const shiftWiseData = useMemo(() => {
    const { engine_hours: engineHours } = data;
    const customerShifts = ShiftDetailsUTC?.[customerCode?.toLowerCase()] || [];

    // Helper function to determine shift based on timestamp
    const getShift = (timestamp: string): string | null => {
      const hour = dayjs(timestamp).hour(); // Use dayjs for getting the hour
      for (let i = 0; i < customerShifts.length; i++) {
        const { startHour, endHour } = customerShifts[i];
        if (
          (startHour < endHour && hour >= startHour && hour < endHour) || // Same day shift
          (startHour > endHour && (hour >= startHour || hour < endHour)) // Overnight shift
        ) {
          return `shift${i + 1}`;
        }
      }
      return null; // No matching shift
    };

    // Initialize shift data object
    const shiftData = customerShifts.reduce(
      (acc: Record<string, any[]>, _, index: number) => {
        acc[`shift${index + 1}`] = [];
        return acc;
      },
      {}
    );

    // Process each truck's productive data
    engineHours.forEach((truck) => {
      const { bumper_id: bumperId, data: events } = truck;

      if (bumperId && events?.length) {
        const shiftRanges: Record<string, { first: string; last: string }> = {};

        // Categorize events into shifts
        events.forEach(({ start_ts: startTs, end_ts: endTs }) => {
          const shift = getShift(startTs);
          if (shift) {
            if (!shiftRanges[shift]) {
              shiftRanges[shift] = { first: startTs, last: endTs };
            } else {
              shiftRanges[shift].first = dayjs(
                shiftRanges[shift].first
              ).isBefore(dayjs(startTs))
                ? shiftRanges[shift].first
                : startTs;

              shiftRanges[shift].last = dayjs(shiftRanges[shift].last).isAfter(
                dayjs(endTs)
              )
                ? shiftRanges[shift].last
                : endTs;
            }
          }
        });

        // Add the shift ranges for the truck
        Object.entries(shiftRanges).forEach(([shift, range]) => {
          shiftData[shift].push({
            'Bumper Id': bumperId,
            'First Engine Start ts (UTC)': dayjs(`${range.first} UTC`).format(
              'YYYY-MM-DD HH:mm'
            ),
            'Last Engine Stop ts (UTC)': dayjs(`${range.last} UTC`).format(
              'YYYY-MM-DD HH:mm'
            ),
          });
        });
      }
    });
    return shiftData;
  }, [data, customerCode]);

  // Build extra worksheets (for e.g."engine_hours","short_idling","excess_idling",etc.) by flattening each event
  const extraWorkbooks = useMemo(() => {
    const worksheets: Record<string, any[]> = {};
    Object.entries(data).forEach(([key, entries]) => {
      if (
        key !== 'engine_hours' &&
        key !== 'productive' &&
        key !== 'short_idling' &&
        key !== 'excess_idling'
      )
        return;
      if (!Array.isArray(entries)) return;
      const rows: any[] = [];
      entries.forEach((item: any) => {
        const parentId = item.bumper_id || item.device_id || '';
        if (Array.isArray(item.data) && item.data.length > 0) {
          item.data.forEach((event: any) => {
            const start = dayjs(event.start_ts);
            const end = dayjs(event.end_ts);
            const duration = end.diff(start, 'second'); // Duration in seconds
            const minutes = Math.floor(duration / 60);
            const seconds = duration % 60;
            const durationString = `${minutes}m ${seconds}s`;
            rows.push({
              'Bumper Id': parentId,
              'Start Timestamp (UTC)': dayjs(event.start_ts).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              'End Timestamp (UTC)': dayjs(event.end_ts).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              'Average RPM': event.average_rpm || event.avg_rpm || '',
              [`Average Fuel Rate (${fuelRateUnit})`]:
                event.average_fuel_rate || '',
              Duration: durationString,
              'Duration in secs': duration,
            });
          });
        }
      });
      if (rows.length > 0) {
        worksheets[key] = rows;
      }
    });
    return worksheets;
  }, [data, customerCode]);

  return (
    <Button
      disabled={disableDownload}
      size="small"
      sx={{
        marginLeft: '15px',
        fontSize: '12px',
        textTransform: 'none',
        border: disableDownload ? '1px solid #606161' : '1px solid #4A7BFA',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        borderRadius: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
      }}
      onClick={() => {
        shiftWiseEngineStartEndDataToExcel(
          shiftWiseData,
          'EngineHoursData',
          extraWorkbooks
        );
      }}
    >
      Download Shift Wise Engine Hours Data
    </Button>
  );
};

export default ExportShiftsToExcel;
