import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

// Custom No Data Component
export const NoDataComponent = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = useMemo(() => isDarkTheme(theme), [theme]);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        // color: enableDarkTheme ? '#fff' : '#000',
      }}
    >
      <Typography
        variant="h6"
        sx={{ color: enableDarkTheme ? '#fff' : '#000' }}
      >
        No Data Available
      </Typography>
    </Box>
  );
};

export default NoDataComponent;
