import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { FlexBox } from 'components/Containers/FlexBox';
import { ThemePalette } from 'mui.theme';
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { processMaintenanceData, mergeAttributesByTimeLabel } from './utils';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';

const PartsChart = ({
  enableDarkTheme,
  data,
  selectedPart,
  selectedFrequency,
  selectedAsset,
}: {
  enableDarkTheme: boolean;
  data: any[];
  selectedPart: string[];
  selectedFrequency: string;
  selectedAsset: string[];
}) => {
  const [partsAssets, setPartsAssets] = useState(selectedAsset);

  useEffect(() => {
    setPartsAssets(selectedAsset);
  }, [selectedAsset]);

  const chartData = data.filter(
    (item) =>
      selectedPart.includes(item.components_category) &&
      partsAssets.includes(item.asset_id) &&
      item.actions_performed === 'REPLACE'
  );

  const processedData = processMaintenanceData(
    chartData,
    selectedFrequency.toLowerCase()
  );

  const colors = [
    '#ff4560',
    '#008ffb',
    '#00e396',
    '#feb019',
    '#775dd0',
    '#f46036',
    '#ADD8E6',
    '#513B63',
    '#D301D7',
    '#EB5758',
    '#039161',
  ];

  const dataToPlot = mergeAttributesByTimeLabel(processedData);

  const labelName =
    processedData.categories.length > 1
      ? 'Aggegragted'
      : processedData.categories[0] ?? '';
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontSize: '14px',
            paddingLeft: '10px',
            marginBottom: '20px',
          }}
        >
          Frequently Replaced Count Trend
        </Typography>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '14px',
              paddingTop: '5px',
            }}
          >
            Showing For
          </Typography>
          <Select
            multiple
            labelId="parts-recommendation-asset-breakdown"
            value={partsAssets}
            renderValue={(selected) => {
              return `${selected?.length || 0} Asset(s) Selected`;
            }}
            onChange={(e) => {
              const selectedValues = e.target.value as string[];
              if (selectedValues.includes('select-all')) {
                const allSelected = partsAssets.length === selectedAsset.length;
                if (allSelected) {
                  setPartsAssets([]);
                } else {
                  setPartsAssets([...selectedAsset]);
                }
              } else if (selectedValues.length > 0) {
                setPartsAssets(selectedValues);
              }
            }}
            sx={{
              height: '30px',
              fontSize: '14px',
              marginLeft: '10px',
              marginRight: '20px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.cartesianGrid
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              '& .MuiSelect-icon': {
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              },
              '& fieldset': {
                borderColor: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
              ...(enableDarkTheme && {
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#2F445D',
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  fontSize: '14px',
                },
              },
            }}
          >
            <MenuItem
              key="select-all-option"
              value="select-all"
              sx={{
                fontSize: '14px',
                borderBottom: '1px solid',
                borderColor: enableDarkTheme ? '#2F445D' : '#E0E0E0',
              }}
            >
              <Checkbox
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                  '&.Mui-disabled': {
                    color: ThemePalette.typography.secondary,
                  },
                }}
                checked={
                  partsAssets.length === selectedAsset.length &&
                  selectedAsset.length > 0
                }
                indeterminate={
                  partsAssets.length > 0 &&
                  partsAssets.length < selectedAsset.length
                }
              />
              <ListItemText primary="Select All" />
            </MenuItem>
            {selectedAsset.map((asset, index) => (
              <MenuItem
                key={index}
                value={asset}
                sx={{
                  fontSize: '14px',
                }}
              >
                <Checkbox
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                    '&.Mui-disabled': {
                      color: ThemePalette.typography.secondary,
                    },
                  }}
                  checked={partsAssets.includes(asset)}
                />
                <ListItemText primary={asset} />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <FlexBox
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        {processedData.data.length === 0 ? (
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.wheat
                : ThemePalette.typography.black,
            }}
          >
            No data for selected filter
          </Typography>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={dataToPlot}
              margin={{ top: 15, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid
                strokeDasharray="3 3"
                vertical={false}
                horizontal={true}
                stroke={ThemePalette.dark.cartesianGrid}
              />
              <XAxis
                dataKey="timeLabel"
                label={{
                  position: 'insideBottom',
                  offset: -5,
                }}
                tick={{ fontSize: 11 }}
              />
              <YAxis tick={{ fontSize: 11 }} />
              <Tooltip
                cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
                content={<CustomTooltip type={selectedFrequency} />}
              />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ top: -10, left: 25, fontSize: '10px' }}
                formatter={(value) => capitalizeFirstLetter(value)}
              />
              <Line
                key={'value'}
                type="linear"
                dataKey={'value'}
                name={labelName}
                activeDot={{ r: 3 }}
                stroke={colors[0]}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </FlexBox>
    </Box>
  );
};

export default PartsChart;

const CustomTooltip = ({
  active,
  payload,
  type,
}: {
  active?: boolean;
  payload?: any;
  type: string;
}) => {
  if (active && payload && payload.length) {
    const { sortKey, timeLabel, ...rest } = payload[0].payload;
    const [year, month] = sortKey.split('-');
    const stringToDisplay = `${
      type === 'Monthly' ? formatMonthYear(sortKey) : `Week ${month} - ${year}`
    }`;

    return (
      <div
        style={{
          backgroundColor: 'rgba(217, 229, 247, 0.8)',
          border: '1px solid #ccc',
          padding: '5px 8px',
          borderRadius: '4px',
        }}
      >
        <p style={{ margin: 0, fontWeight: 'bold', color: '#000' }}>
          Date: {stringToDisplay}
        </p>
        {payload.map((entry: any, index: any) => (
          <p
            key={index}
            style={{ margin: '2px 0', color: entry.color, fontSize: '10px' }}
          >
            {`Count: ${entry.value}`}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

function formatMonthYear(dateStr: any) {
  const [year, month] = dateStr.split('-');
  const date = new Date(year, month - 1);
  return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
}
