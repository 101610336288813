import { Box } from '@mui/material';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import { FlexBox } from 'components/Containers/FlexBox';
import { ThemePalette } from 'mui.theme';
import useAppTheme from 'hooks/useAppTheme';
import useCustomerInfo from 'hooks/useCustomerInfo';
import { type FleetDeviceDetailsPerDay } from 'types/fuelAnalytics';
import { getFuelUsageDataUnit } from '../utils';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { useMemo } from 'react';

interface Props {
  selectedSource: string[];
  height?: string;
  data: FleetDeviceDetailsPerDay;
  sortBy?: string;
}

const FuelAssetItemChart = ({
  selectedSource,
  height = '230px',
  data,
}: Props) => {
  const { enableDarkTheme } = useAppTheme();
  const { unitSystem } = useCustomerInfo();
  const data1Unit = useMemo(() => {
    const str = getFuelUsageDataUnit(selectedSource[0], unitSystem);
    return str ?? '';
  }, [selectedSource]);
  const data2Unit = useMemo(() => {
    if (selectedSource.length < 2) return '';
    const str = getFuelUsageDataUnit(selectedSource[1], unitSystem);
    return str ?? '';
  }, [selectedSource]);
  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('DD MMM');
    return formattedDate;
  };

  const { date_stats: dateStats } = data;

  const formattedDateLoad: any[] = useMemo(() => {
    return Object.keys(dateStats).map((date) => ({
      date,
      ...dateStats[date],
      avg_fuel_rate: Number(dateStats[date].avg_fuel_rate),
      loaded_fuel_consumption: Number(dateStats[date].loaded_fuel_consumption),
      num_of_idle_events: Number(dateStats[date].num_of_idle_events),
      total_duration_of_idle_events: Number(
        +dateStats[date].total_duration_of_idle_events.toFixed(2)
      ),
      consumption: Number(dateStats[date].total_fuel_consumption),
      unloaded_fuel_consumption: Number(
        dateStats[date].unloaded_fuel_consumption
      ),
      co2emission: Number(dateStats[date].co2_emission),
    }));
  }, [dateStats]);

  const valid1Values = useMemo(() => {
    return formattedDateLoad
      .map((entry) =>
        Number(entry[selectedSource[0].toLowerCase().replace(/ /g, '_')])
      )
      .filter((value) => value > 0);
  }, [formattedDateLoad, selectedSource]);

  const valid2Values = useMemo(() => {
    if (selectedSource.length < 2) return [];
    return formattedDateLoad
      .map((entry) =>
        Number(entry[selectedSource[1].toLowerCase().replace(/ /g, '_')])
      )
      .filter((value) => value > 0);
  }, [formattedDateLoad, selectedSource]);

  const maxValue = Math.max(...valid1Values);
  const max2Value = Math.max(...valid2Values);
  formattedDateLoad.sort((a, b) => {
    const dateA = dayjs(a.date).unix();
    const dateB = dayjs(b.date).unix();
    return dateA - dateB;
  });

  return (
    <Box sx={{ paddingTop: '10px' }}>
      <FlexBox
        sx={{
          maxWidth: 'inherit',
          height,
          paddingTop: '20px',
          marginRight: '10px',
          paddingBottom: '10px',
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={700}
            height={300}
            data={formattedDateLoad}
            margin={{
              top: 20,
              right: 100,
              left: 5,
              bottom: 5,
            }}
            barSize={30}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={ThemePalette.dark.cartesianGrid}
              vertical={false}
            />
            <XAxis
              dataKey="date"
              tickFormatter={formatTimestamp}
              tick={{ fontSize: 10 }}
            />
            <YAxis
              yAxisId={'left'}
              tick={{ fontSize: 10 }}
              domain={[0, Math.ceil(maxValue * 1.1)]}
              label={{
                value: `${capitalizeFirstLetter(
                  selectedSource[0]?.split('_').join(' ')
                )} ${data1Unit}`,
                angle: 270,
                position: 'insideLeft',
                fontSize: 10,
                textAnchor: 'middle',
                dy: 50,
              }}
              name={`${capitalizeFirstLetter(
                selectedSource[0]?.split('_').join(' ')
              )} ${data1Unit}`}
            />
            {selectedSource?.length > 1 && (
              <YAxis
                yAxisId={'right'}
                orientation="right"
                tick={{ fontSize: 10 }}
                domain={[0, Math.ceil(max2Value * 1.1)]}
                label={{
                  value: `${capitalizeFirstLetter(
                    selectedSource[1]?.split('_').join(' ')
                  )} ${data2Unit}`,
                  angle: 270,
                  position: 'insideRight',
                  fontSize: 10,
                  textAnchor: 'middle',
                  dy: -50,
                }}
                name={`${capitalizeFirstLetter(
                  selectedSource[1]?.split('_').join(' ')
                )} ${data2Unit}`}
              />
            )}
            <Tooltip
              cursor={{ fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC' }}
              wrapperStyle={{ outline: 'none', fontSize: '11px' }}
              contentStyle={{
                backgroundColor: 'transparent',
              }}
              itemStyle={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
            <Bar
              yAxisId="left"
              name={`${capitalizeFirstLetter(
                selectedSource[0]?.split('_').join(' ')
              )} ${data1Unit}`}
              dataKey={selectedSource[0].toLowerCase().replace(/ /g, '_')}
              fill="#4A7BFA"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            {selectedSource?.length > 1 && (
              <Bar
                yAxisId="right"
                name={`${capitalizeFirstLetter(
                  selectedSource[1]?.split('_').join(' ')
                )} ${data1Unit}`}
                dataKey={selectedSource[1].toLowerCase().replace(/ /g, '_')}
                fill="#FA7B4A"
                activeBar={<Rectangle fill="lightblue" stroke="blue" />}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </FlexBox>
    </Box>
  );
};

export default FuelAssetItemChart;
