export function getFuelUsageDataUnit(type: string, unitSystem: string) {
  type = type.toLowerCase().replace(/ /g, '');
  if (
    type === 'consumption' ||
    type === 'loaded_fuel_consumption' ||
    type === 'unloaded_fuel_consumption'
  ) {
    return unitSystem === 'metric' ? 'L' : 'Gal';
  }
  if (type === 'co2_emission') {
    return unitSystem === 'metric' ? 'Kg' : 'Lb';
  }
  if (type === 'co2emission') {
    return unitSystem === 'metric' ? 'Kg' : 'Lb';
  }
  if (type === 'total_duration_of_idle_events') {
    return 'Hrs';
  }

  if (type === 'fuel') {
    return unitSystem === 'imperial' ? 'Gal' : 'L';
  }

  if (type === 'utilization') {
    return '%';
  }

  if (type === 'idlinghours') {
    return 'Hrs';
  }

  if (type === 'productivehours') {
    return 'Hrs';
  }
  if (type === 'avgfuelrate' || type === 'avg_fuel_rate') {
    return unitSystem === 'imperial' ? 'Gal/h' : 'L/h';
  }
  if (type === 'enginehours') {
    return 'Hrs';
  }
  if (type === 'productionrate') {
    return unitSystem === 'imperial' ? 'itons/h' : 'tons/h';
  }
}

export const getStartAndEndDateFromRange = (
  range: number,
  yearTillDate = false
) => {
  const currentDate = new Date();
  const endDate = new Date(new Date().setDate(currentDate.getDate() - 1)); // currentDate
  let startDate;
  if (yearTillDate) {
    startDate = `${endDate.getFullYear()}-01-01`;
  } else {
    if (range > 1) {
      startDate = new Date(
        new Date(endDate).setDate(endDate.getDate() - range + 1)
      )
        .toISOString()
        .slice(0, 10);
    } else {
      startDate = endDate.toISOString().slice(0, 10);
    }
  }
  return {
    endDate: endDate.toISOString().slice(0, 10),
    startDate,
    noOfDays: range,
  };
};

export const getDaysSinceStartOfYear = (): number => {
  const currentDate = new Date(); // Current date
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1); // Jan 1st of the current year

  const differenceInMilliseconds =
    currentDate.getTime() - startOfYear.getTime();

  const daysSinceStartOfYear = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  return daysSinceStartOfYear;
};
