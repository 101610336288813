import type { GroupedHistogramDataItem } from 'types/FleetOverview.types';

interface TransformedDataPoint {
  name: string;
  [key: string]: number | string;
}

type DateMap = Record<string, TransformedDataPoint>;

export function findHighestAndLowestCumulativeTotal(arr: any[]) {
  if (arr.length === 0) {
    return { highest: null, lowest: null };
  }

  const cumulativeTotals = arr.map((item) => item['Cumulative Total']);

  const highest = Math.max(...cumulativeTotals);
  const lowest = Math.min(...cumulativeTotals);

  return { highest, lowest };
}

export function transformData(
  data: GroupedHistogramDataItem[]
): TransformedDataPoint[] {
  const dateMap: DateMap = {};

  data.forEach((series) => {
    series.data.forEach((point) => {
      if (!dateMap[point.date]) {
        dateMap[point.date] = { name: point.date };
      }
      dateMap[point.date][series.name] = point.value ? Number(point.value) : 0;
    });
  });

  return Object.values(dateMap);
}

// comments in this function should not be
// removed as it helps with the debugging process
export function calculateAverage(arr: any[]) {
  if (arr.length === 0) return [];

  const result = [arr[0]];

  for (let i = 1; i < arr.length; i++) {
    const prevItem = result[i - 1];
    const newItem: any = { name: arr[i].name };

    for (const key in arr[i]) {
      if (key !== 'name') {
        if (key in prevItem) {
          const prevValue = Number(prevItem[key]);
          const currValue = Number(arr[i][key]);

          // Calculate average only if both values are valid numbers
          if (!isNaN(prevValue) && !isNaN(currValue)) {
            // Use explicit type assertions to tell TypeScript these are numbers
            const sum = prevValue + currValue;
            const avg = sum / 2;
            newItem[key] = Number(avg.toFixed(2));
          } else {
            // If one is NaN, use the other value if it's valid
            newItem[key] = !isNaN(prevValue)
              ? prevValue
              : !isNaN(currValue)
              ? currValue
              : null;
          }
        } else {
          // If key only exists in current item, just use its value
          newItem[key] = arr[i][key];
        }
      }
    }

    // Check for keys in prevItem that might not be in current item
    for (const key in prevItem) {
      if (key !== 'name' && !(key in newItem)) {
        // If key only exists in previous item, just use its value
        newItem[key] = prevItem[key];
      }
    }

    result.push(newItem);
  }

  return result;
}
