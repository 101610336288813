import { createSlice } from '@reduxjs/toolkit';
import type {
  FuelLoadedUnloadedAnalytics,
  FuelIdleEvents,
  FuelNoneIdleEvents,
  FuelConsumptionEngineHoursAnalytics,
  FuelConsumptionRangeAnalytics,
  HistoricalAnalysisCard,
  FleetDeviceDetails,
  AirFilterData,
  FuelAnalyticsOverview,
  FleetDeviceDetailsPerDay,
} from 'types/fuelAnalytics';
import { type average_fuel_key_to_use } from 'utils/fuelAnalytics';

export interface FuelAnalyticsSpecificLoadingStatesInterface {
  loadingFuelAnalyticsWRTDriverEvents: boolean;
  isLoadingFleetSummary: boolean;
  isLoadingFuelTreemapData: boolean;
  isLoadingFuelConsumptionPerDayData: boolean;
}

export interface FuelAnalyticsWRTDriverEventResponseInterface {
  asset_type_group: string;
  fuel_used: number;
  is_idle: boolean;
  percentage: number;
  diff: number;
}

export interface FuelConsumptionWRTDriverEventsInterface {
  key: string;
  name: string;
  average_fuel_key_to_use: average_fuel_key_to_use;
  value_present?: boolean;
  data: Record<string, FuelAnalyticsWRTDriverEventResponseInterface>;
}

export interface FuelAnalyticsState {
  fuelLoadedUnloadedAnalytics: FuelLoadedUnloadedAnalytics;
  fuelIdleEvents: FuelIdleEvents;
  fuelNoneIdleEvents: FuelNoneIdleEvents;
  fuelConsumptionEngineHoursAnalytics: FuelConsumptionEngineHoursAnalytics;
  fuelConsumptionRangeAnalytics: FuelConsumptionRangeAnalytics;
  historicalAnalysisCard: HistoricalAnalysisCard;
  fleetDeviceDetails: FleetDeviceDetails[];
  fleetDeviceDetailsPerDayBasis: FleetDeviceDetailsPerDay[];
  airFilterData: AirFilterData[];
  isLoading: boolean;
  loadingStates: FuelAnalyticsSpecificLoadingStatesInterface;
  fuelAnalyticsWRTDriverEvents: FuelConsumptionWRTDriverEventsInterface[];
  fuelAverageAnalytics: any;
  fuel_usage_summary: Record<string, Record<string, number>>;
  data: FuelAnalyticsOverview;
}

const initialState: FuelAnalyticsState = {
  fuelLoadedUnloadedAnalytics: {
    data: [],
    sortedBottom5LoadedFuelData: [],
    sortedBottom5UnLoadedFuelData: [],
    sortedTop5LoadedFuelData: [],
    sortedTop5UnLoadedFuelData: [],
    loadedFuelData: [],
    unloadedFuelData: [],
    loadedAverage: 0,
    unLoadedAverage: 0,
    engineAverages: [],
  },
  fuelIdleEvents: {
    data: [],
    sortedBottom5IdlingEventData: [],
    sortedTop5IdlingEventData: [],
    idlingEventAverage: 0,
  },
  fuelNoneIdleEvents: {
    data: [],
    sortedTop5NonEventIdlingData: [],
    sortedBottom5NonEventIdlingData: [],
    nonIdlingAverage: 0,
  },
  fuelConsumptionEngineHoursAnalytics: {
    data: [],
    sortedBottom5FuelConsumptionEngineHoursData: [],
    sortedTop5FuelConsumptionEngineHoursData: [],
    fuelConsumptionAverage: 0,
  },
  fuelConsumptionRangeAnalytics: [],
  historicalAnalysisCard: {
    totalFuelConsumed: 0,
    fuelConsumedIdling: 0,
    co2Emissions: 0,
    consumptioinIdex: 0,
  },
  fleetDeviceDetails: [],
  fleetDeviceDetailsPerDayBasis: [],
  airFilterData: [],
  isLoading: false,
  loadingStates: {
    loadingFuelAnalyticsWRTDriverEvents: false,
    isLoadingFleetSummary: false,
    isLoadingFuelTreemapData: false,
    isLoadingFuelConsumptionPerDayData: false,
  },
  fuelAnalyticsWRTDriverEvents: [],
  fuelAverageAnalytics: [],
  fuel_usage_summary: {},
  data: {
    allFleet: {
      summary: {
        fleet_avg_fuel_rate: 0,
        fleet_total_fuel_consumption_l: 0,
        fleet_total_diesel_consumption_l: 0,
        fleet_fuel_eff_lost: 0,
        fleet_CO2_used: 0,
        fleet_fuel_cost_incurred: 0,
        hauler_avg_fuel_rate: 0,
        hauler_total_fuel_consumption_l: 0,
        hauler_total_diesel_consumption_l: 0,
        hauler_fuel_eff_lost: 0,
        hauler_CO2_used: 0,
        hauler_fuel_cost_incurred: 0,
        loader_avg_fuel_rate: 0,
        loader_total_fuel_consumption_l: 0,
        loader_total_diesel_consumption_l: 0,
        loader_fuel_eff_lost: 0,
        loader_CO2_used: 0,
        loader_fuel_cost_incurred: 0,
        fleet_avg_fuel_rate_diff: 0,
        fleet_total_fuel_consumption_l_diff: 0,
        fleet_total_diesel_consumption_l_diff: 0,
        fleet_fuel_eff_lost_diff: 0,
        fleet_CO2_used_diff: 0,
        fleet_fuel_cost_incurred_diff: 0,
        hauler_avg_fuel_rate_diff: 0,
        hauler_total_fuel_consumption_l_diff: 0,
        hauler_total_diesel_consumption_l_diff: 0,
        hauler_fuel_eff_lost_diff: 0,
        hauler_CO2_used_diff: 0,
        hauler_fuel_cost_incurred_diff: 0,
        loader_avg_fuel_rate_diff: 0,
        loader_total_fuel_consumption_l_diff: 0,
        loader_total_diesel_consumption_l_diff: 0,
        loader_fuel_eff_lost_diff: 0,
        loader_CO2_used_diff: 0,
        loader_fuel_cost_incurred_diff: 0,
        date_range: 0,
      },
      summaryChart: {
        fleet: [],
        hauler: [],
        loader: [],
      },
    },
  },
};

export const fuelAnalyticsSlice = createSlice({
  name: 'fuelAnalytics',
  initialState,
  reducers: {
    setFleetSummary(state, { payload }) {
      state.data.allFleet.summary = {
        ...state.data.allFleet.summary,
        ...payload.summary,
      };
      // state.data.allFleet.score = {
      //   ...state.data.allFleet.score,
      //   ...(payload.score ? payload.score : { date: '', prod_score: 0 }),
      // };
      // state.data.allFleet.summaryChart = payload.chartData;
    },
    setFleetSummaryChart(state, { payload }) {
      state.data.allFleet.summaryChart = payload;
    },
    setFuelAnalytics(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearFuelAnalytics(state) {
      return initialState;
    },
    setFuelAnalyticsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setSpecificFuelAnalyticsLoadingState(
      state,
      {
        payload,
      }: {
        // payload can have one of the keys of FuelAnalyticsSpecificLoadingStatesInterface
        payload: Partial<FuelAnalyticsSpecificLoadingStatesInterface>;
      }
    ) {
      state.loadingStates = {
        ...state.loadingStates,
        ...payload,
      };
    },
    setFuelAnalyticsWRTDriverEvents(state, { payload }) {
      state.fuelAnalyticsWRTDriverEvents = payload;
    },
    setFleetDeviceDetails(state, { payload }) {
      state.fleetDeviceDetails = payload;
    },
    setFleetDeviceDetailsPerDay(state, { payload }) {
      state.fleetDeviceDetailsPerDayBasis = payload;
    },
  },
});

export const {
  setFuelAnalytics,
  clearFuelAnalytics,
  setFuelAnalyticsLoading,
  setSpecificFuelAnalyticsLoadingState,
  setFuelAnalyticsWRTDriverEvents,
  setFleetSummary,
  setFleetDeviceDetails,
  setFleetDeviceDetailsPerDay,
  setFleetSummaryChart,
} = fuelAnalyticsSlice.actions;
export default fuelAnalyticsSlice.reducer;
