import { Grid } from '@mui/material';
import { CustomTabPanel } from '../CustomPanel';
import DateRangeDiv from '../HeaderMenu/DateRangeDiv';
import { ThemePalette } from 'mui.theme';
import HaulerProductionDetails from './component/LoaderProductionDetails';
import type {
  productionLoadingStates,
  fleetData,
  productionAsset,
} from 'types/production.types';
import LoaderAnalyticsWrapper from './component/LoaderAnalyticsWrapper';
import AverageCycleTime from '../AllFleet/components/AverageCycleTime';
import {
  getHaulerSummary,
  getProductionAssetsByTypeMapping,
} from '../AllFleet/utils';
import FleetProductionChart from '../AllFleet/components/FleetProductionChart';
import AssetView from './component/LoaderAssetView';
import Suggestions from '../components/Suggestions/Suggestions';
import HaulerFuelConsumption from './component/FuelConsumption';
import { useMemo, useState } from 'react';

interface Props {
  value: number;
  enableDarkTheme: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  unitSystem: string;
  customerCode: string;
  loadingStates: productionLoadingStates;
  assetOverview: any;
  data: fleetData;
  aggregation: string;
  setAggregation: any;
  isPrinting: boolean;
  setDetailsVal: any;
  setAnalyticsData: any;
  setHoursBreakDown: any;
  setUserSelections: any;
  setSuggestionData: any;
}

const Loader = ({
  value,
  enableDarkTheme,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  unitSystem,
  customerCode,
  loadingStates,
  data,
  assetOverview,
  aggregation,
  setAggregation,
  isPrinting,
  setDetailsVal,
  setAnalyticsData,
  setHoursBreakDown,
  setUserSelections,
  setSuggestionData,
}: Props) => {
  console.log('🚀 ~ data:', data);
  const haulersSummary: any = getHaulerSummary(data.summary);

  const haulerAssets: productionAsset[] = getProductionAssetsByTypeMapping(
    data.assets,
    'Loader'
  );

  const haulerConsumptionData = {
    loaded: data.summary.haul_truck_loaded_trip_fuel_consumption_l,
    unloaded: data.summary.haul_truck_unloaded_trip_fuel_consumption_l,
    loading: data.summary.haul_truck_loading_fuel_consumption_l,
    unloading: data.summary.haul_truck_unloading_fuel_consumption_l,
  };

  // const [rollingDailyAverage, setRollingDailyAverage] = useState<number>(0);

  const currentDailyAverage = useMemo(() => {
    // if the chartData is empty, return 0, else extract seven day working minutes and return the average
    if (data?.fleetPeriodSummary?.length > 0) {
      const sevenDayDataObject =
        data?.fleetPeriodSummary?.find(
          (dailyProductionObject) =>
            dailyProductionObject?.time_range === 'hauler_last_7_days'
        )?.daily_production ?? {};

      const sevenDayData = Object.values(sevenDayDataObject ?? {}).map(
        (value) => +value
      );

      const sevenDayTotalWorkingMinutes = sevenDayData.reduce(
        (acc: number, curr: number) => acc + curr,
        0
      );

      return sevenDayTotalWorkingMinutes / sevenDayData?.length;
    } else {
      return 0;
    }
  }, [data?.fleetPeriodSummary]);

  return (
    <>
      <CustomTabPanel value={value} index={2}>
        <div>
          {/* <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <HaulerProductionDetails
              enableDarkTheme={enableDarkTheme}
              isLoading={loadingStates.isLoadingFleetDetails}
              unitSystem={unitSystem}
              data={data.totalFleetDetails}
              // rollingDailyAverage={rollingDailyAverage}
              currentDailyAverage={currentDailyAverage ?? 0}
              isLoadingFleetPeriodSummary={
                loadingStates?.isLoadingFleetPeriodSummary
              }
              setDetailsVal={setDetailsVal}
            />

            <FleetProductionChart
              enableDarkTheme={enableDarkTheme}
              data={data.fleetPeriodSummary}
              isLoading={loadingStates.isLoadingFleetPeriodSummary}
              unitSystem={unitSystem}
              title="Daily Haulers Production"
              // setRollingDailyAverage={setRollingDailyAverage}
              setUserSelections={setUserSelections}
            />
          </Grid> */}

          <DateRangeDiv
            enableDarkTheme={enableDarkTheme}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleDateChange={handleDateChange}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            yesterday={yesterday}
            ignoreYtd={value === 2}
            aggregation={aggregation}
            setAggregation={setAggregation}
          />

          <LoaderAnalyticsWrapper
            enableDarkTheme={enableDarkTheme}
            data={data.summary}
            isLoading={loadingStates.isLoadingFleetSummary}
            unitSystem={unitSystem}
            assetOverview={assetOverview}
            scoreData={data.score}
            chartData={data.summaryChart}
            aggregation={aggregation}
            isSelected={isSelected}
            customerCode={customerCode}
          />

          <AssetView
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            customerCode={customerCode}
            isLoading={loadingStates.isLoadingFleetAssets}
            data={haulerAssets}
            isSelected={isSelected}
            startDate={startDate}
            endDate={endDate}
            isPrinting={isPrinting}
          />
        </div>
      </CustomTabPanel>
    </>
  );
};

export default Loader;
