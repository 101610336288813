import { Box, Grid, Typography } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { SVG } from 'components/Asset/SVG';
import useAppTheme from 'hooks/useAppTheme';
import { ThemePalette } from 'mui.theme';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { truncateTo2dp } from 'utils/helpers/general';
import { type FleetDeviceDetailsPerDay } from 'types/fuelAnalytics';
import useCustomerInfo from 'hooks/useCustomerInfo';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';

interface Props {
  data: FleetDeviceDetailsPerDay;
}

const FuelAssetHeader = ({ data }: Props) => {
  const { enableDarkTheme } = useAppTheme();
  const { unitSystem } = useCustomerInfo();
  return (
    <Box
      sx={{
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <SVG
        name={mapVehiclesAssets(data.asset_type)}
        style={{
          fill: '#E2C171',
          height: '30px',
          width: '60px',
          paddingRight: '10px',
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '13px',
              }}
            >
              {data.bumper_id}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PowerSettingsNewIcon
                sx={{
                  color: '#00C853',
                  marginRight: '8px',
                  fontSize: '12px',
                }}
              />
              <Typography
                sx={{
                  fontSize: '10px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {data.make} - {data.model}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              Avg Fuel Rate
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textAlign: 'center',
              }}
            >
              {formatNumberToShortScale(
                truncateTo2dp(Number(data?.avg_fuel_rate)),
                2
              )}
              {unitSystem === 'metric' ? 'L/Hr' : 'G/Hr'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              CO2 Emmission
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textAlign: 'center',
              }}
            >
              {formatNumberToShortScale(
                truncateTo2dp(Number(data?.co2_emission)),
                2
              )}
              {unitSystem ? 'Kg' : 'Lb'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              Total Fuel Consumed
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textAlign: 'center',
              }}
            >
              {formatNumberToShortScale(
                truncateTo2dp(Number(data?.total_fuel_consumption)),
                2
              )}
              {unitSystem ? 'L' : 'G'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                fontSize: '11px',
                textAlign: 'center',
              }}
            >
              No. of Idle Events
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textAlign: 'center',
              }}
            >
              {data?.num_of_idle_events}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FuelAssetHeader;
