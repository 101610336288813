import UtilizationAPI from 'api/utilization.api';
import {
  setFleetSummary,
  setIsLoading,
  setFleetPeriodSummary,
  setTotalFleetAssets,
  setFleetDetails,
  resetState,
} from 'store/utilization.slice';

import { BaseHandler } from './base.handler';

export default class UtilizationHandler extends BaseHandler {
  private readonly api: UtilizationAPI;

  constructor() {
    super();

    this.api = new UtilizationAPI();
  }

  async get(
    range: string,
    startDate: string,
    endDate: string,
    shouldSendDate: boolean,
    initialRun: boolean,
    assetType: string,
    aggregation: string,
    shiftWiseAnalysis: boolean,
    shiftNumber: number,
    utilizationType: string
  ): Promise<any> {
    const cacheKey = `utilization_cache_${range}_${aggregation}_${shiftWiseAnalysis}_${shiftNumber}_${utilizationType}`;
    const cachedData = sessionStorage.getItem(cacheKey);

    // If cached data exists and range is not custom, parse it and return
    if (cachedData && range !== 'Custom') {
      const data = JSON.parse(cachedData);
      this.dispatch(setFleetSummary(data.fleetSummary));
      this.dispatch(setTotalFleetAssets(data.fleetAssets?.assets));
      if (initialRun) {
        this.dispatch(setFleetDetails(data.fleetDetails));
        this.dispatch(setFleetPeriodSummary(data.fleetPeriod));
      }
      return data;
    }

    let stateChanges: any = {
      isLoadingFleetAssets: true,
      isLoadingFleetSummary: true,
      isLoadingSuggestions: true,
    };

    if (initialRun) {
      stateChanges = {
        ...stateChanges,
        isLoadingFleetDetails: true,
        isLoadingFleetPeriodSummary: true,
      };
    }

    this.dispatch(setIsLoading(stateChanges));

    try {
      const fleetSummaryPromise = this.api.getTotalFleetSummary(
        range,
        startDate,
        endDate,
        shouldSendDate,
        aggregation,
        shiftWiseAnalysis,
        shiftNumber,
        utilizationType
      );

      const fleetAssetsPromise = this.api.getTotalFleet(
        range,
        startDate,
        endDate,
        shouldSendDate,
        aggregation,
        shiftWiseAnalysis,
        shiftNumber,
        utilizationType
      );

      let fleetDetailsPromise, fleetPeriodPromise;
      if (initialRun) {
        fleetPeriodPromise = this.api.getFleetPeriodSummary();
        fleetDetailsPromise = this.api.getTotalFleetUtilization();
      }

      if (initialRun) {
        fleetDetailsPromise
          ?.then((totalFleetDetails) => {
            this.dispatch(setFleetDetails(totalFleetDetails.data));
            this.dispatch(
              setIsLoading({
                isLoadingFleetDetails: false,
              })
            );
          })
          .catch(() => {
            this.handleError('Error fetching fleet details');
            this.dispatch(
              setIsLoading({
                isLoadingFleetDetails: false,
              })
            );
          });

        fleetPeriodPromise
          ?.then((fleetPeriod) => {
            this.dispatch(setFleetPeriodSummary(fleetPeriod.data));
            this.dispatch(
              setIsLoading({
                isLoadingFleetPeriodSummary: false,
              })
            );
          })
          .catch(() => {
            this.handleError('Error fetching fleet period summary');
            this.dispatch(
              setIsLoading({
                isLoadingFleetPeriodSummary: false,
              })
            );
          });
      }

      const fleetSummary = await fleetSummaryPromise;
      this.dispatch(setFleetSummary(fleetSummary));
      this.dispatch(
        setIsLoading({
          isLoadingFleetSummary: false,
        })
      );

      const fleetAssets = await fleetAssetsPromise;
      this.dispatch(setTotalFleetAssets(fleetAssets?.assets));
      this.dispatch(
        setIsLoading({
          isLoadingFleetAssets: false,
        })
      );

      // Store the results in session storage
      const dataToCache = {
        fleetSummary,
        fleetAssets,
        fleetDetails: initialRun ? fleetDetailsPromise : null,
        fleetPeriod: initialRun ? fleetPeriodPromise : null,
      };

      try {
        sessionStorage.setItem(cacheKey, JSON.stringify(dataToCache));
      } catch (e) {
        console.log('Error setting cache for utilization');
      }

      return dataToCache; // return the cached data
    } catch (_) {
      this.handleError('An error occurred while fetching utilization details');
      this.dispatch(
        resetState({
          isLoadingFleetAssets: false,
          isLoadingFleetSummary: false,
          isLoadingSuggestions: false,
          isLoadingFleetDetails: false,
          isLoadingFleetPeriodSummary: false,
        })
      );
    }
    return {};
  }
}
