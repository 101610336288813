import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import { formatDateShortener } from 'utils/helpers/dateTime';
import FuelAnalyticsAPI from 'api/fuelAnalytics.api';
import { type Asset } from 'types/asset.types';
import TireProfileAPI from 'api/tire-pressure';
import GeoAPI from 'api/geo.api';
import { setDeviceTireData } from 'store/tireProfile.slice';
import DriverBehaviourAPI from 'api/driver.behaviour.api';

export default class VehicleStatusHandler extends BaseHandler {
  private readonly api: FuelAnalyticsAPI;
  private readonly tireApi: TireProfileAPI;
  private readonly geoApi: GeoAPI;
  private readonly driverBehaviourApi: DriverBehaviourAPI;
  private readonly fuelAnalyticsApi: FuelAnalyticsAPI;

  constructor() {
    super();
    this.api = new FuelAnalyticsAPI();
    this.tireApi = new TireProfileAPI();
    this.geoApi = new GeoAPI();
    this.driverBehaviourApi = new DriverBehaviourAPI();
    this.fuelAnalyticsApi = new FuelAnalyticsAPI();
  }

  async getHealthData(selectedAsset: Asset): Promise<
    | {
        tireData: any;
        // fuelEfficiency: any;
        braking: any;
        overspeed: any;
        acceleration: any;
        gearSkip: any;
        idleFuel: any;
        underspeed: any;
        incorrectGearToSpeed: any;
        incorrectGearDuringDirection: any;
        airFilters: any;
      }
    | undefined
  > {
    if (!selectedAsset) {
      return undefined;
    }
    this.dispatch(updateLoadingState(true));
    try {
      const daysAgo = (days: number) =>
        formatDateShortener(
          new Date(new Date().getTime() - days * 24 * 60 * 60 * 1000)
        );
      // const fuelType =
      //   selectedAsset.assetType === 'haul_truck'
      //     ? 'getFuelEfficiency'
      //     : 'getFuelConsumptionEngineHours';
      const [
        tireData,
        // fuelEfficiency,
        idleFuel,
        braking,
        overspeed,
        acceleration,
        gearSkip,
        underspeed,
        incorrectGearToSpeed,
        incorrectGearDuringDirection,
        airFilters,
      ] = await Promise.allSettled([
        this.tireApi.getAssetTireData(
          selectedAsset.device.deviceId,
          'Last 7 Days',
          selectedAsset.assetType
        ),
        // (this.api as any)[fuelType](
        //   [{ make: selectedAsset.make, model: selectedAsset.model }],
        //   daysAgo(7),
        //   daysAgo(1)
        // ),
        this.api.getFuelIdleEvents(
          [{ make: selectedAsset.make, model: selectedAsset.model }],
          daysAgo(7),
          daysAgo(1)
        ),
        this.driverBehaviourApi.getAll({ event: 'braking', range: '1D' }),
        this.driverBehaviourApi.getAll({ event: 'overspeed', range: '1D' }),
        this.driverBehaviourApi.getAll({ event: 'acceleration', range: '1D' }),
        this.driverBehaviourApi.getAll({ event: 'gear_skip', range: '1D' }),
        this.driverBehaviourApi.getAll({ event: 'underspeed', range: '1D' }),
        this.driverBehaviourApi.getAll({
          event: 'incorrect_gear',
          range: '1D',
        }),
        this.driverBehaviourApi.getAll({
          event: 'incorrect_gear_shift',
          range: '1D',
        }),
        this.fuelAnalyticsApi.getAirFilters([selectedAsset.device.deviceId]),
      ]);

      this.dispatch(
        setDeviceTireData({
          device: selectedAsset.device.deviceId,
          data: (tireData as any).value,
        })
      );

      this.handleSuccess();
      return {
        tireData: (tireData as any).value,
        // fuelEfficiency: (fuelEfficiency as any).value,
        braking: (braking as any).value,
        overspeed: (overspeed as any).value,
        acceleration: (acceleration as any).value,
        gearSkip: (gearSkip as any).value,
        idleFuel: (idleFuel as any).value,
        underspeed: (underspeed as any).value,
        incorrectGearToSpeed: (incorrectGearToSpeed as any).value,
        incorrectGearDuringDirection: (incorrectGearDuringDirection as any)
          .value,
        airFilters: (airFilters as any).value,
      };
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the fuel analytics data: ' + msg
      );
    }
  }
}
