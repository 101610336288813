import { type Dispatch } from 'react';
import {
  LayoutSwitchDiv,
  LayoutSwitchWrapper,
  SVGDiv,
} from './FilterBar.styled';
import { ThemePalette } from 'mui.theme';

interface Props {
  setView: Dispatch<string>;
  view: string;
  enableDarkTheme: boolean;
  isNonGpsClient: boolean | null;
  setSelectedAsset?: any;
}

const SwitchView = ({
  setView,
  view,
  enableDarkTheme,
  isNonGpsClient,
  setSelectedAsset,
}: Props) => {
  const fillColor = (fill: string) =>
    fill === view
      ? enableDarkTheme
        ? ThemePalette.typography.white
        : ThemePalette.typography.secondary
      : enableDarkTheme
      ? ThemePalette.typography.cornflowerBlue
      : '#1049E5';

  return (
    <LayoutSwitchWrapper>
      <p
        style={{
          color: enableDarkTheme
            ? ThemePalette.typography.lightGrey
            : ThemePalette.typography.cyan,
        }}
      >
        Current Layout
      </p>
      <LayoutSwitchDiv
        style={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.cartesianGrid
            : ThemePalette.light.cartesianGrid,
        }}
      >
        {!isNonGpsClient && (
          <SVGDiv
            name="viewMapIcon"
            fill={fillColor('map')}
            onClick={() => {
              setView('map');
              setSelectedAsset(null);
            }}
          />
        )}
        <SVGDiv
          name="viewTableIcon"
          fill={fillColor('list')}
          onClick={() => {
            setView('list');
            setSelectedAsset(null);
          }}
        />
        <SVGDiv
          name="viewGridIcon"
          fill={fillColor('card')}
          onClick={() => {
            setView('card');
            setSelectedAsset(null);
          }}
        />
      </LayoutSwitchDiv>
    </LayoutSwitchWrapper>
  );
};

export default SwitchView;
