import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Box, Grid } from '@mui/material';
import { Base } from 'components/Containers/BaseContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import { ThemePalette } from 'mui.theme';
import { filterObjectByKeys } from '../utils';
import { truncateTo2dp } from 'utils/helpers/general';

type AssetData = Record<
  string,
  Record<string, { sumTotalCost: number; count: number; sumDownTime: number }>
>;

type FormattedData = Array<{
  name: string;
  sumTotalCost: number;
  count: number;
  sumDownTime: number;
}>;

const MaintenanceComparison = ({
  enableDarkTheme,
  data,
  selectedCell,
}: {
  enableDarkTheme: boolean;
  data: any;
  selectedCell: string[];
}) => {
  let assetData = filterObjectByKeys(data.maintenanceCounter, selectedCell);

  if (!assetData) {
    assetData = {};
  }

  const formatAssetData = (assetData: AssetData): FormattedData => {
    return Object.values(assetData)
      .flatMap((category) =>
        Object.entries(category)
          .filter(([name]) => ['PM', 'CM'].includes(name))
          .map(([name, value]) => ({ name, ...value }))
      )
      .reduce<FormattedData>((acc, item) => {
        const existing = acc.find((el) => el.name === item.name);

        if (existing) {
          existing.sumTotalCost += item.sumTotalCost || 0;
          existing.count += item.count || 0;
          existing.sumDownTime += (item.sumDownTime || 0) / (1000 * 60 * 60);
        } else {
          acc.push({
            name: item.name,
            sumTotalCost: item.sumTotalCost || 0,
            count: item.count || 0,
            sumDownTime: (item.sumDownTime || 0) / (1000 * 60 * 60),
          });
        }

        return acc;
      }, []);
  };
  const chartData = formatAssetData(assetData);

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.toolBarBackground
            : ThemePalette.light.toolBarBackground,
          padding: '20px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/** Maintenance cost */}
            <FlexBox
              sx={{
                maxWidth: 'inherit',
                height: '300px',
                paddingTop: '20px',
                marginRight: '10px',
                paddingBottom: '10px',
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  barSize={50}
                  width={700}
                  height={300}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip
                    cursor={{
                      fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC',
                    }}
                    content={<CustomCostTooltip />}
                  />
                  <Legend />
                  <Bar
                    dataKey="sumTotalCost"
                    fill="#feb019"
                    name="Maintenance Cost"
                  />
                </BarChart>
              </ResponsiveContainer>
            </FlexBox>
            {/** Maintenance count */}
            <FlexBox
              sx={{
                maxWidth: 'inherit',
                height: '300px',
                paddingTop: '20px',
                marginRight: '10px',
                paddingBottom: '10px',
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  barSize={50}
                  width={700}
                  height={300}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip
                    cursor={{
                      fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC',
                    }}
                    content={<CustomCountTooltip />}
                  />
                  <Legend />
                  <Bar
                    dataKey="count"
                    fill="#008ffb"
                    name="Maintenance Count"
                  />
                </BarChart>
              </ResponsiveContainer>
            </FlexBox>

            {/** Downtime Hours */}
            <FlexBox
              sx={{
                maxWidth: 'inherit',
                height: '300px',
                paddingTop: '20px',
                marginRight: '10px',
                paddingBottom: '10px',
              }}
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  barSize={50}
                  width={700}
                  height={300}
                >
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip
                    cursor={{
                      fill: enableDarkTheme ? '#1B2B4A' : '#CCCCCC',
                    }}
                    content={<CustomDownTimeTooltip />}
                  />
                  <Legend />
                  <Bar
                    dataKey="sumDownTime"
                    fill="#775dd0"
                    name="Down Time Hours"
                  />
                </BarChart>
              </ResponsiveContainer>
            </FlexBox>
          </Grid>
        </Grid>
      </Box>
    </Base>
  );
};

export default MaintenanceComparison;

const CustomDownTimeTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ background: '#ffffff', padding: 10, border: '1px solid #ccc' }}
      >
        <p>{`Category: ${payload[0].payload.name}`}</p>
        <p>{`Down Time Hours: ${payload[0].payload.sumDownTime} hrs`}</p>
      </div>
    );
  }
  return null;
};

const CustomCostTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ background: '#ffffff', padding: 10, border: '1px solid #ccc' }}
      >
        <p>{`Category: ${payload[0].payload.name}`}</p>
        <p>{`Maintenance Cost: $${truncateTo2dp(
          payload[0].payload.sumTotalCost
        )}`}</p>
      </div>
    );
  }
  return null;
};

const CustomCountTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ background: '#ffffff', padding: 10, border: '1px solid #ccc' }}
      >
        <p>{`Category: ${payload[0].payload.name}`}</p>
        <p>{`Maintenance Count: ${payload[0].payload.count}`}</p>
      </div>
    );
  }
  return null;
};
