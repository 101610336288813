import React, { useState, useEffect } from 'react';
import { ThemePalette } from 'mui.theme';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  InputBase,
  IconButton,
  Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { getUniqueComponentCounts } from './utils';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.7)',
  marginLeft: '10px',
  flex: 1,
  '& .MuiInputBase-input': {
    fontSize: 16,
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 0.5)',
      opacity: 1,
    },
  },
}));

const SearchButton = styled(IconButton)(({ theme }) => ({
  padding: 10,
  color: 'rgba(255, 255, 255, 0.7)',
}));

const SearchContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(30, 41, 59, 0.8)',
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const PartsTable = ({
  enableDarkTheme,
  data,
  healthData,
  selectedPart,
  selectedAsset,
  metadata,
}: {
  enableDarkTheme: boolean;
  data: any[];
  healthData: any;
  selectedPart: string[];
  selectedAsset: string[];
  metadata: any[];
}) => {
  const [sortType, setSortType] = useState('Historical Usage');
  const [searchTerm, setSearchTerm] = useState('');
  const [eolThreshold, setEolThreshold] = useState('1M');

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
  };

  const formatCategoryText = (text: string) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const replacementData = data.filter(
    (item) =>
      item.actions_performed === 'REPLACE' &&
      selectedAsset.includes(item.asset_id) &&
      selectedPart.includes(item.components_category)
  );

  const catgoryData: any[] = getUniqueComponentCounts(replacementData);

  const filteredHealhData = [...healthData].filter(
    (item) =>
      selectedAsset.includes(item.bumper_id) &&
      selectedPart.includes(item.component)
  );

  function getEolValue(eol: string) {
    if (eol === '1M') {
      return 30;
    }
    if (eol === '3M') {
      return 90;
    }
    if (eol === '6M') {
      return 180;
    }
    if (eol === '1Y') {
      return 366;
    }
    return 30;
  }

  function getSortKey(key: string) {
    if (key === 'Recommended Stock Level') {
      return 'eolThreshold';
    }
    return 'count';
  }

  const thresholdValue = getEolValue(eolThreshold);

  const processedData = [...catgoryData]
    .map((item) => {
      const [primaryComponent, subComponent] = item.category.split(' - ');
      const itemHealthAppearance = [...filteredHealhData].filter(
        (hd) =>
          hd.component === primaryComponent &&
          hd.sub_component === subComponent &&
          hd.days_to_eol <= thresholdValue
      ).length;

      const failureMode = metadata.filter(
        (item) =>
          item.component === primaryComponent &&
          item.sub_component === subComponent
      );

      return {
        ...item,
        eolThreshold: itemHealthAppearance,
        category: formatCategoryText(item.category),
        failureMode: failureMode[0]?.failure_mode ?? '--',
      };
    })
    .filter((item) =>
      searchTerm
        ? item.category.toLowerCase().includes(searchTerm.toLowerCase())
        : true
    )
    .sort((a, b) => b[getSortKey(sortType)] - a[getSortKey(sortType)]);

  return (
    <Box
      sx={{
        marginTop: '20px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.toolBarBackground,
        borderRadius: '5px',
        paddingTop: '10px',
        paddingBottom: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '10px',
          marginBottom: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '14px',
              marginTop: '8px',
              marginRight: '10px',
            }}
          >
            Parts Stock Recommendations
          </Typography>
          <SearchContainer elevation={0}>
            <StyledInputBase
              placeholder="Search for a part"
              inputProps={{ 'aria-label': 'search for a part' }}
              value={searchTerm}
              onChange={handleChange}
            />
            <SearchButton type="button" aria-label="search">
              <SearchIcon />
            </SearchButton>
          </SearchContainer>
        </Box>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '14px',
              marginTop: '8px',
              marginRight: '10px',
            }}
          >
            Threshold
          </Typography>
          <Select
            labelId="parts-recommendation-threshold-days"
            value={eolThreshold}
            onChange={(e: any) => {
              setEolThreshold(e.target.value);
            }}
            sx={{
              height: '40px',
              fontSize: '14px',
              marginLeft: '10px',
              marginRight: '20px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.cartesianGrid
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              '& .MuiSelect-icon': {
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              },
              '& fieldset': {
                borderColor: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
              ...(enableDarkTheme && {
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#2F445D',
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  fontSize: '14px',
                },
              },
            }}
          >
            {['1M', '3M', '6M', '1Y'].map((type, index) => (
              <MenuItem
                key={index}
                value={type}
                sx={{
                  fontSize: '14px',
                }}
              >
                {type}
              </MenuItem>
            ))}
          </Select>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '14px',
              marginTop: '8px',
            }}
          >
            Sort By
          </Typography>
          <Select
            labelId="parts-recommendation-sub-components-sort"
            value={sortType}
            onChange={(e: any) => {
              setSortType(e.target.value);
            }}
            sx={{
              height: '40px',
              fontSize: '14px',
              marginLeft: '10px',
              marginRight: '20px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.cartesianGrid
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              '& .MuiSelect-icon': {
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              },
              '& fieldset': {
                borderColor: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
              ...(enableDarkTheme && {
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#2F445D',
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  fontSize: '14px',
                },
              },
            }}
          >
            {['Historical Usage', 'Recommended Stock Level'].map(
              (type, index) => (
                <MenuItem
                  key={index}
                  value={type}
                  sx={{
                    fontSize: '14px',
                  }}
                >
                  {type}
                </MenuItem>
              )
            )}
          </Select>
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        <TableContainer
          sx={{
            color: 'white',
            overflow: 'scroll',
            maxHeight: '500px',
          }}
        >
          <Table
            sx={{
              minWidth: 650,
              borderCollapse: 'collapse',
              '& .MuiTableCell-root': {
                borderBottom: 'none',
              },
            }}
            size="small"
          >
            <TableHead
              sx={{
                backgroundColor: '#1C3459',
              }}
            >
              <TableRow>
                <TableCell sx={{ color: 'white', fontWeight: 500, py: 2 }}>
                  Part
                </TableCell>
                <TableCell
                  sx={{ color: 'white', fontWeight: 500, py: 2 }}
                  align="center"
                >
                  Failure Mode
                </TableCell>
                <TableCell
                  sx={{ color: 'white', fontWeight: 500, py: 2 }}
                  align="center"
                >
                  Historical Usage
                </TableCell>
                <TableCell
                  sx={{ color: 'white', fontWeight: 500, py: 2 }}
                  align="center"
                >
                  Recommended Stock Level
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {processedData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:not(:last-child)': {
                      borderBottom: `1px solid ${ThemePalette.dark.cartesianGrid}`,
                    },
                  }}
                >
                  <TableCell scope="row" sx={{ color: 'white', py: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.category}
                    </Typography>
                  </TableCell>
                  <TableCell
                    scope="row"
                    sx={{ color: 'white', py: 2 }}
                    align="center"
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.failureMode}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ color: 'white', py: 2 }} align="center">
                    <Box
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        bgcolor: '#3351c5',
                        borderRadius: '999px',
                        px: 2,
                        py: 0.5,
                      }}
                    >
                      <Typography variant="body2" sx={{ mr: 1 }}>
                        {row.count}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    scope="row"
                    sx={{ color: 'white', py: 2 }}
                    align="center"
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.eolThreshold}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PartsTable;
