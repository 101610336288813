import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

const useAppTheme = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  return { enableDarkTheme };
};

export default useAppTheme;
