import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useCurrentDateRange } from 'hooks/dateRange';
import HeaderMenu from 'views/Production/HeaderMenu/index';
import { exportOverviewPagesToPdf2 } from 'utils/exports/pdf-reports';
import { setIsPrinting } from 'store/exportData.slice';
import { exportToExcelForFuelUsage } from 'utils/exports/excel';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import useAppTheme from 'hooks/useAppTheme';
import useCustomerInfo from 'hooks/useCustomerInfo';
import AssetTypeFuelUsage from './sections/FuelUsageOverviewSection';
import FuelAnalyticsHandler from 'handlers/fuelAnalytics.handler';
import { getStartAndEndDateFromRange } from './utils';

const FuelUsageOverview = () => {
  const location = useLocation();
  const typeReceived = location.state?.type;
  const fuelAnalyticsHandler = new FuelAnalyticsHandler();
  const { enableDarkTheme } = useAppTheme();
  const { customerCode, unitSystem } = useCustomerInfo();

  const { accessToken } = useAppSelector((state) => state.authReducer);

  const { fuelAnalyticsWRTDriverEvents } = useAppSelector(
    (state) => state.fuelAnalyticsReducer
  );

  const {
    data,
    loadingStates,
    fleetDeviceDetails,
    fleetDeviceDetailsPerDayBasis,
  } = useAppSelector((state) => state.fuelAnalyticsReducer);

  const { data: fuelAnalytics } = useAppSelector(
    (state) => state.fuelAnalyticsReducer
  );

  const { isPrinting } = useAppSelector((state) => state.exportDataReducer);

  const dispatch = useAppDispatch();

  const defaultValue = useMemo(() => {
    return typeReceived
      ? typeReceived === 'Haul Truck'
        ? 1
        : typeReceived === 'Loader'
        ? 2
        : 0
      : 0;
  }, [typeReceived]);

  const [value, setValue] = useState(defaultValue);
  const [isSelected, setIsSelected] = useState('1D');
  const [isCustomDateChanged, setIsCustomDateChanged] = useState(false);
  const [aggregation, setAggregation] = useState<string>('Daily');
  const [shiftWiseAnalysis, setShiftWiseAnalysis] = useState<boolean>(false);
  const [shiftNumber, setShiftNumber] = useState<number>(1);
  const [utilizationType, setUtilizationType] = useState('engineHours');
  const [, setDetailsVal] = useState<Record<string, unknown>>({});
  const [, setAnalyticsData] = useState<any[]>();
  const [, setHoursBreakDown] = useState<{
    hauler: any;
    loader: any;
  }>({
    hauler: null,
    loader: null,
  });

  const [, setUserSelections] = useState({});
  const [, setSuggestionData] = useState({});
  const [, setFuelConsumption] = useState<{
    hauler: any;
    loader: any;
  }>({
    hauler: null,
    loader: null,
  });

  const [intialDateRange] = useState(getStartAndEndDateFromRange(1));

  const { startDate, endDate, setStartDate, setEndDate, today } =
    useCurrentDateRange({ initialDate: new Date(intialDateRange.startDate) });

  const fuelEventsPieData = useMemo(() => {
    const result: Record<string, number> = {};
    const type =
      value === 0 ? 'total_fleet' : value === 1 ? 'haul_truck' : 'loader';
    for (const event of fuelAnalyticsWRTDriverEvents) {
      if (type === 'total_fleet') {
        result[event.key] =
          Number(event?.data?.haul_truck?.fuel_used || 0) +
          Number(event?.data?.loader?.fuel_used || 0);
        result[`${event.key}_diff`] =
          Number(event?.data?.haul_truck?.diff || 0) +
          Number(event?.data?.loader?.diff || 0);
      } else {
        result[event.key] = Number(event?.data?.[type]?.fuel_used || 0);
        result[`${event.key}_diff`] = Number(event?.data?.[type]?.diff || 0);
      }
    }
    return result;
  }, [fuelAnalyticsWRTDriverEvents, value]);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    // const dateDifference = dayjs(endDate).diff(dayjs(startDate), 'day');
    // setAggregation(dateDifference < 31 ? 'Daily' : 'Monthly');
    setStartDate(startDate);
    if (aggregation === 'Monthly' && endDate) {
      setEndDate(dayjs(endDate).endOf('month').toDate());
    } else {
      setEndDate(endDate);
    }
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };
  const [exportTo, setExportTo] = useState('');
  const [, setExportClicked] = useState(false);

  const fileNameWithDateTime = `fuel-usage-dashboard-${formatDate(new Date())}`;

  const handleExportClick = async () => {
    dispatch(setIsPrinting(true));
    await exportOverviewPagesToPdf2(
      'fuel-usage-overview',
      'fuel-usage-overview',
      'Fuel Usage Overview',
      false,
      'portrait',
      value === 0 ? 2 : 4
    );
    dispatch(setIsPrinting(false));
  };

  const memoisedExportFunction = useCallback(() => {
    exportToExcelForFuelUsage(
      data,
      `${fileNameWithDateTime}.xlsx`,
      fleetDeviceDetails,
      fleetDeviceDetailsPerDayBasis,
      fuelEventsPieData,
      value === 0 ? 'total' : value === 1 ? 'haul_truck' : 'loader',
      unitSystem
    );
  }, [
    data,
    fleetDeviceDetails,
    fleetDeviceDetailsPerDayBasis,
    fileNameWithDateTime,
    fuelEventsPieData,
    value,
    unitSystem,
  ]);

  useEffect(() => {
    if (exportTo === 'pdf') {
      void handleExportClick();
    }
    if (exportTo === 'excel') {
      memoisedExportFunction();
    }
  }, [exportTo]);

  const isNotLoadingData = Object.entries(loadingStates)
    .filter(
      ([key]) => key !== 'isLoadingTarget' && key !== 'isLoadingSuggestions'
    )
    .every(([, value]) => !value);
  const fetchData = async (
    range: string,
    startDate: Date,
    endDate: Date,
    aggregation: string,
    shiftWiseAnalysis: boolean,
    shiftNumber: number
  ) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    const shouldSendDate = isSelected === 'Custom';

    if (isNotLoadingData) {
      await fuelAnalyticsHandler.get(
        range,
        formattedStartDate,
        formattedEndDate,
        shouldSendDate,
        false,
        value === 2 ? 'loader' : value === 1 ? 'loader' : 'all',
        ['1Y', 'YTD', 'Custom'].includes(isSelected)
          ? aggregation.toLowerCase()
          : 'daily',
        shiftWiseAnalysis,
        shiftNumber,
        utilizationType
      );
    }
  };

  const fetchFuelAnalyticsData = useCallback(
    async ({
      startDate,
      endDate,
      range,
    }: {
      startDate: Date;
      endDate: Date;
      range: string;
    }) => {
      try {
        const startDateFormatted = dayjs(startDate).format('YYYY-MM-DD');
        const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD');
        const shouldSendDate = isSelected === 'Custom';
        await fuelAnalyticsHandler.getFuelAnalyticsWRTDriverEvents({
          startDate: startDateFormatted,
          endDate: endDateFormatted,
          range,
          shouldSendDate,
          shiftWiseAnalysis,
          shiftNumber,
        });
      } catch (error) {}
    },
    [accessToken, isSelected, shiftWiseAnalysis, shiftNumber]
  );

  /**
   * Fetches the fuel consumption per device - for the treemap
   */
  const fetchFuelConsumptionPerDevice = useCallback(
    async ({
      startDate,
      endDate,
      range,
      aggregation,
    }: {
      startDate: Date;
      endDate: Date;
      range: string;
      aggregation: string;
    }) => {
      try {
        const startDateFormatted = dayjs(startDate).format('YYYY-MM-DD');
        const endDateFormatted = dayjs(endDate).format('YYYY-MM-DD');
        const shouldSendDate = range === 'Custom';
        await fuelAnalyticsHandler.getFleetFuelConsumptionPerDevice(
          startDateFormatted,
          endDateFormatted,
          shiftWiseAnalysis,
          shiftNumber,
          range,
          ['1Y', 'YTD', 'Custom'].includes(range)
            ? aggregation.toLowerCase()
            : 'daily',
          shouldSendDate
        );
      } catch (error) {}
    },
    [accessToken, shiftWiseAnalysis, shiftNumber]
  );
  const timeoutRefMultiplePromises = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (accessToken) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        if (isSelected === 'Custom') {
          if (!isCustomDateChanged) {
            setIsCustomDateChanged(true); // doing this won't have any impact in this render as the current value is in closure
          }
          if (isCustomDateChanged && startDate && endDate) {
            void fetchData(
              isSelected,
              startDate,
              endDate,
              aggregation,
              shiftWiseAnalysis,
              shiftNumber
            );
          }
        } else if (startDate && endDate) {
          setIsCustomDateChanged(false);
          void fetchData(
            isSelected,
            startDate,
            endDate,
            aggregation,
            shiftWiseAnalysis,
            shiftNumber
          );
        }
      }, 200);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [
    isSelected,
    startDate,
    endDate,
    accessToken,
    aggregation,
    shiftWiseAnalysis,
    shiftNumber,
  ]);

  useEffect(() => {
    if (accessToken) {
      if (startDate && endDate) {
        if (timeoutRefMultiplePromises.current) {
          clearTimeout(timeoutRefMultiplePromises.current);
        }
        timeoutRefMultiplePromises.current = setTimeout(() => {
          const promises = [];
          promises.push(
            fetchFuelConsumptionPerDevice({
              startDate,
              endDate,
              range: isSelected,
              aggregation,
            })
          );
          promises.push(
            fetchFuelAnalyticsData({ startDate, endDate, range: isSelected })
          );
          void Promise.all(promises);
        }, 200);
      }
    }
    return () => {
      if (timeoutRefMultiplePromises.current) {
        clearTimeout(timeoutRefMultiplePromises.current);
      }
    };
  }, [
    accessToken,
    startDate,
    endDate,
    isSelected,
    aggregation,
    shiftWiseAnalysis,
    shiftNumber,
  ]);

  return (
    <>
      <HeaderMenu
        value={value}
        handleChange={handleChange}
        handleExport={setExportClicked}
        setExportTo={setExportTo}
        exportTo={exportTo}
        loadingData={isNotLoadingData}
        isPrinting={isPrinting}
      />
      <div id="fuel-usage-overview">
        <AssetTypeFuelUsage
          fuelEventsPieData={fuelEventsPieData}
          value={value}
          index={0}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          unitSystem={unitSystem}
          customerCode={customerCode}
          loadingStates={loadingStates}
          data={fuelAnalytics.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={(value: any) => {
            setHoursBreakDown((prev) => ({ ...prev, hauler: value }));
          }}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
          setFuelConsumption={(value: any) => {
            setFuelConsumption((prev) => ({ ...prev, hauler: value }));
          }}
          assetType="Total Fleet"
        />
        <AssetTypeFuelUsage
          fuelEventsPieData={fuelEventsPieData}
          value={value}
          index={1}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          unitSystem={unitSystem}
          customerCode={customerCode}
          loadingStates={loadingStates}
          data={fuelAnalytics.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={(value: any) => {
            setHoursBreakDown((prev) => ({ ...prev, hauler: value }));
          }}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
          setFuelConsumption={(value: any) => {
            setFuelConsumption((prev) => ({ ...prev, hauler: value }));
          }}
          assetType="Hauler"
        />
        <AssetTypeFuelUsage
          fuelEventsPieData={fuelEventsPieData}
          value={value}
          index={2}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          unitSystem={unitSystem}
          customerCode={customerCode}
          loadingStates={loadingStates}
          data={fuelAnalytics.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={(value: any) => {
            setHoursBreakDown((prev) => ({ ...prev, hauler: value }));
          }}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
          setFuelConsumption={(value: any) => {
            setFuelConsumption((prev) => ({ ...prev, hauler: value }));
          }}
          assetType="Loader"
        />
      </div>
    </>
  );
};

export default FuelUsageOverview;
