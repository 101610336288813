import dayjs from 'dayjs';

export function getTimeRange(interval: string) {
  const endTime = dayjs();

  let startTime;
  switch (interval) {
    case '1m':
      startTime = endTime.subtract(1, 'minute');
      break;
    case '5m':
      startTime = endTime.subtract(5, 'minute');
      break;
    case '10m':
      startTime = endTime.subtract(10, 'minute');
      break;
    case '30m':
      startTime = endTime.subtract(30, 'minute');
      break;
    case '1h':
      startTime = endTime.subtract(1, 'hour');
      break;
    default:
      startTime = endTime.subtract(1, 'minute');
  }

  return {
    startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
    endTime: endTime.format('YYYY-MM-DD HH:mm:ss'),
  };
}

export function getArrayByKey(arr: any[], key: string) {
  const found = arr.find((item) => key in item);
  return found ? found[key] : [];
}
