import { type HealthScore } from 'types/squares.type';
import { type ServiceView } from 'types/survivalAnalysis.types';
import { type PmVsCmApiResponse } from '../DashboardEntityManagementViewV2';

export const calculateProportionalValue = (
  annualValue: number,
  startDate: Date,
  endDate: Date
): number => {
  // Check if startDate or endDate is null, undefined, or not a valid Date object
  if (!startDate || !endDate) {
    console.error('Start date or end date is invalid:', {
      startDate,
      endDate,
    });
    return 0; // Return a default value or handle the error as needed
  }

  // Ensure startDate and endDate are valid Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Check if the date parsing was successful
  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    console.error('Failed to parse start date or end date:', { start, end });
    return 0; // Return a default value or handle the error as needed
  }

  // Calculate the number of days between startDate and endDate
  const numOfDaysBetween =
    (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

  // If numOfDaysBetween is negative or zero, handle that scenario
  if (numOfDaysBetween <= 0) {
    console.warn(
      'Invalid date range: End date is before or same as start date.',
      { start, end }
    );
    return 0; // Return a default value or handle the error as needed
  }

  // Calculate the proportion of the year
  const proportionOfYear = numOfDaysBetween / 365;
  // Return the new proportional value
  return annualValue * proportionOfYear;
};

// Transform `ServiceView` to `HealthScore` using `assetsItems` data
export const transformServiceViewToHealthScore = (
  serviceViews: ServiceView[],
  assetsItems: HealthScore[]
): HealthScore[] => {
  return serviceViews.map((serviceView) => {
    // Find the matching HealthScore from assetsItems using item_id
    const matchedHealthScore = assetsItems.find(
      (item) => item.asset_details.bumper_number === serviceView.item_id
    );

    // If matched, use the data from matchedHealthScore
    if (matchedHealthScore) {
      return matchedHealthScore;
    }

    // If not matched, create a new HealthScore from available ServiceView data
    return {
      asset_details: {
        bumper_number: serviceView.item_id,
        health_score: Number(serviceView.health_score) ?? 0, // Default to 0 if not available
        squares_recommended_date:
          typeof serviceView.squares_recommended_date === 'string'
            ? serviceView.squares_recommended_date
            : '', // Ensure it's a string
        device:
          typeof serviceView.device === 'string' ? serviceView.device : '',
        make: typeof serviceView.make === 'string' ? serviceView.make : '',
        model: typeof serviceView.model === 'string' ? serviceView.model : '',
        asset_type:
          typeof serviceView.asset_type === 'string'
            ? serviceView.asset_type
            : '',
        manufacture_recommended_date:
          typeof serviceView.manufacture_recommended_date === 'string'
            ? serviceView.manufacture_recommended_date
            : '',
        latest_work_order_date:
          typeof serviceView.latest_work_order_date === 'string'
            ? serviceView.latest_work_order_date
            : '',
        age_in_days:
          typeof serviceView.age_in_days === 'string'
            ? serviceView.age_in_days
            : String(serviceView.age_in_days) ?? '', // Convert to string if necessary
        daily_engine_hours:
          typeof serviceView.daily_engine_hours === 'string'
            ? serviceView.daily_engine_hours
            : String(serviceView.daily_engine_hours) ?? '', // Convert to string if necessary
        engine_hours:
          typeof serviceView.engine_hours === 'string'
            ? serviceView.engine_hours
            : String(serviceView.engine_hours) ?? '', // Convert to string if necessary
        lastConnected: serviceView.lastConnected ?? '',
      },
      component_details: Array.isArray(serviceView.component_details)
        ? serviceView.component_details
        : [], // Ensure it's an array
    };
  });
};

// format PM CM data

export const calculateMaintenanceData = ({
  data,
}: {
  data: PmVsCmApiResponse[];
}) => {
  const formattedData: PmVsCmApiResponse[] = data.map((item) => ({
    category: item.category,
    total_quantity: item.total_quantity?.toString() ?? '0',
    total_cost: item.total_cost,
    total_hours: item.total_hours?.toString() ?? '0',
  }));

  // calculating metrics for PM and CM

  const {
    totalCost: pmTotalCost,
    totalHours: pmTotalHours,
    totalQuantity: pmTotalQuantity,
  } = calculateCostMetrics(formattedData, 'PM');

  const {
    totalCost: maintenanceTotalCost,
    totalHours: maintenanceTotalHours,
    totalQuantity: maintenanceTotalQuantity,
  } = calculateCostMetrics(formattedData, 'Maintenance job');

  const {
    totalCost: noneTotalCost,
    totalHours: noneTotalHours,
    totalQuantity: noneTotalQuantity,
  } = calculateCostMetrics(formattedData, 'None');

  const {
    totalCost: cmTotalCost,
    totalHours: cmTotalHours,
    totalQuantity: cmTotalQuantity,
  } = calculateCostMetrics(formattedData, 'CM');

  const totalMaintenanceCost =
    pmTotalCost + cmTotalCost + maintenanceTotalCost + noneTotalCost;
  const totalMaintenanceHours =
    pmTotalHours + cmTotalHours + maintenanceTotalHours + noneTotalHours;
  const totalMaintenanceQuantity =
    pmTotalQuantity +
    cmTotalQuantity +
    maintenanceTotalQuantity +
    noneTotalQuantity;

  return {
    pmTotalCost: pmTotalCost + maintenanceTotalCost + noneTotalCost,
    cmTotalCost,
    totalMaintenanceCost,
    pmTotalHours: pmTotalHours + maintenanceTotalHours + noneTotalHours,
    cmTotalHours,
    totalMaintenanceHours,
    pmTotalQuantity:
      pmTotalQuantity + maintenanceTotalQuantity + noneTotalQuantity,
    cmTotalQuantity,
    totalMaintenanceQuantity,
  };
};

const calculateCostMetrics = (
  data: PmVsCmApiResponse[],
  type: 'PM' | 'CM' | 'Maintenance job' | 'None'
): { totalCost: number; totalHours: number; totalQuantity: number } => {
  const filteredData = data.filter((item) => item.category === type);
  const totalCost = filteredData.reduce(
    (sum, item) => sum + item.total_cost,
    0
  );

  const totalHours = filteredData.reduce(
    (sum, item) => sum + parseFloat(item.total_hours),
    0
  );

  const totalQuantity = filteredData.reduce(
    (sum, item) => sum + parseInt(item.total_quantity, 10),
    0
  );
  return {
    totalCost,
    totalHours,
    totalQuantity,
  };
};
