import { Grid } from '@mui/material';
import type { utilizationFleetScore } from 'types/utilization.types';
import { truncateTo2dp } from 'utils/helpers/general';
import { useEffect, useMemo } from 'react';
import {
  type chartDataResponseItem,
  type FuelSummary,
} from 'types/fuelAnalytics';
import useCustomerInfo from 'hooks/useCustomerInfo';
import FuelCardAnalytics from './FuelCardAnalytics';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { useAppSelector } from 'store/hook';
import {
  evaluateNumber,
  getFleetKpiThreshold,
  getGridDataPoints,
  numberEvaluationKeyMappings,
} from 'views/FleetOverview/components/AssetCardsV2/utils';

interface Props {
  enableDarkTheme: boolean;
  isLoading: boolean;
  unitSystem: string;
  summary: FuelSummary;
  excessFuelUsage: number;
  score?: utilizationFleetScore;
  data?: chartDataResponseItem[];
  isSelected: string;
  aggregation?: string;
  customerCode: string;
  shiftNumber: number;
  shiftWiseAnalysis: boolean;
  utilizationType?: string;
  setAnalyticsData: any;
  assetTypeKey: 'fleet' | 'hauler' | 'loader';
}

const FuelUsageGridAnalytics = ({
  enableDarkTheme,
  isLoading,
  summary,
  excessFuelUsage,
  score,
  data,
  aggregation,
  isSelected,
  // customerCode,
  shiftNumber,
  shiftWiseAnalysis,
  utilizationType,
  setAnalyticsData,
  assetTypeKey,
}: Props) => {
  const { assetOverView: assetOverview } = useAppSelector(
    (state) => state.assetReducer
  );

  const { unitSystem } = useCustomerInfo();
  const isNegative = (value: number) => value < 0;
  const returnAnalysis = (value: number | string) => {
    return `${Math.abs(isNaN(Number(value)) ? 0 : Number(value))}% ${
      isNegative(Number(value)) ? 'less' : 'more'
    }`;
  };

  const entries = Object.values(score ?? {}).filter(
    (item): item is any => typeof item === 'object' && 'asset_type' in item
  );

  // Create key-value pairs of asset_type and util_score
  const keyValueObject = entries?.reduce<Record<string, number>>(
    (acc, item) => {
      acc[item.asset_type] = item.util_score;
      return acc;
    },
    {}
  );
  // Calculate the total average util_score
  const totalSum = entries.reduce(
    (sum, item) => Number(sum) + Number(item.util_score),
    0
  );
  const totalCount = entries.length;
  const totalAverage = totalSum / totalCount;

  // Add the total key to the keyValueObject
  keyValueObject.total = totalAverage;

  const thresholdMappings = numberEvaluationKeyMappings(
    assetTypeKey === 'fleet'
      ? 'total'
      : assetTypeKey === 'hauler'
      ? 'Haul Truck'
      : 'Loader'
  );

  const kpiThresholds = getFleetKpiThreshold(assetOverview.kpiThresholds);

  const assetOverviewData = getGridDataPoints(assetOverview);

  const tr = useMemo(
    () => [
      {
        title: `Total Fuel Consumption`,
        pointingUp: !(
          summary?.[`${assetTypeKey}_total_fuel_consumption_l_diff`] < 0
        ),
        value: formatNumberToShortScale(
          truncateTo2dp(
            summary?.[`${assetTypeKey}_total_fuel_consumption_l`] || 0
          ),
          2
        ),
        unit: `${unitSystem === 'metric' ? ' L' : ' Gal'}`,
        isGreen: isNegative(
          summary?.[`${assetTypeKey}_total_fuel_consumption_l_diff`]
        ),
        analysis: returnAnalysis(
          truncateTo2dp(
            summary?.[`${assetTypeKey}_total_fuel_consumption_l_diff`]
          )
        ),
        message: 'from last period',
        hasWarning: false,
        warningCount: 0,
        showProgressBar: true,
        progressBarValue: evaluateNumber(
          assetOverviewData?.[assetTypeKey === 'hauler' ? 'haul' : assetTypeKey]
            ?.fuel,
          thresholdMappings.fuelUsage,
          kpiThresholds
        ),
        chartData: data?.map((item) => {
          return {
            name: item.date,
            value: item.total_fuel_consumption,
          };
        }),
      },
      {
        title: 'Fuel Rate',
        pointingUp: !isNegative(
          summary?.[`${assetTypeKey}_avg_fuel_rate_diff`]
        ),
        value: formatNumberToShortScale(
          truncateTo2dp(summary?.[`${assetTypeKey}_avg_fuel_rate`] || 0),
          2
        ),
        unit: `${unitSystem === 'metric' ? ' L/Hr' : ' Gal/Hr'}`,
        isGreen: isNegative(summary?.[`${assetTypeKey}_avg_fuel_rate_diff`]),
        analysis: returnAnalysis(
          truncateTo2dp(summary?.[`${assetTypeKey}_avg_fuel_rate_diff`])
        ),
        message: 'from last period',
        hasWarning: false,
        warningCount: 0,
        showProgressBar: false,
        chartData: data?.map((item) => {
          return {
            name: item.date,
            value: item.avg_fuel_rate,
          };
        }),
      },
      {
        title: `CO2 Emitted`,
        pointingUp: !isNegative(summary?.[`${assetTypeKey}_CO2_used_diff`]),
        value: formatNumberToShortScale(
          truncateTo2dp(summary?.[`${assetTypeKey}_CO2_used`] || 0),
          2
        ),
        unit: `${unitSystem === 'metric' ? ' Kg' : ' Lbs'}`,
        isGreen: isNegative(summary?.[`${assetTypeKey}_CO2_used_diff`]),
        analysis: returnAnalysis(
          truncateTo2dp(summary?.[`${assetTypeKey}_CO2_used_diff`])
        ),
        message: 'from last period',
        hasWarning: false,
        warningCount: 0,
        showProgressBar: false,
        progressBarValue: 78,
        chartData: data?.map((item) => {
          return {
            name: item.date,
            value: item.CO2_used,
          };
        }),
      },
      {
        title: `Cost Incurred`,
        pointingUp: !isNegative(
          summary?.[`${assetTypeKey}_fuel_cost_incurred_diff`]
        ),
        value: formatNumberToShortScale(
          truncateTo2dp(summary?.[`${assetTypeKey}_fuel_cost_incurred`] || 0),
          2
        ),
        unit: '$',
        isGreen: isNegative(
          summary?.[`${assetTypeKey}_fuel_cost_incurred_diff`]
        ),
        analysis: returnAnalysis(
          truncateTo2dp(summary?.[`${assetTypeKey}_fuel_cost_incurred_diff`])
        ),
        message: 'from last period',
        hasWarning: false,
        warningCount: 0,
        showProgressBar: false,
        progressBarValue: 78,
        chartData: data?.map((item) => {
          return {
            name: item.date,
            value: item.fuel_cost_incurred,
          };
        }),
      },
      {
        title: `Fuel Efficiency Lost`,
        pointingUp: !isNegative(
          summary?.[`${assetTypeKey}_fuel_eff_lost_diff`]
        ),
        value: truncateTo2dp(summary?.[`${assetTypeKey}_fuel_eff_lost`] || 0),
        unit: '%',
        isGreen: isNegative(summary?.[`${assetTypeKey}_fuel_eff_lost_diff`]),
        analysis: returnAnalysis(
          truncateTo2dp(summary?.[`${assetTypeKey}_fuel_eff_lost_diff`])
        ),
        message: 'from last period',
        hasWarning: false,
        warningCount: 0,
        showProgressBar: false,
        progressBarValue: 78,
        chartData: data?.map((item) => {
          return {
            name: item.date,
            value: item.fuel_eff_lost,
          };
        }),
      },
    ],
    [
      assetTypeKey,
      summary,
      aggregation,
      isSelected,
      shiftWiseAnalysis,
      shiftNumber,
      unitSystem,
      data,
      // shift.startHour,
      // shift.endHour,
      // utilizationScore,
      // utilizationChart,
      // engineHoursChart,
      // idlingHoursChart,
      // productiveHoursChart,
    ]
  );

  useEffect(() => {
    setAnalyticsData((prev: any) =>
      JSON.stringify(prev) !== JSON.stringify(tr) ? tr : prev
    );
  }, [tr, setAnalyticsData]);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      sx={{
        paddingTop: '15px',
      }}
    >
      {tr.map((item: any, index: any) => {
        return (
          <Grid item key={index} lg={2.4} md={6} sm={12} xs={12}>
            <FuelCardAnalytics
              enableDarkTheme={enableDarkTheme}
              analytics={item}
              isLoading={isLoading}
              isSelected={isSelected}
              aggregation={aggregation}
              utilizationType={utilizationType}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default FuelUsageGridAnalytics;
