import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { ReactComponent as DarkModeLogo } from 'assets/logos/logo.svg'; // Import the SVG as a React component
import { ReactComponent as LightModeLogo } from 'assets/logos/symboticware_logo.svg'; // Import the SVG as a React component
import './LoadingScreen.css'; // Optional: Use this for additional styles
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

interface LoadingScreenProps {
  progress?: number; // Progress percentage (0 to 100), optional
  isFullScreen?: boolean;
  message?: string;
}

export const SingleFadeLoader: React.FC<LoadingScreenProps> = ({
  progress,
  isFullScreen = true,
  message = 'Loading...',
}) => {
  const theme = useAppSelector((state) => state.authReducer?.customer?.theme);
  const enableDarkTheme = isDarkTheme(theme);
  return (
    <div className={isFullScreen ? 'loading-screen' : 'loading-screen-small'}>
      {/* Render the SVG */}
      {enableDarkTheme ? (
        <DarkModeLogo className="loading-icon" />
      ) : (
        <LightModeLogo className="loading-icon" />
      )}
      <Typography className="loading-text">{message}</Typography>{' '}
      {/* Display the loading text */}
      {progress !== undefined && ( // Conditionally render LinearProgress
        <Box sx={{ width: '50%', maxWidth: '100px', margin: '0 auto' }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: '10px',
              borderRadius: '5px',
              backgroundColor: '#D3D3D3',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#4A6CF7',
              },
            }}
          />
        </Box>
      )}
    </div>
  );
};
