import { createSlice } from '@reduxjs/toolkit';

export interface PartsMaintenanceExpenditureInterface {
  data: any;
  isLoading: boolean;
}

const initialState: PartsMaintenanceExpenditureInterface = {
  data: { currentData: [], ytdData: [] },
  isLoading: false,
};

export const squaresPartsMaintenanceExpenditureSlice = createSlice({
  name: 'squaresPartsMaintenanceExpenditure',
  initialState,
  reducers: {
    setPartsMaintenanceExpenditure(state, { payload }) {
      state.data = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const { setPartsMaintenanceExpenditure, setIsLoading } =
  squaresPartsMaintenanceExpenditureSlice.actions;

export default squaresPartsMaintenanceExpenditureSlice.reducer;
