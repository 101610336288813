import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ThemePalette } from 'mui.theme';
import { Lightbulb } from '@mui/icons-material';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  selectedAssets: string[];
  setTimeLineAssets: React.Dispatch<React.SetStateAction<string[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  uniqueAssets: string[];
  isLoading?: boolean;
}

const DriverBehaviourFilters = ({
  setTimeLineAssets,
  setCurrentPage,
  selectedAssets: selectedAssetsParent,
  uniqueAssets,
  isLoading,
}: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = useMemo(() => isDarkTheme(theme), [theme]);
  // const uniqueAssets = useMemo(() => {
  //   return Array.from(new Set(assets?.map((asset) => asset.bumperNumber)));
  // }, [assets]);
  const [selectedAssets, setSelectedAssets] = React.useState<string[]>([]);

  useEffect(() => {
    setSelectedAssets(selectedAssetsParent);
  }, [selectedAssetsParent]);

  const handleApplyFilters = () => {
    setTimeLineAssets(selectedAssets);
    setCurrentPage(1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        alignItems: 'end',
        padding: '0 10px',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
        }}
      >
        <Box>
          <Autocomplete
            disabled={isLoading}
            multiple
            limitTags={5}
            size="small"
            value={selectedAssets}
            onChange={(ev, newValue) => {
              if (newValue.length > 5) {
                return;
              }
              setSelectedAssets(newValue);
            }}
            id="checkboxes-assets"
            options={uniqueAssets}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => {
              const { ...optionProps } = props;
              return (
                <li {...optionProps}>
                  <Checkbox
                    size="small"
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                    // disable checkboxes if 5 assets are selected
                    disabled={!selected && selectedAssets.length >= 5}
                  />
                  {option}
                </li>
              );
            }}
            style={{
              width: 550,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Select Assets"
                placeholder={
                  selectedAssets?.length === 0 ? 'Select Assets' : ''
                }
                sx={{
                  '& label': {
                    color: enableDarkTheme ? 'white' : 'black',
                  },
                  '& label.Mui-focused': {
                    color: enableDarkTheme ? 'white' : 'black',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: enableDarkTheme ? 'white' : 'black',
                  },
                  '& .MuiFilledInput-root': {
                    color: enableDarkTheme ? 'white' : 'black',
                    backgroundColor: enableDarkTheme
                      ? ThemePalette.dark.boxBackground
                      : ThemePalette.light.boxBackground,
                    border: '1px solid',
                    borderColor: enableDarkTheme ? '#808080' : '#1E2A47',
                  },
                  '& .MuiFilledInput-root:hover': {
                    color: enableDarkTheme ? 'white' : 'black',
                  },
                  // arrow color
                  '& .MuiSvgIcon-root': {
                    color: enableDarkTheme ? 'white' : 'black',
                  },
                  // Update chip styles for light background
                  '& .MuiChip-root': {
                    color: enableDarkTheme ? 'black' : 'rgba(0, 0, 0, 0.87)', // Adjust text color for visibility
                    backgroundColor: enableDarkTheme
                      ? 'rgba(255, 255, 255, 0.7)' // Light background in dark mode
                      : ThemePalette.light.boxBackground, // Use light theme background
                  },
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
            sx={{
              '&:disabled': {
                color: 'GrayText',
              },
            }}
          >
            Apply Filter
          </Button>
          {selectedAssetsParent?.length > 0 && (
            <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              onClick={() => {
                if (selectedAssetsParent.length > 0) {
                  // clear the parent. which will in turn clear the child
                  setTimeLineAssets([]);
                } else {
                  // clear the child
                  setSelectedAssets([]);
                }
              }}
              sx={{
                '&:disabled': {
                  color: 'GrayText',
                },
              }}
            >
              Clear Filter
            </Button>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: enableDarkTheme ? '#808080' : 'black',
        }}
      >
        {/* disclaimer that max 5 assets can be selected */}
        <Lightbulb
          sx={{
            fontSize: '12px',
          }}
        />
        <Typography sx={{ fontSize: '12px' }}>
          Note: Max 5 assets can be selected for filtering
        </Typography>
      </Box>
    </Box>
  );
};

export default DriverBehaviourFilters;
