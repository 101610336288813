import { Checkbox, FormControlLabel } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import React from 'react';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

interface Props {
  selectAll: boolean;
  handleSelectAll: (selectAll: boolean) => void;
}

const SelectAllCheckbox = ({ selectAll, handleSelectAll }: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  return (
    <FormControlLabel
      sx={{
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
      control={
        <Checkbox
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          color="secondary"
          checked={selectAll}
          onChange={(e) => {
            handleSelectAll(e.target.checked);
          }}
        />
      }
      label="Select All Assets"
      labelPlacement="start"
    />
  );
};

export default SelectAllCheckbox;
