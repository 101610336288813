import React, { useEffect, useState } from 'react';
import {
  type fuelMeasureType,
  type FuelAnalyticsChartToggleList,
} from '../types/fuelAnalytics.types';
import { Box, Typography } from '@mui/material';
import useAppTheme from 'hooks/useAppTheme';
import { Selector } from 'components/Form/Selectors/Selector';
import { ThemePalette } from 'mui.theme';

interface ChartDataTogglerProps {
  selectedDataKey: fuelMeasureType;
  handleChange: (value: fuelMeasureType) => void;
  label?: string;
  hiddenDropDownItems?: fuelMeasureType[];
}
const ChartDataToggler = ({
  selectedDataKey,
  handleChange,
  label,
  hiddenDropDownItems,
}: ChartDataTogglerProps) => {
  const { enableDarkTheme } = useAppTheme();

  const [chartToggleOptions, setChartToggleOptions] =
    useState<FuelAnalyticsChartToggleList>([
      {
        key: 'consumption',
        label: 'Consumption',
      },
      {
        key: 'co2_emission',
        label: 'CO2 emission',
      },
      // {
      //   key: 'cost',
      //   label: 'Cost',
      // },
      {
        key: 'num_of_idle_events',
        label: 'No. of Idle Events',
      },
      {
        key: 'total_duration_of_idle_events',
        label: 'Total Duration of Idle Events',
      },
      {
        key: 'loaded_fuel_consumption',
        label: 'Loaded Fuel Consumption',
      },
      {
        key: 'unloaded_fuel_consumption',
        label: 'Unloaded Fuel Consumption',
      },
      {
        key: 'avg_fuel_rate',
        label: 'Avg. Fuel Rate',
      },
    ]);

  useEffect(() => {
    if (hiddenDropDownItems) {
      setChartToggleOptions(
        chartToggleOptions.filter(
          (item) => !hiddenDropDownItems.includes(item.key)
        )
      );
    }
  }, [hiddenDropDownItems]);

  return (
    <Box
      sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}
      minWidth={300}
      maxWidth={500}
    >
      <Box
        style={{
          color: enableDarkTheme ? ThemePalette.typography.white : '#3d3d3d',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Box sx={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
          <Typography style={{ fontSize: '12px', padding: '5px 10px 5px 0' }}>
            {label ?? 'View chart by'}
          </Typography>
          <Selector
            minWidth="1px"
            selectSx={{
              fontSize: '12px',
              minWidth: '220px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.cartesianGrid
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              '& .MuiSelect-icon': {
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
              '& fieldset': {
                borderColor: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
              ...(enableDarkTheme && {
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#2F445D', // <------------------ outline-color by default
                  },
                },
              }),
            }}
            value={selectedDataKey}
            onChange={(event) => {
              handleChange(event.target.value as fuelMeasureType);
            }}
            selectorOptions={chartToggleOptions.map((item) => ({
              id: item.key,
              display: item.label,
            }))}
            enableDarkTheme={enableDarkTheme}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChartDataToggler;
