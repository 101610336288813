import React from 'react';
import { Card, Typography, Box } from '@mui/material';

interface NetworkStatusProps {
  status: string;
}

const NetworkStatus: React.FC<NetworkStatusProps> = ({ status }) => {
  return (
    <Card
      sx={{
        backgroundColor: '#112240',
        p: 2,
        textAlign: 'center',
        color: 'white',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Network Status
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          color:
            status === 'Connected'
              ? '#4CAF50'
              : status === 'Good'
              ? '#FFC107'
              : '#F44336',
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}
      >
        <span style={{ fontSize: '2rem' }}>●</span> {status}
      </Box>
    </Card>
  );
};

export default NetworkStatus;
