import React, { useEffect, useState, useRef } from 'react';
import ForceGraph2D, { type NodeObject } from 'react-force-graph-2d';

type ApiResponse = Record<
  string,
  {
    nodes: Array<{
      hostname: string;
      mac: string;
      data_type: string; // "node" or "connected_device"
      rssi?: number;
      last_seen?: string;
    }>;
    connected_devices: Array<{
      hostname: string;
      mac: string;
      data_type: string; // "node" or "connected_device"
      rssi?: number;
      last_seen?: string;
    }>;
  }
>;

interface MyNode extends NodeObject {
  id: string;
  data_type?: string; // "gateway" | "node" | "connected_device"
}

interface MyLink {
  source: string;
  target: string;
}

interface GraphData {
  nodes: MyNode[];
  links: MyLink[];
}

function transformDataToGraph(apiData: ApiResponse): GraphData {
  const nodeMap = new Map<string, MyNode>();
  const linksSet = new Set<string>();
  const linksArray: MyLink[] = [];

  Object.entries(apiData).forEach(([mainKey, data]) => {
    const isGateway = mainKey.toLowerCase().startsWith('gateway');

    if (!nodeMap.has(mainKey)) {
      nodeMap.set(mainKey, {
        id: mainKey,
        data_type: isGateway
          ? 'gateway'
          : mainKey.toLowerCase().startsWith('node')
          ? 'node'
          : 'connected_device',
      });
    }

    const connected = [...data.nodes, ...data.connected_devices];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    connected.forEach(({ hostname, data_type }) => {
      if (!nodeMap.has(hostname)) {
        nodeMap.set(hostname, { id: hostname, data_type });
      }
      const sortedPair = [mainKey, hostname].sort();
      const linkKey = sortedPair.join('-');
      if (!linksSet.has(linkKey)) {
        linksSet.add(linkKey);
        linksArray.push({ source: sortedPair[0], target: sortedPair[1] });
      }
    });
  });

  return {
    nodes: Array.from(nodeMap.values()),
    links: linksArray,
  };
}

// Helper functions to draw shapes.
const drawHexagon = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number
) => {
  ctx.beginPath();
  for (let i = 0; i < 6; i++) {
    const angle = (Math.PI / 3) * i;
    const px = x + radius * Math.cos(angle);
    const py = y + radius * Math.sin(angle);
    i === 0 ? ctx.moveTo(px, py) : ctx.lineTo(px, py);
  }
  ctx.closePath();
};

const drawCircle = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number
) => {
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
  ctx.closePath();
};

const drawRectangle = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  radius: number
) => {
  ctx.beginPath();
  ctx.rect(x - radius, y - radius, 2 * radius, 3 * radius);
  ctx.closePath();
};

interface MeshGraphProps {
  apiResponse: ApiResponse;
}

const MeshGraph: React.FC<MeshGraphProps> = ({ apiResponse }) => {
  const [graphData, setGraphData] = useState<GraphData>({
    nodes: [],
    links: [],
  });
  // Create a ref to access the ForceGraph instance.
  const fgRef = useRef<any>(null);

  useEffect(() => {
    if (apiResponse) {
      const transformedData = transformDataToGraph(apiResponse);
      setGraphData(transformedData);
    }
  }, [apiResponse]);

  // Adjust simulation forces using the ref after graphData is updated.
  useEffect(() => {
    if (fgRef.current) {
      // Access the simulation and modify forces.
      // We annotate the simulation parameter as any to bypass TS warnings.
      fgRef.current.d3Force('link').distance((d: any) => 100);
      fgRef.current.d3Force('charge').strength(-200);
    }
  }, [graphData]);

  return (
    <div style={{ width: '100%', height: '600px' }}>
      <ForceGraph2D
        ref={fgRef}
        graphData={graphData}
        backgroundColor="#0A1929"
        nodeLabel={(node) => node.id}
        width={900}
        height={600}
        d3AlphaDecay={0.02}
        d3VelocityDecay={0.3}
        warmupTicks={50}
        cooldownTime={3000}
        enableNodeDrag={true}
        linkDirectionalParticles={1}
        linkDirectionalArrowLength={2}
        linkColor={() => '#90caf9'}
        nodeCanvasObjectMode={() => 'replace'}
        nodeCanvasObject={(node, ctx, globalScale) => {
          const typedNode = node as MyNode;
          const radius = 10;
          const x = typedNode.x ?? 0;
          const y = typedNode.y ?? 0;

          if (typedNode.id.toLowerCase().startsWith('gateway')) {
            drawHexagon(ctx, x, y, radius);
          } else if (typedNode.data_type === 'node') {
            drawCircle(ctx, x, y, radius);
          } else if (typedNode.data_type === 'connected_device') {
            drawRectangle(ctx, x, y, radius);
          } else {
            drawCircle(ctx, x, y, radius);
          }

          ctx.fillStyle = '#f44336';
          ctx.fill();
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#fff';
          ctx.stroke();

          const label = typedNode.id;
          const fontSize = 12 / globalScale;
          ctx.font = `${fontSize}px Sans-Serif`;
          ctx.fillStyle = 'white';
          const textWidth = ctx.measureText(label).width;
          if (typedNode.data_type === 'connected_device') {
            const labelY = y - radius - 4;
            ctx.fillText(label, x - textWidth / 2, labelY);
          } else {
            ctx.fillText(label, x - textWidth / 2, y + fontSize / 3);
          }
        }}
      />
    </div>
  );
};

export default MeshGraph;
