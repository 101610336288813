import dayjs from 'dayjs';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';
import type { Asset } from 'types/asset.types';

export function timeAgo(dateStr: any) {
  const now = dayjs();
  const inputDate = dayjs(dateStr, 'MM/DD/YYYY, HH:mm');

  if (!inputDate.isValid()) {
    return 'N/A';
  }

  const diffInSeconds = now.diff(inputDate, 'second');
  const diffInMinutes = now.diff(inputDate, 'minute');
  const diffInHours = now.diff(inputDate, 'hour');
  const diffInDays = now.diff(inputDate, 'day');
  const diffInWeeks = now.diff(inputDate, 'week');
  const diffInMonths = now.diff(inputDate, 'month');
  const diffInYears = now.diff(inputDate, 'year');

  if (diffInSeconds < 60) {
    return `${Math.round(diffInSeconds)} sec(s) ago`;
  } else if (diffInMinutes < 60) {
    return `${Math.round(diffInMinutes)} min(s) ago`;
  } else if (diffInHours < 24) {
    return `${Math.round(diffInHours)} hr(s) ago`;
  } else if (diffInDays < 7) {
    return `${Math.round(diffInDays)} day(s) ago`;
  } else if (diffInWeeks < 4) {
    return `${Math.round(diffInWeeks)} week(s) ago`;
  } else if (diffInMonths < 12) {
    return `${Math.round(diffInMonths)} month(s) ago`;
  } else {
    return `${Math.round(diffInYears)} year(s) ago`;
  }
}

export function getFuelUsageDiff(
  fuelUsage: any,
  utilization: any,
  productivity: any,
  dateComparer: string
) {
  if (dateComparer === 'day') {
    return {
      fuelUsage: fuelUsage?.fuel_consumption_l_1day_change_pct,
      utilization: utilization?.utilization_1day_change_pct,
      productivity: productivity?.max_load_ton_1day_change_pct,
    };
  }

  if (dateComparer === '3days') {
    return {
      fuelUsage: fuelUsage?.fuel_consumption_l_3day_change_pct,
      utilization: utilization?.utilization_3day_change_pct,
      productivity: productivity?.max_load_ton_3day_change_pct,
    };
  }

  if (dateComparer === 'week') {
    return {
      fuelUsage: fuelUsage?.fuel_consumption_l_7day_change_pct,
      utilization: utilization?.utilization_7day_change_pct,
      productivity: productivity?.max_load_ton_7day_change_pct,
    };
  }
}

export function getAssetMakeModel(assets: Asset[]) {
  const map: Record<string, Asset[]> = {};
  const assetMakeModelMap = assets
    .filter((asset: Asset) => asset.device?.deviceId)
    .reduce((acc: any, asset: Asset) => {
      /*
        find make and model
        we join make and model using `!` as a delimiter
        b/c we can guarantee it will not be included
        in an actual make or model
       */
      const makeModel = composeUniqueKey([asset.make, asset.model]);

      if (!acc[makeModel]) acc[makeModel] = [];

      acc[makeModel].push(asset);

      return acc;
    }, map);

  const assetMakeModelList = Object.keys(assetMakeModelMap);

  const uniquePairs = new Set<string>();

  assetMakeModelList.forEach((pair: string) => {
    const [make, model] = pair.split('!');
    uniquePairs.add(`${make}-${model}`);
  });

  const uniquePairsArray: string[] = Array.from(uniquePairs).sort((a, b) => {
    const [makeA, modelA] = a.split('-');
    const [makeB, modelB] = b.split('-');
    if (makeA !== makeB) {
      return makeA.localeCompare(makeB);
    }
    return modelA.localeCompare(modelB);
  });
  return uniquePairsArray;
}

export function getArrayAverage(arg: number[]) {
  // Calculate the number of attributes
  const numberOfAttributes = Object.keys(arg).length;

  const sumOfArr = arg.reduce((sum, score) => sum + score, 0);

  // Calculate the result (sum of fuel_scores divided by the number of attributes)
  const result = sumOfArr / numberOfAttributes;
  return result;
}

export function getGridDataPoints(arg: any) {
  const fuelScores = Object.values(arg.fuelScore).map((attribute: any) => {
    return attribute.fuel_score;
  });

  const fuelScoresDate = Object.values(arg.fuelScore).map((attribute: any) => {
    return String(attribute?.date).split(' ')?.[0];
  });

  const productivityScores = Object.values(arg.productivityScore).map(
    (attribute: any) => attribute.prod_score
  );

  const productivityScoresDate = Object.values(arg.productivityScore).map(
    (attribute: any) => {
      return String(attribute?.date).split(' ')?.[0];
    }
  );

  const utilizationScores = Object.values(arg.utilizationScore).map(
    (attribute: any) => attribute.util_score
  );

  const utilizationScoresDate = Object.values(arg.utilizationScore).map(
    (attribute: any) => {
      return String(attribute?.date).split(' ')?.[0];
    }
  );

  return {
    fleet: {
      fuel: getArrayAverage(fuelScores),
      utilization: getArrayAverage(utilizationScores),
      production: getArrayAverage(productivityScores),
      fuelScoresDate: fuelScoresDate?.[fuelScoresDate?.length - 1] || null,
      utilizationScoresDate:
        utilizationScoresDate?.[utilizationScoresDate?.length - 1] || null,
      productivityScoresDate:
        productivityScoresDate?.[productivityScoresDate?.length - 1] || null,
    },
    haul: {
      fuel: arg?.fuelScore?.haul_truck?.fuel_score || 0,
      utilization: arg?.utilizationScore?.haul_truck?.util_score || 0,
      production: arg?.productivityScore?.haul_truck?.prod_score || 0,
      fuelScoresDate: fuelScoresDate?.[fuelScoresDate?.length - 1] || null,
      utilizationScoresDate:
        utilizationScoresDate?.[utilizationScoresDate?.length - 1] || null,
      productivityScoresDate:
        productivityScoresDate?.[productivityScoresDate?.length - 1] || null,
    },
    loader: {
      fuel: arg?.fuelScore?.loader?.fuel_score || 0,
      utilization: arg?.utilizationScore?.loader?.util_score || 0,
      production: arg?.productivityScore?.loader?.prod_score || 0,
      fuelScoresDate: fuelScoresDate?.[fuelScoresDate?.length - 1] || null,
      utilizationScoresDate:
        utilizationScoresDate?.[utilizationScoresDate?.length - 1] || null,
      productivityScoresDate:
        productivityScoresDate?.[productivityScoresDate?.length - 1] || null,
    },
    dozer: {
      fuel: arg?.fuelScore?.others?.fuel_score || 0,
      utilization: arg?.utilizationScore?.others?.util_score || 0,
      production: arg?.productivityScore?.others?.prod_score || 0,
      fuelScoresDate: fuelScoresDate?.[fuelScoresDate?.length - 1] || null,
      utilizationScoresDate:
        utilizationScoresDate?.[utilizationScoresDate?.length - 1] || null,
      productivityScoresDate:
        productivityScoresDate?.[productivityScoresDate?.length - 1] || null,
    },
  };
}

export function getFleetKpiThreshold(
  arg: {
    'fuel_usage-haul_truck'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
    'fuel_usage-loader'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
    'fuel_usage-others'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
    'utilization-haul_truck'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
    'utilization-loader'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
    'utilization-others'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
    'productivity-haul_truck'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
    'productivity-loader'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
    'productivity-others'?: {
      asset_type: string;
      red_yellow_thres: number;
      yellow_green_thres: number;
    };
  } = {}
) {
  const calculateAverage = (
    values: Array<
      { red_yellow_thres: number; yellow_green_thres: number } | undefined
    >
  ): { red_yellow_thres: number; yellow_green_thres: number } => {
    const validValues = values.filter((value) => value !== undefined) as Array<{
      red_yellow_thres: number;
      yellow_green_thres: number;
    }>;
    const count = validValues.length;

    const redYellowThres =
      validValues.reduce((acc, val) => acc + val.red_yellow_thres, 0) / count;
    const yellowGreenThres =
      validValues.reduce((acc, val) => acc + val.yellow_green_thres, 0) / count;

    return {
      red_yellow_thres: redYellowThres,
      yellow_green_thres: yellowGreenThres,
    };
  };

  const totalArgs = {
    'fuel_usage-total': calculateAverage([
      arg['fuel_usage-haul_truck'],
      arg['fuel_usage-loader'],
      arg['fuel_usage-others'],
    ]),
    'utilization-total': calculateAverage([
      arg['utilization-haul_truck'],
      arg['utilization-loader'],
      arg['utilization-others'],
    ]),
    'productivity-total': calculateAverage([
      arg['productivity-haul_truck'],
      arg['productivity-loader'],
      arg['productivity-others'],
    ]),
  };
  return {
    ...arg,
    ...totalArgs,
  };
}

export function getNotificationIndex(notification: any) {
  const notifications = notification?.filter((item: any) => item.isActive);
  const result = {
    message: 'No Major Alerts',
    color: '#097969',
    score: 100,
    time: new Date().toISOString(),
  };

  const lowAlerts = notifications?.filter(
    (notification: any) => notification.rule.notificationLevel === '1'
  );

  const mediumAlerts = notifications?.filter(
    (notification: any) => notification.rule.notificationLevel === '2'
  );

  const highAlerts = notifications?.filter(
    (notification: any) => notification.rule.notificationLevel === '3'
  );

  if (lowAlerts?.length > 0) {
    // has low alerts so return low green
    result.color = '#009161';
    result.score = 85;
  }

  if (lowAlerts?.length > 1) {
    // has more than one low alerts so return high yellow
    result.color = '#FFA403';
    result.message = 'Caution Advised';
    result.score = 75;
  }

  if (mediumAlerts?.length > 0) {
    // has  one medium alerts so return yellow
    result.color = '#FFA403';
    result.message = 'Caution Advised';
    result.score = 65;
  }

  if (mediumAlerts?.length > 1) {
    // has more than one medium alerts so return low yellow
    result.color = '#E49B0F';
    result.message = 'Caution Advised';
    result.score = 50;
  }

  if (highAlerts?.length > 0) {
    // has one high alerts so return low red
    result.color = '#EB5757';
    result.message = 'Attention Required';
    result.score = 35;
  }

  if (highAlerts?.length > 0) {
    // has more than one high alerts so return red
    result.color = '#EB5757';
    result.message = 'Attention Required';
    result.score = 25;
  }
  return result;
}

export function sortCompareAssetsByChecked(checkedAssets: any, data: any) {
  const stringsSet = new Set(checkedAssets);

  const sortedObjectsArray = data.sort((a: any, b: any) => {
    const aInSet = stringsSet.has(a.assetId);
    const bInSet = stringsSet.has(b.assetId);

    if (aInSet && !bInSet) {
      return -1;
    } else if (!aInSet && bInSet) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedObjectsArray;
}

export function numberEvaluationKeyMappings(
  key: 'Haul Truck' | 'Loader' | 'Others' | 'total'
) {
  const mappings = {
    'Haul Truck': {
      fuelUsage: 'fuel_usage-haul_truck',
      utilization: 'utilization-haul_truck',
      productivity: 'productivity-haul_truck',
    },
    Loader: {
      fuelUsage: 'fuel_usage-loader',
      utilization: 'utilization-loader',
      productivity: 'productivity-loader',
    },
    Others: {
      fuelUsage: 'fuel_usage-others',
      utilization: 'utilization-others',
      productivity: 'productivity-others',
    },
    total: {
      fuelUsage: 'fuel_usage-total',
      utilization: 'utilization-total',
      productivity: 'productivity-total',
    },
  };

  return mappings[key];
}

export function evaluateNumber(number: any, key: any, obj: any) {
  let percentage = number;
  if (!Object.prototype.hasOwnProperty.call(obj, key)) {
    return percentage;
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  const { red_yellow_thres, yellow_green_thres } = obj[key];
  /* eslint-enable @typescript-eslint/naming-convention */

  if (number < red_yellow_thres) {
    percentage = (number / red_yellow_thres) * 33.33;
  } else if (number < yellow_green_thres) {
    percentage =
      33.33 +
      ((number - red_yellow_thres) / (yellow_green_thres - red_yellow_thres)) *
        33.33;
  } else {
    percentage =
      66.66 +
      ((number - yellow_green_thres) / (100 - yellow_green_thres)) * 33.33;
  }

  return percentage;
}
