import React from 'react';
import { type HealthScore } from 'types/squares.type';
import { formatToUSD } from 'views/SurvivalAnalysis/Helper/Function/helper.function';
import {
  type KPIHeaderValuePair,
  type MaintenanceDataInterface,
} from '../Interfaces/KPI.interface';
import KPICard from './KPICard';

interface KPIsTabProps {
  assetsItems: HealthScore[];
  totalCosts: {
    total_cost: number;
    total_labour_cost: number;
    total_part_cost: number;
  };
  totaltonnageValue: number;
  maintenanceData: MaintenanceDataInterface;
  totaltonnage: KPIHeaderValuePair[];
  totalDowntimeSaved: KPIHeaderValuePair[];
  totalCostSaved: KPIHeaderValuePair[];
  totalProductivityImproved: KPIHeaderValuePair[];
  handleKpiCardClick: (cardType: string) => void;
}
const KPIsTab = ({
  assetsItems,
  totalCosts,
  totaltonnageValue,
  maintenanceData,
  totaltonnage,
  totalDowntimeSaved,
  totalCostSaved,
  totalProductivityImproved,
  handleKpiCardClick,
}: KPIsTabProps) => {
  const totalNumberOfAssets = [{ header: '', value: assetsItems.length }];
  const totalMaintenanceCostOfAllAssets = [
    {
      header: 'Total',
      value: `$${formatToUSD(totalCosts.total_cost, 'USD')}`,
    },
    {
      header: 'Per Tonnage',
      value: `$${formatToUSD(
        totalCosts.total_cost / Number(totaltonnageValue),
        'USD'
      )}`,
    },
  ];
  const totalCostOfPartsReplacement = [
    {
      header: '',
      value: `$${formatToUSD(totalCosts.total_part_cost, 'USD')}`,
    },
  ];
  const totalCostOfLabor = [
    {
      header: '',
      value: `$${formatToUSD(totalCosts.total_labour_cost, 'USD')}`,
    },
  ];
  const maintenanceCount = [
    {
      header: 'Total',
      value: maintenanceData.totalMaintenanceQuantity.toFixed(0),
    },
    {
      header: 'PM',
      value: maintenanceData.pmTotalQuantity.toFixed(0),
    },
    {
      header: 'CM',
      value: maintenanceData.cmTotalQuantity.toFixed(0),
    },
  ];

  const downtime = [
    {
      header: 'Scheduled',
      value: maintenanceData.pmTotalHours,
    },
    {
      header: 'unScheduled',
      value: maintenanceData.cmTotalHours,
    },
  ];

  return (
    <>
      <KPICard title="Total number of Assets" values={totalNumberOfAssets} />

      <KPICard title="Total tonnage" values={totaltonnage} />

      <KPICard
        title="Potential Downtime Savings"
        values={totalDowntimeSaved}
        totalType="hrs"
      />
      <KPICard title="Potential Cost Savings" values={totalCostSaved} />
      <KPICard
        title="Potential Productivity Improvement"
        values={totalProductivityImproved}
      />

      <KPICard
        title="Total Maintenance Cost of all Assets"
        values={totalMaintenanceCostOfAllAssets}
        onClick={() => {
          handleKpiCardClick('totalMaintenanceCost');
        }}
      />
      <KPICard
        title="Total Cost of Parts - Replacement"
        values={totalCostOfPartsReplacement}
        onClick={() => {
          handleKpiCardClick('partsReplacement');
        }}
      />
      <KPICard
        title="Total Cost of Labor"
        values={totalCostOfLabor}
        onClick={() => {
          handleKpiCardClick('maintenanceHours');
        }}
      />
      <KPICard
        title="Work order Count"
        values={maintenanceCount}
        onClick={() => {
          handleKpiCardClick('workOrderCount');
        }}
      />
      <KPICard title="Downtime" values={downtime} totalType="hrs" />
    </>
  );
};

export default KPIsTab;
