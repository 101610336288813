import { CustomTabPanel } from 'views/Production/CustomPanel/index';
import FuelUsageGridAnalytics from '../components/FuelUsageGridAnalytics';
import { type fuelUsageFleetData } from 'types/fuelAnalytics';
import { type FuelAnalyticsSpecificLoadingStatesInterface } from 'store/fuelAnalytics.slice';
import FuelUsageDateRangeDiv from '../components/FuelUsageDateRangeDiv';
import { Box, Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import FuelUsageBreakdown from '../components/FuelUsageBreakdown';
import { useMemo } from 'react';
import DetailedAnalysis from '../components/DetailedAnalysis';

interface Props {
  value: number;
  index: number;
  enableDarkTheme: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  unitSystem: string;
  customerCode: string;
  loadingStates: FuelAnalyticsSpecificLoadingStatesInterface;
  data: fuelUsageFleetData;
  aggregation: string;
  setAggregation: any;
  shiftNumber: number;
  setShiftNumber: any;
  shiftWiseAnalysis: boolean;
  setShiftWiseAnalysis: any;
  isPrinting: boolean;
  utilizationType: string;
  setUtilizationType: any;
  setDetailsVal: any;
  setAnalyticsData: any;
  setHoursBreakDown: any;
  setUserSelections: any;
  setSuggestionData: any;
  setFuelConsumption: any;
  fuelEventsPieData: Record<string, number>;
  assetType: string;
}

const AssetTypeFuelUsage = ({
  fuelEventsPieData,
  value,
  index,
  enableDarkTheme,
  unitSystem,
  customerCode,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  loadingStates,
  data,
  aggregation,
  setAggregation,
  shiftWiseAnalysis,
  setShiftWiseAnalysis,
  shiftNumber,
  setShiftNumber,
  isPrinting,
  utilizationType,
  setUtilizationType,
  setDetailsVal,
  setAnalyticsData,
  setHoursBreakDown,
  setUserSelections,
  setSuggestionData,
  setFuelConsumption,
  assetType,
}: Props) => {
  const assetTypeKey = useMemo(() => {
    return value === 0 ? 'fleet' : value === 1 ? 'hauler' : 'loader';
  }, [value]);

  const excessFuelUsage = useMemo(() => {
    return Object.values(fuelEventsPieData).reduce(
      (acc, curr) => acc + curr,
      0
    );
  }, [fuelEventsPieData]);

  return (
    <>
      <CustomTabPanel value={value} index={index}>
        <div>
          <FuelUsageDateRangeDiv
            enableDarkTheme={enableDarkTheme}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleDateChange={handleDateChange}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            yesterday={yesterday}
            ignoreYtd={value === 2}
            aggregation={aggregation}
            setAggregation={setAggregation}
            shiftWiseAnalysis={shiftWiseAnalysis}
            setShiftWiseAnalysis={setShiftWiseAnalysis}
            shiftNumber={shiftNumber}
            setShiftNumber={setShiftNumber}
            customerCode={customerCode}
            utilizationType={utilizationType}
            setUtilizationType={setUtilizationType}
          />

          <FuelUsageGridAnalytics
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            isLoading={loadingStates.isLoadingFleetSummary}
            isSelected={isSelected}
            summary={data.summary}
            excessFuelUsage={excessFuelUsage}
            // score={data.score}
            data={data?.summaryChart?.[assetTypeKey]}
            customerCode={customerCode}
            aggregation={aggregation}
            shiftNumber={shiftNumber}
            shiftWiseAnalysis={shiftWiseAnalysis}
            utilizationType={utilizationType}
            setAnalyticsData={setAnalyticsData}
            assetTypeKey={assetTypeKey}
          />

          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <Grid item lg={12} sm={12} md={12}>
              <Grid
                container
                lg={12}
                // spacing={2}
                justifyContent="space-between"
              >
                <FuelUsageBreakdown
                  enableDarkTheme={enableDarkTheme}
                  title={`${assetType} Fuel Usage Breakdown`}
                  isLoading={
                    loadingStates.isLoadingFleetSummary ||
                    loadingStates.loadingFuelAnalyticsWRTDriverEvents
                  }
                  customerCode={customerCode}
                  data={fuelEventsPieData}
                  setHoursBreakDown={setHoursBreakDown}
                  totalFuelConsumption={
                    data?.summary?.[`${assetTypeKey}_total_fuel_consumption_l`]
                  }
                  assetTypeKey={assetTypeKey}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: '20px',
              padding: 2,
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.boxBackground
                : ThemePalette.light.boxBackground,
              borderRadius: '5px',
              border: '1px solid #2F445D',
            }}
          >
            <DetailedAnalysis assetTypeKey={assetTypeKey} />
          </Box>
        </div>
      </CustomTabPanel>
    </>
  );
};

export default AssetTypeFuelUsage;
