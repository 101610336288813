import React from 'react';
import Header from './Header';
import { UploadWorkOrdersView } from '../../UploadWorkOrders/UploadWorkOrdersView';
import { type HealthScore } from 'types/squares.type';

interface Props {
  assetsItems: HealthScore[];
  handleIsWorkOrder: (check: boolean) => void;
  isUploadWorkOrder: boolean;
  handleMonthSelect: (selectedMonth: string | null) => void;
}

const UploadWorkOrderComponent = ({
  assetsItems,
  handleIsWorkOrder,
  handleMonthSelect,
  isUploadWorkOrder,
}: Props) => {
  return isUploadWorkOrder ? (
    <>
      <Header
        allAssets={assetsItems}
        handleIsWorkOrder={handleIsWorkOrder}
        isUploadWorkOrder={isUploadWorkOrder}
        onMonthSelect={handleMonthSelect} // Pass onMonthSelect to Header
      />
      <UploadWorkOrdersView />
    </>
  ) : (
    <></>
  );
};

export default UploadWorkOrderComponent;
