import { ThemePalette } from 'mui.theme';
import { Typography, Grid, Box } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import {
  calculateAssetPartsStats,
  percentageDifference,
  getStatColor,
} from './utils';
import { truncateTo2dp } from 'utils/helpers/general';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';

const Stats = ({
  enableDarkTheme,
  data,
  prevData,
  selectedPart,
  selectedAsset,
}: {
  enableDarkTheme: boolean;
  data: any[];
  prevData: any[];
  selectedPart: string[];
  selectedAsset: string[];
}) => {
  const currentStats = calculateAssetPartsStats(
    data,
    selectedAsset,
    selectedPart
  );

  const prevStats = calculateAssetPartsStats(
    prevData,
    selectedAsset,
    selectedPart
  );

  const cards = [
    {
      title: 'Total number of work orders',
      value: currentStats.woNumber,
      diff: percentageDifference(prevStats.woNumber, currentStats.woNumber),
      inverse: false,
    },
    {
      title: 'Total parts replaced',
      value: currentStats.partsReplaced,
      diff: percentageDifference(
        prevStats.partsReplaced,
        currentStats.partsReplaced
      ),
      inverse: false,
    },
  ];

  return (
    <Grid container spacing={2}>
      {cards.map((card, index) => {
        return (
          <Grid item key={index} lg={6} sm={6} xs={12}>
            <Box
              sx={{
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.boxBackground
                  : ThemePalette.light.toolBarBackground,
                padding: '20px 10px 20px 10px',
                borderRadius: '5px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>{card.title}</Typography>
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '700',
                  marginTop: '5px',
                  display: 'flex',
                }}
              >
                {card.diff > 0 ? (
                  <CallMadeIcon
                    sx={{
                      fontSize: '24px',
                      paddingRight: '5px',
                      paddingTop: '6px',
                      color: getStatColor(Number(card.value), card.inverse),
                    }}
                  />
                ) : (
                  <CallReceivedIcon
                    sx={{
                      fontSize: '24px',
                      paddingRight: '5px',
                      paddingTop: '6px',
                      color: getStatColor(Number(card.value), card.inverse),
                    }}
                  />
                )}
                {formatNumberToShortScale(card.value)}
              </Typography>
              <Typography sx={{ fontSize: '12px', marginTop: '5px' }}>
                <span
                  style={{
                    color: getStatColor(Number(card.value), card.inverse),
                  }}
                >
                  {truncateTo2dp(Math.abs(card.diff))}%
                </span>{' '}
                <span>{card.diff > 0 ? 'More' : 'Less'}</span> than last period
              </Typography>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Stats;
