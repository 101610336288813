import { Box, Typography, Button, Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { truncateTo2dp } from 'utils/helpers/general';
import { formatNumberToShortScale } from 'views/Production/Hauler/component/utils';
import { CircularProgressChart } from './TrendChart';

export const MaintenanceCostTrend = ({
  enableDarkTheme,
  data,
}: {
  enableDarkTheme: boolean;
  data: any[];
}) => {
  const ytdTotalCost = data.reduce((acc: number, curr: any) => {
    return acc + Number(curr?.total_cost ?? 0);
  }, 0);
  const yearData = getYearProgress();

  const trendStats = [
    {
      title: 'Annual Maintenance Budget',
      value: 3000000,
      unit: '$',
    },
    {
      title: 'Remaining Days in the Year',
      value: yearData.daysLeft,
    },
    {
      title: 'Rolling Daily Average Maintenance Cost',
      value: `${Number(truncateTo2dp(ytdTotalCost / yearData.daysSpent))}`,
      unit: '$',
    },
  ];
  return (
    <Box
      sx={{
        marginTop: '10px',
        marginBottom: '20px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.toolBarBackground
          : ThemePalette.light.toolBarBackground,
        padding: '15px 10px 15px 10px',
        borderRadius: '5px',
        border: '1px solid #2F445D',
        minHeight: '420px',
        maxHeight: '420px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
            marginTop: '8px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            fontWeight: 'bolder',
          }}
        >
          Monthly Maintenance Cost Trend
        </Typography>
        <Button
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            border: '1px solid #3666EC',
            textTransform: 'none',
            paddingLeft: '20px',
            paddingRight: '20px',
            borderRadius: '5px',
          }}
        >
          Set Budget
        </Button>
      </Box>
      <Box
        sx={{
          marginTop: '20px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={6} sm={12} md={12} xs={12}>
            <Box
              sx={{
                marginLeft: '20px',
              }}
            >
              <CircularProgressChart
                enableDarkTheme={enableDarkTheme}
                target={3000000}
                value={ytdTotalCost}
              />
              <Box
                sx={{
                  marginTop: '20px',
                }}
              >
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? '#B4B4B4'
                      : ThemePalette.typography.black,
                    fontSize: '12px',
                  }}
                >
                  YTD Target
                </Typography>
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    fontSize: '14px',
                    fontWeight: 'bolder',
                  }}
                >
                  {`$${formatNumberToShortScale(
                    Number(truncateTo2dp(3000000 / yearData.daysSpent))
                  )}`}
                </Typography>
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? '#B4B4B4'
                      : ThemePalette.typography.black,
                    fontSize: '12px',
                    marginTop: '20px',
                  }}
                >
                  Annual Target
                </Typography>
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    fontSize: '14px',
                    fontWeight: 'bolder',
                  }}
                >
                  ${formatNumberToShortScale(3000000)}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} sm={12} md={12} xs={12}>
            <Box>
              {trendStats.map((item, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      borderRadius: '5px',
                      border: '1px solid #2F445D',
                      padding: '20px 15px 20px 15px',
                      marginBottom: '20px',
                    }}
                  >
                    <Typography
                      sx={{
                        color: enableDarkTheme
                          ? '#B4B4B4'
                          : ThemePalette.typography.black,
                        fontSize: '12px',
                        marginBottom: '10px',
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        fontSize: '18px',
                        fontWeight: 'bolder',
                      }}
                    >
                      {item.unit && item.unit}
                      {formatNumberToShortScale(item.value)}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

function getYearProgress(today: any = new Date()) {
  const startOfYear: any = new Date(today.getFullYear(), 0, 1);
  const endOfYear: any = new Date(today.getFullYear(), 11, 31);

  const daysSpent = Math.ceil((today - startOfYear) / (1000 * 60 * 60 * 24));
  const daysLeft = Math.ceil((endOfYear - today) / (1000 * 60 * 60 * 24));

  return { daysSpent, daysLeft };
}
