import { Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { CustomTabPanel } from 'views/Production/CustomPanel/index';
import ProductiveHours from '../components/ProductiveHours';
import DateRangeDiv from 'views/Utilization/HeaderMenu/DateRangeDiv';
import GridAnalytics from '../components/GridAnalytics';
import HoursBreakdown from '../components/HoursBreakdown';
import FuelConsumption from '../components/FuelConsumption';
import Suggestions from 'views/Utilization/components/Suggestions/Suggestions';
import AssetView from '../components/Asset/AssetWrapper';
import type {
  utilizationLoadingStates,
  utilizationFleetData,
} from 'types/utilization.types';
import { aggregateFuelConsumption } from '../AllFleet';
import { getShiftHours } from '../utils';

interface Props {
  value: number;
  enableDarkTheme: boolean;
  isSelected: string;
  setIsSelected: any;
  handleDateChange: any;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  yesterday: any;
  unitSystem: string;
  customerCode: string;
  assetOverview: any;
  loadingStates: utilizationLoadingStates;
  data: utilizationFleetData;
  aggregation: string;
  setAggregation: any;
  shiftNumber: number;
  setShiftNumber: any;
  shiftWiseAnalysis: boolean;
  setShiftWiseAnalysis: any;
  isPrinting: boolean;
  utilizationType: string;
  setUtilizationType: any;
  setDetailsVal: any;
  setAnalyticsData: any;
  setHoursBreakDown: any;
  setUserSelections: any;
  setSuggestionData: any;
  setFuelConsumption: any;
}

const Hauler = ({
  value,
  enableDarkTheme,
  unitSystem,
  customerCode,
  isSelected,
  setIsSelected,
  handleDateChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  yesterday,
  assetOverview,
  loadingStates,
  data,
  aggregation,
  setAggregation,
  shiftWiseAnalysis,
  setShiftWiseAnalysis,
  shiftNumber,
  setShiftNumber,
  isPrinting,
  utilizationType,
  setUtilizationType,
  setDetailsVal,
  setAnalyticsData,
  setHoursBreakDown,
  setUserSelections,
  setSuggestionData,
  setFuelConsumption,
}: Props) => {
  const summaryData = {
    utilization:
      data.summary.hauler_avg_utilization_engine_by_shift_hours * 100,
    utilizationDiff:
      data.summary.hauler_avg_utilization_engine_by_shift_hours_diff,
    idling: data.summary.hauler_avg_idling_duration,
    idlingDiff: data.summary.hauler_avg_idling_duration_diff,
    shiftHours: getShiftHours(customerCode) * 60, // 10.5 in mins,
    shiftHoursDiff: 0,
    engineHours: data.summary.hauler_avg_utilization_engine_working_minute,
    engineHoursDiff:
      data.summary.hauler_avg_utilization_engine_working_minute_diff,
    engineActiveMinutes:
      data.summary.hauler_avg_utilization_engine_active_minute,
    engineActiveMinutesDiff:
      data.summary.hauler_avg_utilization_engine_active_minute_diff,
  };

  const haulerBreakDown = {
    productiveHours: data.summary.hauler_total_engine_working_minutes,
    productiveHoursDiff: data.summary.hauler_total_engine_working_minutes_diff,
    idling: data.summary.hauler_total_idling_minutes,
    idlingDiff: data.summary.hauler_total_idling_minutes_diff,
    noOfAssets: data.summary.number_of_haulers,
    dateRange: data.summary.date_range,
    engineHours: data.summary.hauler_total_engine_active_minutes,
    shiftHours: data.summary.hauler_total_shift_hours,
    engineHoursDiff: data.summary.hauler_total_engine_active_minutes_diff,
    shiftHoursDiff: data.summary.hauler_total_shift_hours_diff,
  };

  const fuelData = {
    total:
      Number(data.summary.hauler_total_productive_fuel_consumption_l) +
      Number(data.summary.hauler_total_idle_fuel_consumption_l),
    totalDiff:
      (Number(data.summary.hauler_total_productive_fuel_consumption_l_diff) *
        Number(data.summary.hauler_total_idle_fuel_consumption_l_diff)) /
      100,
    productive: data.summary.hauler_total_productive_fuel_consumption_l,
    productiveDiff:
      data.summary.hauler_total_productive_fuel_consumption_l_diff,
    idle: data.summary.hauler_total_idle_fuel_consumption_l,
    idleDiff: data.summary.hauler_total_idle_fuel_consumption_l_diff,
  };

  const haulerAssets = data.assets.filter((item) =>
    ['haul_truck', 'light_truck', 'heavy_truck'].includes(item.asset_type)
  );
  const aggregatedData = aggregateFuelConsumption(haulerAssets);

  return (
    <>
      <CustomTabPanel value={value} index={1}>
        <div>
          <ProductiveHours
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            customerCode={customerCode}
            loadingStates={loadingStates}
            chartData={data.fleetPeriodSummary.haulers}
            detailsData={data.fleetDetails.haulers}
            assetType="hauler"
            setDetailsVal={setDetailsVal}
            setUserSelections={setUserSelections}
          />

          <DateRangeDiv
            enableDarkTheme={enableDarkTheme}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            handleDateChange={handleDateChange}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            yesterday={yesterday}
            ignoreYtd={value === 2}
            aggregation={aggregation}
            setAggregation={setAggregation}
            shiftWiseAnalysis={shiftWiseAnalysis}
            setShiftWiseAnalysis={setShiftWiseAnalysis}
            shiftNumber={shiftNumber}
            setShiftNumber={setShiftNumber}
            customerCode={customerCode}
            utilizationType={utilizationType}
            setUtilizationType={setUtilizationType}
          />

          <GridAnalytics
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            assetOverview={assetOverview}
            isLoading={loadingStates.isLoadingFleetSummary}
            isSelected={isSelected}
            summary={summaryData}
            score={data.score}
            data={data.summaryChart.haulers}
            assetType="Haul Truck"
            customerCode={customerCode}
            aggregation={aggregation}
            shiftNumber={shiftNumber}
            shiftWiseAnalysis={shiftWiseAnalysis}
            utilizationType={utilizationType}
            setAnalyticsData={setAnalyticsData}
          />

          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{
              paddingTop: '15px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <Grid item lg={9} sm={12} md={12}>
              <Grid container spacing={2} justifyContent="space-between">
                <HoursBreakdown
                  enableDarkTheme={enableDarkTheme}
                  title="Hauler Hours Breakdown"
                  isLoading={loadingStates.isLoadingFleetSummary}
                  customerCode={customerCode}
                  data={haulerBreakDown}
                  setHoursBreakDown={setHoursBreakDown}
                />
                <FuelConsumption
                  enableDarkTheme={enableDarkTheme}
                  title="Hauler Fuel Consumption"
                  isLoading={loadingStates.isLoadingFleetSummary}
                  data={fuelData}
                  unitSystem={unitSystem}
                  setFuelConsumption={setFuelConsumption}
                />
              </Grid>
            </Grid>
            <Suggestions
              enableDarkTheme={enableDarkTheme}
              minHeight="400px"
              data={aggregatedData}
              isLoading={loadingStates.isLoadingFleetSummary}
              startDate={startDate as string}
              isSelected={isSelected}
              assetType="hauler"
              unitSystem={unitSystem}
              setSuggestionData={setSuggestionData}
            />
          </Grid>
          <AssetView
            enableDarkTheme={enableDarkTheme}
            unitSystem={unitSystem}
            customerCode={customerCode}
            isLoading={loadingStates.isLoadingFleetAssets}
            isSelected={isSelected}
            data={haulerAssets}
            isPrinting={isPrinting}
            utilizationType={utilizationType}
          />
        </div>
      </CustomTabPanel>
    </>
  );
};

export default Hauler;
