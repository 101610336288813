import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { useAppSelector } from 'store/hook';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { Box, Typography } from '@mui/material';
import { VehicleDisplay, DTCCodeDisplay } from './DTCCellrenderer';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import { ThemePalette } from 'mui.theme';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { formatAge } from 'utils/helpers/dateTime';
import DtcHandler from 'handlers/dtc.handler';
import { IsActiveFilter } from './IsActiveFilter';
import { useEffect, useState } from 'react';
import { type DTC } from 'types/dtc.types';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DTCList = ({ enableDarkTheme }: { enableDarkTheme: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const bumperNumberReceived = location.state?.data;

  const dtcHandler = new DtcHandler();
  const { data, isLoading } = useAppSelector((state) => state.dtcReducer);
  const [filteredDTC, setFilteredDTC] = useState(data);

  const { assets } = useAppSelector((state) => state.assetReducer);

  const [selectedStartDate, setStartDate] = useState(
    new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000)
  );
  const [selectedEndDate, setEndDate] = useState(new Date());
  // Helper to calculate unique values
  const getUniqueValues = (field: string) => {
    return Array.from(new Set(filteredDTC?.map((row) => row[field]))).length;
  };

  async function getDtcData(startDate: Date, endDate: Date) {
    const formattedSDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEDate = dayjs(endDate).format('YYYY-MM-DD');
    await dtcHandler.getAll({
      startDate: formattedSDate,
      endDate: formattedEDate,
    });
  }

  useEffect(() => {
    if (bumperNumberReceived?.bumperNumber) {
      const filDTC = data.filter(
        (dtc: DTC) => dtc.asset === bumperNumberReceived.bumperNumber
      );
      setFilteredDTC(filDTC);
    }
  }, [bumperNumberReceived]);

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      void getDtcData(selectedStartDate, selectedEndDate);
    }
  }, [selectedStartDate, selectedEndDate]);

  function convertToUserTimeZone(gmtDate: any) {
    // Assume the input date is in GMT
    const dateInGMT = dayjs.utc(gmtDate);

    // Convert to the user's local time zone
    const localTime = dateInGMT.local();

    return localTime.format('MM/DD/YYYY h:mma'); // You can customize the format if needed
  }

  // Custom value getter for Start column
  function startValueGetter(params: any) {
    return params.data.start_ts
      ? convertToUserTimeZone(params.data.start_ts)
      : null;
  }

  // Custom value getter for End column
  function endValueGetter(params: any) {
    return params.data.end_ts
      ? convertToUserTimeZone(params.data.end_ts)
      : null;
  }

  const [columnDefs] = useState([
    {
      field: 'start_ts',
      headerName: 'DTC Start Time',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate: Date, cellValue: string) => {
          const cellDate = dayjs(cellValue.split(' ')[0]);
          const filterDateComp = dayjs(filterDate);

          if (cellDate.isBefore(filterDateComp, 'day')) {
            return -1;
          } else if (cellDate.isAfter(filterDateComp, 'day')) {
            return 1;
          }
          return 0;
        },
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
      sortable: true,
      valueGetter: startValueGetter,
    },
    {
      field: 'end_ts',
      headerName: 'DTC End Time',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: (filterDate: Date, cellValue: string) => {
          const cellDate = dayjs(cellValue.split(' ')[0]);
          const filterDateComp = dayjs(filterDate);

          if (cellDate.isBefore(filterDateComp, 'day')) {
            return -1;
          } else if (cellDate.isAfter(filterDateComp, 'day')) {
            return 1;
          }
          return 0;
        },
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
      sortable: true,
      valueGetter: endValueGetter,
    },
    {
      field: 'is_active',
      headerName: 'Status',
      filter: IsActiveFilter,
      sortable: true,
      valueFormatter: (params: any) => (params.value ? 'Active' : 'Inactive'),
    },
    {
      field: 'asset',
      headerName: 'Vehicle',
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: getUniqueValues('asset'),
      },
      sortable: true,
      cellRenderer: VehicleDisplay,
      cellRendererParams: {
        field: 'asset',
        handleVehicleClick: async (
          asset: string,
          startTs: string,
          dtcType: string
        ) => {
          const selectedAsset = assets.find(
            (item) => item.bumperNumber === asset
          );
          if (selectedAsset) {
            const dataToPass = {
              assetVin: selectedAsset.assetVin,
              alertStartTs: startTs,
              tagNames: [dtcType],
            };
            let encodedData = encodeURIComponent(JSON.stringify(dataToPass));
            encodedData = encodedData.replace(/%/g, '%25');
            navigate(
              `/sensor-analysis?assetVin=${selectedAsset.assetVin}&data=${encodedData}`
            );
          }
        },
      },
    },
    {
      field: 'dtc_type',
      headerName: 'DTC Type',
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: getUniqueValues('dtc_type'),
      },
      sortable: true,
    },
    {
      field: 'dtc_code',
      headerName: 'DTC Code',
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: getUniqueValues('dtc_code'),
      },
      sortable: true,
      cellRenderer: DTCCodeDisplay,
      tooltipValueGetter: (params: any) => {
        if (params.data.failure_reason) {
          const formattedString = `
          DTC Type Info: ${params.data.dtc_type_info}
          DTC Code Info: ${params.data.dtc_code_info}
          Failure Reason: ${params.data.failure_reason}
          Recommendations:
          - ${params.data.recommendations.slice(1, -1).split(', ').join('\n- ')}
        `;
          return `${formattedString}`;
        }
      },
    },
    {
      field: 'device',
      headerName: 'Sym ID',
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
        maxNumConditions: getUniqueValues('device'),
      },
      sortable: true,
    },
    {
      field: 'age',
      headerName: 'DTC Age',
      sortable: true,
      valueFormatter: (params: any): string => {
        return `${formatAge(params?.value)}`;
      },
    },
  ]);

  const sortedData = [...filteredDTC].sort((a, b) => {
    return dayjs(b.start_ts).isAfter(dayjs(a.start_ts)) ? 1 : -1;
  });

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SingleFadeLoader />
        </div>
      ) : (
        <Box>
          <Box
            sx={{
              paddingLeft: '20px',
              paddingTop: '10px',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                paddingRight: '20px',
                paddingTop: '5px',
              }}
            >
              Date Range
            </Typography>
            <DatePicker
              showIcon
              selected={selectedStartDate}
              onChange={handleDateChange}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              maxDate={new Date(new Date().getTime())}
              selectsRange
              className={
                enableDarkTheme
                  ? 'custom-datepicker-prod'
                  : 'custom-datepicker-prod-light-theme'
              }
            />
          </Box>
          <PaddedListWrapper>
            {sortedData.length < 1 ? (
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                DTC Data not available
              </Typography>
            ) : (
              <BaseTableList
                rowData={sortedData}
                columnDefs={columnDefs}
                cacheQuickFilter={true}
              />
            )}
          </PaddedListWrapper>
        </Box>
      )}
    </>
  );
};
