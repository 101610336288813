import type { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Box, Typography } from '@mui/material';
import { type Asset } from 'types/asset.types';
import { ThemePalette } from 'mui.theme';
import type { DriverBehaviorData } from 'types/driver.behavior.types';
import {
  transformDriverBehaviorData,
  filterDriverBehaviorByDeviceId,
  isAllTimeLineDataEmpty,
} from 'utils/driverBehavior';
import { tooltip } from 'views/EngineHours/Components/tooltip';
import { useAppSelector } from 'store/hook';
import dayjs from 'dayjs';

interface Props {
  enableDarkTheme: boolean;
  assets: Asset[];
  selectedAsset: string;
  timelineDate: string;
  setSelectedAsset: any;
  latestTagData: any;
  hierarchyInfo: any;
  data: DriverBehaviorData;
  startDate: Date;
  endDate: Date;
}

const AssetDisplay = ({
  enableDarkTheme,
  assets,
  selectedAsset,
  data,
  startDate,
  endDate,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const filterStartDate = dayjs(startDate);
  const filterEndDate = dayjs(endDate);

  const chartOptions: ApexOptions = {
    chart: {
      height: 200,
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
      zoom: {
        enabled: false,
      },
    },
    tooltip: {
      custom: (props) =>
        tooltip({
          ...props,
          unitSystem,
        }),
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%',
        borderRadius: 4,
      },
    },

    colors: ['#FEB019', '#FF4560', '#D2448F', '#325C9F', '#777777', '#00E396'],
    xaxis: {
      type: 'datetime',
      position: 'bottom',
      labels: {
        datetimeUTC: false,
        format: 'HH:mm',
        formatter: function (value) {
          const localOffset = new Date().getTimezoneOffset();

          const localTimestamp = Number(value) - localOffset * 60000;

          const localDate = new Date(localTimestamp);

          const localTime = localDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          });

          return localTime;
        },
        style: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
      },
      tickAmount: 6,
    },
    yaxis: {
      show: false,
      labels: {
        style: {
          colors: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        },
      },
    },
    stroke: {
      width: 1,
    },
    fill: {
      type: 'solid',
      opacity: 0.6,
    },
    legend: {
      show: false,
      position: 'top',
      labels: {
        colors: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      },
    },
  };

  const selectedAssetDeviceId = assets.find(
    (item) => item.bumperNumber === selectedAsset
  )?.bumperNumber;

  let timeLineData: any = [];

  if (selectedAssetDeviceId) {
    const assetData = filterDriverBehaviorByDeviceId(
      data,
      selectedAssetDeviceId
    );

    timeLineData = transformDriverBehaviorData(assetData);

    timeLineData = timeLineData.map((item: any) => ({
      ...item,
      data: item.data
        .filter((entry: any) => {
          const startTs = dayjs.utc(entry.start_ts);
          const endTs = dayjs.utc(entry.end_ts);

          return (
            startTs.isAfter(filterStartDate) && endTs.isBefore(filterEndDate)
          );
        })
        .map((entry: any) => ({
          ...entry,
          start_ts: dayjs
            .utc(entry.start_ts)
            .local()
            .format('YYYY-MM-DD HH:mm:ss.sss'),
          end_ts: dayjs
            .utc(entry.end_ts)
            .local()
            .format('YYYY-MM-DD HH:mm:ss.sss'),
        })),
    }));
  }

  const isDataEmpty = isAllTimeLineDataEmpty(timeLineData);

  return (
    <Box
      sx={{
        padding: '10px',
        border: '1px solid #2F445E',
        borderRadius: '4px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        marginTop: '20px',
        marginBottom: '20px',
      }}
    >
      <div
        style={{
          padding: '20px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        {isDataEmpty ? (
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            No timeline data available for the selected time range.
          </Typography>
        ) : (
          <ReactApexChart
            options={chartOptions}
            series={timeLineData}
            type="rangeBar"
            height={200}
          />
        )}
      </div>
    </Box>
  );
};

export default AssetDisplay;
