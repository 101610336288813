import { Box } from '@mui/material';
import AssetItemDetails from './AssetDetails';
import AssetItemChart from './AssetChart';
import { ThemePalette } from 'mui.theme';
import type { DeviceStats } from 'types/utilization.types';

interface Props {
  enableDarkTheme: boolean;
  data: DeviceStats;
  selectedSource: string[];
  unitSystem: string;
  customerCode: string;
  utilizationType: string;
}

const AssetItem = ({
  enableDarkTheme,
  data,
  selectedSource,
  unitSystem,
  customerCode,
  utilizationType,
}: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? '#19304F'
          : ThemePalette.light.toolBarBackground,
        padding: '16px',
        borderRadius: '8px',
        marginBottom: '10px',
      }}
      data-attribute="assetItemBox"
    >
      <AssetItemDetails
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        customerCode={customerCode}
        data={data}
        utilizationType={utilizationType}
      />
      <AssetItemChart
        enableDarkTheme={enableDarkTheme}
        unitSystem={unitSystem}
        customerCode={customerCode}
        selectedSource={selectedSource}
        data={data}
      />
    </Box>
  );
};

export default AssetItem;
