import { Box, Tooltip, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import React from 'react';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

interface Props {
  allTitles: string[];
  overflowAfter: number;
}

const OverflowingTitles = ({ allTitles, overflowAfter }: Props) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  return (
    <Box sx={{ minHeight: '20px' }}>
      <Tooltip title={`${allTitles?.slice(overflowAfter)?.join(', ')}`} arrow>
        <Typography
          sx={{
            fontSize: '12px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            textDecoration: 'underline',
            cursor: 'none',
          }}
        >
          {allTitles?.length > 2 &&
            `+${allTitles?.slice(2)?.length} more asset${
              allTitles?.slice(2)?.length > 1 ? 's' : ''
            }`}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default OverflowingTitles;
