import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { type Asset } from 'types/asset.types';
import LoadingDots from '../../LoadingDots';
import { truncateTo2dp } from 'utils/helpers/general';
import { getFuelUsageDiff } from '../../utils';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';
import { SWToolTip } from 'components/SWToolTip';
import { ThemePalette } from 'mui.theme';
import { useAppSelector } from 'store/hook';
import {
  fuelIdleConsumptionLabels,
  getFuelLabelWrtUnitSystem,
  getHourlyFuelEfficencyLabelWrtUnitSystem,
} from 'utils/helpers/labels';

interface Props {
  asset: Asset;
  enableDarkTheme: boolean;
  isLoadingAllAssets: boolean;
  dateComparer: string;
}

const AssetCardFuelUsage = ({
  asset,
  enableDarkTheme = false,
  isLoadingAllAssets,
  dateComparer,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const assetDiff = getFuelUsageDiff(
    asset.fuelUsage,
    asset.utilization,
    asset.productivity,
    dateComparer
  );
  const navigate = useNavigate();
  const type = assetTypeMappings[asset.assetType];
  return (
    <SWToolTip
      title={`Total Fuel Used: ${
        asset.fuelUsage?.fuel_consumption_l !== undefined
          ? asset.fuelUsage?.fuel_consumption_l
          : 0
      } ${getFuelLabelWrtUnitSystem(
        unitSystem,
        fuelIdleConsumptionLabels.total_fuel_consumed
      )} | Click for drill-down to view Fuel Usage for ${asset.bumperNumber}`}
    >
      <div
        onClick={() => {
          if (!isLoadingAllAssets) {
            navigate(`/fuel-usage`, {
              state: {
                type,
                value: 0,
                device: asset.device.deviceId,
              },
            });
          }
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <Typography
          sx={{
            fontSize: '11px',
          }}
        >
          Fuel Usage
        </Typography>
        <Typography
          style={{
            fontSize: '16px',
          }}
        >
          {asset.fuelUsage?.hourly_fuel_consumption_l ? (
            <>
              {asset.fuelUsage?.hourly_fuel_consumption_l}{' '}
              {getHourlyFuelEfficencyLabelWrtUnitSystem(
                unitSystem,
                fuelIdleConsumptionLabels.total_fuel_consumed
              )}
            </>
          ) : (
            <>
              {isLoadingAllAssets ? (
                <LoadingDots enableDarkTheme={enableDarkTheme} />
              ) : (
                <>n/a</>
              )}
            </>
          )}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {assetDiff?.fuelUsage ? (
            <>
              {assetDiff?.fuelUsage < 0 ? (
                <CallMadeIcon
                  sx={{
                    paddingTop: '3px',
                    paddingRight: '5px',
                    fontSize: '10px',
                    color: ThemePalette.typography.lightDanger,
                  }}
                />
              ) : (
                <CallReceivedIcon
                  sx={{
                    paddingTop: '3px',
                    paddingRight: '5px',
                    fontSize: '10px',
                    color: ThemePalette.typography.lightSuccess,
                  }}
                />
              )}
              <span
                style={{
                  color:
                    assetDiff.fuelUsage < 0
                      ? ThemePalette.typography.lightDanger
                      : ThemePalette.typography.lightSuccess,
                }}
              >
                {truncateTo2dp(Math.abs(assetDiff.fuelUsage))}%
              </span>
            </>
          ) : (
            <>
              {isLoadingAllAssets ? (
                <LoadingDots enableDarkTheme={enableDarkTheme} />
              ) : (
                <>n/a</>
              )}
            </>
          )}
        </Typography>
      </div>
    </SWToolTip>
  );
};

export default AssetCardFuelUsage;
