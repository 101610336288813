import { Box } from '@mui/material';
import React from 'react';
import KPICard from './KPICard';
import KPIPieChart from '../Helpers/KPi.Pie';
import {
  type KPIHeaderValuePair,
  type MaintenanceDataInterface,
} from '../Interfaces/KPI.interface';
import useAppTheme from 'hooks/useAppTheme';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';

interface Props {
  totaltonnage: KPIHeaderValuePair[];
  totalCosts: {
    total_cost: number;
    total_labour_cost: number;
    total_part_cost: number;
  };
  maintenanceData: MaintenanceDataInterface;
}

const GraphsTab = ({ totaltonnage, totalCosts, maintenanceData }: Props) => {
  const { enableDarkTheme } = useAppTheme();

  return (
    <>
      <Box marginBottom={1}>
        <KPICard title="Total tonnage" values={totaltonnage} totalType="Tons" />
        <KPIPieChart
          title="Maintenance Costs Overview"
          label1="Parts"
          label2="Labour"
          number1={Number(totalCosts.total_part_cost.toFixed(2))}
          number2={Number(totalCosts.total_labour_cost.toFixed(2))}
          totalType="USD"
          enableDarkTheme={enableDarkTheme}
          precision={2}
          color1={ThemePalette.pie.blue}
          color2={ThemePalette.pie.green}
        />
      </Box>
      <Box marginBottom={1}>
        <KPIPieChart
          title="Maintenance Costs - PM vs CM"
          label1="PM"
          label2="CM"
          number1={maintenanceData.pmTotalCost}
          number2={maintenanceData.cmTotalCost}
          totalType="USD"
          enableDarkTheme={enableDarkTheme}
          precision={2}
          color1={ThemePalette.pie.blue}
          color2={ThemePalette.pie.green}
        />
      </Box>{' '}
      <Box marginBottom={1}>
        <KPIPieChart
          title="Work order Count - PM vs CM"
          label1="PM"
          label2="CM"
          number1={maintenanceData.pmTotalQuantity}
          number2={maintenanceData.cmTotalQuantity}
          totalType=""
          enableDarkTheme={enableDarkTheme}
          precision={0}
          color1={ThemePalette.pie.blue}
          color2={ThemePalette.pie.green}
        />
      </Box>{' '}
      <Box marginBottom={1}>
        <KPIPieChart
          title="Maintenance Hours - PM vs CM"
          label1="PM"
          label2="CM"
          number1={maintenanceData.pmTotalHours}
          number2={maintenanceData.cmTotalHours}
          totalType="hrs"
          enableDarkTheme={enableDarkTheme}
          precision={0}
          color1={ThemePalette.pie.blue}
          color2={ThemePalette.pie.green}
        />
      </Box>
    </>
  );
};

export default GraphsTab;
