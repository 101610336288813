/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { type AssetComponentCostInterface } from 'store/SquaresStore/DashboardSquaresState.slice';

export interface CategoryData {
  name: string;
  totalCost: number;
  totalWorkOrders: string;
  totalHours: string;
}

export interface Asset {
  asset: string;
  total_asset_cost: number;
}

export function extractWorkOrderData(
  data: Record<string, any>
): CategoryData[] {
  const result: CategoryData[] = [];
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const {
        total_cost: totalCost,
        total_work_orders: totalWorkOrders,
        total_hours: totalHours,
      } = data[key];
      const newObj = {
        totalCost,
        totalWorkOrders,
        totalHours,
        name: key,
        size: 1,
      };
      result.push(newObj);
    }
  }
  result.sort((a, b) => {
    return b.totalCost - a.totalCost;
  });
  return result;
}

export function getAssetsByKey(
  data: Record<string, any>,
  key: string
): Asset[] | [] {
  const category = data?.[key];
  if (category?.assets) {
    const assetsWithNames = category.assets.map((asset: Asset) => ({
      ...asset,
      name: asset.asset, // Set 'name' to the value of 'asset'
    }));
    return assetsWithNames;
  }
  return [];
}

export function extractSelectedAssets(
  dataset: Record<string, AssetComponentCostInterface[]>,
  selectedLines: string[],
  selectedComponent: string
): any {
  const extractedData: any = {};
  selectedLines.forEach((lineKey: any) => {
    if (dataset[lineKey]) {
      extractedData[lineKey] = dataset[lineKey]?.filter(
        (item: any) => item.component === selectedComponent
      );
    }
  });
  const output: any[] = [];

  for (const key in extractedData) {
    if (Object.prototype.hasOwnProperty.call(extractedData, key)) {
      output.push({
        name: key,
        data: extractedData[key],
      });
    }
  }

  return output;
}

export function getFirstAttribute(obj: any): string {
  const arr = [];
  let response = '';
  // Check if the object is empty
  if (obj === undefined || Object.keys(obj).length === 0) {
    response = '';
  }

  for (const key in obj) {
    arr.push({ [key]: obj[key].total_cost || 0 });
  }

  if (arr.length > 0) {
    const sortedArr = arr.sort((a, b) => {
      const costA = Object.values(a)[0];
      const costB = Object.values(b)[0];
      return costB - costA;
    });

    const highestKey = Object.keys(sortedArr[0])[0];
    response = highestKey;
  }

  return response;
}

export function aggregateSubcomponentData(data: any[]) {
  const resultMap = new Map();

  data.forEach((item) => {
    const subComponent = item.sub_component_categories;
    const totalCost: number = item.total_cost || 0;
    const workOrderDate: any = item.date_of_orders
      ? new Date(item.date_of_orders)
      : null;
    const completionDate: any = item.date_of_completion
      ? new Date(item.date_of_completion)
      : null;

    let totalHours = 0;
    if (workOrderDate && completionDate) {
      totalHours = Math.abs(completionDate - workOrderDate) / (1000 * 60 * 60); // Convert milliseconds to hours
    }

    if (!resultMap.has(subComponent)) {
      resultMap.set(subComponent, {
        totalCost: 0,
        totalWorkOrders: 0,
        totalHours: 0,
        name: subComponent,
        size: 1,
      });
    }

    const entry: {
      totalCost: number;
      totalWorkOrders: number;
      totalHours: number;
    } = resultMap.get(subComponent);
    entry.totalCost += totalCost;
    entry.totalWorkOrders += 1;
    entry.totalHours += totalHours;
  });

  return Array.from(resultMap.values());
}

export function aggregateByAssetId(data: any[]) {
  const assetMap = new Map();

  data.forEach((item) => {
    const assetId: any = item.asset_id;
    const totalCost: number = item.total_cost || 0;

    if (!assetMap.has(assetId)) {
      assetMap.set(assetId, {
        asset: assetId,
        total_asset_cost: 0,
        name: assetId,
      });
    }

    assetMap.get(assetId).total_asset_cost += totalCost;
  });

  return Array.from(assetMap.values());
}

export function getLineChartData(
  assets: any[],
  data: any[],
  selectedAssets: any[]
) {
  return assets
    .filter((item) => [...selectedAssets].includes(item.name))
    .map((item) => {
      const assetId = item.name;
      const assetData = data
        .filter((wo) => wo.asset_id === assetId)
        .map((wo) => {
          const date = new Date(wo.date_of_orders);
          const timestampDate = Math.floor(date.getTime() / 1000);
          return {
            asset_id: assetId,
            component: wo.sub_component_categories,
            timestamp: timestampDate,
            total_cost_asset: wo.total_cost,
            total_labour_cost: wo.total_cost,
            total_part_cost: wo.total_cost,
            work_order_number: wo.work_order_number,
          };
        });
      return {
        name: assetId,
        data: [...assetData],
      };
    });
}
