import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { type Asset } from 'types/asset.types';
import { useEffect, useState } from 'react';
import Header from './Header';
import MainBody from './MainBody';
import { getAssetMakeModel } from 'views/FleetOverview/components/AssetCardsV2/utils';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';
import VehicleStatusHandler from 'handlers/vehicleStatus.handler';

const VehicleStatus = () => {
  const vehicleStatusHandler = new VehicleStatusHandler();

  const { latestTagData } = useAppSelector((state) => state.deviceReducer);
  const hierarchyInfo = useAppSelector((state) => state.hierarchyReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const { isLoading } = useAppSelector((state) => state.appReducer);

  const { assets } = useAppSelector((state) => state.assetReducer);
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const { notifications } = useAppSelector(
    (state) => state.ruleNotificationsReducer
  );

  const defaultAsset: string | null = new URLSearchParams(location.search).get(
    'asset'
  );
  const makeModels = getAssetMakeModel(assets);

  const [selectedMakeModel, setSelectedMakeModel] = useState(() => {
    if (makeModels.length > 0) {
      return makeModels[0];
    } else {
      return '';
    }
  });
  const [assetBumperIds, setAssetBumperIds] = useState<string[]>([]);

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const [healthData, setHealthData] = useState<any>();

  const handleSelectAsset = (bumperNumber: string) => {
    const item = assets.find((asset) => asset.bumperNumber === bumperNumber);
    if (item) {
      setSelectedAsset(item);
    }
  };

  useEffect(() => {
    const filteredAssets = assets
      ?.filter(
        (asset) =>
          composeUniqueKey([asset.make, asset.model]) ===
          selectedMakeModel.replace('-', '!')
      )
      .map((item) => {
        return item.bumperNumber;
      });
    setAssetBumperIds(filteredAssets);
    setSelectedAsset(
      assets.find((asset) => asset.bumperNumber === filteredAssets[0]) as Asset
    );
  }, [selectedMakeModel]);

  useEffect(() => {
    let assetDetails: Asset | undefined;

    if (defaultAsset) {
      assetDetails = assets.find((item) => item.bumperNumber === defaultAsset);
    } else {
      assetDetails = assets[0];
    }

    if (assetDetails) {
      const assetMakeModel = composeUniqueKey(
        [assetDetails?.make, assetDetails?.model],
        '-'
      );
      setSelectedAsset(assetDetails);
      setSelectedMakeModel(assetMakeModel);
    }
  }, [assets]);

  const getVehicleStatusData = async () => {
    const {
      tireData,
      braking,
      overspeed,
      acceleration,
      gearSkip,
      idleFuel,
      underspeed,
      incorrectGearToSpeed,
      incorrectGearDuringDirection,
      airFilters,
    }: any = await vehicleStatusHandler.getHealthData(selectedAsset as Asset);

    setHealthData({
      tireData,
      braking,
      overspeed,
      acceleration,
      gearSkip,
      idleFuel,
      underspeed,
      incorrectGearToSpeed,
      incorrectGearDuringDirection,
      airFilters: airFilters.Items,
    });
  };

  useEffect(() => {
    if (selectedAsset?.assetId && selectedAsset?.device?.deviceId) {
      void getVehicleStatusData();
    }
  }, [selectedAsset]);

  const assetNotifications = notifications.filter(
    (item) => item.assetId === selectedAsset?.assetId && item.isActive
  );

  return (
    <>
      {selectedAsset && (
        <>
          <Header
            enableDarkTheme={enableDarkTheme}
            latestTagData={latestTagData}
            hierarchyInfo={hierarchyInfo}
            selectedAsset={selectedAsset}
            makeModels={makeModels}
            assetBumperIds={assetBumperIds}
            setSelectedAsset={handleSelectAsset}
            selectedMakeModel={selectedMakeModel}
            setSelectedMakeModel={setSelectedMakeModel}
            notifications={assetNotifications}
          />
          {!isLoading && (
            <MainBody
              enableDarkTheme={enableDarkTheme}
              healthData={healthData}
              unitSystem={unitSystem}
              makeModel={`${selectedAsset?.make.toLowerCase()}${selectedAsset?.model.toLowerCase()}`}
              asset={selectedAsset}
              notifications={assetNotifications}
            />
          )}
        </>
      )}
    </>
  );
};

export default VehicleStatus;
