import React, { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import { useAppSelector } from 'store/hook';
import { Filter } from './Filter';
import AssetChart from './AssetChart';
import PartsChart from './PartsChart';
import Stats from './Stats';
import AssetDetails from './AssetDetails';
import PartsTable from './PartsTable';

export const PartsRecommendationView = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  const { assets } = useAppSelector((state) => state.assetReducer);
  const {
    data: stateData,
    prevData,
    healthData,
    metadata,
    isLoading,
  } = useAppSelector((state) => state.squaresPartsRecommendationsReducer);

  const data = useMemo(() => {
    const bumperIds = assets.map((item: any) => {
      return item.bumperNumber;
    });
    return stateData.filter((item) => bumperIds.includes(item.asset_id));
  }, [stateData]);

  const uniqueAssets = useMemo(() => {
    return [...new Set(data.map((item) => item.asset_id))].filter(
      (item) => !['nan', 'None'].includes(item)
    );
  }, [data]);

  const uniqueComponentCategories = useMemo(() => {
    return [...new Set(data.map((item) => item.components_category))];
  }, [data]);

  const squaresHandler = new SquaresDashboardHandler();
  let initialStartDate = new Date();
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1);
  let initialEndDate = new Date();

  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );

  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedAsset, setSelectedAsset] = useState(uniqueAssets);
  const [selectedPart, setSelectedPart] = useState(uniqueComponentCategories);
  const [selectedStartDate, setStartDate] = useState(initialStartDate);
  const [selectedEndDate, setEndDate] = useState(initialEndDate);
  const [selectedFrequency, setSelectedFrequency] = useState('Monthly');
  const [isSelected, setIsSelected] = useState(
    formerSelectedDates ? 'Custom' : '1Y'
  );

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);

    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      void squaresHandler.getPartsRecommendations(
        selectedStartDate,
        selectedEndDate
      );
    }
  }, [selectedStartDate, selectedEndDate]);

  useEffect(() => {
    setSelectedAsset(uniqueAssets);
  }, [uniqueAssets]);

  useEffect(() => {
    setSelectedPart(uniqueComponentCategories);
  }, [uniqueComponentCategories]);

  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.pageBackground
          : ThemePalette.light.pageBackground,
        padding: '10px',
      }}
    >
      {isLoading ? (
        <SingleFadeLoader />
      ) : (
        <>
          <Filter
            enableDarkTheme={enableDarkTheme}
            assets={uniqueAssets}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
            categories={uniqueComponentCategories}
            selectedPart={selectedPart}
            setSelectedPart={setSelectedPart}
            selectedStartDate={selectedStartDate}
            handleDateChange={handleDateChange}
            selectedEndDate={selectedEndDate}
            selectedFrequency={selectedFrequency}
            setSelectedFrequency={setSelectedFrequency}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            isLoading={isLoading}
          />

          <Stats
            enableDarkTheme={enableDarkTheme}
            data={data}
            prevData={prevData}
            selectedPart={selectedPart}
            selectedAsset={selectedAsset}
          />
          <Grid container spacing={2}>
            <Grid item lg={9} sm={12} md={9} xs={12}>
              <AssetChart
                enableDarkTheme={enableDarkTheme}
                data={data}
                selectedAssets={selectedAsset}
                selectedPart={selectedPart}
              />
            </Grid>
            <Grid item lg={3} sm={12} md={3} xs={12}>
              <AssetDetails
                enableDarkTheme={enableDarkTheme}
                data={data}
                selectedPart={selectedPart}
                selectedAsset={selectedAsset}
                assets={assets}
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: '20px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.boxBackground
                : ThemePalette.light.toolBarBackground,
              borderRadius: '5px',
              paddingTop: '20px',
              paddingBottom: '20px',
            }}
          >
            <PartsChart
              enableDarkTheme={enableDarkTheme}
              data={data}
              selectedPart={selectedPart}
              selectedFrequency={selectedFrequency}
              selectedAsset={selectedAsset}
            />
          </Box>

          <PartsTable
            enableDarkTheme={enableDarkTheme}
            data={data}
            healthData={healthData}
            selectedPart={selectedPart}
            selectedAsset={selectedAsset}
            metadata={metadata}
          />
        </>
      )}
    </Box>
  );
};
