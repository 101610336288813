import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Import a drill-down icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import a drill-down icon
import { PmVsCmVsModification } from './Modals/PmVsCmVsModification';
import { PmVsCmExpenditure } from './Modals/PmVsCmExpenditure';
import {
  useFetchPmVsCmVsModification,
  useFetchPmVsCmExpenditures,
} from './Helper/fetchData';
import Colors from '../../Helpers/Graphs/styles';
import { MaintenanceEntityManagementView } from '../../Maintenance/MaintenanceEntityManagementView';
import useAppTheme from 'hooks/useAppTheme';

export const MaintenanceComparisonView = () => {
  const { enableDarkTheme } = useAppTheme();
  const [showMaintenanceEntity, setShowMaintenanceEntity] = useState(false); // State to manage the view
  const fetchPmVsCmVsModification = useFetchPmVsCmVsModification();
  const fetchPmVsCmExpenditures = useFetchPmVsCmExpenditures();

  // Conditional rendering
  if (showMaintenanceEntity) {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Align back icon left and drill-down icon right
            alignItems: 'center',
            padding: 2, // Add padding as needed
          }}
        >
          {/* Back Arrow IconButton */}
          <IconButton
            onClick={() => {
              setShowMaintenanceEntity(false);
            }}
            sx={{
              color: enableDarkTheme ? 'white' : 'black', // Adjust colors for dark theme
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
        <MaintenanceEntityManagementView />
      </>
    );
  }

  return (
    <Box>
      {/* Flex container for the drill-down icon */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end', // Align the icon to the right
          alignItems: 'center',
          padding: 2, // Add padding as needed
        }}
      >
        {/* Drill-down IconButton */}
        <IconButton
          onClick={() => {
            setShowMaintenanceEntity(true);
          }} // Show MaintenanceEntityManagementView when clicked
          sx={{
            color: enableDarkTheme ? 'white' : 'black', // Adjust colors for dark theme
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>

      {/* Main Grid Layout */}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PmVsCmVsModification
            dataKey="total_cost"
            dataLabel="Total Cost"
            fetchSquaresData={fetchPmVsCmVsModification}
            graphHeader="Cost Totals"
            yAxisLabel="Cost ($)"
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
        <Grid item xs={6}>
          <PmVsCmVsModification
            dataKey="total_quantity"
            dataLabel="Total Quantity"
            fetchSquaresData={fetchPmVsCmVsModification}
            graphHeader="by Count"
            yAxisLabel="Count"
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
        <Grid item xs={6}>
          <PmVsCmVsModification
            dataKey="total_hours"
            dataLabel="Total Hours"
            fetchSquaresData={fetchPmVsCmVsModification}
            graphHeader="Hours Totals"
            yAxisLabel="Hours"
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
        <Grid item xs={6}>
          <PmVsCmExpenditure
            fetchSquaresData={fetchPmVsCmExpenditures}
            graphHeader="Expenditures"
            barKeys={[
              {
                name: 'grand_total_cost',
                color: Colors.orange,
                label: 'Grand total cost',
              },
              {
                name: 'total_labour_cost',
                color: Colors.blue,
                label: 'Total labour cost',
              },
              {
                name: 'total_part_cost',
                color: Colors.purple,
                label: 'Total part cost',
              },
            ]}
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
