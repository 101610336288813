import dayjs from 'dayjs';
import {
  Box,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  ListItemText,
  ButtonGroup,
  Button,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import { ThemePalette } from 'mui.theme';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';

export const Filter = ({
  enableDarkTheme,
  assets,
  selectedAsset,
  setSelectedAsset,
  categories,
  selectedPart,
  setSelectedPart,
  handleDateChange,
  selectedStartDate,
  selectedEndDate,
  isSelected,
  setIsSelected,
  setStartDate,
  setEndDate,
  isLoading,
}: {
  enableDarkTheme: boolean;
  assets: string[];
  selectedAsset: string[];
  setSelectedAsset: any;
  categories: any[];
  selectedPart: string[];
  setSelectedPart: any;
  handleDateChange: any;
  selectedStartDate: any;
  selectedEndDate: any;
  selectedFrequency: any;
  setSelectedFrequency: any;
  isSelected: string;
  setIsSelected: any;
  setStartDate: any;
  setEndDate: any;
  isLoading: boolean;
}) => {
  const handleSelect = (id: string) => {
    setIsSelected(id);
    let newStartDate = new Date();
    const newEndDate = new Date();

    switch (id) {
      case '1M':
        newStartDate = dayjs(newStartDate).subtract(31, 'day').toDate();
        break;
      case '3M':
        newStartDate = dayjs(newStartDate).subtract(91, 'days').toDate();
        break;
      case '1Y':
        newStartDate = dayjs(newStartDate).subtract(1, 'year').toDate();
        break;
      case 'YTD':
        newStartDate = dayjs(newStartDate).startOf('year').toDate();
        break;
      case 'Custom':
        break;
      default:
        break;
    }

    if (id !== 'Custom') {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  const dateRangeList = ['1M', '3M', '1Y', 'YTD', 'Custom'];

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '10px',
        marginBottom: '20px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.toolBarBackground
          : ThemePalette.light.toolBarBackground,
        padding: '15px 10px 15px 10px',
        borderRadius: '5px',
      }}
    >
      <Typography
        style={{
          fontSize: '12px',
          marginRight: '10px',
          paddingTop: '7px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Quick Range:
      </Typography>
      <ButtonGroup
        size="small"
        variant="outlined"
        aria-label="Basic button group"
        sx={{
          border: '1px solid #2F445D',
          marginRight: '10px',
        }}
        disabled={isLoading}
      >
        {dateRangeList.map((item) => {
          return (
            <Button
              key={item}
              size="small"
              style={{
                backgroundColor:
                  isSelected === item
                    ? '#3666EC'
                    : enableDarkTheme
                    ? ThemePalette.dark.cartesianGrid
                    : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
              onClick={() => {
                handleSelect(item);
              }}
            >
              {item}
            </Button>
          );
        })}
      </ButtonGroup>
      <Typography
        sx={{
          paddingTop: '7px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          marginRight: '10px',
        }}
      >
        Date Range
      </Typography>

      <DatePicker
        showIcon
        selected={selectedStartDate}
        onChange={handleDateChange}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        maxDate={new Date(new Date().getTime())}
        selectsRange
        className={
          enableDarkTheme
            ? 'custom-datepicker-prod-squares'
            : 'custom-datepicker-prod-light-theme'
        }
        disabled={isLoading || isSelected !== 'Custom'}
      />

      <Typography
        sx={{
          paddingTop: '7px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          marginLeft: '10px',
        }}
      >
        Assets
      </Typography>
      {/** Assets Selector */}
      <Select
        multiple
        labelId="parts-recommendation-selected-asset"
        value={selectedAsset}
        renderValue={(selected) => {
          return `${selected?.length || 0} Asset(s) Selected`;
        }}
        onChange={(e) => {
          const selectedValues = e.target.value;
          if (selectedValues.includes('select-all')) {
            const allSelected = selectedAsset.length === assets.length;
            if (allSelected) {
              setSelectedAsset([]);
            } else {
              setSelectedAsset([...assets]);
            }
          } else if (selectedValues.length > 0) {
            setSelectedAsset(selectedValues);
          }
        }}
        sx={{
          height: '40px',
          fontSize: '14px',
          marginLeft: '10px',
          marginRight: '20px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.cartesianGrid
            : ThemePalette.light.menuOptions,
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          '& .MuiSelect-icon': {
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          },
          '& fieldset': {
            borderColor: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          },
          ...(enableDarkTheme && {
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#2F445D',
              },
            },
          }),
        }}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.menuOptions
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '14px',
            },
          },
        }}
      >
        <MenuItem
          key="select-all-option"
          value="select-all"
          sx={{
            fontSize: '14px',
            borderBottom: '1px solid',
            borderColor: enableDarkTheme ? '#2F445D' : '#E0E0E0',
          }}
        >
          <Checkbox
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.cornflowerBlue
                : ThemePalette.typography.primary,
              '&.Mui-checked': {
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
              },
              '&.Mui-disabled': {
                color: ThemePalette.typography.secondary,
              },
            }}
            checked={
              selectedAsset.length === assets.length && assets.length > 0
            }
            indeterminate={
              selectedAsset.length > 0 && selectedAsset.length < assets.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>

        {assets.map((asset, index) => (
          <MenuItem
            key={index}
            value={asset}
            sx={{
              fontSize: '14px',
            }}
          >
            <Checkbox
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
                '&.Mui-checked': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                },
                '&.Mui-disabled': {
                  color: ThemePalette.typography.secondary,
                },
              }}
              checked={selectedAsset.includes(asset)}
            />
            <ListItemText primary={asset} />
          </MenuItem>
        ))}
      </Select>

      {/** Parts Selector */}
      <Typography
        sx={{
          paddingTop: '7px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Parts
      </Typography>
      <Select
        labelId="parts-recommendation-parts"
        multiple
        value={selectedPart}
        renderValue={(selected) => {
          return `${selected?.length || 0} Part(s) Selected`;
        }}
        onChange={(e) => {
          const selectedValues = e.target.value;
          if (selectedValues.includes('select-all')) {
            const allSelected = selectedPart.length === categories.length;
            if (allSelected) {
              setSelectedPart([]);
            } else {
              setSelectedPart([...categories]);
            }
          } else if (selectedValues.length > 0) {
            setSelectedPart(selectedValues);
          }
        }}
        sx={{
          height: '40px',
          fontSize: '14px',
          marginLeft: '10px',
          marginRight: '20px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.cartesianGrid
            : ThemePalette.light.menuOptions,
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          '& .MuiSelect-icon': {
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          },
          '& fieldset': {
            borderColor: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          },
          ...(enableDarkTheme && {
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#2F445D',
              },
            },
          }),
        }}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.menuOptions
                : ThemePalette.light.menuOptions,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              fontSize: '14px',
            },
          },
        }}
      >
        <MenuItem
          key="select-all-option"
          value="select-all"
          sx={{
            fontSize: '14px',
            borderBottom: '1px solid',
            borderColor: enableDarkTheme ? '#2F445D' : '#E0E0E0',
          }}
        >
          <Checkbox
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.cornflowerBlue
                : ThemePalette.typography.primary,
              '&.Mui-checked': {
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
              },
              '&.Mui-disabled': {
                color: ThemePalette.typography.secondary,
              },
            }}
            checked={
              selectedPart.length === categories.length && categories.length > 0
            }
            indeterminate={
              selectedPart.length > 0 && selectedPart.length < categories.length
            }
          />
          <ListItemText primary="Select All" />
        </MenuItem>

        {categories.map((part, index) => (
          <MenuItem
            key={index}
            value={part}
            sx={{
              fontSize: '14px',
            }}
          >
            <Checkbox
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
                '&.Mui-checked': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                },
                '&.Mui-disabled': {
                  color: ThemePalette.typography.secondary,
                },
              }}
              checked={selectedPart.includes(part)}
            />
            <ListItemText primary={capitalizeFirstLetter(part)} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
