import React, { useState, useEffect, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
  aggregateByAssetId,
  aggregateSubcomponentData,
  getLineChartData,
} from '../utils';
import { ComponentsTreeChart } from './TreeChart';
import { ComponentsPieChart } from './PieChart';
import { ComponentsLineChart } from './LineChart';
import { ThemePalette } from 'mui.theme';

export const Subcomponent = (props: {
  enableDarkTheme: boolean;
  data: any[];
  selectedCell: string;
}) => {
  const [selectedSubComponent, setSelectedSubComponent] = useState<string>('');
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const { enableDarkTheme, data: originalData, selectedCell } = props;

  const data = useMemo(
    () =>
      originalData.map((item) => ({
        ...item,
        sub_component_categories: item.sub_component_categories.replace(
          /\[|\]/g,
          ''
        ),
      })),
    [originalData]
  );

  const selectedCellData = useMemo(
    () => data.filter((item) => item.components_category === selectedCell),
    [data, selectedCell]
  );

  const { assetsData, lineChartData } = useMemo(() => {
    if (!selectedCellData.length)
      return { subComponentData: [], assetsData: [], lineChartData: [] };

    const newSelectedSubComponent =
      selectedSubComponent || selectedCellData[0].sub_component_categories;

    const filteredSubComponentData = selectedCellData.filter(
      (item) => item.sub_component_categories === newSelectedSubComponent
    );

    const aggregatedAssets = aggregateByAssetId(filteredSubComponentData);
    const lineChartData = getLineChartData(
      aggregatedAssets,
      filteredSubComponentData,
      selectedAssets
    );

    return {
      subComponentData: filteredSubComponentData,
      assetsData: aggregatedAssets,
      lineChartData,
    };
  }, [selectedCellData, selectedSubComponent, selectedAssets]);

  const treeData = useMemo(
    () =>
      aggregateSubcomponentData(selectedCellData).sort(
        (a, b) => b.totalCost - a.totalCost
      ),
    [selectedCellData]
  );

  const handleTreeCellClick = (event: any, name: string) => {
    setSelectedSubComponent(name);
    if (selectedSubComponent !== name) {
      setSelectedAssets([]);
    }
  };

  const handlePieClick = (data: any) => {
    if (data) {
      const name = data.name;
      const updatedClickedCells = [...selectedAssets];

      if (updatedClickedCells.includes(name)) {
        const indexToRemove = updatedClickedCells.indexOf(name);
        updatedClickedCells.splice(indexToRemove, 1);
      } else {
        updatedClickedCells.push(name);
      }
      setSelectedAssets(updatedClickedCells);
    }
  };

  useEffect(() => {
    if (treeData.length > 0) {
      setSelectedSubComponent(treeData[0].name);
    }
  }, [treeData]);

  return (
    <Box>
      <Box
        sx={{
          height: '400px',
          maxHeight: '400px',
        }}
      >
        <ComponentsTreeChart
          selectedCell={selectedSubComponent}
          handleCellClick={handleTreeCellClick}
          data={treeData}
          enableDarkTheme={enableDarkTheme}
          open={false}
          anchorEl={''}
          handleClose={() => {}}
          handleShowTab={() => {}}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={4} md={8} sm={12} xs={12}>
          <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Asset distribution by total cost for
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {selectedSubComponent}
            </Typography>
          </Box>

          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <ComponentsPieChart
              data={assetsData}
              handlePieClick={handlePieClick}
              enableDarkTheme={enableDarkTheme}
              onlyOneActive
            />
          </Box>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Asset distribution by total cost for
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {selectedSubComponent}
            </Typography>
          </Box>

          {assetsData.length === 0 || lineChartData.length === 0 ? (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                paddingTop: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              No Line chart data.
            </Typography>
          ) : (
            <Box sx={{ width: '100%', height: '100%' }}>
              <ComponentsLineChart data={lineChartData} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
