import React from 'react';
import { Card, Typography, Box } from '@mui/material';

interface AssetsConnectedProps {
  count: number;
}

const AssetsConnected: React.FC<AssetsConnectedProps> = ({ count }) => {
  return (
    <Card
      sx={{
        backgroundColor: '#112240',
        p: 2,
        textAlign: 'center',
        color: 'white',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Assets Connected
      </Typography>
      <Box
        sx={{
          fontSize: '1.8rem',
          fontWeight: 'bold',
          color: '#4CAF50',
        }}
      >
        {count}
      </Box>
    </Card>
  );
};

export default AssetsConnected;
