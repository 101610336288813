import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  Paper,
  CardActions,
  Tooltip,
} from '@mui/material';
import { SurvivalAnalysisDetailsChart } from 'views/SurvivalAnalysis/Helper/Modal/SurvivalAnalysis.details.chart';
import { ThemePalette } from 'mui.theme';
import {
  type CumulativeOverview,
  type EolDates,
} from 'types/survivalAnalysis.types';
import {
  addDaysToDate,
  formatAgeInDays,
  mergeEolDatesIntoComponents,
} from '../Function/helper.function';
import SurvivalAnalysisEditModal from './SurvivalAnalysis.edit.modal';
import { Edit } from '@mui/icons-material';
import { GetIcons } from '../Icons/GetIcons.function';
import componentsImage from '../Icons/componenent.png';
import assetImage from '../Icons/vehicle.png';
interface SurvivalAnalysisDetailsCardProps {
  components: any[];
  assetsPerSubComponent: CumulativeOverview[];
  enableDarkTheme: boolean;
  displayMode: string;
  detailType: string;
  eolDates?: EolDates[];
  handleModalSuccess: () => void;
}

export const SurvivalAnalysisDetailsCard: React.FC<
  SurvivalAnalysisDetailsCardProps
> = ({
  components,
  assetsPerSubComponent,
  enableDarkTheme,
  displayMode,
  detailType,
  eolDates,
  handleModalSuccess,
}) => {
  const backgroundColor = (workOrderAgeCategory: string): string => {
    switch (workOrderAgeCategory) {
      case 'eol':
        return 'rgba(204, 75, 55, 0.4)';
      case 'low':
        return 'rgba(107, 165, 131, 0.4)';
      case 'med':
        return 'rgba(209, 178, 0, 0.4)';
      case 'high':
        return 'rgba(204, 75, 55, 0.4)';
      default:
        return 'transparent';
    }
  };
  const textColor = enableDarkTheme
    ? ThemePalette.typography.white
    : ThemePalette.typography.black;

  const [isSurvivalAnalysisEditModal, setIsSurvivalAnalysisEditModal] =
    useState(false);
  const [selectedComponent, setSelectedComponent] = useState<any>({});

  const [componentItems, setComponentItems] = useState<any[]>([]);
  const [editedComponentId, setEditedComponentId] = useState<any>('');
  const handleModalSuccessItem = () => {
    setEditedComponentId(selectedComponent);
    setIsSurvivalAnalysisEditModal(false);
    handleModalSuccess();
  };
  useEffect(() => {
    if (eolDates) {
      const updatedComponents = mergeEolDatesIntoComponents(
        components,
        eolDates
      );
      setComponentItems(updatedComponents);
    }
  }, [components, eolDates]);

  const processComponentItems = useCallback((items: any[]) => {
    const processed: Record<string, any> = {};

    items.forEach((item) => {
      const key = `${item.asset_id}-${item.components_category}-${item.sub_component}`;

      if (!processed[key]) {
        // Initialize the object with other_actions array
        const otherActions: Array<{
          actions_performed: string;
          date: string;
        }> = [];
        if (
          !item?.actions_performed?.toLowerCase()?.includes('repair') &&
          !item?.actions_performed?.toLowerCase()?.includes('replace')
        ) {
          otherActions.push({
            actions_performed: item.actions_performed,
            date: item.date_of_orders,
          });
        }
        processed[key] = { ...item, other_actions: otherActions };
      } else {
        const existing = processed[key];

        // If actions_performed is REPAIR or REPLACE, update eolDate
        if (
          item?.actions_performed?.toLowerCase()?.includes('repair') ||
          item?.actions_performed?.toLowerCase()?.includes('replace')
        ) {
          const keys = Object.keys(item);

          keys.forEach((key) => {
            if (key !== 'actions_performed') {
              existing[key] = item[key];
            }
          });
        } else {
          // Add to other_actions if it's not REPAIR or REPLACE
          existing.other_actions.push({
            actions_performed: item.actions_performed,
            date: item.date_of_orders,
          });
        }
      }
    });

    return Object.values(processed);
  }, []);

  const processedComponentItems = useMemo(() => {
    return processComponentItems(componentItems);
  }, [componentItems, processComponentItems]);

  return (
    <>
      <SurvivalAnalysisEditModal
        key={`${isSurvivalAnalysisEditModal.toString()}-edit-date`}
        open={isSurvivalAnalysisEditModal}
        handleAbort={() => {
          setIsSurvivalAnalysisEditModal(false);
        }}
        title="Edit High Risk Date"
        enableDarkTheme={enableDarkTheme}
        component={selectedComponent}
        handleModalSuccess={handleModalSuccessItem}
      />

      <Grid container spacing={1}>
        {processedComponentItems.map((component, index) => {
          const componentEol = eolDates?.find(
            (item) =>
              item.components_category === component.components_category &&
              item.sub_components_category === component.sub_component &&
              item.asset_id === component.asset_id
          );
          return (
            <Grid
              item
              xs={6}
              sm={6}
              md={displayMode === 'grid' ? 4 : 12}
              key={index}
            >
              <Card
                className="card"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: backgroundColor(
                    component.work_order_age_category
                  ),
                  color: textColor,
                  padding: 0,
                  gap: 0,
                  border:
                    component.asset_id === editedComponentId.asset_id &&
                    component.components_category ===
                      editedComponentId.components_category
                      ? '2px solid #FFFF6E'
                      : 'none', // Add border conditionally
                }}
              >
                <CardContent
                  sx={{
                    padding: 0,
                    gap: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 1,
                        minWidth: '150px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Box>
                          <GetIcons
                            icon={
                              detailType === 'Assets'
                                ? componentsImage
                                : assetImage
                            }
                            enableDarkTheme={enableDarkTheme}
                          />
                        </Box>
                        <Box>
                          <Tooltip
                            title={`${component.asset_id} ${component.components_category}`}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                paddingLeft: 1,
                              }}
                            >
                              <Typography variant="small">
                                {component.asset_id}
                              </Typography>
                              <Typography
                                variant="small"
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '100px',
                                }}
                              >
                                {component.components_category}
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Box>

                      <Typography variant="small">
                        {component.sub_component}
                      </Typography>
                      <Typography variant="small" color="grey">
                        Installation Date:
                      </Typography>
                      <Typography variant="small">
                        {component.date_of_orders}
                      </Typography>
                      <Typography variant="small" color="grey">
                        Age:
                      </Typography>
                      <Typography variant="small">
                        {formatAgeInDays(component.work_order_age_in_days)}
                      </Typography>
                      <Typography variant="small">
                        <var>
                          <span style={{ color: 'grey' }}>
                            {component.work_order_age_category === 'eol' ||
                            component.work_order_age_category === 'high'
                              ? 'Expected EOL: '
                              : component.work_order_age_category === 'med'
                              ? 'High Risk: '
                              : component?.work_order_age_category === 'low'
                              ? 'Medium Risk: '
                              : 'Expected EOL: '}
                          </span>
                        </var>
                        {component.work_order_age_category === 'eol' ||
                        component.work_order_age_category === 'high'
                          ? component.eolDate
                          : component.work_order_age_category === 'med'
                          ? addDaysToDate(
                              component.date_of_orders,
                              component.yellow_red
                            )
                          : component.work_order_age_category === 'low'
                          ? addDaysToDate(
                              component.date_of_orders,
                              component.green_yellow
                            )
                          : component.eolDate}
                      </Typography>
                      {componentEol?.actions_performed ? (
                        <>
                          <Typography variant="small" color="grey">
                            Action to perform:
                          </Typography>
                          <Typography variant="small">
                            {componentEol.actions_performed}
                          </Typography>
                        </>
                      ) : null}
                    </Box>
                    <Paper
                      sx={{
                        display: 'flex',
                        backgroundColor: enableDarkTheme
                          ? 'rgba(255, 255, 255, 0.1)'
                          : 'rgba(0, 0, 0, 0.05)',
                        paddingLeft: 2,
                        paddingRight: 0,
                        width: '100%',
                        // minWidth: '150px',
                      }}
                    >
                      <SurvivalAnalysisDetailsChart
                        enableDarkTheme={enableDarkTheme}
                        workOrderAgeCategory={component.work_order_age_category}
                        component={component}
                        assetsPerSubComponent={assetsPerSubComponent}
                      />
                      {detailType === 'Assets' &&
                        component.work_order_age_category === 'eol' && (
                          <IconButton
                            sx={{
                              color: enableDarkTheme
                                ? ThemePalette.typography.white
                                : ThemePalette.typography.black,
                              '&:hover': {
                                backgroundColor: 'transparent', // Remove hover background color
                              },
                              alignItems: 'flex-start',
                            }}
                            onClick={() => {
                              setIsSurvivalAnalysisEditModal(true);
                              setSelectedComponent(component);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        )}
                    </Paper>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    padding: 0,
                    gap: 0,
                  }}
                ></CardActions>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
