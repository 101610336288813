import { type AgGridReact } from 'ag-grid-react';
import { clearExcel, setExcel } from 'store/exportData.slice';
import {
  type FuelAnalyticsOverview,
  type FleetDeviceDetails,
  type FleetDeviceDetailsPerDay,
} from 'types/fuelAnalytics';
import { ASSET_OPERATIONAL_STATUS, ASSET_TYPES } from 'utils/enums';
import { formatQuickRange } from 'utils/helpers/dateTime';
import { moveKeyToBeginningInObject, formatTo2dp } from 'utils/helpers/general';
import {
  fuelIdleConsumptionLabels,
  fuelNonIdleConsumptionLabels,
  getFuelLabelWrtUnitSystem,
  loadedFuelEfficiencyLabels,
  unLoadedFuelEfficiencyLabels,
} from 'utils/helpers/labels';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { notificationLevel } from 'views/FleetOverview/helpers/asset.helpers';
import { handleAssetStatus } from 'views/Settings/ExternalUsers/AssetView/helpers/common';
import {
  AlertDelivery,
  AppliesTo,
  NotificationConditions,
  SeverityDisplay,
} from 'views/Settings/Rules/components/NotificationListCellRenderer';
import {
  convertMinutesToHours,
  convertsecondsToHours,
  getDataUnit,
} from 'views/Utilization/utils';
import { utils, writeFile } from 'xlsx';
import * as XLSX from 'xlsx';

// this function maps backend data with columnHeaders and AGgrid output
export function processArrays(array1: any, array2: any) {
  const newArray: any = [];

  array1.forEach((obj1: any) => {
    const newObj: any = {};

    array2.forEach((column: any) => {
      const fieldParts = column.field.split('.');
      let value = obj1;

      fieldParts.forEach((part: any) => {
        if (value && Object.prototype.propertyIsEnumerable.call(value, part)) {
          value = value[part];
          if (part === 'isActive') {
            if (obj1.conditions) {
              value = value ? 'Active' : 'Inactive';
            } else {
              const operationalStatus = handleAssetStatus(value);
              value = (ASSET_OPERATIONAL_STATUS as any)[operationalStatus];
              if (!value) return null;
            }
          }
          if (part === 'assetType') {
            value = ASSET_TYPES.find((e) => e.id === value)?.display ?? '';
          }
          if (part === 'notifications') {
            const { warning } = notificationLevel(value);
            value = warning;
          }
          if (part === 'ingestDate') {
            value = dateFormat(value);
          }
          if (part === 'conditions') {
            value = NotificationConditions({
              data: {
                conditions: value,
                notificationLevel: value?.notificationLevel,
                ruleId: '',
                ruleName: '',
                devices: [],
                bumperNumbers: [],
                assets: [],
                alertByEmail: false,
                alertBySms: false,
                alertByUI: false,
                alertByNotificationCenter: false,
                distributionUsers: [],
                isActive: false,
                isDeleted: false,
                updatedAt: new Date(),
                smsUsers: [],
              },
              value: '',
              handleRuleNameClick: () => null,
              handleRuleStatusClick: () => null,
            });
          }
          if (part === 'bumperNumbers') {
            value = AppliesTo({
              data: {
                conditions: [],
                notificationLevel: '',
                ruleId: '',
                ruleName: '',
                devices: [],
                bumperNumbers: value,
                assets: [],
                alertByEmail: false,
                alertBySms: false,
                alertByUI: false,
                alertByNotificationCenter: false,
                distributionUsers: [],
                isActive: false,
                isDeleted: false,
                updatedAt: new Date(),
                smsUsers: [],
              },
              value: '',
              handleRuleNameClick: () => null,
              handleRuleStatusClick: () => null,
            });
          }
          if (part === 'notificationLevel') {
            value = SeverityDisplay({
              data: {
                conditions: [],
                notificationLevel: value,
                ruleId: '',
                ruleName: '',
                devices: [],
                bumperNumbers: [],
                assets: [],
                alertByEmail: false,
                alertBySms: false,
                alertByUI: false,
                alertByNotificationCenter: false,
                distributionUsers: [],
                isActive: false,
                isDeleted: false,
                updatedAt: new Date(),
                smsUsers: [],
              },
              value: '',
              handleRuleNameClick: () => null,
              handleRuleStatusClick: () => null,
            });
          }
          if (part === 'alertByEmail') {
            value = AlertDelivery({
              data: {
                conditions: [],
                notificationLevel: '',
                ruleId: '',
                ruleName: '',
                devices: [],
                bumperNumbers: [],
                assets: [],
                alertByEmail: obj1.alertByEmail,
                alertBySms: obj1.alertBySms,
                alertByUI: obj1.alertByUI,
                alertByNotificationCenter: obj1.alertByNotificationCenter,
                distributionUsers: [],
                isActive: false,
                isDeleted: false,
                updatedAt: new Date(),
                smsUsers: [],
              },
              value: '',
              handleRuleNameClick: () => null,
              handleRuleStatusClick: () => null,
            });
          }
        } else if (
          value.liveTagData &&
          Object.prototype.propertyIsEnumerable.call(value.liveTagData, part)
        ) {
          value = value.liveTagData[part];
        } else {
          value = undefined;
        }
      });
      // Convert the value to a string if it's an object
      if (typeof value === 'object') {
        if (
          column.headerName === 'Applies To' ||
          column.headerName === 'Condition(s)' ||
          column.headerName === 'Severity' ||
          column.headerName === 'Alert Delivery Method(s)'
        ) {
          newObj[column.headerName] = value;
        } else {
          newObj[column.headerName] = JSON.stringify(value);
        }
      } else {
        newObj[column.headerName] = value;
      }
    });

    newArray.push(newObj);
  });

  return newArray;
}

const roundNestedValues = (
  obj: any,
  movingAverages: Array<Record<string, unknown>>,
  average: number
) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        value = value.map((item: any) => {
          const newItem = {
            ...item,

            'Productive Hours': formatTo2dp(
              Number(item.total_engine_working_minutes) / 60
            ),
            'Moving Average':
              formatTo2dp(
                movingAverages?.find(
                  (avg: any) =>
                    avg?.date === item?.date && item?.period === avg?.period
                )?.movingAverage as number
              ) ?? 0,
            'Total Average (Hours/Day)': formatTo2dp(average),
          };

          const keysToRemove = new Set([
            'total_engine_working_minutes',
            'total_engine_active_minutes',
          ]);

          return Object.fromEntries(
            Object.entries(newItem).filter(([k]) => !keysToRemove.has(k))
          );
        });
      }

      return [key, value];
    })
  );
};

function transformData(input: any, unitSystem: string) {
  const groupedData: Record<string, any> = {};

  input.forEach((asset: any) => {
    const bumperId = asset['bumber id'];
    if (!groupedData[bumperId]) {
      groupedData[bumperId] = {
        title: bumperId,
        subTitle: `Make/Model: ${asset.make_model},Asset Type: ${
          asset.asset_type
        },Average Fuel For selected Period (${getDataUnit(
          'fuel',
          unitSystem
        )}): ${
          asset.avg_fuel
        },Average Idling Hours For selected Period (${getDataUnit(
          'idlinghours',
          unitSystem
        )})/Day: ${
          asset.avg_idling_hours
        },Average Productive Hours For selected Period (${getDataUnit(
          'productivehours',
          unitSystem
        )})/Day: ${
          asset.avg_productive_hours
        },Average Engine Hours For Selected Period: ${
          asset.avg_engine_hours
        },Average Utilization For selected Period (${getDataUnit(
          'utilization',
          unitSystem
        )}): ${
          asset.avg_utilization
        },Average Fuel Rate For selected Period (${getDataUnit(
          'avgfuelrate',
          unitSystem
        )}): ${asset.avg_fuel_rate}
        `,
        data: [],
        name: bumperId,
      };
    }
    groupedData[bumperId].data.push({
      Date: asset.date,
      [`Idling Hours (${getDataUnit('idlinghours', unitSystem)})`]:
        asset.idling_hrs,
      [`Productive Hours (${getDataUnit('productivehours', unitSystem)})`]:
        asset.productive_hrs,
      [`Avg Fuel Rate (${getDataUnit('avgfuelrate', unitSystem)})`]:
        asset.avg_fuel_use_rate,
      'Engine Hours': asset.eng_hrs,
      [`Utilization (${getDataUnit('utilization', unitSystem)})`]: asset.utilzn,
      [`Fuel (${getDataUnit('fuel', unitSystem)})`]: asset.fuel,
      [`Production (${getDataUnit('production', unitSystem)})`]: asset.prod,
    });
  });

  return Object.values(groupedData);
}

function transformProductionData(input: any, unitSystem: string) {
  const groupedData: Record<string, any> = {};

  input.forEach((asset: any) => {
    const bumperId = asset['bumber id'];
    if (!groupedData[bumperId]) {
      groupedData[bumperId] = {
        title: bumperId,
        subTitle: `Make/Model: ${asset.make_model},Asset Type: ${
          asset.asset_type
        },Average Fuel For selected Period (${getDataUnit(
          'fuel',
          unitSystem
        )}): ${asset.avg_fuel},Production Average (${getDataUnit(
          'production',
          unitSystem
        )})/Day: ${asset.prod_avg},Production Rate Average (${getDataUnit(
          'productionrate',
          unitSystem
        )}): ${
          asset.prod_rate_avg
        },Utilization Average For selected Period (${getDataUnit(
          'utilization',
          unitSystem
        )})/Day: ${
          asset.utilzn_avg
        },Average Productive Hours For selected Period (${getDataUnit(
          'productivehours',
          unitSystem
        )})/Day: ${
          asset.prod_hrs_avg
        },Average Idling Hours For selected Period (${getDataUnit(
          'idlinghours',
          unitSystem
        )})/Day: ${asset.avg_idln_hrs}
        `,
        data: [],
        name: bumperId,
      };
    }
    groupedData[bumperId].data.push({
      Date: asset.date,
      [`Production (${getDataUnit('production', unitSystem)})`]: asset.prod,
      [`Production Rate (${getDataUnit('productionrate', unitSystem)})`]:
        asset.prod_rate,
      [`Utilization (${getDataUnit('utilization', unitSystem)})`]: asset.utilzn,
      [`Fuel (${getDataUnit('fuel', unitSystem)})`]: asset.fuel,
      [`Idling Hours (${getDataUnit('idlinghours', unitSystem)})`]:
        asset.idln_hrs,
      [`Productive Hours (${getDataUnit('productivehours', unitSystem)})`]:
        asset.prod_hrs,
    });
  });

  return Object.values(groupedData);
}

function filterOutSuggestionKeys(
  obj: Record<string, any>,
  val: string
): Record<string, any> {
  function filterObject(input: Record<string, any>): Record<string, any> {
    return Object.fromEntries(
      Object.entries(input)
        .filter(([key]) => !key.toLowerCase().includes(val))
        .map(([key, value]) => [
          key,
          typeof value === 'object' && value !== null
            ? filterObject(value)
            : value,
        ])
    );
  }

  return filterObject(obj);
}

type DateStats = Record<string, any>;

interface ExtractTableProps {
  handleToPrint: (val: boolean) => void;
  gridRef: React.RefObject<AgGridReact>;
  dispatch: any;
}
//  this function gets data output, processed(sorted, filtered) by AGgrid
export const extractTableData = ({
  handleToPrint,
  gridRef,
  dispatch,
}: ExtractTableProps) => {
  handleToPrint(true);

  if (gridRef.current) {
    dispatch(clearExcel());

    const allData: any = [];
    gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
      allData.push(node.data);
    });

    const columnDefs = gridRef.current.api.getColumnDefs();
    const keysToInclude = [
      'colId',
      'field',
      'filter',
      'flex',
      'headerClass',
      'headerName',
    ];

    const filteredColumnDef = columnDefs?.map((obj: any) => {
      const includedObject = Object.keys(obj)
        .filter((key) => keysToInclude.includes(key))
        .reduce((acc: any, key) => {
          acc[key] = obj[key];
          return acc;
        }, {});

      return includedObject;
    });

    const data = allData;

    dispatch(setExcel({ data, columnHeaders: filteredColumnDef }));
  }
};

export const dateFormat = (dateString: string) => {
  return new Date(dateString).toLocaleString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
};

export function trimSheetName(
  sheetName: string,
  maxLength: number = 31
): string {
  const sheetName2 = sheetName
    .replace('engine', 'e')
    .replace('intake', 'int')
    .replace('temperature', 'tmp')
    .replace('manifold', 'md')
    .replace('absolute', 'abs')
    .replace('pressure', 'press')
    .replace('resolution', 'res');
  if (sheetName2.length <= maxLength) {
    return sheetName2;
  }

  const firstPart = sheetName2.substring(0, 14);
  const lastPart = sheetName2.substring(sheetName2.length - 14);
  return `${firstPart}...${lastPart}`;
}

export const chartToExcelForVehicleStatus = (
  data: any,
  filename: string,
  title: string,
  unitSystem: string
) => {
  const wb = utils.book_new();
  const clonedData = JSON.parse(JSON.stringify(data));

  clonedData.map((tagGroup: any) => {
    const newData = tagGroup.data.map((item: any) => {
      item.ts = dateFormat(item.ts);
      item.sensorName = tagGroup.name;
      return {
        ts: item.ts,
        'Sensor Name': item.sensorName,
        'Min Value': item.min_val,
        'Avg Value': item.avg_val,
        'Max Value': item.max_val,
      };
    });

    const newObj: Record<string, unknown> = setHeaders(newData[0], unitSystem);
    newData.unshift(newObj);

    // Convert chart data to worksheet
    const ws = utils.json_to_sheet(newData);

    // Add the worksheet to the workbook
    utils.book_append_sheet(wb, ws, trimSheetName(tagGroup.name));

    // Add the title to the sheet
    ws.A1 = { t: 's', v: `${title}` };
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }];
    return null;
  });
  // Save the workbook as a file
  writeFile(wb, `${filename}.xlsx`);
};

export const chartToExcelForUsageDashboard = (
  data: any,
  filename: string,
  title: string,
  isHistorical = false,
  unitSystem: string
) => {
  const wb = utils.book_new();

  const filterData = structuredClone(data);
  filterData.fuelConsumptionRangeAnalytics.sort(
    (a: any, b: any) =>
      new Date(b.customer_month).getTime() -
      new Date(a.customer_month).getTime()
  );
  filterData.loadedFuelData = data.fuelLoadedUnloadedAnalytics.loadedFuelData;
  filterData.unloadedFuelData =
    data.fuelLoadedUnloadedAnalytics.unloadedFuelData;

  delete filterData.fuelLoadedUnloadedAnalytics;
  delete filterData.fuelConsumptionEngineHoursAnaly;
  delete filterData.fuelConsumptionEngineHoursAnalytics;

  if (!isHistorical) {
    delete filterData.fuelConsumptionRangeAnalytics;
  } else {
    const keyMapping2: Record<string, unknown> = {
      customer_month: 'Date/Time',
      asset_type: 'Asset Type',
      bumperNumber: 'bumper Number',
      sum_fuel: 'Total Consumption',
      CO2: 'CO2 Consumption',
      fleet_num_devices: 'Number of Assets',
      average_fuel: 'Average Consumption',
      fleet_average_fuel: 'Fleet Average',
    };

    const monthlyData = structuredClone(
      filterData.fuelConsumptionRangeAnalytics
    );

    const transformedData = monthlyData.map((obj: any) =>
      Object.fromEntries(
        Object.entries(obj)
          .filter(([key]) => keyMapping2[key]) // Keep only keys in keyMapping
          .map(([key, value]) => [keyMapping2[key], value]) // Rename the keys
      )
    );
    delete Object.assign(filterData, {
      'Monthly Fuel Consumption': transformedData,
    }).fuelConsumptionRangeAnalytics;
  }
  const newFilteredData: any = [];
  filterData?.loadedFuelData?.map((item: any) => {
    const cloneObj = structuredClone(item);

    delete cloneObj.unloaded_duration_seconds;
    delete cloneObj.unloaded_fuel_avg;
    delete cloneObj.fuelSource;
    delete cloneObj.loaded_total_hours_operated;
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      loaded_fuel_efficiency: cloneObj.loaded_fuel_avg,
    }).loaded_fuel_avg;

    newFilteredData.push(cloneObj);
    return cloneObj;
  });
  filterData['Loaded Fuel Data'] = newFilteredData;

  const newunLoadedFilteredData: any = [];
  filterData?.unloadedFuelData?.map((item: any) => {
    const cloneObj = structuredClone(item);
    delete cloneObj.fuelSource;
    delete cloneObj.loaded_fuel_avg;
    delete cloneObj.loaded_total_hours_operated;
    delete cloneObj.unloaded_duration_seconds;
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      unloaded_fuel_efficiency: cloneObj.unloaded_fuel_avg,
    }).unloaded_fuel_avg;

    newunLoadedFilteredData.push(cloneObj);
    return cloneObj;
  });
  filterData['Unloaded Fuel Data'] = newunLoadedFilteredData;

  const newFuelIdleEventsData: any = [];
  filterData?.fuelIdleEvents.data?.map((item: any) => {
    const cloneObj = structuredClone(item);
    cloneObj.idling_event_average =
      filterData.fuelIdleEvents.idlingEventAverage;
    delete cloneObj.avg_fuel_consumption_per_hour;
    delete cloneObj.fuelSource;
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      total_idle_time_events: cloneObj.excess_idle,
    }).excess_idle;

    const moveBumperNumbertoFirst = moveKeyToBeginningInObject(
      cloneObj,
      'bumperNumber'
    );
    newFuelIdleEventsData.push(moveBumperNumbertoFirst);
    return moveBumperNumbertoFirst;
  });
  filterData.fuelIdleEvents = newFuelIdleEventsData;

  const newFuelNonIdleEventsData: any = [];
  filterData?.fuelNoneIdleEvents.data?.map((item: any) => {
    const cloneObj = structuredClone(item);
    delete cloneObj.avg_idle_fuel_consumption;
    delete cloneObj.fuelSource;
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      nonidling_event_average: cloneObj.avg_idle_time_minutes,
    }).avg_idle_time_minutes;

    const moveBumperNumbertoFirst = moveKeyToBeginningInObject(
      cloneObj,
      'bumperNumber'
    );
    newFuelNonIdleEventsData.push(moveBumperNumbertoFirst);
    return moveBumperNumbertoFirst;
  });
  const keyMapping: Record<string, string> = {
    bumperNumber: 'bumper Number',
    total_idle_fuel_consumption: 'Total Fuel Consumed',
    total_idle_time_minutes: 'Total Idling Time (mins)',
    CO2: 'CO2 ',
    nonidling_event_average: 'Fleet Average Idling Time (mins)',
  };

  const noEvnIdln = structuredClone(newFuelNonIdleEventsData);
  // Modify the array in place
  const transformedData = noEvnIdln.map((obj: any) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        (keyMapping as any)[key] ?? key,
        value,
      ])
    )
  );
  filterData['Non-Event Idling Time'] = transformedData;

  delete filterData.fuelNoneIdleEvents;
  delete filterData.loadedFuelData;
  delete filterData.unloadedFuelData;

  for (const key in filterData) {
    const dataArr = [];
    if (filterData[key]?.data) {
      dataArr.push(...filterData[key].data);
    } else if (Array.isArray(filterData[key])) {
      dataArr.push(...filterData[key]);
    }
    const dataWithKey: Array<Record<string, unknown>> = [];
    dataArr.map((tagData) => {
      const newObj = { ...tagData };
      return dataWithKey.push(newObj);
    });

    const newObj: Record<string, unknown> = setHeaders(
      dataWithKey[0],
      unitSystem,
      {
        CO2: dataWithKey[0]?.loaded_fuel_efficiency
          ? getFuelLabelWrtUnitSystem(
              unitSystem,
              loadedFuelEfficiencyLabels.CO2
            )
          : dataWithKey[0]?.unloaded_fuel_efficiency
          ? getFuelLabelWrtUnitSystem(
              unitSystem,
              unLoadedFuelEfficiencyLabels.CO2
            )
          : getFuelLabelWrtUnitSystem(
              unitSystem,
              fuelIdleConsumptionLabels.CO2
            ),
        loaded_fuel_efficiency: getFuelLabelWrtUnitSystem(
          unitSystem,
          loadedFuelEfficiencyLabels.loaded_fuel_efficiency
        ),
        unloaded_fuel_efficiency: getFuelLabelWrtUnitSystem(
          unitSystem,
          unLoadedFuelEfficiencyLabels.unloaded_fuel_efficiency
        ),
        total_fuel_consumed: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelIdleConsumptionLabels.total_fuel_consumed
        ),
        total_idle_time_events: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelIdleConsumptionLabels.total_idle_time_events
        ),
        total_idle_time_minutes: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelNonIdleConsumptionLabels.total_idle_time_minutes
        ),
        idling_event_average: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelIdleConsumptionLabels.total_idle_time_events
        ),
        nonidling_event_average: getFuelLabelWrtUnitSystem(
          unitSystem,
          fuelNonIdleConsumptionLabels.total_idle_time_minutes
        ),
      }
    );

    dataWithKey.unshift(newObj);

    const ws = utils.json_to_sheet(dataWithKey);

    utils.book_append_sheet(wb, ws, trimSheetName(key));

    ws.A1 = { t: 's', v: `${title}` };
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }];
  }
  writeFile(wb, `${filename}.xlsx`);
};

export const exportEngineHoursToExcel = async ({
  dataWithKey,
  title,
  key,
  filename,
}: {
  dataWithKey: Array<Record<string, any>>;
  title?: string;
  key: string;
  filename: string;
}) => {
  const wb = utils.book_new(); // to create a new workbook
  //  Modify name of key from engine_hour_total to engine_hour_total (hrs) and converting it to 2 dp
  dataWithKey = dataWithKey.map((obj: Record<string, any>) => {
    const newObj = { ...obj };
    delete newObj.asset;
    if (newObj.engine_hour_total !== undefined) {
      newObj['engine_hour_total (hrs)'] = parseFloat(
        newObj.engine_hour_total
      ).toFixed(2);
      delete newObj.engine_hour_total;
    }
    return newObj;
  });

  const ws = utils.json_to_sheet(dataWithKey); //  Convert your JSON object (dataWithKey) into a worksheet object (ws) that is compatible with Excel.

  utils.book_append_sheet(wb, ws, trimSheetName(key)); // Appends the worksheet (ws) to an existing workbook (wb) under a specific sheet name.

  // ws.A1 = { t: 's', v: `${title}` }; //  Modifies the value of cell A1 in the worksheet to display a title (title).

  // ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]; // Merges cells in the first row (A1 to K1), to create an header for you
  writeFile(wb, `${filename}.xlsx`); // saves, generates and downloads your workbook
};

export const chartToExcelForNonHaulTruckUsageDashboard = (
  data: any,
  filename: string,
  title: string,
  isHistorical = false,
  unitSystem: string
) => {
  const wb = utils.book_new();

  const filterData = structuredClone(data);

  const newData: any = [];
  filterData[0].data.map((item: any) => {
    const cloneObj = structuredClone(item);
    delete cloneObj.device;

    delete Object.assign(cloneObj, {
      fuel_use_rate: cloneObj.fuel_rate,
    }).fuel_rate;

    delete Object.assign(cloneObj, {
      avg_fuel_use_rate: cloneObj.avg_fuel_rate,
    }).avg_fuel_rate;

    const moveBumperNumbertoFirst = moveKeyToBeginningInObject(
      cloneObj,
      'bumperNumber'
    );

    newData.push(moveBumperNumbertoFirst);
    return moveBumperNumbertoFirst;
  });
  filterData[0].data = newData;

  const newData2: any = [];
  filterData[1].data.map((item: any) => {
    const cloneObj = structuredClone(item);
    delete cloneObj.device;
    delete cloneObj.customer_day;
    delete cloneObj.avg_idle_fuel_consumption;

    const moveBumperNumbertoFirst = moveKeyToBeginningInObject(
      cloneObj,
      'bumperNumber'
    );

    newData2.push(moveBumperNumbertoFirst);
    return moveBumperNumbertoFirst;
  });
  filterData[1].data = newData2;

  if (!isHistorical) {
    filterData.splice(2, 1);
  }

  const sheetNames = [
    'Engine Hours',
    'Idling Time',
    'Monthly Fuel Consumption',
  ];

  filterData.map((sheets: any, index: number) => {
    let newData = sheets.data;
    if (Array.isArray(newData)) {
      newData.map((data: Record<string, unknown>) => {
        if (data.customer_day) {
          data.customer_day = dateFormat(data.customer_day as string);
        }
        delete data.fuelSource;
        delete data['Fuel Source'];
        return data;
      });
    } else {
      newData = sheets;
    }

    const newObj: Record<string, unknown> = setHeaders(newData[0], unitSystem);

    newData.unshift(newObj);
    const ws = utils.json_to_sheet(newData);

    utils.book_append_sheet(wb, ws, trimSheetName(sheetNames[index]));

    ws.A1 = { t: 's', v: `${title}` };
    ws['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }];

    return null;
  });

  writeFile(wb, `${filename}.xlsx`);
};

const setHeaders = (
  dataWithKey: Record<string, unknown>,
  unitSystem: string,
  options?: Record<string, string>
): Record<string, unknown> => {
  const newObj: any = {};

  for (const key in dataWithKey) {
    if (options && key in options) {
      newObj[key] = `${key} (${options[`${key}`]})`;
    } else if (key.includes('fuel')) {
      newObj[key] = `${key} ${unitSystem === 'metric' ? '(litres)' : '(gals)'}`;
    } else if (key.includes('minutes')) {
      newObj[key] = `${key} (mins)`;
    } else if (key.includes('time')) {
      newObj[key] = `${key} (secs)`;
    } else if (key.includes('CO2')) {
      newObj[key] = `${key} ${unitSystem === 'metric' ? '(kg)' : '(lbs)'}`;
    } else if (key.includes('customer_month') || key === 'ts') {
      newObj[key] = `Date/Time`;
    } else if (
      key === 'sensorName' ||
      key === 'sensorName' ||
      key === 'tagName'
    ) {
      newObj[key] = `Tag Name`;
    } else if (key === 'bumperNumber') {
      newObj[key] = `bumper number`;
    } else {
      newObj[key] = key;
    }
    newObj[key] = newObj[key].split('_').join(' ');
  }
  return newObj;
};

export function shiftWiseEngineStartEndDataToExcel(
  shiftData: Record<string, any[]>,
  fileName: string,
  extraWorksheets: Record<string, any[]> = {}
) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Append worksheets from the shiftData
  Object.entries(shiftData).forEach(([sheetName, data]) => {
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });

  // Append additional worksheets (e.g., "short_idling", etc.)
  Object.entries(extraWorksheets).forEach(([sheetName, data]) => {
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });

  // Write the workbook to a file
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

const getAdditionalTableData = (
  sheetName: string,
  clonedData: any,
  unitSystem: string
) => {
  const htAssets = clonedData.assets.filter(
    (item: any) => item.asset_type === 'haul_truck'
  );

  const loaderAssets = clonedData.assets.filter(
    (item: any) => item.asset_type === 'loader'
  );

  const processAssets = (data: any[]) => {
    return data.flatMap((asset) => {
      return Object.entries(asset.date_stats).map(([date, stats]) => ({
        'bumber id': asset.bumper_id,
        make_model: `${asset.make} ${asset.model}`,
        asset_type: asset.asset_type,
        date,
        idling_hrs: formatTo2dp(
          Number(convertsecondsToHours(Number((stats as any).idling_duration)))
        ),
        avg_idling_hours: formatTo2dp(
          convertsecondsToHours(
            Number(asset.total_idling_duration) /
              Object.values(asset.date_stats).filter(
                (item: any) => item.idling_duration !== 0
              ).length
          )
        ),
        productive_hrs: formatTo2dp(
          Number(
            convertMinutesToHours(
              Number((stats as any).utilization_engine_working_minute)
            )
          )
        ),
        avg_productive_hours: formatTo2dp(
          convertMinutesToHours(
            Number(asset.total_engine_working_minutes) /
              Object.values(asset.date_stats).filter(
                (item: any) => item.utilization_engine_working_minute !== 0
              ).length
          )
        ),
        avg_fuel_use_rate: formatTo2dp(
          (stats as any).hourly_fuel_consumption_l
        ),

        avg_fuel_rate: formatTo2dp(Number(asset.avg_hourly_fuel_consumption_l)),
        eng_hrs: formatTo2dp(
          Number(
            convertMinutesToHours(
              Number((stats as any).utilization_engine_active_minute)
            )
          )
        ),
        avg_engine_hours: formatTo2dp(
          Number(
            convertMinutesToHours(
              Number(asset.total_engine_active_minutes) /
                Object.values(asset.date_stats).filter(
                  (item: any) => item.utilization_engine_active_minute !== 0
                ).length
            )
          )
        ),
        utilzn: formatTo2dp(
          Number((stats as any).utilization_by_shift_hours) * 100
        ),
        avg_utilization: formatTo2dp(
          Number(asset.avg_utilization_by_shift_hours) * 100
        ),
        fuel: formatTo2dp((stats as any).fuel_consumption_l),

        avg_fuel: formatTo2dp(
          Number(asset.total_fuel_consumption_l) /
            Object.values(asset.date_stats).filter(
              (item: any) => item.fuel_consumption_l !== 0
            ).length
        ),

        prod: formatTo2dp((stats as any).max_load_ton),
      }));
    });
  };

  if (sheetName === 'hauler') {
    return processAssets(htAssets);
  } else if (sheetName === 'loader') {
    return processAssets(loaderAssets);
  } else if (sheetName === 'all fleet') {
    return processAssets(clonedData.assets);
  }
  return [];
};

const getAdditionalProductionTableData = (
  sheetName: string,
  clonedData: any,
  unitSystem: string
) => {
  const htAssets = clonedData.assets.filter(
    (item: any) => item.asset_type === 'haul_truck'
  );

  const loaderAssets = clonedData.assets.filter(
    (item: any) => item.asset_type === 'loader'
  );

  const processAssets = (data: any[], unitSystem: string) => {
    return data.flatMap((asset) => {
      return Object.entries(asset.date_stats).map(([date, stats]) => ({
        'bumber id': asset.bumper_id,
        make_model: `${asset.make} ${asset.model}`,
        asset_type: asset.asset_type,
        date,
        // ...(stats as any),
        // [`Production (${getDataUnit('production', unitSystem)})`]:
        prod: formatTo2dp((stats as any).max_load_ton),
        // [`Production Average (${getDataUnit('production', unitSystem)})/Day`]:
        prod_avg: asset.avg_max_load_per_day,
        // [`Production Rate (${getDataUnit('productionrate', unitSystem)})`]:
        prod_rate: formatTo2dp((stats as any).load_per_hour),
        // [`Production Rate Average (${getDataUnit(
        //   'productionrate',
        //   unitSystem
        // )})`]:
        prod_rate_avg: asset.avg_load_per_hour,

        utilzn: formatTo2dp(
          Number((stats as any)?.utilization_by_shift_hours) * 100
        ),
        utilzn_avg: formatTo2dp(
          Number(asset.avg_utilization_by_shift_hours) * 100
        ),

        // [`Fuel (${getDataUnit('fuel', unitSystem)})/Day`]:
        fuel: formatTo2dp((stats as any)?.fuel_consumption_l),
        avg_fuel: formatTo2dp(Number(asset.avg_fuel_consumption_per_day)),
        // [`Idling Hours (${getDataUnit('idlinghours', unitSystem)})`]:
        idln_hrs: formatTo2dp(
          Number(convertsecondsToHours((stats as any)?.idling_duration))
        ),
        avg_idln_hrs: formatTo2dp(
          convertsecondsToHours(
            getAverages(asset.date_stats, 'idling_duration')
          )
        ),

        prod_hrs: formatTo2dp(
          convertMinutesToHours(
            Number((stats as any)?.utilization_engine_working_minute) ?? 0
          )
        ),
        prod_hrs_avg: formatTo2dp(
          convertMinutesToHours(
            getAverages(
              asset.date_stats,
              'utilization_engine_working_minute'
            ) ?? 0
          )
        ),
      }));
    });
  };

  if (sheetName === 'hauler') {
    return processAssets(htAssets, unitSystem);
  } else if (sheetName === 'loader') {
    return processAssets(loaderAssets, unitSystem);
  } else if (sheetName === 'all fleet') {
    return processAssets(clonedData.assets, unitSystem);
  }
  return [];
};

const getAverages = (data: any, key: string) => {
  const values = Object.values(data)
    .map((entry: any) => entry[key])
    .filter((value) => typeof value === 'number');

  if (values.length === 0) return 0;

  return (
    values.reduce((sum, num) => Number(sum) + Number(num), 0) / values.length
  );
};

export const exportToExcelForUtilization = (
  data: Record<string, any>,
  filename: string,
  title: string,
  unitSystem: string,
  analyticsData: any[],
  hoursBreakDown: {
    hauler: any;
    loader: any;
  },
  userSelections: any,
  suggestionData: any,
  fuelConsumption: {
    hauler: any;
    loader: any;
  }
) => {
  const wb = utils.book_new();

  const clonedData = JSON.parse(JSON.stringify(data));

  const editedCloned: any = roundNestedValues(
    clonedData.fleetPeriodSummary,
    userSelections.movingAverages,
    userSelections.average
  );

  const processAssets = clonedData.assets.map((item: any) => {
    const utilization = formatTo2dp(
      Number(item?.avg_utilization_by_shift_hours) * 100
    );
    const productiveHours = formatTo2dp(
      Number(item?.total_engine_working_minutes) / 60
    );
    const idlingHours = formatTo2dp(
      convertsecondsToHours(Number(item?.total_idling_duration))
    );
    const engineHours = formatTo2dp(
      Number(convertMinutesToHours(Number(item?.total_engine_active_minutes)))
    );

    return {
      'Bumper ID': item.bumper_id,
      'Make/Model': `${item.make} ${item.model}`,
      'Asset Type': item.asset_type,
      [`Utilization (${getDataUnit('utilization', unitSystem)})`]: utilization,
      // [`Average Utilization (${getDataUnit('utilization', unitSystem)})`]:
      // utilization,
      'Productive Hours': productiveHours,
      'Idling Hours': idlingHours,
      'Engine Hours': engineHours,
    };
  });

  const htAssets = processAssets.filter(
    (item: any) => item.asset_type === 'haul_truck'
  );

  const loaderAssets = processAssets.filter(
    (item: any) => item.asset_type === 'loader'
  );

  const returnAnalysisData: Array<Record<string, unknown>> = [];
  analyticsData.forEach((item) => {
    const data = {
      Item: item.title,
      Analysis: `${item.analysis} from last ${formatQuickRange(
        userSelections.quickrange
      )}`,
      Value: `${item.value}${item.unit}`,
    };
    return returnAnalysisData.push(data);
  });

  const processedData = [];
  const fleetPeriod = (
    data.value === 'all fleet'
      ? editedCloned.fleet
      : data.value === 'loader'
      ? editedCloned.loaders
      : editedCloned.haulers
  )
    .filter((item: any) => {
      const today = new Date();
      if (userSelections.showChart.includes('7 Days')) {
        item.period = 'Last 7 Days';
        return (
          new Date(item.date) >= new Date(today.setDate(today.getDate() - 8))
        );
      }
      if (userSelections.showChart.includes('14 Days')) {
        item.period = 'Last 14 Days';
        return (
          new Date(item.date) >= new Date(today.setDate(today.getDate() - 15))
        );
      }
      if (userSelections.showChart.includes('4 Weeks')) {
        item.period = 'Last 4 Weeks';
        return (
          new Date(item.date) >= new Date(today.setDate(today.getDate() - 30))
        );
      }
      if (userSelections.showChart.includes('6 Months')) {
        item.period = 'Last 6 Months';
        return (
          new Date(item.date) >= new Date(today.setMonth(today.getMonth() - 6))
        );
      }
      return true;
    })
    .sort(
      (a: any, b: any) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );

  const suggestionData2 =
    Object.entries(suggestionData).length > 0
      ? data.value === 'all fleet'
        ? [
            ...Object.entries(suggestionData.facts),
            ...Object.entries(suggestionData.recommendations),
          ]
        : data.value === 'hauler'
        ? [
            ...Object.entries(
              filterOutSuggestionKeys(suggestionData, 'loader').facts
            ),
            ...Object.entries(
              filterOutSuggestionKeys(suggestionData, 'loader').recommendations
            ),
          ]
        : [
            ...Object.entries(
              filterOutSuggestionKeys(suggestionData, 'hauler').facts
            ),
            ...Object.entries(
              filterOutSuggestionKeys(suggestionData, 'hauler').recommendations
            ),
          ]
      : [];

  const additionalData = getAdditionalTableData(
    data.value,
    clonedData,
    unitSystem
  ).sort(
    (a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  processedData.push(
    {
      title: `${clonedData.value} productive hours summary`,
      data: clonedData.fleetSummary,
      name: `${String(clonedData.value)
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')} Summary`,
    },
    {
      title: `${clonedData.value} productive hours by date range summary`,
      data: fleetPeriod,
      name:
        data.value === 'all fleet'
          ? `All Fleet Range Summary`
          : data.value === 'loader'
          ? `Loaders Range Summary`
          : `Haulers Range Summary`,
    },
    {
      title:
        data.value === 'all fleet'
          ? `Breakdown for all assets`
          : data.value === 'hauler'
          ? `Breakdown for all Haul Truck assets`
          : `Breakdown for all Loader assets`,
      data:
        data.value === 'all fleet'
          ? processAssets
          : data.value === 'hauler'
          ? htAssets
          : loaderAssets,
      name:
        (data.value === 'all fleet'
          ? 'All Assets'
          : data.value === 'hauler'
          ? `Assets - Haul Trucks`
          : `Assets - Loaders`) + ' Performance',
    },
    {
      title: 'Analytics Data',
      data: returnAnalysisData,
      name: 'Analytics Data',
    },
    {
      title: 'Suggestions',
      data: suggestionData2,
      name: 'Suggestions',
    },
    ...transformData(additionalData, unitSystem)
  );

  processedData.forEach((item: any) => {
    const sheetData = Array.isArray(item.data) ? item.data : [item.data];

    if (sheetData.length === 0) {
      return;
    }
    // const headers = setHeaders(sheetData[0], unitSystem);

    // if (
    //   !sheetData.some((row: any) => {
    //     return Object.keys(row)[0] === Object.keys(headers)[0];
    //   })
    // ) {
    //   sheetData.unshift(headers);
    // }

    const formattedTitle = item.subTitle
      ? [
          [item.title.toUpperCase()],
          ...item.subTitle.split(',').map((s: any) => [s]),
        ]
      : [[item.title.toUpperCase()]];

    const ws = utils.json_to_sheet([]);

    utils.sheet_add_aoa(ws, formattedTitle, { origin: 'A1' });

    // Start table data from the next available row
    const dataStartRow = item.subTitle
      ? formattedTitle.length + 2
      : formattedTitle.length + 1;

    // Add data below title and subtitle
    utils.sheet_add_json(ws, sheetData, {
      origin: `A${dataStartRow}`,
      skipHeader: Array.isArray(sheetData[0]),
    });

    ws['!merges'] = [
      {
        s: { r: 0, c: 0 },
        e: { r: 0, c: Object.keys(sheetData[0]).length - 1 },
      },
    ];

    if (!ws.A1) ws.A1 = {};
    ws.A1.s = {
      font: { bold: true, sz: 14 },
      alignment: { horizontal: 'center' },
    };

    utils.book_append_sheet(wb, ws, trimSheetName(item.name || 'Sheet'));

    if (item.name === 'Analytics Data') {
      const addHourData =
        data.value === 'all fleet'
          ? hoursBreakDown
          : data.value === 'hauler'
          ? {
              'Hours BreakDown': hoursBreakDown.hauler,
            }
          : {
              'Hours BreakDown': hoursBreakDown.loader,
            };
      const additionalHourData = Object.entries(addHourData).map((item) => {
        const newObj = Object.assign(
          {
            'Asset Types':
              data.value === 'all fleet'
                ? item[0].toUpperCase() + 'S'
                : (data.value as string).toUpperCase(),
          },
          item[1]
        );

        return newObj;
      });

      const addFuelData =
        data.value === 'hauler'
          ? {
              'Fuel Consumption': fuelConsumption.hauler,
            }
          : data.value === 'loader'
          ? {
              'Fuel Consumption': fuelConsumption.loader,
            }
          : [];
      const additionalFuelData = Object.entries(addFuelData).map((item) => {
        const newObj = Object.assign(
          {
            Details: item[0].toUpperCase() + 'S',
          },
          item[1]
        );

        return newObj;
      });

      if (additionalHourData.length > 0) {
        const existingRange = ws['!ref']; // Get current range
        const lastRow = existingRange
          ? +existingRange.split(':')[1].replace(/\D/g, '')
          : sheetData.length;

        const newTableTitle = [[`ENGINE HOUR BREAKDOWN`]];
        utils.sheet_add_aoa(ws, newTableTitle, {
          origin: `A${Number(lastRow) + 3}`,
        });

        // Add new table data below
        utils.sheet_add_json(ws, additionalHourData, {
          origin: `A${Number(lastRow) + 4}`,
          skipHeader: false,
        });
      }

      if (additionalFuelData.length > 0) {
        const existingRange = ws['!ref']; // Get current range
        const lastRow = existingRange
          ? +existingRange.split(':')[1].replace(/\D/g, '')
          : sheetData.length;

        const newTableTitle = [[`ENGINE FUEL BREAKDOWN`]];
        utils.sheet_add_aoa(ws, newTableTitle, {
          origin: `A${Number(lastRow) + 3}`,
        });

        utils.sheet_add_json(ws, additionalFuelData, {
          origin: `A${Number(lastRow) + 4}`,
          skipHeader: false,
        });
      }
    }
  });

  // Save the workbook
  writeFile(wb, `${filename}.xlsx`);
};

export const exportToExcelForProduction = (
  data: Record<string, any>,
  filename: string,
  title: string,
  unitSystem: string,
  analyticsData: any[],
  userSelections: any,
  hoursBreakDown: {
    hauler: any;
    'Fuel Consumption': any;
    // loader: any;
  },
  suggestionData: any
) => {
  const wb = utils.book_new();

  const clonedData = JSON.parse(JSON.stringify(data));
  clonedData.fleetPeriodSummary = Array.isArray(clonedData.fleetPeriodSummary)
    ? clonedData.fleetPeriodSummary.flatMap((item: any) => {
        if (!item?.time_range) return []; // Ensure item is valid

        const period = item.time_range.includes('7_days')
          ? 'Last 7 Days'
          : item.time_range.includes('14_days')
          ? 'Last 14 Days'
          : item.time_range.includes('1_month')
          ? 'Last 4 Weeks'
          : item.time_range.includes('6_months')
          ? 'Last 6 Months'
          : null;

        const assetType =
          !item.time_range.includes('hauler') &&
          !item.time_range.includes('loader')
            ? 'all'
            : item.time_range.includes('hauler')
            ? 'hauler'
            : item.time_range.includes('loader')
            ? 'loader'
            : null;

        return item?.daily_production
          ? Object.entries(item.daily_production).map(([date, value]) => ({
              period,
              date,
              value,
              assetType,
            }))
          : [];
      })
    : [];

  const allFleetRange = clonedData.fleetPeriodSummary.filter(
    (item: any) => item.assetType === 'all'
  );
  const haulersRange = clonedData.fleetPeriodSummary.filter(
    (item: any) => item.assetType === 'hauler'
  );
  const loadersRange = clonedData.fleetPeriodSummary.filter(
    (item: any) => item.assetType === 'loader'
  );

  const returnAnalysisData: Array<Record<string, unknown>> = [];
  analyticsData.forEach((item) => {
    const data = {
      Item: item.title,
      Analysis: `${item.analysis} from last ${formatQuickRange(
        userSelections.quickrange
      )}`,
      Value: `${item.value}${item.unit}`,
    };
    return returnAnalysisData.push(data);
  });

  const fleetPeriod = (
    data.value === 'all fleet'
      ? allFleetRange
      : data.value === 'loader'
      ? loadersRange
      : haulersRange
  )
    .filter((item: any) => {
      const today = new Date();
      if (userSelections.showChart.includes('7 Days')) {
        item.period = 'Last 7 Days';
        return (
          new Date(item.date) >= new Date(today.setDate(today.getDate() - 8))
        );
      }
      if (userSelections.showChart.includes('14 Days')) {
        item.period = 'Last 14 Days';
        return (
          new Date(item.date) >= new Date(today.setDate(today.getDate() - 15))
        );
      }
      if (userSelections.showChart.includes('4 Weeks')) {
        item.period = 'Last 4 Weeks';
        return (
          new Date(item.date) >= new Date(today.setDate(today.getDate() - 32)) // this is due to an incorrect calculation already on the chart
        );
      }
      if (userSelections.showChart.includes('6 Months')) {
        item.period = 'Last 6 Months';
        return (
          new Date(item.date) >=
          new Date(
            today.setMonth(today.getMonth() - 6) - 1 * 24 * 60 * 60 * 1000
          )
        );
      }
      return true;
    })
    .sort(
      (a: any, b: any) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    );

  const suggestionData2 =
    Object.entries(suggestionData).length > 0
      ? data.value === 'all fleet'
        ? [
            ...Object.entries(suggestionData.facts),
            ...Object.entries(suggestionData.recommendations),
          ]
        : data.value === 'hauler'
        ? [
            ...Object.entries(
              filterOutSuggestionKeys(suggestionData, 'loader').facts
            ),
            ...Object.entries(
              filterOutSuggestionKeys(suggestionData, 'loader').recommendations
            ),
          ]
        : [
            ...Object.entries(
              filterOutSuggestionKeys(suggestionData, 'hauler').facts
            ),
            ...Object.entries(
              filterOutSuggestionKeys(suggestionData, 'hauler').recommendations
            ),
          ]
      : [];
  const processedData: any = [];

  const processAssets = clonedData.assets.map((item: any) => {
    const productionValue = Number(item?.max_load_sum);

    return {
      'Bumper ID': item.bumper_id,
      'Make/Model': `${item.make} ${item.model}`,
      'Asset Type': item.asset_type,
      [`Production Value (${getDataUnit('production', unitSystem)})`]:
        productionValue,
      [`Loads/Day (${getDataUnit('production', unitSystem)})`]: formatTo2dp(
        Number(item.avg_max_load_per_day)
      ),
      'Avg Cycle Time (mins)': formatTo2dp(
        Number(convertMinutesToHours(Number(item.avg_cycle_time)))
      ),
      'Pass Count/Cycle': formatTo2dp(Number(item.pass_count_cycle)),
    };
  });

  const htAssets = processAssets.filter(
    (item: any) => item.asset_type === 'haul_truck'
  );

  const loaderAssets = processAssets.filter(
    (item: any) => item.asset_type === 'loader'
  );

  const additionalData = getAdditionalProductionTableData(
    data.value,
    clonedData,
    unitSystem
  ).sort(
    (a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()
  );

  processedData.push(
    {
      title: `${clonedData.value} production target summary`,
      data: clonedData.fleetSummary,
      name: `${String(clonedData.value)
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')} Target Summary`,
    },
    {
      title: `${clonedData.value} production by date range summary`,
      data: [
        ...Array.from(
          new Map(
            fleetPeriod
              .filter((item: any) => item.date && item.value && item.assetType) // Filter out invalid entries
              .map((item: any) => [
                `${item.period}-${item.date}-${item.value}-${item.assetType}`,
                {
                  // ...item, // Spread existing item properties
                  Period: item.period,
                  Date: item.date,
                  [`Production (${getDataUnit('production', unitSystem)})`]:
                    item.value,
                  // 'Asset Type': item.assetType,
                  'Moving Average':
                    formatTo2dp(
                      userSelections.movingAverages?.find(
                        (avg: any) => avg?.date === item?.date
                      )?.movingAverage as number
                    ) ?? 0,
                  'Total Average (Hours/Day)': formatTo2dp(
                    userSelections.average
                  ),
                },
              ])
          ).values()
        ),
      ],
      name:
        (data.value === 'all fleet'
          ? `All Fleet`
          : data.value === 'loader'
          ? `Loaders`
          : `Haulers`) + ' Range Summary',
    },
    {
      title:
        data.value === 'all fleet'
          ? `Breakdown for all assets`
          : data.value === 'hauler'
          ? `Breakdown for all Haul Truck assets`
          : `Breakdown for all Loader assets`,
      data:
        data.value === 'all fleet'
          ? processAssets
          : data.value === 'hauler'
          ? htAssets
          : loaderAssets,
      name:
        (data.value === 'all fleet'
          ? 'All Assets'
          : data.value === 'hauler'
          ? `Assets - Haul Trucks`
          : `Assets - Loaders`) + ' Performance',
    },
    {
      title: 'Analytics Data',
      data: returnAnalysisData,
      name: 'Analytics Data',
    },
    {
      title: 'Suggestions',
      data: suggestionData2,
      name: 'Suggestions',
    },
    ...transformProductionData(additionalData, unitSystem) // create a different function
  );

  processedData.forEach((item: any) => {
    const sheetData = Array.isArray(item.data) ? item.data : [item.data];

    if (sheetData.length === 0) {
      return;
    }

    const headers = setHeaders(sheetData[0], unitSystem);

    // Ensure headers are only added once
    if (
      !sheetData.some((row: any) => {
        return Object.keys(row)[0] === Object.keys(headers)[0];
      })
    ) {
      sheetData.unshift(headers);
    }

    const formattedTitle = item.subTitle
      ? [
          [item.title.toUpperCase()],
          ...item.subTitle.split(',').map((s: any) => [s]),
        ]
      : [[item.title.toUpperCase()]];

    const ws = utils.json_to_sheet([]);

    utils.sheet_add_aoa(ws, formattedTitle, { origin: 'A1' });

    // Start table data from the next available row
    const dataStartRow = item.subTitle
      ? formattedTitle.length + 2
      : formattedTitle.length + 1;

    // Add data below title and subtitle
    utils.sheet_add_json(ws, sheetData, {
      origin: `A${dataStartRow}`,
      skipHeader: Array.isArray(sheetData[0]),
    });

    ws['!merges'] = [
      {
        s: { r: 0, c: 0 },
        e: { r: 0, c: Object.keys(sheetData[0]).length - 1 },
      },
    ];

    if (!ws.A1) ws.A1 = {};
    ws.A1.s = {
      font: { bold: true, sz: 14 },
      alignment: { horizontal: 'center' },
    };

    utils.book_append_sheet(wb, ws, trimSheetName(item.name || 'Sheet'));

    if (item.name === 'Analytics Data') {
      const additionalData = Object.entries({ ...hoursBreakDown }).map(
        (item) => {
          if (item[0] === 'Fuel Consumption') {
            return {};
          }
          const newObj = Object.assign(
            { 'Asset Types': item[0].toUpperCase() + 'S' },
            item[1]
          );

          return newObj;
        }
      );

      const additionalDataFuel = Object.entries(hoursBreakDown)
        .filter(([key]) => key === 'Fuel Consumption') // Filter first
        .map((item) => {
          const newObj = Object.assign({ '': '' }, item[1]);
          return newObj;
        });

      if (additionalData.length > 0) {
        const existingRange = ws['!ref']; // Get current range
        const lastRow = existingRange
          ? +existingRange.split(':')[1].replace(/\D/g, '')
          : sheetData.length;

        const newTableTitle = [[`AVERAGE CYCLE TIME`]];
        utils.sheet_add_aoa(ws, newTableTitle, {
          origin: `A${Number(lastRow) + 3}`,
        });
        // Add new table data below
        utils.sheet_add_json(ws, additionalData, {
          origin: `A${Number(lastRow) + 4}`,
          skipHeader: false,
        });
      }

      if (additionalDataFuel.length > 0) {
        const existingRange = ws['!ref'];
        const lastRow = existingRange
          ? +existingRange.split(':')[1].replace(/\D/g, '')
          : sheetData.length;

        const newTableTitle = [[`FUEL CONSUMPTION`]];
        utils.sheet_add_aoa(ws, newTableTitle, {
          origin: `A${Number(lastRow) + 2}`,
        });

        utils.sheet_add_json(ws, additionalDataFuel, {
          origin: `A${Number(lastRow) + 3}`,
          skipHeader: false,
        });
      }
    }
  });

  // Save the workbook
  writeFile(wb, `${filename}.xlsx`);
};

export const exportToExcelForFuelUsage = (
  data: FuelAnalyticsOverview,
  filename: string,
  fleetDeviceDetails: FleetDeviceDetails[],
  fleetDeviceDetailsPerDayBasis: FleetDeviceDetailsPerDay[],
  fuelEventsPieData: Record<string, number>,
  assetType: string,
  unitSystem: string
) => {
  const wb = utils.book_new();

  // First Sheet - Summary
  const summarySheetData = [];
  const summary = data.allFleet.summary;

  const prefix =
    assetType === 'total'
      ? 'fleet_'
      : assetType === 'loaders' || assetType === 'loader'
      ? 'loader_'
      : 'hauler_';

  summarySheetData.push([
    'Metric',
    `Fuel Rate (${unitSystem === 'metric' ? 'L/Hr' : 'Gal/Hr'})`,
    `Total Fuel Consumption (${unitSystem === 'metric' ? 'L' : 'Gal'})`,
    `CO2 Emitted (${unitSystem === 'metric' ? 'Kg' : 'Lbs'})`,
    'Cost Incurred ($)',
    'Fuel Efficiency Lost (%)',
  ]);
  summarySheetData.push([
    'Value',
    +(summary?.[`${prefix}avg_fuel_rate`]?.toFixed(4) || '0'),
    +(summary?.[`${prefix}total_fuel_consumption_l`]?.toFixed(4) || '0'),
    +(summary?.[`${prefix}CO2_used`]?.toFixed(4) || '0'),
    +(summary?.[`${prefix}fuel_cost_incurred`]?.toFixed(4) || '0'),
    +(summary?.[`${prefix}fuel_eff_lost`]?.toFixed(4) || '0'),
  ]);

  const summaryWS = utils.aoa_to_sheet(summarySheetData);
  utils.book_append_sheet(wb, summaryWS, 'Summary');

  const analyticSheetData = [];
  analyticSheetData.push(['Fuel Usage Breakdown']);
  analyticSheetData.push([
    'Event',
    `Fuel Usage (${unitSystem === 'metric' ? 'L' : 'Gal'})`,
  ]);
  const entries = Object.entries(fuelEventsPieData);
  let totalExcessFuelUsage = 0;
  entries.forEach(([key, value]) => {
    if (!key?.includes('diff')) {
      analyticSheetData.push([
        capitalizeFirstLetter(key?.split('_').join(' ')),
        value,
      ]);
      totalExcessFuelUsage += value;
    }
  });
  analyticSheetData.push([]);
  analyticSheetData.push([
    'Total Fuel Usage',
    summary?.[`${prefix}total_fuel_consumption_l`],
  ]);
  analyticSheetData.push([
    'Total Excess Fuel Usage',
    +totalExcessFuelUsage?.toFixed(4),
  ]);

  const analyticWS = utils.aoa_to_sheet(analyticSheetData);
  utils.book_append_sheet(wb, analyticWS, 'Fuel Usage Breakdown');

  // Other Sheets - Per Asset
  fleetDeviceDetailsPerDayBasis
    ?.filter((device) => {
      if (assetType === 'total') {
        return true;
      } else if (assetType === 'loaders' || assetType === 'loader')
        return device.asset_type === 'loader';
      else return device.asset_type === 'haul_truck';
    })
    .forEach((device) => {
      const sheetData: Array<Array<string | number>> = [
        [
          'Date',
          `Total Fuel Consumption (${unitSystem === 'metric' ? 'L' : 'Gal'})`,
          `CO2 Emitted (${unitSystem === 'metric' ? 'Kg' : 'Lbs'})`,
          'No. of Idle Events',
          'Total Idle Duration (Hrs)',
          `Avg. Fuel Rate (${unitSystem === 'metric' ? 'L/Hr' : 'Gal/Hr'})`,
        ],
      ];

      if (assetType === 'haul_truck') {
        sheetData?.[0]?.push(
          `Loaded Fuel Consumption ${unitSystem === 'metric' ? 'L' : 'Gal'}`,
          `Unloaded Fuel Consumption ${unitSystem === 'metric' ? 'L' : 'Gal'}`
        );
      }

      Object.keys(device.date_stats).forEach((date) => {
        const stats = device.date_stats[date];
        const row = [
          date,
          +(stats.total_fuel_consumption?.toFixed(4) || '0'),
          +(stats.co2_emission?.toFixed(4) || '0'),
          +(stats.num_of_idle_events?.toFixed(4) || '0'),
          +(stats.total_duration_of_idle_events?.toFixed(4) || '0'),
          +(stats.avg_fuel_rate?.toFixed(4) || '0'),
        ];

        if (assetType === 'haul_truck') {
          row.push(
            +(stats.loaded_fuel_consumption?.toFixed(4) || '0'),
            +(stats.unloaded_fuel_consumption?.toFixed(4) || '0')
          );
        }

        sheetData.push(row);
      });

      const ws = utils.aoa_to_sheet(sheetData);
      const sheetName = device.bumper_id || device.device || 'Unknown';
      utils.book_append_sheet(wb, ws, sheetName.substring(0, 31)); // Excel sheet name limit
    });

  // Save the workbook
  writeFile(wb, `${filename}.xlsx`);
};
