import TargetAPI from 'api/target.api';

import { BaseHandler } from './base.handler';
import { showNotification } from 'store/notification.slice';
import {
  setTarget,
  setTargetLoading,
  updateAllTargets,
} from 'store/targets.slice';
import { updateTargets } from 'store/production.slice';
import { isAxiosError } from 'axios';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';

export default class TargetHandler extends BaseHandler {
  private readonly api: TargetAPI;

  constructor() {
    super();

    this.api = new TargetAPI();
  }

  async getAll(
    customerId: string,
    year: number,
    signal?: AbortSignal
  ): Promise<any> {
    try {
      this.dispatch(setTargetLoading({ isLoadingTarget: true }));
      const targetPromise = await this.api.getTargetAllApi(
        customerId,
        year,
        signal
      );
      this.dispatch(updateAllTargets(targetPromise));
      // if (targetPromise?.metricName === `production`) {
      //   this.dispatch(
      //     updateTargets({ targetValue: targetPromise?.targetValue })
      //   );
      // }
      return targetPromise;
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      this.dispatch(setTargetLoading({ isLoadingTarget: false }));
    }
  }

  async getTarget(
    customerId: string,
    metricName: string,
    assetType: string,
    year: number,
    signal?: AbortSignal
  ): Promise<any> {
    try {
      this.dispatch(setTargetLoading({ isLoadingTarget: true }));
      const targetPromise = await this.api.getTargetApi(
        customerId,
        metricName,
        assetType,
        year,
        signal
      );
      targetPromise.targetValue = Number(targetPromise?.targetValue || 0);
      this.dispatch(setTarget(targetPromise));
      if (targetPromise?.metricName === `production`) {
        this.dispatch(
          updateTargets({ targetValue: targetPromise?.targetValue })
        );
      }
      return targetPromise;
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      this.dispatch(setTargetLoading({ isLoadingTarget: false }));
    }
  }

  async postTarget(
    customerId: string,
    metricName: string,
    targetValue: number,
    year: number,
    assetType: string
  ): Promise<any> {
    try {
      this.dispatch(setTargetLoading({ isLoadingTarget: true }));
      const targetPromise = await this.api.postTargetApi(
        customerId,
        metricName,
        targetValue,
        year,
        assetType
      );
      this.dispatch(setTarget({ ...targetPromise?.target }));
      // if (targetPromise?.target.metricName === `production`) {
      //   this.dispatch(updateTargets({ ...targetPromise?.target, targetValue }));
      // }
      this.dispatch(
        showNotification({
          message: 'Target updated successfully',
          type: 'success',
        })
      );
      return targetPromise;
    } catch (error) {
      console.error(error);
      if (isAxiosError(error)) {
        this.dispatch(
          showNotification({
            message: capitalizeFirstLetter(error.response?.data?.message),
            type: 'error',
          })
        );
      }
      return {};
    } finally {
      this.dispatch(setTargetLoading({ isLoadingTarget: false }));
    }
  }
}
