interface calculateSavingsProps {
  totalWorkOrders: number;
  numAssets: number;
  maintenanceHoursPerWO: number;
  manufacturerFrequency: number;
  applicationFrequency: number;
  totalMaintenanceCost: number;
  tonsPerRound: number;
  roundsPerDay: number;
  startDate?: string;
  endDate?: string;
}

interface KPIInterface {
  downtimeSaved: number;
  costSaved: number;
  productivityImproved: number;
}

interface ResultInterface {
  annual: KPIInterface;
  dateRange?: KPIInterface;
  daysInRange?: number;
  proportionOfYear?: number;
}

interface UseKPIInterface {
  totalWorkOrders: number;
  numAssets: number;
  maintenanceHoursPerWO: number;
  manufacturerFrequency: number;
  applicationFrequency: number;
  totalMaintenanceCost: number;
  tonsPerRound: number;
  roundsPerDay: number;
  startDate?: string;
  endDate?: string; // Half year example
}
export function calculateSavings({
  totalWorkOrders,
  numAssets,
  maintenanceHoursPerWO,
  manufacturerFrequency,
  applicationFrequency,
  totalMaintenanceCost,
  tonsPerRound,
  roundsPerDay,
  startDate,
  endDate,
}: calculateSavingsProps) {
  // Calculate days between two dates (inclusive)
  const getDaysInRange = (start: Date, end: Date) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeDiff = endDate.getTime() - startDate.getTime();
    return Math.floor(timeDiff / (1000 * 3600 * 24)) + 1; // +1 to include both dates
  };

  // Calculate work orders saved
  const workOrdersManufacturer = 365 / manufacturerFrequency;
  const workOrdersApplication = 365 / applicationFrequency;
  const workOrdersSavedPerAssetIntermediate =
    workOrdersManufacturer - workOrdersApplication;
  const workOrdersSavedPerAsset =
    workOrdersSavedPerAssetIntermediate <= 0.5 &&
    workOrdersSavedPerAssetIntermediate > 0
      ? 0.5
      : Math.round(workOrdersSavedPerAssetIntermediate);
  const totalWorkOrdersSaved = workOrdersSavedPerAsset * numAssets;

  // Calculate annual savings
  const annualDowntime = totalWorkOrdersSaved * maintenanceHoursPerWO;
  const annualCost =
    totalWorkOrders > 0
      ? totalWorkOrdersSaved * (totalMaintenanceCost / (totalWorkOrders || 1))
      : 0;
  const annualProductivity =
    workOrdersSavedPerAsset * (tonsPerRound * roundsPerDay) * numAssets;

  // Initialize result object
  const result: ResultInterface = {
    annual: {
      downtimeSaved: annualDowntime,
      costSaved: annualCost,
      productivityImproved: annualProductivity,
    },
  };

  // Calculate date range proportional savings if dates provided
  if (startDate && endDate) {
    const daysInRange = getDaysInRange(new Date(startDate), new Date(endDate));
    const proportion = daysInRange / 365;

    result.dateRange = {
      downtimeSaved: annualDowntime * proportion,
      costSaved: annualCost * proportion,
      productivityImproved: annualProductivity * proportion,
    };
    result.daysInRange = daysInRange;
    result.proportionOfYear = proportion;
  }

  return result;
}

const useKPI = (clientData: UseKPIInterface) => {
  // Example parameters:
  // const clientData = {
  //   totalWorkOrders: 24,
  //   numAssets: 8,
  //   maintenanceHoursPerWO: 24,
  //   manufacturerFrequency: 121,
  //   applicationFrequency: 136,
  //   totalMaintenanceCost: 358450,
  //   tonsPerRound: 82.3,
  //   roundsPerDay: 20,
  //   startDate: '2024-01-01',
  //   endDate: '2024-06-30', // Half year example
  // };

  const savings = calculateSavings(clientData);
  return savings;
};

export default useKPI;
