/* eslint-disable @typescript-eslint/restrict-plus-operands */
export interface AssetData {
  name: string;
  total_cost: number;
  total_hours: number;
  total_labour_cost: number;
  total_part_cost: number;
  [key: string]: any;
}

export interface TimeseriesData {
  name: string;
  asset_id: string;
  timestamp: string;
  work_order_number: string;
  total_cost_asset: number;
  total_labour_cost: number;
  total_part_cost: number;
}

export function extractWorkOrderData(data: Record<string, any>): AssetData[] {
  const result: AssetData[] = [];
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { total_cost, total_hours, total_labour_cost, total_part_cost } =
        data[key];

      const newObj: AssetData = {
        name: key,
        total_cost, // align property names with the AssetData interface
        total_hours,
        total_labour_cost,
        total_part_cost,
      };

      result.push(newObj);
    }
  }

  result.sort((a, b) => b.total_cost - a.total_cost);
  return result;
}

export function getAssetsByKey(
  data: Record<string, any>,
  keys: string[]
): AssetData[] {
  if (!data) {
    return [];
  }
  const assetsWithNames: AssetData[] = [];

  Object.entries(data).forEach(([key, value]) => {
    if (keys.includes(key)) {
      assetsWithNames.push({
        ...value,
        name: key,
      });
    }
  });

  return assetsWithNames;
}

export function extractSelectedAssetsTimesSeries(
  dataset: Record<string, any[]>,
  selectedAssets: string[],
  selectedAssetsCostType: string[]
): any {
  if (!dataset) {
    return [];
  }
  const extractedData: Record<string, any[]> = {};

  // Filter the dataset for selected assets
  selectedAssets.forEach((assetId) => {
    if (dataset[assetId]) {
      const filteredData = dataset[assetId]
        .map((entry) => {
          // Create a new object potentially only with selected cost types
          const filteredEntry: any = {
            asset_id: entry.asset_id,
            timestamp: entry.timestamp,
            work_order_number: entry.work_order_number,
            total_cost_asset: entry.total_cost_asset,
          };
          selectedAssetsCostType.forEach((costType) => {
            filteredEntry[costType] = entry[costType];
          });
          return filteredEntry;
        })
        // Sort entries by timestamp within each asset
        .sort((a, b) =>
          a.timestamp.toString().localeCompare(b.timestamp.toString())
        );
      extractedData[assetId] = filteredData;
    }
  });

  // Create an output array with asset data properly structured and sorted
  const output = Object.keys(extractedData).map((assetKey) => ({
    name: assetKey,
    data: extractedData[assetKey],
  }));
  return output;
}

export function getFirstAttribute(obj: any): string[] {
  if (!obj) {
    return [];
  }
  const arr = [];
  let response = [];
  // Check if the object is empty
  if (Object.keys(obj).length === 0) {
    response = [];
  }

  for (const key in obj) {
    arr.push({ [key]: obj[key].total_cost || 0 });
  }

  if (arr.length > 0) {
    const sortedArr = arr.sort((a, b) => {
      const costA = Object.values(a)[0];
      const costB = Object.values(b)[0];
      return costB - costA;
    });

    const highestKey = Object.keys(sortedArr[0])[0];
    response.push(highestKey);
  }
  return response;
}

export function filterObjectByKeys(obj: any, keys: string[]) {
  return keys.reduce((result: any, key: string) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}

export const aggregateAssetData = (
  assetData: Record<string, Record<string, number>>,
  colors: string[]
) => {
  const aggregatedData: Record<string, number> = {};

  Object.values(assetData).forEach((asset) => {
    Object.entries(asset).forEach(([key, value]) => {
      if (typeof value === 'number') {
        aggregatedData[key] = (aggregatedData[key] || 0) + value;
      }
    });
  });

  return Object.entries(aggregatedData).map(([name, value], index) => ({
    name,
    value,
    color: colors[index % colors.length],
  }));
};

export function aggregateCostData(data: any) {
  const name = data.length === 1 ? data[0].name : 'aggregated_data';
  const flattenedData = data.reduce((acc: any, asset: any) => {
    return acc.concat(asset.data);
  }, []);

  const aggregatedByTimestamp = flattenedData.reduce(
    (acc: any, current: any) => {
      const timestamp = current.timestamp;

      if (acc[timestamp]) {
        acc[timestamp].total_cost_asset += current.total_cost_asset;
        acc[timestamp].total_labour_cost += current.total_labour_cost;
        acc[timestamp].total_part_cost += current.total_part_cost;

        const existingWorkOrders = acc[timestamp].work_order_number.split(',');
        const currentWorkOrder = current.work_order_number.toString();
        if (!existingWorkOrders.includes(currentWorkOrder)) {
          acc[timestamp].work_order_number += `,${currentWorkOrder}`;
        }
      } else {
        acc[timestamp] = {
          timestamp: timestamp,
          work_order_number: current.work_order_number.toString(),
          total_cost_asset: current.total_cost_asset,
          total_labour_cost: current.total_labour_cost,
          total_part_cost: current.total_part_cost,
        };
      }

      return acc;
    },
    {}
  );

  const result = Object.values(aggregatedByTimestamp);

  return [
    {
      name,
      data: result,
    },
  ];
}
