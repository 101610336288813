import { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import { useAppSelector } from 'store/hook';
import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import { ComponentsTreeChart } from './Components/TreeChart';
import { Base } from 'components/Containers/BaseContainer';
import {
  extractWorkOrderData,
  getAssetsByKey,
  getFirstAttribute,
  extractSelectedAssetsTimesSeries,
  type AssetData,
} from './utils';
import { ThemePalette } from 'mui.theme';
import LoadingDots from 'views/FleetOverview/components/AssetCardsV2/LoadingDots';
import { TabList, TabPanel, Tabs } from 'react-tabs';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import SWTab from 'components/Layout/Tabs/SWTab';
import TotalCostVSLabourCost from './Components/TotalCostVSLabourCost';
import useComponentCostBreakdown from './hooks/useComponentCostBreakdown';
import ComponentsCostBreakdown from './Components/ComponentsCostBreakdown';
import useAppTheme from 'hooks/useAppTheme';
import SelectAllCheckbox from './Components/SelectAllCheckbox';
import MaintenanceBreakdown from './Components/MaintenanceBreakdown';
import WorkOrderStatusBreakdown from './Components/WorkOrderBreakdown';
import MaintenanceComparison from './Components/MaintenanceComparison';

export const AssetsViewV2 = () => {
  const [selectAllAssets, setSelectAllAssets] = useState<boolean>(false);
  const { enableDarkTheme } = useAppTheme();
  const accessToken = useAppSelector((state) => state.authReducer.accessToken);
  const squaresHandler = new SquaresDashboardHandler();
  const { assetDashboard: assetDashboardData, maintenanceMetrics } =
    useAppSelector((state) => state.squaresDashboardReducer);

  // interface WorkOrderItem {
  //   name: string;
  //   totalCost: number;
  //   totalHours: string;
  //   totalLabourCost: string;
  //   totalPartCost: string;
  // }

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [workOrderData, setWorkOrderData] = useState<AssetData[]>([]);

  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );
  let initialStartDate = new Date();
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1);
  let initialEndDate = new Date();
  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);
    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedStartDate, setStartDate] = useState(initialStartDate);
  const [selectedEndDate, setEndDate] = useState(initialEndDate);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);

    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };

  const handleSelectAllAssets = () => {
    setSelectAllAssets(!selectAllAssets);
  };

  const [selectedCell, setSelectedCell] = useState<string[]>([]);
  // const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [selectedAssetsCostType, setSelectedAssetsCostType] = useState<
    string[]
  >(
    ['total_labour_cost', 'total_part_cost'] // Initialize with all available types
  );

  const handleTreeCellClick = (name: string) => {
    let newSelectedCell;

    if (selectedCell.length === 1 && selectedCell[0] === name) {
      // don't do anything if only one cell is selected
      return;
    }

    if (selectedCell.includes(name)) {
      // Remove name if it's already in the array
      newSelectedCell = selectedCell.filter((cellName) => cellName !== name);
    } else {
      // Add name to the array if it's not already included
      newSelectedCell = [...selectedCell, name];
    }

    setSelectedCell(newSelectedCell);
    // setSelectedAssets(newSelectedCell);
    // Clear assets if no cell is selected
    // if (newSelectedCell.length <= 0) {
    //   // setSelectedAssets([]);
    // }
  };

  const handlePieClick = (data: any) => {
    const name = data.name;
    const updatedClickedCells = [...selectedAssetsCostType];
    if (updatedClickedCells.includes(name)) {
      // Remove the clicked name if it's already selected
      const indexToRemove = updatedClickedCells.indexOf(name);
      updatedClickedCells.splice(indexToRemove, 1);
    } else {
      // Add the clicked name if it's not already selected
      updatedClickedCells.push(name);
    }
    setSelectedAssetsCostType(updatedClickedCells);
  };

  const pieChartDataByKey = getAssetsByKey(
    assetDashboardData.assets,
    selectedCell
  );
  const selectedAssetsTimesSeries = extractSelectedAssetsTimesSeries(
    assetDashboardData.timeseries,
    selectedCell,
    selectedAssetsCostType
  );

  const { pieChartData } = useComponentCostBreakdown({
    selectedAssets: selectedCell,
  });

  const fetchData = useCallback(async () => {
    try {
      if (!accessToken) return;
      if (selectedStartDate && selectedEndDate) {
        setIsLoading(true);
        // setSelectedAssets([]);
        const promises = [];
        promises.push(
          squaresHandler.getDashboardAssetsData(
            selectedStartDate,
            selectedEndDate
          ),
          squaresHandler.getPartsComponentsData(
            selectedStartDate,
            selectedEndDate
          ),
          squaresHandler.getPmVsCmMaintenanceMetrics(
            selectedStartDate,
            selectedEndDate
          )
        );
        await Promise.all(promises);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, selectedStartDate, selectedEndDate, squaresHandler]);

  useEffect(() => {
    void fetchData();
  }, [selectedStartDate, selectedEndDate, accessToken]);

  useEffect(() => {
    if (selectAllAssets) {
      setSelectedCell(Object.keys(assetDashboardData.assets));
    } else {
      const highestKey = getFirstAttribute(assetDashboardData.assets);
      setSelectedCell(highestKey);
    }
    setWorkOrderData(extractWorkOrderData(assetDashboardData.assets));
    setIsLoading(false);
  }, [assetDashboardData, selectAllAssets]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          paddingLeft: '40%',
          paddingTop: '10px',
          paddingBottom: '20px',
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            paddingRight: '10px',
            fontSize: '20px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {' '}
          Assets Total Cost
        </Typography>
        <DatePicker
          showIcon
          selected={selectedStartDate}
          onChange={handleDateChange}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          maxDate={new Date(new Date().getTime())}
          selectsRange
        />
        {/* select all assets checkbox */}
        <Box
          sx={{
            marginLeft: 'auto',
          }}
        >
          <SelectAllCheckbox
            selectAll={selectAllAssets}
            handleSelectAll={handleSelectAllAssets}
          />
        </Box>
      </Box>
      {isLoading ? (
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '20px',
            paddingTop: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Waiting For Assets
          <LoadingDots enableDarkTheme={enableDarkTheme} />{' '}
        </Typography>
      ) : (
        <>
          {workOrderData.length <= 0 ? (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '20px',
                paddingTop: '10px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {' '}
              No Data, Please Select a different Date Range
            </Typography>
          ) : (
            <>
              <Base
                sx={{
                  padding: '20px 20px 40px 20px',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.toolBarBackground
                    : ThemePalette.light.toolBarBackground,
                }}
              >
                <Box
                  sx={{
                    height: '400px',
                    maxHeight: '400px',
                  }}
                >
                  <ComponentsTreeChart
                    selectedCell={selectedCell}
                    handleCellClick={handleTreeCellClick}
                    data={workOrderData}
                    enableDarkTheme={enableDarkTheme}
                  />
                </Box>
              </Base>
              <Box
                sx={{
                  marginY: '20px',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.toolBarBackground
                    : ThemePalette.light.toolBarBackground,
                }}
              >
                <Tabs>
                  <TabList>
                    <ToolbarContainer
                      style={{
                        paddingBottom: 0,
                      }}
                      enableDarkTheme={enableDarkTheme}
                    >
                      {/* BUG: The Permission tag causes MUI to detect the SWTab and create empty tab indexes */}
                      <SWTab enableDarkTheme={enableDarkTheme}>
                        Component Cost Breakdown
                      </SWTab>
                      <SWTab enableDarkTheme={enableDarkTheme}>
                        Labour Cost vs Part Cost
                      </SWTab>
                      <SWTab enableDarkTheme={enableDarkTheme}>
                        Maintenance Comparison
                      </SWTab>
                      <SWTab enableDarkTheme={enableDarkTheme}>
                        Maintenance Distribution
                      </SWTab>
                      <SWTab enableDarkTheme={enableDarkTheme}>
                        Work Order Status
                      </SWTab>
                    </ToolbarContainer>
                  </TabList>
                  <TabPanel
                    style={{
                      padding: '11px 21px 21px',
                    }}
                  >
                    <ComponentsCostBreakdown
                      pieChartData={pieChartData}
                      selectedCell={selectedCell}
                    />
                  </TabPanel>
                  <TabPanel
                    style={{
                      padding: '11px 21px 21px',
                    }}
                  >
                    <TotalCostVSLabourCost
                      handlePieClick={handlePieClick}
                      pieChartDataByKey={pieChartDataByKey}
                      selectedAssetsCostType={selectedAssetsCostType}
                      selectedAssetsTimesSeries={selectedAssetsTimesSeries}
                      pieChartTitle="Labour Cost vs Part Cost"
                      lineChartTitle="Labor vs Part Cost Trend"
                    />
                  </TabPanel>
                  <TabPanel>
                    <MaintenanceComparison
                      enableDarkTheme={enableDarkTheme}
                      data={maintenanceMetrics}
                      selectedCell={selectedCell}
                    />
                  </TabPanel>
                  <TabPanel>
                    <MaintenanceBreakdown
                      enableDarkTheme={enableDarkTheme}
                      data={maintenanceMetrics}
                      selectedCell={selectedCell}
                    />
                  </TabPanel>
                  <TabPanel>
                    <WorkOrderStatusBreakdown
                      enableDarkTheme={enableDarkTheme}
                      data={maintenanceMetrics}
                      selectedCell={selectedCell}
                    />
                  </TabPanel>
                </Tabs>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};
