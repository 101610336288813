import { createSlice } from '@reduxjs/toolkit';
import { type HealthScore, type DashboardSquares } from 'types/squares.type';

export interface AssetComponentCostInterface {
  asset_id: string;
  timestamp: number;
  work_order_number: string;
  component: string;
  total_cost: number;
  total_labour_cost: number;
  total_part_cost: number;
}

export interface SquaresAssetCostDataInterface {
  asset: string;
  total_asset_cost: number;
}

export interface SquaresComponentCostDataInterface {
  total_cost: number;
  total_work_orders: string;
  total_hours: string;
  assets: SquaresAssetCostDataInterface[];
}

export interface DashboardSquaresState {
  squares: DashboardSquares[];
  squareAssets: DashboardSquares[];
  dashboard: {
    assets: any;
    components?: Record<string, SquaresComponentCostDataInterface>;
    timeseries?: any;
    assetComponentsCosts: Record<string, AssetComponentCostInterface[]>;
    workOrders: any[];
  };
  assetDashboard: {
    assets: any;
    components?: any;
    timeseries?: any;
  };
  healthScoreData: HealthScore[];
  maintenanceMetrics: any;
}

const initialState: DashboardSquaresState = {
  squares: [],
  squareAssets: [],
  dashboard: {
    assets: {},
    components: {},
    timeseries: {},
    assetComponentsCosts: {},
    workOrders: [],
  },
  assetDashboard: {
    assets: {},
    components: {},
    timeseries: {},
  },
  healthScoreData: [],
  maintenanceMetrics: {
    actionsCounter: {},
    workOrderStatusCounter: {},
    maintenanceCounter: {},
  },
};

export const squaresDashboardSlice = createSlice({
  name: 'squares',
  initialState,
  reducers: {
    appendSquares(state, { payload }) {
      state.squares.push(payload);
    },

    updateSquaresList(state, { payload }: { payload: any }) {
      const index = state.squares.findIndex(
        (squares) => squares.category === payload.squaresId
      );

      if (index === -1) {
        // do not update the state if the squares is not found
        return;
      }

      state.squares[index] = payload;
    },

    setSquares(state, { payload }) {
      state.squares = payload;
    },

    setSquaresAssets(state, { payload }) {
      state.squareAssets = payload;
    },
    setSquaresDashboard(state, { payload }) {
      state.dashboard = payload;
    },
    setSquaresAssetsDashboard(state, { payload }) {
      state.assetDashboard = payload;
    },
    setHealthScore(state, { payload }) {
      state.healthScoreData = payload;
    },
    setMaintenanceMetrics(state, { payload }) {
      state.maintenanceMetrics = payload;
    },
  },
});

export const {
  appendSquares,
  updateSquaresList,
  setSquares,
  setSquaresAssets,
  setSquaresDashboard,
  setSquaresAssetsDashboard,
  setHealthScore,
  setMaintenanceMetrics,
} = squaresDashboardSlice.actions;

export default squaresDashboardSlice.reducer;
