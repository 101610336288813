import { useCallback } from 'react';
import { useAppSelector } from 'store/hook';

interface Props {
  selectedAssets: string[];
}
const useComponentCostBreakdown = ({ selectedAssets }: Props) => {
  const {
    dashboard: { components },
  } = useAppSelector((state) => state.squaresDashboardReducer);

  const calculateTotalComponentsCostForSelectedAssets = useCallback<
    () => Array<{ asset: string; name: string; total_asset_cost: number }>
  >(() => {
    // if components is falsy, return an empty map
    if (!components) {
      return [];
    }

    // final pie chart data for components contribution for each selected asset

    const pieChartMap = new Map<string, { total_cost: number }>();

    const selectedAssetsSet = new Set(selectedAssets);
    const entries = Object.entries(components ?? {});
    /* 
      final structure needed: 
      name: string - name of component, 
      total_cost: number - total cost of component accross selected assets
    */
    for (const entry of entries) {
      const [component, values] = entry;
      const { assets } = values;
      pieChartMap?.set(component, {
        total_cost: 0,
      });
      let { total_cost: totalCost } = pieChartMap.get(component) ?? {
        total_cost: 0,
      };
      for (const assetData of assets) {
        const { asset, total_asset_cost: totalAssetCost } = assetData;
        if (selectedAssetsSet.has(asset)) {
          totalCost += totalAssetCost;
        }
        // assetsSet.add(asset);
      }
      pieChartMap.set(component, {
        total_cost: totalCost,
      });
    }
    const pieChartData: Array<{
      asset: string;
      name: string;
      total_asset_cost: number;
    }> = [];
    pieChartMap.forEach((value, key) => {
      pieChartData.push({
        asset: key,
        name: key,
        total_asset_cost: value.total_cost,
      });
    });
    return pieChartData;
  }, [components, selectedAssets]);
  const pieChartData = calculateTotalComponentsCostForSelectedAssets();
  return {
    pieChartData,
  };
};

export default useComponentCostBreakdown;
