import { Box, Grid } from '@mui/material';
import TirePressure from '../TirePressure';
import FourWheels from '../Schematics/FourWheels';
import { tireDataCalc } from './util';

export const FourTires = ({
  tireData,
  limits,
  enableDarkTheme,
  unitSystem,
  tireCodes,
  metricType,
}: {
  tireData: any;
  limits: any;
  enableDarkTheme: boolean;
  unitSystem: string;
  tireCodes: any;
  metricType: string;
}) => {
  const tireNumberSystem = ['0x17', '0x19', '0x27', '0x29'];

  const data: any = tireDataCalc(tireData, metricType, limits, unitSystem);

  const slowLeaks = tireData.tireCurrentStatus?.slowLeaks ?? [];

  const fastLeaks = tireData.tireCurrentStatus?.fastLeaks ?? [];

  return (
    <>
      <Grid item lg={3}>
        <Box
          sx={{
            marginTop: '80px',
          }}
        >
          {[1, 3].map((item, index) => {
            return (
              <TirePressure
                key={index}
                enableDarkTheme={enableDarkTheme}
                value={data[`p${item}`]}
                limit={data[`p${item}Limits`]}
                tireCode={tireNumberSystem[item - 1]}
                unitSystem={unitSystem}
                tireCodes={tireCodes}
                metricType={metricType}
                slowLeaks={slowLeaks}
                fastLeaks={fastLeaks}
              />
            );
          })}
        </Box>
      </Grid>
      <Grid item lg={6}>
        <Box
          sx={{
            textAlign: 'center',
            paddingTop: '30px',
            paddingBottom: '30px',
          }}
        >
          <FourWheels name="tireVehicleSchematicIcon" values={data} />
        </Box>
      </Grid>
      <Grid item lg={3}>
        <Box
          sx={{
            marginTop: '80px',
          }}
        >
          {[2, 4].map((item, index) => {
            return (
              <TirePressure
                key={index}
                enableDarkTheme={enableDarkTheme}
                value={data[`p${item}`]}
                limit={data[`p${item}Limits`]}
                tireCode={tireNumberSystem[item - 1]}
                unitSystem={unitSystem}
                tireCodes={tireCodes}
                metricType={metricType}
                slowLeaks={slowLeaks}
                fastLeaks={fastLeaks}
              />
            );
          })}
        </Box>
      </Grid>
    </>
  );
};
