import React, { useEffect, useState } from 'react';
// import { Authenticate } from 'components/Route/Auth';
import { SideBar } from 'components/Layout/Sidebar';
import { NoAccess } from 'components/Modals/NoAccessModal/NoAccessModal';
import Permission from 'components/Permission/Permission';
import {
  type PermissionConstraint,
  type Role,
} from 'components/Permission/permission.types';

import { useAppDispatch, useAppSelector } from 'store/hook';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CustomerSelectionView } from 'views/Settings/CustomerSelectionView/CustomerSelectionView';
import { FleetOverview } from 'views/FleetOverview/FleetOverview';
import LoginView from 'views/Login/Login';
import { PlayGround } from 'views/Playground/Playground';
import { CustomersView } from 'views/Settings/Customer/CustomerView';
import { EditCustomerContactView } from 'views/Settings/ExternalUsers/EditCustomerContactView/EditCustomerContactView';
import { EntityManagementView } from 'views/Settings/ExternalUsers/EntityManagementView';
// import { VehicleStatusView } from 'views/VehicleStatusView/VehicleStatusView';
import { SensorAnalysisView2 } from 'views/SensorAnalysisView/SensorAnalysisView2';
import { InternalUsersView } from 'views/Settings/InternalUsers/InternalUsersView';
import { NotificationView } from 'views/Settings/Rules/NotificationView';
import { NotificationsView } from 'views/Settings/Notifications/NotificationsView';
import { DTCView } from 'views/EngineHours/DTCView';
import { navigationMap } from './navigationMap';
// import { LocalizationView } from 'views/Settings/Localization/LocalizationView';
import { FleetComparisonView } from 'views/FleetComparison';
import { TagView } from 'views/Settings/Tag/TagView';
import { InclineRoadCondition } from 'views/DriverBehavior/Inclinometer';
import { UsageDash } from 'views/FuelAnalytics/UsageDashboard';
import { MaintenanceEntityManagementView } from 'views/Settings/ExternalUsers/Squares/Maintenance/MaintenanceEntityManagementView';
import { WorkOrdersView } from 'views/Settings/ExternalUsers/Squares/WorkOrders/WorkOrdersView';
import { UploadWorkOrdersView } from 'views/Settings/ExternalUsers/Squares/UploadWorkOrders/UploadWorkOrdersView';

import { HistoricalAnalysis } from 'views/FuelAnalytics/HistoricalAnalysis';
import { clearNotification } from 'store/notification.slice';
import ProductionOld from 'views/FuelAnalytics/Production/Production';
import Production from 'views/Production';
import Utilization from 'views/Utilization';

import VehicleInsight from 'views/TirePressure/VehicleInsight';
import FleetInsight from 'views/TirePressure/FleetInsight';

import { OverviewView } from 'views/SurvivalAnalysis/Overview/OverviewView';
import Productivity from 'views/Reports/Productivity';
import { DashbaordEntityManagementViewV2 } from 'views/Settings/ExternalUsers/Squares/DashboardV2/DashboardEntityManagementViewV2';
import { CostAnalysisView } from 'views/Settings/ExternalUsers/Squares/CostAnalysis/CostAnalysisView';
import RoadConditionSummary from 'views/RoadConditionSummary';
import {
  hiddenUploadWorkOrderCustomers,
  roadConditionEnabledCustomers,
} from 'utils/helpers/general';
import FuelHistoryAnalytics from 'views/FuelHistoricalAnalysis';
import DriverBehavior from 'views/DriverBehavior/DriverBehavior';
import HealthIndex from 'views/VehicleStatus';
import EngineHoursTabView from 'views/EngineHours/EngineHoursTabView';
import NetworkHealth from 'views/NetworkHealth/NetworkHealth';
import FuelUsageOverview from 'views/FuelUsage';
import MlInsights from 'views/MlInsights';
import MlInsightDetails from 'views/MlInsights/Details';

type TProtectedRouteList = Array<{
  path: string;
  displayName: string;
  iconName?: string;
  Component: JSX.Element;
  roles: Role[];
  type?: PermissionConstraint;
}>;

type THiddenProtectedRouteList = Array<{
  path: string;
  displayName?: string;
  iconName?: string;
  Component: JSX.Element;
  roles: Role[];
  type?: PermissionConstraint;
}>;

const filterRoutes = (code: string): TProtectedRouteList => {
  const ROUTES: TProtectedRouteList = [
    {
      ...navigationMap.sensorAnalysis,
      Component: <SensorAnalysisView2 />,
    },
    {
      ...navigationMap.notifications,
      Component: <NotificationsView />,
    },
    // {
    //   ...navigationMap.insights,
    //   Component: <MlInsights />,
    // },
  ];

  if (roadConditionEnabledCustomers.includes(code)) {
    ROUTES.push({
      ...navigationMap.roadConditionSummary,
      Component: <RoadConditionSummary />,
    });
  }

  return ROUTES;
};

const REPORTS: THiddenProtectedRouteList = [
  {
    ...navigationMap.reports,
    Component: <Productivity />,
  },
];

const FLEET_OVERVIEW_ROUTES: TProtectedRouteList = [
  {
    ...navigationMap.fleetOverview.fleetSummary,
    Component: <FleetOverview />,
  },
  {
    ...navigationMap.fleetOverview.engineHoursSummary,
    Component: <EngineHoursTabView />,
  },
  {
    ...navigationMap.fleetOverview.dtcSummary,
    Component: <DTCView />,
  },
  {
    ...navigationMap.fleetOverview.vehicleStatus,
    Component: <HealthIndex />,
  },
];

const FUEL_USAGE_ROUTES: TProtectedRouteList = [
  {
    ...navigationMap.fuelAnalytics.overview,
    Component: <FuelUsageOverview />,
  },
];

const UTILIZATION_ROUTES: TProtectedRouteList = [
  {
    ...navigationMap.utilization.overview,
    Component: <Utilization />,
  },
  {
    ...navigationMap.utilization.idlingAnalysis,
    Component: <UsageDash />,
  },
];

const DRIVER_BEHAVIOUR_ROUTES: TProtectedRouteList = [
  {
    ...navigationMap.driverBehavior.overview,
    Component: <DriverBehavior />,
  },

  {
    ...navigationMap.driverBehavior.inclineRoadCondition,
    Component: <InclineRoadCondition />,
  },
];

const NETWORK_HEALTH_ROUTES: TProtectedRouteList = [
  {
    ...navigationMap.networkHealth,
    Component: <NetworkHealth />,
  },
];

const PRODUCTION_ROUTES: TProtectedRouteList = [
  {
    ...navigationMap.production.overview,
    Component: <Production />,
  },
  {
    ...navigationMap.production.loadCycleAnalysis,
    Component: <ProductionOld />,
  },
];

const TYPRE_PRESSURE_ROUTES: TProtectedRouteList = [
  {
    ...navigationMap.tirePressure.fleet,
    Component: <FleetInsight />,
  },
  {
    ...navigationMap.tirePressure.vahicle,
    Component: <VehicleInsight />,
  },
];

/*
  Settings routes are nested under the settings route
  By compartementalizing the routes, we can easily add new routes
  and have them automatically show up in the sidebar as nested
  under the "settings" list item
*/
const SETTING_ROUTES: TProtectedRouteList = [
  {
    ...navigationMap.settings.internalUsers,
    Component: <InternalUsersView />,
  },
  {
    ...navigationMap.settings.externalUsers,
    Component: <EntityManagementView />,
  },
  {
    ...navigationMap.settings.customers,
    Component: <CustomersView />,
  },
  {
    ...navigationMap.settings.contactInformation,
    Component: <EditCustomerContactView />,
  },
  {
    ...navigationMap.settings.rules,
    Component: <NotificationView />,
  },
  // {
  //   ...navigationMap.settings.localization,
  //   Component: <LocalizationView />,
  // },
  {
    ...navigationMap.settings.tags,
    Component: <TagView />,
  },
];

const EXTRAS_ROUTES: TProtectedRouteList = [
  {
    path: '/compare-assets',
    displayName: 'Compare Assets',
    iconName: 'sidebarFleetOverview',
    roles: [],
    Component: <FleetComparisonView />,
  },
  {
    path: '/insights/details',
    displayName: 'Insight Details',
    iconName: 'sidebarFleetOverview',
    roles: [],
    Component: <MlInsightDetails />,
  },
];

/*
  If in development mode, add the development route to the list of routes
  View is hidden from the sidebar
*/

const MainRouter = (): JSX.Element => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    customer: { code },
  } = useAppSelector((state) => state.authReducer);

  const ROUTES = filterRoutes(code);

  const [squaresRoutes, setSquaresRoutes] = useState<TProtectedRouteList>([
    {
      ...navigationMap.squares.dashboard,
      Component: <DashbaordEntityManagementViewV2 />,
    },
    {
      ...navigationMap.squares.costAnalysis,
      Component: <CostAnalysisView />,
    },
    {
      ...navigationMap.squares.maintenance,
      Component: <MaintenanceEntityManagementView />,
    },
    {
      ...navigationMap.squares.workOrders,
      Component: <WorkOrdersView />,
    },
    {
      ...navigationMap.squares.survivalAnalysis,
      Component: <OverviewView />,
    },
    // {
    //   ...navigationMap.squares.ReportsView,
    //   Component: <ReportsView />,
    // },
  ]);

  const ALL_ROUTES = [
    ...FLEET_OVERVIEW_ROUTES,
    ...ROUTES,
    ...FUEL_USAGE_ROUTES,
    ...SETTING_ROUTES,
    ...UTILIZATION_ROUTES,
    ...DRIVER_BEHAVIOUR_ROUTES,
    ...NETWORK_HEALTH_ROUTES,
    ...PRODUCTION_ROUTES,
    ...TYPRE_PRESSURE_ROUTES,
    ...REPORTS,
    ...squaresRoutes,
    ...EXTRAS_ROUTES,
  ];

  if (navigationMap.development) {
    ALL_ROUTES.push({
      ...navigationMap.development,
      Component: <PlayGround />,
    });
  }

  useEffect(() => {
    if (!hiddenUploadWorkOrderCustomers.includes(code)) {
      setSquaresRoutes((prev) => {
        const alreadyExists = prev.find(
          (item) => item.path === '/squares/uploadWorkOrders'
        );
        if (alreadyExists) {
          return prev;
        } else {
          const temp = [...prev];
          temp.push({
            ...navigationMap.squares.uploadWorkOrders,
            Component: <UploadWorkOrdersView />,
          });
          return temp;
        }
      });
    } else {
      setSquaresRoutes((prev) =>
        prev.filter((item) => item.path !== '/squares/uploadWorkOrders')
      );
    }
  }, [code]);

  useEffect(() => {
    const delay = 5000;

    const timeoutId = setTimeout(() => {
      dispatch(clearNotification());
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [location]);

  return (
    <Routes>
      <Route path={'/'} element={<LoginView />} />
      <Route
        path={navigationMap.customerSelectionView.path}
        element={
          <Permission
            roles={navigationMap.customerSelectionView.roles}
            fallbackComponent={<NoAccess />}
          >
            <CustomerSelectionView />
          </Permission>
        }
      />
      <Route
        element={
          <SideBar
            routes={ROUTES}
            productionRoutes={PRODUCTION_ROUTES}
            tireRoutes={TYPRE_PRESSURE_ROUTES}
            utilizationRoutes={UTILIZATION_ROUTES}
            driverBehaviourRoutes={DRIVER_BEHAVIOUR_ROUTES}
            fuelUsage={FUEL_USAGE_ROUTES}
            settingRoutes={SETTING_ROUTES}
            squaresRoutes={squaresRoutes}
            fleetOverViewRoutes={FLEET_OVERVIEW_ROUTES}
            networkHealthRoutes={NETWORK_HEALTH_ROUTES}
          />
        }
      >
        {ALL_ROUTES.map(({ path, roles, type, Component }): JSX.Element => {
          return (
            <Route
              path={path}
              key={path}
              element={
                <Permission
                  roles={roles}
                  type={type}
                  fallbackComponent={<NoAccess />}
                >
                  {Component}
                </Permission>
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
};

export default MainRouter;
