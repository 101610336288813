/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { ThemePalette } from 'mui.theme';
import { Box } from '@mui/material';
import { SVG } from 'components/Asset/SVG';
import InfoPanel from './InfoPanel';
import LineAndCircle from './LineAndCircle';
import { useAppSelector } from 'store/hook';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';
import { tirePressureCustomers, truncateTo2dp } from 'utils/helpers/general';
import { includedLargeSVgs } from './utils';
import { tireDataCalc } from 'views/TirePressure/Components/Content/Components/VehicleTypes/util';
import { type Asset } from 'types/asset.types';
import { useNavigate } from 'react-router-dom';
import { airFilterColors } from 'views/FuelHistoricalAnalysis/utils';
import { type RuleNotifications } from 'types/notification.types';
import {
  getFuelUsageDiff,
  getNotificationIndex,
} from 'views/FleetOverview/components/AssetCardsV2/utils';

const MainBody = ({
  enableDarkTheme,
  healthData,
  unitSystem,
  makeModel,
  asset,
  notifications,
}: {
  enableDarkTheme: boolean;
  healthData: any;
  unitSystem: string;
  makeModel: string;
  asset: Asset;
  notifications: RuleNotifications[];
}) => {
  const {
    customer: { code },
  } = useAppSelector((state) => state.authReducer);
  const { tireData, limits } = useAppSelector(
    (state) => state.tireProfileReducer
  );

  const dtcData = useAppSelector((state) => state.dtcReducer);

  const dtc = Array.isArray(dtcData.data)
    ? dtcData?.data?.filter(
        (item: any) => item.is_active && item.device === asset.device.deviceId
      )
    : [];
  const navigate = useNavigate();

  const totalFuelConsumed = `${
    asset.fuelUsage?.fuel_consumption_l !== undefined &&
    asset.fuelUsage?.fuel_consumption_l !== 0
      ? asset.fuelUsage?.fuel_consumption_l
      : '-'
  } ${getLabelWrtUnitSystem('L', unitSystem)}`;

  const assetTireData = tireData[asset?.device.deviceId];

  const currentLimit =
    limits.length > 0
      ? (limits as any)?.customerLimits[asset?.assetType] ??
        (limits as any)?.globalLimits[asset?.assetType]
      : null;
  const pressureData = assetTireData
    ? tireDataCalc(assetTireData, 'Pressure', currentLimit, unitSystem)
    : null;

  const tempData = assetTireData
    ? tireDataCalc(assetTireData, 'Temperature', currentLimit, unitSystem)
    : null;

  const brakingVal = healthData?.braking?.find(
    (item: any) => item.device_id === asset.device.deviceId
  );
  const overspeedVal = healthData?.overspeed?.find(
    (item: any) => item.device_id === asset.device.deviceId
  );
  const accelerationVal = healthData?.acceleration?.find(
    (item: any) => item.device_id === asset.device.deviceId
  );
  const gearSkipVal = healthData?.gearSkip?.find(
    (item: any) => item.device_id === asset.device.deviceId
  );
  const idleFuelVal = healthData?.idleFuel.data?.find(
    (item: any) => item.device === asset.device.deviceId
  )?.total_fuel_consumption;
  const underspeedVal = healthData?.underspeed?.find(
    (item: any) => item.device_id === asset.device.deviceId
  );
  const incorrectGearToSpeedVal = healthData?.incorrectGearToSpeed?.find(
    (item: any) => item.device_id === asset.device.deviceId
  );
  const incorrectGearDuringDirectionVal =
    healthData?.incorrectGearDuringDirection?.find(
      (item: any) => item.device_id === asset.device.deviceId
    );
  const airFilterVal = healthData?.airFilters.some(
    (item: any) => item.filter_health === 'eol'
  )
    ? { status: 'EOL', color: airFilterColors[3] }
    : healthData?.airFilters.some(
        (item: any) => item.filter_health === 'approaching eol'
      )
    ? { status: 'APPROACHING EOL', color: airFilterColors[2] }
    : healthData?.airFilters.some(
        (item: any) => item.filter_health === 'unknown'
      )
    ? { status: 'UNKNOWN', color: airFilterColors[1] }
    : { status: 'OK', color: airFilterColors[1] };
  const notificationIndex = getNotificationIndex(notifications);

  const assetDiff = getFuelUsageDiff(
    asset.fuelUsage,
    asset.utilization,
    asset.productivity,
    'day'
  );

  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        marginTop: '20px',
        borderRadius: '5px',
      }}
    >
      {/* Truck SVG */}
      <SVG
        name={
          includedLargeSVgs.includes(makeModel)
            ? `${makeModel}ImageLarge`
            : `cat810eImageLarge`
        }
        style={{
          width: '70%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />

      {/* lines and pulsating circles */}
      <svg
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          pointerEvents: 'none',
        }}
      >
        {/* notifications */}
        <LineAndCircle
          x1={'32%'}
          y1={'50%'}
          x2={'10%'}
          y2={'20%'}
          r1={'10'}
          r2={'20'}
        />

        {/* tire system */}
        <LineAndCircle
          x1={'48%'}
          y1={'60%'}
          x2={'15%'}
          y2={'90%'}
          r1={'10'}
          r2={'20'}
        />

        {/* Driver Behaviour */}
        <LineAndCircle
          x1={'44%'}
          y1={'42%'}
          x2={'85%'}
          y2={'20%'}
          r1={'10'}
          r2={'20'}
        />

        {/* fuel system */}
        <LineAndCircle
          x1={'57.5%'}
          y1={'57%'}
          x2={'95%'}
          y2={'85%'}
          r1={'10'}
          r2={'20'}
        />
      </svg>

      {/* Driver Behaviour */}
      <InfoPanel
        enableDarkTheme={enableDarkTheme}
        header="Driver Behaviour"
        items={[
          {
            name: 'Overspeeding',
            desc: `${overspeedVal?.noOfEvents ?? ''}`,
            status: !overspeedVal
              ? 'OK'
              : overspeedVal?.noOfEvents < 1
              ? 'OK'
              : 'WARNING',
            handleClick: () => {
              navigate(`/driver-behavior`, {
                state: {
                  asset: asset.bumperNumber,
                  tabIndex: 1,
                },
              });
            },
            toolTipDesc:
              !overspeedVal || overspeedVal?.noOfEvents < 1
                ? 'No Overspeeding detected'
                : 'Click to redirect to Driver Behaviour',
          },
          {
            name: 'Underspeeding',
            desc: `${underspeedVal?.noOfEvents ?? ''}`,
            status: !underspeedVal
              ? 'OK'
              : underspeedVal?.noOfEvents < 1
              ? 'OK'
              : 'WARNING',
            handleClick: () => {
              navigate(`/driver-behavior`, {
                state: {
                  asset: asset.bumperNumber,
                  tabIndex: 1,
                },
              });
            },
            toolTipDesc:
              !overspeedVal || overspeedVal?.noOfEvents < 1
                ? 'No Underspeeding detected'
                : 'Click to redirect to Driver Behaviour',
          },
          {
            name: 'Incorrect Gear to speed ratio',
            desc: `${incorrectGearToSpeedVal?.noOfEvents ?? ''}`,
            status: !incorrectGearToSpeedVal
              ? 'OK'
              : incorrectGearToSpeedVal?.noOfEvents < 1
              ? 'OK'
              : 'WARNING',
            handleClick: () => {
              navigate(`/driver-behavior`, {
                state: {
                  asset: asset.bumperNumber,
                  tabIndex: 1,
                },
              });
            },
            toolTipDesc:
              !incorrectGearToSpeedVal || overspeedVal?.noOfEvents < 1
                ? 'No Incorrect Gear to speed ratio detected'
                : 'Click to redirect to Driver Behaviour',
          },
          {
            name: 'Hard Braking',
            desc: `${brakingVal?.noOfEvents ?? ''}`,
            status: !brakingVal
              ? 'OK'
              : brakingVal?.noOfEvents < 1
              ? 'OK'
              : 'WARNING',
            handleClick: () => {
              navigate(`/driver-behavior`, {
                state: {
                  asset: asset.bumperNumber,
                  tabIndex: 1,
                },
              });
            },
            toolTipDesc:
              !brakingVal || brakingVal?.noOfEvents < 1
                ? 'No Hard Braking detected'
                : 'Click to redirect to Driver Behaviour',
          },
          {
            name: 'Hard Acceleration',
            desc: `${accelerationVal?.noOfEvents ?? ''}`,
            status: !accelerationVal
              ? 'OK'
              : accelerationVal?.noOfEvents < 1
              ? 'OK'
              : 'WARNING',
            handleClick: () => {
              navigate(`/driver-behavior`, {
                state: {
                  asset: asset.bumperNumber,
                  tabIndex: 1,
                },
              });
            },
            toolTipDesc:
              !accelerationVal || accelerationVal?.noOfEvents < 1
                ? 'No Hard Acceleration detected'
                : 'Click to redirect to Driver Behaviour',
          },
          {
            name: 'Incorrect Gear during direction switch',
            desc: `${incorrectGearDuringDirectionVal?.noOfEvents ?? ''}`,
            status: !incorrectGearDuringDirectionVal
              ? 'OK'
              : incorrectGearDuringDirectionVal?.noOfEvents < 1
              ? 'OK'
              : 'WARNING',
            handleClick: () => {
              navigate(`/driver-behavior`, {
                state: {
                  asset: asset.bumperNumber,
                  tabIndex: 1,
                },
              });
            },
            toolTipDesc:
              !incorrectGearDuringDirectionVal ||
              incorrectGearDuringDirectionVal?.noOfEvents < 1
                ? 'No Incorrect Gear during direction switch detected'
                : 'Click to redirect to Driver Behaviour',
          },
          {
            name: 'Gear Skip',
            desc: `${gearSkipVal?.noOfEvents ?? ''}`,
            status: !gearSkipVal
              ? 'OK'
              : gearSkipVal?.noOfEvents < 1
              ? 'OK'
              : 'WARNING',
            handleClick: () => {
              navigate(`/driver-behavior`, {
                state: {
                  asset: asset.bumperNumber,
                  tabIndex: 1,
                },
              });
            },
            toolTipDesc:
              !gearSkipVal || gearSkipVal?.noOfEvents < 1
                ? 'No Gear Skip detected'
                : 'Click to redirect to Driver Behaviour',
          },
        ]}
        sx={{
          position: 'absolute',
          top: '20%',
          right: '5%',
          transform: 'translate(0, -50%)',
        }}
      />

      {/* Tire System */}
      <InfoPanel
        enableDarkTheme={enableDarkTheme}
        header="Tire System"
        items={[
          {
            name: 'TPMS Pressure',
            desc: '',
            status: !pressureData
              ? 'NO DATA'
              : pressureData.isP1WarninigMax ||
                pressureData.isP2WarninigMax ||
                pressureData.isP3WarninigMax ||
                pressureData.isP4WarninigMax
              ? 'ALERT'
              : 'OK',
            handleClick: () => {
              navigate(
                `/tire-analytics/vehicle-insight?asset=${asset.bumperNumber}`,
                {
                  state: {
                    device: asset.bumperNumber,
                    navType: 'Pressure',
                  },
                }
              );
            },
            toolTipDesc:
              !pressureData ||
              !pressureData?.isP1WarninigMax ||
              !pressureData?.isP2WarninigMax ||
              !pressureData?.isP3WarninigMax ||
              !pressureData?.isP4WarninigMax
                ? 'No abnormal TPMS Pressure'
                : 'Click to redirect to Tire Analytics Pressure',
          },
          {
            name: 'TPMS Temp',
            desc: '',
            status: !tempData
              ? 'NO DATA'
              : tempData.isP1WarninigMax ||
                tempData.isP2WarninigMax ||
                tempData.isP3WarninigMax ||
                tempData.isP4WarninigMax
              ? 'ALERT'
              : 'OK',
            handleClick: () => {
              navigate(
                `/tire-analytics/vehicle-insight?asset=${asset.bumperNumber}`,
                {
                  state: {
                    device: asset.bumperNumber,
                    navType: 'Temperature',
                  },
                }
              );
            },
            toolTipDesc:
              !tempData ||
              !tempData?.isP1WarninigMax ||
              !tempData?.isP2WarninigMax ||
              !tempData?.isP3WarninigMax ||
              !tempData?.isP4WarninigMax
                ? 'No abnormal TPMS Temperature'
                : 'Click to redirect to Tire Analytics Temperature',
          },
        ]}
        sx={{
          position: 'absolute',
          bottom: '10%',
          left: '5%',
        }}
        // disabled={!assetTireData}
        // disabledText={!assetTireData ? 'TPMS not available for client' : ''}
        disabled={!tirePressureCustomers.includes(code)}
        disabledText={
          !tirePressureCustomers.includes(code)
            ? 'TPMS not available for client'
            : ''
        }
      />

      {/* Notifications */}
      <InfoPanel
        enableDarkTheme={enableDarkTheme}
        header="Notifications"
        items={[
          {
            name: 'Air Filter',
            desc: ``,
            status: `${airFilterVal.color.key}`,
            statusText: `${airFilterVal.status}`,
            handleClick: () => {
              if (airFilterVal.status !== 'OK' && asset.fuelUsage?.date) {
                // navigate(`/fuel-usage/historical-analysis`, {
                //   state: {
                //     device: asset.bumperNumber,
                //     date: asset.fuelUsage.date,
                //   },
                // });
              }
            },
            toolTipDesc: '',
            //   airFilterVal.status === 'OK'
            //     ? 'No Air Filter Alerts'
            //     : 'Click to redirect to Air Filter',
          },
          {
            name: 'DTC',
            desc: `${dtc.length && dtc.length > 0 ? dtc.length : ''}`,
            status: `${dtc.length < 1 ? 'OK' : 'WARNING'}`,
            handleClick: () => {
              if (dtc.length > 0) {
                const dataToPass = {
                  bumperNumber: asset.bumperNumber,
                };
                navigate(`/diagnostic-tool-summary`, {
                  state: {
                    data: dataToPass,
                  },
                });
              }
            },
            toolTipDesc:
              dtc.length < 1 ? 'No DTC alerts' : 'Click to redirect to DTC',
          },
          {
            name: 'Rule Based Alerts',
            desc: `${
              notifications.length && notifications.length > 0
                ? notifications.length
                : ''
            }`,
            status: `${notificationIndex.color}`,
            statusText: `${
              notificationIndex.message === 'No Major Alerts'
                ? 'OK'
                : notificationIndex.message
            }`,
            handleClick: () => {
              if (notifications.length > 0) {
                const dataToPass = {
                  bumperNumber: asset.bumperNumber,
                };
                navigate('/notifications', {
                  state: {
                    data: dataToPass,
                  },
                });
              }
            },
            toolTipDesc:
              notifications.length < 1
                ? 'No Rule Based Alerts'
                : 'Click to redirect to Rule Based Notifications',
          },
        ]}
        sx={{
          position: 'absolute',
          top: '17%',
          left: '5%',
          transform: 'translate(0, -50%)',
        }}
      />

      {/* Fuel System */}
      <InfoPanel
        enableDarkTheme={enableDarkTheme}
        header="Fuel System"
        items={[
          {
            name: 'Total Fuel Used',
            desc: totalFuelConsumed,
            status: !assetDiff?.fuelUsage
              ? 'NO DATA'
              : assetDiff.fuelUsage < 0
              ? 'ALERT'
              : 'OK',
            handleClick: () => {
              // navigate(`/fuel-usage`, {
              //   state: {
              //     type: assetTypeMappings[asset.assetType],
              //     value: 0,
              //     device: asset.device.deviceId,
              //   },
              // });

              navigate(`/fuel-usage/overview`);
            },
            toolTipDesc:
              !assetDiff?.fuelUsage || assetDiff.fuelUsage > 0
                ? ''
                : 'Click to redirect to Fuel Overview',
          },
          ...(asset?.assetType === 'haul_truck'
            ? [
                {
                  name: 'Idling Fuel Consumption',
                  desc: `${
                    idleFuelVal && idleFuelVal !== 0
                      ? truncateTo2dp(idleFuelVal)
                      : '-'
                  } ${getLabelWrtUnitSystem('L', unitSystem)}`,
                  status:
                    !idleFuelVal || idleFuelVal === 0
                      ? 'NO DATA'
                      : !asset.fuelUsage?.dashboard_color ||
                        asset.fuelUsage?.dashboard_color === 'green'
                      ? 'OK'
                      : 'WARNING',
                  handleClick: () => {
                    navigate(`/fuel-usage/overview`);
                  },
                  toolTipDesc:
                    !idleFuelVal ||
                    idleFuelVal === 0 ||
                    !asset.fuelUsage?.dashboard_color ||
                    asset.fuelUsage?.dashboard_color === 'green'
                      ? ''
                      : 'Click to redirect to Fuel Overview',
                },
              ]
            : []),
        ]}
        sx={{
          position: 'absolute',
          bottom: '10%',
          right: '5%',
        }}
      />
    </Box>
  );
};

export default MainBody;
