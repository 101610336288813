import { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import { useAppSelector } from 'store/hook';
import { DateFilter } from './DateFilter';
import { MaintenanceCostTrend } from './MaintenanceCostTrend';
import { FleetProduction } from './FleetProduction';
import { MaintenanceStats } from './Stats';

export const MaintenanceExpenditure = ({
  enableDarkTheme,
  switchToTab,
}: {
  enableDarkTheme: boolean;
  switchToTab: any;
}) => {
  const squaresHandler = new SquaresDashboardHandler();
  const { data, isLoading } = useAppSelector(
    (state) => state.squaresPartsMaintenanceExpenditureReducer
  );
  const initialStartDate = new Date();
  initialStartDate.setFullYear(initialStartDate.getFullYear() - 1);
  const initialEndDate = new Date();

  const [selectedStartDate, setStartDate] = useState(initialStartDate);
  const [selectedEndDate, setEndDate] = useState(initialEndDate);
  const [isSelected, setIsSelected] = useState('1Y');

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      sessionStorage.setItem(
        'datePickerSelection',
        JSON.stringify({
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        })
      );

      void squaresHandler.getPartsMaintenanceExpenditure(
        selectedStartDate,
        selectedEndDate
      );
    }
  }, [selectedStartDate, selectedEndDate]);

  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.pageBackground
          : ThemePalette.light.pageBackground,
        padding: '10px',
      }}
    >
      {isLoading ? (
        <SingleFadeLoader />
      ) : (
        <>
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={6} sm={12} md={12} xs={12}>
                <MaintenanceCostTrend
                  enableDarkTheme={enableDarkTheme}
                  data={data.ytdData}
                />
              </Grid>
              <Grid item lg={6} sm={12} md={12} xs={12}>
                <FleetProduction
                  enableDarkTheme={enableDarkTheme}
                  data={data.ytdData}
                />
              </Grid>
            </Grid>
          </Box>
          <DateFilter
            enableDarkTheme={enableDarkTheme}
            selectedStartDate={selectedStartDate}
            handleDateChange={handleDateChange}
            selectedEndDate={selectedEndDate}
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            isLoading={isLoading}
          />
          <MaintenanceStats
            enableDarkTheme={enableDarkTheme}
            switchToTab={switchToTab}
            data={data.currentData}
          />
        </>
      )}
    </Box>
  );
};
