import React from 'react';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import { ThemePalette } from 'views/SurvivalAnalysis/Helper/Icons/theme.squares';
import useAppTheme from 'hooks/useAppTheme';

// Define the shape of value items
export interface ValueItem {
  header: any;
  value: any;
}

// Extend the component's props to accept an onClick handler
interface AssetValueCardProps {
  title: string;
  values: ValueItem[];
  onClick?: () => void; // Add optional onClick prop
  totalType?: string;
}

const KPICard: React.FC<AssetValueCardProps> = ({
  title,
  values,
  onClick, // Destructure the onClick prop
  totalType = '',
}) => {
  const { enableDarkTheme } = useAppTheme();
  return (
    <Card
      onClick={onClick} // Apply the onClick event to the Card
      sx={{
        mb: 2,
        cursor: onClick ? 'pointer' : 'default', // Change cursor if onClick is provided
        background: enableDarkTheme
          ? ThemePalette.dark.tableBackground
          : ThemePalette.light.tableBackground,
      }}
    >
      <CardContent>
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          variant="body2"
          fontSize={12}
        >
          {title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent:
              values.length > 1 ? 'space-evenly' : 'space-between',
            gap: 1,
          }}
        >
          {values.map((item, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '10px',
                    fontWeight: 'bold',
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.grey,
                  }}
                >
                  {item.header}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {item.value} {totalType}
                </Typography>
              </Box>

              {index < values.length - 1 && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    height: '20px',
                    alignSelf: 'center',
                    borderColor: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.grey,
                  }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default KPICard;
