import { truncateTo2dp } from './general';

export function formatNumberOrString(
  input: string | number | undefined
): string | number | undefined {
  if (input === undefined) {
    return undefined;
  }

  let parsedValue: number;

  if (typeof input === 'number') {
    // If the input is already a number, return it
    return input;
  } else if (typeof input === 'string') {
    parsedValue = parseFloat(input);

    if (isNaN(parsedValue)) {
      // If parsing as a number fails, return the original input
      return input;
    }
  } else {
    // If the input is neither a string nor a number, return the original input
    return input;
  }

  // Check if the parsedValue has more than 2 decimal places
  const decimalPlaces = (parsedValue.toString().split('.')[1] || []).length;

  // If the number has more than 2 decimal places, truncate to 2 decimal places
  const formattedValue =
    decimalPlaces > 2 ? truncateTo2dp(parsedValue) : parsedValue;

  return formattedValue;
}
