import { type SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Typography, Box, Autocomplete } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { Selector } from 'components/Form/Selectors/Selector';
import { modalInputFieldStyle } from 'styles/global.css';
import type { Asset } from 'types/asset.types';
import type { SelectOptions } from 'types/utils.types';
import type { ICreateRulePayload } from 'types/payloads/rule.payload.types';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { createRuleGeneralInfo } from 'utils/schemas/rule.schema';
import { RuleModalHeader } from './RuleModalHeader';
import {
  handleSelectAssetAll,
  handleSelectAssetSingle,
  handleSelectSingle,
  handleSelectAll,
  getInitialAssetTypes,
  getAssetMakeOptions,
  getAssetModelOptions,
  getAssetListOptions,
  getCurrentRuleMakeModel,
  handleSelectSingleModel,
} from 'views/Settings/Rules/helpers/common';
import { setIsClonedStatus, updateNewRule } from 'store/rule.slice';
import { ThemePalette } from 'mui.theme';
import { type SelectedRule } from 'types/notification.types';

interface Props {
  open: boolean;
  handleAbort: () => void;
  handleConfirm: () => void;
  title: string;
  assets: Asset[];
  newRule: ICreateRulePayload;
  confirmCancellation?: boolean;
  enableDarkTheme: boolean;
}

/**
 *
 * The createRuleGeneralInfo modal is a modal
 * that is used to create a rule general info.
 */
export const CreateRuleGeneralInfoModal = ({
  open,
  handleAbort,
  handleConfirm,
  title,
  assets,
  newRule,
  confirmCancellation = false,
  enableDarkTheme,
}: Props) => {
  const { typography } = useTheme();
  const dispatch = useAppDispatch();
  const { rules } = useAppSelector((state) => state.ruleReducer);
  const [assetSelectOptions, setAssetSelectOptions] = useState<SelectOptions[]>(
    []
  );

  const [assetTypeOptions] = useState<SelectOptions[]>(
    getInitialAssetTypes(assets)
  );
  const [assetMakeOptions, setAssetMakeOptions] = useState<SelectOptions[]>([]);
  const [assetModelOptions, setAssetModelOptions] = useState<SelectOptions[]>(
    []
  );

  const [currentRule, setCurrentRule] = useState<ICreateRulePayload>(newRule);
  const [isRuleNameError, setIsRuleNameError] = useState({
    length: false,
    duplicate: false,
  });
  const [isValidForm, setFormValidity] = useState(false);
  const [assetFilter, setAssetFilter] = useState<{
    assetType: string;
    assetMake: SelectOptions[];
    assetModel: SelectOptions[];
  }>({
    assetType: '',
    assetMake: [],
    assetModel: [],
  });

  // const { make, model } = getCurrentRuleMakeModel(currentRule.assets, assets);
  const { make, model } = useMemo(
    () => getCurrentRuleMakeModel(currentRule.assets, assets),
    [currentRule.assets, assets]
  );

  const { assetType, assetMake, assetModel } = assetFilter;
  // const [modelChanged, setModelChanged] = useState(model.length);
  const savedRules = rules
    .filter((rule) => !rule.isDeleted) // Filter out the deleted rules
    .map((rule) => ({ ...rule }));
  const [selectedRule, setSelectedRule] = useState<SelectedRule>();

  const assetDetails = (): Asset | undefined => {
    const selectedAsset = selectedRule?.deviceIds ?? [];
    if (selectedAsset?.length > 0 || newRule.assets.length > 0) {
      return assets.find(
        (asset) =>
          asset.device.deviceId === (selectedAsset[0] || newRule.assets[0].id)
      );
    }
  };
  const selectedAssetDetailType = (value: any, param: keyof Asset) => {
    if (value) {
      return value.toString();
    } else {
      const details = assetDetails();
      if (details && param) {
        return details[param].toString();
      }
    }
  };

  /**
   * This UseEffect Updates the asset make option.
   * Determined by the assetType Selection
   */
  useEffect(() => {
    const makeOptions = getAssetMakeOptions(
      assets,
      selectedAssetDetailType(assetType, 'assetType')
    );
    const modelOptions = getAssetModelOptions(
      assets,
      selectedAssetDetailType(assetType, 'assetType'),
      makeOptions
    );
    setAssetMakeOptions(makeOptions);
    const initialSelectedItems = make && make.length > 0 ? make : makeOptions;
    setAssetFilter((prevFilter) => ({
      ...prevFilter,
      assetMake: initialSelectedItems,
      assetModel: modelOptions,
    }));
  }, [assetType, selectedRule]);

  /**
   * This UseEffect Updates the asset model option.
   * Determined by the assetType and assetMake Selections
   */

  useEffect(() => {
    if (assetMake.length < 1) {
      setAssetModelOptions([]);
    } else {
      const modelOptions = getAssetModelOptions(
        assets,
        selectedAssetDetailType(assetType, 'assetType'),
        assetMake
      );
      setAssetModelOptions(modelOptions);
      const initialSelectedItems =
        model && model.length > 0 ? model : modelOptions;

      setAssetFilter((prevFilter) => ({
        ...prevFilter,
        assetModel: initialSelectedItems,
      }));
    }
  }, [assetMake]);

  /**
   * This UseEffect Updates the asset list option.
   * Determined by the assetModel Selections
   */

  useEffect(() => {
    if (assetModel.length < 1) {
      setAssetSelectOptions([]);
    } else {
      const listOptions = getAssetListOptions(
        assets,
        selectedAssetDetailType(assetType, 'assetType'),
        assetModel
      );
      setAssetSelectOptions(listOptions);
    }
  }, [assetModel, model]);

  /**
   * This UseEffect Validates the form as user fills out fields
   */
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(currentRule, createRuleGeneralInfo);

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [currentRule]);

  /**
   * This UseEffect Updates the asset selection optioins.
   * Determined by the asset Filter attributes
   */
  useEffect(() => {
    if (assetMake.length < 1 || assetModel.length < 1) {
      setAssetSelectOptions([]);
    } else {
      const mappedAsset: SelectOptions[] = [];
      let filteredAssets = assets.filter(
        (asset) => asset.assetType === assetType
      );

      if (assetMake.length) {
        const assetMakeList = assetMake.map((assetMake) => {
          return assetMake.id;
        });
        filteredAssets = filteredAssets.filter((asset) =>
          assetMakeList.includes(asset.make)
        );
      }

      if (assetModel.length) {
        const assetModelList = assetModel.map((assetModel) => {
          return assetModel.id;
        });
        filteredAssets = filteredAssets.filter((asset) =>
          assetModelList.includes(asset.model)
        );
      }
      /**
       * Using the asset device Id as the id here since
       * we are saving the deviceID as the device list in the DB.
       */
      filteredAssets.forEach((asset) => {
        mappedAsset.push({
          id: asset.device.deviceId,
          display: asset.bumperNumber,
        });
      });

      setCurrentRule({
        ...currentRule,
        assets: mappedAsset.length > 0 ? [...mappedAsset] : currentRule.assets,
      });
    }
  }, [assets, assetFilter]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setIsRuleNameError({ duplicate: false, length: false });
    value.length > 40
      ? setIsRuleNameError({ ...isRuleNameError, length: true })
      : setIsRuleNameError({ duplicate: false, length: false });
    setCurrentRule({
      ...currentRule,
      [name]: value,
    });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    value.length > 40
      ? setIsRuleNameError({ ...isRuleNameError, length: true })
      : setIsRuleNameError({ duplicate: false, length: false });
    if (rules.some((rule) => rule.ruleName === value)) {
      setIsRuleNameError({ ...isRuleNameError, duplicate: true });
    }
  };

  const handleSelect = async (
    event: SyntheticEvent<Element, Event>,
    value: string | SelectedRule | null
  ) => {
    if (value && typeof value === 'object') {
      const selectedAssets =
        (value?.deviceIds ?? value?.devices)?.map((device) => {
          const bumperNo = assets.find(
            (asset) => asset.device.deviceId === device
          );
          return { id: device, display: bumperNo?.bumperNumber ?? '' };
        }) ?? [];
      const adaptedArray = value.distributionUsers.map((item: any) => {
        if (typeof item === 'object' && item !== null) {
          return {
            id: item.id,
            display: item.id,
          };
        } else {
          return {
            id: item,
            display: item,
          };
        }
      });
      const adaptedSmsArray = value.smsUsers.map((item: any) => {
        if (typeof item === 'object' && item !== null) {
          return {
            id: item.id,
            display: item.id,
          };
        } else {
          return {
            id: item,
            display: item,
          };
        }
      });
      dispatch(setIsClonedStatus(true));
      setIsRuleNameError({ duplicate: false, length: false });

      setCurrentRule((prevRule) => ({
        ...prevRule,
        ruleName: `${value.ruleName} - copy`,
        assets: selectedAssets,
        conditions: value.conditions,
        alertByEmail: value.alertByEmail,
        alertBySms: value.alertBySms,
        alertByUI: value.alertByUI,
        alertByNotificationCenter: value.alertByNotificationCenter,
        distributionUsers: adaptedArray,
        notificationLevel: value.notificationLevel,
        smsUsers: adaptedSmsArray,
      }));

      if (value.ruleId) {
        setSelectedRule(
          savedRules.find((item) => item.ruleId === value.ruleId)
        );
      }
      setAssetSelectOptions(selectedAssets);
      dispatch(
        updateNewRule({
          ruleName: `${value.ruleName} - copy`,
          assets: selectedAssets,
          conditions: value.conditions,
          alertByEmail: value.alertByEmail,
          alertBySms: value.alertBySms,
          alertByUI: value.alertByUI,
          alertByNotificationCenter: value.alertByNotificationCenter,
          distributionUsers: adaptedArray,
          notificationLevel: value.notificationLevel,
          smsUsers: adaptedSmsArray,
        })
      );
    }
  };

  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          dispatch(updateNewRule(currentRule));
          handleConfirm();
        },
        disabled:
          Object.values(isRuleNameError).some((value) => value) ?? !isValidForm,
        label: 'Next: Conditions',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      <Typography
        key="general-info-text"
        variant="medium"
        sx={{
          fontFamily: typography.fontFamily,
        }}
      >
        <RuleModalHeader
          disabledPages={[2, 3]}
          enableDarkTheme={enableDarkTheme}
        />
      </Typography>
      {/* Rule Name TextField */}
      <Autocomplete
        freeSolo
        disablePortal
        options={savedRules}
        getOptionLabel={(option) => {
          return typeof option === 'object' ? option.ruleName : option;
        }}
        value={currentRule.ruleName}
        onChange={handleSelect}
        renderInput={(params) => (
          <SWTextField
            name="ruleName"
            label="Rule Name"
            placeholder="Enter Rule Name OR Select From Existing Rule"
            TextFieldProps={{
              ...params,
              name: 'ruleName',
              value: currentRule.ruleName,
              onChange: handleChange,
              onBlur: handleBlur,
              InputProps: {
                ...params.InputProps,
                sx: {
                  width: '500px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  border: 'gray 1px solid',
                },
              },
            }}
            enableDarkTheme={enableDarkTheme}
          />
        )}
      />

      {Object.values(isRuleNameError).some((value) => value) ? (
        <Typography
          key="small-warning-text"
          variant="small"
          sx={{
            paddingTop: '0px',
            color: '#e57373',
          }}
        >
          {isRuleNameError.length
            ? 'Rule name cannot be more than 40 characters'
            : isRuleNameError.duplicate
            ? 'Another Rule currenlty uses this rule name.'
            : 'Rule name error'}
        </Typography>
      ) : (
        <Typography
          key="small-info-text"
          variant="small"
          sx={{
            paddingTop: '0px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Only underscores, spaces and dashes are permitted as special
          characters.
        </Typography>
      )}
      <Box key="assetType">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Applies to
        </Typography>

        {/* Asset Types */}
        <Selector
          inputProps={{
            sx: {
              minWidth: '355px',
            },
          }}
          fallbackValue="Select asset type"
          value={selectedAssetDetailType(assetType, 'assetType')}
          onChange={(event) => {
            const selectedAssetType = selectedAssetDetailType(
              event.target.value,
              'assetType'
            );
            setAssetFilter({
              assetType: selectedAssetType,
              assetMake: [],
              assetModel: [],
            });
            // Reset existing selected assets
            setCurrentRule({
              ...currentRule,
              assets: [],
            });
          }}
          selectorOptions={assetTypeOptions}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      <Box key="assetMake">
        {/* Asset Make List */}
        <MultiSelector
          labelText="Asset Make/OEM"
          value={assetMake}
          renderValue={(selected) => {
            return `${selected?.length || 0} OEM(s) Selected`;
          }}
          handleSelectOne={(event) => {
            // Reset existing selected assets
            setCurrentRule({
              ...currentRule,
              assets: [],
            });

            const updatedAssetFilter = {
              ...assetFilter,
              assetModel: [],
            };

            handleSelectSingle(
              event,
              updatedAssetFilter,
              setAssetFilter,
              'assetMake'
            );
          }}
          handleSelectAll={() => {
            // Reset existing selected assets
            setCurrentRule({
              ...currentRule,
              assets: [],
            });

            const updatedAssetFilter = {
              ...assetFilter,
              assetModel: [],
            };

            handleSelectAll(
              assetMakeOptions,
              updatedAssetFilter,
              setAssetFilter,
              'assetMake'
            );
          }}
          selectorOptions={assetMakeOptions}
          minWidth="400px"
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      <Box key="AssetmodelList">
        {/* Asset Model List */}
        <MultiSelector
          labelText="Asset Model"
          value={assetFilter.assetModel}
          renderValue={(selected) => {
            return `${selected?.length || 0} Model(s) Selected`;
          }}
          handleSelectOne={(event) => {
            // Reset existing selected assets
            setCurrentRule({
              ...currentRule,
              assets: [],
            });

            handleSelectSingleModel(
              event,
              assetFilter,
              setAssetFilter,
              'assetModel',
              Infinity,
              false,
              model
            );
          }}
          handleSelectAll={() => {
            // Reset existing selected assets
            setCurrentRule({
              ...currentRule,
              assets: [],
            });

            handleSelectAll(
              assetModelOptions,
              assetFilter,
              setAssetFilter,
              'assetModel'
            );
          }}
          selectorOptions={assetModelOptions}
          minWidth="400px"
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      <Box key="assetList">
        {/* Assets List */}
        <MultiSelector
          labelText="Asset List"
          value={currentRule.assets}
          renderValue={(selected) => {
            return `${selected?.length || 0} Asset(s) Selected`;
          }}
          handleSelectOne={(event) => {
            handleSelectAssetSingle(event, currentRule, setCurrentRule);
          }}
          handleSelectAll={() => {
            handleSelectAssetAll(
              assetSelectOptions,
              currentRule,
              setCurrentRule
            );
          }}
          selectorOptions={assetSelectOptions
            .slice() // Create a shallow copy of the array to avoid mutating the original
            .sort((a, b) => a.display.localeCompare(b.display))}
          minWidth="400px"
          enableDarkTheme={enableDarkTheme}
        />
      </Box>
    </InteractiveModal>
  );
};
