import { Box } from '@mui/material';
import React from 'react';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import Chart from 'react-apexcharts';
import { type ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';

interface IndividualData {
  asset_id: string;
  timestamp: number;
  work_order_number: string;
  total_cost_asset: number;
  total_labour_cost: number;
  total_part_cost: number;
}

interface SelectedAssetCostData {
  data: IndividualData[];
  name: string; // the name of the asset
}

interface AreaChartProps {
  data: SelectedAssetCostData[] | any[];
  selectedAssetsCostType: string[];
}

const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const assetColors = ['#2196f3', '#4caf50', '#9c27b0', '#ff9800', '#3f51b5'];

export const AreaChart = ({ data, selectedAssetsCostType }: AreaChartProps) => {
  const theme = useAppSelector((state) => state.authReducer.customer.theme);
  const enableDarkTheme = isDarkTheme(theme);

  const series = data.flatMap((asset, assetIndex) =>
    selectedAssetsCostType.map((costType) => {
      const baseColor = assetColors[assetIndex % assetColors.length];
      const isLabour = costType === 'total_labour_cost';
      const color = isLabour ? '#4caf50' : '#ff9800';

      return {
        name: `${asset.name} - ${costType.replace(/_/g, ' ')}`,
        data: asset.data.map((d: any) => ({
          x: dayjs.unix(d.timestamp).toDate(),
          y: d[costType as keyof IndividualData],
        })),
        color,
      };
    })
  );

  const options: ApexOptions = {
    chart: {
      type: 'area',
      // background: enableDarkTheme ? '#424242' : '#fff',
      // foreColor: enableDarkTheme ? '#fff' : '#000',
      toolbar: { show: false },
      zoom: { enabled: true },
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'smooth' },
    xaxis: {
      type: 'datetime',
      labels: {
        style: { colors: enableDarkTheme ? '#fff' : '#000' },
        datetimeUTC: false,
      },
    },
    yaxis: {
      labels: {
        style: { colors: enableDarkTheme ? '#fff' : '#000' },
        formatter: (value) => `${value.toFixed(2)}`,
      },
    },
    tooltip: {
      theme: enableDarkTheme ? 'dark' : 'light',
      x: { format: 'dd MMM yyyy HH:mm' },
    },
    grid: {
      borderColor: enableDarkTheme ? '#555' : '#eee',
      strokeDashArray: 5,
    },
    legend: {
      labels: { colors: enableDarkTheme ? '#fff' : '#000' },
      position: 'top',
    },
  };

  return (
    <Box sx={{ height: '340px', width: '100%' }}>
      <Chart
        height={'340px'}
        width={'100%'}
        options={options}
        series={series}
        type="area"
      />
    </Box>
  );
};

export default AreaChart;
