import { Fragment } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import type { Asset } from 'types/asset.types';
import { AssetSelector } from './AssetSelector';
import { SVG } from 'components/Asset/SVG';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';
import { ThemePalette } from 'mui.theme';

export const AssetDetails = ({
  enableDarkTheme,
  selectedMakeModel,
  setSelectedMakeModel,
  assetMakeModelList,
  selectedAsset,
  setSelectedAsset,
  assetMap,
  filteredAssets,
  hierarchyInfo,
  injestedTime,
  latestTagData,
}: {
  enableDarkTheme: boolean;
  selectedMakeModel: string;
  setSelectedMakeModel: any;
  assetMakeModelList: any[];
  selectedAsset: Asset;
  setSelectedAsset: any;
  assetMap: any;
  filteredAssets: Asset[];
  hierarchyInfo: any;
  injestedTime?: Record<string, string>;
  latestTagData: any;
}) => {
  const assetDetails = [
    {
      label: 'Asset ID',
      value: selectedAsset.bumperNumber,
    },
    {
      label: 'Type',
      value: assetTypeMappings[selectedAsset.assetType],
    },
    {
      label: 'Hierarchy Node',
      value: hierarchyInfo.nodeDisplay,
    },
    {
      label: 'OEM',
      value: selectedAsset.make,
    },
    {
      label: 'Model',
      value: selectedAsset.model,
    },
    {
      label: 'Status',
      value: selectedAsset.isActive ? 'Operational' : 'Out Of Service',
    },
  ];

  return (
    <Box>
      <AssetSelector
        enableDarkTheme={enableDarkTheme}
        selectedMakeModel={selectedMakeModel}
        setSelectedMakeModel={setSelectedMakeModel}
        assetMakeModelList={assetMakeModelList}
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        assetMap={assetMap}
        filteredAssets={filteredAssets}
      />
      <Box
        sx={{
          marginTop: '10px',
          border: '1px solid #2F445E',
          borderRadius: '4px',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          padding: '10px',
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Asset Details
          {injestedTime ? (
            <>
              <span
                style={{
                  fontSize: '11px',
                  paddingLeft: '10px',
                  fontWeight: 'normal',
                }}
              >
                {Object.keys(injestedTime)[0]}
              </span>
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: '600',
                  paddingLeft: '5px',
                }}
              >
                {Object.values(injestedTime)[0]}
              </span>
            </>
          ) : (
            <>
              <span
                style={{
                  fontSize: '11px',
                  paddingLeft: '10px',
                  fontWeight: 'normal',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Last injested time:
              </span>
              <span
                style={{
                  fontSize: '11px',
                  fontWeight: '600',
                  paddingLeft: '5px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {Array.isArray(latestTagData)
                  ? undefined
                  : latestTagData[selectedAsset.device?.deviceId]?.ingestDate}
              </span>
            </>
          )}
        </Typography>
        <Box
          sx={{
            paddingTop: '10px',
          }}
        >
          <Grid2
            container
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            {/* Render asset details */}
            {assetDetails.map((data, index, arr) => (
              <Fragment key={`${data.label}-group`}>
                <Grid2 key={`${data.label}-1`} display="flex">
                  {index === 0 && (
                    <SVG
                      name={mapVehiclesAssets(selectedAsset.assetType)}
                      style={{
                        fill: '#E2C171',
                        height: '45px',
                        width: '50px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                    />
                  )}
                  <div>
                    <Typography
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        fontSize: '11px',
                        textAlign: 'center',
                        paddingBottom: '5px',
                      }}
                    >
                      {data.label}
                    </Typography>
                    <Typography
                      sx={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                        fontSize: '11px',
                        fontWeight: '600',
                        textAlign: 'center',
                      }}
                    >
                      {data.value}
                    </Typography>
                  </div>
                </Grid2>
                <Grid2 padding={1}>
                  {index < arr.length - 1 && (
                    <Divider
                      key={`${data.label}-divider`}
                      orientation="vertical"
                      sx={{
                        background: ThemePalette.border,
                      }}
                    />
                  )}
                </Grid2>
              </Fragment>
            ))}
          </Grid2>
        </Box>
      </Box>
    </Box>
  );
};
