import { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import dayjs from 'dayjs';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  Label,
  ReferenceLine,
  ComposedChart,
  Line,
} from 'recharts';
import { ThemePalette } from 'mui.theme';
import { FlexBox } from 'components/Containers/FlexBox';
import ProductionSelector from 'views/Production/components/Selector/Selector';
import type { fleetPeriodSummary } from 'types/production.types';
import {
  type ChartSource,
  getFleetProduction,
  chartOptions,
  calculateDailyMovingAverage,
} from '../utils';
import MultiColorCircularLoader from 'views/Production/components/BoxLoader/MultiColorCircularLoader';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';

interface Props {
  enableDarkTheme: boolean;
  data: fleetPeriodSummary;
  isLoading: boolean;
  unitSystem: string;
  title: string;
  // setRollingDailyAverage: (number: number) => void;
  setUserSelections: any;
}

const mergeChartDataAndMovingAverages = (
  chartData: any[],
  movingAverages: Array<{
    date: string;
    movingAverage: number | null;
  }>,
  selectedChartSource: ChartSource
): Array<{
  date: string;
  value: number;
  movingAverage: number | null;
}> => {
  // logic to set the initial values for moving averages based on the selected window (selectedChartSource)
  if (
    selectedChartSource === 'Last 7 Days' ||
    selectedChartSource === 'Last 14 Days'
  ) {
    if (movingAverages?.length > 0) {
      movingAverages[0].movingAverage = +chartData[0]?.value || 0;
    }
  } else if (selectedChartSource === 'Last 4 Weeks') {
    if (movingAverages?.length >= 2) {
      movingAverages[0].movingAverage = +chartData[0]?.value || 0;
      movingAverages[1].movingAverage = +chartData[1].value || 0;
    }
  } else if (selectedChartSource === 'Last 6 Months') {
    if (movingAverages?.length >= 5) {
      for (let i = 0; i < 6; i++) {
        movingAverages[i].movingAverage = +chartData[i].value || 0;
      }
    }
  }

  return chartData.map((entry) => {
    const movingAverageEntry = movingAverages.find(
      (ma) => ma.date === entry.date
    );

    return {
      ...entry,
      movingAverage: movingAverageEntry
        ? Number(Number(movingAverageEntry?.movingAverage).toFixed(2))
        : null,
    };
  });
};

const FleetProductionChart = ({
  enableDarkTheme,
  data,
  isLoading,
  unitSystem,
  title,
  // setRollingDailyAverage,
  setUserSelections,
}: Props) => {
  const [selectedChartSource, setSelectedChartSource] =
    useState<ChartSource>('Last 7 Days');
  let chartData: any = [];

  const selectedSource: any = data?.find(
    (item) => item.time_range === getFleetProduction(selectedChartSource)
  );

  if (selectedSource?.daily_production) {
    chartData = Object.keys(selectedSource?.daily_production).map((date) => ({
      date,
      value: convertToTwoDecimalPlaces(selectedSource?.daily_production[date]),
    }));
  }

  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs(timestamp);
    const formattedDate = date.format('DD MMM');
    return formattedDate;
  };

  const getHighestValue = (arr: any) => {
    const highestValue: number = arr.reduce((max: any, obj: any) => {
      const currentValue = Number(obj.value);
      return !isNaN(currentValue) && currentValue > max ? currentValue : max;
    }, -Infinity);

    return highestValue;
  };

  const average =
    chartData.reduce(
      (sum: number, entry: { value: string }) => sum + Number(entry.value),
      0
    ) / chartData.length;

  // Calculate the moving averages - If selectedChartSource is last 7 or last 14 days then keep window size as 2, if selectedChartSource is last 4 weeks then keep window size as 3, else keep window size as 7
  const movingAverages = calculateDailyMovingAverage(
    chartData,
    selectedChartSource === 'Last 7 Days' ||
      selectedChartSource === 'Last 14 Days'
      ? 2
      : selectedChartSource === 'Last 4 Weeks'
      ? 3
      : 7
  );

  useEffect(() => {
    setUserSelections({
      showChart: selectedChartSource,
      movingAverages,
      average,
    });
  }, [selectedChartSource, movingAverages, average]);

  const mergedChartData = mergeChartDataAndMovingAverages(
    chartData,
    movingAverages,
    selectedChartSource
  );

  // useEffect(() => {
  //   const result = calculateDailyMovingAverage(chartData, 2);
  //   if (result?.length > 0 && Array.isArray(result)) {
  //     setRollingDailyAverage(result[result.length - 1].movingAverage ?? 0);
  //   }
  // }, [chartData]);

  return (
    <Grid item lg={6} md={6} sm={12} xs={12}>
      <Box
        sx={{
          padding: 2,
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.toolBarBackground,
          borderRadius: '5px',
          border: '1px solid #2F445D',
          minHeight: '300px',
          maxHeight: '300px',
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '13px',
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontSize: '12px',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          >
            Show
          </Typography>

          <ProductionSelector
            enableDarkTheme={enableDarkTheme}
            options={chartOptions}
            selected={selectedChartSource}
            setSelected={setSelectedChartSource}
            minWidth="150px"
          />
        </Box>

        {isLoading ? (
          <MultiColorCircularLoader height="300px" />
        ) : (
          <Box
            sx={{
              paddingTop: '15px',
            }}
          >
            <FlexBox
              sx={{
                maxWidth: 'inherit',
                height: '230px',
                paddingTop: '20px',
                marginRight: '10px',
                paddingBottom: '10px',
              }}
            >
              {mergedChartData.length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={700}
                    height={300}
                    data={mergedChartData}
                    margin={{
                      top: 20,
                      right: 110,
                      left: 5,
                      bottom: 5,
                    }}
                    barSize={30}
                  >
                    <CartesianGrid
                      strokeDasharray="3 3"
                      stroke={'#808080'}
                      vertical={false}
                    />
                    <XAxis
                      dataKey="date"
                      tickFormatter={formatTimestamp}
                      tick={{ fontSize: 10 }}
                    />
                    <YAxis
                      tick={{ fontSize: 10 }}
                      label={{
                        value: `Production (${
                          unitSystem === 'imperial' ? 'iTons' : 'Tons'
                        })`,
                        angle: 270,
                        position: 'insideLeft',
                        fontSize: 10,
                        textAnchor: 'middle',
                        dy: 50,
                      }}
                      domain={[0, getHighestValue(chartData)]}
                    />
                    <Label
                      style={{
                        textAnchor: 'middle',
                        fontSize: '11px',
                      }}
                      angle={270}
                      value={`Production (${
                        unitSystem === 'imperial' ? 'iTons' : 'Tons'
                      })`}
                    />
                    <Tooltip
                      cursor={{ fill: '#1B2B4A' }}
                      wrapperStyle={{ outline: 'none', fontSize: '11px' }}
                      contentStyle={{
                        backgroundColor: 'transparent',
                      }}
                      itemStyle={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.wheat
                          : ThemePalette.typography.black,
                      }}
                    />
                    <Bar
                      dataKey="value"
                      fill="#4A7BFA"
                      activeBar={<Rectangle fill="pink" stroke="blue" />}
                    />
                    <Line
                      type="monotone"
                      dataKey="movingAverage"
                      stroke={enableDarkTheme ? '#fafafa' : '#172A46'}
                      dot={{ r: 0 }}
                      strokeDasharray={'3 3'}
                    />
                    <ReferenceLine
                      y={average}
                      label={{
                        value: `Avg ${average.toFixed(2)}  ${
                          unitSystem === 'imperial' ? 'iTons' : 'Tons'
                        }/Day`,
                        position: 'right',
                        fontSize: '10px',
                      }}
                      stroke="#FF6600"
                      strokeDasharray="3 3"
                      isFront
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              ) : (
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.black,
                  }}
                >
                  No data available currently
                </Typography>
              )}
            </FlexBox>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default FleetProductionChart;
