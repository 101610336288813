import React, { useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Sector,
} from 'recharts';
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Base } from 'components/Containers/BaseContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import { ThemePalette } from 'mui.theme';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { filterObjectByKeys, aggregateAssetData } from '../utils';
import { handleFocusRemoveSelectOutline } from '../Helper/pieChart.Helper';

const MaintenanceBreakdown = ({
  enableDarkTheme,
  data,
  selectedCell,
}: {
  enableDarkTheme: boolean;
  data: any;
  selectedCell: string[];
}) => {
  let assetData = filterObjectByKeys(data.actionsCounter, selectedCell);

  if (!assetData) {
    assetData = {};
  }

  const colors = [
    '#ff4560', // Red
    '#008ffb', // Blue
    '#00e396', // Green
    '#feb019', // Yellow
    '#775dd0', // Purple
    '#f46036', // Orange
  ];

  const formattedData = aggregateAssetData(assetData, colors);

  const [activeIndices, setActiveIndices] = useState<Record<number, boolean>>({
    0: true, // Set the first segment as active
    1: true,
  });

  const onPieClick = (data: any, index: number) => {
    setActiveIndices((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the active state for the clicked segment
    }));
  };

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.toolBarBackground
            : ThemePalette.light.toolBarBackground,
          padding: '20px',
        }}
      >
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Maintenance Distribution
        </Typography>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div onFocus={handleFocusRemoveSelectOutline}>
              <FlexBox>
                <ResponsiveContainer width="100%" height={450}>
                  <PieChart>
                    <Pie
                      data={formattedData}
                      isAnimationActive={true}
                      cx="50%"
                      cy="50%"
                      innerRadius={80}
                      outerRadius={105}
                      paddingAngle={2}
                      dataKey="value"
                      startAngle={90}
                      endAngle={-270}
                      onClick={onPieClick}
                      activeIndex={Object.keys(activeIndices)
                        .map(Number)
                        .filter((i) => activeIndices[i])}
                      activeShape={renderActiveShape}
                    >
                      {formattedData.map((entry: any, index: number) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip
                      content={
                        <CustomTooltip enableDarkTheme={enableDarkTheme} />
                      }
                    />
                  </PieChart>
                </ResponsiveContainer>
              </FlexBox>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: 'transparent',
                boxShadow: 'none',
                '& .MuiTableCell-root': {
                  color: 'white',
                  borderColor: 'rgba(255, 255, 255, 0.1)',
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">No. of Notifications</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formattedData.map((row: any, index: any) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            sx={{
                              width: '12px',
                              height: '12px',
                              borderRadius: '50%',
                              backgroundColor: row.color,
                              marginRight: '8px',
                            }}
                          />
                          {capitalizeFirstLetter(row.name)}
                        </Box>
                      </TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Base>
  );
};

export default MaintenanceBreakdown;

const CustomTooltip = ({ active, payload, enableDarkTheme }: any) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
          padding: '10px',
        }}
      >
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
        >
          {capitalizeFirstLetter(data.name)}: {data.value}
        </Typography>
      </Box>
    );
  }
  return null;
};

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    name,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = Number(cx) + (Number(outerRadius) + 10) * cos;
  const sy = Number(cy) + (Number(outerRadius) + 10) * sin;
  const mx = Number(cx) + (Number(outerRadius) + 30) * cos;
  const my = Number(cy) + (Number(outerRadius) + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={Number(outerRadius) + 6}
        outerRadius={Number(outerRadius) + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-42}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={10}
      >{`Category:`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-28}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={14}
      >{`${name}`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-12}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={10}
      >{`Value:`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={14}
      >{`${value.toFixed(0)}`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={15}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={10}
      >
        {`Rate`}
      </text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={27}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={14}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};
