import { Typography, LinearProgress } from '@mui/material';
import { ProgressBarWithArrow } from './ProgressBarWithArrow';
import { ThemePalette } from 'mui.theme';
import { useNavigate } from 'react-router-dom';
import { SWToolTip } from 'components/SWToolTip';
import {
  evaluateNumber,
  numberEvaluationKeyMappings,
} from '../components/AssetCardsV2/utils';

export const FleetOverviewV2GridItem = ({
  value = {},
  enableDarkTheme,
  type,
  kpiThresholds = {},
  disabled = false,
  isLoading = false,
}: any) => {
  const fuelDisabled = disabled || !value.fuel || value.fuel === null;
  const utilizationDisabled =
    disabled || !value.utilization || value.utlization === null;
  const productionDisabled =
    disabled || !value.production || value.production === null;

  const navigate = useNavigate();

  const thresholdMappings = numberEvaluationKeyMappings(type);

  return isLoading ? (
    <LinearProgress
      color="secondary"
      sx={{
        marginTop: '15px',
      }}
    />
  ) : (
    <>
      <SWToolTip
        title={
          fuelDisabled
            ? 'No data available for category at this site'
            : `${`Last Fuel Usage Data available for ${value?.fuelScoresDate}
                Click for drill-down to view Fuel Usage for ${
                  type === 'total' ? 'all Fleet' : type
                }`}`
        }
      >
        <Typography
          component="div"
          sx={{
            fontSize: '12px',
            textAlign: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (type && !fuelDisabled) {
              navigate(`/fuel-usage/overview`, {
                state: {
                  type,
                  value: value.fuel,
                },
              });
            }
          }}
        >
          <ProgressBarWithArrow
            value={evaluateNumber(
              value.fuel,
              thresholdMappings.fuelUsage,
              kpiThresholds
            )}
            enableDarkTheme={enableDarkTheme}
            disabled={fuelDisabled}
          />
          Fuel Usage
        </Typography>
      </SWToolTip>

      <SWToolTip
        title={
          utilizationDisabled
            ? 'No data available for category at this site'
            : `${`Last Utilization Data available for ${
                value?.utilizationScoresDate
              } 
                Click for drill-down to view Utilization for ${
                  type === 'total' ? 'all Fleet' : type
                }`}`
        }
      >
        <Typography
          component="div"
          sx={{
            fontSize: '12px',
            textAlign: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (type && !utilizationDisabled) {
              navigate(`/utilization`, {
                state: {
                  type,
                },
              });
            }
          }}
        >
          <ProgressBarWithArrow
            value={evaluateNumber(
              value.utilization,
              thresholdMappings.utilization,
              kpiThresholds
            )}
            enableDarkTheme={enableDarkTheme}
            disabled={utilizationDisabled}
          />
          Utilization
        </Typography>
      </SWToolTip>

      <SWToolTip
        title={
          productionDisabled
            ? 'No data available for category at this site'
            : `${`Last Production Data available for ${
                value?.productivityScoresDate
              } 
            Click for drill-down to view Production for ${
              type === 'total' ? 'all Fleet' : type
            }`}`
        }
      >
        <Typography
          component="div"
          sx={{
            fontSize: '12px',
            textAlign: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (type && !productionDisabled) {
              navigate(`/production/overview`, {
                state: {
                  type,
                },
              });
            }
          }}
        >
          {/** Others is dozer */}
          {type === 'Others' ? (
            <>{''}</>
          ) : (
            <>
              <ProgressBarWithArrow
                value={evaluateNumber(
                  value.production,
                  thresholdMappings.productivity,
                  kpiThresholds
                )}
                enableDarkTheme={enableDarkTheme}
                disabled={productionDisabled}
              />
              Production
            </>
          )}
        </Typography>
      </SWToolTip>
    </>
  );
};
