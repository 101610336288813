import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import TagAPI from 'api/tag.api';
import { setThresholds } from 'store/tag.threshold.slice';

export default class TagThresholdHandler extends BaseHandler {
  private readonly api: TagAPI;
  constructor() {
    super();

    this.api = new TagAPI();
  }

  /**
   * Gets tag Thresholds
   */

  async get() {
    this.dispatch(updateLoadingState(true));
    try {
      const response = await this.api.getTagThresholds();
      this.dispatch(setThresholds(response));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving tag thresholds : ' + msg
      );
    }
  }
}
