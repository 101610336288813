import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { useCurrentDateRange } from 'hooks/dateRange';
import HeaderMenu from 'views/Production/HeaderMenu/index';
import Loader from './Loader';
import AllFleet from './AllFleet';
import Hauler from './Hauler';
import UtilizationHandler from 'handlers/utilization.handler';
import { exportOverviewPagesToPdf2 } from 'utils/exports/pdf-reports';
import { selectExportFormat, setIsPrinting } from 'store/exportData.slice';
import { exportToExcelForUtilization } from 'utils/exports/excel';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import { useAuth0 } from '@auth0/auth0-react';
import TargetHandler from 'handlers/targets.handler';
import { calculateDaysDifference } from 'views/FuelAnalytics/IdleDashboard/utils';
import { formatDateShortener, formatQuickRange } from 'utils/helpers/dateTime';
import { splitCamelCase } from 'utils/helpers/string.manipulation';

const Utilization = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const typeReceived = location.state?.type;
  const utilizationHandler = new UtilizationHandler();
  const {
    customer: { theme },
    accessToken,
  } = useAppSelector((state) => state.authReducer);
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const { code: customerCode, id: customerId } = useAppSelector(
    (state) => state.persistedReducer.customer
  );
  const { assetOverView: assetOverview } = useAppSelector(
    (state) => state.assetReducer
  );
  const { data, loadingStates } = useAppSelector(
    (state) => state.utilizationReducer
  );

  const { isPrinting } = useAppSelector((state) => state.exportDataReducer);
  const exportFormat = useAppSelector(selectExportFormat);

  const enableDarkTheme = isDarkTheme(theme);
  const dispatch = useAppDispatch();

  const defaultValue = typeReceived
    ? typeReceived === 'Haul Truck'
      ? 1
      : typeReceived === 'Loader'
      ? 2
      : 0
    : 0;
  const [value, setValue] = useState(defaultValue);
  const [isSelected, setIsSelected] = useState('1D');
  const [initRun, setInitRun] = useState<boolean>(true);
  const [isCustomDateChanged, setIsCustomDateChanged] = useState(false);
  const [aggregation, setAggregation] = useState<string>('Daily');
  const [shiftWiseAnalysis, setShiftWiseAnalysis] = useState<boolean>(false);
  const [shiftNumber, setShiftNumber] = useState<number>(1);
  const [utilizationType, setUtilizationType] = useState('engineHours');
  const [detailsVal, setDetailsVal] = useState<Record<string, unknown>>({});
  const [analyticsData, setAnalyticsData] = useState<any[]>();
  const [hoursBreakDown, setHoursBreakDown] = useState<{
    hauler: any;
    loader: any;
  }>({
    hauler: null,
    loader: null,
  });

  const [userSelections, setUserSelections] = useState({});
  const [suggestionData, setSuggestionData] = useState({});
  const [fuelConsumption, setFuelConsumption] = useState<{
    hauler: any;
    loader: any;
  }>({
    hauler: null,
    loader: null,
  });

  const { startDate, endDate, setStartDate, setEndDate, today } =
    useCurrentDateRange({});

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    const dateDifference = dayjs(endDate).diff(dayjs(startDate), 'day');
    // setAggregation(dateDifference < 31 ? 'Daily' : 'Monthly');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };
  const [exportTo, setExportTo] = useState('');
  const [exportClicked, setExportClicked] = useState(false);

  const fileNameWithDateTime = `utilization-dashboard-${formatDate(
    new Date()
  )}`;
  const title = '';

  const handleExportClick = async () => {
    dispatch(setIsPrinting(true));
    await exportOverviewPagesToPdf2(
      'utilization-overview',
      'utilization-overview',
      'Utilization Overview',
      false,
      'portrait',
      value === 0 ? 2 : 4
    );
    dispatch(setIsPrinting(false));
  };

  useEffect(() => {
    if (exportTo === 'pdf') {
      void handleExportClick();
    }
    if (exportTo === 'excel') {
      exportToExcelForUtilization(
        {
          ...data.allFleet,
          fleetSummary: detailsVal,
          value: value === 2 ? 'loader' : value === 1 ? 'hauler' : 'all fleet',
        },
        fileNameWithDateTime,
        `${title}`,
        unitSystem,
        analyticsData as any[],
        hoursBreakDown as {
          hauler: any;
          loader: any;
        },
        {
          ...userSelections,
          quickrange:
            isSelected === 'Custom'
              ? `${calculateDaysDifference(
                  startDate,
                  endDate
                )} Days from ${formatDateShortener(startDate)} ${
                  shiftWiseAnalysis ? 'using shift wise analysis ' : ''
                }with utilization by ${splitCamelCase(
                  utilizationType
                )} and ${aggregation} aggregation`
              : `${formatQuickRange(isSelected)} ${
                  shiftWiseAnalysis ? 'using shift wise analysis ' : ''
                }with utilization by ${splitCamelCase(utilizationType)}`,
        },
        suggestionData,
        fuelConsumption
      );
    }
  }, [exportTo]);
  const isNotLoadingData = Object.entries(loadingStates)
    .filter(
      ([key]) => key !== 'isLoadingTarget' && key !== 'isLoadingSuggestions'
    )
    .every(([, value]) => !value);
  const fetchData = async (range: string, startDate: Date, endDate: Date) => {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
    const shouldSendDate = isSelected === 'Custom';

    if (isNotLoadingData) {
      await utilizationHandler.get(
        range,
        formattedStartDate,
        formattedEndDate,
        shouldSendDate,
        false,
        value === 2 ? 'loader' : value === 1 ? 'loader' : 'all',
        ['1Y', 'YTD', 'Custom'].includes(isSelected)
          ? aggregation.toLowerCase()
          : 'daily',
        shiftWiseAnalysis,
        shiftNumber,
        utilizationType
      );
      setInitRun(false);
    }
  };

  useEffect(() => {
    if (isSelected === 'Custom') {
      if (!isCustomDateChanged) {
        setIsCustomDateChanged(true);
      }
      if (isCustomDateChanged && startDate && endDate) {
        void fetchData(isSelected, startDate, endDate);
      }
    } else if (startDate && endDate) {
      setIsCustomDateChanged(false);
      void fetchData(isSelected, startDate, endDate);
    }
  }, [
    isSelected,
    startDate,
    endDate,
    aggregation,
    shiftWiseAnalysis,
    shiftNumber,
    utilizationType,
  ]);

  return (
    <>
      <HeaderMenu
        value={value}
        handleChange={handleChange}
        handleExport={setExportClicked}
        setExportTo={setExportTo}
        exportTo={exportTo}
        loadingData={isNotLoadingData}
        isPrinting={isPrinting}
      />
      <div id="utilization-overview">
        <Loader
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          unitSystem={unitSystem}
          customerCode={customerCode}
          assetOverview={assetOverview}
          loadingStates={loadingStates}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={(value: any) => {
            setHoursBreakDown((prev) => ({ ...prev, loader: value }));
          }}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
          setFuelConsumption={(value: any) => {
            setFuelConsumption((prev) => ({ ...prev, loader: value }));
          }}
        />
        <Hauler
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          unitSystem={unitSystem}
          customerCode={customerCode}
          assetOverview={assetOverview}
          loadingStates={loadingStates}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={(value: any) => {
            setHoursBreakDown((prev) => ({ ...prev, hauler: value }));
          }}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
          setFuelConsumption={(value: any) => {
            setFuelConsumption((prev) => ({ ...prev, hauler: value }));
          }}
        />
        <AllFleet
          value={value}
          enableDarkTheme={enableDarkTheme}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          handleDateChange={handleDateChange}
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          yesterday={today}
          unitSystem={unitSystem}
          customerCode={customerCode}
          assetOverview={assetOverview}
          loadingStates={loadingStates}
          data={data.allFleet}
          aggregation={aggregation}
          setAggregation={setAggregation}
          shiftWiseAnalysis={shiftWiseAnalysis}
          setShiftWiseAnalysis={setShiftWiseAnalysis}
          shiftNumber={shiftNumber}
          setShiftNumber={setShiftNumber}
          isPrinting={isPrinting}
          utilizationType={utilizationType}
          setUtilizationType={setUtilizationType}
          setDetailsVal={setDetailsVal}
          setAnalyticsData={setAnalyticsData}
          setHoursBreakDown={setHoursBreakDown}
          setUserSelections={setUserSelections}
          setSuggestionData={setSuggestionData}
        />
      </div>
    </>
  );
};

export default Utilization;
