import dayjs from 'dayjs';
import type {
  DriverBehavior,
  DriverBehaviorData,
  DriverBehaviorTimeLineData,
  EngineHourDriverBehaviourEventConfig,
} from 'types/driver.behavior.types';

export const allEvents: EngineHourDriverBehaviourEventConfig[] = [
  {
    key: 'overspeed',
    name: 'overspeeding',
    tooltipConfig: { showDuration: true, showAvgSpeed: true },
  },
  {
    key: 'underspeed',
    name: 'underspeeding',
    tooltipConfig: { showDuration: true, showAvgSpeed: true },
  },
  {
    key: 'incorrect_gear',
    name: 'incorrect gear to speed ratio',
    tooltipConfig: {
      showGear: true,
      showDuration: true,
    },
  },
  {
    key: 'braking',
    name: 'hard braking',
    isInstantEvent: true,
    tooltipConfig: {
      showCurrentAndPreviousSpeed: true,
    },
  },
  {
    key: 'acceleration',
    name: 'hard acceleration',
    isInstantEvent: true,
    tooltipConfig: {
      showCurrentAndPreviousSpeed: true,
    },
  },
  {
    key: 'incorrect_gear_shift',
    name: 'Incorrect gear during direction switch',
    isInstantEvent: true,
    tooltipConfig: {
      showCurrentAndNextGear: true,
      showSpeed: true,
    },
  },
  {
    key: 'gear_skip',
    name: 'Gear Skip',
    isInstantEvent: true,
    tooltipConfig: {
      showCurrentAndNextGear: true,
      showSpeed: true,
    },
  },
];

export const allEngineHoursEvents: EngineHourDriverBehaviourEventConfig[] = [
  {
    key: 'productive',
    name: 'productive',
    tooltipConfig: { showDuration: true },
  },
  {
    key: 'short_idling',
    name: 'short_idling',
    tooltipConfig: { showDuration: true },
  },
  {
    key: 'excess_idling',
    name: 'excess_idling',
    tooltipConfig: { showDuration: true },
  },
  {
    key: 'engine_hours',
    name: 'engine_hours',
    tooltipConfig: { showDuration: true },
  },
];

export function aggregateEvents(data: DriverBehavior[]) {
  const eventMap: Record<string, number> = {};

  data.forEach((item) => {
    item.data?.forEach((event) => {
      const startDate = event?.start_ts?.split(' ')[0]; // Extract date portion
      if (!eventMap[startDate]) {
        eventMap[startDate] = 0;
      }
      eventMap[startDate] += 1; // Increment count for this date
    });
  });

  // Convert the map to an array of objects
  const result = Object.entries(eventMap).map(([date, count]) => ({
    start_ts: date,
    noOfEvents: count,
  }));
  result.sort((a, b) => dayjs(a.start_ts).unix() - dayjs(b.start_ts).unix());
  return result;
}

export function transformDriverBehaviorData(
  driverBehaviorData: DriverBehaviorData,
  engineHours?: boolean
): DriverBehaviorTimeLineData[] {
  const mapEventsToTimelineData = (
    behaviors: DriverBehavior[],
    name: string
  ): DriverBehaviorTimeLineData => {
    return {
      name,
      data: behaviors?.flatMap(
        (behavior) =>
          behavior.data?.map((event) => ({
            x: behavior.bumper_id,
            y: [
              new Date(event?.start_ts).getTime(),
              new Date(event?.end_ts).getTime(),
            ],
            ...event,
          })) ?? []
      ),
    };
  };

  const eventsToUse = engineHours ? allEngineHoursEvents : allEvents;

  const resp: DriverBehaviorTimeLineData[] = eventsToUse?.map((item) => {
    const eventData = mapEventsToTimelineData(
      // @ts-expect-error ignore we have type defined
      driverBehaviorData?.[item?.key],
      item?.name
    );
    return eventData;
  });

  return resp;
}

export function filterDriverBehaviorByDeviceId(
  data: DriverBehaviorData,
  bumperId: string
): DriverBehaviorData {
  const filterByDeviceId = (behaviorList: DriverBehavior[]) =>
    behaviorList.filter((behavior) => behavior.bumper_id === bumperId);

  return {
    productive: filterByDeviceId(data.productive),
    short_idling: filterByDeviceId(data.short_idling),
    excess_idling: filterByDeviceId(data.excess_idling),
    overspeed: filterByDeviceId(data.overspeed),
    underspeed: filterByDeviceId(data.underspeed),
    braking: filterByDeviceId(data.braking),
    incorrect_gear: filterByDeviceId(data?.incorrect_gear),
    incorrect_gear_shift: filterByDeviceId(data?.incorrect_gear_shift),
    gear_skip: filterByDeviceId(data?.gear_skip),
    engine_hours: [],
    acceleration: filterByDeviceId(data.acceleration),
  };
}

export function isAllTimeLineDataEmpty(arr: any) {
  return arr.every(
    (item: any) => Array.isArray(item.data) && item.data.length === 0
  );
}
