import { useState } from 'react';

interface DateRange {
  initialDate?: Date | null;
}

export const useDateRange = ({ initialDate = null }: DateRange) => {
  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  const defaultDate = initialDate ?? yesterday;
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(defaultDate);

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    yesterday,
  };
};

export const useCurrentDateRange = ({ initialDate = null }: DateRange) => {
  const today = new Date();
  const defaultDate = initialDate ?? today;
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(defaultDate);

  return {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    today,
  };
};
