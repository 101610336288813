import { useAppSelector } from 'store/hook';

const useCustomerInfo = () => {
  const customerCode = useAppSelector(
    (state) => state.persistedReducer.customer.code
  );
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  return {
    customerCode,
    unitSystem,
  };
};

export default useCustomerInfo;
