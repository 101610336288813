import dayjs from 'dayjs';
import { Box, Typography, ButtonGroup, Button } from '@mui/material';
import DatePicker from 'react-datepicker';
import { ThemePalette } from 'mui.theme';

export const DateFilter = ({
  enableDarkTheme,
  handleDateChange,
  selectedStartDate,
  selectedEndDate,
  isSelected,
  setIsSelected,
  setStartDate,
  setEndDate,
  isLoading,
}: {
  enableDarkTheme: boolean;
  handleDateChange: any;
  selectedStartDate: any;
  selectedEndDate: any;
  isSelected: string;
  setIsSelected: any;
  setStartDate: any;
  setEndDate: any;
  isLoading: boolean;
}) => {
  const handleSelect = (id: string) => {
    setIsSelected(id);
    let newStartDate = new Date();
    const newEndDate = new Date();

    switch (id) {
      case '1D':
        newStartDate = dayjs(newStartDate).subtract(1, 'day').toDate();
        break;
      case '7D':
        newStartDate = dayjs(newStartDate).subtract(7, 'days').toDate();
        break;
      case '14D':
        newStartDate = dayjs(newStartDate).subtract(14, 'days').toDate();
        break;
      case '1M':
        newStartDate = dayjs(newStartDate).subtract(31, 'days').toDate();
        break;
      case '1Y':
        newStartDate = dayjs(newStartDate).subtract(1, 'year').toDate();
        break;
      case 'YTD':
        newStartDate = dayjs(newStartDate).startOf('year').toDate();
        break;
      case 'Custom':
        break;
      default:
        break;
    }

    if (id !== 'Custom') {
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
  };

  const dateRangeList = ['1D', '7D', '14D', '1M', '1Y', 'YTD', 'Custom'];

  return (
    <Box
      sx={{
        display: 'flex',
        marginTop: '10px',
        marginBottom: '20px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.toolBarBackground
          : ThemePalette.light.toolBarBackground,
        padding: '15px 10px 15px 10px',
        borderRadius: '5px',
        border: '1px solid #2F445D',
      }}
    >
      <Typography
        style={{
          fontSize: '12px',
          marginRight: '10px',
          paddingTop: '7px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Quick Range:
      </Typography>
      <ButtonGroup
        size="small"
        variant="outlined"
        aria-label="Basic button group"
        sx={{
          border: '1px solid #2F445D',
          marginRight: '10px',
        }}
        disabled={isLoading}
      >
        {dateRangeList.map((item) => {
          return (
            <Button
              key={item}
              size="small"
              style={{
                backgroundColor:
                  isSelected === item
                    ? '#3666EC'
                    : enableDarkTheme
                    ? ThemePalette.dark.cartesianGrid
                    : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                textTransform: 'none',
              }}
              onClick={() => {
                handleSelect(item);
              }}
            >
              {item}
            </Button>
          );
        })}
      </ButtonGroup>
      <Typography
        sx={{
          paddingTop: '7px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          marginRight: '10px',
        }}
      >
        Date Range
      </Typography>

      <DatePicker
        showIcon
        selected={selectedStartDate}
        onChange={handleDateChange}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        maxDate={new Date(new Date().getTime())}
        selectsRange
        className={
          enableDarkTheme
            ? 'custom-datepicker-prod-squares'
            : 'custom-datepicker-prod-light-theme'
        }
        disabled={isLoading || isSelected !== 'Custom'}
      />
    </Box>
  );
};
