import { type Asset } from 'types/asset.types';
import { type DTC } from 'types/dtc.types';
import { type GeoTrip } from 'types/geo.types';
import { type RuleNotifications } from 'types/notification.types';
import { type TripData, type TripDetails } from 'types/production.types';
import { NOTIFICATION_LEVEL, COLORS } from 'utils/enums';
import { coordsMap } from 'utils/helpers/general';

// todo update type defs when we have them
export const addLiveDataToAssets = (
  // list of assets with device data
  associatedAssets: Asset[],
  liveTagData: Record<string, Record<string, any>>,
  notificationData: RuleNotifications[],
  latestPaths?: GeoTrip[],
  dtc?: DTC[]
): Asset[] => {
  return associatedAssets.map((asset): Asset => {
    if (asset?.device?.deviceId) {
      const liveTagDataForAsset = liveTagData[asset.device.deviceId];
      const notificationForAsset = notificationData.filter(
        (notification: any) =>
          notification.deviceId === asset.device.deviceId &&
          notification.isActive === true
      );
      const assetCopied = structuredClone(asset);
      assetCopied.device.daysBehind = liveTagDataForAsset?.daysBehind;
      assetCopied.device.ingestDate = liveTagDataForAsset?.ingestDate;
      const daysBehind = assetCopied.device.daysBehind;
      let latestGeoPaths;
      let dtcData;

      if (latestPaths) {
        latestGeoPaths = latestPaths?.find((path: GeoTrip) => {
          return asset.device.deviceId === path.device;
        });
      }
      if (dtc) {
        dtcData = Object.entries(dtc)
          .filter(([key, value]) => value.device === asset.device.deviceId)
          .reduce((acc: any, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});
      }

      // Update deviceStatus based on daysBehind
      if (daysBehind >= 0 && daysBehind < 0.042) {
        assetCopied.device.deviceStatus = 'Online';
      } else if (daysBehind >= 0.042 && daysBehind < 1) {
        assetCopied.device.deviceStatus = 'Recently Online';
      } else if (daysBehind >= 1 && daysBehind < 30) {
        assetCopied.device.deviceStatus = 'Offline';
      } else {
        // Handle any other cases here, e.g., set to 'Unknown'
        assetCopied.device.deviceStatus = 'Offline > 30D';
      }
      return {
        ...assetCopied,
        liveTagData: liveTagDataForAsset,
        notifications: notificationForAsset,
        latestGeoPaths,
        dtcData,
      };
    } else {
      return {
        ...asset,
      };
    }
  });
};

export const notificationLevel = (
  notifications?: RuleNotifications[]
): { color: string; warning: string; level: number; message: string } => {
  let color, warning, level, message;
  if (notifications && notifications?.length <= 0) {
    color = COLORS.green; // Greeen  #749A4A
    warning = 'No Alerts';
    level = 0;
    message = 'Ideal Performance';
  } else if (
    notifications?.some(
      (el: any) => el.rule.notificationLevel === NOTIFICATION_LEVEL[2].id
    )
  ) {
    color = COLORS.red; // Red #A03434
    warning = NOTIFICATION_LEVEL[2].display;
    level = Number(NOTIFICATION_LEVEL[2].id);
    message = 'Attention Required';
  } else if (
    notifications?.some(
      (el: any) => el.rule.notificationLevel === NOTIFICATION_LEVEL[1].id
    )
  ) {
    color = COLORS.yellow; // Yellow #F5C274
    warning = NOTIFICATION_LEVEL[1].display;
    level = Number(NOTIFICATION_LEVEL[1].id);
    message = 'Caution Advised';
  } else if (
    notifications?.some(
      (el: any) => el.rule.notificationLevel === NOTIFICATION_LEVEL[0].id
    )
  ) {
    color = COLORS.grey; // GREY #77859F
    warning = NOTIFICATION_LEVEL[0].display;
    level = Number(NOTIFICATION_LEVEL[0].id);
    message = 'Caution Advised';
  } else {
    color = COLORS.green; // Greeen  #749A4A
    warning = 'No Alerts';
    level = 0;
    message = 'Ideal Performance';
  }
  return { color, warning, level, message };
};

export function transformText(input: string) {
  // Split the string into words
  let words = input.split(/[\s_]+/);

  // Filter out the word 'at'
  words = words.filter((word) => word.toLowerCase() !== 'at');

  // Capitalize the first letter of each remaining word
  words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with a space
  return words.join(' ');
}

export function calculatePercentageChange(
  firstNumber: number,
  secondNumber: number
) {
  let percentageChange;

  if (firstNumber === 0) {
    if (secondNumber === 0) {
      percentageChange = 0; // If both numbers are zero, the percentage change is zero.
    } else {
      percentageChange = 100; // If the first number is zero and the second number is not, treat it as a 100% increase.
    }
    return {
      percentageChange: percentageChange.toFixed(2),
      isIncrement: secondNumber > firstNumber,
    };
  }

  percentageChange = ((secondNumber - firstNumber) / firstNumber) * 100;

  return {
    percentageChange: Math.abs(percentageChange).toFixed(2),
    isIncrement: secondNumber > firstNumber,
  };
}

export const assetTypeMappings: Record<string, string> = {
  haul_truck: 'Haul Truck',
  light_truck: 'Haul Truck',
  heavy_truck: 'Haul Truck',
  loader: 'Loader',
  dozer: 'Others',
  grader: 'Others',
  excavator: 'Others',
  drill: 'Others',
  bolter: 'Others',
  miscellaneous: 'Others',
};

export const getAssetType = (assetName: string) => {
  switch (assetName) {
    case 'Haul Truck':
      return 'haul_truck';
    case 'Loader':
      return 'loader';
    default:
      return 'others';
  }
};

export function findHighestAndValuesForKey(data: any, key: any) {
  const assetValues = data.map((obj: any) => {
    return obj[key] ?? 0;
  });

  const sumOfHighestNumbers = assetValues.reduce(
    (acc: number, cur: number) => acc + cur,
    0
  );

  const percentages = assetValues.map((highest: any) => {
    return (highest / sumOfHighestNumbers) * 100;
  });

  const mergedArr = data.map((item: any, index: any) => {
    return {
      ...item,
      'Cumulative Total': percentages[index].toFixed(2) ?? 0,
    };
  });
  return mergedArr;
}

export const assetHasDTC = (asset: Asset): boolean => {
  if (asset?.dtcData && Object.keys(asset.dtcData).length > 0) {
    return true;
  }
  return false;
};
export const assetHasSuggestions = (
  asset: Asset,
  suggestions: TripData[]
): number => {
  const now = new Date();
  const last24Hours = new Date(now.getTime() - 24 * 60 * 60 * 1000);
  const details = suggestions
    .flatMap((trip) => trip.outlier_trips_based_on_time)
    .filter((item: TripDetails) => {
      // Parse UTC date and adjust it to local time zone
      const [datePart, timePart] = item.date.split(' '); // Extract "YYYY-MM-DD" and "HH:MM:SS.sss"
      const utcDate = new Date(`${datePart}T${timePart}Z`); // Convert to proper UTC Date

      // Convert UTC Date to local time
      const itemDateLocal = new Date(
        utcDate.getTime() + new Date().getTimezoneOffset() * 60 * 1000 * -1
      );
      return (
        item.device === asset.device.assetBumperNumber &&
        itemDateLocal >= last24Hours &&
        itemDateLocal <= now
      );
    });

  return details.length;
};

export const isAssetIgnition = (
  latestSensorDetails: any,
  asset: Asset
): boolean => {
  const deviceStatus = asset.device.deviceStatus;
  const ignitionStatus =
    latestSensorDetails[`${asset.device.deviceId}`]?.sb_ignition_status ===
    'True';

  // If the device is Offline or Offline > 30D, always return false (even if sb_ignition is true)
  if (deviceStatus === 'Offline' || deviceStatus === 'Offline > 30D') {
    return false;
  }

  return ignitionStatus; // Otherwise, return the actual sb_ignition status
};

export const isVehcleParked = (
  latestSensorDetails: any,
  asset: Asset,
  customerCode: string
): boolean => {
  const assetDetails = latestSensorDetails[`${asset.device.deviceId}`];
  if (assetDetails?._status === 'True') {
    const parkingLot = coordsMap[`${customerCode}`]; // we do not have parkingLot coords
    if (
      // what logic do we use here?
      assetDetails.gps_latitude === parkingLot[0] &&
      assetDetails.gps_longitude === parkingLot[1]
    ) {
      return true;
    }
    return false;
  }

  return false;
};

export const iconCount = (asset: Asset, suggestions: any): number => {
  let count = 0;
  if (assetHasDTC(asset)) {
    count++;
  }
  if (assetHasSuggestions(asset, suggestions) > 0) {
    count++;
  }
  return count;
};
