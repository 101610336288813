import { Box } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { type FleetDeviceDetailsPerDay } from 'types/fuelAnalytics';
import useAppTheme from 'hooks/useAppTheme';
import FuelAssetItemChart from './FuelAssetItemChart';
import FuelAssetHeader from './FuelAssetHeader';

const FuelAssetItem = ({
  data,
  selectedSource,
}: {
  data: FleetDeviceDetailsPerDay;
  selectedSource: string[];
}) => {
  const { enableDarkTheme } = useAppTheme();

  return (
    <Box
      sx={{
        height: '350px',
        backgroundColor: enableDarkTheme
          ? '#19304F'
          : ThemePalette.light.toolBarBackground,
        marginBottom: '15px',
        padding: '10px',
        borderRadius: '4px',
      }}
      data-attribute="assetItemBox"
    >
      {/* Header Section */}
      <FuelAssetHeader data={data} />

      {/* Bar Chart Section */}
      <FuelAssetItemChart data={data} selectedSource={selectedSource} />
    </Box>
  );
};

export default FuelAssetItem;
