import { useAuth0 } from '@auth0/auth0-react';
import UserHandler from 'handlers/internal.user.handler';
import { useEffect, useMemo, useState } from 'react';
import {
  selectAssetsWithExcessiveIdling,
  selectAssociatedFilteredAssets,
} from 'store/asset.slice';
import { selectLatestTagData } from 'store/device.slice';
import { useAppSelector } from 'store/hook';
import { addLiveDataToAssets } from './helpers/asset.helpers';
import { selectNotificationsData } from 'store/notification.slice';
import useCustomEffect from './helpers/pieChart2Logic';
import { isDarkTheme } from 'utils/theme';
import FleetOverView3 from './FleetOverviewV3';
import { nonGPSCustomers } from 'utils/helpers/general';
import { SingleFadeLoader } from 'components/SingleFadeLoader';

export const FleetOverview = () => {
  const { isAuthenticated } = useAuth0();
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const { id: customerId, code: customerCode } = useAppSelector(
    (state) => state.persistedReducer
  ).customer;
  const [pieChartValue, setPieChartValue] = useState({
    clickState: true,
    val: '',
  });
  const [oldVal, setOldVal] = useState('');
  const userHandler = new UserHandler();

  const [isNonGPsClient, setIsNonGPsClient] = useState<boolean | null>(null);

  useEffect(() => {
    if (customerCode) {
      const result = nonGPSCustomers(customerCode);
      setIsNonGPsClient(result);
    }
  }, [customerCode]);

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated || !accessToken || !customerId) return;

      await userHandler.userInfoSync();
    };

    void fetchData();
  }, [isAuthenticated, accessToken, customerId]);

  const associatedFilteredAssets = useAppSelector(
    selectAssociatedFilteredAssets
  );

  const latestTagData = useAppSelector(selectLatestTagData);
  const notificationsData = useAppSelector(selectNotificationsData);
  const latestPaths = useAppSelector((state) => state.geoReducer).data
    .latestTripsForEachFleetAsset;
  const dtcData = useAppSelector((state) => state.dtcReducer);

  const dtc = Array.isArray(dtcData.data)
    ? dtcData?.data?.filter((item: any) => item.is_active)
    : [];

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const [shouldDraw, setShouldDraw] = useState<boolean>(false);

  // filter associatedFilteredAssets here with values from the second pie chart
  const excessiveIdlingAssetsData = useAppSelector(
    selectAssetsWithExcessiveIdling
  );

  const [newArr, setNewArr] = useState(associatedFilteredAssets);

  useCustomEffect({
    pieChartValue,
    associatedFilteredAssets,
    oldVal,
    excessiveIdlingAssetsData,
    setNewArr,
    setOldVal,
  });

  /**
   * NOTE: Leave this in for debugging purposes
   * because we're still working on the live process of
   * retrieving tags
   */
  // console.log(`DEBUG latestTagData ${new Date().toISOString()}`, {
  //   latestTagData,
  // });

  // add live data to the asset
  const filteredAssets = useMemo(() => {
    return addLiveDataToAssets(
      newArr,
      latestTagData,
      notificationsData,
      latestPaths,
      dtc
    );
  }, [newArr, latestTagData, latestPaths, dtc]);

  useEffect(() => {
    const shouldRenderTable = filteredAssets.some((asset) => {
      return asset.device?.daysBehind !== undefined;
    });
    setShouldDraw(shouldRenderTable);
  }, [filteredAssets]);

  /**
   * This is used to test the new dashboard behind this value.
   * To be removed when we are satisfied with tests
   * LEAVE IN in case we want a roll back
   */

  return (
    <>
      {isNonGPsClient === null ? (
        <SingleFadeLoader /> // Ensure there's a loading state
      ) : (
        <FleetOverView3
          filteredAssets={filteredAssets}
          enableDarkTheme={enableDarkTheme}
          shouldDraw={shouldDraw}
          isNonGpsClient={isNonGPsClient}
        />
      )}
    </>
  );
};
