import DriverBehaviorAPI from 'api/driver.behaviour.api';
import { BaseHandler } from './base.handler';
import {
  setLoadingState,
  setInclinometerEvent,
  setTirePressure,
  setGpsData,
  setFuelEfficiency,
} from 'store/inclinometer.slice';

export default class InclinometerHandler extends BaseHandler {
  private readonly api: DriverBehaviorAPI;

  constructor() {
    super();

    this.api = new DriverBehaviorAPI();
  }

  async get(
    startDateTime: string,
    endDateTime: string,
    assetId: string,
    assetType: string
  ): Promise<any> {
    this.dispatch(setLoadingState(true));
    try {
      const data = await this.api.getInclinometer({
        startDateTime,
        endDateTime,
        assetId,
        assetType,
      });

      this.dispatch(setInclinometerEvent(data.driverEvents));
      this.dispatch(setTirePressure(data.tirePressureData));
      this.dispatch(setFuelEfficiency(data.fuelEfficiency));
      this.dispatch(setGpsData(data.gpsData));
      this.dispatch(setLoadingState(false));
    } catch (_) {
      this.handleError('An error occurred while fetching Inclinometer Data.');
      this.dispatch(setLoadingState(false));
    }
  }
}
